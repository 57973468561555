export const ValidationSchema = {
    request_type: [
        { rule: "required", message: "Request Type required" },
    ],
    // notes: [
    //     { rule: "required", message: "Notes required" },
    // ],
    
}

export const ValidationSchemaLandlord = {
    landlordSign: [
        { rule: "required", message: "Landlord Signature Required" },
        { rule: "fullName", message: "Please enter your full name" }
    ],  
    
}

export const ValidationSchemaTenant = {
    tenantSign: [
        { rule: "required", message: "Tenant Signature Required" },
        { rule: "fullName", message: "Please enter tenant's full name" }
    ],  
    
}