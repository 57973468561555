import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EditButton from "../../Buttons/EditButton";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const UnitDescription = (props) => {
  const { editDetails } = props;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  useEffect(() => {
    if (history.location.pathname.indexOf("tour") > 0) {
      setIsTour(true);
    }
  }, [history.location.pathname]);

  const role = Number(Cookies.get("role"));
  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <h6>Description</h6>
      {(role === 2 || role === 1) &&
        <>
          {(editDetails && role === 1) &&
            <div className='button-wrapper for-admin'>
              <EditButton onClick={editDetails} />
            </div>}
          {unitDetails && unitDetails.description ?
            <p style={{ whiteSpace: 'pre-line' }} className="description-property">{unitDetails && unitDetails.description}</p>
            : (!isTour ? <h6>click edit to include a description here</h6> : '')}
          {(editDetails && role === 2) &&
            <div className='button-wrapper'>
              <EditButton onClick={editDetails} />
            </div>}
        </>}
        {(!role || role === 3 || (role === 2 && isTour)) &&
        <>
          {propertyDetails && propertyDetails.description ?
            <p style={{ whiteSpace: 'pre-line' }} classname="description-property">{propertyDetails && propertyDetails.description}</p>
            : ""}
        </>}
    </div>
  );
}
export default UnitDescription;