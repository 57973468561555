
const initialState = {}

export default function RawDataReducer(state = initialState, action) {
    switch (action.type) {        
        case 'UPDATE_RAW_DATA':
            delete action.type
             return { ...state, ...action }
        case 'CLEAR_RAW_DATA':
            return {}
        default:
            return state;
    }
}
