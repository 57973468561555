import React, { useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import {SingleOptionCheck, DatePickerComponent, ModalBox, PDFViewerComponent, FileBrowse} from "../../../";
import { leaseType, tenancyAgreement } from "../../../../../Constants/LeaseConstants";
import { fileUploadApi } from "../helper";



const LeaseDetails = (props) => {
    const { leaseInfo, OnSingleOptionCheck, handleDate, leaseData, errorClass, isSaveEnable, setSaveState, setLeaseInfo, setErrorClass, setSaveEnable } = props
    const [pdfModal, setPdfModal] = useState(false);
    const [showImageLoading, setShowImageLoading] = useState(false)
    let maxLength = 25;

    const handleViewPdf = () => setPdfModal(!pdfModal);

    const handleDrop = async (file, state) => {
        setShowImageLoading(true)
        const dataFile = file[0];
        let formdata = new FormData();
        formdata.append('file', dataFile);
        
        const {response, error} = await fileUploadApi(formdata, {setLeaseInfo, state});
        if(error){
            setShowImageLoading(false);
            setErrorClass(true);
        }else{
            setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [state] : { file: response }}))
            setErrorClass(false);
            setShowImageLoading(false);
        }
        setSaveEnable(true);
    }

    const removeFile = (index, state) => setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [state] : ''}))

    return (
        <>
            <div className='body-header'>
                <h3 className='text-left lease-heading'>lease details</h3>
                {leaseData && leaseData.is_sent_to_tenant ?
                    <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
                    : <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
            </div>
            <Row>
                <Form.Group as={Col} md='5'>
                    <Form.Label>How long is the lease</Form.Label>
                    <SingleOptionCheck
                        mapData={leaseType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'lease_type'} setParam={leaseInfo.lease_type}
                    />
                </Form.Group>
                <Form.Group as={Col} md='6'>
                    <Form.Label>Please indicate the start date {(leaseInfo.lease_type === 0 || leaseInfo.lease_type === 1) ? ' and end date' : ''}</Form.Label>
                    <Row>
                        <Col md='6'>
                            <DatePickerComponent
                                name="lease_start_date"
                                id="lease_start_date"
                                value={leaseInfo.lease_start_date}
                                handleDateChange={date => handleDate(date, 'lease_start_date')}
                                displayText={"Select Date"}
                                minDate={new Date()}
                            />
                        </Col>
                        {(leaseInfo.lease_type === 0 || leaseInfo.lease_type === 1) &&
                        <Col md='6'>
                            <DatePickerComponent
                                name="lease_end_date"
                                id="lease_end_date"
                                value={leaseInfo.lease_end_date}
                                handleDateChange={date => handleDate(date, 'lease_end_date')}
                                displayText={"Select Date"}
                                minDate={leaseInfo.lease_start_date}
                                disableValue={leaseInfo.lease_type === 0 ? false : true}
                            />
                        </Col>}
                    </Row>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md='10'>
                    <Form.Label>Are there any additional terms about the tenancy agreement?</Form.Label>
                    <Row>
                        <Col md='12' className={'mb-3'}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <SingleOptionCheck
                                    mapData={tenancyAgreement}
                                    OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                                    name={'tenancy_agreement'}
                                    setParam={leaseInfo.tenancy_agreement}
                                    extraClass={'d-inline-flex mb-0'}
                                />
                            </div>
                        </Col>
                        {leaseInfo.tenancy_agreement === 0 &&
                        <Col className='mb-2' xl={"12"} md={'12'}>
                            <div className="ln-pdf-file-box" style={{ cursor: 'pointer' }} onClick={handleViewPdf}>
                                <div className="ln-pdf-file-box-left">
                                    <Image className="icon" src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                                </div>
                                <div className='ln-pdf-file-box-right'>
                                    <div className="ln-pdfname">
                                        {leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop().length > maxLength ? leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop()}
                                    </div>
                                    <div className="ln-pdfname-static-word">
                                        <b>View PDF</b>
                                    </div>
                                </div>
                            </div>
                            <span className='d-flex justify-content-start' >

                            </span>
                            <ModalBox
                                show={pdfModal}
                                onHide={() => handleViewPdf()}
                                size="xl"
                                className="cu-full-modal"
                            >
                                <PDFViewerComponent PDFUrl={leaseData && leaseData.lease_deatils.rent_panda_lease_appendix} />
                            </ModalBox>
                        </Col>
                        }
                        {leaseInfo.tenancy_agreement === 1 ?
                        <Col md='5'>
                            {(leaseInfo.lease_pdf && leaseInfo.lease_pdf.file) ?
                                <Col className='confirm-section mb-2 d-flex justify-content-between details'>
                                    <span className='d-flex justify-content-start'>
                                        <Image className="icon" src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                                        <div className='file-details d-flex'>
                                            {leaseInfo.lease_pdf.file.split('/').pop().length > maxLength ? leaseInfo.lease_pdf.file.split('/').pop().substring(0, maxLength) + '...' : leaseInfo.lease_pdf.file.split('/').pop()}
                                        </div>
                                    </span>
                                    <Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, 'lease_pdf')} src={require("../../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
                                </Col>
                                : <FileBrowse
                                    handleDrop={(e) => handleDrop(e, 'lease_pdf')}
                                    size={'sm'}
                                    title={'Upload Documents'}
                                    percent={leaseInfo.lease_pdf.percent}
                                    multiple={false}
                                    fileType={'.pdf'}
                                    disable={false}
                                    imageData={leaseInfo.lease_pdf.file && leaseInfo.lease_pdf.file}
                                    removeFile={(e) => removeFile(e, 'lease_pdf')}
                                    lableClass={'custom-lable'}
                                    section={'upload-lease'}
                                    // uploadData={uploadData && uploadData}
                                    errorClass={errorClass ? "error-upload-box" : ""}
                                    showImageLoading={showImageLoading}
                                />}
                        </Col> : ""}
                    </Row>
                </Form.Group>
            </Row>
        </>
    )
}

export default LeaseDetails
