import React from 'react';
import { HighlightContent } from '../../components';
import './style.scss';
import { withRouter } from 'react-router-dom';
import CMSTableHeader from './CMS/CMSTableHeader';
// import { logger } from '../../../Utils';

// const log = logger("ContentManagementTable");
const ContentManagementTable = (props) => {
	const { to, from, current_page, last_page, total, clickNext, clickPrev, previewPage, confirmDeleteModal, tableData, goToPageDetail } = props;

	const changeStatus = (data) => {
		if (data === 1) {
			return "one";
		} else if (data === 2) {
			return "two";
		} else if (data === 3) {
			return "three";
		} else if (data === 5) {
			return "five";
		}
	}

	return (
		<div className="table-component-wrapper listing-management" style={{ overflow: 'visible', minHeight: '600px' }}>
			<div className='table-responsive fixing-height'>
				<table className="table-component">
					<thead>
						<tr className='thead-height'>
							<CMSTableHeader
								sort_by_date={props.sort_by_date}
								sortDateState={props.sortDateState}
								sort_by_type={props.sort_by_type}
								sort_by_status={props.sort_by_status}
								sortStatus={props.sortStatus}
								componentLoad={props.componentLoad}
								columns={props.columns}
								searchName={props.searchName}
								setSearchName={props.setSearchName}
								searchCategory={props.searchCategory}
								setSeachCategory={props.setSeachCategory}
								searchCreator={props.searchCreator}
								setSearchCreator={props.setSearchCreator}
								sortDate={props.sortDate}
								sortContentType={props.sortContentType}
							/>
						</tr>
					</thead>
					<tbody>
						{tableData.map((data, i) => {
							return (
								<tr key={'table_body_' + i}>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'><HighlightContent searchWord={props.searchName} highlightWord={data.name} /></td>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'>{data.created_date}</td>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'>{data.content_type}</td>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'><HighlightContent searchWord={props.searchCategory} highlightWord={data.category} /></td>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'><HighlightContent searchWord={props.searchCreator} highlightWord={data.creator} /></td>
									<td onClick={() => goToPageDetail(data)} className='cursorPointer'>
										<span className={"formatted-status " + changeStatus(data.status)}>{data.status_string}</span>
									</td>
									<td>
										<div className='d-flex justify-content-around align-items-center'>
											<img onClick={() => previewPage(data)} className="cursorPointer" src={require('../../../_assets/images/green-eye-view-icon.svg')} alt="" />
											<img onClick={() => confirmDeleteModal(data)} className="cursorPointer" src={require('../../../_assets/images/red-delete-icon.svg')} alt="" />
										</div>
									</td>
								</tr>)
						})}
					</tbody>
				</table>
				{tableData.length === 0 &&
					<div className="empty-table-data">
						There is no data found
            </div>
				}
			</div>
			<div className="table-footer">
				<div>
					{tableData.length === 0 ? <span>Data not found</span> : <span className="left">
						{tableData.length} of {total}
					</span>}
				</div>
				<div>
					{from && to &&
						<span className="right">
							{from} to {to}
							{current_page !== from ? <img className="previous-arrow" onClick={clickPrev} src={require('../../../_assets/images/next.svg')} alt="previous" /> : <img className="previous-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="previous" />}
							{current_page !== last_page ? <img className="next-arrow" onClick={clickNext} src={require('../../../_assets/images/next.svg')} alt="next" /> : <img className="next-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="next" />}
						</span>}
				</div>
			</div>

		</div>
	)
}

export default withRouter(ContentManagementTable);