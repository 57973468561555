export const SET_SEARCH_LOCATION    = 'SET_SEARCH_LOCATION';
export const SET_SEARCH_FILTER      = 'SET_SEARCH_FILTER';
export const SET_PROPERTY_LISTS     = 'SET_PROPERTY_LISTS';
export const SET_LOCATION_LIST      = 'SET_LOCATION_LIST';

export function setSearchLocation(data) {
    return function (dispatch) {
        dispatch({ type: SET_SEARCH_LOCATION, getSearchLocation: data });
    };
}
export function setSearchFilter(data) {
    return function (dispatch) {
        dispatch({ type: SET_SEARCH_FILTER, getSearchFilter: data });
    };
}

export function setPropertyLists(data) {
    return function (dispatch) {
        dispatch({ type: SET_PROPERTY_LISTS, getListData: data });
    };
}

export function setLocationList(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOCATION_LIST, getLocationList: data });
    };
}
