import React, { useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { LargeButton, CancelButton, SidePanel, FileBrowse } from '../../components';
import LeaseFIleView from './LeaseFIleView';
import { uploadLeaseFile } from './helper';

const LeaseUploadSidePanel = (props) => {
    const {onHide,onCreateLease}=props
    const [back_image, setBack_image] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [showImageLoading, setShowImageLoading] = useState(false)


    const handleDrop = (file, setImage) => {
        setShowImageLoading(true)
        uploadLeaseFile(file,setUploadData, setImage, setErrorClass, setShowImageLoading)
    }
    const removeImage = (index, setImage) => {
        setImage('')
    }
    const submit = () => {

        onCreateLease(back_image)
    }

    return (<>
        <SidePanel
            isShow={true}
            isHide={onHide}
            title='Upload Lease'
            subTitle='Select previous agreement'
        >
            <div className='sidepanel-body mt-5'>
                <Row>
                    <Col md="12">
                        {!back_image?.file ? <FileBrowse
                            handleDrop={(e) => handleDrop(e, setBack_image)}
                            size={'sm'}
                            title={'Upload Documents'}
                            percent={back_image.percent}
                            multiple={false}
                            fileType={'.pdf'}
                            disable={false}
                            showImageLoading={showImageLoading}
                            imageData={back_image.file && back_image.file}
                            removeImage={(e) => removeImage(e, setBack_image)}
                            lableClass={'custom-lable'}
                            section={'upload-lease'}
                            uploadData={uploadData && uploadData}
                            errorClass={errorClass === true ? "error-upload-box" : ""}
                        />
                            :
                            <LeaseFIleView
                                removeFile={() => setBack_image({ file: '', percent: 0 })}
                                formDoc={back_image} />
                        }
                    </Col>
                </Row>
            </div>
            <div className='fixed-bottom d-flex justify-content-between'>
                <CancelButton onClick={onHide} title='Back' />
                <LargeButton 
                 isDisable={!back_image?.file}
                 saveLoading={false}
                 title='upload lease' onClick={() => submit()} style={{ width: '200px' }} />
            </div>

        </SidePanel>
    </>);
}
export default LeaseUploadSidePanel