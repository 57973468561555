import React from "react"
import "../ChartsStyle.scss"
import "../../ReportSections/ReportSection.scss"
import ExecutiveSummary from "../../ReportSections/ExecutiveSummary/ExecutiveSummary";

const RChart = ({data, chartData, name, isYearlyRecap = false}) => {
  const calcBgColor = (item) => {
    item = parseFloat(item)
    if (item > 0) {
      return "#D1E9B5"
    } else if (item < 0) {
      return "#ED7474"
    } else {
      return "#F5F5F5"
    }
  }

  const getKeys = () => {
    const keys = []
    for (const key in chartData[0]) {
      keys.push(key)
    }
    return keys
  }

  const countPat = /Count/mi
  const myKeys = getKeys().filter((key) => !countPat.test(key))
  const tableClass = isYearlyRecap ? "yearlyRecap" : "rent-rates-table"
  const remodeledData = {
    title: data.data.execSumHeader,
    data: {...data.data.execSumText}
  }

  return (
      <div className={"reportSection"} id={name}>
        {data.data.execSumText && (
              <ExecutiveSummary data={remodeledData} name={data.data.execSumHeader} />

        )}
        <h3 className={"chapterHeader"}>{data.title}</h3>
        <div className={"sectionContent"}>
          <div className="tableContainer">
            <table id={"affordabilityTable"}
                   className={tableClass}>
              <thead>
              <tr>
                {myKeys.map((key, index) => {
                  return (
                      <th key={index}>{key}</th>
                  )})
                }
              </tr>
              </thead>
              <tbody>
              { !isYearlyRecap ? chartData.map((row, index) => {
                return (
                    <tr key={index}>
                      <td>{row[myKeys[0]]}</td>
                      <td>{row[myKeys[1]]}</td>
                      <td>{row[myKeys[2]]}</td>
                      <td>{row[myKeys[3]]}</td>
                      <td style={ { backgroundColor: calcBgColor(row[myKeys[4]]),borderRight: "1px solid grey" }}>
                        {row[myKeys[4]]}
                      </td>
                      {row[myKeys[5]] && (
                          <td style={{ backgroundColor: calcBgColor(row[myKeys[5]])} }>
                            {row[myKeys[5]]}
                          </td>
                      )}
                    </tr>
                )}
              ): ( chartData.map((row, index) => {
                const lastRow = index === chartData.length - 1
                return (
                    <tr key={index}>
                      <td style={{ backgroundColor: calcBgColor(row[myKeys[0]])}}>
                        {lastRow ? (   <b>{row[myKeys[0]]}</b>) : ( row[myKeys[0]])}
                      </td>
                      <td style={{ backgroundColor: calcBgColor(row[myKeys[1]])}}>
                        {row[myKeys[1]]}</td>
                        
                      <td style={{ backgroundColor: calcBgColor(row[myKeys[2]])}}>
                        {row[myKeys[2]]}</td>
                        
                      {row[myKeys[3]] && ( <td style={{ backgroundColor: calcBgColor(row[myKeys[3]])}}>
                        {row[myKeys[3]]}</td>
                      )}
                      
                      {row[myKeys[4]] && ( <td style={{ backgroundColor: calcBgColor(row[myKeys[4]]), borderRight: "1px solid grey"}}>
                        {row[myKeys[4]]}</td>
                      )}
                      
                      {row[myKeys[5]] && ( <td style={{ backgroundColor: calcBgColor(row[myKeys[5]])} }>
                            {row[myKeys[5]]}
                          </td>
                      )}
                    </tr>
                )}))}
              </tbody>
            </table>
          </div>
        </div>
        {isYearlyRecap && (
            <>
              <p className={"appendixText"}>{data.data.appendixText}</p>
              <hr className={"reportDivider"}/>
            </>
        )}
      </div>
  )
}

export default RChart


