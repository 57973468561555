import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Image, Dropdown, NavDropdown } from "react-bootstrap"; // Form, Nav, Navbar, ,
import { setToggleMenu } from "../../../Store/actions/Action";
import Cookie from "js-cookie";
import { logout } from '../../../Store/actions/AuthAction';
import CookiePolicyBanner from '../CookiePolicyBanner';
// import RpImage from "../../../../components/Image";
import "./style.scss";
class HeaderLandingPage extends Component {
  handleClose = () => {
    this.setState({
      isShow: false
    })
  }
  openNotificationPanel = () => {
    this.setState({
      isShow: true
    })
  }
  onLogout = () => {
    Cookie.remove('access_token');
    Cookie.remove('role');
    this.props.dispatch(logout());
  };

  getRole = () =>{
    return parseInt(Cookie.get('role') || 0)
  }

  render() {
    var name = this.props.editProfileList.data
      ? this.props.editProfileList.data.name
      : "User";
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    const role = this.getRole()
    return (
      <React.Fragment>
        <CookiePolicyBanner />
        <header className="landing-header">
          <nav className="d-flex justify-content-between navbar navbar-expand-lg navbar-light sticky-top cu-container-wrap">
            <div className="container">
              {/* <div className="nav d-lg-none d-xl-none d-md-none p-3 navbar-nav">
                <div className="d-flex align-items-center">
                  <div className="navbar-drawer">
                    <div className="navbar-dark navbar-toggler-icon"></div>
                  </div>
                </div>
              </div> */}
              <div className="mobile-icon-landing">
                <button type="button" className="menu-icon btn btn-primary" onClick={() => this.props.dispatch(setToggleMenu(!this.props.toggleMenu))}><div></div><div></div><div></div></button>
              </div>
              <Link to={(role && role === 2) ? "/landlord" : "/"} className="navbar-brand">
                <Image
                  alt="Rent Panda"
                  src={require("../../../_assets/images/logo-color.svg")}
                  className="d-inline-block align-top"
                />
              </Link>
              <div className="d-flex align-items-center">
                <Link to="/leasing" className="cu-dropdown-toggle btn btn-success header-no-show-mobile">leasing</Link>
                <Link to="/screening" className="cu-dropdown-toggle btn btn-success header-no-show-mobile">screening</Link>
              
                <Dropdown className="d-none d-md-block">
                  <Dropdown.Toggle variant="success" className="cu-dropdown-toggle" id="how-it-works">
                    how it works
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/landlord" id="MNU-landlord-HIW" data-description="Landlord clicked how it works from main nav">for landlords</Dropdown.Item>
                    <Dropdown.Item href="/how-it-works/tenant" id="MNU-tenant-HIW" data-description="Tenant clicked how it works from main nav">for tenants</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Link to="/resources"
                  className="navbar-link d-none d-md-block nav-link"
                  onClick={() => this.props.dispatch(setToggleMenu(false))}>
                    resources
                </Link> */}

                  <Dropdown className="d-none d-md-block">
                  <Dropdown.Toggle variant="success" className="cu-dropdown-toggle" id="how-it-works">
                  resources
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/resources/landlord" id="MNU-resources-for-landlords" data-description="Landlord clicked resources from main nav">for landlords</Dropdown.Item>
                    <Dropdown.Item href="/resources/tenant" id="MNU-resources-for-tenants" data-description="Tenant clicked resources from main nav">for tenants</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


                {(Cookie.get("access_token")) && this.props.auth.loggedIn ? (<>
                  <div className="line-devider" style={{ borderLeft: "3px solid #EAEDF0", left: "18px", height: "50px" }}> </div>
                  <div className="user-sort-name" style={{ width: "50px", height: "50px", marginLeft: "13px" }}>
                    <div
                      className="w3-circle"
                      style={{
                        background: "#4B4B4B",
                        marginTop: "5px",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        left: "4.7px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "white" }}>{initials}</span>
                    </div>
                  </div>
                  <NavDropdown
                    style={{  }}
                    title={
                      this.props.editProfileList.data
                        ? Object.values(this.props.editProfileList.data.name.toLowerCase())
                            .join("")
                            .toString()
                        : "User"
                    }
                    id="nav-dropdown"
                    className="profile-dropdown"
                  >
                    <NavDropdown.Item onClick={() => {
                      this.props.history.push({
                        pathname: role === 2 ? '/dashboard'
                          : role === 1 ? '/dashboard/all-user' : '/dashboard/my-listing'
                      })
                    }}>
                      my dashboard
                    </NavDropdown.Item>

                    {this.props.auth.role !== 1 && (
                      <NavDropdown.Item href="/profile">my profile</NavDropdown.Item>
                    )}

                    <NavDropdown.Item onClick={this.onLogout}>
                      sign out
                    </NavDropdown.Item>
                  </NavDropdown>
                </>) : (<>
                    <Dropdown className="d-none d-md-block">
                      <Dropdown.Toggle variant="success" className="cu-dropdown-toggle" id="MNU-sign-up" data-description="Visitor initiated sign up process">
                      sign up
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/signup/landlord" id="MNU-sign-up-for-landlord" data-description="Visitor chose landlord sign up">for landlords</Dropdown.Item>
                        <Dropdown.Item href="/signup/tenant" id="MNU-sign-up-for-tenant" data-description="Visitor chose tenant sign up">for tenants</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/*
                    <Link to={`${(this.props.location.pathname.split("/").pop() === "landlord") ? "/signup/landlord" : "/signup/tenant"}`}
                      className="navbar-link d-none d-md-block nav-link"
                      onClick={() => this.props.dispatch(setToggleMenu(false))}>
                        sign up
                    </Link>*/}

                    <Link to="/login"
                      className="navbar-link d-md-flex nav-link-active f-secondary-r nav-link text-center"
                      onClick={() => this.props.dispatch(setToggleMenu(false))}
                      id="BTN-log-in" data-description="Visitor chose to log into their account">
                        log in
                    </Link>
                  </>)}
              </div>
            </div>
          </nav>
          {/* mobile menu */}
          <div className={`mobile-menu-links-landing ${this.props.toggleMenu ? 'active' : ''}`}>
            <div className="text-left">
              <button className="btn mobile-menu-landing-close-icon" onClick={() => this.props.dispatch(setToggleMenu(false))}>
              <Image
                    alt="Rent Panda"
                    src={require("../../../_assets/images/close-icon.svg")}
                    className=""
                  />
              </button>
            </div>
              <ul className="mobile-menu-links-ul">
                <li><Link to="/login" onClick={() => this.props.dispatch(setToggleMenu(false))} id="BTN-log-in" data-description="Visitor chose to log into their account">log in</Link></li>
                <li><Link to="/signup/tenant" onClick={() => this.props.dispatch(setToggleMenu(false))}>sign up</Link></li>

                <li className="separator"></li>

                {/* <li><Link to="/resources" onClick={() => this.props.dispatch(setToggleMenu(false))}>resources</Link></li> */}

                <li><h5 className="subMenuHead">how it works</h5>
                  <ul>
                    <li><Link to="/how-it-works/landlord" onClick={() => this.props.dispatch(setToggleMenu(false))} id="MNU-landlord-HIW" data-description="Landlord clicked how it works from main nav">for landlords</Link></li>
                    <li><Link to="/how-it-works/tenant" onClick={() => this.props.dispatch(setToggleMenu(false))} id="MNU-tenant-HIW" data-description="Tenant clicked how it works from main nav">for tenants</Link></li>
                  </ul>
                </li>

                <li><h5 className="subMenuHead">resources</h5>
                  <ul>
                    <li><Link to="/resources/landlord" onClick={() => this.props.dispatch(setToggleMenu(false))} id="MNU-resources-for-landlords" data-description="Landlord clicked resources from main nav">for landlords</Link></li>
                    <li><Link to="/resources/tenant" onClick={() => this.props.dispatch(setToggleMenu(false))} id="MNU-resources-for-tenants" data-description="Tenant clicked resources from main nav">for tenants</Link></li>
                  </ul>
                </li>

                <li className="separator"></li>

                <li><Link to="/for-panda-partners" onClick={() => this.props.dispatch(setToggleMenu(false))}>for panda partners</Link></li>
                <li><Link to="/for-realtors" onClick={() => this.props.dispatch(setToggleMenu(false))}>for realtors</Link></li>
                <li><Link to="/for-property-managers" onClick={() => this.props.dispatch(setToggleMenu(false))}>for property managers</Link></li>
                <li><Link to="/leasing" onClick={() => this.props.dispatch(setToggleMenu(false))}>leasing</Link></li>
                <li><Link to="/screening" onClick={() => this.props.dispatch(setToggleMenu(false))}>screening</Link></li>
                {/* <li><Link to="/portals-and-auxillary/advertisers" onClick={() => this.props.dispatch(setToggleMenu(false))}>Advertise On Rentpanda.Ca</Link></li> */}

                <li className="separator"></li>

                <li><Link to="/about-us" onClick={() => this.props.dispatch(setToggleMenu(false))}>about us</Link></li>
                <li><Link to="/terms-and-conditions" onClick={() => this.props.dispatch(setToggleMenu(false))}>terms and conditions</Link></li>
                <li><Link to="/privacy-policy" onClick={() => this.props.dispatch(setToggleMenu(false))}>privacy policy</Link></li>

              </ul>
          </div>
        </header>

      </React.Fragment>
    );
  }
}

const select = (state) => ({
  editProfileList: state.editProfile.editProfileList,
  toggleMenu: state.headerReducer.toggleMenu,
  auth: state.auth,
});

export default connect(select) (withRouter(HeaderLandingPage));
