import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import { TableLandlord } from '../../../components';
import UserManagementTable from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import '../style.scss';

const log = logger('Showings');
const Showings = (props) => {
	const { user_id, setOnClick, role } = props;
	const [showingList, setShowingList] = useState(null);
	const [searchLocation, setSearch] = useState(null);
	const [sort_by_time, setSortTime] = useState('all');
	const [sort_by_date, setSortDate] = useState('all');
	const [sort_by_status_showing, setStatusShowing] = useState('all');
	const [isLoading, setIsLoading] = useState(true);

	let userColumns = [];
	let name = role === 'landlord' ? 'TENANT' : 'LANDLORD'
	userColumns = [
		{
			header: 'ADDRESS',
			width: '30%'
		},
		{
			header: 'DATE',
			width: '15%'
		},
		{
			header: 'TIME',
			width: '20%'
		},
		{
			header: name,
			width: '17%'
		},
		{
			header: 'STATUS',
			width: '18%'
		}
	];
	const filterData = {
		'filter': {
			'address': searchLocation,
			'status': [sort_by_status_showing]
		},
		'sort_by_date': {
			'order': sort_by_date // send here (any one): all, latest_to_earliest, earliest_to_latest
		},
		'sort_by_time': {
			'order': sort_by_time // send here (any one): all, latest_to_earliest, earliest_to_latest
		},
		'limit': 10
	}
	let url = '';
	if (role === 'landlord') {
		url = '/admin/users/landlord/showings/' + user_id;
	} else {
		url = '/admin/users/tenants/showings/' + user_id;
	}

	const getData = (fetchedUrl) => {
		if (user_id !== '') {
			postAPI(fetchedUrl ? fetchedUrl : url, filterData)
				.then(response => {
					if (response.status === 200) {
						setIsLoading(false);
						const res = response.data.data.listing;
						setShowingList(res)
					}
				})
		}
	}

	const onClickNext = (currentPage) => {
		log.info('second', currentPage);
		const nextPage = currentPage + 1;
		let urlNext = '';
		if (role === 'landlord') {
			urlNext = `/admin/users/landlord/showings/${user_id}?page=${nextPage}`;
		} else {
			urlNext = `/admin/users/tenants/showings/${user_id}?page=${nextPage}`;
		}
		getData(urlNext);
	}

	const onClickPrev = (currentPage) => {
		log.info('three', currentPage);
		const prevPage = currentPage - 1;
		let urlPrev = '';
		if (role === 'landlord') {
			urlPrev = `/admin/users/landlord/showings/${user_id}?page=${prevPage}`;
		} else {
			urlPrev = `/admin/users/tenants/showings/${user_id}?page=${prevPage}`;
		}
		getData(urlPrev);
	}
	const goToProfileDetail = (userData) => {
		setOnClick(userData);
	}

	useEffect(() => {
		if ((user_id !== '' || user_id !== undefined) && (role === 'landlord' || role === 'tenant')) {
			let urlInside = '';
			role === 'landlord' ?
				urlInside = '/admin/users/landlord/showings/' + user_id :
				urlInside = '/admin/users/tenants/showings/' + user_id;
			const data = {
				'filter': {
					'address': searchLocation,
					'status': [sort_by_status_showing]
				},
				'sort_by_date': {
					'order': sort_by_date // send here (any one): all, latest_to_earliest, earliest_to_latest
				},
				'sort_by_time': {
					'order': sort_by_time // send here (any one): all, latest_to_earliest, earliest_to_latest
				},
				'limit': 10
			}
			postAPI(urlInside, data)
				.then(response => {
					if (response.status === 200) {
						setIsLoading(false)
						const res = response.data.data.listing;
						setShowingList(res)
					}
				})
		}
	}, [user_id, searchLocation, sort_by_date, sort_by_time, sort_by_status_showing, role])
	return (
		isLoading === true ? <UserManagementTable columns={userColumns} component={'lease'} /> :
			<>{
				showingList && showingList.data ?
					<TableLandlord
						columns={userColumns}
						tableData={showingList.data}
						to={showingList.to}
						from={showingList.from}
						current_page={showingList.current_page}
						last_page={showingList.last_page}
						per_page={showingList.per_page}
						total={showingList.total}
						goToProfileDetail={(data) => goToProfileDetail(data)}
						clickNext={() => onClickNext(showingList.current_page)}
						clickPrev={() => onClickPrev(showingList.current_page)}
						setSearch={(data) => setSearch(data)}
						searchLocation={searchLocation}
						sort_by_status_showing={sort_by_status_showing}
						sortShowing={(data) => setStatusShowing(data)}
						onSortTime={(data) => setSortTime(data)}
						onSortDate={(data) => setSortDate(data)}
						component='showings'
					/> : ''
			}</>
	);
}

export default withRouter(Showings);
