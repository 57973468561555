import React from 'react';
import "./style.scss"

import DIYIcon from "../../../_assets/images/new_homepage/icons/DIY.svg"
import PropertyManagementIcon from "../../../_assets/images/new_homepage/icons/PropertyManagement.svg"
import TenantPlacementIcon from "../../../_assets/images/new_homepage/icons/TenantPlacement.svg"
import rightHalfArrow from "../../../_assets/images/homepage/rightHalfArrow.svg"
import Map from "../../../_assets/images/new_homepage/Map.png"
import useWindowDimensions from "../../../App/hooks/WindowSize";


const ServicesSection = ({openRentFormPM, openRentFormTP}) => {
    const { height, width } = useWindowDimensions();
    const serviceData = [
        {
            serviceTitle: "DIY Digital Tools",
            icon: DIYIcon,
            cardDesc: [
              "Post unlimited properties free",
              "Syndicate To Facebook Marketplace<span>$</span>",
              "View Tenant Profiles",
              "Message Tenants",
              "Book Showings",
              "Screen Tenants with Best in Class Tools<span>$</span>",
              "Build your Lease and Lease Appendix"
            ],
            cardCost: "Freemium",
            cta: "get started",
            cardLink: "/signup/landlord",
        },
        {
            serviceTitle: "Tenant Placement",
            icon: TenantPlacementIcon,
            cardDesc: [
              "Rent Assessment & Tenant Profile Creation",
              "Professional Photos and Videos",
              "Marketing Across Multiple Platforms",
              "Attendance at Every Showing",
              "Best In Class Tenant Screening",
              "Lease And Proprietary Appendix Creation",
              "Tenant Hand-off",
              "Move In Inspection & Key Handoff<span>$</span>"
            ],
            cardCost: "1 Months Rent",
            cta: "get in touch",
            cardLink: openRentFormTP,
        },
        {
            serviceTitle: "Property Management",
            icon: PropertyManagementIcon,
            cardDesc: [
              "24/7/365 Tenant Care",
              "Collection and Deposit of Monthly Rent",
              "Maintenance and Repair Coordination",
              "Monthly and Year-End Accounting Statements",
              "Lease Enforcement And Notice Serving",
              "Tenant Turnover Coordination",
              "On Demand Inspections<span>$</span>",
              "10% Discount On Tenant Placement"
            ],
            cardCost: "As Low as $39/Month/Unit",
            cta: "find out more",
            cardLink: openRentFormPM,
        }
    ]

    const ServiceCard = ({cardData, index}) => {
        return (
            <div className={`servicesCard${index + 1} servicesCard col-sm`}>
                <div className="serviceText">
                    { cardData.cardLink !== null ? (
                            <a>
                                <div className="serviceTitle">
                                    {cardData.serviceTitle} <img src={cardData.icon} />
                                </div>
                            </a>)
                        : <div className={"serviceTitle"}>{cardData.serviceTitle} <img src={cardData.icon} /></div>
                    }
                  
                    { cardData.cardDesc !== null ? (
                      <ul>
                        {cardData.cardDesc.map((data, i) =>
                          <li data-card-desc-id={i} dangerouslySetInnerHTML={{__html: data}} />
                        )}
                      </ul>
                    ) : ( <></> )}
                  
                    { cardData.cardCost !== null
                      ? <div className="cardCost">Cost:&nbsp;{ cardData.cardCost }</div>
                      : <></> }
                  
                    { cardData.cardLink !== null && (
                      <>
                        { typeof cardData.cardLink == 'string' ? ( 
                           <a href={cardData.cardLink} className={"viewMoreLink"}> {cardData.cta} <img src={rightHalfArrow} alt=""/> </a>
                          ) : ( <a onClick={() => cardData.cardLink(true)} className={"viewMoreLink"}> {cardData.cta} <img src={rightHalfArrow} alt=""/> </a> )
                        }
                      </>
                    )}
                </div>
            </div>
        )
    }


    return (
          <div className="servicesSection">
              <div className="servicesHeader">
                  what we do
              </div>
              {/* Media query to show buttons*/}
              <div className="servicesContent container">
                <div class="row">
                  {serviceData.map((data, index) => {
                              return <ServiceCard cardData={data} index={index} key={index} />
                          })}
                </div>
              </div>
          </div>
    );
};

export default ServicesSection;