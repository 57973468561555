import React from "react";
import { Container, Row, Col, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from "react-multi-carousel";
import { Loader } from "../../App/components";
import "./style.scss";
const placeHolderImg = require("../../_assets/images/placeholder.jpg");

const PartnersList = (props) => {
  console.log("props", props);
  const {
    partnersList,
    colCount,
    showLoader,
    responsive,
    isDesktop,
    numberOfCards,
  } = props;

  const partnersBox = () => {
    return partnersList.length > 0 ? (
      partnersList.slice(0, 3).map((partner, i) => {
        return (
          <Col key={"article" + i} md={colCount} className="grid mb-4">
            <div className="resources-box">
              <div className="resources-box-image">
                {/* <Image className="grid-img" src={partner.image_url ? partner.image_url : placeHolderImg} alt="grid-img" /> */}
                <LazyLoadImage
                  effect="blur"
                  className="grid-img"
                  src={partner.image_url ? partner.image_url : placeHolderImg}
                  alt="grid-img"
                />
              </div>
              <h4 className="grid-name">{partner.company_name}</h4>
              <p class="grid-content">
                {partner.company_bio.replace(/<[^>]*>?/gm, "")}
              </p>

              <div className="mt-auto">
                <Nav.Link
                  className="date-text"
                  href={`/resources/partners/preview/${partner.id}`}
                >
                  Read more
                  <Image
                    className="pl-2 next"
                    src={require("../../_assets/images/arrow-forward.svg")}
                    alt="View More"
                  />
                </Nav.Link>
              </div>
            </div>
          </Col>
        );
      })
    ) : showLoader ? (
      <Loader />
    ) : (
      <h2>No Data Found</h2>
    );
  };

  return (
    <section>
      <Container>
        <Row className="section-head">
          <Col>
            <h2 className="subHead">Panda Partners</h2>
          </Col>
          <Nav.Link className="date-text" href={`/resources/partners`}>
                View More
                <Image
                  className="pl-2 next"
                  src={require("../../_assets/images/arrow-forward.svg")}
                  alt="View More"
                />
              </Nav.Link>
          {partnersList && numberOfCards && partnersList.length >= numberOfCards && (
            <Col className="text-right viewMore col-auto">
              <Nav.Link className="date-text" href={`/resources/partners`}>
                View More
                <Image
                  className="pl-2 next"
                  src={require("../../_assets/images/arrow-forward.svg")}
                  alt="View More"
                />
              </Nav.Link>
            </Col>
          )} 
        </Row>

        <Carousel
          responsive={responsive}
          infinite={isDesktop ? false : true}
          partialVisible={isDesktop ? false : true}
          removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
          swipeable={numberOfCards === 1}
          showDots={true}
          draggable={true}
          autoPlay={isDesktop ? false : true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          dotListClass="landlord-resources-dot-list"
          arrows={numberOfCards !== 1}
        >
          {partnersList && partnersBox()}
        </Carousel>
      </Container>
    </section>
  );
};

export default PartnersList;
