import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const DetailStatusLoader = (props) => {
    const loop = 3;
    return (
        <>
            {(() => {
                const options = [];
                for (let i = 0; i < loop; i++) {
                    options.push(<React.Fragment key={i}><RowList /></React.Fragment>);
                }
                return options;
            })()}
        </>
    )
}
export default DetailStatusLoader;

const RowList = (props) => {
    return (
        <div className="inner-wrapper">
            <Row>
                <Col md={8}>
                    <h6>
                        <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="0" y="0" rx="0" ry="0" width="10%" height="10" />
                        </ContentLoader>
                    </h6>
                    <h4 className="switch-heading">
                        <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="0" y="0" rx="0" ry="0" width="40%" height="10" />
                        </ContentLoader>
                    </h4>
                </Col>
                <Col md={4} className="text-right">
                    <ContentLoader height={50} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                        <rect x="100" y="0" rx="20" ry="20" width="45%" height="40" />
                    </ContentLoader>
                </Col>
            </Row>
        </div>
    )
}