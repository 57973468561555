import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Row, Container } from 'react-bootstrap';
import { PageTitleHeader, Loader, ModalBox } from '../../App/components';
import Layout from '../components/Layout';
import { MainBanner, LeaveItToThePros, Testimonials } from './components';
import { logger } from '../../Utils';


import './style.scss';

const bannerData = [
	{
		id: 1,
		formFor: 'realtors',
		formTitle: 'become a realtor partner'
	}, {
		id: 2,
		formFor: 'property-managers',
		formTitle: 'get a piece of mind with property management tools'
	}, {
		id: 3,
		formFor: 'advertisers',
		formTitle: 'advertise with us easily and quickly'
	}, {
		id: 4,
		formFor: 'partners',
		formTitle: 'become a panda partner'
	},
];
const leaveItToTheProsData = [
	{
		id: 1,
		icon: require("../../_assets/images/portals-and-auxillary/save-money.svg"),
		title: "Save Money",
		description: "We'll get you the best rent, keep vacancies low and make sure there are no missed payments.",
	}, {
		id: 2,
		icon: require("../../_assets/images/portals-and-auxillary/save-time.svg"),
		title: "Save Time",
		description: "Sit back while we keep your property delivering value with fast maintenance, personal service and transparent, on-demand reports.",
	}, {
		id: 3,
		icon: require("../../_assets/images/portals-and-auxillary/save-headaches.svg"),
		title: "Save Headaches",
		description: "We handle it all, so you can avoid the late night phone calls, dealing with trades and chasing unpaid rent.",
	}
];
const testimonialsData = [
	{
		id: 1,
		userImage: require("../../_assets/images/portals-and-auxillary/user-image.png"),
		testimonial: "In a somewhat complex investment property world, where many factors affect your investment, Rent Panda brings peace of mind.",
		name: "Anthony Poon",
		userType: "Realtor",
	}, {
		id: 2,
		userImage: require("../../_assets/images/portals-and-auxillary/user-image.png"),
		testimonial: "In a somewhat complex investment property world, where many factors affect your investment, Rent Panda brings peace of mind.",
		name: "Anthony Poon",
		userType: "Realtor",
	}, {
		id: 3,
		userImage: require("../../_assets/images/portals-and-auxillary/user-image.png"),
		testimonial: "In a somewhat complex investment property world, where many factors affect your investment, Rent Panda brings peace of mind.",
		name: "Anthony Poon",
		userType: "Realtor",
	},
];

const log = logger("Portals And Auxillary");
const PortalsAndAuxillary = (props) => {
	log.info("props", props);
	const [bannerInfo, setBannerInfo] = useState({});
	const [titleHeader, setTitleHeader] = useState('');
	const [formSubmitPop, setFormSubmitPop] = useState(false);

	useEffect(() => {
		if (props.match.params.slug) {
			let urlSlug = props.match.params.slug;
			let title = urlSlug;
			setBannerInfo(bannerData.find(obj => obj.formFor === urlSlug));
			if (urlSlug.includes("-")) {
				title = `${urlSlug.split('-')[0]} ${(urlSlug.split('-')[1])}`;
			}
			setTitleHeader(title);
		} else {
			props.history.goBack();
		}
	}, [props.match.params.slug, props.history]);

	const back = () => props.history.goBack();

	return (
		<div className="portalsAndAuxillaryPage">
			<Layout newHeader={true}>
				<PageTitleHeader
					isArrow={true}
					onClick={() => back()}
					title={`for ${titleHeader}`}
				/>
				
				<section className="portalsAndAuxillaryPage-container">
					<Container fluid>
						<Row className="portalsAndAuxillaryPage-banner">
							<img src={require("../../_assets/images/portals-and-auxillary/banner-realtors.jpg")} alt="" className="d-xl-none d-lg-none" style={{ width: "100%" }}></img>
							<MainBanner formFor={bannerInfo.formFor} formTitle={bannerInfo.formTitle} setFormSubmitPop={(data) => setFormSubmitPop(data)} />
						</Row>
					</Container>					
					<div className="portalsAndAuxillaryPage-leaveItToThePros ">
						<Container>
							<h3 className="smallHead">leave it to the pros</h3>
							<h2 className="bigHead">sit back and collect rent while we manage the rest</h2>
							{leaveItToTheProsData &&
								(leaveItToTheProsData.length > 0) ?
								(<Row>
									{leaveItToTheProsData.map(littp => {
										return <LeaveItToThePros key={littp.id} icon={littp.icon} title={littp.title} description={littp.description} />
									})}
								</Row>)
								: <Loader />
							}
						</Container>
					</div>
					<div className="portalsAndAuxillaryPage-testimonials">
						<Container>
							{testimonialsData &&
								(testimonialsData.length > 0) ?
								(<Row>
									{testimonialsData.map(testimonial => {
										return <Testimonials key={testimonial.id} userImage={testimonial.userImage} testimonial={testimonial.testimonial} name={testimonial.name} userType={titleHeader.slice(0, -1)} />
									})}
								</Row>)
								: <Loader />
							}
						</Container>
					</div>
				</section>
			</Layout>

			<ModalBox
				show={formSubmitPop}
				onHide={() => setFormSubmitPop(false)}
				size='sm'
				actionbuttontitle={'got it'}
				buttonaction={() => setFormSubmitPop(false)}
			>
				<h4>Your request has been submitted successfully!</h4>
			</ModalBox>
		</div >
	)
}

export default withRouter(PortalsAndAuxillary);
