import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton, DatePickerComponent, AutoCompleteAddress, CostNumberFormat } from '../../../components'
import { Form, Row, Col, Image } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { is_ownedData } from '../../../../Constants/tenantDetails'
import { logger, toaster } from '../../../../Utils'
import { postAPI } from '../../../../Api';
import { PastHomeInfoValidationSchema } from './ValidationSchema/PastHomeInfoValidationSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { dateSanitizer } from '../../../../Utils/DateSanitizer/DateSanitizer';
import Cookies from 'js-cookie';
import './style.scss';
import { Numbering } from '../../../../Utils/Numbering/Numbering';
import { RentedHomePastValidation } from './ValidationSchema/RentedHomePastValidation';
import { numberFormatSanitizerInt, phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import { updateProfile, validatePastHomes } from './helper';

const log = logger('PastHomeInfo');
const PastHomeInfo = (props) => {
  const { past_home, isShow, handleClose, setEmploymentInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [submitted, setSubmitted] = useState(false)
  let submitStatus = false
  const [past_home_info_state, setPast_home_info_state] =
    useState([
      {
        id: 0,
        full_address: '',
        unit_no: '',
        city: '',
        province: '',
        country: '',
        postal_code: '',
        latitude: 34.059849,
        longitude: -118.455551,
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
        is_owned: 0,
        landlord_name: '',
        landlord_phone: '',
        landlord_email: '',
        monthly_rent: ''
      }
    ]);
  const [errors,setErrors]=useState({})
  useEffect(() => {
    if (past_home) {
      // setPast_home_info_state([{...past_home, end_date: moment(new Date()).format('YY-MM-DD')}])
      const newPastHomeData = past_home.map((data,i) => {
        if(!data.end_date){
          return(
            {...data, end_date: moment(new Date()).format('YYYY-MM-DD')}
          )
        }else{
          return(
            {...data}
          )
        }
      })
      setPast_home_info_state(newPastHomeData)
    }
    setSubmitted(false)
  }, [past_home])

  const addMore = () => {
    const today_date = moment(new Date()).format('YYYY-MM-DD');
    const data = {
      id: 0,
      full_address: '',
      unit_no: '',
      start_date: today_date,
      end_date: today_date,
      is_owned: 0
    }
    setPast_home_info_state(past_home_info_state => [...past_home_info_state, data])
  }

  const OnChangeInputArray = (event, index) => {
    let items = [...past_home_info_state];
    let item = { ...items[index] };
    const name = event.target.name ? event.target.name : event.target.getAttribute('name')
    if(name === 'monthly_rent'){
      item[name] = numberFormatSanitizerInt(event.target.value)
      items[index] = item;
      setPast_home_info_state(items);
    }else if(event.target.name === 'landlord_phone'){
      item[name] = phoneNumberSanitizer(event.target.value)
      items[index] = item;
      setPast_home_info_state(items);
    }else if(event.target.name === 'full_address'){
      item[name] = event.target.value;
      item['latitude'] = null;
      item['longitude'] = null;
      items[index] = item;
      setPast_home_info_state(items);
    }else{
      item[name] = event.target.value;
      items[index] = item;
      setPast_home_info_state(items);
    }
    submitted && setTimeout(()=>{
      validate(item.is_owned ? PastHomeInfoValidationSchema : RentedHomePastValidation)
      const {errors}=validatePastHomes(past_home_info_state);
      setErrors(errors)
    },300)

  }

  const OnChangeDate = (name, date, index) => {
    const dateValid = dateSanitizer(date);
    // console.log()
    log.info(name)
    let items = [...past_home_info_state];
    let item = { ...items[index] };
    item[name] = moment(dateValid).format('YYYY-MM-DD');
    items[index] = item;
    setPast_home_info_state(items);
  }

  const OnRemove = (index) => {
    if (index === 'all') {
      setPast_home_info_state([]);
    } else {
      let items = [...past_home_info_state];
      items.splice(index, 1);
      setPast_home_info_state(items);
    }
  }

  const onSubmit = () => {
    setSubmitted(true)
    let url = '';
    if (user_id) {
      url = '/admin/users/tenant/past-home/' + user_id;
    } else {
      url = '/tenant/profile/past-home';
    }
    let pastHomeData = []
      past_home_info_state.forEach((data, i) => {
      if (validate(PastHomeInfoValidationSchema) && data.is_owned) {
        submitStatus = true
        const newArr=[...past_home_info_state]
        delete newArr[i].landlord_email
        delete newArr[i].landlord_phone
        delete newArr[i].landlord_name

        pastHomeData.push([...newArr]);
      }else if(!data.is_owned && validate(RentedHomePastValidation)){
        submitStatus = true
        pastHomeData.push([...past_home_info_state])
      }
    })
    
    const {isValid,errors}=validatePastHomes(past_home_info_state);
    setErrors(errors)
    submitStatus && isValid && postData(url, pastHomeData[0])
    
    submitStatus = false
  }

  const postData = (url, data) => {
    updateProfile(url, data)
      .then(response => {
        if (response.status === 200) {
          handleClose()
          if (past_home_info_state[0].id===0) {
            setEmploymentInfo(true)
          }
          toaster('success', response.data.message)
          return
        }
      })
      .catch((error) => {
        toaster('error', 'Something went wrong')
      })
  }

  const handlePlaceChanged = (place, index) => {
    const address = place.formatted_address;
    let items = [...past_home_info_state];
    let item = { ...items[index] };
    items[index] = item;
    item['full_address'] = address;

    const addressArray = place.address_components;
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
        item['city'] = addressArray[i].long_name
      } else if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
        item['province'] = addressArray[i].long_name
      } else if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
        item['country'] = addressArray[i].long_name
      } else if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
        item['postal_code'] = addressArray[i].long_name
      }
    }

    item['latitude'] = place.geometry.location.lat()
    item['longitude'] = place.geometry.location.lng()
    setPast_home_info_state(items);
  }
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Tell Us About Your Past Homes' : 'Past Address'}>

      <div className='sidepanel-body'>
        <Form id='Form'>
          {past_home_info_state ?
            past_home_info_state.map((data, i) => {
              return (
                <div key={'past_info_' + i}>
                  {i > 0 ?
                    <>
                      <hr />
                      <div className='sub-title d-flex justify-content-between align-items-center'>
                        <h3>{ Numbering(i + 1) + ' Past Address'}</h3>
                        <Image
                          className='icon'
                          width='20px'
                          height='20px'
                          title='Remove'
                          onClick={() => OnRemove(i)}
                          src={require('../../../../_assets/images/delete-icon.svg')}
                        />
                      </div>
                    </> : ''}

                  <Row>
                    <Form.Group as={Col} md='12'
                       
                      >
                      <AutoCompleteAddress
                        md='12'
                        as={Col}
                        id={'full_address' + i}
                        name={'full_address'}
                        state={data.full_address}
                        updateField={(e) => OnChangeInputArray(e, i)}
                        handlePlaceChanged={(data) => handlePlaceChanged(data, i)}
                        addressTitle='Street Number & Name'
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Unit Number (If applicable)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Unit 111'
                        name='unit_no'
                        id={'unit_no' + i}
                        value={data.unit_no ? data.unit_no : ''}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">When did you live here?</Form.Label>
                        <Row>
                          <Col>
                            <DatePickerComponent
                              id={'start_date' + i}
                              name={'past_start_date'}
                              format={'dd/MM/yyyy'}
                              value={data.start_date !== '' && moment(data.start_date).toDate()}
                              handleDateChange={(date) => OnChangeDate('start_date', date, i)}
                              maxDate={new Date()}
                              displayText={'Select Date'}
                              popperPlacement='bottom-start'
                            />
                          </Col>
                    
                          <Col>
                            <DatePickerComponent
                              id={'end_date' + i}
                              name={'past_end_date'}
                              format={'dd/MM/yyyy'}
                              value={(data.end_date !== '' || data.end_date) && moment(data.end_date).toDate()}
                              handleDateChange={(date) => OnChangeDate('end_date', date, i)}
                              maxDate={new Date()}
                              minDate={new Date(data.start_date)}
                              displayText={'Select Date'}
                              popperPlacement='bottom-end'
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Did you own or rent this place?</Form.Label>
                      <ul className='select-options'>
                        {is_ownedData.map((ownedData) =>
                          <li onClick={(e) => OnChangeInputArray(e, i)} key={'is_owned_' + ownedData.value} value={ownedData.value} name='is_owned'
                            className={(data.is_owned === ownedData.value) ? 'active' : ''}>{ownedData.title}</li>)}
                      </ul>
                    </Form.Group>
                  </Row>
                  { !data.is_owned && 
                    <>
                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">Monthly Rent</Form.Label>
                          <CostNumberFormat
                            name={'monthly_rent'}
                            id={'monthly_rent'+i+100}
                            classname='form-control'
                            // value={monthly_rent ? monthly_rent : ''}
                            value={data.monthly_rent ? data.monthly_rent : ''}
                            updateField={(e) => OnChangeInputArray(e, i)}
                            pre='$'
                            placeholder={'$1,200'}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">Landlord Name</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Name'
                            name='landlord_name'
                            id={'landlord_name'+i+100}
                            // value={landlord_name ? landlord_name : ''}
                            value={data.landlord_name}
                            // updateField={(e) => OnChangeInputArray(e, i)}
                            onChange={(e) => OnChangeInputArray(e, i)}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">Landlord Email</Form.Label>
                          <Form.Control
                            type='email'
                            isInvalid={!!errors[i]?.landlord_email}
                            placeholder='Enter Email'
                            name='landlord_email'
                            id={'landlord_email'+i+100}
                            // value={landlord_email ? landlord_email : ''}
                            value={data.landlord_email}
                            // updateField={(e) => OnChangeInputArray(e, i)}
                            onChange={(e) => OnChangeInputArray(e, i)}
                          />
                          <span className='invalid-feedback'>
                          {errors[i]?.landlord_email}
                          </span>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">Landlord Phone</Form.Label>
                          <NumberFormat
                            format='###-###-####'
                            mask='_'
                            placeholder='Enter Phone Number'
                            name='landlord_phone'
                            id={'landlord_phone_current_form'+i+100}
                            className={`form-control ${!!errors[i]?.landlord_phone ? 'is-invalid':''}`}
                            // value={landlord_phone ? landlord_phone : ''}
                            value={data.landlord_phone}
                            onChange={(e) => OnChangeInputArray(e, i)}
                          />
                           <span className='invalid-feedback'>
                          {errors[i]?.landlord_phone}
                          </span>
                        </Form.Group>
                      </Row>
                    </>
                    }
                </div>)
            }) : ''}
        </Form>

        <Row className='d-flex justify-content-center'>
          <Col md='10'>
            <LargeButton
              variant='dark'
              title='add more'
              isIcon={true}
              onClick={() => addMore()} />
            <p style={{ textAlign: 'center' }}>Add up to 5 years of residential history</p>
          </Col>
        </Row>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton  onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton  onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default PastHomeInfo;