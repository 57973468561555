import React from "react";
import ContentLoader from 'react-content-loader';

const CauraselContentLoader = (props) => {
    return (
        <div className="caurousel-struct">
            <ContentLoader height={420} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                <rect x="0" y="17" rx="0" ry="0" width="100%" height="374" />
            </ContentLoader>
        </div>
    )
}
export default CauraselContentLoader;