import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image } from 'react-bootstrap';
import { Nav } from "react-bootstrap";
import "./style.scss";

class JombotronSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="sec-jombotron">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 jumbotron-left">
                <div className="text-center mb-4 d-lg-none">
                  <Image src={require("../../../../_assets/images/landing-pages/landlord-leasing-house.png")} alt="Banner" />                  
                </div>
                <h2 className="jombotron-title text-left">let us find<br/> you a tenant.
                </h2>
                <p className="jombotron-disc-title">As a landlord, finding the right tenant can be a stressful and time-consuming process. Our team of pros will handle everything from listing to lease, so you get the best tenants and top market rent, without any of the headaches. </p>
              </div>
              <div className="col-lg-6 jumbotron-right">
                <div className="text-center d-none d-lg-block">
                  <Image src={require("../../../../_assets/images/landing-pages/landlord-leasing-house.png")} alt="Banner" />                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(JombotronSection);
