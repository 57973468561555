import { getAPI } from "../../Api";
import toaster from "../toaster";

export const getCrtnPDFReport = async (tenant_id, conversation_id) => {
  const url = `/landlord/check-certn-report/${tenant_id}/${conversation_id}`;
  const response = await getAPI(url);
  if (response.status !== 200) {
    toaster("error", response.response.data.message);
    return false
  } else if (response.status === 200) {
    //log.info("response",response.data.data.report_url);
    window.open(response.data.data.report_url);
    return true;
  }
};
