import moment from "moment";
import logger from "../Logger/Logger";

const log = logger("dateSanitizer");
export const dateSanitizer = (date) => {
    log.info("sanitizer", date);
    if (date === null || date === "Invalid Date") {
        return '';
    } else {
        return date;
    }
}

export const dateFormat = (date) => {
    if (date === "") {
        return date;
    } else {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
    }
}
export const dateFormat2 = (date) => {
    if (date === "") {
        return date;
    } else {
        const formattedDate = moment(date).format("ll");
        return formattedDate;
    }
}
export const dateFormat3 = (date) => {
    if (date === "") {
        return date;
    } else {
        const formattedDate = moment(date).format("LL");
        return formattedDate;
    }
}

export const timeFormat = (time) => {
    if (time === "") {
        return time;
    } else {
        const formattedTime = moment(time).format("hh:mm:ss a");
        return formattedTime;
    }
}

export const emptyLeadingSpaceSanitizer = (value) => {
    if (value === " ") {
        return value.replace(/^\s*/, "");
    } else {
        return value;
    }
}

export const timeLeft = (days) => {
    let timeLeftVar = '';
    if (days > 30) {
        let months = Math.round(days / 30);
        if (months > 1) {
            timeLeftVar = `${months} months left`;
        } else {
            timeLeftVar = `${months} month left`;
        }
    } else {
        if (days > 1) {
            timeLeftVar = `${days} days left`;
        } else {
            timeLeftVar = `${days} day left`;
        }
    }
    return timeLeftVar;
}