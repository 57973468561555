export const InquiryValidationSchema = {
    contact_full_name: [
        { rule: "required", message: "full name required" }
    ],
    contact_number: [
        { rule: "required", message: "contact number required" },
    ],
    contact_email: [
        { rule: "required", message: "email required" },
    ],
    full_property_address: [
        { rule: "required", message: "address required" },
    ],
    property_status: [
        { rule: "required", message: "property status required" },
    ],
    contact_day: [
        { rule: "required", message: "day required" },
    ],
    contact_time: [
        { rule: "required", message: "time required" },
    ],
}