export const N2Schema = {
    date: [
        { rule: "required", message: "Date required" },
    ],
    rent: [
        { rule: "required", message: "rent required" },
    ],      
    signingName: [
        { rule: "required", message: "signing name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}