import React from 'react';
import "./style.scss"
// import { SidePanel } from "../../../App/components"
import {Image} from "react-bootstrap";
import closeIcon from "../../../_assets/images/homepage/sideModalIcons/closeIcon.svg";
import bestInClass from "../../../_assets/images/homepage/sideModalIcons/bestInClassSide.png";
import createLeaseSide from "../../../_assets/images/homepage/sideModalIcons/createLeaseSide.png"
import proMarketing from "../../../_assets/images/homepage/sideModalIcons/proMarkertingSide.png";
import rentIconSide from "../../../_assets/images/homepage/sideModalIcons/rentIconSideModal.png";
import manageShowing from "../../../_assets/images/homepage/sideModalIcons/manageShowingSide.png";
import tenantHandOff from "../../../_assets/images/homepage/sideModalIcons/tenantHandOffSide.png"

const SidePanel = (props) => {
    const { title, subTitle, children, isShow, isHide, extraClasses, headerClass } = props
    return (<>
        <div className={extraClasses ? extraClasses + ' side-panel' : 'side-panel'} style={{ visibility: isShow ? 'visible' : 'hidden', right: isShow ? "0px" : "-450px" }}>
            <header className={headerClass}>
                <Image className="close-icon" title="close" onClick={isHide} src={closeIcon} alt="close" />
                <h3 className="text-left mr-2">close</h3>
            </header>
            {title && <p className="sideModalTitle">{title}</p>}

            {subTitle && <p className='edit-sub'>{subTitle}</p>}
            {children}
        </div>
        <div className='overlay-layer' onClick={isHide} style={{ display: isShow ? 'block' : 'none' }}></div>
    </>);
}

const SideHowItWorks = ({show, handleClose, openRentForm}) => {
    return (
        <SidePanel
            extraClasses={"sideHowItWorks"}
            isShow={show}
            isHide={handleClose}
            title={"leasing"}
            headerClass={"sideHowItWorksHeader"}
            subTitle={"As a landlord, finding the right tenant can be a stressful and time-consuming process. Our team of pros will handle everything from listing to lease, so you get the best tenants and top market rent, without any of the headaches."}
        >
            <div className="sideHowSubHeader">
                check out how it works
            </div>

            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={rentIconSide} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">property&nbsp;&amp;&nbsp;rent assessment</h5>
                        <p className="boxDesc">Even before you sign on the dotted line, get an inspection and rent assessment, so you know what to charge and who will be the perfect tenant for your rental.  </p>
                    </div>
                </div>
            </div>
            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={manageShowing} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">manage showings</h5>
                        <p className="boxDesc">We manage all of the showings, with pre-qualified tenants, so you don&#39;t have to worry.</p>
                    </div>
                </div>
            </div>
            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={createLeaseSide} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">lease creation</h5>
                        <p className="boxDesc">We craft the lease with a unique lease appendix for your property, to make sure your investment is secure. </p>
                    </div>
                </div>
            </div>
            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={proMarketing} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">professional marketing</h5>
                        <p className="boxDesc">High quality ads are crafted with pro photos and 360 videos, and then listed on all the best sites to attract the right tenants. </p>
                    </div>
                </div>
            </div>
            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={bestInClass} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">best-in-class screening</h5>
                        <p className="boxDesc">Credit, background and reference checks are done, before top tenants are presented. </p>
                    </div>
                </div>
            </div>
            <div className="sideBoxContainer">
                <div className="sideBoxItem">
                    <div className="boxLeft">
                        <Image src={tenantHandOff} alt="Banner" />
                    </div>
                    <div className=" boxRight">
                        <h5 className="h-list-box-title">tenant handoff</h5>
                        <p className="boxDesc">Once the lease is signed, a handoff is done to make sure everyone knows next steps, and you get your rent right away. </p>
                    </div>
                </div>
            </div>

            <div className={"sideCtaCont"}>
                <button  className="ctaBtnSide"
                    onClick={() => {
                        openRentForm()
                        handleClose()
                    }}
                >
                    connect with us
                </button>
            </div>

        </SidePanel>
    );
};

export default SideHowItWorks;