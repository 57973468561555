export const FreeRentAssessmentValidationScheme = {
    fullName: [
        { rule: "required", message: "Full Name is required" },
        { rule: "fullName", message: "Please enter a full name" },
    ],
    date: [
        { rule: "required", message: "A date for call back is required" },
        { rule: "date", message: "Please enter a date for call back" },
    ],
    assessment_time: [
        { rule: "required", message: "An assessment time is required" },
        { rule: "date", message: "Please enter an assessment time" },
    ],
    email: [
        { rule: "required", message: "An email is required" },
        { rule: "email", message: "Please enter an email" },
    ],
    phone_number_free: [
        { rule: "required", message: "Phone number is required" },
        { rule: "phone", length: 10, message: "Phone number must be of 10 digits" },
    ],
    address: [
        { rule: "required", message: "An address is required" },
        { rule: "address", message: "Please enter an address" },
    ],
}