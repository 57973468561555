import { logger } from '../Utils'

const log = logger('colorStatus');
export const colorStatus = (status) => {
    log.info(status)
    if (status === 'In progress') {
        return 'progressStatus'
    } else if (status === 'For Rent') {
        return 'for-rent'
    } else if (status === 'Rented') {
        return 'rented'
    } else if (status === 'Verified' || status === 'verified') {
        return 'verified'
    } else if (status === 'pending approval' || status === 'Needs Approval') {
        return 'pendingApproval'
    } else if (status === 'approved' || status === 'Approved') {
        return 'approved'
    } else if (status === 'Deactivated') {
        return 'deactivated'
    }
}

export const colorStatusShowings = (status) => {
    if (status === 'Confirmed') {
        return 'confirmed-showing'
    } else if (status === 'Pending') {
        return 'pending-showing'
    }
}

export const colorStatusLease = (status) => {
    if (status === 'Delivered') {
        return 'approved'
    } else if (status === 'In progress') {
        return 'progressStatus'
    } else if (status === 'Pending') {
        return 'pending-showing'
    } else if (status === 'Signed') {
        return 'confirmed-showing'
    }
}
export const colorStatusMaintain = (status) => {
    if (status === 'Pending') {
        return 'pending-showing'
    } else if (status === 'Completed') {
        return 'approved'
    } else if (status === 'Cancelled') {
        return 'pendingApproval'
    }
}
export const colorStatusInspection = (status) => {
    if (status === 'Pending') {
        return 'pending-showing'
    } else if (status === 'In Progress') {
        return 'progressStatus'
    } else if (status === 'Completed') {
        return 'approved'
    }
}
export const colorNotification = (status) => {
    // if (status === 'In Progress') {
    //     return 'progressStatus'
    // } else
    if (status === 'Delivered') {
        return 'approved'
    } else if (status === 'Signed' || status === 'Completed' || status === 'In Progress') {
        return 'approved'
    } else if (status === 'Needs Approval') { //TBC
        return 'pendingApproval'
    }
}
export const colorRequest = (status) => {
    if (status === 'Needs Approval') {
        return 'pendingApproval'
    } else if (status === 'Completed') {
        return 'approved'
    } else if (status === 'needs attention') {
        return 'pendingApproval'
    }
}