import React from 'react';
import { Image, Col } from 'react-bootstrap';

const ErrorPage = (props) => {

    return (
        <div>
            <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                <Image src={require("../../../_assets/images/no-data.svg")} />
                <p style={{ fontSize: '40px', color: '#979797', textAlign:'center' }}>Please try to refresh your browser and if the problem continues, email us at hello@rentpanda.ca.</p>
            </Col>
        </div>
    )
}

export default ErrorPage;