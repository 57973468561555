import React from "react";
import { useSelector } from 'react-redux'
import { Container, Row, Col } from "react-bootstrap";
import { ProfileCard } from "../../../components";
import Cookies from 'js-cookie';

const RoommateInfoDisplay = (props) => {
  const { editaction } = props;
  const role = Number(Cookies.get('role'));
  const roommateInfo = useSelector(state => state.UserProfileReducer.userProfile.roommate_info) ||   props?.profileDetails?.roommate_info

  return (
    <ProfileCard
      isComplete={roommateInfo?.roommate_status !==null}
      title={role !== 1 ? "Roommate Info" : 'Roommates'}
      editaction={editaction}>

      <Container fluid>
        {roommateInfo && roommateInfo.roommate_status === null ?
          <Row>
            <Col md='12' className="d-flex justify-content-center">
              <label>No Information Provided</label>
            </Col>
          </Row>
          :
          roommateInfo && roommateInfo.roommate_info && roommateInfo.roommate_status === 1 ?
            <>
              <Row>
                <Col md='12'>
                  <label>{role !== 1 ? 'Name and contact info' : 'Name'}</label>
                </Col>
              </Row>
              {roommateInfo.roommate_info.map((data, i) => {
                return (
                  <Row key={'roommates_' + i} className="mb-2">
                    <Col md='2'>
                      <h3>{data.name}</h3>
                    </Col>
                    <Col md='5'>
                      <h3 className="highlight">{data.email}</h3>
                    </Col>
                  </Row>
                )
              })}
            </> :
            <Row>
              <Col md='12' className="d-flex justify-content-center">
                <label>{role === 3 ? 'You have' : 'Tenant has'} no roommates</label>
              </Col>
            </Row>}
      </Container>
    </ProfileCard>
  );
}

export default RoommateInfoDisplay;