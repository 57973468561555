import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Accordion, Card, Button, Tab, Row, Nav, Image } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { getAPI } from '../../../Api';
import { Loader } from '../../../App/components';
import { logger } from '../../../Utils';
import Cookies from 'js-cookie';

import '../style.scss';

const log = logger("HowItWorks");
const HowItWorks = (props) => {
    const [faqList, setFaqList] = useState([]);
    const [accordianId, setAccordionId] = useState(null);
    const isLandlord = Cookies.get('access_token') && parseInt(Cookies.get('role')) === 2;
    const [isLoading, setIsLoading] = useState(false);

    const clickExterior = (id) => {
        const check = id === accordianId ? null : id
        setAccordionId(check);
    }
    useEffect(() => {
        setIsLoading(true);
        getAPI('/cms/faq/list?category=3').then(res => {
            if (res.status === 200) {
                setFaqList(res.data.data);
            }
            setIsLoading(false);
            log.info("/cms/faq/list > res ", res);
        })
    }, [])

    return (
        <div className="howItWorksPage">
            <Layout newHeader={true}>
                <div className="cu-tabbing-wrap">                
                    <section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                        <div className="container">
                            <h1 className="website-page-title text-center">rent panda has a few different ways to rent</h1>
                        </div>
                    </section>
                    <div className="position-relative">
                        <Container>                    
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">do it yourself</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">let us find you a tenant</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">let us manage your property</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h2 className="title">do it yourself tools to make renting simple</h2>
                                        <div className="discrip text-center">Cost:<b> a la carte (free)</b></div>
                                        <Row>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-1.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">post unlimited rentals</h5>
                                                        <div className="h-list-box-disc">We don't limit how many listings you have at once so you can manage all your rental properties in one place, easily.</div>
                                                    </div>
                                                </div>
                                            </div>    
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-2.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">build sign and store leases</h5>
                                                        <div className="h-list-box-disc">No more paper leases or hard to use government forms with our online lease builder, which gives you access to a best in class lease appendix to protect your property.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-3.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">get your ad on kijiji, facebook and top spots ($)</h5>
                                                        <div className="h-list-box-disc">With one click you can share your listings across the main platforms and get more tenants coming your way.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-4.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">collect rent online (coming soon)</h5>
                                                        <div className="h-list-box-disc">Online rent collection with automatic reminders, arrears notifications and credit bureau reporting is the next best tool coming your way in summer 2021.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-5.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">access tenant profiles</h5>
                                                        <div className="h-list-box-disc">From the first time an applicant messages, you can access basic info about a tenant including employment, tenant history, pet info, vehicle info and more.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-6.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">conduct move-in/out inspections</h5>
                                                        <div className="h-list-box-disc">When a tenant moves in and out, use this tool to take and store pictures, statuses and notes on every bit of your rental property, so everything is documented and signed by you and your tenant.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            {/* 7 */}
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-7.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">schedule showings online</h5>
                                                        <div className="h-list-box-disc">Once you’ve narrowed down your choices of tenants from their profiles, you can schedule showings online, keeping track of them all in one consolidated calendar.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-8.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">get repair requests from tenants</h5>
                                                        <div className="h-list-box-disc">Let your tenants send you repair requests, with detailed notes and pictures, instead of those late night texts and calls. Keep track of issues easily, so the headaches melt away.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 9 */}
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-9.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">get instant credit &amp; background checks ($)</h5>
                                                        <div className="h-list-box-disc">With a tenant’s consent you can instantly pull current credit, criminal history checks and a social media analysis so you can get the full picture of your future tenant.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-10.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">serve notices to tenants</h5>
                                                        <div className="h-list-box-disc">Whether its a notice of entry or an N4 for unpaid rent, the notice builder easily fills our detailed government forms and sends them to your tenant instantly and easily.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-11.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">repost your ad with a single click</h5>
                                                        <div className="h-list-box-disc">No need to ever input information and pictures all over again. When your tenant gives notice, you can have your ad posted and new tenants coming your way within minutes.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        {isLandlord ? <div className="col-12 my-4 py-4">&nbsp;</div> :
                                            <Button href="/signup/landlord" className="tabbing-lastBtn" id="BTN-landord-HIW-lets-get-started-1" data-description="Visitor chose do it yourself let's get started">
                                                let's get started
                                            </Button>
                                        }
                                    </Tab.Pane>
                                    {/* Tab-2 */}
                                    <Tab.Pane eventKey="second">
                                        <h2 className="title">let us find you the perfect tenant.</h2>
                                        <div className="discrip text-center">Cost:<b> 1 month rent</b></div>
                                        <Row>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-1.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">property &amp; rent assessment</h5>
                                                        <div className="h-list-box-disc">Even before you sign on the dotted line we will do a free inspection of the property and give you a full assessment to figure out rent prices and ideal tenant profiles.</div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-2.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">conduct showings</h5>
                                                        <div className="h-list-box-disc">Qualified applicants can schedule showings at their convenience and our team will manage all in person and digital walkthroughs.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-3.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">photos, videos &amp; listing creation</h5>
                                                        <div className="h-list-box-disc">We take professional photos, 360 videos and craft the best ads to bring the perfect tenant into your property.</div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-4.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">lease creation</h5>
                                                        <div className="h-list-box-disc">We prepare the lease agreement for you, with our proprietary lease appendix, to make sure that your investment is secure and protected.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-5.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">applicant interviews</h5>
                                                        <div className="h-list-box-disc">Prior to booking any showings we interview applicants and collect all their basic information, to make sure only the best and most interested applicants come to showings.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-6.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">property promotion</h5>
                                                        <div className="h-list-box-disc">We promote your property across multiple platforms to ensure we get all the best potential tenants coming your way.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-7.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">credit, background and reference checks</h5>
                                                        <div className="h-list-box-disc">With best-in-class screening tools we check an applicant’s credit, criminal history, social media, confirm their employment, past tenancy, ID verification and call references.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-8.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">tenant handoff</h5>
                                                        <div className="h-list-box-disc">Once the lease and appendix has been signed we schedule a time to handoff your tenant to you, making sure everyone has the info they need to start an amazing tenancy.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <Button href="/signup/landlord" className="tabbing-lastBtn">
                                            let's get started
                                        </Button>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <h2 className="title">let us manage your property</h2>
                                        <div className="discrip text-center">Cost:<b> 9% monthly rent</b></div>
                                        <Row>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t2-1.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">property &amp; rent assessment</h5>
                                                        <div className="h-list-box-disc">Even before you sign on the dotted line we will do a free inspection of the property and give you a full assessment to figure out rent prices and ideal tenant profiles</div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-4.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">collect rent</h5>
                                                        <div className="h-list-box-disc">Direct deposit is set up for all of your tenants, approved maintenance payments are paid automatically and rent is deposited right into your account.</div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-3.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">preparing the property</h5>
                                                        <div className="h-list-box-disc">We will repair and manage upgrades to make sure your place is ready to be rented and you can get top dollar rent.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-4.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">tenant management</h5>
                                                        <div className="h-list-box-disc">Don’t worry about the day-to-day calls from your tenant. We take care of everything so you can focus on the things that matter most in your life.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-5.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">photos, videos &amp; listing creation</h5>
                                                        <div className="h-list-box-disc">We take professional photos, 360 videos and craft the best ads to bring the perfect tenant into your property.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            {/* <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-6.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">contractor &amp; trade management</h5>
                                                        <div className="h-list-box-disc">From small upgrades to complete renos, we bring in the best team, get you preferred pricing and manage the job from start to finish.</div>
                                                    </div>
                                                </div>
                                            </div>  */}
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-7.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">applicant screening</h5>
                                                        <div className="h-list-box-disc">We use best-in-class screening tools to check an applicant’s credit, background, criminal history, social media and confirm past employment and tenancy.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-8.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">legal notices &amp; evictions</h5>
                                                        <div className="h-list-box-disc">Our experienced managers, strict process and legal partners make sure that the proper steps are taken to keep your property protected and your unit tenanted.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-9.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">conduct showings</h5>
                                                        <div className="h-list-box-disc">Interested applicants can schedule showings at their convenience and our team will manage all visits and applications.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-10.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">maintenance &amp; repairs</h5>
                                                        <div className="h-list-box-disc">Snow removal, lawns and all repairs are taken care of, so you’ve got peace of mind 365 days a year.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-11.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">lease creation</h5>
                                                        <div className="h-list-box-disc">We prepare the lease agreement for you, with our proprietary lease appendix, to make sure that your investment is secure and protected.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-12.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">move in/out inspections</h5>
                                                        <div className="h-list-box-disc">We perform bi-annual, as well as move in/move out inspections and provide detailed reports so everything is documented from start to finish.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-13.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">onboarding tenants</h5>
                                                        <div className="h-list-box-disc">A smooth transition is key to make the tenant feel comfortable, communication flow easily and rent comes through uninterrupted.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../_assets/images/how-it-works/how-it-works-landlord-t3-14.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">owner statements</h5>
                                                        <div className="h-list-box-disc">With us you’ll get monthly reports and on-demand owner statements, so you are always in the know.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <Button href="/signup/landlord" className="tabbing-lastBtn">
                                            let's get started
                                        </Button>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>                            
                        </Container>
                    </div>                    
                </div>
                <section className="sec-faq cu-container-wrap">
                    <div className="container">
                        <h5 className="faq-title-text f-secondary-b text-center">frequently asked questions</h5>
                        <div className="faqsContainer">
                            {faqList && <Accordion defaultActiveKey={faqList.length !== 0 && faqList[0].title}>
                                {faqList.length > 0 ?
                                    <>
                                        {faqList.map((faq, i) => {
                                            return <Card className='mb-3' key={'faq_' + i}>
                                                <Card.Header className='d-flex justify-content-between'>
                                                    <div className='accordian-heading'>{faq.title}</div>
                                                    <Accordion.Toggle as={Button} className='inspection-btn' variant="link" eventKey={faq.title} onClick={() => clickExterior(faq.id)}>
                                                        <img className="next-arrow"
                                                            src={accordianId === faq.id
                                                            ? require('../../../_assets/images/faq-arrow2.svg')
                                                            : require('../../../_assets/images/faq-arrow1.svg')}
                                                            alt="next" />
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={faq.title}>
                                                    <Card.Body className='section-card ql-editor' dangerouslySetInnerHTML={{__html: faq.description}}></Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        })}
                                    </>
                                    : isLoading ? <Loader /> : <h2 style={{fontSize: "1.5rem"}}>No FAQs Available</h2>}
                                </Accordion>
                            }
                        </div>                    
                    </div>
                </section>            

            </Layout>
        </div>
    )
}

export default withRouter(HowItWorks);
