import {
    SET_SEARCH_LOCATION,
    SET_PROPERTY_LISTS,
    SET_LOCATION_LIST,
    SET_SEARCH_FILTER,
} from '../actions/LandingPageAction';

export const initialState = {
    getSearchLocation: '',
    getListData: '',
    getLocationList: [],
    getSearchFilter: null,
}

export default function LandingPageReducer(state = initialState, action) {
    const handlers = {
        [SET_SEARCH_LOCATION]: (state) => ({
            ...state,
            getSearchLocation: action.getSearchLocation,
        }),
        [SET_SEARCH_FILTER]: (state) => ({
            ...state,
            getSearchFilter: action.getSearchFilter,
        }),
        [SET_PROPERTY_LISTS]: (state) => ({
            ...state,
            getListData: action.getListData,
        }),
        [SET_LOCATION_LIST]: (state) => ({
            ...state,
            getLocationList: action.getLocationList,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}
