import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N8Schema } from "./ValidationSchema/N8Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const N8Form = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    getParalegal,
    triggerReqTenant,
    onBack, 
    onNoticeDataUpdate
  } = props;
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [primaryTime, setPrimaryTime] = useState(null);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [reason1, setReason1] = useState(false);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);
  const [reason4, setReason4] = useState(false);
  const [reason5, setReason5] = useState(false);
  const [details, setDetails] = useState("");
  const [globalState,setGlobalState]=useGlobalState()


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])



  useEffect(()=>{

    onNoticeDataUpdate({
      tenant_move_out: terminationDate,
      end_tenancy_reason_1: reason1 ? 1 : 0,
      end_tenancy_reason_2: reason2 ? 1 : 0,
      end_tenancy_reason_3: reason3 ? 1 : 0,
      end_tenancy_reason_4: reason4 ? 1 : 0,
      end_tenancy_reason_5: reason5 ? 1 : 0,
      notice_reason_details: details,
      signData,
      notice_type:8
    })
    setGlobalState({ onSubmit: onSubmit })

  },[
    tenant_id.length,
    property_id,
    terminationDate,
    signData,
    terminationDate,phoneError,reason1,reason3,reason4,reason5,details
  ])
  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N8Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    )  {
    const url = "/landlord/notice/store-notice";
    const data = {
      property_id: property_id,
      property_detail_id: property_detail_id,
      notice_type: 8,
      landlord_id: 1,
      tenant_move_out: terminationDate,
      end_tenancy_reason_1: reason1 ? 1 : 0,
      end_tenancy_reason_2: reason2 ? 1 : 0,
      end_tenancy_reason_3: reason3 ? 1 : 0,
      end_tenancy_reason_4: reason4 ? 1 : 0,
      end_tenancy_reason_5: reason5 ? 1 : 0,
      notice_reason_details: details,
      agree_terms_and_conditions: 1,
      first_name: signData && signData?.firstName,
      last_name: signData && signData?.lastName,
      phone_number: signData && signData?.phoneNumber,
      date: signData && signData?.signDate,
      landlord_signature: signData && signData?.full_name_sign,
      tenants: tenant_id,
      i_want_to: 14,
    };
    postAPI(url, data).then((response) => {
      if (response === undefined) {
        toaster("error", "Something went wrong!");
      } else if (response.status === 200) {
        const notice_id = response.data.data.notice_id;
        toaster("success", "Data Saved Successfully!");
        viewNotice(notice_id);
      }
    });
  }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    }
  };

  useEffect(() => {
    if (noticeData) {
      
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());
      setReason1(noticeData.end_tenancy_reason_1 == "0" ? false : noticeData.end_tenancy_reason_1 == "1" && true)
      setReason2(noticeData.end_tenancy_reason_2 == "0" ? false : noticeData.end_tenancy_reason_2 == "1" && true)
      setReason3(noticeData.end_tenancy_reason_3 == "0" ? false : noticeData.end_tenancy_reason_3 == "1" && true) 
      setReason4(noticeData.end_tenancy_reason_4 == "0" ? false : noticeData.end_tenancy_reason_4 == "1" && true) 
      setReason5(noticeData.end_tenancy_reason_5 == "0" ? false : noticeData.end_tenancy_reason_5 == "1" && true)         
      setDetails(noticeData.notice_reason_details)
    }
  }, [noticeData.type]);
  

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Notice to End Tenancy End of term (N8 Form)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: 0 }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">Reason(s) for Ending Tenancy</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label style={{margin:"10px 0px 15px 0px"}}>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={reason1}
                  onChange={() => {
                    setReason1(!reason1);
                  }}
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: Tenant(s) have persistently paid your rent late.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={reason2}
                  onChange={() => {
                    setReason2(!reason2);
                  }}
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: Tenant(s) no longer qualify to live in public or
                  subsidized housing.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={reason3}
                  onChange={() => {
                    setReason3(!reason3);
                  }}
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You have made the unit available to your current
                  tenant(s) as a condition of their employment and their
                  employment has already ended
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason4"}
                  name={"reason4"}
                  checked={reason4}
                  onChange={() => {
                    setReason4(!reason4);
                  }}
                  hidden
                />
                <label htmlFor={"reason4"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 4: Your tenant(s) tenancy was created in good faith as
                  a result of an Agreement of Purchase and Sale for a proposed
                  condominium unit and that agreement has been terminated.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason5"}
                  name={"reason5"}
                  checked={reason5}
                  onChange={() => {
                    setReason5(!reason5);
                  }}
                  hidden
                />
                <label htmlFor={"reason5"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 5: You are occupying the unit specifically to receive
                  rehabilitative or therapeutic services and the period of
                  tenancy to which you agreed has ended. I can only give you a
                  notice for this reason if no other tenant receiving
                  rehabilitative and/or therapeutic services is allowed to live
                  in the residential complex for more than 4 years
                </span>
              </div>
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">
              Details about the reasons for notice
            </span>
          </div>
          <Form.Label>
            List the events below that have led you to give this notice,
            including the dates, times and specific detail
          </Form.Label>
          <div className="event-details">
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details1"
                  id="details1"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Form.Group>
            </Row>
          </div>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
           phoneError={phoneError}
           disableError={(data) => disableError(data)}
           type={"lastName"}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={8}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N8Form);
