import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { getAPI } from "../../../Api";
import { createdCompletedSort, noticeStatusConst } from "../../../Constants/adminConstant";
import { logger } from "../../../Utils";
import ImageComponent from "../../Admin/ProfileDetail/ListUsableComponent/ImageComponent";
import DropDown from "../DropDown";
import ThreeDotsDropdown from "../ThreeDotsDropdown";

const log = logger('NoticesHeader');
const NoticesHeader = (props) => {
    const { data, component } = props;
    log.info(component);
    const [status, setStatus] = useState(false);
    const showStatus = () => {
        setStatus(!status);
    }
    // const [noticeStatus, setNoticeStatus] = useState(false);
    // const showNoticeStatus = () => {
    //     setNoticeStatus(!noticeStatus);
    // }
    const noticeType = useSelector(state => state.propertyDetailsReducer.noticeType)
    log.info(noticeType)
    return (
        <>
            {component === "notices" &&
                <>
                    {(() => {
                        switch (data.header) {
                            case 'ADDRESS':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'TYPE':
                                return (
                                    <span className="d-flex justify-content-between">
                                        {data.header}
                                        <DropDown close={() => setStatus(false)} open={status} position={"left"} enableTopArrow={false} style={{ width: "640px", top: "20px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {noticeType && noticeType.map((data, i) => {
                                                        log.info(data)
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                                        name={data.id}
                                                                        checked={props.selectedNoticeType.find(elm => elm.id === data.id)} onChange={(e) => props.sortByNoticeType(e, data)}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.id}>{data.title}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showStatus}><ImageComponent /></div>
                                        </DropDown>

                                    </span>
                                )
                            case 'CREATED':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {createdCompletedSort.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"created" + i} onClick={() => props.sortCreatedNotice(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'DOCS':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'STATUS':
                                return (
                                    <span className="d-flex justify-content-between">
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {noticeStatusConst.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"main-status" + i} onClick={() => props.sortNoticeStatus(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                        {/* <DropDown close={() => setNoticeStatus(false)} open={noticeStatus} position={"right"} enableTopArrow={false} style={{ width: "208px", top: "20px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {noticeStatusConst.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={props.status.find(elm => elm.value === data.value)} onChange={(e) => props.sortNoticeStatus(e, data)}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showNoticeStatus}><ImageComponent /></div>
                                        </DropDown> */}
                                    </span>
                                )
                            default:
                                return null
                        }
                    })()} </>}
        </>
    );
}
export default NoticesHeader;