import React from "react";
import { Button, Image } from "react-bootstrap";
import "./styles.scss";

const ButtonWithNo = (props) => {
  const { onClick, style, title, img, count } = props;
  return (
    <button onClick={onClick} type="button" className="  position-relative no-btn">
      {!!count && <span className="no badge position-absolute top-0 left-0  bg-danger" style={{marginTop:-2}}>
       {count}
      </span>}
      <div className={"d-flex "}>
        <img src={img} />
        <span className={"ml-3"}>{title}</span>
      </div>
      
    </button>
    
  );
};
export default ButtonWithNo;
