import store from "../../configureStore";
import { editProfileAPI } from "../api/editProfile-api";
import { SET_PROFILE } from "./AuthAction";

export const GET_EDIT_PROFILE_INIT = "GET_EDIT_PROFILE_INIT";
export const GET_EDIT_PROFILE_SUCCESS = "GET_EDIT_PROFILE_SUCCESS";
export const GET_EDIT_PROFILE_FAILED = "GET_EDIT_PROFILE_FAILED";

export function getEditProfile() {
  return function (dispatch) {
    dispatch({ type: GET_EDIT_PROFILE_INIT });
    editProfileAPI()
      .then((response) => {
        dispatch({ type: SET_PROFILE, data: response });
        store.dispatch({ type: SET_PROFILE, data: response });
        return dispatch({ type: GET_EDIT_PROFILE_SUCCESS, response });
      })

      .catch((error) => dispatch({ type: GET_EDIT_PROFILE_FAILED, error }));
  };
}
