import React, { useState, useEffect } from 'react';
import { SidePanel } from '../..';
import { AddingTenantSchema } from '../Schema/AddingTenantSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { useDispatch } from 'react-redux';
import { getTenantsList } from '../../../../Store/actions/AddedTenantAction';
import toaster from '../../../../Utils/toaster/toaster';
import { leaseSubmit } from './helper';
import AddTenant from './AddingTenantsComponents/AddTenant';
import AddTenantFooter from './AddingTenantsComponents/AddTenantFooter';

const AddingTenants = (props) => {
	const { isShow, handleClose, sendBackRes, from, setSkipOpen, setReturnPath, singleReasonData, setTenantList } = props;
	const dispatch = useDispatch();
	const [tenantAdded, setTenantAdded] = useState([
		{
			tenantFullName: '', tenantEmailId: '',tenantPhone:''
		}
	]);
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	useEffect(() => {
		dispatch(getTenantsList([]))
	}, [dispatch]);

	useEffect(() => {
		setTenantAdded([
			{
				tenantFullName: '', tenantEmailId: '',tenantPhone:''
			}
		])
		clearValidationError()
	}, [isShow]);

	const OnChangeInput = (event, index) => {
		let items = [...tenantAdded];
		let item = { ...items[index] };
		const name = event.target.name ? event.target.name : event.target.getAttribute('name')
		item[name] = event.target.value;
		items[index] = item;
		setTenantAdded(items);
		isSubmitClicked && setTimeout(() => {
			validate(AddingTenantSchema)
		}, 300)
	}
	const addMore = () => {
		const data = { tenantFullName: '', tenantEmailId: '',tenantPhone:'' }
		setTenantAdded(tenantAdded => [...tenantAdded, data])
	}
	const OnRemove = (index) => {
		let items = [...tenantAdded];
		items.splice(index, 1);
		setTenantAdded(items);
	}
	const addAndInvite = async () => {
		setIsSubmitClicked(true);
		if (validate(AddingTenantSchema)) {
			const data = tenantAdded.map((tenant) => {
				return {
					name: tenant.tenantFullName,
					email: tenant.tenantEmailId,
					phone_number:tenant.tenantPhone.replaceAll('-','')
 				}
			});
			const tenant = {
				'tenants': data
			}
			const res = await leaseSubmit(tenant, 'add-tenant');
			if (res?.status === 200) {
				let resTenant = [];
				resTenant.push(res?.data);
				dispatch(getTenantsList(resTenant))
				setTenantList(resTenant);
				handleClose();
				if (props.isDeactivation) {
					props.setLeaseOptionsScreen(true);
				} else {
					sendBackRes(true);
				}
			} else if(res?.errors){
				let obj = Object.keys(res?.errors);
				const msg = obj.map(o => {
					let arr = o.split('.');
					const tenant=data[arr[1]];
 					return (tenant[arr[2]] )+ ' has already been taken';
				});
				toaster('error', msg.join(', '))
			}else{
				toaster('error', res?.message)
			}
		}
	}
	const handleCloseConfirm = () => {
		handleClose();
		setTenantAdded([{ tenantFullName: '', tenantEmailId: '' }]);
		if (props.isDeactivation) {
			props.setOpenDeactivate(true);
		} else {
			sendBackRes(true);
		}
	}
	const openSkipModal = (returnPath) => {
		setReturnPath(returnPath);
		setSkipOpen(true)
		handleClose();
	}
	const removeValidation = (id) => {
		let ele = document.getElementById(id);
		let classNames = ele.className.split(" ");
		let errorIndex = classNames.indexOf('is-invalid');
		if (errorIndex !== -1) {
			classNames.splice(errorIndex, 1);
			ele.className = classNames.join(" ");
		}
		let errorEle = document.getElementById('error_' + id);
		if (errorEle) {
			errorEle.remove();
		}
	}
	const clearValidationError = () => {
		removeValidation('tenantEmailId0');
		removeValidation('tenantFullName0');
		removeValidation('tenantPhone0');
	}

	return (
		<SidePanel
			isShow={isShow}
			isHide={() => { props.isDeactivation ? handleCloseConfirm() : setTenantAdded([{ tenantFullName: '', tenantEmailId: '' }]); handleClose('callBackApi') }}
			title={
				props.isDeactivation ? "register tenants" :
					(from === 'listing-details' ? 'Add And Send Invite' : 'ADD AND SEND INVITATION')
			}
			subTitle={
				props.isDeactivation ? "Your tenants will be sent an invitation for you to manage your property better" :
					(from === 'listing-details' ? 'Invite a tenant outside of rent panda' : 'invite a tenant outside of rent panda')
			}>
			<AddTenant
				tenantAdded={tenantAdded}
				OnRemove={OnRemove}
				OnChangeInput={OnChangeInput}
				addMore={addMore}
				singleReasonData={singleReasonData}
				openSkipModal={openSkipModal}
				isDeactivation={props.isDeactivation}
			/>
			<AddTenantFooter
				handleCloseConfirm={handleCloseConfirm}
				isDeactivation={props.isDeactivation}
				addAndInvite={addAndInvite}
			/>
		</SidePanel>
	);
}
export default AddingTenants;