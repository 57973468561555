import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Button, Tab, Row, Nav, Container,  } from 'react-bootstrap';
import { AppLayout, PageTitleHeader, LargeButton, ModalBox } from '../../components';
import "./style.scss";

class TabsSection extends Component {
 constructor(props) {
  super(props);
  this.LandlordHomeComponent =
   props.LandlordHomeComponent;
 }

 componentDidMount() {
  var activeAccordion = document.getElementsByClassName(
   "accordion-button"
  );
  var accordionLength = activeAccordion.length;
  for (let i = 0; i < accordionLength; i++) {
   activeAccordion[i].addEventListener(
    "click",
    function () {
     activeAccordion[i].classList.toggle(
      "active"
     );
    }
   );
  }
 }
  
 render() {
  return (
   <>
    <div className="home-tabbing-wrap-thunder-bay">
     <div className="position-relative">
      <Container>
       <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="first"
       >
        <Nav
         variant="pills"
         className="thunder-bay"
        >
         <Nav.Item>
          <Nav.Link eventKey="first">
           1. do it yourself
          </Nav.Link>
         </Nav.Item>
         <Nav.Item>
          <Nav.Link eventKey="second">
           2. let us find you a tenant
          </Nav.Link>
         </Nav.Item>
         <Nav.Item>
          <Nav.Link eventKey="third">
           3. let us manage your property{" "}
          </Nav.Link>
         </Nav.Item>
        </Nav>
        <Tab.Content>
         <Tab.Pane eventKey="first">
          <Row>
           <div className="col-lg-12 mb-4 tab-top-title">
            <h5 className="h-list-box-title">
             Find a tenant for free, with all the
             tools you need to go from listing to
             lease in one easy place
            </h5>
           </div>
          </Row>
          <Button
           href="/signup/landlord"
           className="tabbing-lastBtn"
           id="BTN-landlord-post-listing-llp"
           data-description="Post a listing from landlord landing page"
           gtmdetails="Tab 1"
          >
           get started
          </Button>
          <Row className="tab-accordion-container">
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-1.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               post unlimited rentals
              </h5>
              <div className="h-list-box-disc">
               We don’t limit how many listings
               you have at once so you can manage
               all your rental properties in one
               place, easily.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-6.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               conduct move-in/out inspections
              </h5>
              <div className="h-list-box-disc">
               When a tenant moves in and out,
               take and store pictures, statuses
               and notes on every bit of your
               rental property.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-5.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right accordion-button">
              <h5 className="h-list-box-title">
               access tenant profiles
              </h5>
              <div className="h-list-box-disc">
               Access tenant info including rental
               history, employment info, if they
               have pets, if they have a car and
               more.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left ">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-11.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               repost your ad with a single click
              </h5>
              <div className="h-list-box-disc">
               No need to ever input information
               and pictures all over again. One
               click and your ad is reposted.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-7.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               schedule showings online
              </h5>
              <div className="h-list-box-disc">
               Once you’ve narrowed down your
               choice of tenants, you can schedule
               showings online.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-10.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               serve notices to tenants
              </h5>
              <div className="h-list-box-disc">
               Build and send all your N forms
               quickly and easily with our notice
               tool.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-2.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               build sign and store leases
              </h5>
              <div className="h-list-box-disc">
               No more paper leases or hard to use
               government forms with our online
               lease builder.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-8.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               get repair requests from tenants
              </h5>
              <div className="h-list-box-disc">
               Get instant repair requests, with
               detailed notes and pictures,
               instead of those late night texts
               and calls.{" "}
              </div>
             </div>
            </div>
           </div>
          </Row>
          <div className="key-line-container">
           <Row className="paid-add-ons">
            <div className="col-lg-6 mb-4">
             <h2 className="title">
              paid add-ons
             </h2>
             <div className="h-list-box accordion-button">
              <div className="h-list-box-left">
               <Image
                src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-3.svg")}
                alt="Banner"
               />
              </div>
              <div className="h-list-box-right">
               <h5 className="h-list-box-title">
                post to kijiji and facebook
               </h5>
               <div className="h-list-box-disc">
                Post your listing to kijiji &amp;
                facebook with a single click.
               </div>
              </div>
             </div>
             <div className="h-list-box accordion-button">
              <div className="h-list-box-left">
               <Image
                src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-9.svg")}
                alt="Banner"
               />
              </div>
              <div className="h-list-box-right">
               <h5 className="h-list-box-title">
                best-in-class screening
               </h5>
               <div className="h-list-box-disc">
                With a tenant’s consent you can
                instantly pull current a credit
                and background check so you can
                get the full picture of your
                future tenant.
               </div>
              </div>
             </div>
            </div>
            <div className="col-lg-6 mb-4">
             <div className="text-center mb-4">
              <Image
               src={require("../../../../../_assets/images/landing-pages/tabs-lower-banner.svg")}
               alt="Banner"
              />
             </div>
            </div>
           </Row>
          </div>
         </Tab.Pane>
         {/* Tab-2 */}
         <Tab.Pane eventKey="second">
          <Button
           onClick={() =>
            this.LandlordHomeComponent.setState({
             freeRentAssessment: 13,
             inquiry_type: "pro_find",
            })
           }
           className="tabbing-lastBtn"
           id="BTN-landlord-get-started-llp"
           data-description="Get started from landlord landing page"
           gtmdetails="Tab 2"
          >
           connect with us
          </Button>
          <Row className="tab-accordion-container">
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-1.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               property &amp; rent assessment
              </h5>
              <div className="h-list-box-disc">
               Even before you sign on the dotted
               line, get an inspection and rent
               assessment, so you know what to
               charge and who will be the perfect
               tenant for your rental.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-7.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               manage showings
              </h5>
              <div className="h-list-box-disc">
               We manage all of the showings, with
               pre-qualified tenants, so you don’t
               have to worry.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t2-6.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               professional marketing
              </h5>
              <div className="h-list-box-disc">
               High quality ads are crafted with
               pro photos and 360 videos, and then
               listed on all the best sites to
               attract the right tenants.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-9.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               best-in-class screening
              </h5>
              <div className="h-list-box-disc">
               Best in class screening tools are
               used and all references are
               checked, before top tenants are
               presented to you.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t2-4.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               lease creation
              </h5>
              <div className="h-list-box-disc">
               We craft the lease with a unique
               lease appendix for your property,
               to make sure your investment is
               secure.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-4.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               tenant handoff
              </h5>
              <div className="h-list-box-disc">
               Once the lease has been signed, a
               handoff is done to make sure
               everyone knows next steps, and you
               get your rent right away.
              </div>
             </div>
            </div>
           </div>
          </Row>
         </Tab.Pane>
         {/* Tab-3 */}
         <Tab.Pane eventKey="third">
          <Button
           onClick={() =>
            this.LandlordHomeComponent.setState({
             freeRentAssessment: 13,
             inquiry_type: "pro_manage",
            })
           }
           className="tabbing-lastBtn"
           id="BTN-landlord-getintouch-tab3-llp"
           data-description="Get in touch landlord landing page"
           gtmdetails="Tab 3"
          >
           connect with a pro
          </Button>
          <Row className="tab-accordion-container">
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-4.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               rent collection
              </h5>
              <div className="h-list-box-disc">
               Rent is collected from the tenants
               and deposited directly into your
               account.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-12.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               property inspections
              </h5>
              <div className="h-list-box-disc">
               Bi-annual, move in/out, fire safety
               and requested inspections are taken
               care of professionally.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-4.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               tenant management
              </h5>
              <div className="h-list-box-disc">
               Whether it’s tenant questions,
               requests, complaints or anything in
               between, you’ll never be bugged.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-8.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               legal notices &amp; evictions
              </h5>
              <div className="h-list-box-disc">
               Legal specialists handle all legal
               notices, LTB filings and evictions.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-10.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               maintenance &amp; repairs
              </h5>
              <div className="h-list-box-disc">
               Snow removal, lawn-care and all
               repairs are managed, so you never
               have to worry.
              </div>
             </div>
            </div>
           </div>
           <div className="col-lg-6 mb-4 accordion-button">
            <div className="h-list-box">
             <div className="h-list-box-left">
              <Image
               src={require("../../../../../_assets/images/how-it-works/how-it-works-landlord-t3-14.svg")}
               alt="Banner"
              />
             </div>
             <div className="h-list-box-right">
              <h5 className="h-list-box-title">
               owner statements
              </h5>
              <div className="h-list-box-disc">
               Always be in the know with your
               property, so you never have to
               worry about what’s going on.{" "}
              </div>
             </div>
            </div>
           </div>
          </Row>
         </Tab.Pane>
         <div className="bottom-accent"></div>
        </Tab.Content>
       </Tab.Container>
      </Container>
     </div>
    </div>
   </>
  );
 }
}

export default withRouter(TabsSection);
