import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LargeButton, CancelButton, SidePanel, FileBrowse } from '../../../components';
import { postAPI } from '../../../../Api';
import { withRouter } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { logger, toaster } from '../../../../Utils';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';

const log = logger('UploadLeaseDocument');
const UploadLeaseDocument = (props) => {
    const { isShow, handleClose, openLeaseDuration, setLeaseId } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    // const reasonDeactivate = useSelector(state => state.deactiveReasonReducer.deactiveReason);
    const [back_image, setBack_image] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [disableBtn, setBtnDisable] = useState(false);
    const [showImageLoading,setShowImageLoading]=useState(false)


    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }
        setShowImageLoading(true)

        file.map((dataFile) => {
            setUploadData(dataFile);
            let formdata = new FormData();
            formdata.append('file', dataFile);
            let url = FILE_API_URL;
            Axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImage({ percent: percent });
                        return file;
                    } else {
                        setImage({ percent: percent });
                        return null;
                    }
                }
            })
                .then(response => {
                    setImage({ file: response.data.data.url })
                    setErrorClass(false)
                    setShowImageLoading(false)

                })
                .catch(error => {
                    setShowImageLoading(false)
                });
            return dataFile;
        })
    }
    const removeImage = (index, setImage) => {
        setImage('')
    }
    const submit = () => {

        if (back_image.file === '') {
            setErrorClass(true)
        } else {
            setBtnDisable(true)
            const url = '/admin/listing/lease/upload';
            const data = {
                'lease_id': 0,
                'property_id': property_id,
                'property_detail_id': property_details_id,
                "lease_pdf": back_image.file
            }
            postAPI(url, data).then(res => {
                setBtnDisable(false)
                if (res.status === 200) {
                    const leaseId = res.data.data.id;
                    setLeaseId(leaseId);
                    openLeaseDuration(true);
                    toaster('success', 'lease uploaded successfully')
                } else if (res.response.status === 422) {
                    toaster('error', 'The lease pdf field is required.');
                    setErrorClass(true)
                }
            })
        }

    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        // setLeaseId(props.match.params.lease_id);
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title='Upload Lease'
            subTitle='Select previous agreement'
        >
            <div className='sidepanel-body mt-5'>
                <Row>
                    {log.info("file", back_image.file)}
                    <Col md="12">
                        <FileBrowse
                            handleDrop={(e) => handleDrop(e, setBack_image)}
                            size={'sm'}
                            title={'Upload Documents'}
                            percent={back_image.percent}
                            multiple={false}
                            fileType={'.jpg,.png,.jpeg,.pdf'}
                            disable={false}
                            showImageLoading={showImageLoading}
                            imageData={back_image.file && back_image.file}
                            removeImage={(e) => removeImage(e, setBack_image)}
                            lableClass={'custom-lable'}
                            section={'upload-lease'}
                            uploadData={uploadData && uploadData}
                            errorClass={errorClass === true ? "error-upload-box" : ""}
                        />
                    </Col>
                </Row>
            </div>
            <div className='fixed-bottom d-flex justify-content-between'>
                <CancelButton onClick={() => handleClose('callBackApi')} title='Back' />
                <LargeButton saveLoading={disableBtn} title='create lease' onClick={() => submit()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(UploadLeaseDocument);