import React, { useState } from "react";
import { Button, Image, Row, Col } from "react-bootstrap";
import { ModalBox } from "../../../components";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useSelector, useDispatch } from "react-redux";
import { logger } from "../../../../Utils";

const log = logger("StepFive");
const StepFive = (props) => {
  const{ setPhotoType } = props

  const dispatch = useDispatch();

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  log.info("propertyDetails", propertyDetails.city);

    return (<>
      <div className="inner-wrapper cu-step-form-body" style={{minHeight:"500px"}}>
        <div className="body-container">
          <div className="body-header">
              <h3>it's time for the pictures</h3>
          </div>
          <Row>
            <Col data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" md="6">
              <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-wrapper' onClick={() => dispatch(getCurrentPage(5))}>
                <div data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className='image-listing-inner'>
                  <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" className="icon" src={require("../../../../_assets/images/photo-icon.png")} alt="add"/>
                  <h4 data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Upload Photos</h4>
                   <p data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init">Use photos you have or take them on your phone right now!</p>
                </div>
                <Image data-description="Landlord initiated photo upload" id="BTN-listing-step-5-photo-upload-init" src={require("../../../../_assets/images/path.svg")} alt="path"/>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container-footer cu-step-form-footer">
        <Button variant="secondary" onClick={()=> dispatch(getCurrentPage(3))}>Back</Button>
        <Button variant="success" onClick={()=> dispatch(getCurrentPage(5))}>Next</Button>
      </div>
   </>);
}

export default StepFive;
