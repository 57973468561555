import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const LeaseDetailFormContentLoader = (props) => {
	const { grid, type, loop = 4 } = props;
	return (
		<>
			<Form className='higher-level-lease'>
				<div className='inner-wrapper'>
					<div className='body-container'>
						<div className='body-header'>
							<h3 className='text-left lease-heading'>lease details</h3>
						</div>

						<Row>
							<Form.Group as={Col} md='5'>
								<Form.Label className='transform-none'>How long is the lease</Form.Label>
								<RowList />
							</Form.Group>
							<Form.Group as={Col} md='6'>
								<Form.Label className='transform-none'>Please indicate the start date and end date</Form.Label>
								<Row>
									<Col md='6'>
										<RowList />
									</Col>
									<Col md='6'>
										<RowList />
									</Col>

								</Row>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='10'>
								<Form.Label className='transform-none'>Are there any additional terms about the tenancy agreement?</Form.Label>
								<Row>
									<Col md='2'>
										<div className='d-flex justify-content-between align-items-center'>
											<RowList />
										</div>
									</Col>
									<Col md='5'>
										<RowList />
									</Col>
								</Row>
							</Form.Group>
						</Row>
						<hr />

						<h3 className='text-left lease-heading'>more details about the rent</h3>
						<Row>
							<Form.Group as={Col} md='5'>
								<Form.Label className='transform-none'>Rent is to be paid on the:</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='4'>
								<Form.Label className='transform-none'>Day of each:</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='5'>
								<Form.Label className='transform-none'>Base rent for the rental property</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label className='transform-none'>Is parking available</Form.Label>
								<RowList />
							</Form.Group>

						</Row>
						<Row>
							<Form.Group as={Col} md='5'>
								<Form.Label className='transform-none'>Rent is payable to:</Form.Label>
								<RowList />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md='8'>
								<Form.Label className='transform-none'>Rent will be paid using the following methods:</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='3'>
								<Form.Label className='transform-none'>Is the first rental period a partial period?</Form.Label>
								<RowList />
							</Form.Group>
							<Form.Group as={Col} md='9'>
								<Form.Label className='transform-none'>This partial covers the rental of the unit from:</Form.Label>
								<Row>
									<Col md='5'>
										<RowList />
									</Col>
									<span className='mt-2'>to</span>
									<Col md='5'>
										<RowList />
									</Col>
								</Row>
								<Row>
									<Col md='5'>
										<Form.Label className='mt-4 transform-none'>The tenant will have to pay an amount of:</Form.Label>
										<RowList />
									</Col>
									<span className='mt-6'>on</span>
									<Col md='5'>
										<Form.Label className='mt-4 transform-none'>Date of partial payment</Form.Label>
										<RowList />
									</Col>
								</Row>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='6'>
								<Form.Label className='transform-none'>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='7'>
								<Form.Label className='transform-none'>Is smoking allowed? (If allowed, please ensure to include it to your lease appendix)</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<hr />
						<h3 className='text-left lease-heading transform-none'>deposits and discounts</h3>
						<Row>
							<Form.Group as={Col} md='3'>
								<Form.Label>Is there a rent deposit?</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='5'>
								<Form.Label>Rent Deposit Amount</Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='3'>
								<Form.Label className='transform-none'>Is there a key deposit?</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='5'>
								<Form.Label className='transform-none'>Key Deposit Amount <em>(can only be amount to replace key)</em></Form.Label>
								<RowList />
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md='3'>
								<Form.Label className='transform-none'>Is there a rent discount</Form.Label>
								<RowList />
							</Form.Group>
							<Form.Group as={Col} md='5'>
								<Form.Label>Amount</Form.Label>
								<RowList />

								<Form.Label className="mt-3">Rent Discount Description</Form.Label>
								<RowList />
							</Form.Group>
						</Row>
						<hr />
						<h3 className='text-left lease-heading'>who pays for utilities?</h3>
						<Row>
							<Form.Group as={Col} md='3'>
								<Form.Label>Electricity</Form.Label>
								<RowList />
							</Form.Group>
							<Form.Group as={Col} md='3'>
								<Form.Label>Heat</Form.Label>
								<RowList />
							</Form.Group>
							<Form.Group as={Col} md='3'>
								<Form.Label>Water</Form.Label>
								<RowList />
							</Form.Group>
						</Row>
						<hr />

						<h3 className='text-left lease-heading'>amenities</h3>
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Gas</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='2'>
								<Form.Label>A/C</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='3'>
								<Form.Label>Additional Storage Space</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='2'>
								<Form.Label>Laundry</Form.Label>
								<RowList />
							</Form.Group>

							<Form.Group as={Col} md='2'>
								<Form.Label>Guest Parking</Form.Label>
								<RowList />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md='4'>
								<RowList />
							</Form.Group>
						</Row>
					</div>
				</div>

			</Form>
		</>
	)
}
export default LeaseDetailFormContentLoader;


const RowList = (props) => {
	return (
		<ContentLoader height={40} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
			<rect x="0" y="0" rx="0" ry="0" width="100%" height="40" />
		</ContentLoader>
	)
}