import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getAPI } from '../../../../Api';
import { dateFormat3 } from '../../../../Utils/DateSanitizer/DateSanitizer';

import '../style.scss';

const ListingStatus = (props) => {
	const { unitDetails, publishNow } = props;
	const [listingStatus, setListingStatus] = useState("");
	const [reason, setReason] = useState("");
	useEffect(() => {
		if (unitDetails != null) {
			if (unitDetails.listingStatus === 1) {
				setListingStatus("listing-for-rent")
			} else if (unitDetails.listingStatus === 2) {
				setListingStatus("in-progress")
			} else if (unitDetails.listingStatus === 3) {
				setListingStatus("rented-property")
			} else if (unitDetails.listingStatus === 4) {
				setListingStatus("promoted")
			} else if (unitDetails.listingStatus === 5) {
				setListingStatus("needs-approval")
			} else if (unitDetails.listingStatus === 6) {
			// else if (unitDetails.deactivate_reason !== null && unitDetails.listingStatus === 6) {
				setListingStatus("deactivated")
			}
		}
		if (unitDetails) {
			if (unitDetails.deactivate_reason_description !== null && unitDetails.deactivate_reason_description !== '') {
				setReason(unitDetails.deactivate_reason_description)
			} else {
				if (unitDetails.deactivate_reason) {
					let url = '/admin/listing/lease/get-deactive-reasons';
					getAPI(url).then(res => {
						if (res.status === 200) {
							res.data.data && res.data.data.filter(data => data.order === unitDetails.deactivate_reason).map(filter => {
								setReason(filter.deactive_reason)
								return filter;
							})
						}
					})
				}
			}
		}
	}, [unitDetails])

	return (
		<Row className={"m-0 manage-status " + listingStatus}>
			<Col md={12} className="text-center">
				{(() => {
					switch (listingStatus) {
						case 'listing-for-rent':
							return (
								<h4>This listing is currently approved and is now for rent</h4>
							)
						case 'in-progress':
							return (
								<h4>The landlord hasn’t completed the details for this property yet. Currently In Progress.
									<Button variant="link" className="publishNowLink" onClick={publishNow}>Publish Now</Button>
								</h4>
							)
						case 'rented-property':
							return (
								<h4>This property is currently Rented</h4>
							)
						case 'promoted':
							return (
								<h4>
									This listing is active &amp; currently being promoted with {(unitDetails && unitDetails.promotion) && unitDetails.promotion.days_left} days left (Expiring on {dateFormat3((unitDetails && unitDetails.promotion) && unitDetails.promotion.expiry_date)})</h4>
							)
						case 'needs-approval':
							return (
								<h4>This listing needs approval before it can be listed publically</h4>
							)
						case 'deactivated':
							return (
								<h4 style={{ fontSize: '16px' }}>Listing Deactivated Reason: “{reason}”</h4>
							)
						default:
							return null
					}
				})()}
			</Col>

		</Row>
	)
}

export default withRouter(ListingStatus);