import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import BetaNotification from "./BetaNotification";
import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";
import "react-tempusdominus-bootstrap";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

// Bugsnag.notify(new Error('Test error'))

// Add identifier for production
if(typeof process.env.NODE_ENV != 'undefined') window.currentEnv = process.env.NODE_ENV;

ReactDOM.render(
  <>
    <ErrorBoundary>
      {/* <BetaNotification /> */}
      <App />
    </ErrorBoundary>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
