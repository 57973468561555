import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CostNumberFormat, DatePickerComponent } from "../../../components"
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { bedroomsData, bathroomsData, isSharedBathroomData } from "../../../../Constants/propertyDetails"
import { logger, toaster } from "../../../../Utils";
import { StepTwoValidationSchema } from "./ValidationSchema/StepTwoValidationSchema";
import { validate } from "../../../../Utils/Validation/validation";
import { postAPI } from "../../../../Api";
import { numberFormatSanitizerFloat } from "../../../../Utils/NumberSanitizer/NumberSanitizer";
import { Prompt } from "react-router";
import { differenceInDays } from 'date-fns';
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useLocation, useHistory } from 'react-router-dom';


const log = logger("StepTwo");
const StepTwo = (props) => {
  const { setProperty_details_id, setFreeRentAssessment, free_rent_assessment_id, backToPrevPage } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_type = propertyDetails && propertyDetails.property_type;
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  log.info("currentPath", currentPath);

  const [unit_number, setUnit_number] = useState('');
  const [date, setDate] = useState(new Date());
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [is_shared_bathroom, setIs_shared_bathroom] = useState(0);
  const [lease_duration, setLease_duration] = useState("");
  const [price_per_month, setPrice_per_month] = useState('');
  const [bathroomError, setBathroomError] = useState(false);
  const [beadroomError, setBedroomError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  let days = differenceInDays(new Date(date), new Date());
  log.info("price_per_month", price_per_month);
  useEffect(() => {
    if (property_details_id && unitDetails) {
      setUnit_number(unitDetails.unit_no)
      setDate(new Date(unitDetails.available_date))
      setBedrooms(unitDetails.bedrooms || 1)
      setBathrooms(unitDetails.bathrooms || 1)
      setIs_shared_bathroom(unitDetails.is_shared_bathroom)
      setLease_duration(unitDetails.lease_duration)
      setPrice_per_month(unitDetails.price_per_month);
    }
  }, [unitDetails, property_details_id])

  const OnChangeInput = (event) => {
    if (event.target.name === 'lease_duration') {
      setLease_duration(event.target.value)
    } else if (event.target.name === 'price_per_month') {
      const sanitizeValue = numberFormatSanitizerFloat(event.target.value);
      setPrice_per_month(sanitizeValue)
    } else if (event.target.name === 'unit_number') {
      setUnit_number(event.target.value)
    }
    setShouldBlockNavigation(true);
    log.info("price_per_month", event.target.name, event.target.value);
  }

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'bedrooms') {
      setBedrooms(Value);
      setBedroomError(false);
    } else if (name === 'bathrooms') {
      setBathrooms(Value);
      setBathroomError(false);
    } else if (name === 'is_shared_bathroom') {
      setIs_shared_bathroom(Value);
    }
    setShouldBlockNavigation(true);
  }

  const onSubmit = (type) => {
    log.info("date", days);
    if (days < 0) {
      setDateError(true);
    } else if (validate(StepTwoValidationSchema)) {
      const url = '/landlord/create-listing/step-2';
      const data = {
        property_id: property_id,
        property_type: property_type,
        available_date: moment(date).format('YYYY-MM-DD'),
        lease_duration: lease_duration,
        price_per_month: price_per_month
      }
      if (free_rent_assessment_id) {
        data.free_rent_assessment_id = free_rent_assessment_id
      }

      if (property_type === 1) {
        data.unit_name = 'Single Unit'
        data.bedrooms = bedrooms
        data.bathrooms = bathrooms
      } else if (property_type === 2) {
        data.unit_number = unit_number
        data.bedrooms = bedrooms
        data.bathrooms = bathrooms
      } else if (property_type === 3) {
        data.unit_number = unit_number
        data.is_shared_bathroom = is_shared_bathroom
      }

      if (property_details_id) {
        data.property_details_id = property_details_id;
      }

      postAPI(url, data)
        .then(response => {
          if (response.status !== 200) {
            toaster('error', response.response.data.message)
            log.info("fsdfsjd", response);
          } else if (response.status === 200) {
            setProperty_details_id(response.data.data.property_details_id)
            toaster('success', 'Data Saved Successfully!')
            if (type === 'next') {
              dispatch(getCurrentPage(2));
            } else if (type === "save" && currentPath === "edit-listing") {
              history.goBack();
            }
            setShouldBlockNavigation(false);
          }
        })
        .catch(function (error) {
          toaster('error', error.response.data.message)
        });
    }
  }
  // useEffect(() => {
  //   if (shouldBlockNavigation) {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = undefined;
  //   }
  // });
  const handleDate = (date) => {
    setDate(date);
    if (days < 0) {
      setDateError(false);
    }
    setShouldBlockNavigation(true);
  }

  return (<>
    <Prompt
      when={shouldBlockNavigation}
      message="your information will be lost do you want to leave this page?"
    />
    <div className="inner-wrapper cu-step-form-body">
      <div className="body-container">
        <div className="body-header">
          <h3>let’s get to know your place</h3>
          {!(currentPath === 'edit-listing') && <span className="save-link" onClick={() => onSubmit()}>Save</span>}
        </div>

        <Form id="Form">

          {(property_type === 2 || property_type === 3) &&
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>{(property_type === 2) ? "Unit Number" : "Room Name or Number"}</Form.Label>
                <Form.Control
                  type="text"
                  name="unit_number"
                  id="unit_number"
                  value={unit_number}
                  placeholder={(property_type === 2) ? "Unit Number" : "Example: Master Bedroom"}
                  onChange={(e) => OnChangeInput(e)}
                />
              </Form.Group>
            </Row>}

          {(property_type === 3) &&
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Is the Bathroom Shared or Private?</Form.Label>
                <ul className="select-options">
                  {isSharedBathroomData.map((data) =>
                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"isSharedBedroom_" + data.value} value={data.value} name="is_shared_bathroom" id="is_shared_bathroom"
                      className={(is_shared_bathroom === data.value) ? "active" : ""}>{data.title}</li>)}
                </ul>
              </Form.Group>
            </Row>
          }

          {(property_type === 1 || property_type === 2) &&
            <>
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>How Many Bedrooms In This Property?</Form.Label>
                  <ul className="select-options">
                    {bedroomsData.map((data) =>
                      <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"bedrooms_" + data.value} value={data.value} name="bedrooms" id="bedrooms"
                        className={(bedrooms === data.value) ? "active" : ""}>{data.title}</li>)}
                  </ul>
                  {beadroomError === true && <p style={{ color: 'red' }}>Please select beadroom</p>}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>How Many Bathrooms In This Property?</Form.Label>
                  <ul className="select-options">
                    {bathroomsData.map((data) =>
                      <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"bathrooms_" + data.value} value={data.value} name="bathrooms" id="bathrooms"
                        className={(bathrooms === data.value) ? "active" : ""}>{data.title}</li>)}
                  </ul>
                  {bathroomError === true && <p style={{ color: 'red' }}>Please select Bathrooms</p>}
                </Form.Group>
              </Row>
            </>}

          <Row>
            <Form.Group as={Col} md="6" className="step-2-datePicker">
              <Form.Label>When Will The Home Be Available?</Form.Label>
              <DatePickerComponent
                name="date"
                id="date"
                value={date}
                handleDateChange={(date) => handleDate(date)}
                displayText={"Select Date"}
                minDate={new Date()}
              />
              {dateError === true && <p style={{ color: 'red' }}>Past Date not allowed</p>}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Lease Duration</Form.Label>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                id="lease_duration"
                custom
                name="lease_duration"
                value={lease_duration}
                onChange={(e) => OnChangeInput(e)}
              >
                <option value="">Select one</option>
                <option value="2">Month to month</option>
                <option value="1">1 Year lease</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6">
              <div className="display-flex-inline-elm">
                <Form.Label>Price Per Month</Form.Label>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom-start"
                  rootClose={true}
                  overlay={
                    <Tooltip className="info-tooltip">
                      Don’t know what to charge for rent or what your place is worth?
                      <br/><br/>
                      <b><a href="https://www03.cmhc-schl.gc.ca/hmip-pimh/en/TableMapChart/Table?TableId=2.1.31.2&GeographyId=35&GeographyTypeId=2&DisplayAs=Table&GeograghyName=Ontario"
                            target="_blank"
                            style={{color: "#3D3D3D", textDecoration: "underline"}}>
                            Click Here</a></b> to find accurate rental data for your area.
                          </Tooltip>
                  }
                >
                  <Image src={require("../../../../_assets/images/que-icon.svg")} alt="close" />
                </OverlayTrigger>
              </div>
              <CostNumberFormat
                name={"price_per_month"}
                id={"price_per_month"}
                classname="form-control"
                value={price_per_month ? price_per_month : ''}
                updateField={(e) => OnChangeInput(e)}
                pre="$"
                placeholder={"Enter your desired pricing"}
              />
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>

    <div className="container-footer cu-step-form-footer">
      {currentPath === 'edit-listing' ?
        <>
          <Button variant="secondary" onClick={() => backToPrevPage()}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('save')}>Save</Button>
        </> :
        <>
          <Button variant="secondary" onClick={
            () => dispatch(getCurrentPage(0))
          }>Back</Button>
          <Button data-description="Landlord entered property details" id="BTN-listing-step-2-complete" variant="success" onClick={() => onSubmit('next')}>Next</Button>
        </>
      }
    </div>
  </>);
}

export default StepTwo;
