import logger from "../Logger/Logger";

const log = logger("sanitizer");
export const phoneNumberSanitizer = (value) => {
    log.info(typeof value)
    if (value !== null || value !== "") {
        const type = typeof value;
        if (type === 'string') {
            const convert1 = value.replace("-", "");
            const convert2 = convert1.replace("-", "");
            return convert2;
        } else if (type === 'number') {
            return value;
        }
    } else {
        return value;
    }
}
export const commaSanitizer = (value) => {
    if (value !== null || value !== "") {
        const type = typeof value;
        log.info('====', value)
        if (type === 'string') {
            var result = value.replace(/,/g, "");
            return result;
        } else if (type === 'number') {
            return value;
        }
    } else {
        return value;
    }
}
export const phoneNumberDisplay = (value) => {
    log.info(typeof value)
    // let phone = value.toString()
    if (value !== null || value !== "") {
        let formated_phone = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        return formated_phone
    } else {
        return value;
    }
}

export const numberFormatSanitizerInt = (num) => {

    var a = num.replace(/[^0-9.-]+/g, ""); // 1125, but a string, so convert it to number
    log.info("before", a)
    var integer = parseInt(a, 10);
    return integer;
}

export const numberFormatSanitizerFloat = (num) => {
    const pre = num.toString().replace(/[^0-9.-]+/g, '');
    var integer = parseFloat(pre, 10);
    return integer;
}