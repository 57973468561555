import React from "react";
import { Image } from "react-bootstrap";
import "../style.scss";

const ImageComponent = (props) => {
    const { extraClass, url } = props;
    return (
        <Image
            width="8px"
            height="13px"
            alt="sort-dropdown"
            className={"mb-1 ml-2 " + extraClass}
            src={url ? url : require(`../../../../_assets/images/sort-dropdown.svg`)} />
    );
}

export default ImageComponent;
