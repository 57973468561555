import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { logger } from '../../../../Utils';
import { LargeButton, CancelButton, SidePanel } from '../../../components';
import { getAPI, postAPI } from '../../../../Api';
import { useSelector } from 'react-redux';
import './style.scss';
import SearchInput from '../ListUsableComponent/SearchInput';

const log = logger('ConfirmListTenants');
const ConfirmListTenants = (props) => {
	const { isShow, handleClose, property_id, lease_id, openAddTenantWindow, property_details_id, apiCallback } = props;
	const addedTenantReducer = useSelector(state => state.addedTenantReducer.addedTenants);
	const [reducerData] = useState(addedTenantReducer !== null ? addedTenantReducer[0] : [])

	const [getSelectedList, setSelectedList] = useState([]);
	const [tenantList, setRentpandaTenant] = useState();
	const [searchLocation] = useState(null);
	const [serachData, setSearchData] = useState([]);

	log.info(tenantList);

	const onValueChange = (e, data) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		// log.info(item, isChecked);
		isChecked === true ? getSelectedList.push(data) : getSelectedList.splice(getSelectedList.indexOf(item), 1);
		setSelectedList(getSelectedList)
	}
	const customSearch = (e) => {
		// log.info('In execute', e)
		const excludeColumns = [
			'created_at',
			'email',
			'is_invited',
			'tenant_id',
			'updated_at',
			'user_type',
		];
		var updatedList = serachData;

		const lowercasedFilter = e.toLowerCase().trim();
		const filteredData = updatedList.filter((item) => {
			return Object.keys(item).some((key) =>
				excludeColumns.includes(key)
					? false
					: (key === 'name' ? item[key].toString().toLowerCase().indexOf(lowercasedFilter) > -1 : item[key].toString().toLowerCase().indexOf(lowercasedFilter) === 0)
			);
		});
		// log.info('filteredData', filteredData);
		setRentpandaTenant(filteredData)
	}
	const handleCloseConfirm = () => {
		handleClose();
	}
	const addAndInvite = () => {
		const url = '/admin/listing/lease/confirm-tenant';
		const data = [];
		getSelectedList.map((list) => {
			let mapList = {
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'tenant_id': list.tenant_id,
				'lease_id': Number(lease_id)
			}
			data.push(mapList)
			return list;
		})

		postAPI(url, data).then(res => {
			if (res.status === 200) {
				handleClose();
				apiCallback(true);
			}
		})
	}
	useEffect(() => {
		if (property_id && property_details_id) {
			let url = `/admin/users/get-tenants/${property_id}/${property_details_id}`;
			getAPI(url).then(res => {
				if (res.status === 200) {
					setRentpandaTenant(res.data.data.concat(reducerData))
					setSearchData(res.data.data.concat(reducerData));
				} else {
					let arr = [];
					setRentpandaTenant(arr.concat(reducerData))
				}
			})
		}
	}, [property_id, property_details_id, reducerData])
	let subTitleData = '';
	if (props.from === 'create-lease') {
		subTitleData = `landlord's`;
	} else {
		subTitleData = `your`;
	}
	return (<>
		<SidePanel
			isShow={isShow}
			isHide={handleClose}
			title={props.from === 'create-lease' ? 'Select Tenant(S)' : 'Select your tenant'}
			subTitle={`Select a tenant from the ${subTitleData} contact list`}
			extraClasses={'confirmList'}
		>
			<div className='deactive-reasons sidepanel-body checklist-tenant'>
				<SearchInput extraCalss={'mb-3'} searchLocation={searchLocation} placeHolder={props.from !== 'create-lease' ? 'Type to search tenant' : 'Search user'} setSearch={(data) => customSearch(data)} />
				{(tenantList && tenantList !== null) ?
					<>
						{tenantList.map((data, i) => {
							return <div className={props.from !== 'create-lease' ? 'row border-reason-checkbox checkbox-left check-status' : 'row border-reason-checkbox checkbox-left check-status for-create-lease'} >
								<div className='col-12' key={i}>
									<div className='form-group mb-0'>
										<div className='custom-control custom-checkbox large'>
											<input type='checkbox' className='custom-control-input' id={'customCheck' + data.tenant_id}
												name={data.tenant_id}
												checked={getSelectedList.find(elm => elm.tenant_id === data.tenant_id)} onChange={(e) => onValueChange(e, data)}
											/>
											<label className='custom-control-label pl-2 pt-1' htmlFor={'customCheck' + data.tenant_id}>{data.name} {data.is_invited === 1 ? <span className='invited'>invited</span> : ''}</label>
										</div>
									</div>
								</div>
							</div>
						})}
					</>
					: <h4 className='edit-sub text-center'>There is no tenant found</h4>}


				<Form.Group as={Col} md='12' className='mt-5'>
					<LargeButton isIcon={true} title='Add tenant outside of Rent Panda' variant='dark' onClick={() => openAddTenantWindow(true)} />
				</Form.Group>
			</div>

			<div className='fixed-bottom d-flex justify-content-between'>
				<CancelButton onClick={() => handleCloseConfirm()} title='back' style={{ color: '#000', fontWeight: 'bold' }} />
				<LargeButton title='add' onClick={() => addAndInvite()} style={{ width: '150px' }} />
			</div>
		</SidePanel>
	</>);
}
export default ConfirmListTenants;