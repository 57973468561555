import React, { useState, useEffect } from "react";
import { ModalBox } from "../../../components";
import './index.css';
function ImageGalary(props) {
  const { show, onHide, modalImage,className } = props;
  const [currentImage, setCurrentImage] = useState(modalImage.image);

  useEffect(() => {
    setCurrentImage(modalImage.image);
  }, [modalImage.image]);

  return (
    <ModalBox
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
      extraClassUpper={`modal-border-none  imageGalary ${className}`}
     >
      <div
        style={{
          padding: "0 4% 4% 4%",
          display: "flex",
          flexDirection: "column",
          width:'100%'
         }}
      >
        <img src={currentImage} />

        {modalImage?.selectedItem?.images?.length > 1 && (
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              height: 120,
              marginTop: 20,
            }}
          >
            {modalImage?.selectedItem?.images?.map((i) => (
              <img
                style={{
                   marginRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                   flex:1
                }}
                onClick={() => {
                  setCurrentImage(i);
                }}
                src={i}
              />
            ))}
          </div>
        )}
      </div>
    </ModalBox>
  );
}

export default ImageGalary;
