import React from 'react'
import { Image, Col } from 'react-bootstrap'

function EmptyState() {
    return (
        <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '0 0 1rem' }}>
            <Image src={require("../../../_assets/images/RP-Illustration.svg")} style={{ marginBottom: '1.5rem', maxHeight: '50vh', maxWidth: '60vw' }} />
            <p className="empty-tenant-list-screen">You haven’t received any inquiries for this property yet</p>
        </Col>
    )
}

export default EmptyState
