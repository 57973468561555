import React from 'react'
import { Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import moment from "moment";

function StudentInfo(props) {
    const { employmentStatus, emp = {} } = props
    console.warn({ emp });
    const formatTime = (time) => {
        return moment(time).format('ll');
    }

    return (
        <>
            <Col md={6}>
                <span className="card-labels">Employment Status</span>
                <p className="card-labels-desc">{employmentStatus}</p>

            </Col>
            <Col md={6}>
                <span className="card-labels">School Attended</span>
                <p className="card-labels-desc">{emp.school_attend}</p>
            </Col>
            <Col md={6}>
                <span className="card-labels">Period</span>
                <p className="card-labels-desc">{formatTime(emp.start_date)} - {formatTime(emp.end_date)}</p>
            </Col>
            <Col md={6}>
                <span className="card-labels">Program Taking</span>
                <p className="card-labels-desc">
                    {emp.program_taking}
                </p>
            </Col>

        </>
    )
}

export default StudentInfo
