import React from "react";
import "./style.scss";
import { ButtonGroup, Button, Modal } from "react-bootstrap";

const ModalBox = (props) => {
  const { title, extraClassUpper, disable=false, closeButtonHeader,extraClass, extraBtnStyle, actionbuttontitle, buttonaction, children, type, backbuttontitle, backaction, btnelementid, btndatadescription, lnkelementid, lnkdatadescription, customActionButtonClass } = props;
  let shouldCloseButton = closeButtonHeader === undefined ? true : closeButtonHeader
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={extraClassUpper}
    >
      <Modal.Header closeButton={shouldCloseButton}>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className={extraClass}>
        {children}
      </Modal.Body>
      {type ?
        <ButtonGroup className={customActionButtonClass ? customActionButtonClass +' '+'condition-button' : 'condition-button'}>
          <Button onClick={backaction}>{backbuttontitle}</Button>
          <Button className='border-left' disabled={disable} onClick={buttonaction}>{actionbuttontitle}</Button>
        </ButtonGroup> :
        actionbuttontitle &&
        <Modal.Footer className={extraBtnStyle ? 'extraBtnStyle' : ''}>
          <Button id={btnelementid} data-description={btndatadescription} onClick={buttonaction} className={"modal-submit-button " + extraBtnStyle} block>{actionbuttontitle}</Button>
          <u id={lnkelementid} data-description={lnkdatadescription} style={{ cursor: 'pointer' }} onClick={backaction}>{backbuttontitle}</u>
        </Modal.Footer>}
    </Modal>
  );
}
export default ModalBox;
