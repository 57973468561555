export const StepTwoValidationSchema = {
    unit_number: [
        { rule: "required", message: "Unit number required" },
    ],
    date: [
        { rule: "required", message: "Date available required" },
    ],
    lease_duration: [
        { rule: "required", message: "lease duration required" },
    ],
    price_per_month: [
        { rule: "required", message: "Price per month required" },
    ],
}