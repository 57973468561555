import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import "./style.scss";
import TimePicker from "../TimePicker/TimePicker";

/* https://tonix-tuft.github.io/react-tempusdominus-bootstrap/ */

const TimePickerComponent = (props) => {
  const { name, id, viewDateParam, handleChangeTime, placeholder } = props;
  console.warn({ viewDateParam })
  return (
    <TimePicker
      {...props}
    />

  );
};
export default TimePickerComponent;
