import React from 'react';
import "./style.scss"
import PostCard from "./PostCard/PostCard";
import arrowForward from "../../../_assets/images/arrow-forward.svg"
import { Link } from "react-router-dom";

const CardDisplay = ({ gridData, cardItems, viewMoreAction}) => {
    const {classTag, sectionTitle, header, cardType, extraClass} = gridData

    if (cardType === "resource") {
        return (
            <div className={`${classTag} ${extraClass !== null ? extraClass : ''}`}>
                <div className={header}>
                    <div className={"headerText"}>
                        {sectionTitle}
                    </div>
                    <Link to={"/resources/landlord/forms?for=landlord"}>
                        <p className={"viewMoreText"}>
                            view more
                            &nbsp;
                            <img src={arrowForward} alt="view more posts"/>
                        </p>
                    </Link>
                </div>
                {cardItems.map((card, index) => {
                    return (
                        <PostCard
                            key={index}
                            id={card.id}
                            headline={card.title}
                            excerpt={card.description}
                            placeholder={card.thumbnail_url}
                            cardUrl={card.form_url}
                            index={index}
                            cardType={cardType}
                        />
                    )
                })}
            </div>
        );
    }

    return (
        <div className={classTag}>
            <div className={header}>
                <div className={"headerText"}>
                    {sectionTitle}
                </div>
                <Link to={"/resources/landlord/blogs"}>
                    <p className={"viewMoreText"}>
                        view more
                        &nbsp;
                        <img src={arrowForward} alt="view more posts"/>
                    </p>
                </Link>
            </div>
            {cardItems.map((card, index) => {
                return (
                    <PostCard
                        key={index}
                        id={card.id}
                        headline={card.headline}
                        excerpt={card.excerpt}
                        placeholder={card.image_url}
                        index={index}
                        cardType={cardType}
                    />
                )
            })}
        </div>
    );
};

export default CardDisplay;

