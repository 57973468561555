import React, { useState } from "react";
import { SidePanel, ModalBox, DatePickerComponent } from "../../../components"
import { Form, Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { postAPI } from "../../../../Api";
import { validate } from "../../../../Utils/Validation/validation";
import { VerificationSchema } from './VerificationSchema';
import NumberFormat from "react-number-format";
import { logger } from "../../../../Utils";
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';

const log = logger("Verification");
const Verification = (props) => {
  const { isShow, handleClose, propertyData } = props
  const [date, setDate] = useState(new Date());
  const [appointment_time, setAppointment_time] = useState('select');
  const [phone_number, setPhone_number] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [phoneError, setPhoneError] = useState(false);

  log.info("propertyData",propertyData);

  const OnChangeInput = (event) => {
    if (event.target.name === 'appointment_time') {
      setAppointment_time(event.target.value)
    } else if (event.target.name === 'phone_number') {
      const change = phoneNumberSanitizer(event.target.value);
      if (isNaN(Number(change))) {
        setPhoneError(true)
      } else {
        setPhoneError(false)
      }
      setPhone_number(change)
    }
  }

  const onSubmit = () => {
    if ( !isNaN(Number(phone_number)) && validate(VerificationSchema) ) {
      const url = '/landlord/create-listing/step-5/professional-photoshoot';
      const data = {
        property_id: propertyData && propertyData.property_id,
        property_details_id: propertyData && propertyData.property_details_id,
        appointment_date: moment(date).format('YYYY-MM-DD'),
        appointment_time: appointment_time,
        phone_number: phone_number
      }
      postAPI(url, data).then(response => {
        handleClose()
        setModalShow(true)
      })
    } else if ( isNaN(Number(phone_number)) ) {
      setPhoneError(true)
    }
  }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title="it’s time to verify your property"
      subTitle="Let us know when would be best to contact you and our team will be in touch to schedule an appointment.">
      <Form id="Form">
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What date can we get in touch with you?</Form.Label>
            <DatePickerComponent
              name="date"
              id="date"
              value={date}
              handleDateChange={date => setDate(date)}
              displayText={"Select Date"}
              minDate={new Date()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What time of day would be best to chat?</Form.Label>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="appointment_time"
              custom
              name="appointment_time"
              value={appointment_time}
              onChange={(e) => OnChangeInput(e)}
            >
              <option value="">Select one</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              className={phoneError === true ? 'form-control is-invalid' : 'form-control'}
              name="phone_number"
              id="phone_number"
              value={phone_number ? phone_number : ''}
              onChange={(e) => OnChangeInput(e)}
            />
            {phoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
          </Form.Group>
        </Row>
      </Form>
      <div className="container-footer">
        <Button variant="success" onClick={onSubmit}>Submit</Button>
      </div>
    </SidePanel>

    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => {
        setModalShow(false)
      }}
    >
      <h4>You have applied for property verification.</h4>
      <p>Our team will be in touch within 24 hours to book a time with you! </p>
    </ModalBox>
  </>);
}

export default Verification;