import React from 'react'
import { Form, Row, Col, Image, Button } from 'react-bootstrap'
import NumberFormat from 'react-number-format';

const AddTenant = props => {
    const {tenantAdded, OnRemove, OnChangeInput, addMore, singleReasonData, openSkipModal, isDeactivation}= props;
    return (
        <div className='deactive-reasons sidepanel-body'>
				<Form id='Form'>
					{tenantAdded ?
						tenantAdded.map((data, i) => {
							return (
								<div key={'tenantAdded' + i} className={'tenantAdded' + i}>
									<Row>
										<Form.Group as={Col} md='12'>
											<div className='display-flex-inline-elm'>
												<Form.Label>Tenant’s Full Name</Form.Label>
												{i !== 0 &&
													<div className='sub-title d-flex justify-content-between align-items-center pt-0'>
														<Image
															className='icon cursorPointer'
															width='20px'
															height='20px'
															title='Remove'
															onClick={() => OnRemove(i)}
															src={require('../../../../../_assets/images/delete-icon.svg')}
														/>
													</div>}
											</div>
											<Form.Control
												type='text'
												placeholder='John Doe'
												name='tenantFullName'
												id={'tenantFullName' + i}
												value={data.tenantFullName}
												onChange={(e) => OnChangeInput(e, i)} />
										</Form.Group>
										<Form.Group as={Col} md='12'>
											<Form.Label>Tenant’s Email</Form.Label>
											<Form.Control
												type='text'
												placeholder='name@mail.com'
												name='tenantEmailId'
												id={'tenantEmailId' + i}
												value={data.tenantEmailId}
												onChange={(e) => OnChangeInput(e, i)} />
										</Form.Group>
										<Form.Group as={Col} md='12'>
											<Form.Label>Tenant’s Phone Number</Form.Label>
											 		<NumberFormat
																		format='###-###-####'
																		mask='_'
																		placeholder='145-602-758'
																		name='tenantPhone'
																		id={'tenantPhone'+i}
																		className='form-control'
																		value={data.tenantPhone}
																		onChange={(e) => OnChangeInput(e, i)}
																	 
																	/>
										</Form.Group>
									</Row>
									<hr />
								</div>)
						}) : ''}
				</Form>
				<Row>
					<Col md='12'>
						<Button variant="link" onClick={() => addMore()} className='pull-right add-more'>+ Add More</Button>
					</Col>
				</Row>
				{(isDeactivation || (singleReasonData && singleReasonData.order === 2)) && <Row className="fixed-bottom skip-link">
					<Col md='12'>
						<Button variant="link" onClick={() => openSkipModal('addTenant')} className="skip-btn mb-4">{(isDeactivation && singleReasonData === 4) ? "Skip and Deactivate" : "Skip"}</Button>
					</Col>
				</Row>}
			</div>
    )
}

export default AddTenant
