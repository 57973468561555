import React from 'react';
import { AppLayout, PageTitleHeader, LargeButton } from '../..';
import '../style.scss';
import { withRouter } from "react-router-dom";

const N9Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (<>
   <AppLayout>
    <PageTitleHeader
      isArrow={true}
      onClick={() => back()}
      title={'Important Information About the Landlord and Tenant Board'}
      subTitle={'Tenant’s notice to end tenancy (N9 Form)'}/>

      <div className="notice-info-card">
        <h6 className="form-title">The termination date</h6>
        <p className="mt-3">For most types of tenancies (including monthly tenancies) the termination date must be atleast 60 days after the tenant gives the landlord this notice. Also, the termination date
        must be the last day of the rental period. For example, if the tenant pays on the first dayof each month, the termination date must be the last day of the month. If the tenancy is
        for a fixed term (for example, a lease for one year), the termination date cannot be earlier than the last date of the fixed term.</p>

        <h5>
         <b>  Exceptions</b>
          </h5>
        <ul>
          <li>The termination date must at least 28 days after the tenant gives the landlord this notice if the tenancy is daily or weekly (the tenant pays rent daily or weekly). Also, the termination date must be the last day of the rental period. For example, if the tenant pays rent weekly each Monday, the termination date must be a Sunday. If the tenancy is for a fixed term, the termination date cannot be earlier than the last date of the fixed term</li>
          <li>The termination date can be earlier than the last day of a fixed term tenancy (but still must be the last day of a rental period) if the tenant is giving this notice because:
            <ul>
              <li>the tenancy agreement was entered into on or after April 30, 2018,</li>
              <li>the landlord was required to use the Residential Tenancy Agreement (Standard Form of Lease) form but did not,</li>
              <li>the tenant demanded in writing that the landlord give them this form, and more than 21 days have passed since the tenant made their demand, and the landlord has not provided the form, or the landlord provided the form less than 30 days ago but it was not signed by the tenant.</li>
              <li>A special rule allows less than 60 days' notice in situations where the tenant would normally be required to give 60 days notice (for example, monthly tenancies). The tenant can give notice for the end of February no later than January 1st and can give notice for the end of March no later than February 1st.</li>
            </ul>
          </li>
        </ul>

        <h6 className="form-title mt-4">The landlord can apply to end the tenancy</h6>
        <p className="mt-3">The landlord can apply to the Board for an order to end the tenancy and evict the tenant as soon as the tenant gives the landlord this notice. However, if the Board issues an order ending the tenancy, the order will not require the tenant to move out any earlier than the termination date the tenant included in this notice.</p>

        <h6 className="form-title mt-4">When a tenant can give 10 days' notice</h6>
        <p>The termination date set out in this notice can be 10 days (or more) after the tenant gives this notice to the landlord if the landlord has given the tenant either an N12 Notice to End your Tenancy or an N13 Notice to End your Tenancy. The termination date does not have to be the last day of a rental period.</p>

        <h6 className="form-title mt-4">Ending the tenancy when the landlord refused to allow the tenant to assign the rental unit</h6>
        <p>The tenant can use this notice to end the tenancy if the tenant asked the landlord for permission to assign the rental unit to someone else, and the landlord refused. 
          <br/>The termination date must be:</p>
        <ul>
          <li>at least 28 days after the tenant gives the notice to the landlord if the tenancy is daily or weekly,</li>
          <li>at least 30 days after the tenant gives the notice to landlord if the tenancy is anything other than daily or weekly.</li>
        </ul>
        <p>The termination date does not have to be the last day of a rental period or the last day of a fixed term.</p>

        <h6 className="form-title mt-4">Ending the tenancy in a care home</h6>
        <p>If the tenant lives in a care home, the termination date in this notice can be 30 days (or more) after the tenant gives the notice to the landlord. The termination date does not have to be the end of a rental period or the last day of a fixed term.</p>
        <p>If a tenant who lives in a care home gives this notice to the landlord, they can also give the landlord a 10-day notice for the landlord to stop providing care services and meals. If the tenant gives the landlord the 10-day notice, the tenant is not required to pay for care services and meals after the end of the 10-day period.</p>

        <h6 className="form-title mt-4">Tenant’s can’t be required to sign this notice</h6>
        <p>A landlord cannot require the tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a unit. A tenant does not have to move out based on this notice if the landlord required the tenant to sign it when the tenant agreed to rent the unit.</p>
        
        <h5 className="mt-2">Exceptions</h5>
        <p>A landlord can require a tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a rental unit in the following two situations:</p>
        <ul>
          <li>The tenant is a student living in accommodation provided by a post-secondary institution or by a landlord who has an agreement with the post-secondary school to provide the accommodation.</li>
          <li>The tenant is occupying a rental unit in a care home for the purposes of receiving rehabilitative or therapeutic services, and
            <ul>
              <li>The tenant agreed to occupy the rental unit for not more than 4 years,</li>
              <li>the tenancy agreement set out that the tenant can be evicted when the objectives ofproviding the care services have been met or will not be met, and</li>
              <li>the rental unit is provided to the tenant under an agreement between the landlord and a service manager under the Housing Services Act, 2011.</li>
            </ul>
          </li>
        </ul>
        

      </div>
      <div className="d-flex justify-content-end button-extra-margin">
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal} 
          extraClass={'get-paralegal-help-btn'}

        />
      </div>
  </AppLayout>
  </>);
}

export default withRouter(N9Info);