import React from 'react'

import './style.scss'

function CompleteWarningMessage(props) {
    const { message, ctaText, onClick, colorStyle, extraMessage } = props
    return (
        <div style={{ background: colorStyle === 'success' ? '#009D1025' : 'rgba(242,116,0,0.25)'}} className='rp-warning-wrapper'>
            <p style={{ color: colorStyle === 'success' ? '#009D10' : 'rgba(242,116,0,1)'}}> {message} <span onClick= {onClick} > {ctaText}</span> {extraMessage ? extraMessage : ''}</p>
        </div>
    )
}

export default CompleteWarningMessage