import { getAPI } from "../../Api";

export const getAds = async () => {
    try {
        const response = await getAPI('/cms/advertisements-list')
        const res = response?.data?.data;
        return res || []
    } catch (err) {
        return []
    }
}