import React from "react";
import NumberFormat from "react-number-format";

const CostNumberFormat = (props) => {
    const { format, name, id, value, allowNegative, onBlur, updateField, placeholder, classname, pre, su, disabled, required, minValue = 0, decimalScale = 2, decimalSeparator = "." } = props
    return (
        <NumberFormat
            format={format}
            thousandSeparator={true}
            prefix={pre}
            suffix={su}
            allowNegative={allowNegative ? allowNegative : false}
            allowEmptyFormatting={false}
            allowLeadingZeros={false}
            value={value}
            onChange={(e) => updateField({ target: { name: e.target.name, id: e.target.id, value: e.target.value.replace('$', '').replaceAll(',', '').replace('+', '') } })}
            onBlur={onBlur}
            className={classname}
            placeholder={placeholder}
            id={id}
            name={name}
            disabled={disabled}
            required={required}
            decimalScale={decimalScale || false}
            decimalSeparator={decimalSeparator || false}
            isAllowed={(value) => {
                const { formattedValue, floatValue } = value;
                return formattedValue === '' || (floatValue > minValue && floatValue <= 99999999999);
            }}
        />
    );
}
export default CostNumberFormat;