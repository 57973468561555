import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { getLeaseDetailPath, notificationPagination } from '../../../../Store/actions/Action';
import { logger } from '../../../../Utils';
import { multiCheckbox } from '../../../../Utils/MultiCheckbox/MultiCheckbox';
import '../style.scss';
import NotificationTable from './NotificationTable';

const log = logger('AdminNotification');
const AdminNotification = (props) => {
    const { userDetails, openNotificationRequest, setClickedData, isConfirmed } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    // const [user_id, setUserId] = useState('');
    // const [user_type, setUserType] = useState('');
    const [section, setSection] = useState(['all']);
    const [tabledata, setTableData] = useState([]);
    const dispatch = useDispatch();
    const pagination = useSelector(state => state.propertyDetailsReducer.previousPageNotification)
    log.info(pagination);
    // const dataPass = {
    //     'section': section.includes('all') ? [] : section
    // }

    let userColumns = [
        {
            header: 'SECTION',
            width: '30%'
        },
        {
            header: 'TYPE',
            width: '40%'
        },
        {
            header: 'STATUS',
            width: '30%'
        }
    ];

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        dispatch(notificationPagination({"property_details_id": property_details_id, "property_id": property_id, notificationPagination: nextPage}));
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        dispatch(notificationPagination({"property_details_id": property_details_id, "property_id": property_id, notificationPagination: prevPage}));
    }
    const goToProfileDetail = (userData) => {
        log.info(userData)
        if (userData.section === 'Lease') {
            if (Number(userData.rp_lease) === 0) {
                dispatch(getLeaseDetailPath('notification'))
                props.history.push({ pathname: `/lease-detail/${userDetails.user_type.toLowerCase()}/${userData.id}/${userDetails.id}`, state: { path: props.path } })
            } else {
                props.openLeaseDuration(true)
                setClickedData(userData);
            }

        } else if (userData.section === 'Maintenance') {
            dispatch(getLeaseDetailPath('notification'))
            props.history.push({ pathname: `/maintainance-details/${userDetails.user_type.toLowerCase()}/${userData.id}/${userDetails.id}`, state: { path: props.path } })
        } else if (userData.section === 'Notice') {
            dispatch(getLeaseDetailPath('notification'))
            props.history.push({ pathname: `/notice-details/${userDetails.user_type.toLowerCase()}/${userData.id}/${userDetails.id}`, state: { path: props.path } })
        } else if (userData.section === 'Inspection') {
            dispatch(getLeaseDetailPath('notification'))
            props.history.push({ pathname: `/inspection-details/${userDetails.user_type.toLowerCase()}/${userData.id}/${userDetails.id}`, state: { path: props.path } })
        } else if (userData.section === 'Request') {
            openNotificationRequest(true)
            let data = userData;
            data.request_type_id = '5';
            setClickedData(data);
        }
    }

    const sortSection = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(section, item, isChecked);
        setSection(fetchedFilter)
        const filterDataInside = {
            'section': fetchedFilter.includes('all') ? [] : fetchedFilter
        }
        const url = `/admin/listing/notifications/${property_id}/${property_details_id}`
        postAPI(url, filterDataInside)
            .then(res => {
                setTableData(res.data.data)
            })
    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [
        props.match.params.property_id,
        props.match.params.property_details_id,
    ]);

    useEffect(() => {
        if (property_details_id && property_id) {
            let urlInside = '';
            if ( pagination !== null && (pagination.property_details_id === property_details_id && pagination.property_id === property_id && pagination.notificationPagination > 1) ) {
                urlInside = `/admin/listing/notifications/${property_id}/${property_details_id}?page=${pagination.notificationPagination}`;
            } else {
                urlInside = `/admin/listing/notifications/${property_id}/${property_details_id}`;
            }
            const data = {
                'section': section.includes('all') ? [] : section
            }
            postAPI(urlInside, data).then(res => {
                if (res.status === 200) {
                    setTableData(res.data.data)
                }
            })
        }
    }, [property_details_id, property_id, pagination, section]);

    useEffect(() => {
        if (isConfirmed === true) {
            const urlInside = `/admin/listing/notifications/${property_id}/${property_details_id}`;
            const data = {
                'section': section.includes('all') ? [] : section
            }
            postAPI(urlInside, data).then(res => {
                log.info(res)
                if (res.status === 200) {
                    setTableData(res.data.data)
                }
            })
        }
    }, [isConfirmed, property_details_id, property_id, section]);

    return (
        <>

            {tabledata && tabledata.data ?
                <NotificationTable
                    columns={userColumns}
                    tableData={tabledata.data}
                    to={tabledata.to}
                    from={tabledata.from}
                    current_page={tabledata.current_page}
                    last_page={tabledata.last_page}
                    per_page={tabledata.per_page}
                    total={tabledata.total}
                    goToProfileDetail={(data) => goToProfileDetail(data)}
                    clickNext={() => onClickNext(tabledata.current_page)}
                    clickPrev={() => onClickPrev(tabledata.current_page)}
                    sort_by_section={section}
                    sortSection={(data) => sortSection(data)}
                />
                : ''}
        </>
    );
}

export default withRouter(AdminNotification);
