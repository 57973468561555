import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image } from 'react-bootstrap';
import { Nav } from "react-bootstrap";
import "./style.scss";

class JombotronSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="sec-jombotron">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 jumbotron-left">
                <div className="text-center mb-4 d-lg-none">
                  <Image src={require("../../../../../_assets/images/landing-pages/landing-banner.svg")} alt="Banner" />                  
                </div>
                <h2 className="jombotron-title text-left">change the way<br/> you rent.
                </h2>
                <p className="jombotron-disc-title">renting can really suck. but it doesn’t have to. with three ways to 
                rent, you can feel as good as a warm panda hug. </p>
              </div>
              <div className="col-lg-6 jumbotron-right">
                <div className="text-center d-none d-lg-block">
                  <Image src={require("../../../../../_assets/images/landing-pages/landing-banner.svg")} alt="Banner" />                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(JombotronSection);
