import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row, Form } from "react-bootstrap";
import { LargeButton, Loader, ThreeDotsDropdown, ModalBox } from "..";
import { Link, useHistory, withRouter } from 'react-router-dom';
import "./style.scss";
import { logger, toaster } from "../../../Utils";
import { postAPI, getAPI } from '../../../Api';
import { useSelector, useDispatch } from "react-redux";
import { setReceiverData, setViewingRequestId, getCalenderData, setTenancyApplicationId, setCreditCheckRequestId, setIsProfileCompleted, setHeaderContent, setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";
import { validate } from '../../../Utils/Validation/validation';
import DatePickerComponent from '../DatePickerComponent/DatePickerComponent';
import NumberFormat from "react-number-format";
import { DisclaimerText } from "../Tenancy/TenancyForm/TenancyForm";
import backArrow from '../../../_assets/images/arrowVector.png'
import moment from "moment";
import { getQueyParam, isProfileCompletedForSelectedTenant } from "../../Landlord/PropertyDetails/helper";
import { updateRawData } from "../../../Store/actions/RawDataAction";
import { askToCompleteProfile, cancelScreening, getShowingData, cancelShowingFromTenant } from "./helper";
import dangerIcon from '../../../_assets/images/danger.svg'
import { getCertnReport } from "../CertnIntegration/Steps/helper";
import { ConfirmShowingValidationSchema } from '../Showings/ConfirmShowing/ConfirmShowingValidationSchema';
import TenantHeader from "./TenantHeader";
import CompleteProfileModal from "../CompleteProfileModal/CompleteProfileModal";


{/*
*************************
'request_type' Values
*************************
1 - Showing Request
2 - Showing invitation
3 - Lease
4 - Apply Tenancy Application
5 - Credit Check Request
6 - Complete Profile Request
7 - Accept lease
*/}

{/*
*************************
'lease_status' Values
*************************
0 => 'In progress',
1 => 'Delivered', // delivered to tenant / Pending
2 => 'Signed'    
*/}

{/*
*************************
'type' Values
*************************
0 => None,
1 => Showing invitation //only Landlord can do
2 => Showing invitation confirm
3 => Showing invitation reject
4 => Showing invitation reschedule
5 => Showing Request //only Tenant can do
6 => Showing Request confirm
7 => Showing Request reject
8 => Showing Request reschedule
9 => Showing Request cancel
10 => Invite to apply // From landlord
11 => Accept Tenancy Application // by tenant
12 => Reject application invitation // by tenant
13 => Tenancy application // from tenant
14 => Reject tenancy application // by landlord
15 => Credit check request
16 => Credit check request confirm
17 => Credit check request reject
18 => Credit check pulled
19 => Profile incomplete
20 => Create / signed lease // by landlord
21 => Update lease
22 => Signed lease / by tenant
*/}

const log = logger("ChatBox");

const creditCheckValidationSchema = {
	date_of_birth: [
		{ rule: "required", message: "Date Of Birth is required" },
	],
	sin: [
		{ rule: "required", message: "SIN is required" },
		{ rule: "sinNumber", message: "SIN # needs to be 9 digits." }
	]
}

const ChatBox = (props) => {
	const {
		isConcentVerified,
		messageData,
		listData,
		headerContent,
		showTenantList,
		setShowTenantList,
		conversation_id,
		grid,
		sendMessage,
		message,
		handleChange,
		role,
		setShowingRequest,
		setConfirmShowing,
		viewProfile,
		set_tenancy,
		messageLoader,
		blockUser,
		removeFromContact,
		setLeaseOptionsScreen,
		setCertnPurchaseReport,
		setLeasePdfViewScreen,
		is_reSchedule,
		setReSchedule,
		is_blocked,
		setShowPropertyDetails,
		isDashboard
	} = props;
	const [reject_reason, setReject_reason] = useState(null);
	const [cancelShowingRequestModal, setCancelShowingRequestModal] = useState(false);
	const [tenantIsNotScreenedModal, setTenantIsNotScreenedModal] = useState(false);
	const [askTenantToCompleteProfileModal, setAskTenantToCompleteProfileModal] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBlockShow, setModalBlockShow] = useState(false);
	const [credit_check_request_id, setCredit_check_request_id] = useState(null);
	const [creditCheckModalShow, setCreditCheckModalShow] = useState(false);
	const [profileIncompleteModalShow, setProfileIncompleteModalShow] = useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [getReportProfileIncompleteModalShow, setGetReportProfileIncompleteModalShow] = useState(false);

	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');

	const dispatch = useDispatch();

	const {consentStatus,tenantProfileInfo} = useSelector(state => state.rawData)
	const leaseData = useSelector(state => state.messageDetailsReducer.leaseData);
	let is_ProfileComplete = useSelector(state => role === 2 ? state.messageDetailsReducer.headerContent.is_profile_completed : state.UserProfileReducer.userProfile.basic_info?.is_profile_completed);
	const existingShowingRequestId = useSelector(state => state.messageDetailsReducer.existingShowingRequestId);
	console.log(`existingShowingRequestId`, existingShowingRequestId)
	const existingShowingSentBy = useSelector(state => state.messageDetailsReducer.existingShowingSentBy);
	const existingTenancyApplicationId = useSelector(state => state.messageDetailsReducer.existingTenancyApplicationId);
	const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);
	const isLeaseExists = useSelector(state => state.messageDetailsReducer.isLeaseExists);
	const isTenancyApplicationReceived = useSelector(state => state.messageDetailsReducer.isTenancyApplicationReceived);
	const isPropertyRented = useSelector(state => role === 2 ? state.propertyDetailsReducer?.propertyDetails?.details?.length && state.propertyDetailsReducer?.propertyDetails?.details[0]?.status : 2) === 4
	const propertyDetails = useSelector(state => state.propertyDetailsReducer?.propertyDetails)
	const userId = useSelector((state) => state.auth.id);
	const creditCheckRequestId = useSelector(state => state.messageDetailsReducer?.creditCheckRequestId);
	console.log({ creditCheckRequestId });
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [sin, setSin] = useState('');
	const [creditCheckModal, setCreditCheckModal] = useState(false);
	const [showCancelScreening, setShowCancelScreening] = useState(false)
	const showLargeButtons = leaseData?.status !== 2 && leaseData?.status !== 4 && !isPropertyRented;
	const isDownloadReport = getQueyParam(window.location.search, 'download_report')
	const [showCompleteProfileForShowing, setShowCompleteProfileForShowing] = useState(false)

	if(role === 2 && tenantProfileInfo)
	{
		is_ProfileComplete=isProfileCompletedForSelectedTenant(tenantProfileInfo)
	}

	useEffect(() => {
		if (isDownloadReport) {
			getReport();
		}
	}, [isDownloadReport]);

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		let params = new URLSearchParams(props.location.search)
		if (params?.get('profile') === "true") {
			set_tenancy(true);
		}
	}, [props]);


	useEffect(() => {
		const section = document.querySelector('#messageBody');
		if (section) {
			section.scrollTop = section.scrollHeight;
		}

	}, [props, messageData])

	useEffect(() => {
		if (messageData?.length) {
			const lastMessage = messageData[messageData?.length - 1];
			if (lastMessage.title === 'Profile Completed') {
				dispatch(setIsProfileCompleted(true));
				dispatch(setHeaderContent({ is_profile_completed: true }));

			}
		}
	}, [messageData?.length]);

	useEffect(() => {
		setSin('')
		setDateOfBirth('')
	}, [creditCheckModal])

	console.log({ messageData });
	const handleDate = (date) => {
		setDateOfBirth(date)
	}

	const submitUserDOBAndSin = () => {
		if (validate(creditCheckValidationSchema)) {
			let data = {
				'date_of_birth': dateOfBirth,
				'sin_number': sin
			}
			const age = moment().diff(dateOfBirth, 'years', true)
			if (age <= 18) {
				toaster('error', 'Age must be greater than or equal to  18 years')
				return
			}
			postAPI('/tenant/profile/set-certn-details', data).then(async response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					updateRawData({showLoader:true})
					await getCertnReport(userId, conversation_id, 'tenant')

					// creditCheckAction('accept')
					setCreditCheckModal(false);
					updateRawData({showLoader:false})
				}
			})
				.catch(error => {
				});
		}
	}

	const handleReasonChange = (e) => {
		setReject_reason(e.target.value)
	}

	const onReject = async() => {
		if (validate(ConfirmShowingValidationSchema)) {
			const res = await cancelShowingFromTenant(conversation_id,existingShowingRequestId,reject_reason)
			if(res.status){
				setCancelShowingRequestModal(false)
				setReject_reason(null)
			}
		}
	}

	const creditCheckAction = (status) => {
		let url = '';
		if (status === 'accept') {
			url = '/tenant/accept-credit-check-request';
		} else if (status === 'reject') {
			url = '/tenant/reject-credit-check-request';
		}
		const data = {
			"credit_check_request_id": credit_check_request_id || creditCheckRequestId,
			"conversation_id": conversation_id
		}
		postAPI(url, data).then(res => {
			if (res.status !== 200) {
				toaster('error', res.response.data.message)
			} else if (res.status === 200) {
				setCreditCheckModalShow(false)
			}
		})
	}

	const viewLeaseAction = () => {
		if ((leaseData?.status === 2)) {
			props.history.push({
				pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`,
			});
		} else {
			onViewLease({ 'property_lease_id': leaseData?.id, 'is_offline_lease': leaseData?.is_signed_offline, 'is_updated': leaseData?.is_updated, 'is_sent_to_tenant': leaseData?.is_sent_to_tenant });
		}
	}

	const onCompleteProfileAction = (forCertn = false) => {
		if (role === 3) {
			props.history.push({
				pathname: `/profile`,
				state: {
					redirect: `/property-details/${property_id}/${property_details_id}/messages`,
					conversation_id,
					forCertn,
					creditCheckRequestId
				}
			});
		}
		else {
			viewProfile()
		}
	}

	const onApplyAction = () => {
		if (!is_blocked) {
			if (is_ProfileComplete === 0 && role === 3) {
				setProfileIncompleteModalShow(true);
			} else if (is_ProfileComplete === 0 && role === 2) {
				viewProfile();
			} else {
				onApplyTenancy();
			}
		}
	}

	const onApplyTenancy = () => {
		dispatch(setReceiverData(null));
		dispatch(getCalenderData(null));
		dispatch(setViewingRequestId(null));
		dispatch(setTenancyApplicationId(existingTenancyApplicationId));
		set_tenancy(true);
	}

	const onShowingAction = async () => {
		if (!is_ProfileComplete && role === 3) {
			setShowCompleteProfileForShowing(true)
			return
		}
		if (!is_blocked) {
			if (existingShowingRequestId && role === 2) {
				const res = await getShowingData(existingShowingRequestId)
				if (res.viewingRequestDates.length) {
					setConfirmShowing(true)
					dispatch(getCalenderData(null))
					dispatch(setViewingRequestId(existingShowingRequestId))
				} else {
					dispatch(setViewingRequestId(null));
					setShowingRequest(true);
					setReSchedule(false);
				}
			} else if (existingShowingRequestId) {
				setConfirmShowing(true)
				dispatch(getCalenderData(null))
				dispatch(setViewingRequestId(existingShowingRequestId))
			} else {
				dispatch(setViewingRequestId(null));
				setShowingRequest(true);
				setReSchedule(false);
			}
		}
	}

	const onCreditCheckConfirmationFlow = (data) => {
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));
		setCredit_check_request_id(data.credit_check_request_id);
		dispatch(setCreditCheckRequestId(data.credit_check_request_id));
		// setCreditCheckModalShow(true);
		setCreditCheckModal(true)

	}

	const onReschedule = (data) => {
		setShowingRequest(true)
		setReSchedule(true)
	}

	const onActionCertnFlow = (data) => {
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));
		updateRawData({ showLoader: true })

		const url = '/landlord/tenant-application-details/' + data.tenancy_application_id;
		getAPI(url).then(response => {



			if (response.status !== 200) {
				toaster('error', response.response.data.message)
				updateRawData({ showLoader: false })

			} else if (response.status === 200) {
				const obj = response.data.data;
				const showCompleteProfile = !(obj.userDetails.basic_info.sin_number && (obj.userDetails.current_home || obj.userDetails.past_home));

				dispatch(setIsProfileCompleted(response.data.data.userDetails.basic_info.is_profile_completed))

				if (showCompleteProfile) {
					updateRawData({ showLoader: false })

					setGetReportProfileIncompleteModalShow(true);
					return;
				}
				// if (response.data.data.userDetails.basic_info.is_profile_completed !== 1) {
				// 	setGetReportProfileIncompleteModalShow(true)
				// 	return
				// }
				const res = response.data.data.tenancyApplicationData;

				if (res.tenant_consent_to_credit_check === 1) {
					setCredit_check_request_id(data.credit_check_request_id);
					dispatch(setCreditCheckRequestId(data.credit_check_request_id));
					checkConcentReport(data);
				} else {
					onApplyAction();
				}
				setTimeout(() =>
					updateRawData({ showLoader: false }), 700)


			}

		})
			.catch(error => {
				updateRawData({ showLoader: false })

			});
	}

	const getReport = () => {
		const url = `/landlord/check-certn-report/${tenant_id}/${conversation_id}`;
		updateRawData({ showLoader: true })
		getAPI(url).then(response => {
			updateRawData({ showLoader: false })

			if (response.status !== 200) {
				toaster('error', response.response.data.message);
			} else if (response.status === 200) {
				//log.info("response",response.data.data.report_url);
				if (isDownloadReport) {
					const uri = window.location.toString();
					const cleanUri = uri.substring(0, uri.indexOf("?"));
					window.history.replaceState({}, document.title, cleanUri);

				}
				window.open(response.data.data.report_url);
			}

		})
			.catch(error => {
				updateRawData({ showLoader: true })

			});
	}

	const checkConcentReport = (data) => {
		if (data.tenancy_application_id) {
			let url = '';
			if (role === 2) {
				url = `/landlord/tenant-application-details/${data.tenancy_application_id}`;
			} else if (role === 3) {
				url = `/tenant/tenancy-application-details/${data.tenancy_application_id}`;
			}
			getAPI(url).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					dispatch(setIsProfileCompleted(response.data.data.userDetails.basic_info.is_profile_completed))
					const basic_info = res.userDetails.basic_info;
					if (basic_info.is_consent_payment_completed) {
						if (basic_info.is_certn_verified) {
							getReport();
						} else {
							setReportModalShow(true);
						}
					} else {
						setCertnPurchaseReport(true);
					}
				}
			})
				.catch(error => {
				});
		}
	}

	const onTenancyApplicationFlow = (data) => {
		dispatch(setReceiverData(data));
		dispatch(setTenancyApplicationId(data.tenancy_application_id));
		setCredit_check_request_id(data.credit_check_request_id);
		dispatch(setCreditCheckRequestId(data.credit_check_request_id));
		dispatch(getCalenderData(null));
		// if (is_ProfileComplete === 0 && role === 2) {
		// // if (role === 2) {
		// 	viewProfile();
		// }

		// if (role === 2) {
		set_tenancy(true);
		// }

	}

	const onViewLease = (data) => {
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));
		setLeasePdfViewScreen(true);
	}

	const onScreening = () => {
		if (!consentStatus || consentStatus === 4) {
			setCertnPurchaseReport(true)
		}
		else {
			setShowCancelScreening(true)
		}
	}

	const onBuildLease = () => {
		if (!is_ProfileComplete) {
			setAskTenantToCompleteProfileModal(true)
		} else {
			checkConsentStatus()
		}
	}

	const checkConsentStatus = () => {
		if (consentStatus === 0 || consentStatus === 2) {
			setTenantIsNotScreenedModal(true)
			setAskTenantToCompleteProfileModal(false)
		} else {
			dispatch(setIsLeaseFromTenantApplication(true));
			setLeaseOptionsScreen(true);
			// viewProfile()
			// props.history.push({
			// 	pathname: `/property-details/${property_id}/${property_details_id}/leases`,
			// });
		}
	}

	const getButton = () => {
		return (
			<>
				<LargeButton title={'book showing'} onClick={onShowingAction} id="BTN-landlord-msg-book-showing" dataDescription="Landlord chose to book a showing"/>
				{consentStatus !== 3 ? (
					consentStatus === 2 ? (
						<button className={'disable-btn  '} disabled>
							cancel screening
						</button>
					) : (
						<LargeButton
							extraClass={consentStatus === 2 || consentStatus === 1? 'cancel_screen' : ''}
							title={!consentStatus || consentStatus === 4 ? 'screen tenant' : 'cancel screening'}
							onClick={() => consentStatus !== 2 && onScreening()}
							id={!consentStatus || consentStatus === 4 ? 'BTN-landlord-msg-screen-tenant' : 'BTN-landlord-msg-cancel-screening'}
							dataDescription={!consentStatus || consentStatus === 4 ? 'Landlord chose to screen tenant from messages' : 'Landlord chose to cancel screening from messages'}
						/>
					)
				) : (
					<LargeButton
						extraClass={consentStatus === 3 && 'view_report'}
						title={'view report'}
						onClick={() => getReport()}
					/>
				)}
				<LargeButton title={'build lease'} id={'BTN-landlord-msg-build-lease'} dataDescription={'Landlord chose to build a lease'} onClick={() => onBuildLease()} />
			</>
		);
	};
	return (<>
		<Col md={grid ? grid : 12}>
			<div className="chat-wrapper">
				<div className="chat-box-Header m-0">
					<div>
						{headerContent &&
							<div className='chat-header-name-wrapper'>
								{!showTenantList && (role === 2 || role === 3) && <img
									alt="arrowVector"
									style={{ marginRight: '10px' }}
									src={backArrow}
									width={"18"}
									height={"13"}
									className={
										"align-top mt-2"
									}
									onClick={() => setShowTenantList(true)}
								/>}
								{<div className="chat-header-name">
									{/* <h4 className="tenant-name text-left">{headerContent.name}</h4> */}
									{role === 2 && !isDashboard && <h4 className="tenant-name text-left">{headerContent?.name}</h4>}
									{role === 2 && !isDashboard && <Button className="link-btn" onClick={() => viewProfile()} id="LNK-landlord-msg-view-profile" data-description="Landlord viewed a tenants profile">View Profile</Button>}
								</div>}
								{role === 3 && isDashboard ?
									<TenantHeader
										propertyDetails={propertyDetails}
										setShowPropertyDetails={setShowPropertyDetails}
									/> : role === 3 && <h4 className="tenant-name text-left">{headerContent?.name}</h4>}
							</div>
						}
					</div>
					<div>
						{headerContent &&
							<ThreeDotsDropdown
								icon={<Image
									width="6px"
									height="24px"
									alt="three-dots-vertical"
									src={require(`../../../_assets/images/three-dots-vertical.svg`)} />}
								actionsButton={
									<>
										{role === 2 &&
											<>
												{!isLeaseExists && <div className="dropdown-item cursor-pointer" onClick={() => setLeaseOptionsScreen(true)}> Create Lease</div>}
												{is_blocked ?
													<div className="dropdown-item cursor-pointer" onClick={() => blockUser('unblock')}> Unblock Tenant </div>
													: <div className="dropdown-item cursor-pointer" onClick={() => setModalShow(true)}> Block This Tenant </div>
												}
												<div className="dropdown-item cursor-pointer" onClick={() => setModalBlockShow(true)}> Remove From Contact List </div>
											</>}

										{role === 3 &&
											<>
												{is_blocked ?
													<div className="dropdown-item cursor-pointer" onClick={() => blockUser('unblock')}> Unblock Landlord </div>
													: <div className="dropdown-item cursor-pointer" onClick={() => setModalShow(true)}> Block This Landlord </div>
												}
											</>}
									</>
								}
							/>}
					</div>
				</div>
				{messageLoader ?
					<Loader /> :
					<Row className="chat-box-body" style={{ height: !showLargeButtons ? '38vh' : '' }}>
						{((role === 2 && (listData && listData.length !== 0)) || role === 3) ?
							<Col md={12} id="messageBody" className={messageData && messageData.length !== 0 ? "message-body-scroll" : ""} style={{ height: !showLargeButtons ? '33vh' : '' }}>
								{/* <h4 className="chat-history">Today</h4> */}
								{(messageData && messageData.length !== 0) ?
									messageData.map((data, i) => {
										if (!data?.message?.length || data.type === 16) {
											return ''
										}
										// if(!data?.message?.length || (data.request_type === 5 && data.title === 'CREDIT AND BACKGROUND CHECK COMPLETE'))
										// {
										// 	return ''
										// }
										return <React.Fragment key={'message' + i}>
											<Row>
												<Col md={12}>
													<div className={data.is_message_received ? "message-recieved" : "message-send pull-right"}>
														<div className="message-card recieved">
															{data.title && <p className="request-message-title">{data.title}</p>}
															<div className="ql-editor request-message-details" dangerouslySetInnerHTML={{ __html: data.message }} />

															{(leaseData?.status !== 2) &&
																<>
																	{data.showing_request_id && ((data.type === 3 || data.type === 7 || data.type === 9) ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onReschedule(data)
																				}}
																			>schedule a new time?</button>
																		</div>
																		: role === 3 || (role === 2 && data.title === 'SHOWING INVITATION SENT') ? ''
																			:
																			<>
																			{data.type!==2 && data.type !==8 && data.type!==6 && <div style={{ display: 'flex', marginTop: 10 }}>
																				<button
																					type="button"
																					className="request-message-btn"
																					style={{ flex: 1, borderRight: '1px solid #DBDBDB' }}
																					onClick={viewProfile}
																				>View Profile</button>
																				<button
																					type="button"
																					style={{ flex: 1 }}
																					className="request-message-btn"
																					onClick={onShowingAction}
																				>Book Showing</button>
																			</div>
																			}
																			</>
																			)}



																	{/* 3 - Lease */}
																	{data.tenancy_application_id && data.request_type === 3 ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onTenancyApplicationFlow(data)
																				}}
																			>{(data.type === 10) &&'see details' }</button>
																		</div> : ""}

																	{data.type === 21 &&
																		<div className='text-center'>
																			<button
																				type='button'
																				className='request-message-btn'
																				onClick={() => {
																					onViewLease({ ...data, 'is_updated': false, 'is_sent_to_tenant': true })
																				}}
																			>
																				view the lease
																			</button>
																		</div>
																	}

																	{/* 4 - Apply Tenancy Application */}
																	{data.request_type === 4 ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onTenancyApplicationFlow(data)
																				}}
																			>view application</button>
																		</div> : ""}

																	{/* 5 - Credit Check Request */}

																	{((role === 2) || (role === 3))
																		?
																		<div className="text-center">
																			{
																				(role === 2 ?
																					((data.type === 18 && consentStatus === 3) &&
																						<button
																							type="button"
																							className="request-message-btn"
																							style={{ marginTop: '1rem' }}
																							onClick={() => {
																								getReport()
																								// onActionCertnFlow(data)
																							}}
																						>View Screening Report</button>)
																					:
																					<>
																						{(!isConcentVerified && consentStatus!==0 && consentStatus!==4 && consentStatus !== 3 && consentStatus !== 2 && (data.title === "Screening Consent") && data.show === 1)
																							&&
																							//(!isConcentVerified && data.credit_check_request_id &&data.type!==16 && data.type!==18   && data.type!==17 && (data.type === 15 && data.title === "Screening Consent")) || (!isConcentVerified && data.is_message_received && data.type === 19)

																							<div style={{ display: 'flex', marginTop: 10 }}>
																								<button
																									type="button"
																									className="request-message-btn"
																									style={{ flex: 1, borderRight: '1px solid #DBDBDB' }}
																									onClick={() => {

																										onCreditCheckConfirmationFlow(data)
																									}}
																								>Yes</button>
																								<button
																									type="button"
																									style={{ flex: 1 }}
																									className="request-message-btn"
																									onClick={() => {
																										creditCheckAction('reject')
																									}}
																								>No</button>
																							</div>
																						}
																						{!isConcentVerified && data.credit_check_request_id && (data.type === 15 && data.title === "Please Complete Your Profile & Consent To A Screening") && role === 3 &&
																							<div style={{ display: 'flex', marginTop: 10 }}>
																								<button
																									type="button"
																									disabled={is_ProfileComplete}
																									className={is_ProfileComplete ? "request-message-btn-disabled" : "request-message-btn"}
																									style={{ flex: 1, borderRight: '1px solid #DBDBDB' }}
																									onClick={() => {
																										onCompleteProfileAction(true)
																									}}
																								>Get Started</button>
																							</div>
																						}
																					</>
																				)
																			}
																		</div> : ""}

																	{/* Cancel showing from tenant */}
																	{  (data.type===1 || data.type === 6 || data.type  === 8 || data.type  === 2 || (data.type === 5 && data.title === 'Showing Requested')) && (
																		<div style={{display:'flex',marginTop:10}}>
																			<button
																				className={!existingShowingRequestId ? 'request-message-btn-disabled' : 'request-message-btn'}
																				id={(data.type!==5 || role === 3)&& 'BTN-cancel-showing-request'}
																				data-description={(data.type!==5 || role === 3)&& 'Tenant chose to cancel showing request'}
																				type="button"
																				disabled={!existingShowingRequestId}
																				style={{flex:1,marginTop:8}}
																				onClick={() => {
																					if(data.type!==1 &&  data.type  !== 8 &&  data.type  !== 6 &&  data.type  !== 2)
																					{
																						setCancelShowingRequestModal(true)
																					}
																					else{
																						setConfirmShowing(true)
																					dispatch(setReceiverData(data))
																					dispatch(getCalenderData(null))
																					dispatch(setViewingRequestId(data.showing_request_id))
																					}

																				}}
																			>{(data.type===1 || data.type === 6 || data.type  === 8 ||  data.type  === 2) ? 'see details' : (data.type!==5 || role === 3)&& 'Cancel Showing Request'}</button>
																		</div>
																	)}


																	{/* 6 - Complete Profile Request */}
																	{(data.is_message_received && data.type === 19) && !is_ProfileComplete && data.title !== "Screening Consent" ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => onCompleteProfileAction(false)}
																			>{role === 2 ? 'View Profile' : 'Complete Profile'}</button>
																		</div> : ""}

																	{/* 7 - Accept lease */}
																	 

																</>}
																{(data.request_type === 7) ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onViewLease(data)
																				}}
																			>see details</button>
																		</div> : ""}
														</div>
														<span className="message-time">{data.created_on + ' / ' + data.time}</span>
													</div>
												</Col>
											</Row>
										</React.Fragment>
									})
									: <p className='start-convertation'>Start your conversation with the {role === 3 ? 'landlord' : 'tenant'}</p>}
							</Col> :
							<div className="no-messages width-100"><Loader /></div>}
					</Row>}


				<div className={(is_blocked) ? "chat-ctrls blur-box" : "chat-ctrls"} data-rows={props.rowCount}
					style={{ minHeight: (showLargeButtons) ? 140 : 74 }}>

					{(showLargeButtons) &&
						(!messageLoader && messageData &&
							(
								<div className="action-buttons d-flex justify-content-center">
									{role === 3 &&
										<LargeButton isDisable={((role === 2 && (listData?.length !== 0)) || role === 3) ? false : true} type="button" onClick={() => onShowingAction()}
											title={(existingShowingRequestId
												? (existingShowingSentBy === 1 ? "see showing invitation" : "see showing request")
												: (role === 2 ? "invite to showing" : "request showing"))} 
											id={(existingShowingRequestId
												? (existingShowingSentBy === 1 ? null : "BTN-see-showing-request")
												: (role === 2 ? null : "BTN-request-showing"))}
											dataDescription={(existingShowingRequestId
												? (existingShowingSentBy === 1 ? null : "Tenant chose to see their showing request")
												: (role === 2 ? null : "Tenant chose to request a showing"))}
											/>
									}

									<div className='mr-3 mobilertmar'></div>


									{role === 2 && getButton()}

								</div>
							)
						)
					}

					<Row className="chat-box-footer">
						<Col md={12}>
							<form id="Form" className="msger-inputarea" onSubmit={sendMessage}>
								<Form.Control as="textarea"
									onKeyPress={props.handleKeyPress}
									rows={props.rowCount} data-empty={props.isMessageEmpty}
									autoComplete="off" className="msger-input"
									placeholder="Your message…" id="message" name="message" value={message}
									onChange={handleChange} onPaste={handleChange} maxLength={2000} disabled={(is_blocked) ? true : false} />
								<span id="fakeTextarea">{props.handleTextWrap}</span>
								<Button type="submit" disabled={(is_blocked) ? true : false} className="msger-send-btn">
									<Image
										width="18px"
										height="18px"
										alt="message-send-btn"
										src={require(`../../../_assets/images/message-send-btn.svg`)} />
								</Button>
							</form>
						</Col>
					</Row>
				</div>
			</div>
		</Col>

		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size={role === 2 ? "md" : "sm"}
			type="condition"
			actionbuttontitle={'No'}
			buttonaction={() => {
				setModalShow(false)
			}}
			backbuttontitle={'Yes'}
			backaction={() => {
				blockUser('block')
				setModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">are you sure you want to block {(role === 2 && headerContent) ? headerContent.name : 'this landlord'}? </p>
			{role === 2 ?
				<p>you won’t see messages from or notifications about this tenant anymore. are you sure you want to block this user? </p>
				: <p>You will not receive message from this landlord anymore</p>}
		</ModalBox>

		<ModalBox
			show={askTenantToCompleteProfileModal}
			onHide={() => setAskTenantToCompleteProfileModal(false)}
			size={"sm"}
			type="condition"
			closeButtonHeader=""
			actionbuttontitle={'Yes'}
			buttonaction={async () => {
				await askToCompleteProfile(conversation_id, tenant_id)
				setAskTenantToCompleteProfileModal(false)
			}}
			backbuttontitle={'No'}
			customActionButtonClass='height-large-only'
			backaction={() => {
				checkConsentStatus()
				setAskTenantToCompleteProfileModal(false)
			}}

		>
			<p className="modal-confirmation-text">tenant profile is <br /> incomplete</p>
			<p style={{ fontSize: '20px' }}>would you like the tenant to complete their profile before you create a lease?</p>
		</ModalBox>

		<ModalBox
			show={tenantIsNotScreenedModal}
			onHide={() => setTenantIsNotScreenedModal(false)}
			size={"sm"}
			type="condition"
			closeButtonHeader=""
			actionbuttontitle={'Yes'}
			buttonaction={async () => {
				setTenantIsNotScreenedModal(false)
				dispatch(setIsLeaseFromTenantApplication(true));
				setLeaseOptionsScreen(true);
				setAskTenantToCompleteProfileModal(false)
			}}
			customActionButtonClass='height-large'
			backbuttontitle={'No'}
			backaction={() => {
				setAskTenantToCompleteProfileModal(false)
				setTenantIsNotScreenedModal(false)
				onScreening()
			}}
		>
			<div style={{ marginBottom: '1.5rem' }}>
				<img src={dangerIcon} />
			</div>
			<p className="modal-confirmation-text">this tenant has not been<br /> screened.</p>
			<div style={{ width: '16rem', margin: '0 auto' }}>
				<p style={{ fontSize: '20px' }}>You have not run a credit or background check on this tenant. Are you sure you want to create a lease without screening this tenant?</p>
			</div>
		</ModalBox>

		<ModalBox
			show={modalBlockShow}
			onHide={() => setModalBlockShow(false)}
			size={"sm"}
			type="condition"
			actionbuttontitle={'No'}
			buttonaction={() => {
				setModalBlockShow(false)
			}}
			backbuttontitle={'Yes'}
			backaction={() => {
				removeFromContact();
				setModalBlockShow(false)
			}}
		>
			<p className="modal-confirmation-text">Remove {(role === 2 && headerContent) ? headerContent.name : 'this landlord'} from your contact list? </p>
			<p>This will delete all your current conversation as well</p>
		</ModalBox>

		<ModalBox
			show={creditCheckModalShow}
			onHide={() => setCreditCheckModalShow(false)}
			size="sm"
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={() => {
				setCreditCheckModal(true)
			}}
			backbuttontitle={'No'}
			backaction={() => {
				creditCheckAction('reject')
			}}
		>
			<p className="modal-confirmation-text">Credit Check Confirmation</p>
			<p>Do you want to allow the landlord to run a credit check?</p>
		</ModalBox>

		<ModalBox
			show={getReportProfileIncompleteModalShow}
			onHide={() => setGetReportProfileIncompleteModalShow(false)}
			size="md"
			actionbuttontitle={'Ask Tenant To Complete Profile'}
			buttonaction={async () => {
				await askToCompleteProfile(conversation_id, tenant_id)

				setGetReportProfileIncompleteModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">
				The tenant is missing information in their profile that is required to pull a credit report.
				Please request that they complete their entire profile prior to pulling a credit report.

			</p>
		</ModalBox>

		<ModalBox
			show={profileIncompleteModalShow}
			onHide={() => setProfileIncompleteModalShow(false)}
			size="sm"
			actionbuttontitle={'complete profile'}
			buttonaction={() => {
				onCompleteProfileAction(false)
			}}
			backbuttontitle={'Not Now'}
			backaction={() => {
				setProfileIncompleteModalShow(false)
				onApplyTenancy()
			}}
		>
			<h4>your profile is your application for rentals on rent panda</h4>
			<p>No more filling out dozens of applications! <br />
				Please complete your profile to apply for this home.</p>
		</ModalBox>

		<ModalBox
			show={reportModalShow}
			onHide={() => setReportModalShow(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setReportModalShow(false)
			}}
		>
			<h4>your profile is your application for rentals on rent panda</h4>
			<p>No more filling out dozens of applications! <br />
				Please complete your profile to apply for this home.</p>

		</ModalBox>
		<ModalBox
			show={showCancelScreening}
			onHide={() => setShowCancelScreening(false)}
			size={"sm"}
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={async () => {
				await cancelScreening(creditCheckRequestId, conversation_id)
				setShowCancelScreening(false)
			}}
			backbuttontitle={'No'}
			backaction={() => {
				setShowCancelScreening(false)
			}}
			customActionButtonClass='height-large-only'
		>
			<h4> cancel screening request </h4>
			<p> are you sure you want to  <br />
				cancel this screening request?</p>

		</ModalBox>

		<CompleteProfileModal
		show={showCompleteProfileForShowing}
		setShow={setShowCompleteProfileForShowing}
		redirect={`/property-details/${property_id}/${property_details_id}/messages`}
		/>


 



		<ModalBox show={creditCheckModal}
			onHide={() => setCreditCheckModal(false)}
			size="md" actionbuttontitle={'Submit'}
			buttonaction={() => submitUserDOBAndSin()}>
			<form id="Form">
				<Row style={{ textAlign: 'left' }}>
					<Form.Group as={Col} md="12">
						<Form.Label className='bold'>Date of birth</Form.Label>
						<DatePickerComponent
							name="date_of_birth"
							id="date_of_birth"
							value={dateOfBirth}
							handleDateChange={(dob) => handleDate(dob)}
							displayText={"Date of birth"}
							disableValue={false}
							maxDate={new Date()}
							showMonthDropdown={true}
							showYearDropdown={true}
						/>
					</Form.Group>
					<Form.Group as={Col} md="12">
						<Form.Label className='bold'>SIN</Form.Label>
						<NumberFormat
							allowNegative={false}
							allowEmptyFormatting={false}
							value={sin ? sin : ''}
							onChange={(e) => setSin(e.target.value)}
							className='form-control'
							placeholder={''}
							name={'sin'}
							id={'sin'}
						/>
					</Form.Group>
					<DisclaimerText />
				</Row>
			</form>
		</ModalBox>

		<ModalBox
			show={cancelShowingRequestModal}
			onHide={() => {
				setCancelShowingRequestModal(false)
			}}
			size="md"
			type="condition"
			actionbuttontitle={'no'}
			buttonaction={() => {
				setCancelShowingRequestModal(false)
			}}
			backbuttontitle={'yes'}
			backaction={() => {
				onReject()
			}}
		>
			<Form id="Form">
				<Form.Group controlId="ControlTextarea1">
					<>
						<p style={{ marginBottom: '30px' }} className="modal-confirmation-text">are you sure you want to cancel this showing?</p>
						<Form.Label style={{textAlign:'left',display: 'block'}}>Please provide reason for the cancellation.</Form.Label>
					</>
					<Form.Control
						type="text"
						as="textarea"
						rows={6}
						id="reject_reason"
						name="reject_reason"
						value={reject_reason}
						placeholder="Type your note here."
						onChange={(e) => handleReasonChange(e)}
					/>
				</Form.Group>
			</Form>
		</ModalBox>
	</>);
}

export default withRouter(ChatBox)

