import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { TableCoupon } from "../../components";
import { postAPI } from "../../../Api";
import { setInActiveCouponTableData, setInActiveCouponCurrentPage } from "../../../Store/actions/CouponAction";
import { toaster } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import TableContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader";
import { phoneNumberSanitizer } from "../../../Utils/NumberSanitizer/NumberSanitizer";
import { getDateMeta } from "@fullcalendar/react";

const InActiveCoupon = (props) => {
  // const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [couponCode, setCouponCode] = useState("")
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [user_type, setUserType] = useState("");
  const [createdDate, setCreatedDate] = useState("desc");
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("")
  const [noOfUses, setNoOfUses] = useState("");
  const [couponValue,setCouponValue]=useState("")
  const [noOfRedeemed,setNoOfRedeemed]=useState("")
  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  const tableData = useSelector(state => state.CouponReducer.inActiveCouponTableData)
  const filters = useSelector(state => state.CouponReducer.filters)
  const pagination = useSelector(state => state.CouponReducer.inActiveCouponCurrentPage)
  const [isLoading, setIsLoading] = useState(true);
  const [oldFilter, setOldFilter] = useState({...filters})

  let dataFilter = {
    status: 0,
    ...filters
};

const getData = (url) => {
  postAPI(url, dataFilter)
  .then(response => {
    if(response.status !== 200 && response.response){
      toaster('error', 'Something went wrong!')
    }else{
      dispatch(setInActiveCouponTableData(response.data.data))
    }
  })
  .catch(error => {
    toaster('error', 'Something went wrong!')
  })
}

useEffect(() => {
  let url =''
  if(JSON.stringify(oldFilter) !== JSON.stringify(filters)){
    url = `/admin/coupons/list`
    setOldFilter({...filters})
  }else{
    if(pagination !== null && pagination){
      url = `/admin/coupons/list?page=${pagination}`
    }else{
      url = `/admin/coupons/list`
    }
  }
  getData(url)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [pagination, props.reload,filters]);

  useEffect(() => {
    let urlType = "/admin/coupons/list";
    postAPI(urlType, dataFilter).then((response) => {
      setIsLoading(false);      
      console.log('data', response.data.data)
      dispatch(setInActiveCouponTableData(response.data.data));         
    });
  }, []);

const onClickNext = (currentPage) => {
  const nextPage = currentPage + 1;
  dispatch(setInActiveCouponCurrentPage(nextPage))
};

  const onClickPrev = (currentPage) => {
    const prevPage = currentPage - 1;
    dispatch(setInActiveCouponCurrentPage(prevPage))
  };


  const onClickSwitch = (data) => {
    setOpenSwitchModal(true);
    setProfile(data);
  };
  const toggleModal = () => {
    setOpenSwitchModal(!openSwitchModal);
  };
  
  

  let userColumns = [];
  userColumns = [
    {
      header: "Name",
      name: "name",
      width: "14.28%",
    },
    {
      header: "# of coupon",
      name: "no_of_coupon",
      width: "14.28%",
    },
    {
      header: "# of redeemed",
      name: "no_of_redemeed",
      width: "14.28%",
    },
    {
      header: "start date",
      name: "start_date",
      width: "14.28%",
    },
    {
      header: "expire date",
      name: "expiry_date",
      width: "14.28%",
    },
    {
      header: "coupon value",
      name: "coupon_value",
      width: "14.28%",
    },
    {
      header: "coupon code",
      name: "coupon_code",
      width: "14.28%",
    },
  ];

  return (
    <React.Fragment>
      {isLoading === true ? (
        <TableContentLoader
          columns={userColumns}
          component={"userManagement"}
        />
      ) : (
        <>
          {tableData.data ? (
            <TableCoupon
              columns={userColumns}
              tableData={tableData.data}
              to={tableData.to}
              from={tableData.from}
              current_page={tableData.current_page}
              last_page={tableData.last_page}
              per_page={tableData.per_page}
              total={tableData.total}
              setName={(data) => setName(data)}
              setEmail={(data) => setEmail(data)}
              setCouponCode={(data)=>setCouponCode(data)}
              setPhoneNumber={(data) => setPhoneNumber(data)}
              setUserType={(data) => setUserType(data)}
               setCreatedDate={(data) => setCreatedDate(data)}
              setStartDate={(data) => setStartDate(data)}
              setExpiryDate={(data)=>setExpiryDate(data)}
              setCouponValue={(data)=>setCouponValue(data)}
              setNoOfUses={(data) => setNoOfUses(data)}
              setNoOfRedeemed={(data)=>setNoOfRedeemed(data)}
              couponCode={couponCode}
              name={name}
              email={email}
              phone_number={phone_number}
              user_type={user_type}
              deleteCoupon={props.deleteCoupon}
              viewCoupon={props.viewCoupon}             
              clickNext={() => onClickNext(tableData.current_page)}
              clickPrev={() => onClickPrev(tableData.current_page)}
              onClickSwitch={(data) => onClickSwitch(data)}
              component={"InActiveCoupons"}
            />
          ) : (
            ""
          )}
        </>
      )}
      
    </React.Fragment>
  );
};

export default withRouter(InActiveCoupon);
