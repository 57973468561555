import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Tips from "../../../../Website/PhotographyTips/Tips";
import { AppLayout } from "../../../components";

const PandaPartners = (props) => { 

  return (
    <AppLayout noSidebar={true}>
      <div className="photography-tips-header">
          <Container>
              <Row>
                  <Col md={12}>
                      <div className="main-heading text-center">panda partners </div>
                      <div className="sub-heading text-center">dummy text</div>
                  </Col>
              </Row>
          </Container>
      </div>
      {/* <Tips/> */}
    </AppLayout>
  );
}

export default withRouter(PandaPartners);
