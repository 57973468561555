import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import { TableLandlord } from '../../../components';
import UserManagementTable from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import '../style.scss';

const log = logger('Inspection');
const Inspection = (props) => {
    const { user_id, role } = props;
    const [tableData, setTableList] = useState(null);
    const [path] = useState('');
    const [searchLocation, setSearch] = useState(null);
    const [inspectionType, setType] = useState('');
    const [completedOn, sortCreatedOn] = useState('');
    const [status, sortInspectionStatus] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    let userColumns = [];
    userColumns = [
        {
            header: 'ADDRESS',
            width: '30%'
        },
        {
            header: 'TYPE',
            width: '15%'
        },
        {
            header: 'TENANT',
            width: '15%'
        },
        {
            header: 'COMPLETED ON',
            width: '20%'
        },
        {
            header: 'STATUS',
            width: '25%'
        }
    ];
    const filterData = {
        'address': searchLocation,
        'type': inspectionType,
        'sort_completed': completedOn, //asc or desc
        'status': status === 'all' ? '' : status //0 for pending, 3 for Completed
    }
    let url = '';
    if (role === 'landlord') {
        url = `/admin/users/landlord/inspections/${user_id}`;
    } else {
        url = `/admin/users/tenants/inspections/${user_id}`;
    }


    const getData = (fetchedUrl) => {
        if (user_id) {
            log.info('inside 1')
            if (user_id !== '') {
                postAPI(fetchedUrl ? fetchedUrl : url, filterData)
                    .then(response => {
                        if (response.status === 200) {
                            setIsLoading(false);
                            const res = response.data.data.inspections;
                            setTableList(res)
                        }
                    })
            }
        }
    }

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        let urlNext = '';
        if (role === 'landlord') {
            urlNext = `/admin/users/landlord/inspections/?page=${nextPage}`;
        } else {
            urlNext = `/admin/users/tenants/inspections/?page=${nextPage}`;
        }
        getData(urlNext);
    }
    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        let urlPrev = '';
        if (role === 'landlord') {
            urlPrev = `/admin/users/landlord/inspections/${user_id}?page=${prevPage}`;
        } else {
            urlPrev = `/admin/users/tenants/inspections/${user_id}?page=${prevPage}`;
        }
        getData(urlPrev);
    }
    const goToProfileDetail = (userData) => {
        props.history.push({ pathname: `/inspection-details/${role}/${userData.id}/${user_id}`, state: { path: path } })
    }

    useEffect(() => {
        if (user_id) {
            if (user_id !== '' || user_id !== undefined) {
                log.info('inside 1')
                let urlInside = '';
                if (role === 'landlord') {
                    urlInside = `/admin/users/landlord/inspections/${user_id}`;
                } else {
                    urlInside = `/admin/users/tenants/inspections/${user_id}`;
                }
                const data = {
                    'address': searchLocation,
                    'type': inspectionType,
                    'sort_completed': completedOn, //asc or desc
                    'status': status === 'all' ? '' : status //0 for pending, 3 for Completed
                }
                postAPI(urlInside, data)
                    .then(response => {
                        if (response.status === 200) {
                            setIsLoading(false);
                            const res = response.data.data.inspections;
                            setTableList(res)
                        }
                    })
            }
        }
    }, [searchLocation, user_id, completedOn, status, inspectionType, role])
    return (
        isLoading === true ? <UserManagementTable columns={userColumns} component={'lease'} /> :
            <>
                {tableData && tableData.data ?
                    <TableLandlord
                        columns={userColumns}
                        tableData={tableData.data}
                        to={tableData.to}
                        from={tableData.from}
                        current_page={tableData.current_page}
                        last_page={tableData.last_page}
                        per_page={tableData.per_page}
                        total={tableData.total}
                        searchLocation={searchLocation}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        clickNext={() => onClickNext(tableData.current_page)}
                        clickPrev={() => onClickPrev(tableData.current_page)}
                        setSearch={(data) => setSearch(data)}
                        setType={(data) => setType(data)}
                        sortCreatedOn={(data) => sortCreatedOn(data)}
                        sortInspectionStatus={(data) => sortInspectionStatus(data)}
                        component='inspection'
                    />
                    : ''}
            </>
    );
}

export default withRouter(Inspection);
