import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import {
  DatePickerComponent,
  LargeButton
} from "../..";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { logger, toaster, dateFormat, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";
import { N9Schema } from "./ValidationSchema/N9Schema";


const log = logger("N9Form");
const N9Form = (props) => {
  const { 
    property_id, 
    property_detail_id,
    noticeData, 
    getParalegal,
    onBack, 
    onNoticeDataUpdate
  } = props;
  const [date, setDate] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const profileDetails = useSelector(state => state.editProfile.editProfileList);
  const [globalState,setGlobalState]=useGlobalState()


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
    onNoticeDataUpdate({
      tenants: [profileDetails && profileDetails.data.id],
      tenant_move_out: dateFormat(date?date:''),
      signData,
      notice_type:9
    })
    setGlobalState({ onSubmit: onSubmit })

  },[
    property_id,
    date,
    phoneError,
    signData,
    profileDetails
    ])



  const onSubmit = () => {
    const url = "/tenant/notice/store-notice";
    setGlobalState({isSubmitClicked:true})

    if (signData?.tenant_phone_number === "" || signData?.tenant_phone_number?.replace("-", '').replace("_", '').length !== 10
    ) {
      setPhoneError(true);
      return
    }
    const isValid=validate(N9Schema);
    if(!isValid)
    {
      return
    }
 
    const data = {
      notice_id: noticeData && noticeData.id,
      property_id: property_id,
      property_detail_id: property_detail_id,
      notice_type: 9,
      tenants: [profileDetails && profileDetails.data.id],
      tenant_move_out: dateFormat(date),
      tenant_first_name: signData && signData?.tenant_first_name,
      tenant_last_name: signData && signData?.tenant_last_name,
      tenant_phone_number: signData && signData?.tenant_phone_number,
      tenant_sign_date: signData && signData?.tenant_sign_date,
      tenant_signature: signData && signData?.tenant_signature,
      agree_terms_and_conditions: 1,
    };

    postAPI(url, data).then((response) => {
      if (response.status === 200) {
        const notice_id = response.data.data.notice_id;
        toaster("success", "Data Saved Successfully!");
        viewNotice(notice_id);
      }
    });
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, Date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  useEffect(() => {
    if (noticeData ) {
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setDate(moment(noticeData.tenant_move_out).toDate());
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Tenant's Notice to End the Tenancy (N9 Form)
          </span>
          {/* <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", height: "48px", margin: "0" }}
            onClick={getParalegal}
          /> */}
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="date"
                name="date"
                format={"dd/MM/yyyy"}
                value={date && date}
                handleDateChange={(date) => handleDateChange(date, setDate)}
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-end"
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Tenant Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            type={"lastName"}
            signUserType={"tenant"}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={9}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N9Form);
