import React, { useState } from "react";
import { leaseDuration, rentSorting, sort_status_lease } from "../../../Constants/adminConstant";
import ImageComponent from "../../Admin/ProfileDetail/ListUsableComponent/ImageComponent";
import DropDown from "../DropDown";
import ThreeDotsDropdown from "../ThreeDotsDropdown";

const LeaseHeader = (props) => {
    const { data, component, durationFilter, filterRent, sortStatusLease, sort_by_status_lease } = props;

    const [status, setStatus] = useState(false);
    const showStatus = () => {
        setStatus(!status);
    }
    return (
        <>
            {component === "lease" &&
                <>
                    {(() => {
                        switch (data.header) {
                            case 'ADDRESS':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'DURATION':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {leaseDuration.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"leaseDuration" + i} onClick={() => durationFilter(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'TENANT':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'LANDLORD':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'RENT ($)':
                                return (
                                    <>
                                        {data.header}

                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {rentSorting.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"sort_date" + i} onClick={() => filterRent(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'STATUS':
                                return (
                                    <span className="d-flex justify-content-between">
                                        {data.header}
                                        <DropDown close={() => setStatus(false)} open={status} position={"right"} enableTopArrow={false} style={{ width: "208px", top: "20px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {sort_status_lease.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={sort_by_status_lease.includes(data.value)} onChange={sortStatusLease}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showStatus}><ImageComponent /></div>
                                        </DropDown>
                                    </span>
                                )
                            default:
                                return null
                        }
                    })()} </>}
        </>
    );
}
export default LeaseHeader;