import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import Cookies from 'js-cookie';

const N8DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice to End Tenancy End of term (N8 Form)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            Date of termination
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span style={{ marginBottom: '30px' }} className="form-title">Reason(s) for Ending Tenancy</span>
        </div>
        {noticeData.end_tenancy_reason_1 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label style={{ margin: "10px 0px 15px 0px" }}>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: Tenant(s) have persistently paid your rent late.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_2 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={noticeData.end_tenancy_reason_2 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: Tenant(s) no longer qualify to live in public or
                  subsidized housing.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_3 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={noticeData.end_tenancy_reason_3 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You have made the unit available to your current
                  tenant(s) as a condition of their employment and their
                  employment has already ended
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_4 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason4"}
                  name={"reason4"}
                  checked={noticeData.end_tenancy_reason_4 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason4"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 4: Your tenant(s) tenancy was created in good faith as
                  a result of an Agreement of Purchase and Sale for a proposed
                  condominium unit and that agreement has been terminated.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_5 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason5"}
                  name={"reason5"}
                  checked={noticeData.end_tenancy_reason_5 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason5"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 5: You are occupying the unit specifically to receive
                  rehabilitative or therapeutic services and the period of
                  tenancy to which you agreed has ended. I can only give you a
                  notice for this reason if no other tenant receiving
                  rehabilitative and/or therapeutic services is allowed to live
                  in the residential complex for more than 4 years
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">
            Details about the reasons for notice
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">Details </span>
          <span className="field-value-text">
            {noticeData.notice_reason_details}
          </span>
        </div>

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N8DetailsView);
