import React from 'react'
import { Col, Image, Button } from 'react-bootstrap'
import PDFIcon from '../../../_assets/images/pdf-icon.svg'
import DeleteIcon from '../../../_assets/images/red-delete-icon.svg'

function LeaseFIleView(props) {
    const { setPdfModal, formDoc, removeFile, setFormDoc } = props
    return (
        <div>
            <Col className='confirm-section mb-2 d-flex justify-content-between'>
                <span className='d-flex justify-content-start pdfBox'>
                    <Image className="icon" src={PDFIcon} alt="PDF ICON" />
                    <div className='file-details d-flex flex-column'>
                        {formDoc.file.split('/').pop()}
                        <Button variant="link" onClick={() => setPdfModal(true)}>View</Button>
                    </div>
                    <Image className="icon cursor-pointer" title='Delete' onClick={removeFile} src={DeleteIcon} alt="Delete" />
                </span>
            </Col>
        </div>
    )
}

export default LeaseFIleView
