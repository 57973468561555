import { getAPI, postAPI } from '../../../../Api';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import Cookies from 'js-cookie';
import Axios from 'axios';
import moment from "moment";
import { getUtilitiesHydro, getUtilitiesHeat, getUtilitiesWater } from '../../../../Constants/LeaseConstants';

export const leaseSubmit = async (data, type, role='') => {
    try {
        let url = `/landlord/listing/lease/${type}`;
        if(role === 'tenant'){
            url = `/tenant/listing/lease/${type}`;
        }
        const response = await postAPI(url, data)
        if(response.status === 200){
            return { status: response.status, data: response.data.data, message: response.data.message }
        }
        return { status: response.response.status, message: response.response.data.message, errors : response.response.data.errors }
    } catch (error) {
        return { status: 400, message: 'Something went wrong!' }
    }
}

export const getPdfApi = async (role, property_details_id, lease_id) => {
    let url = `/tenant/listing/lease/view-appendix/${property_details_id}/${lease_id}`;
    if (role === 2) {
        url = `/landlord/listing/lease/view-appendix/${property_details_id}/${lease_id}`;
    }
    
    try {
        const res = await getAPI(url);
        return {response : res, error : null}
    }catch(error){
        return {response : null, error : error.message}
    }
}

export const fileUploadApi = async (formdata, stateInfo, type='') => {
    const token = `Bearer ${Cookies.get('access_token')}`
    const headers = {
        'Authorization': token,
        'Content-Type': 'multipart/form-data'
    }
    
    let url = FILE_API_URL;
    try{
        const response = await Axios.post(
            url, formdata, {
            headers: headers,
            onUploadProgress: progressEvent => {
                var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                if(type === 'completeLease'){
                    stateInfo.setImage({ percent: percent });
                }else{
                    stateInfo.setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [stateInfo.state] : { percent: percent }}))
                }
            }
        });
        return {response : response.data.data.url, error : null}
    }catch(error){
        return {response : null, error : error.message}
    }
}

export const flattenObject = (obj) => {
    if(obj){
        const flatten ={}
        Object.keys(obj).forEach((key) => {
            if(typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null){
                Object.assign(flatten, flattenObject(obj[key]))
            }else{
                flatten[key] = obj[key]
            }
        })
        return flatten
    }
}

export const getContactInfoFromApi = (fetchedData) => {
    const updatedContactInfo = {};
    if (fetchedData && fetchedData.contact_details) {
        updatedContactInfo['full_name'] = fetchedData && fetchedData.contact_details.full_name;
        updatedContactInfo['email'] = fetchedData && fetchedData.contact_details.email;
        updatedContactInfo['phone_number'] = fetchedData && fetchedData.contact_details.phone_number;
    }
    if (fetchedData && fetchedData.notices_and_documents) {
        updatedContactInfo['agree_to_received_notice'] = fetchedData.notices_and_documents.agree_to_received_notice;
        updatedContactInfo['agree_to_received_notices_email'] = fetchedData.notices_and_documents.agree_to_received_notices_email;
        updatedContactInfo['day_to_day_communication'] = fetchedData.notices_and_documents.day_to_day_communication;
        updatedContactInfo['day_to_day_communication_email'] = fetchedData.notices_and_documents.day_to_day_communication_email;
        updatedContactInfo['day_to_day_communication_phone_number'] = fetchedData.notices_and_documents.day_to_day_communication_phone_number;
    }

    if (fetchedData && fetchedData.landlord_mailing_address) {
        updatedContactInfo['mailing_address'] = fetchedData.landlord_mailing_address.mailing_address;
        updatedContactInfo['mailing_city'] = fetchedData.landlord_mailing_address.mailing_city;
        updatedContactInfo['mailing_province'] = fetchedData.landlord_mailing_address.mailing_province;
        updatedContactInfo['mailing_country'] = fetchedData.landlord_mailing_address.mailing_country;
        updatedContactInfo['mailing_postal_code'] = fetchedData.landlord_mailing_address.mailing_postal_code !== null && fetchedData.landlord_mailing_address.mailing_postal_code;
        updatedContactInfo['mailing_unit_no'] = fetchedData.landlord_mailing_address.mailing_unit_no !== null ? fetchedData.landlord_mailing_address.mailing_unit_no : "";
        updatedContactInfo['mailing_latitude'] = fetchedData.landlord_mailing_address.mailing_latitude;
        updatedContactInfo['mailing_longitude'] = fetchedData.landlord_mailing_address.mailing_longitude;
    }
    if (fetchedData && fetchedData.property_address) {
        updatedContactInfo['address'] = fetchedData.property_address.address;
        updatedContactInfo['unit_no'] = fetchedData.property_address.unit_no;
        updatedContactInfo['city'] = fetchedData.property_address.city;
        updatedContactInfo['postal_code'] = fetchedData.property_address.postal_code;
        updatedContactInfo['province'] = fetchedData.property_address.province;
        updatedContactInfo['latitude'] = fetchedData.property_address.latitude;
        updatedContactInfo['longitude'] = fetchedData.property_address.longitude;
        updatedContactInfo['is_condo'] = fetchedData.property_address.is_condo;
    }
    return updatedContactInfo;
}

export const getLeaseInfoFromApi = (fetchedData, propertyDetails, unitDetails, profileDetails) => {
    const updatedLeaseInfo = {};
        if ((fetchedData && fetchedData.lease_deatils) && propertyDetails?.details?.length) {
            
            updatedLeaseInfo['lease_type'] = (fetchedData.lease_deatils.lease_type !== null ? fetchedData.lease_deatils.lease_type : propertyDetails.details[0].lease_duration);
            if(fetchedData?.lease_deatils?.lease_end_date){
            updatedLeaseInfo['lease_end_date'] = moment(fetchedData.lease_deatils.lease_end_date).toDate()
            }            
            updatedLeaseInfo['tenancy_agreement'] = (fetchedData.lease_deatils.tenancy_agreement)
            updatedLeaseInfo['lease_pdf'] = ({
                file: fetchedData.lease_deatils.lease_appendix !== null ? fetchedData.lease_deatils.lease_appendix : ""
            })
            
            const defaultRent = propertyDetails.details[0].price_per_month;
            const isZero = fetchedData.lease_deatils.rent === '0.00' || fetchedData.lease_deatils.rent === '0.0' || fetchedData.lease_deatils.rent === '0'
            const updatedRent = isZero ? defaultRent : unitDetails ? fetchedData.lease_deatils.rent || unitDetails?.price_per_month : fetchedData.lease_deatils.rent;
            updatedLeaseInfo['rent'] = updatedRent;
            
            updatedLeaseInfo['rent_paid_on'] = (fetchedData.lease_deatils.rent_paid_on !== null ? fetchedData.lease_deatils.rent_paid_on : "1st")
            updatedLeaseInfo['rent_paid_on_day_of_each'] = (fetchedData.lease_deatils.rent_paid_on_day_of_each)
            updatedLeaseInfo['rent_paid_on_day_of_each_other'] = (fetchedData.lease_deatils.rent_paid_on_day_of_each_other)
            updatedLeaseInfo['is_parking_available'] = ((fetchedData?.lease_deatils?.is_parking_available) || (unitDetails?.is_parking_included) ? 1 : 0);
            updatedLeaseInfo['parking_type'] = (fetchedData.lease_deatils.parking_type !== null ? fetchedData.lease_deatils.parking_type : 1);
            updatedLeaseInfo['parking_spot'] = ((fetchedData?.lease_deatils?.parking_spot) || (unitDetails?.parking_slot));
            updatedLeaseInfo['parking_price'] = (fetchedData.lease_deatils.parking_price !== null ? fetchedData.lease_deatils.parking_price : '');

            const rent_payable_to = fetchedData.lease_deatils.rent_payable_to !== null ? 
                fetchedData.lease_deatils.rent_payable_to : 
                (fetchedData.contact_details.full_name ? 
                    fetchedData.contact_details.full_name : 
                        profileDetails !== null ? 
                            profileDetails.data.name : '');
            
            updatedLeaseInfo['rent_payable_to'] = rent_payable_to;
            updatedLeaseInfo['rent_pay_method'] = (fetchedData.lease_deatils.rent_pay_method !== null ? fetchedData.lease_deatils.rent_pay_method : [])
            updatedLeaseInfo['first_rental_partial_period'] = (fetchedData.lease_deatils.first_rental_partial_period)
            updatedLeaseInfo['partial_cover_start_date'] = (fetchedData.lease_deatils.partial_cover_start_date !== null ? moment(fetchedData.lease_deatils.partial_cover_start_date).toDate() : "")
            updatedLeaseInfo['partial_cover_end_date'] = (fetchedData.lease_deatils.partial_cover_end_date !== null ? moment(fetchedData.lease_deatils.partial_cover_end_date).toDate() : "")
            updatedLeaseInfo['partial_cover_amount'] = (fetchedData.lease_deatils.partial_cover_amount !== null ? fetchedData.lease_deatils.partial_cover_amount : "");
            updatedLeaseInfo['partial_payment_date'] = (fetchedData.lease_deatils.partial_payment_date !== null ? moment(fetchedData.lease_deatils.partial_payment_date).toDate() : "")
            updatedLeaseInfo['administrative_charge'] = (fetchedData.lease_deatils.administrative_charge !== null ? fetchedData.lease_deatils.administrative_charge : "20")

            updatedLeaseInfo['utility_electricity_pay_by'] = (fetchedData.lease_deatils.utility_electricity_pay_by !== null ? fetchedData.lease_deatils.utility_electricity_pay_by :
                unitDetails !== null ? getUtilitiesHydro(unitDetails?.utilities) : 1
            );
            updatedLeaseInfo['utility_heat_pay_by'] = (fetchedData.lease_deatils.utility_heat_pay_by !== null ? fetchedData.lease_deatils.utility_heat_pay_by :
                unitDetails !== null ? getUtilitiesHeat(unitDetails?.utilities) : 1
            );
            updatedLeaseInfo['utility_water_pay_by'] = (fetchedData.lease_deatils.utility_water_pay_by !== null ? fetchedData.lease_deatils.utility_water_pay_by :
                unitDetails !== null ? getUtilitiesWater(unitDetails?.utilities) : 1
            );

            const isRentDeposit = fetchedData.lease_deatils.is_rent_deposit !== null && fetchedData.step_completed > 1
                ? fetchedData.lease_deatils.is_rent_deposit
                : 1
            updatedLeaseInfo['is_rent_deposit'] = (isRentDeposit)

            const rentDepositAmount = fetchedData.lease_deatils.is_rent_deposit_amount
            const untiDetailsPricePerMonth = unitDetails?.price_per_month;
            const depositAmounts = rentDepositAmount ? rentDepositAmount :
                untiDetailsPricePerMonth ? untiDetailsPricePerMonth || defaultRent :
                    isRentDeposit ? defaultRent :
                        ''
            updatedLeaseInfo['is_rent_deposit_amount'] = (depositAmounts)
            updatedLeaseInfo['other_service_utilities'] = (fetchedData.lease_deatils.other_service_utilities && fetchedData.lease_deatils.other_service_utilities)
        }

        if (fetchedData && fetchedData.other_details) {
            updatedLeaseInfo['is_smoking_allowed'] = (fetchedData.other_details.is_smoking_allowed)
            updatedLeaseInfo['is_key_deposite'] = (fetchedData.other_details.is_key_deposite !== null ? fetchedData.other_details.is_key_deposite : 1);
            updatedLeaseInfo['key_deposite_amount'] = (fetchedData.other_details.key_deposite_amount);
            updatedLeaseInfo['is_rent_discounted'] = (fetchedData.other_details.is_rent_discounted !== null ? fetchedData.other_details.is_rent_discounted : 1);
            updatedLeaseInfo['rent_discount_amount'] = (fetchedData.other_details.rent_discount_amount);
            updatedLeaseInfo['rent_discount_description'] = (fetchedData.other_details.rent_discount_description);
        }
        return updatedLeaseInfo;
}

export const getAddressFromPlace = place => {
    let address_components = [];
		if (place?.address_components && place?.address_components?.length > 0) {
			place.address_components.forEach(ac => {
				if (ac?.types?.includes('street_number') || ac?.types?.includes('route')) {
					address_components.push(ac?.long_name)
				}
			})
		}
    return address_components.join(' ');
}

export const skipActionApi = async (lease_id, ) => {
    let url = `/landlord/listing/lease/sign-lease-offline/${lease_id}`
    try {
        const res = await getAPI(url);
        
        if (res.status === 200) {
            return {response : res, error : null};
        }
        return {response : null, error : res.response.data.message}
    }catch(error){
        return {response : null, error : error.message}
    } 
}

export const ADDRESS_CONDITIONS={
    locality:(state)=>state === 'mailing' ? 'mailing_city' : 'city',
    administrative_area_level_1:(state)=>state === 'mailing' ? 'mailing_province' : 'province',
    country:(state)=>state === 'mailing' ? 'mailing_country' : 'country',
    postal_code:(state)=>state === 'mailing' ? 'mailing_postal_code' : 'postal_code'
}

export const getContactInfo=(place, address, state,contactInfo)=>{
    if (place?.geometry?.location && place?.address_components && address && state) {
        const addressArray = place?.address_components || []
        const upadtedConctactInfo = { ...contactInfo }
        
        upadtedConctactInfo[state === 'mailing' ? 'mailing_address' : 'address'] = address
        upadtedConctactInfo[state === 'mailing' ? 'mailing_longitude' : 'longitude'] = place.geometry.location.lng()
        upadtedConctactInfo[state === 'mailing' ? 'mailing_latitude' : 'latitude'] = place.geometry.location.lat()

        addressArray.forEach((addressItem) => {
            const longName = addressItem?.long_name
            const addressType = addressItem?.types[0]
            if (addressType) {
                const addressKey = ADDRESS_CONDITIONS[addressType] && ADDRESS_CONDITIONS[addressType](state)
                if (addressKey) {
                    upadtedConctactInfo[addressKey] = longName
                }
            }
        })
        return upadtedConctactInfo
    }
    return contactInfo
}

export const textToImage = value => {
    const textToImage = require('text-to-image');
    return new Promise((res, rej) => {
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        })
        .then((dataUri) => {
            res(dataUri);
        })
        .catch(error => {
            rej(error)
        });
    })
}