import React, { useState } from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { updateRawData } from '../../../../Store/actions/RawDataAction';

import { DollorNumberDisplay } from '../../../components';

function TenantPropertyList({ propertyList = [], onProperySelect = () => {},isMobile }) {
  const [activeKey, setActiveKey] = useState('new to old');
  const onProperySelectHandler = (property) => {
    onProperySelect(property);
  };
  const { selectedPropertyForMessage = {} } = useSelector(
    (state) => state.rawData
  );
  return (
    <div>
      <Navbar className="filter-nav"  >
        <Navbar.Collapse className="d-flex justify-content-between">
          <Navbar.Text>posted date:</Navbar.Text>
          <NavDropdown title={activeKey} id="nav-dropdown">
            <NavDropdown.Item
              onClick={(e) => {
                setActiveKey('new to old');
                updateRawData({ sortPropertyListFlag: 'desc' });
              }}
            >
              posted date: new to old
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={(e) => {
                setActiveKey('old to new');

                updateRawData({ sortPropertyListFlag: 'asc' });
              }}
            >
              posted date: old to new
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>

      <div className={'property-list-scroll'}>
        {propertyList?.map((property, index) => {
          return (
           !!property?.latest_message_time?.created_at && <div
              className={`property-item ${
                selectedPropertyForMessage.property_id === property.property_id
                  ? 'selected-property-item'
                  : ''
              }`}
              onClick={() => {
                onProperySelectHandler(property);
              }}
            >
              <img
                className={'property-img'}
                src={property.image_url}
                alt={'Property_Image'}
              />
              <div className={'address-info'}>
                <b>{property.address_line_1} </b>
                <span>
                  {property.unit_name ? `${property.unit_name} - ` : ''}

                  <DollorNumberDisplay price={property.price_per_month} />
                </span>
                <div>
                  {!!property?.latest_message_time?.created_at && (
                    <>
                      last message:&nbsp;
                      <Moment format="YYYY/MM/DD">
                        {property?.latest_message_time?.created_at}
                      </Moment>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TenantPropertyList;
