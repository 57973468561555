import React from 'react';
import { Button, Image } from 'react-bootstrap';
import './style.scss';

const LargeButton = (props) => {
  const { title, isIcon, onClick, variant, style, type, isDisable, saveLoading, extraClass, id, dataDescription } = props
  return (
    <Button variant={variant} disabled={saveLoading === false ? isDisable : saveLoading} type={type ? type : 'button'} className={`large-btn ${extraClass}`} id={id} data-description={dataDescription} onClick={onClick} style={style}>
      {isIcon && <Image className='icon' src={require('../../../../_assets/images/plus-white-icon.svg')} alt='add' />}
      {title}{saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
    </Button>
  );
}
export default LargeButton;