export const N9Schema = { 
    tenant_first_name: [
        { rule: "required", message: "first name required" },
    ], 
    tenant_last_name:[
        { rule: "required", message: "last name required" },

    ],
    tenant_sign_date: [
        { rule: "required", message: "signing date required" },
    ], 
    date:[
        { rule: "required", message: "date required" },

    ]
}