import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import NumberFormat from 'react-number-format';
import { postAPI, getAPI } from '../../../../Api';
import history from '../../../../history';
import { ContactSchema } from './CreateLeaseSchema/ContactSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { useSelector } from "react-redux";
import { AutoCompleteAddress, SingleOptionCheck, ModalBox } from '../../../components';
import { rentDeposite } from '../../../../Constants/LeaseConstants';
import LeaseContactDetailsLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/LeaseContactDetailsLoader/LeaseContactDetailsLoader';
import { Prompt } from 'react-router';

const condo = [{ display: 'Yes', value: 1 }, { display: 'No', value: 0 }]
const log = logger('CreateLeaseContactInfo');
const CreateLeaseContactInfo = (props) => {
    const [property_id, setProperty_id] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [property_details_id, setProperty_details_id] = useState('');
    const [full_name_contact, setFullName] = useState('');
    const [emailAddress_contact, setEmail] = useState('');
    const [phone_number_contact, setPhoneNumber] = useState('');
    const [current_address, setAddress] = useState('');
    const [city_contact, setCity] = useState('');
    const [province_contact, setProvince] = useState('');
    const [country_contact, setCountry] = useState('');
    const [postal_code_contact, setPostal_code] = useState('');
    const [latitude_contact, setLatitude] = useState(null);
    const [longitude_contact, setLongitude] = useState(null);
    const [unit_no_contact, setUnit_no] = useState('');
    const [is_condo_contact, setCondo] = useState(0);
    const [lease_id, setLeaseId] = useState('');
    const [user_id, setUserId] = useState('');
    // const [reasondata, setReasonDeactivateData] = useState(null);
    const [phoneError, setPhoneError] = useState(false);
    const [agree_to_received_notice, setAgree_to_received_notice] = useState(0);
    const [agree_to_received_notices_email, setAgree_to_received_notice_email] = useState('');
    const [day_to_day_communication, setDay_to_day_communication] = useState(0);
    const [day_to_day_communication_email, setDay_to_day_communication_email] = useState('');
    const [day_to_day_communication_phone_number, setDay_to_day_communication_phone_number] = useState('');
    const [communicationPhoneError, setCommunicationPhoneError] = useState(false);
    const [mailing_full_address, setMailing_Address] = useState('');
    const [mailing_city, setMailing_City] = useState('');
    const [mailing_province, setMailing_Province] = useState('');
    const [mailing_country, setMailing_Country] = useState('');
    const [mailing_postal_code, setMailing_Postal_code] = useState('');
    const [mailing_latitude, setMailing_Latitude] = useState(null);
    const [mailing_longitude, setMailing_Longitude] = useState(null);
    const [mailing_unit_no, setMailing_Unit_no] = useState('');
    const [saveLoading, setBtnDisable] = useState(false);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [saveState, setSaveState] = useState('');
    const [is_lease_updated, setLease_updated] = useState(false);
    const [leaseData, setCompleteLeaseData] = useState(null);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    const OnChangeInput = (event) => {
        if (event.target.name === 'phone_number_contact') {
            const change = phoneNumberSanitizer(event.target.value);
            if (isNaN(Number(change))) {
                setPhoneError(true)
            } else {
                setPhoneError(false)
            }
            setPhoneNumber(change)
        } else if (event.target.name === 'day_to_day_communication_phone_number') {
            const change = phoneNumberSanitizer(event.target.value);
            if (isNaN(Number(change))) {
                setCommunicationPhoneError(true)
            } else {
                setCommunicationPhoneError(false)
            }
            setDay_to_day_communication_phone_number(change)
        }
    }
    const backToPrevPage = () => {
        props.history.push({ pathname: `/profile-detail/listings/landlord/${user_id}/${property_id}/${property_details_id}` })
    }
    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'is_condo') {
            setCondo(Value);
        } else if (name === 'agree_to_received_notice') {
            setAgree_to_received_notice(Value);
        } else if (name === 'day_to_day_communication') {
            setDay_to_day_communication(Value);
        }
        setShouldBlockNavigation(true);
    }
    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        setAddress(address);
        getPlaceDetails(place.address_components);
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setShouldBlockNavigation(true);
    }

    const handleChange = event => {
        setAddress(event.target.value);
    }
    const handleChangeMailing = event => {
        setMailing_Address(event.target.value);
        setShouldBlockNavigation(true);
    }

    const handlePlaceChangedMailing = (place) => {
        const address = place.formatted_address;
        log.info('==>', place.geometry.location.lat(), place.geometry.location.lng())
        setMailing_Address(address);
        getPlaceDetails(place.address_components, 'mailing');
        setMailing_Latitude(place.geometry.location.lat())
        setMailing_Longitude(place.geometry.location.lng())
        setShouldBlockNavigation(true);
    }

    const getPlaceDetails = (addressArray, state) => {
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
                if (state === 'mailing') {
                    setMailing_City(addressArray[i].long_name)
                } else {
                    setCity(addressArray[i].long_name)
                }
            } else if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                if (state === 'mailing') {
                    setMailing_Province(addressArray[i].long_name)
                } else {
                    setProvince(addressArray[i].long_name)
                }
            } else if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                if (state === 'mailing') {
                    setMailing_Country(addressArray[i].long_name)
                } else {
                    setCountry(addressArray[i].long_name)
                }
            } else if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
                if (state === 'mailing') {
                    setMailing_Postal_code(addressArray[i].long_name)
                } else {
                    setPostal_code(addressArray[i].long_name)
                }
            }
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        log.info(validate(ContactSchema))
        setShouldBlockNavigation(false);
        if (validate(ContactSchema)) {
            setBtnDisable(true)
            const data = {
                'lease_id': lease_id,
                'property_id': property_id,
                'property_detail_id': property_details_id,
                'full_name': full_name_contact,
                'email': emailAddress_contact,
                'phone_number': phone_number_contact,
                'agree_to_received_notice': agree_to_received_notice,
                'agree_to_received_notices_email': agree_to_received_notices_email,
                'day_to_day_communication': day_to_day_communication,
                'day_to_day_communication_email': day_to_day_communication_email,
                'day_to_day_communication_phone_number': day_to_day_communication_phone_number,
                'latitude': latitude_contact,
                'longitude': longitude_contact,
                'address': current_address,
                'unit_no': unit_no_contact,
                'province': province_contact,
                'country': country_contact,
                'city': city_contact,
                'postal_code': postal_code_contact,
                'mailing_address': mailing_full_address,
                'mailing_latitude': mailing_latitude,
                'mailing_longitude': mailing_longitude,
                'mailing_unit': mailing_unit_no,
                'mailing_province': mailing_province,
                'mailing_country': mailing_country,
                'mailing_city': mailing_city,
                'mailing_postal_code': mailing_postal_code,
                'is_condo': is_condo_contact
            }
            const url = '/admin/listing/lease/contact-details';
            postAPI(url, data).then(res => {
                setBtnDisable(false)
                if (res.status === 200) {
                    toaster('success', 'Data Saved Successfully!');
                    const leaseId = res.data.data.lease_id;
                    if (saveState === 'next') {
                        props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/lease-details/${leaseId}` });
                        history.go(0);
                    } else if (saveState === 'update') {
                        setLease_updated(true);
                    }
                } else if (res.response.status === 400) {
                    log.info(res.response)
                    toaster('error', res.response.data.message);
                } else if (res.response.status === 422) {
                    toaster('error', res.response.data.message);
                }

            })
        } else {
            toaster('error', 'Some fields required to filled!');
        }
    }
    const redirectLast = () => {
        setLease_updated(false)
        props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/complete-lease-details/${lease_id}` });
    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setLeaseId(props.match.params.lease_id)
        setUserId(props.match.params.user_id);
    }, [
        props.match.params.property_id,
        props.match.params.property_details_id,
        props.match.params.user_id,
        props.match.params.lease_id]);

    useEffect(() => {
        log.info(typeof lease_id)
        if (property_details_id && lease_id !== '0') {
            setLoading(true)
            const url = `/admin/listing/lease/get-lease-details/${property_details_id}/${lease_id}`
            getAPI(url).then(res => {
                setLoading(false)
                if (res.status === 200) {
                    const fetchedData = res.data.data;
                    setCompleteLeaseData(fetchedData)
                    if (fetchedData.contact_details !== null || fetchedData.contact_details !== undefined) {
                        setFullName(fetchedData.contact_details.full_name === null ? propertyDetails.user.name : fetchedData.contact_details.full_name);
                        setEmail(fetchedData.contact_details.email === null ? propertyDetails.user.email : fetchedData.contact_details.email);
                        setPhoneNumber(fetchedData.contact_details.phone_number === 0 ? propertyDetails.user.phone_number : fetchedData.contact_details.phone_number);
                    }
                    if (fetchedData && fetchedData.notices_and_documents) {
                        setAgree_to_received_notice(fetchedData.notices_and_documents.agree_to_received_notice);
                        setAgree_to_received_notice_email(fetchedData.notices_and_documents.agree_to_received_notices_email);
                        setDay_to_day_communication(fetchedData.notices_and_documents.day_to_day_communication);
                        setDay_to_day_communication_email(fetchedData.notices_and_documents.day_to_day_communication_email);
                        setDay_to_day_communication_phone_number(fetchedData.notices_and_documents.day_to_day_communication_phone_number);
                    }

                    if (fetchedData.property_address !== null || fetchedData.property_address !== undefined) {
                        setAddress(fetchedData.property_address.address === null ? propertyDetails.address : fetchedData.property_address.address);
                        setUnit_no(fetchedData.property_address.unit_no === null ? propertyDetails.unit_no : fetchedData.property_address.unit_no);
                        setCity(fetchedData.property_address.city === null ? propertyDetails.city : fetchedData.property_address.city);
                        setPostal_code(fetchedData.property_address.postal_code === null ? propertyDetails.postal_code : fetchedData.property_address.postal_code);
                        setProvince(fetchedData.property_address.province === null ? propertyDetails.province : fetchedData.property_address.province);
                        setLatitude(fetchedData.property_address.latitude === null ? propertyDetails.latitude : fetchedData.property_address.latitude);
                        setLongitude(fetchedData.property_address.longitude === null ? propertyDetails.longitude : fetchedData.property_address.longitude);
                        setCondo(fetchedData.property_address.is_condo);
                    }
                    if (fetchedData && fetchedData.landlord_mailing_address) {
                        setMailing_Address(fetchedData.landlord_mailing_address.mailing_address);
                        setMailing_City(fetchedData.landlord_mailing_address.mailing_city);
                        setMailing_Province(fetchedData.landlord_mailing_address.mailing_province);
                        setMailing_Country(fetchedData.landlord_mailing_address.mailing_country);
                        setMailing_Postal_code(fetchedData.landlord_mailing_address.mailing_postal_code !== null ? fetchedData.landlord_mailing_address.mailing_postal_code : "");
                        setMailing_Unit_no(fetchedData.landlord_mailing_address.mailing_unit_no !== null ? fetchedData.landlord_mailing_address.mailing_unit_no : "");
                        setMailing_Latitude(fetchedData.landlord_mailing_address.mailing_latitude);
                        setMailing_Longitude(fetchedData.landlord_mailing_address.mailing_longitude);
                    }
                }
            })
        } else if (propertyDetails) {
            log.info(propertyDetails)
            setAddress(propertyDetails.address);
            setUnit_no(propertyDetails.unit_no);
            setCity(propertyDetails.city);
            setPostal_code(propertyDetails.postal_code);
            setProvince(propertyDetails.province);
            setLatitude(propertyDetails.latitude);
            setLongitude(propertyDetails.longitude);
            setCondo(propertyDetails.is_condo ? 1 : 0);
            setFullName(propertyDetails.user.name);
            setEmail(propertyDetails.user.email);
            setPhoneNumber(propertyDetails.user.phone_number);
        }
    }, [property_details_id, lease_id, propertyDetails]);
    useEffect(() => {
        if (mailing_full_address === '') {
            setMailing_City('')
            setMailing_Province('')
            setMailing_Country('')
            setMailing_Postal_code('')
            setMailing_Latitude(null)
            setMailing_Longitude(null)
        }
        if (current_address === '') {
            setCity('')
            setProvince('')
            setCountry('')
            setPostal_code('')
            setLongitude(null)
            setLongitude(null)
        }
    }, [mailing_full_address, current_address])
    log.info(mailing_latitude, mailing_longitude, latitude_contact, longitude_contact)
    return (isLoading === true ? <LeaseContactDetailsLoader /> : <>
        <Prompt
            when={shouldBlockNavigation}
            message="your information will be lost do you want to leave this page?"
        />
        <Form id='Form' onSubmit={onSubmit} className='higher-level-lease'>
            <div className='inner-wrapper'>
                <div className='body-container'>
                    <div className='body-header'>
                        <h3 className='text-left'>landlord contact information</h3>
                        {leaseData && leaseData.is_sent_to_tenant ?
                            <button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('update')}>Save</button>
                            : <button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('')}>Save</button>}
                    </div>

                    <Row>
                        <Form.Group as={Col} md='4'>
                            <Form.Label className='transform-capitalize'>Landlord Full Name</Form.Label>
                            <Form.Control
                                type='text'
                                name='full_name_contact'
                                id='full_name_contact'
                                value={full_name_contact}
                                placeholder='Type here'
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='4'>
                            <Form.Label className='transform-capitalize'>Email Address</Form.Label>
                            <Form.Control
                                type='text'
                                name='emailAddress_contact'
                                id='emailAddress_contact'
                                value={emailAddress_contact}
                                placeholder='Type here'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='4'>
                            <Form.Label className='transform-capitalize'>Phone Number</Form.Label>
                            <NumberFormat
                                format='###-###-####'
                                mask='_'
                                placeholder='Enter Phone Number'
                                name='phone_number_contact'
                                id='phone_number_contact'
                                className={phoneError === true ? 'form-control is-invalid' : 'form-control'}
                                value={phone_number_contact ? phone_number_contact : ''}
                                onChange={(e) => OnChangeInput(e)}
                            />
                            {/* {phoneError === true && <div id="error_unit_no" className="invalid-feedback"></div>} */}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='12'>
                            <Form.Label className='transform-none'>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
                            <div className='d-flex justify-content-flex-start align-items-center'>
                                <SingleOptionCheck
                                    mapData={rentDeposite}
                                    OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                                    name={'agree_to_received_notice'}
                                    setParam={agree_to_received_notice}
                                    extraClass={'d-inline-flex mb-0'}
                                />
                                {agree_to_received_notice ?
                                    <Form.Group as={Col} md='4' className='mb-0'>
                                        <Form.Control
                                            type='text'
                                            name='agree_to_received_notices_email'
                                            id='agree_to_received_notices_email'
                                            value={agree_to_received_notices_email}
                                            placeholder='name@mail.com'
                                            onChange={(e) => setAgree_to_received_notice_email(e.target.value)}
                                        />
                                    </Form.Group> : ''}
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='12'>
                            <Form.Label className='transform-none'>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
                            <div className='d-flex justify-content-flex-start align-items-center'>
                                <SingleOptionCheck
                                    mapData={rentDeposite}
                                    OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                                    name={'day_to_day_communication'}
                                    setParam={day_to_day_communication}
                                    extraClass={'d-inline-flex mb-0'}
                                />
                                {day_to_day_communication ?
                                    <>
                                        <Form.Group as={Col} md='4' className='mb-0'>
                                            <Form.Control
                                                type='text'
                                                name='day_to_day_communication_email'
                                                id='day_to_day_communication_email'
                                                value={day_to_day_communication_email}
                                                placeholder='name@mail.com'
                                                onChange={(e) => setDay_to_day_communication_email(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md='4' className='mb-0'>
                                            <NumberFormat
                                                format='###-###-####'
                                                mask='_'
                                                placeholder='Enter Phone Number'
                                                name='day_to_day_communication_phone_number'
                                                id='day_to_day_communication_phone_number'
                                                className={communicationPhoneError === true ? 'form-control is-invalid' : 'form-control'}
                                                value={day_to_day_communication_phone_number ? day_to_day_communication_phone_number : ''}
                                                onChange={(e) => OnChangeInput(e)}
                                            />
                                            {communicationPhoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
                                        </Form.Group>
                                    </> : ''}
                            </div>
                        </Form.Group>
                    </Row>
                    <hr />
                    <h3 className='text-left mt-4'>landlord mailling address</h3>
                    <Row>
                        <Form.Group as={Col} md='6'>
                            <AutoCompleteAddress
                                md='12'
                                as={Col}
                                id='mailing_current_address'
                                name='mailing_current_address'
                                state={mailing_full_address}
                                updateField={(data) => handleChangeMailing(data)}
                                handlePlaceChanged={(data) => handlePlaceChangedMailing(data)}
                                addressTitle={'Address'}
                            />
                            {(mailing_full_address !== '' && mailing_latitude === null && mailing_longitude === null) && <p style={{ color: 'red' }}>Please select address from google</p>}
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Unit</Form.Label>
                            <Form.Control
                                type='text'
                                name='mailing_unit_no'
                                id='mailing_unit_no'
                                value={mailing_unit_no}
                                placeholder='Type here'
                                onChange={(e) => setMailing_Unit_no(e.target.value)}
                                maxLength={15} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='4'>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type='text'
                                name='mailing_city'
                                id='mailing_city'
                                value={mailing_city}
                                placeholder='Type here'
                                onChange={(e) => setMailing_City(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Province</Form.Label>
                            <Form.Control
                                type='text'
                                name='mailing_province'
                                id='mailing_province'
                                value={mailing_province}
                                placeholder='Type here'
                                onChange={(e) => setMailing_Province(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                type='text'
                                name='mailing_postal_code'
                                id='mailing_postal_code'
                                value={mailing_postal_code}
                                placeholder='Type here'
                                onChange={(e) => setMailing_Postal_code(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                    </Row>
                    <hr />
                    <h3 className='text-left mt-5'>rental property address</h3>
                    <Row>
                        <Form.Group as={Col} md='4'>
                            <AutoCompleteAddress
                                md='12'
                                as={Col}
                                id='current_address'
                                name='current_address'
                                state={current_address}
                                updateField={(data) => handleChange(data)}
                                handlePlaceChanged={(data) => handlePlaceChanged(data)}
                                addressTitle={'Address'}
                            />
                            {(current_address !== '' && latitude_contact === null && longitude_contact === null) && <p style={{ color: 'red' }}>Please select address from google</p>}
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Unit</Form.Label>
                            <Form.Control
                                type='text'
                                name='unit_no_contact'
                                id='unit_no_contact'
                                value={unit_no_contact}
                                placeholder='Type here'
                                onChange={(e) => setUnit_no(e.target.value)}
                                maxLength={15}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='4'>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type='text'
                                name='city_contact'
                                id='city_contact'
                                value={city_contact}
                                placeholder='Type here'
                                onChange={(e) => setCity(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Province</Form.Label>
                            <Form.Control
                                type='text'
                                name='province_contact'
                                id='province_contact'
                                value={province_contact}
                                placeholder='Type here'
                                onChange={(e) => setProvince(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                type='text'
                                name='postal_code_contact'
                                id='postal_code_contact'
                                value={postal_code_contact}
                                placeholder='Type here'
                                onChange={(e) => setPostal_code(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md='6'>
                            <Form.Label>Is this rental unit a condo?</Form.Label>
                            <ul className='select-options'>
                                {condo.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'condo_' + data.value} value={data.value} name='is_condo' id='is_condo'
                                        className={(is_condo_contact === data.value) ? 'active' : ''}>{data.display}</li>)}
                            </ul>
                        </Form.Group>
                    </Row>
                </div>
            </div>
            <div className='container-footer'>
                <Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
                {leaseData && leaseData.is_sent_to_tenant ?
                    <Button variant='success' type='submit' disabled={saveLoading === false ? false : true} onClick={() => setSaveState('update')}>Update {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                    : <Button variant='success' type='submit' disabled={saveLoading === false ? false : true} onClick={() => setSaveState('next')}>Next {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                }
            </div>
        </Form>
        <ModalBox
            show={is_lease_updated}
            onHide={() => redirectLast()}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => {
                redirectLast();
            }}
        >
            <h4>your lease has been updated</h4>
            <p>the tenant has been <br /> notified of the changes. </p>
        </ModalBox>
    </>);
}

export default withRouter(CreateLeaseContactInfo);
