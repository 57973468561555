import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { IMAGE_API_URL } from "../../../../Constants/endPoints"
import axios from 'axios';
import { getAPI, postAPI } from '../../../../Api';
import { logger, toaster } from '../../../../Utils';
import Cookies from "js-cookie";
import { FileBrowse, ModalBox } from '../../../components';
import { Col, Button, Image } from 'react-bootstrap';

import './style.scss';
import { Input } from 'reactstrap';
import { isValidUrl } from './helper';

let log = logger("Advertisement");
const Advertisement = (props) => {
    const [advImage, setAdvImage] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [showImageLoading,setShowImageLoading]=useState(false)
    const [adLink,setAdLink]=useState('')

    useEffect(() => {
        if (advImage !== null) {
            getAPI('/admin/cms/advertisements/list').then(response => {
                if (response.status === 200) {
                    const res = response.data.data;
                    res.map(data => {
                        setAdvImage({file: data.image_url}) 
                        setAdLink(data.image_link)
                    }  )
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }
        setShowImageLoading(true)

        file.map((dataFile) => {
            let fileSize = (dataFile.size / (1024*1024)).toFixed(2);
            
            if (fileSize > 1.5) {
                toaster('error', 'File size should not exceed 1.5 MB');
                return false;
            }
            const url = IMAGE_API_URL;
            let formdata = new FormData();
            formdata.append('image', dataFile);
            
            axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImage({ percent: percent });
                        return file;
                    } else {
                        setImage({ percent: percent });
                        return null;
                    }
                }
            })
            .then(response => {
                let img = document.createElement("img");
                img.src = response.data.data.url;
                img.style.display = 'none';
                img.addEventListener('load', (event) => {
                    log.info("image loaded...");
                    if (img.width !== 950 && img.height !== 250) {
                        toaster('error', 'Recommended dimention for advertisement banner is 950 x 250 (px)');
                    } else {
                        toaster('success', 'Images uploaded successfully!');
                    }
                    setTimeout(() => { img.remove() }, 1000);
                })
                document.body.appendChild(img);

                setImage({ file: response.data.data.url })
                setErrorClass(false)
                setShowImageLoading(false)
            })
            return dataFile;
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid=isValidUrl(adLink)
        if(!isValid)
        {
             setErrorClass({adLink:'Please enter valid link'})
            return   
        }
        const url = '/admin/cms/advertisements/update';
        const data = { "data": [{img:advImage.file,link:adLink}] }

        setSaveLoading(true)
        postAPI(url, data).then(response => {
            setSaveLoading(false);
            if (response.status === 200) {
                const message = response.data.message;
                setConfirmMessage(message)
                setConfirmAction(true)
                setErrorClass('')
            }
        })
    }
    const onClickYes = () => {
        setDeleteModal(false)
        setConfirmAction(true)
        setAdvImage({file: null})
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }
    const onAdLinkChange = (e) => {
        setAdLink(e.target.value)
        if (errorClass) {
            const isValid = isValidUrl(e.target.value)
            setErrorClass(isValid ? '' : errorClass)
        }
    }
    return (
        <div className="advertisementPage">
            {advImage.file !== null ?
                <div className="available-image-container">
                    <div className="available-image" style={{'background':`url(${advImage.file}) no-repeat center center`}}>
                        <Image className='remove-icon'
                            src={require('../../../../_assets/images/remove-icon.svg')}
                            alt='remove-icon'
                            onClick={() => setDeleteModal(true)} />
                    </div>
                </div>
                :
                <FileBrowse
                    handleDrop={(e) => handleDrop(e, setAdvImage)}
                    size={'sm'}
                    percent={advImage.percent}
                    multiple={false}
                    fileType={'.jpg,.png,.jpeg'}
                    disable={false}
                    showImageLoading={showImageLoading}
                    imageData={advImage.file && advImage.file}
                    removeImage={() => setDeleteModal(true)}
                    errorClass={errorClass === true ? "error-upload-box" : ""}
                />
            }

            <Col className="d-flex justify-content-end px-0 mt-3" style={{alignItems:'center'}}>
                <div style={{marginRight:20,flex:1}}> 
                <Input invalid={errorClass?.adLink} onChange={onAdLinkChange} value={adLink} placeholder={'Please enter link '}  />
                </div>
                <Button className='btn-publish' type='submit' onClick={(e) => onSubmit(e)} disabled={saveLoading === true ? true : false}>update {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
            </Col>
            <div className={'error_msg'} style={{marginTop:-10}}>{errorClass?.adLink}</div>
              
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes(setAdvImage)}
                backbuttontitle={'Not Now'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    Are you sure you want to delete content? This cannot<br /> be undo.
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => setConfirmAction(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => setConfirmAction(false)}
            >
                <h4>{confirmMessage === '' ? 'Advertisement has been deleted.' : confirmMessage}</h4>
            </ModalBox>
        </div>
    )
}

export default withRouter(Advertisement);
