import React, { Component } from "react";
import AppLayout from "../../AppLayout";
import "./style.scss";
import "../../Profile/style.scss";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import * as yup from "yup";
import { FormFeedback } from "reactstrap";
import PageTitleHeader from "../../PageTitleHeader";
import ModalBox from "../../ModalBox";
import LargeButton from "../../Buttons/LargeButton";
import { postAPI } from '../../../../Api';
import { logger, toaster } from "../../../../Utils"
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

const schema = yup.object().shape({
  new_password: yup
    .string()
    .required("New password is required"),
  // .min(8)
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords do not match")
    .required("Password confirm is required"),
});

const log = logger("ForgetChangePassword");
class ForgetChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal = async () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.history.location.state.toastMsg && toaster("success", this.props.history.location.state.toastMsg);
    }, 400);
  }

  render() {
    const self = this;

    return (
      <>
        <AppLayout>
          <div className="profile">
            <PageTitleHeader
              title="forgot password"

              isArrow={true}
              onClick={() => this.props.history.goBack()}
            />

            <div className="profile-container">
              <div className="profile-container-box">
                <div className="inner-wrapper">
                  <div className="profile-item">
                    <div className="image-wrapper">
                      <img
                        width="67.08"
                        height="69.99"
                        alt="entercode"
                        src={require("../../../../_assets/images/entercode.png")}
                        className="d-inline-block align-top"
                      />
                      <span className="bl-img-title navbar-brand-name">
                        Change Password
                      </span>
                      <span style={{ marginTop: "20px", fontSize: "20px" }}>
                        You can now change your old password
                      </span>
                    </div>

                    <Formik
                      initialValues={{
                        new_password: "",
                        repeat_password: "",
                      }}
                      validationSchema={schema}
                      onSubmit={async (values, actions) => {
                        try {
                          const data = {
                            pin: Cookies.get("pin"),
                            type: Cookies.get("type"),
                            new_password: values.new_password,
                            repeat_password: values.repeat_password,
                          };
                          const role = Number(Cookies.get('role'));
                          let userRole = '';
                          if (role === 2) {
                            userRole = 'landlord'
                          } else if (role === 3) {
                            userRole = 'tenant'
                          }
                          const url = `/${userRole}/change-forgot-password`;
                          await postAPI(url, data).then(response => {
                            if (response.status === 200) {
                              toaster('success', response.data.message)
                              Cookies.remove('type')
                              Cookies.remove('pin')
                              setTimeout(() => {
                                this.props.history.push("/profile");
                              }, 1000);
                            } else if (response.response) {
                              if (response.response.status === 422) {
                                if (response.response.data.errors.new_password) {
                                  toaster('error', response.response.data.errors.new_password[0]);
                                } else if (response.response.data.errors.pin) {
                                  toaster('error', 'Your 4 digit PIN has expired');
                                }
                              } else if (response.response.status === 400) {
                                toaster('error', response.response.data.message);
                              }
                            } else {
                              log.info('response error', response)
                            }
                          })
                        } catch (err) {
                          self.setState({
                            isModalOpen: !self.state.isModalOpen,
                            modalText: err.message,
                          });
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                          <FormikForm className="profile-form">
                            <Form.Group
                              className="mb-allign"
                              controlId="formNewPassword"
                            >
                              <Form.Label>New Password</Form.Label>
                              <Field name={"new_password"}>
                                {({
                                  field: { name, value, onChange, onBlur },
                                  form: { touched, errors },
                                  meta,
                                }) => {
                                  return (
                                    <React.Fragment>
                                      <Form.Control
                                        className="bg-pf-ip profile-input"
                                        type="password"
                                        placeholder="Input Here"
                                        name={name}
                                        onChange={handleChange}
                                        isInvalid={!!meta.error}
                                        value={value}
                                        onBlur={handleBlur}
                                      />
                                      {meta.touched && meta.error && (
                                        <FormFeedback>{meta.error}</FormFeedback>
                                      )}
                                    </React.Fragment>
                                  );
                                }}
                              </Field>
                            </Form.Group>
                            <Form.Group controlId="formRepeatPassword">
                              <Form.Label>Repeat Password</Form.Label>
                              <Field name={"repeat_password"}>
                                {({
                                  field: { name, value, onChange, onBlur },
                                  form: { touched, errors },
                                  meta,
                                }) => {
                                  return (
                                    <React.Fragment>
                                      <Form.Control
                                        className="bg-pf-ip profile-input"
                                        type="password"
                                        placeholder="Input Here"
                                        name={name}
                                        onChange={handleChange}
                                        isInvalid={!!meta.error}
                                        value={value}
                                        onBlur={handleBlur}
                                      />
                                      {meta.touched && meta.error && (
                                        <FormFeedback>{meta.error}</FormFeedback>
                                      )}
                                    </React.Fragment>
                                  );
                                }}
                              </Field>
                            </Form.Group>

                            <div className="button-wrapper">
                              <LargeButton
                                title="Confirm"
                                type="submit"
                                onClick={this.onSubmitClick}
                              />

                              <LargeButton
                                variant="secondary"
                                title="Cancel"
                                type="button"
                                onClick={() => this.props.history.push('/profile')}
                              />
                            </div>
                          </FormikForm>
                        )}
                    </Formik>
                  </div>
                </div>
                <ModalBox
                  show={this.state.isModalOpen}
                  onHide={this.toggleModal}
                  size="sm"
                  actionbuttontitle={"got it"}
                  buttonaction={
                    () => {
                      this.toggleModal();
                      this.props.history.push("/profile");
                    }
                  }
                >
                  <p>Your password has been updated</p>
                </ModalBox>
              </div>
            </div>
          </div>
        </AppLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(ForgetChangePassword));
