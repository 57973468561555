import { getAPI } from "../../../../Api";
import Cookies from 'js-cookie';

export const getProfileDetails = async (userId,conversation_id) => {
    // debugger
    const role = parseInt(Cookies.get('role'))
    let url = ''
    if(role === 2){
        url = `/landlord/tenant-profile/${userId}?conversation_id=${conversation_id}`;
    }else if(role === 3){
        url = '/tenant/profile';
    }
    try {
        const res = await getAPI(url)
        if(res.status === 200){
            const result = res.data.data
            return result
        }
        return null
    } catch (error) {
        return null
    }
}