import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster } from '../../../Utils';
import { AppLayout, ModalBox, PageTitleHeader } from '../../components';
import { CreateLeaseContactInfo, CompleteLeaseDetails, CreateLeaseDetails, CreateLeaseTenantDetails, SignLease, LeasePdfView, AddingTenants } from '../../components/LeaseManagement/CreateLeaseSteps';
import { CheckListTenant } from '../../components/LeaseManagement/UploadLease';
import { Button } from 'react-bootstrap';
import PDFViewerComponent from '../../components/PDFViewerComponent/PDFViewerComponent';
import { updateRawData } from "../../../Store/actions/RawDataAction";
import { getLeaseDetails } from './helper';
import CheckSignedLease from './CheckSignedLease';

const CreateLease = (props) => {
	const noticeDataRaw = useSelector((state) => state.rawData)
	const { leaseCurrentStep = 0, leaseId = 0 } = noticeDataRaw || {}

	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState(leaseId || 0);
	const [leaseData, setLeaseData] = useState(null);
	const [currentStep, setCurrentStep] = useState((leaseCurrentStep) || 0);
	const [stepCompleted, setstepCompleted] = useState(0);
	const [addTenant, setAddTenant] = useState(false);
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [searchTenant, setSearchTenant] = useState(false);
	const [signLease, setOpenSignLease] = useState(false);
	const [is_signOffline, setSignOffline] = useState(false);
	const [tenantList, setTenantList] = useState(null);
	const [selectedTenant, setSelectedTenant] = useState(null);
	const [is_LeasePdfViewScreen, setLeasePdfViewScreen] = useState(false);
	const [is_pdfModal, setPdfModal] = useState(false);
	const [isEditClicked, setEditClicked] = useState(false);
	const [update, setUpdate] = useState(false);
	const [view, setView] = useState(false);

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));

		setLeaseId(Number(props.match.params.lease_id));
		props.location.state && updateRawData({ leaseId: props.match.params.lease_id })

		if (props.location.state && props.location.state.step) {
			updateRawData({ leaseCurrentStep: props.location.state.step })
			setCurrentStep(props.location.state.step);
		}
		if (props.location.state && props.location.state.view) {
			setView(props.location.state.step);
		}

	}, [props]);

	useEffect(() => {
		updateRawData({ leaseCurrentStep: currentStep })
	}, [currentStep]);

	useEffect(() => {
		if (property_details_id && lease_id) {
			(async()=>{
				updateRawData({ leaseId: lease_id })
				const res = await getLeaseDetails(property_details_id, lease_id)
			
				if (res.status === 200) {
					const fetchedData = res.data;
					setLeaseData(fetchedData);
					updateRawData({ leaseData: fetchedData })
					setstepCompleted(fetchedData.step_completed);
					setEditClicked(false);
					selectedTenant === null && fetchedData.tenant_details && setSelectedTenant(fetchedData.tenant_details.tenants)
				} else if (res.status === 404) {
					props.history.push({
						pathname: '/404',
						state: { message: 'This lease you are trying to view no longer exists.' }
					});
				}else{
					toaster("error", res.error)
				}
			})()
		}
	}, [lease_id, currentStep, is_LeasePdfViewScreen, property_details_id, selectedTenant, props.history]);

	useEffect(() => {
		document.getElementById('profileTop').scrollIntoView();
	}, [currentStep])


	const goBack = () => {
		setOpenSwitchModal(false);
	}

	const back = () => {
		props.history.push({
			pathname: `/property-details/${property_id}/${property_details_id}/leases`
		})
	}

	const setCurrentStepData = (data) => {
		setCurrentStep(data);
		updateRawData({ leaseCurrentStep: data })
		setEditClicked(true);
		props.history.push({
			pathname: `/create-lease/${property_id}/${property_details_id}/${lease_id}`,
			state: {
				lease_id: lease_id,
				step: data
			}
		})
	}

	const handleSelectedTenant = (data) => {
		setUpdate(true);
		setSelectedTenant(data);
		setUpdate(false);
	}

	const handleContinueLeaseCreation = (stepCompleted) => {
		setCurrentStep(stepCompleted);
		setView(false);
	}

	return (<>

		<AppLayout>
			<div id={"profileTop"} >
				<PageTitleHeader
					isArrow={true}
					onClick={() => back()}
					title={view && currentStep === 3 && isEditClicked === false ? 'Lease information' : (leaseData && leaseData.is_sent_to_tenant === true ? 'Editing lease details' : (currentStep === 3 ? 'review lease info' : 'Create a lease'))}
					subTitle={
						leaseData && leaseData.is_sent_to_tenant === true ? leaseData && leaseData.property_address.address : (currentStep === 0) ? 'Fill out the info below to begin' :
							(currentStep === 1) ? 'Let’s talk about the lease details' :
								(currentStep === 2) ? 'One page to go and you’re done' :
									(currentStep === 3) && (!view && currentStep !== stepCompleted ? leaseData && leaseData.property_address.address : 'Review the lease details')
					}
					rightBtn={(currentStep === 3 && currentStep !== stepCompleted && stepCompleted < 3) && (leaseData && leaseData.is_sent_to_tenant === false && leaseData.is_signed === false) ? 'true' : 'false'}
					rightBtnText={'Continue Lease Creation'}
					rightBtnStyle={{ width: '250px' }}
					rightBtnHandler={() => handleContinueLeaseCreation(stepCompleted)}
					progress={
						(currentStep !== stepCompleted && currentStep !== 3) || isEditClicked === true ? ((currentStep === 0) ? 20 :
							(currentStep === 1) ? 50 :
								(currentStep === 2) ? 70 :
									(currentStep === 3) && 90) : false
					}
					extraElm={currentStep === 3 && leaseData && isEditClicked === false && leaseData.is_sent_to_tenant === true && (leaseData.lease_deatils && leaseData.lease_deatils.lease_pdf !== '') &&
						<Button
							variant="link"
							className="btnDownloadLease"
							disabled={leaseData.lease_deatils && leaseData.lease_deatils.lease_pdf === '' ? true : false}
							onClick={() => setPdfModal(true)}> Download Lease
						</Button>}
				/>
			</div>

			<CheckSignedLease
				currentStep={currentStep}
				leaseData={leaseData}
				view={view}
			/>

			<div className='profile-container create-lease'>
				<div className='profile-container-box'>

					{currentStep === 0 &&
						<CreateLeaseContactInfo
							setCurrentStep={(data) => setCurrentStep(data)}
							property_id={property_id}
							property_details_id={property_details_id}
							leaseData={leaseData}
							lease_id={lease_id}
							setLeaseId={(data) => setLeaseId(data)}
						/>
					}
					{currentStep === 1 &&
						<CreateLeaseDetails
							setCurrentStep={(data) => setCurrentStep(data)}
							property_id={property_id}
							property_details_id={property_details_id}
							leaseData={leaseData}
							lease_id={lease_id}
						/>
					}
					{currentStep === 2 && update === false &&
						<CreateLeaseTenantDetails
							setCurrentStep={(data) => setCurrentStep(data)}
							property_id={property_id}
							property_details_id={property_details_id}
							leaseData={leaseData}
							lease_id={lease_id}
							callAddTenant={(flag) => setSearchTenant(flag)}
							selectedTenant={selectedTenant}
							setSelectedTenant={(data) => setSelectedTenant(data)}
						/>
					}
					{currentStep === 3 &&
						<CompleteLeaseDetails
							setCurrentStep={(data) => setCurrentStepData(data)}
							property_id={property_id}
							property_details_id={property_details_id}
							leaseData={leaseData}
							lease_id={lease_id}
							setOpenSignLease={(flag) => setOpenSignLease(flag)}
							setLeasePdfViewScreen={(flag) => setLeasePdfViewScreen(flag)}
							inProgress={currentStep !== stepCompleted}
							leaseCreationHandler={() => setCurrentStep(stepCompleted)}
						/>
					}
				</div>
			</div>

		</AppLayout>

		<CheckListTenant
			isShow={searchTenant}
			handleClose={() => setSearchTenant(false)}
			property_id={property_id}
			property_details_id={property_details_id}
			setAddTenant={(flag) => setAddTenant(flag)}
			newTenantList={tenantList}
			selectedTenantList={selectedTenant}
			setSelectedTenant={(data) => handleSelectedTenant(data)}
		/>

		<AddingTenants
			isShow={addTenant}
			handleClose={() => setAddTenant(false)}
			property_details_id={property_details_id}
			sendBackRes={(flag) => setSearchTenant(flag)}
			setTenantList={(data) => setTenantList(data)}
		/>


		<SignLease
			isShow={signLease}
			handleClose={() => setOpenSignLease(false)}
			lease_id={lease_id}
			setLeasePdfViewScreen={(flag) => setLeasePdfViewScreen(flag)}
			is_sent_to_tenant={leaseData?.is_sent_to_tenant}
			setSignOffline={(flag) => setSignOffline(flag)}
		/>

		<LeasePdfView
			isShow={is_LeasePdfViewScreen}
			handleClose={() => setLeasePdfViewScreen(false)}
			handleBack={() => setOpenSignLease(true)}
			lease_id={lease_id}
			role={2}
			is_signOffline={is_signOffline}
			is_sent_to_tenant={leaseData?.is_sent_to_tenant}
			isUpdated={leaseData?.is_updated}
		/>

		<ModalBox
			show={is_pdfModal}
			onHide={() => setPdfModal(false)}
			size="xl"
			className="cu-full-modal"
		>
			<PDFViewerComponent PDFUrl={(leaseData && leaseData.lease_deatils) ? leaseData.lease_deatils.lease_pdf : ''} />
		</ModalBox>

		<ModalBox
			show={openSwitchModal}
			onHide={goBack}
			size="sm"
			actionbuttontitle={"got it"}
			buttonaction={goBack}
		>
			<p classNmae="switching-details">Lease has been sent to tenant. You will be notified when it has been confirmed</p>
		</ModalBox>

	</>);
}

export default withRouter(CreateLease);
