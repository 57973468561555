import React from 'react'
import { LargeButton } from '../../../../components';
import { Button } from 'react-bootstrap';

const CompleteLeaseDetailsFooter = props => {
    const {leaseDetailsData, viewAppendix, inProgress, leaseData, leaseCreationHandler, uploadData, loading, handleSubmitSignedLease, onSubmit} = props;
    return (
        <div className="d-flex justify-content-between align-items-center">
				{(leaseDetailsData && leaseDetailsData.lease_appendix !== '') ?
					<LargeButton title='view lease appendix' variant='dark' onClick={() => viewAppendix()} style={{ display: 'block', width: "300px", height: "40px", padding: "0px" }} /> : <span></span>}
				<div className='container-footer'>
					{inProgress && (leaseData && leaseData.step_completed < 3 && leaseData.is_sent_to_tenant !== true && leaseData.is_signed !== true) ? <Button variant='success' type='submit' style={{ width: '250px' }} onClick={leaseCreationHandler}>Continue Lease Creation</Button> :
						<>
							{uploadData && uploadData.file ? <Button variant='success' disabled={(uploadData && uploadData.file) && loading === false ? false : true} type='submit' onClick={() => handleSubmitSignedLease()}>Submit</Button> : <Button variant='success' type='submit' id='BTN-create-lease-review' data-description='Landlord reviewed lease' onClick={() => onSubmit()}>Next</Button>}
						</>
					}
				</div>
			</div>
    )
}

export default CompleteLeaseDetailsFooter
