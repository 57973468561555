import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { TableCoupon } from "../../components";
import { postAPI } from "../../../Api";
import TableContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader";
import { toaster } from "../../../Utils";
import { setActiveCouponTableData, setActiveCouponCurrentPage } from "../../../Store/actions/Action";

const ActiveCoupon = (props) => {
  // const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [couponCode, setCouponCode] = useState("")
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [user_type, setUserType] = useState("");
  const [createdDate, setCreatedDate] = useState("desc");
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("")
  const [noOfUses, setNoOfUses] = useState("");
  const [couponValue,setCouponValue]=useState("")
  const [reloadList, setReloadList] = useState(false);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.CouponReducer.filters)
  const [isLoading, setIsLoading] = useState(true);
  const [oldFilter, setOldFilter] = useState({...filters})
  const tableData = useSelector(state => state.CouponReducer.activeCouponTableData)
  const pagination = useSelector(state => state.CouponReducer.activeCouponCurrentPage)
  
  let dataFilter = {
    status: 1,
    ...filters
  };
  
  const getData = (url) => {
    postAPI(url, dataFilter)
    .then(response => {
      if(response.status !== 200 && response.response){
        toaster('error', 'Something went wrong!')
      }else{
        dispatch(setActiveCouponTableData(response.data.data))
      }
    })
    .catch(error => {
      toaster('error', 'Something went wrong!')
    })
  }

  // useEffect(() => {
  //   if (createdDate !== null || noOfUses !== null || startDate !== null || expiryDate!==null || couponValue!==null || name!==null || couponCode!== null)  {
  //     let urlType = "/admin/coupons/list";
  //     postAPI(urlType, dataFilter).then((response) => {
  //       setIsLoading(false);
  //       setTableData(response.data.data);
  //     });
  //   }
  // }, [createdDate, noOfUses, startDate, expiryDate, couponValue, name, couponCode]);

  useEffect(() => {
    let url = "";
    if(JSON.stringify(oldFilter) !== JSON.stringify(filters)){
      url = `/admin/coupons/list`
      setOldFilter({...filters})
    }else{
      if(pagination !== null && pagination > 1){
        url = `/admin/coupons/list?page=${pagination}`
      }else{
        url = `/admin/coupons/list`
      }
    }
    getData(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters, pagination, props.reload])

  useEffect(() => {
    console.log(props)
    let urlType = "/admin/coupons/list";
    postAPI(urlType, dataFilter).then((response) => {
      setIsLoading(false);
      dispatch(setActiveCouponTableData(response.data.data))
    });
  }, []);

  // useEffect(() => {
  //   setReloadList(props.reload);
  //   if (props.reload === true) {
  //     let urlType = "/admin/coupons/list";
  //     postAPI(urlType, dataFilter).then((response) => {
  //       setIsLoading(false);
  //       dispatch(setActiveCouponTableData(response.data.data))
  //       setReloadList(false);
  //     });
  //   }
  // }, [props.reload]);

  const onClickNext = (currentPage) => {
    const nextPage = currentPage + 1;
    dispatch(setActiveCouponCurrentPage(nextPage))
  };

  const onClickPrev = (currentPage) => {
    const prevPage = currentPage - 1;
    dispatch(setActiveCouponCurrentPage(prevPage))
  };

  

  let userColumns = [];
  userColumns = [
    {
      header: "Name",
      name: "name",
      width: "14.28%",
    },
    {
      header: "Created Date",
      name: "created_date",
      width: "14.28%",
    },
    {
      header: "Total uses",
      name: "no_of_uses",
      width: "14.28%",
    },
    {
      header: "Use per user",
      name: "total_uses",
      width: "14.28%",
    },
    {
      header: "start date",
      name: "start_date",
      width: "14.28%",
    },
    {
      header: "expire date",
      name: "expiry_date",
      width: "14.28%",
    },
    {
      header: "coupon value",
      name: "coupon_value",
      width: "14.28%",
    },
    {
      header: "coupon code",
      name: "coupon_code",
      width: "14.28%",
    },
  ];
  console.log("user_type", user_type);
  return (
    <React.Fragment>
      {isLoading === true ? (
        <TableContentLoader
          columns={userColumns}
          component={"userManagement"}
        />
      ) : (
        <>
          {tableData.data ? (
            <TableCoupon
              columns={userColumns}
              tableData={tableData.data}
              to={tableData.to}
              from={tableData.from}
              current_page={tableData.current_page}
              last_page={tableData.last_page}
              per_page={tableData.per_page}
              total={tableData.total}
              setName={(data) => setName(data)}
              setCouponCode={(data)=>setCouponCode(data)}
              setEmail={(data) => setEmail(data)}
              setPhoneNumber={(data) => setPhoneNumber(data)}
              setUserType={(data) => setUserType(data)}
              setCreatedDate={(data) => setCreatedDate(data)}
              setStartDate={(data) => setStartDate(data)}
              setExpiryDate={(data)=>setExpiryDate(data)}
              setCouponValue={(data)=>setCouponValue(data)}
              setNoOfUses={(data) => setNoOfUses(data)}
              name={name}
              couponCode={couponCode}
              email={email}
              phone_number={phone_number}
              user_type={user_type}
              deleteCoupon={props.deleteCoupon}
              viewCoupon={props.viewCoupon}
              clickNext={() => onClickNext(tableData.current_page)}
              clickPrev={() => onClickPrev(tableData.current_page)}
              // onClickSwitch={(data) => onClickSwitch(data)}
              component={"activeCoupons"}
            />
          ) : (
            ""
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default withRouter(ActiveCoupon);
