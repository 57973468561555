import React from "react";
import { SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";

const InsertSections = (props) => {
    const { isShow, handleClose,sections,openAddItemSection,setSectionId,openCustomizePanel } = props;

    
    return (<>
        <SidePanel
        extraClasses="p-0"
        headerClass="add-section-pad"
        isShow={isShow}
        isHide={() => handleClose("newSection")}
        title={"Create New Section"}
      >
        <div className="section-sub-title">
          <span>What Sections You Want To Insert</span>
        </div>
        {sections.map((section, index) => {
          return (
            <div
              onClick={() => {
                openAddItemSection(section.section_name);
                setSectionId(section.inspection_section_id);
              }}
              className="cursor-pointer d-flex align-self-center justify-content-between indi-section"
            >
              <span>{section.section_name}</span>
              <img src={require("../../../../_assets/images/next.svg")} />
            </div>
          );
        })}
        <div
          onClick={openCustomizePanel}
          className="cursor-pointer d-flex align-self-center justify-content-between indi-section"
        >
          <span>Customize Section</span>
          <img src={require("../../../../_assets/images/next.svg")} />
        </div>
      </SidePanel>
    </>);
}
export default withRouter(InsertSections);