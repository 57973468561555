export const WarningFormSchema = {
    // fullName: [
    //     { rule: "required", message: "date required" },
    // ],    
    // description: [
    //     { rule: "required", message: "required" },
    // ],
    landlord_fullName:[
        { rule: "required", message: "required" },
    ],
    landlord_phoneNumber:[
        { rule: "required", message: "required" },
    ],
    
    
}