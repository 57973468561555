import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { logger } from "../../../../Utils";
import { LargeButton, CancelButton, SidePanel } from "../../../components";
import { postAPI } from "../../../../Api";
import { useSelector } from "react-redux";

const log = logger("ConfirmListTenants");
const ConfirmListTenants = (props) => {
    const { isShow, handleClose, property_id, lease_id, openAddTenantWindow, property_details_id, apiCallback } = props;
    const listTenants = useSelector(state => state.addedTenantReducer.addedTenants);
    const [getSelectedList, setSelectedList] = useState([]);

    log.info('in confirm list tenant');

    const onValueChange = (e, data) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        log.info(item, isChecked);
        isChecked === true ? getSelectedList.push(data) : getSelectedList.splice(getSelectedList.indexOf(item), 1);
        setSelectedList(getSelectedList)
    }

    const handleCloseConfirm = () => {
        handleClose();
    }
    const addAndInvite = () => {
        const url = "/admin/listing/lease/confirm-tenant";
        const data = [];
        getSelectedList.map((list) => {
            let mapList = {
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "tenant_id": list.id,
                "lease_id": Number(lease_id)
            }
            data.push(mapList)
            return list;
        })

        postAPI(url, data).then(res => {
            if (res.status === 200) {
                handleClose();
                apiCallback(true);
            }
        })
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Add Another Tenant">
            <h4 className="edit-sub">Select a tenant from your contact list</h4>
            <div className="deactive-reasons sidepanel-body">
                {listTenants !== null ?
                    <>
                        {listTenants[0].map((data, i) => {
                            return <div className="row border-reason-checkbox checkbox-left check-status">
                                <div className="col-12 mb-2" key={i}>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox large">
                                            <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                name={data.id}
                                                checked={getSelectedList.find(elm => elm.id === data.id)} onChange={(e) => onValueChange(e, data)}
                                            />
                                            <label className="custom-control-label pl-4 pt-1" htmlFor={"customCheck" + data.id}>{data.name}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </>
                    : <h4 className="edit-sub text-center">There is no tenant found</h4>}


                <Form.Group as={Col} md="12">
                    <LargeButton isIcon={true} title='Add tenant outside of Rent Panda' variant="dark" onClick={() => openAddTenantWindow(true)} />
                </Form.Group>
            </div>

            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                <LargeButton title='add and invite' onClick={() => addAndInvite()} style={{ width: '160px' }} />
            </div>
        </SidePanel>
    </>);
}
export default ConfirmListTenants;