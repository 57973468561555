import React from "react";
import '../style.scss';
import { DollorNumberDisplay } from "../../../components";
import { logger } from "../../../../Utils";

const log = logger("SidePanelAddress");
const SidePanelAddress = (props) => {
    const { details } = props;
    log.info(details)
    return (<>
        <div className="showing-address-unit">
            <h2 className="property-title m-0">{details && details.address}</h2>
            <div className="details-wrapper">
                <div className="full-width">
                    <span className="specification pb-1">Unit {details && details.unit_no} - <DollorNumberDisplay price={details && details.price_per_month} /> per month</span><br />
                </div>
            </div>
        </div>
    </>);
}

export default SidePanelAddress;