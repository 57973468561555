import moment from 'moment'

export const transFormSignData = (signData) => {

     return ({
          full_name: signData?.signingName,
          last_name: signData?.lastName,
          landlord_signature: signData?.full_name_sign,
          date: signData?.signDate && signData?.signDate?.length ? moment(signData?.signDate).toDate() : moment(),
          phone_number: signData?.phoneNumber,
          first_name: signData?.firstName,
          tenant_first_name: signData?.tenant_first_name,
          tenant_last_name: signData?.tenant_last_name,
          tenant_phone_number: signData?.tenant_phone_number,
          tenant_sign_date: signData?.tenant_sign_date ? moment(signData?.tenant_sign_date).toDate() : moment(),
          tenant_signature: signData?.tenant_signature
     })
}

export const convertTime24to12 = (timeStr) => {
     if (timeStr.toLowerCase().includes('am') || timeStr.toLowerCase().includes('pm')) {
          const arr = timeStr.split(" ")
          const timeArr = arr[0].split(":")
          return ({ hr: timeArr[0], min: timeArr[1], ampm: arr[1] })
     }


     let time = timeStr.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeStr];

     if (time.length > 1) {
          time = time.slice(1);
          time[5] = +time[0] < 12 ? ':AM' : ':PM';
          time[0] = +time[0] % 12 || 12;
     }
     const timeString = time.join('')
     const arr = timeString.split(":")
     return { hr: arr[0], min: arr[1], ampm: arr[3] }
}

export const convertTime12to24 = (time12h) => {
     const [time, modifier] = time12h.split(' ');
     let [hours, minutes] = time.split(':');

     if (hours === '12') {
          hours = '00';
     }

     if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
     }

     return { hours, minutes };
}