import React from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { checkIsBottom } from '../../Tenant/SearchListing/helper';
import MessageTenantListLoader from '../ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/MessageTenantListLoader/Index';
import MessageSortBar from './MessageSort';
import './style.scss';

const TenantListForMessage = (props) => {
  const {
    tenantListClick,
    headerContent,
    isLoading,
    activeKey,
    setActiveKey,
    listData = [],
    onScrollDown = () => {},
    showBottomLoader,
  } = props;
  const handleDropdownSelect = (key) => {
    setActiveKey(key);
  };

  const handleScroll = (e) => {
    const isBottom = checkIsBottom(e);
    if (isBottom) {
      onScrollDown();
    }
  };

  return (
    <>
      <MessageSortBar
        handleDropdownSelect={handleDropdownSelect}
        activeKey={activeKey}
      />
      <div
        style={{ maxHeight: 500, overflowY: 'auto' }}
        onScroll={handleScroll}
      >
        {isLoading === true ? (
          <MessageTenantListLoader />
        ) : (
          <>
            {listData?.length !== 0 ? (
              <>
                {listData?.map((data, i) => {
                  const {
                    new_tenancy_application,
                    new_viewing_request,
                    new_message,
                  } = data;

                  return (
                    <div
                      className={`message-list-card${
                        headerContent &&
                        headerContent.conversation_id !== null &&
                        headerContent.conversation_id === data.conversation_id
                          ? ' highlight'
                          : ''
                      }${new_message ? ' green-card' : ''}`}
                      key={'tenant' + i}
                      onClick={() => tenantListClick(data)}
                    >
                      {data.is_verified === true && (
                        <div className={'verified status'}>verified</div>
                      )}
                      <h4 className="tenant-name">{data.name}</h4>
                      {data.contacted_on !== null && (
                        <p className="tenant-contact-date">
                          Contacted on: {data.contacted_on}
                        </p>
                      )}
                      <div className="list-inline">
                        <div className="list-li">
                          <div className="icon-wrap">
                            <Image
                              width="20px"
                              height="14px"
                              alt="list-grid-icon"
                              src={require(`../../../_assets/images/messages/messages.svg`)}
                            />
                            {new_message && (
                              <div className="message-active"></div>
                            )}
                          </div>
                        </div>
                        <div className="list-li">
                          <div className="icon-wrap">
                            <Image
                              width="20px"
                              height="14px"
                              alt="list-grid-icon"
                              src={require(`../../../_assets/images/messages/calender.svg`)}
                            />
                            {new_viewing_request && (
                              <div className="message-active"></div>
                            )}
                          </div>
                        </div>
                        <div className="list-li">
                          <div className="icon-wrap">
                            <Image
                              width="20px"
                              height="14px"
                              alt="list-grid-icon"
                              src={require(`../../../_assets/images/messages/application.svg`)}
                            />
                            {new_tenancy_application && (
                              <div className="message-active"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {showBottomLoader ? (
                  <center>
                  <Spinner animation="border" variant="success" />
                  </center>
                ) : (
                  ''
                )}
              </>
            ) : (
              <div className="no-data">Tenant not present</div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default TenantListForMessage;
