import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { logger } from "../../../Utils";
import "./style.scss";
import { ParalegalForm } from "../../components/Notices/NoticeForms";
import {
  N1Info,
  N2Info,
  N3Info,
  N5Info,
  N6Info,
  N7Info,
  N8Info,
  N9Info,
  N10Info,
  N11Info,
  N12Info,
  N13Info
} from "../../components/Notices/ImportantInfo";


const log = logger("NoticeInfo");
const NoticeInfo = (props) => {
  const [notice_type, setNotice_type] = useState(); 
  const [is_Paralegal, setParalegal] = useState(false); 

  const getParalegal = () => {
    setParalegal(true);
  };
  
  useEffect(() => {
    setNotice_type(Number(props.match.params.notice_type));
  }, [props]);

  log.info("notice_type",notice_type);
  return (
    <>  
        {notice_type === 1 && (<N1Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 2 && (<N2Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 3 && (<N3Info getParalegal={() => getParalegal()}/>)}
        
        {notice_type === 5 && (<N5Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 6 && (<N6Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 7 && (<N7Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 8 && (<N8Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 9 && (<N9Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 10 && (<N10Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 11 && (<N11Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 12 && (<N12Info getParalegal={() => getParalegal()}/>)}

        {notice_type === 13 && (<N13Info getParalegal={() => getParalegal()}/>)}

        <ParalegalForm
          isShow={is_Paralegal}
          handleClose={() => setParalegal(false)}
        />
    </>
  );
};

export default withRouter(NoticeInfo);
