import React, { Component } from "react";
import RpImage from "../../components/Image";
import { Nav, Navbar, ListGroup, ListGroupItem } from "react-bootstrap";
import "./style.scss";
class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid footer-section-mobile d-lg-none d-xl-none d-md-none">
          <span className="ml-2 mr-3">
            &#169; 2021 Rent Panda. All rights reserved.
          </span>
        </div>
        <div className="d-none d-sm-block">
          <Navbar
            className="d-flex justify-content-between"
            id="footer-navbar"
            expand="lg"
            sticky="bottom"
            collapseOnSelect
            style={{ flexDirection: 'column' }}
          >
            <div className="footer-head">
              <Navbar.Brand href="/">
                <RpImage
                  height="auto"
                  alt="Rent Panda"
                  src={require("../../../_assets/images/rent-panda-logo.png")}
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
              <div className="pt-5 footer-navbar">
                <div className="container-fluid">
                  <Nav.Link
                    href="/for-property-managers"
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    Property Manager Portal
                  </Nav.Link>
                  <Nav.Link
                    href="/for-realtors"
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    Realtor Portal
                  </Nav.Link>
                  <Nav.Link
                    href="/portals-and-auxillary/advertisers"
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    Advertise on RentPanda.ca
                  </Nav.Link>
                  <Nav.Link
                    href="/for-panda-partners"
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    Partner with Us
                  </Nav.Link>
                </div>
                <div className="d-flex justify-content-end pr-3 pt-3">
                  <Nav.Link
                    href="/about-us"
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    About Us
                  </Nav.Link>
                  {/* <Nav.Link
                    className="navbar-link d-none d-sm-block"
                    onClick={this.onLogout}
                  >
                    Contact Us
                  </Nav.Link> */}
                </div>
              </div>
            </div>
            <div className="container-fluid pb-5 footer-links">
              <div className="d-flex">
                <span className="ml-2 mr-3">
                  &#169; 2021 Rent Panda. All rights reserved.
                </span>
                <ListGroup horizontal>
                  <ListGroupItem tag="a" href="#" style={{ textDecoration: 'underline' }}>
                    Privacy Policy
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#" style={{ textDecoration: 'underline' }}>
                    Terms of Service
                  </ListGroupItem>
                </ListGroup>
              </div>
            </div>
          </Navbar>

        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
