import React from "react";
import ReactDOM from "react-dom";
import ModalBox from "../ModalBox";
import './style.scss'
const UserConfirmation = (message, callback) => {
   const container = document.createElement("div");
   container.setAttribute("custom-confirmation-navigation", "");
   document.body.appendChild(container);
const closeModal = (callbackState) => {
      ReactDOM.unmountComponentAtNode(container);
      // document.body.removeChild(container);
      callback(callbackState);
   };
   ReactDOM.render(    
            <ModalBox
            show={true}
            onHide={() => closeModal(false)}
            size="sm"
            type="condition"
            actionbuttontitle={'No'}
            buttonaction={(e) => {
               if(message==="your changes will not be saved"){
                  closeModal(true)
               }else{
                  closeModal(false)
               }
               }}
            backbuttontitle={'Yes'}
            backaction={() => {
               if(message==="your changes will not be saved"){
                  closeModal(false)
               }else{
                  closeModal(true)
               }
               }}
            >
            {message==="your changes will not be saved" ? <p className="modal-confirmation-text">are you sure you want to leave this page?</p> : <p className="modal-confirmation-text">wait! you have not saved this information yet! </p>}
            <p className='modal-text'>{message}</p>
        </ModalBox>
            ,
   container
  );
};
export default UserConfirmation;