import React, { useEffect, useState } from "react";
// import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import { getAPI } from "../../../../../Api";
// import { logger } from '../../../../../Utils';
import { AppLayout } from "../../../../components";
import { Container } from "react-bootstrap"; //, Row, Col

// const log = logger('Preview Blog');
// this component handles the redirect from old legacy id blog URLs to the new SEO/slug format
const PreviewBlogLegacy = (props) => {  
  useEffect(() => {
    if (props.match.params.id) {
      getAPI(`/cms/articles/view/${props.match.params.id}`).then(
        (resData) => {
          if (resData.status === 200) {
            const resDataView = resData.data.data;
            
            if(resDataView.slug && resDataView.resource_type) props.history.push('/resources/' + resDataView.resource_type + '/blog/' + resDataView.slug);
          }
        }
      );
    }
  }, [props.match.params.id]);

  return (
    <>
    </>
  );
};

export default withRouter(PreviewBlogLegacy);