import React from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SidePanel } from '..';
import { cmsClickedData } from '../../../Store/actions/Action';
import { logger } from '../../../Utils';

import './style.scss';

const log = logger('CreateContent');
const CreateContent = (props) => {
    const { isShow, handleClose } = props
    const dispatch = useDispatch();

    const formList = [
        { value: 'blog', display: 'Blog' },
        { value: 'form-content', display: 'Form Content' },
        { value: 'faq', display: 'FAQ' },
        { value: 'partners', display: 'Partners' },
    ];

    const clickOnList = (value) => {
        log.info(value)
        dispatch(cmsClickedData(null));
        switch (value) {
            case 'blog': return props.history.push('/cms/blog');
            case 'form-content': return props.history.push('/cms/forms/');
            case 'faq': return props.history.push('/create-faq');
            case 'partners': return props.history.push('/cms/partners/create?');
            default: return false;
        }
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            extraClasses={'deactivate'}
            title='create new content'
            subTitle='Please select a content type to create'
        >
            <div className='deactive-reasons sidepanel-body'>
                {formList && formList.map((data, i) => {
                    return <React.Fragment key={'reason' + i}>
                        <Row className='border-reason'>
                            <button className='change-heading arrow-button' onClick={() => clickOnList(data.value)}>
                                {data.display}
                                <img className='next-arrow' src={require('../../../_assets/images/next.svg')} alt='next' />
                            </button>
                        </Row>
                    </React.Fragment>

                })}
            </div>
        </SidePanel>
    </>);
}
export default withRouter(CreateContent);