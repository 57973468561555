import React from "react";
import NumberFormat from "react-number-format";

const DollorNumberDisplay = (props) => {
    const { customClass, price } = props;
    return (
        <NumberFormat value={price} className={customClass} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    );
}
export default DollorNumberDisplay;


