import React, { useState, useEffect } from "react";
import { SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";
import { toaster, validate } from "../../../../Utils";
import { LeasePdfViewSchema } from "./CreateLeaseSchema/LeasePdfViewSchema";
import { leaseSubmit, skipActionApi, textToImage } from "./helper";
import SignLeaseBody from "./SignLeaseComponents/SignLeaseBody";
import SignLeaseFooter from "./SignLeaseComponents/SignLeaseFooter";


const SignLease = (props) => {
    const { isShow, handleClose, lease_id, setLeasePdfViewScreen, setSignOffline, is_sent_to_tenant } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [full_name_sign_Lease, setFullName] = useState('');
    const [full_name_sign_LeaseUrl, setFullNameUrl] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);

    const clearValidationError = () => {
        let ele = document.getElementById('full_name_sign_Lease');
        let classNames = ele.className.split(" ");
        let errorIndex = classNames.indexOf('is-invalid');
        if (errorIndex !== -1) {
            classNames.splice(errorIndex, 1);
            ele.className = classNames.join(" ");
        }
        let errorEle = document.getElementById('error_full_name_sign_Lease');
        if (errorEle) {
            errorEle.remove();
        }
    }

    const handleCloseConfirm = () => {
        setFullName('');
        setFullNameUrl(null)
        clearValidationError();
        handleClose();
    }

    const createSignImage = async (value) => {
        try{
            const dataUri = await textToImage(value);
            setFullNameUrl(dataUri);
        }catch(error){}
    }

    const onChangeInput = (e) => {
        validate(LeasePdfViewSchema)
        setFullName(e.target.value)
        createSignImage(e.target.value)
    }

    const skipAction = async () => {
        const {response, error} = await skipActionApi(lease_id);
        if(error){
            toaster('error', error);
        }else{
            handleClose();
            setLeasePdfViewScreen(true);
            if(!is_sent_to_tenant) {setSignOffline(true);}
        }
    }

    const createSignLease = async () => {
        if (validate(LeasePdfViewSchema)) {
            setSubmitLoader(true);
            const data = {
                "lease_id": lease_id,
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "landlord_lease_signature": full_name_sign_LeaseUrl
            }
            const res = await leaseSubmit(data, 'sign');
            setSubmitLoader(false);
            if (res.status === 200) {
                handleClose();
                setSignOffline(false);
                setLeasePdfViewScreen(true);
            } else {
                toaster('error', res.message);
            }
        }
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleCloseConfirm}
            title="CREATE A LEASE">
            <h4 className="edit-sub">Sign your lease</h4>
            <SignLeaseBody 
                full_name_sign_Lease={full_name_sign_Lease}
                onChangeInput={onChangeInput}
                full_name_sign_LeaseUrl={full_name_sign_LeaseUrl}
                skipAction={skipAction}
            />
            <SignLeaseFooter
                handleCloseConfirm={handleCloseConfirm}
                submitLoader={submitLoader}
                createSignLease={createSignLease}
            />
        </SidePanel>
    </>);
}
export default withRouter(SignLease);