import React from "react";
import "./style.scss";

const SingleOptionCheckInspection = (props) => {
  const {
    mapData,
    OnSingleOptionCheck,
    name,
    setParam,
    extra,
    extraClass,
    type
  } = props;
  return (
    <>
      <ul
        className={
          extraClass
            ? "select-options-inspection " + extraClass
            : "select-options-inspection"
        }
      >
        <div className="w-100" style={{ width: "100%" }}>
          {mapData.map((data) => {
            return (
              <li
                style={{ cursor: type === 'reviewInspection' && 'default' }}
                onClick={(e) => {type !== 'reviewInspection' && OnSingleOptionCheck(e, this)}}
                key={name + data.value}
                value={data.value}
                name={name}
                id={name}                
                className={(data.value === 1
                    ? setParam === data.value
                      ? "good-active good-icon-active text-center  width30 "
                      : "width30 good-icon-deactive text-center"
                    : data.value === 2
                    ? setParam === data.value
                      ? "issue-active issue-icon-active width30 text-center"
                      : "width30 issue-icon-deactive text-center"
                    : setParam === data.value
                    ? "repair-active repair-icon-active width30 text-center"
                    : "width30 repair-icon-deactive text-center")}
                // className={
                //   setParam === data.value ? "active width30 " : "width30"
                // }
              >
                                
                  {/* <img
                    src={require("../../../_assets/images/" +
                      (data.value === 1
                        ? setParam === data.value
                          ? "good-active.svg"
                          : "good-inactive.svg"
                        : data.value === 2
                        ? setParam === data.value
                          ? "issue-active.svg"
                          : "issue-inactive.svg"
                        : setParam === data.value
                        ? "repair-active.svg"
                        : "repair-inactive.svg"))}
                  /> */}
                 
                    {data.title}
                  
              
              </li>
            );
          })}
          {extra && extra}
        </div>
      </ul>
    </>
  );
};
export default SingleOptionCheckInspection;
