import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { LargeButton, SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { logger } from "../../../../Utils";
import { dateFormat2 } from "../../../../Utils/DateSanitizer/DateSanitizer";
const log = logger("ListLeaseViewDetails");
const ListLeaseViewDetails = (props) => {
    const { isShow, handleClose, user_type, path } = props;
    const [user_id, setUserId] = useState(false);
    const [lease_id, setLeaseId] = useState(false);
    const leaseData = useSelector(state => state.propertyDetailsReducer.leaseFetchedData)
    log.info('leaseFetchedData', user_type)
    const viewLeaseDetails = () => {
        props.history.push({
            pathname: `/review-lease-detail/${user_type}/${lease_id}/${user_id}/${leaseData.property_id}/${leaseData.property_detail_id}`,
            state: { leaseData: leaseData, path: path }
        })
    }
    const download = () => {
        window.open(leaseData && leaseData.lease_pdf);
    }
    useEffect(() => {
        setUserId(props.match.params.user_id);
        setLeaseId(props.match.params.lease_id);
    }, [props.match.params.user_id, props.match.params.lease_id]);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title={`Lease for ${leaseData && leaseData.unit_no}, ${leaseData && leaseData.address} `}
            subTitle={'Download The Lease'}
            extraClasses={'lease-detail-view'}
        >
            <div className="deactive-reasons sidepanel-body m-3">
                <Row>
                    <Col md={12} className='lease-duration-block'>
                        <div className='d-flex justify-content-between'>
                            <span className='lease-type'>Property Lease</span>
                            <span className='lease-type-detail'>{leaseData && leaseData.lease_type}</span>
                        </div>
                        {leaseData && <h4>
                            {(leaseData.lease_start_date && leaseData.lease_end_date) && <>
                                {dateFormat2(leaseData.lease_start_date)} - {dateFormat2(leaseData.lease_end_date)}
                            </>}
                        </h4>}
                    </Col>
                </Row>
                {leaseData && leaseData.lease_pdf &&
                    <Row className='mt-4'>
                        <Col md={12} className='lease-pdf-block'>
                            <Row>
                                <Col md={2}>
                                    <Image
                                        src={require('../../../../_assets/images/pdf-icon.svg')}
                                        alt='pdf-icon'
                                        width={40}
                                        height={48}
                                    />
                                </Col>

                                <Col md={10}>
                                    <p>{leaseData.lease_pdf.split('/').pop()}</p>
                                    <Button onClick={() => download()} type="button" className="skip-btn text-left m-0" style={{ textDecoration: 'none' }}>
                                        <Image
                                            src={require('../../../../_assets/images/download.svg')}
                                            alt='download-icon'
                                            width={11}
                                            height={12}
                                        /> Download
                                </Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                }
            </div>
            <div className="fixed-bottom ml-4 mr-4" style={{ boxShadow: 'none' }}>
                <LargeButton title='view lease details' onClick={() => viewLeaseDetails()} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(ListLeaseViewDetails);