import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { getAPI } from "../../../Api";
import { logger, timeLeft } from "../../../Utils";
import Cookies from "js-cookie";
import "./style.scss";
import { Loader } from "..";
import { setOverviewDetails } from "../../../Store/actions/MaintenanceRepairAction";
import { useDispatch } from "react-redux";
import LeaseFooter from "./LeaseFooter";

const log = logger("RentOverview");
const RentOverview = (props) => {
  log.info("props", props);
  const { viewDownloadPdf, rentDetails,uploadPDF } = props;
  const role = Number(Cookies.get("role"));
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
   const daysLeft = rentDetails && moment(rentDetails.lease_end_date).diff(moment(new Date()).format('YYYY-MM-DD'), 'days') + 1;
  

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
   }, [props.match.params]);

  const redirectToMessage = () => {
    props.history.push({
      pathname: `/property-details/${property_id}/${property_details_id}/messages`,
      state: { backPath: props?.history?.location?.pathname }
     });
  }

  return (
    <>
      <div className="overview">
        {rentDetails ?
          <>
            <Row>
              <Col md={6} className="mb-2">
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <span className="rent-card-title">Rent</span>
                   </div>
                  <div className="rent-amount">
                    <span>${rentDetails.rent}</span>
                  </div>
                 
                </div>
              </Col>
              <Col md={6} className="mb-2">
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <span className="rent-card-title">Lease</span>
                    {<span className="rent-card-title">{timeLeft(daysLeft)}</span>}
                  </div>
                  <div className="rent-amount">
                    <span>{moment(rentDetails.lease_start_date).format('MMM DD, YYYY')} 
                    {' to ' + moment(rentDetails.lease_end_date).format('MMM DD, YYYY')}</span>
                  </div>
                  <LeaseFooter 
                   role={role} 
                   rentDetails={rentDetails} 
                   viewDownloadPdf={viewDownloadPdf}
                   uploadPDF={uploadPDF
                  } />
                </div>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col md={6}>
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    {role === 2 ?
                      <>
                        <span className="rent-card-title">Tenants</span>
                        <span className="mates-message" onClick={() => redirectToMessage()}>
                          <img src={require('../../../_assets/images/msg-icon.svg')} alt="" />message tenants
                  </span>
                      </>
                      : <span className="rent-card-title">Roommates</span>}
                  </div>
                  {rentDetails.tenants && rentDetails.tenants.map((tenant, i) => {
                    return <div className="roommates d-flex justify-content-between" key={'tenant' + i}>
                      <span className="mates-name">{tenant.name}</span>
                      <span className="mates-email">{tenant.email}</span>
                    </div>
                  })}
                </div>
              </Col>

              {role === 3 &&
                <Col md={6}>
                  <div className="rent-card d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <span className="rent-card-title">landlord</span>
                    </div>
                    <div className="roommates d-flex justify-content-between">
                      <span className="mates-name">{rentDetails.landlord_name}</span>
                      <span className="mates-message" onClick={() => redirectToMessage()}>
                        <img src={require('../../../_assets/images/msg-icon.svg')} alt="" />message
                </span>
                    </div>
                  </div>
                </Col>}
            </Row>
          </> : <Loader />}
      </div></>
  );
};
export default withRouter(RentOverview);
