import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Image, Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Carousel from "react-multi-carousel";
import { Loader } from "../../App/components";
import "./style.scss";
const placeHolderImg = require("../../_assets/images/placeholder.jpg");

const FormsList = (props) => {
  const {
    formsList,
    colCount,
    showLoader,
    responsive,
    isDesktop,
    numberOfCards,
    setPdfModal,
    setPdfUrl,
    userType
  } = props;

  const handleFormClick = (form_url) => {
    setPdfUrl(form_url);
    setPdfModal(true);
  }

  const formsBox = () => {
    return formsList.length > 0 ? (
      formsList.slice(0, 3).map((form, i) => {
        return (
          <Col key={"article" + i} md={colCount} className="grid mb-4">
            <div className="resources-box">
              <div className="resources-box-image">
                {/* <Image className="grid-img" src={form.thumbnail_url ? form.thumbnail_url : placeHolderImg} alt="grid-img" /> */}
                <LazyLoadImage
                  effect="blur"
                  className="grid-img"
                  src={form.thumbnail_url ? form.thumbnail_url : placeHolderImg}
                  alt="grid-img"
                />
              </div>
              <h4 className="grid-name">{form.title}</h4>
              <p class="grid-content">
                {form.description.replace(/<[^>]*>?/gm, "")}
              </p>

              <div className="mt-auto">
                {form.form_url && (
                  <Button
                    variant="link"
                    className="date-text"
                    onClick={() => handleFormClick(form.form_url)}
                  >
                    Read more
                    <Image
                      className="pl-2 next"
                      src={require("../../_assets/images/arrow-forward.svg")}
                      alt="View More"
                    />
                    {showLoader && (
                      <i className="fa fa-spinner fa-spin ml-2"></i>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        );
      })
    ) : showLoader ? (
      <Loader />
    ) : (
      <h2>No Data Found</h2>
    );
  };

  return (
    <section>
      <Container>
        <div className="section-head d-flex justify-content-between align-items-end">
          <div>
            <h2 className="subHead">Common Forms &amp; Info</h2>
          </div>
          <div>
            <Link to={`/resources/landlord/forms?for=${userType}`} className="btn btn-link" >View More &#62; </Link>
          </div>
        </div>
        <Row>
          {formsList && formsList.length >= numberOfCards && (
            <Col className="text-right viewMore col-auto">
              <Nav.Link className="date-text" href={`/resources/forms`}>
                View More
                <Image
                  className="pl-2 next"
                  src={require("../../_assets/images/arrow-forward.svg")}
                  alt="View More"
                />
              </Nav.Link>
            </Col>
          )}
        </Row>

        <Carousel
          responsive={responsive}
          infinite={isDesktop ? false : true}
          partialVisible={isDesktop ? false : true}
          removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
          swipeable={numberOfCards === 1}
          showDots={true}
          draggable={true}
          autoPlay={isDesktop ? false : true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          dotListClass="landlord-resources-dot-list"
          arrows={numberOfCards !== 1}
        >
          {formsList && formsBox()}
        </Carousel>
      </Container>
    </section>
  );
};

export default FormsList;
