
export const frequencyType = [
    { title: "Monthly", value: 1 }, { title: "Weekly", value: 2 }, { title: "Other", value: 3 }
]

export const personNeedEntry = [
    { title: "Landlord", value: 1 },
    { title: "Property Manager", value: 2 },
    { title: "Handyman", value: 3 },
    { title: "Tradesperson", value: 4 },
    { title: "Inspector", value: 5 }
]

export const frequencyTypeDisplay = (value) => {
    var title = "";
    frequencyType.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}


