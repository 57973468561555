import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import { Container, Row } from "reactstrap";
import "./style.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PageTitleHeader } from '../../App/components';

const AuthLayout = (props) => {
  const { title, children, className, image, style, history } = props;
  const [pageTitlebar, setPageTitlebar] = useState(false);
  const back = () => history.push('/login');

  useEffect(() => {
    if (title === 'Forgot Password?' || title === 'Verification' || title === 'Enter four Digit Code' || title === 'Change Password') {
      setPageTitlebar(true)
    } else {
      setPageTitlebar(false)
    }
  }, [title])

  return (
    <Layout newHeader={true}>
      {pageTitlebar && <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title='Forgot password?'
      />}
      <Container fluid={true} className={"authentication-page-wrap " + className}>
        <Row>
          <div className="authentication-page-section">
            {image && <img className="mr-3" width="67.08" height="69.99" src={require("../../_assets/images/" + image)} alt={image} />}            
            <h3 className="authentication-page-title mt-3 mb-2">{title}</h3>
            <div className="authentication-page-form mt-0" style={{ ...style }}>
              {children}
            </div>
          </div>
        </Row>
      </Container>
      <ToastContainer />
    </Layout>
  );
}

export default AuthLayout;