import React from 'react';
import { withRouter } from "react-router-dom";
import { Image, Col } from 'react-bootstrap';

const ToolsInfo = ({ icon, title, description }) => {
    return (
        <Col lg={6} className="mb-4">
            <div className="image-list-box">
                <div className="image-list-box">
                    <Image src={icon} alt="" />
                </div>
                <div className="image-list-box-content">
                    <h5 className="title">{title}</h5>
                    <div className="discription">{description}</div>
                </div>
            </div>
        </Col>
    )
}

export default withRouter(ToolsInfo);
