import React, { useState, useEffect } from "react";
import { Form, Image } from "react-bootstrap";
import { SidePanel, LargeButton, FileBrowse } from "../../../../components";
import { logger, toaster } from "../../../../../Utils";
import axios from "axios";
import { IMAGE_API_URL } from "../../../../../Constants/endPoints";
import Cookies from "js-cookie";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { postAPI } from "../../../../../Api";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
// import { reorder } from "./helper";

const log = logger("AdminImageEdit");

const AdminImageEdit = (props) => {
  const {
    isShow,
    handleClose,
    apiCallback,
    path,
    openImageSubmitModal,
  } = props;
  const unitDetails = useSelector(
    (state) => state.propertyDetailsReducer.unitDetails
  );
  const [property_id, setProperty_id] = useState("");
  const [property_details_id, setProperty_details_id] = useState("");
  const [imageUrlData, setImageUrlData] = useState([]);
  const [imagesURL, setImagesURL] = useState([]);
  const [videoUrlData, setVideoUrlData] = useState([]);
  const [errorClass, setErrorClass] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [imgErrorData, SetErrorData] = useState("");
  const [disableBtn, setBtnDisable] = useState(false);
  const [showImageLoading, setShowImageLoading] = useState(false);

  useEffect(() => {
    setVideoUrlData([...(unitDetails.videos || [])]);
    const images = unitDetails?.images || []
    const thumbnailImages = unitDetails?.thumbnail_images || []
    setImagesURL(images)
    if (unitDetails) {
      const  finalImage = []
      for(let i=0; i<images.length; i++){
        finalImage.push({
          url: images[i],
          thumbnail_url: thumbnailImages[i] ? thumbnailImages[i] : images[i]
        })
      }
      setImageUrlData(finalImage)
    }
  }, [unitDetails]);

  const removeImage = (index) => {
    imagesURL.splice(index, 1)
    imageUrlData.splice(index, 1);
    setImageUrlData((imageUrlData) => [...imageUrlData]);
  };

  const handleDrop = (file) => {

    const url = IMAGE_API_URL;
    const token = `Bearer ${Cookies.get("access_token")}`;
    const headers = {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    };
    setShowImageLoading(true);

    file.map((dataFile) => {
      let formdata = new FormData();
      formdata.append("image", dataFile);

      axios
        .post(url, formdata, {
          headers: headers,
        })
        .then((response) => {
          if (response.status === 200) {
            setImageUrlData((imageUrlData) => [
              ...imageUrlData,
              response.data.data,
            ]);
            setImagesURL((imagesURL) => [
              ...imagesURL,
              response.data.data.thumbnail_url
            ]);
          setErrorClass(false);
          setShowImageLoading(false);
          }
        })
        .catch((err) => {
          log.info("error code", err.response.data.message);
          if (err.response.status === 400) {
            SetErrorData(err.response.data.message);
            setShowImageLoading(false);
          }
        });
      return dataFile;
    });
  };

  const OnChangeInput = (event, index) => {
    if (event.target.name === "videoUrlData") {
      const videoUrl = [...videoUrlData];
      videoUrl[index] = event.target.value;
      setVideoUrlData(videoUrl);
    }
  };

  const VideoUrlFild = (action, index) => {
    if (action === "add") {
      setVideoUrlData((videoUrlData) => [...videoUrlData, ""]);
    } else if (action === "remove") {
      videoUrlData.splice(index, 1);
      setVideoUrlData((videoUrlData) => [...videoUrlData]);
    }
  };

  const onSubmit = (event, props) => {
    event.preventDefault();
	
    log.info(videoUrlData, imageUrlData);
    if (imageUrlData?.length === 0 && videoUrlData?.length === 0) {
      log.info(videoUrlData, imageUrlData);
      setErrorClass(true);
      setVideoError(true);
    } else {
      setBtnDisable(true);
      const url = "/admin/listing/update/step-5/upload-photos";
      const videoUrlDataFiltered = videoUrlData.filter((videoUrl) => {
        return videoUrl !== "";
      });

      const data = {
        property_id: property_id,
        property_details_id: property_details_id,
        images: imageUrlData,
        videos: videoUrlDataFiltered,
      };
      postAPI(url, data).then((response) => {
        setBtnDisable(false);
        if (response.status === 200) {
          if (path) {
            openImageSubmitModal(true);
          } else {
            toaster("success", "Data Saved Successfully!");
          }
          apiCallback();
          handleClose();
        } else if (response.response.status === 400) {
          log.info(response.response);
          toaster("error", response.response.data.message);
        } else if (response.response.status === 422) {
          toaster("error", response.response.data.message);
        }
      });
    }
  };

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
  }, [props.match.params.property_id, props.match.params.property_details_id]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source && result.destination) {
      const reorderImages=  reorder(imageUrlData, result.source.index, result.destination.index)
      const reorderImagesUrl=  reorder(imagesURL, result.source.index, result.destination.index)
      setImageUrlData(
        reorderImages
      );
      setImagesURL(
        reorderImagesUrl
      );
    }
  };

  return (
    <>
      <SidePanel isShow={isShow} isHide={handleClose} title="Edit Images">
        <h4 className="edit-main mt-3 pb-2">Upload or delete photos</h4>
        <form id="Form" onSubmit={onSubmit} autoComplete="off">
          <div className="sidepanel-body filter-tenant">
            {imgErrorData !== "" && (
              <p style={{ color: "red" }}>{imgErrorData}</p>
            )}
            <FileBrowse
              handleDrop={(e) => handleDrop(e)}
              percent={0}
              multiple={true}
              fileType={"image/jpeg, image/jpg, image/png"}
              disable={false}
              onDragEnd={onDragEnd}
              imageData={imagesURL}
              showImageLoading={showImageLoading}
              loaderStyle={{ height: "100%", width: "100%" }}
              removeImage={(e) => removeImage(e)}
              errorClass={errorClass === true ? "error-upload-box" : ""}
            />

            <hr />
            {videoError === true && (
              <p style={{ color: "red" }}>Video url required</p>
            )}
            {videoUrlData?.length > 0
              ? videoUrlData.map((videoUrl, index) => {
                  return (
                    <Form.Group key={"video_" + index}>
                      <Form.Label className="d-flex justify-content-between">
                        <span className="video-link-text">
                          Paste Your Video Link Here
                        </span>
                        <span>
                          <Image
                            style={{ cursor: "pointer" }}
                            width="20px"
                            height="20px"
                            src={require("../../../../../_assets/images/delete-icon.svg")}
                            onClick={() => VideoUrlFild("remove", index)}
                          />
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="url"
                        name="videoUrlData"
                        id={"videoUrlData" + index}
                        value={videoUrl}
                        placeholder="Paste video link here..."
                        onChange={(e) => OnChangeInput(e, index)}
                      />
                    </Form.Group>
                  );
                })
              : "Add Video Link"}

            <LargeButton
              title={
                videoUrlData?.length === 0
                  ? "add video link"
                  : "add another video link"
              }
              isIcon={true}
              variant="dark"
              onClick={() => VideoUrlFild("add")}
            />
          </div>
          <div className="fixed-bottom d-flex justify-content-end">
            <LargeButton
              saveLoading={disableBtn}
              title="save changes"
              type="submit"
              style={{ width: "200px" }}
            />
          </div>
        </form>
      </SidePanel>
    </>
  );
};
export default withRouter(AdminImageEdit);
