import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import {
  CostNumberFormat,
  DatePickerComponent,
  LargeButton,
  SingleOptionCheck,
} from "../..";
import { numberFormatSanitizerInt } from "../../../../Utils/NumberSanitizer/NumberSanitizer";
import { frequencyType } from "../../../../Constants/NoticeConstants";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { logger, toaster, dateFormat, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { N10Schema } from "./ValidationSchema/N10Schema";
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const log = logger("N10Form");
const N10Form = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    getParalegal,
    triggerReqTenant,
    onBack, 
    onNoticeDataUpdate
  } = props;
  const [date, setDate] = useState(null);
  const [rent, setRent] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [other, setOther] = useState("");
  const [do_capital_work, setDo_capital_work] = useState(false);
  const [provide_additional_services, setProvide_additional_services] = useState(false);
  const [description, setDescription] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [globalState,setGlobalState]=useGlobalState()
  const [errors,setErrors]=useState({})

  //Signature Data
  const [signData, setSignData] = useState(null);
  log.info("signData", signData);

  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])

  useEffect(()=>{
    console.log(do_capital_work, provide_additional_services)
    onNoticeDataUpdate({
      when_rent_increase: dateFormat(date?date:''),
      new_rent_rate: rent,
      frequency: frequency,
      frequency_text: other,
      do_capital_work: do_capital_work ? '1' : '0',
      provide_additional_services: provide_additional_services ? '1' : '0',
      additional_service_notes: description,
      signData,
      notice_type:10
    })
    setGlobalState({ onSubmit: onSubmit })
  },[
    tenant_id.length,property_id,
    date,
    phoneError,
    signData,
    rent,frequency,other,do_capital_work,provide_additional_services,description
   ])



  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if(!(do_capital_work || provide_additional_services))
    {
      setErrors({detailsWork:'Please select atleast one details of work'})
      document.getElementById('detailsWork').scrollIntoView()
      return 
    }


    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10) {
      setPhoneError(true);
      return 
    }
    if (
      validate(N10Schema) &&     
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
      && (do_capital_work || provide_additional_services)
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        notice_id: noticeData && noticeData.id,
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 10,
        when_rent_increase: dateFormat(date),
        new_rent_rate: rent,
        frequency: frequency,
        frequency_text: other,
        do_capital_work: do_capital_work ? "1" : "0",
        provide_additional_services: provide_additional_services ? "1" : "0",
        additional_service_notes: description,
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        agree_terms_and_conditions: 1,
        i_want_to: 15,
      };

      postAPI(url, data).then((response) => {
        if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    } else if (!do_capital_work || !provide_additional_services) {
      const divDetailsOfWork = document.getElementById('detailsOfWork');
      divDetailsOfWork.scrollIntoView({ behavior: 'smooth', block: 'start' });
      divDetailsOfWork.classList.add("highlightError");
      toaster("error", "Please provide details of work. if rent increase is more than the rent increase guidelines.");
      setTimeout(() => {
        divDetailsOfWork.classList.remove("highlightError");
      },1500)
    }
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, Date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    if (name === "frequency") {
      setFrequency(Value);
    }
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  useEffect(() => {
    if (noticeData ) {
      if( noticeData.when_rent_increase && noticeData.when_rent_increase!=='Invalid date')
      setDate(moment(noticeData.when_rent_increase).toDate());
      setRent(noticeData.new_rent_rate);
      setFrequency(noticeData.frequency || 1);
      setOther(noticeData.frequency_text);
      setDo_capital_work(noticeData.do_capital_work === '1' ? true : false);
      setProvide_additional_services(noticeData.provide_additional_services === '1' ? true : false);
      setDescription(noticeData.additional_service_notes);
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Agreement Increasing The Rent Above The Guideline (N10)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>When is the rent increasing?</Form.Label>
              <DatePickerComponent
                id="date"
                name="date"
                format={"dd/MM/yyyy"}
                value={date && date}
                handleDateChange={(date) => handleDateChange(date, setDate)}
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-end"
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>New rent rate will increase to</Form.Label>
              <CostNumberFormat
                name={"rent"}
                id={"rent"}
                classname="form-control"
                value={rent ? rent : ""}
                updateField={(e) =>
                  setRent(e.target.value)
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"frequency"}
                setParam={frequency}
                extra={
                  frequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="other"
                        id="other"
                        value={other}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="d-flex justify-content-between mb-3 mt-3" id='detailsWork'>
            <span className="form-title">
              Details of the work, new or additional service
              <br/>
              {!!errors['detailsWork'] && <span style={{color:'red',fontSize:14}}>{errors['detailsWork'] } </span> } 
            </span>
          </div>

          <Row>
            <Form.Group className="checkGroup" as={Col} md="12">
              <Form.Label>
                The law allows a landlord and tenant to agree to a rent increase
                that is more than the rent increase guideline if you as a
                landlord will: (Please indicate what you will provide)
              </Form.Label>
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"agree_1"}
                  name={"do_capital_work"}
                  checked={do_capital_work}
                  onChange={() => {
                    setErrors({})
                    setDo_capital_work(!do_capital_work);
                  }}
                  hidden
                />
                <label htmlFor={"agree_1"} className="checkmark"></label>
                <span className="checkbox-title">
                  As the landlord you have done or will do capital work
                </span>
              </div>
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"agree_2"}
                  name={"provide_additional_services"}
                  checked={provide_additional_services}
                  onChange={() => {
                    setErrors({})

                    setProvide_additional_services(
                      !provide_additional_services
                    );
                  }}
                  hidden
                />
                <label htmlFor={"agree_2"} className="checkmark"></label>
                <span className="checkbox-title">
                  As the landlord you have provided (or will provide) a new or
                  additional service.
                </span>
              </div>
            </Form.Group>
            <Form.Group as={Col} md="10">
              <Form.Label>
                Provide details of the work or new or additional service. Also
                include the date the work was (or will be) completed or the
                service provided.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="8"
                placeholder="Write here"
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            type={"lastName"}
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={10}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N10Form);
