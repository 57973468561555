import {
    STORE_ADDRESS,    
    STORE_LANDLORD,
    STORE_OVERVIEW_DETAILS
} from "../actions/Action";

export const initialState = {
    address: null,
    landlord: null,
    overviewDetails: null
};

export default function maintenanceRepairReducer(state = initialState, action) {
   
    const handlers = {
        [STORE_ADDRESS]: (state) => ({
            ...state,
            address: action.address,
        }),
        [STORE_LANDLORD]: (state) => ({
            ...state,
            landlord: action.landlord,
        }),
        [STORE_OVERVIEW_DETAILS]: (state) => ({
            ...state,
            overviewDetails: action.overviewDetails,
        }),
        
    };
    
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}