import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger } from '../../../../Utils';
import {
  AdminUserDetails,
  TenantRoomatesBlock,
  AppLayout,
  DollorNumberDisplay,
  ModalBox,
  PageTitleHeader,
  LargeButton,
  LandlordDetailBlock,
} from '../../../components';
import { dateFormat2 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaseData } from '../../../../Store/actions/Action';
import ListLeaseViewDetails from './ListLeaseViewDetails';
import TenantDetailBlock from '../../../components/TenantDetailBlock/TenantDetailBlock';
import { getLeaseDetails, userSuspend, userActivate } from './helper';

const log = logger('RootLeaseDetails');
const RootLeaseDetails = (props) => {
  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  const [user_id, setUserId] = useState('');
  const [path] = useState('');
  const [lease_id, setLeaseId] = useState('');
  const [user_type, setUserType] = useState('');
  const [userData, setUserData] = useState(null);
  const [leaseData, setLeaseData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [action, setAction] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [viewLease, setViewLease] = useState(false);
  const dispatch = useDispatch();
  const leasePath = useSelector(
    (state) => state.propertyDetailsReducer.leasePath
  );

  useEffect(() => {
    setUserId(props.match.params.user_id);
    setLeaseId(props.match.params.lease_id);
    setUserType(props.match.params.user_type);
  }, [
    props.match.params.user_id,
    props.match.params.lease_id,
    props.match.params.user_type,
  ]);

  useEffect(() => {
    if (user_id !== false && lease_id !== false) {
        getLease();
    }
  }, [user_id, lease_id, dispatch]);

  const getLease = async () => {
    if (user_id && lease_id) {
      const data = await getLeaseDetails(lease_id, user_id);
      setUserData(data.User);
      setLeaseData(data.Leases);
      dispatch(getLeaseData(data.Leases));
    }
  };

  const back = () => {
    if (leasePath === 'list-lease') {
      props.history.push({
        pathname: `/profile-detail/${user_type}/${user_id}/leases`,
      });
    } else if (leasePath === 'notification') {
      props.history.push({
        pathname: `/profile-detail/listings/${userData.user_type.toLowerCase()}/${
          userData.id
        }/${leaseData.property_id}/${leaseData.property_detail_id}`,
      });
    }
  };

  const toggleModal = (data, action) => {
    setOpenSwitchModal(!openSwitchModal);
    setAction(action);
    if (action === 'switch') {
      onClickSwitch(data);
      setButtonTitle('yes, switch profile');
    } else if (action === 'suspend' || action === 'active') {
      suspendUser(data);
      if (action === 'suspend') {
        setButtonTitle('yes, suspend');
      } else if (action === 'active') {
        setButtonTitle('yes, activate');
      }
    }
  };
  const onClickSwitch = (data) => {
    setProfile(data);
  };
  const suspendUser = (userData) => {
    setProfile(userData);
  };
  const goBack = async () => {
    if (action === 'suspend' || action === 'active') {
      if (action === 'suspend') {
        userSuspend(user_id).then((res) => {
          getLease();
          setOpenSwitchModal(false);
        });
      } else if (action === 'active') {
        userActivate(user_id).then((res) => {
          getLease();
          setOpenSwitchModal(false);
        });
      }
    }
  };
  const viewDetail = () => {
    setViewLease(true);
  };
  const toggleViewLease = () => {
    setViewLease(!viewLease);
  };

  return (
    <>
      <AppLayout>
        <div className="root-lease-details">
          <PageTitleHeader
            isArrow={true}
            onClick={() => back()}
            title={'LEASE DETAILS'}
          />
        </div>
        <div className="main-lease-container">
          <Row className="container-detail">
            <Col md="12">
              <div className="card-detail d-flex justify-container-between">
                <div className="detail-left">
                  {userData && (
                    <AdminUserDetails
                      userDetails={userData}
                      user_type={userData && userData.user_type}
                    />
                  )}
                </div>
                <div className="detail-right">
                  {userData && userData.is_active !== 'Suspended' && (
                    <LargeButton
                      onClick={() => toggleModal(userData, 'suspend')}
                      className="suspend-btn"
                      variant={'dark'}
                      title="suspend user"
                      style={{ width: '177px', float: 'right' }}
                    />
                  )}
                  {userData && userData.is_active !== 'Active' && (
                    <LargeButton
                      onClick={() => toggleModal(userData, 'active')}
                      className="suspend-btn"
                      variant={'dark'}
                      title="activate"
                      style={{ width: '177px', float: 'right' }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="container-detail">
            <Col md="6">
              <div className="card-detail">
                <h4 className="user-name text-left">PROPERTY RENT</h4>
                <span className="green-text">
                  <DollorNumberDisplay price={leaseData && leaseData.rent} />
                </span>
              </div>
            </Col>
            <Col md="6">
              <div className="card-detail">
                {leaseData &&
                  leaseData.status &&
                  leaseData.status === 'Signed' && (
                    <div
                      className="arrow-class cursorPointer"
                      onClick={() => viewDetail()}
                    >
                      <img
                        className="next-arrow"
                        src={require('../../../../_assets/images/next.svg')}
                        alt="next"
                      />
                    </div>
                  )}
                <h4 className="user-name text-left">PROPERTY LEASE</h4>
                {leaseData && (
                  <div className="d-flex justify-content-between">
                    {leaseData.lease_start_date && leaseData.lease_end_date && (
                      <span className="green-text">
                        {leaseData.lease_start_date &&
                          dateFormat2(leaseData.lease_start_date)}{' '}
                        to{' '}
                        {leaseData.lease_end_date &&
                          dateFormat2(leaseData.lease_end_date)}
                      </span>
                    )}
                    <span>
                      <Button
                        className="view-details p-0"
                        style={{ color: '#000' }}
                        type="button"
                        onClick={() => viewDetail()}
                      >
                        View Details
                      </Button>
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="container-detail">
            <Col md="6">
              <TenantRoomatesBlock leaseData={leaseData} />
            </Col>
            <Col md="6">
              <TenantDetailBlock
                heading={'PRINCIPAL TENANT'}
                leaseData={leaseData}
              />
            </Col>
            {userData && userData.user_type.toLowerCase() === 'tenant' && (
              <Col md="6">
                <LandlordDetailBlock leaseData={leaseData} />
              </Col>
            )}
          </Row>
        </div>
      </AppLayout>
      {viewLease === true && (
        <ListLeaseViewDetails
          isShow={viewLease}
          handleClose={() => toggleViewLease()}
          user_type={userData && userData.user_type}
          path={path}
        />
      )}
      <ModalBox
        show={openSwitchModal}
        onHide={toggleModal}
        size="sm"
        actionbuttontitle={buttonTitle}
        buttonaction={goBack}
        extraBtnStyle="extraBtnStyle"
      >
        {action === 'suspend' && (
          <p className="switching-details">
            Are you sure to suspend{' '}
            <span className="profile-name">{profile && profile.name}’s?</span>{' '}
            <br />
            They will be unable to apply to any properties within Rent Panda
          </p>
        )}
        {action === 'active' && (
          <p className="switching-details">
            Are you sure to activate{' '}
            <span className="profile-name">{profile && profile.name}’s?</span>{' '}
            <br />
            Their rented property will remain within Rent Panda but other
            listing will be activated automatically
          </p>
        )}
      </ModalBox>
    </>
  );
};

export default withRouter(RootLeaseDetails);
