import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {SingleOptionCheck, CostNumberFormat, DatePickerComponent, MultipleCheckOptions} from '../../../';
import { parkingType, rentDeposite, rentPaid, rentPaidType } from '../../../../../Constants/LeaseConstants';
import { numberFormatSanitizerInt } from '../../../../../Utils';

const DetailsAboutRent = props => {
    
    const {leaseInfo, onChangeHandler, OnSingleOptionCheck, setLeaseInfo, handleDate, setSaveEnable, onUpdateBaseRent  } = props;
    const [errorRentMethodClass, setErrorRentMethodClass] = useState(false);

    const OnMultipleOptionCheck = (event, current) => {
        const value = event.target.value;
        const updatedRentPayMethod = [...leaseInfo.rent_pay_method];
        const paymentMethodIndex = updatedRentPayMethod.findIndex(method => method === value);
        if(paymentMethodIndex >= 0){
            updatedRentPayMethod.splice(paymentMethodIndex, 1);
        }else{
            updatedRentPayMethod.push(value);
        }
        if(errorRentMethodClass && updatedRentPayMethod.length > 0) setErrorRentMethodClass(false);
        if(updatedRentPayMethod.length === 0) setErrorRentMethodClass(true);
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, rent_pay_method : updatedRentPayMethod}));
        setSaveEnable(true);
    }

    return (
    <>
        <h3 className='text-left lease-heading'>more details about the rent</h3>
            <Row>
                <Form.Group as={Col} md='5'>
                    <Form.Label>Rent is to be paid on the:</Form.Label>
                    <Form.Control
                        type='text'
                        name='rent_paid_on'
                        id='rent_paid_on'
                        value={leaseInfo.rent_paid_on}
                        placeholder='Type here'
                        onChange={(e) => onChangeHandler(e.target.value, 'rent_paid_on')}
                    />
                </Form.Group>

                <Form.Group as={Col} md='3'>
                    <Form.Label>Day of each:</Form.Label>
                    <SingleOptionCheck
                        mapData={rentPaidType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'rent_paid_on_day_of_each'} setParam={leaseInfo.rent_paid_on_day_of_each}
                    />
                </Form.Group>

                {leaseInfo.rent_paid_on_day_of_each === 2 &&

                    <Form.Group as={Col} md='3'>
                        <Form.Label>{"Other:"}</Form.Label>
                        <Form.Control
                            type='text'
                            name='rent_paid_on_day_of_each_other'
                            id='rent_paid_on_day_of_each_other'
                            value={leaseInfo.rent_paid_on_day_of_each_other}
                            placeholder='Example: Weekly'
                            onChange={(e) => onChangeHandler(e.target.value, 'rent_paid_on_day_of_each_other')}
                        />
                    </Form.Group>

                }
            </Row>

            <Row>
                <Form.Group as={Col} md='5'>
                    <Form.Label>Base rent for the rental property</Form.Label>
                    <CostNumberFormat
                        name={'rent'}
                        id={'rent'}
                        classname='form-control'
                        value={leaseInfo.rent ? leaseInfo.rent : ''}
                        updateField={(e) => onUpdateBaseRent(numberFormatSanitizerInt(e.target.value))}
                        pre='$'
                        placeholder={'Please indicate $ amount'}
                    // disabled={true}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='2'>
                    <Form.Label>Is parking available</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_parking_available'} setParam={leaseInfo.is_parking_available}
                    />
                </Form.Group>
                {leaseInfo.is_parking_available === 1 &&
                    <>
                        <Form.Group as={Col} md='3'>
                            <Form.Label>Parking Type</Form.Label>
                            <SingleOptionCheck
                                mapData={parkingType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'parking_type'} setParam={leaseInfo.parking_type}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='2'>
                            <Form.Label># of spots</Form.Label>
                            <Form.Control
                                type='text'
                                name='parking_spot'
                                id='parking_spot'
                                value={leaseInfo.parking_spot}
                                placeholder='Type here'
                                onChange={(e) => onChangeHandler(e.target.value, 'parking_spot')}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='3'>
                            <Form.Label>Price for Parking (per month)</Form.Label>
                            <CostNumberFormat
                                name={'parking_price'}
                                id={'parking_price'}
                                classname='form-control'
                                value={leaseInfo.parking_price}
                                updateField={(e) => onChangeHandler((numberFormatSanitizerInt(e.target.value)), 'parking_price')}
                                pre='$'
                                placeholder={'$$$'}
                                minValue={-1}
                                decimalScale={2}
                                decimalSeparator='.'
                                disabled={leaseInfo.parking_spot === 0}
                            />
                        </Form.Group>
                    </>
                }
            </Row>

            <Row>
                <Form.Group as={Col} md='5'>
                    <Form.Label>Rent is payable to:</Form.Label>
                    <Form.Control
                        type='text'
                        name='rent_payable_to'
                        id='rent_payable_to'
                        value={leaseInfo.rent_payable_to}
                        placeholder='Type here'
                        onChange={(e) => onChangeHandler(e.target.value, 'rent_payable_to')}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='8'>
                    <Form.Label>Rent will be paid using the following methods:</Form.Label>
                    <div id='errorRentMethodClass'>
                        <MultipleCheckOptions
                            mapData={rentPaid} OnMultipleOptionCheck={(event, current) => OnMultipleOptionCheck(event, current)} name={'rent_pay_method'} setParam={leaseInfo.rent_pay_method}
                        />
                        {errorRentMethodClass && <p style={{color : 'red'}}>Rent payment method is required</p>}
                    </div>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Is the first rental period a partial period?</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'first_rental_partial_period'} setParam={leaseInfo.first_rental_partial_period}
                    />
                </Form.Group>

                {leaseInfo.first_rental_partial_period === 1 &&
                    <Form.Group as={Col} md='9'>
                        <Form.Label>This partial covers the rental of the unit from:</Form.Label>
                        <Row>
                            <Col md='5'>
                                <DatePickerComponent
                                    name="partial_cover_start_date"
                                    id="partial_cover_start_date"
                                    value={leaseInfo.partial_cover_start_date}
                                    handleDateChange={(date) => handleDate(date, 'partial_cover_start_date', 'partial_period')}
                                    displayText={"Select Date"}
                                    minDate={new Date()}
                                />
                            </Col>
                            <span className='mt-2'>to</span>
                            <Col md='5'>
                                <DatePickerComponent
                                    name="partial_cover_end_date"
                                    id="partial_cover_end_date"
                                    value={leaseInfo.partial_cover_end_date}
                                    displayText={"Select Date"}
                                    disableValue={true}
                                    minDate={leaseInfo.partial_cover_start_date}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='5'>
                                <Form.Label className='mt-4'>The tenant will have to pay an amount of:</Form.Label>
                                <CostNumberFormat
                                    name={'partial_cover_amount'}
                                    id={'partial_cover_amount'}
                                    classname='form-control'
                                    value={leaseInfo.partial_cover_amount ? leaseInfo.partial_cover_amount : ''}
                                    updateField={(e) => onChangeHandler(numberFormatSanitizerInt(e.target.value), 'partial_cover_amount')}
                                    pre='$'
                                    placeholder='Type here'
                                    disabled={false}
                                    decimalScale={2}
                                    decimalSeparator='.'
                                />
                            </Col>
                            <span className='mt-6'>on</span>
                            <Col md='5'>
                                <Form.Label className='mt-4'>Date of partial payment</Form.Label>
                                <DatePickerComponent
                                    name="partial_payment_date"
                                    id="partial_payment_date"
                                    value={leaseInfo.partial_payment_date}
                                    displayText={"Select Date"}
                                    disableValue={true}
                                />
                            </Col>
                        </Row>
                    </Form.Group>}
            </Row>

            <Row>
                <Form.Group as={Col} md='6'>
                    <Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
                    <NumberFormat
                        thousandSeparator={true}
                        prefix='$'
                        allowNegative={false}
                        allowEmptyFormatting={false}
                        allowLeadingZeros={false}
                        value={leaseInfo.administrative_charge ? leaseInfo.administrative_charge : ''}
                        onChange={(e) => onChangeHandler(numberFormatSanitizerInt(e.target.value), 'administrative_charge')}
                        className='form-control'
                        placeholder={'$20'}
                        name={'administrative_charge'}
                        id={'administrative_charge'}
                        isAllowed={(value) => {
                            const { formattedValue, floatValue } = value;
                            return formattedValue === '' || (floatValue > 0 && floatValue <= 20);
                        }}
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='7'>
                    <Form.Label>Is smoking allowed? (If allowed, please ensure to include it to your lease appendix)</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_smoking_allowed'} setParam={leaseInfo.is_smoking_allowed}
                    />
                </Form.Group>
            </Row>
    </>
    )
}

export default DetailsAboutRent
