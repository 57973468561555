import React, { useState, useEffect } from 'react'

const Offline = (props) => {
    const [showContent, setShowContent] = useState(false)
    useEffect(() => {
        window.addEventListener('online', () => setShowContent(false));
        window.addEventListener('offline', () => setShowContent(true));
    })
    return <>{showContent && props.children}</>
}

export default Offline
