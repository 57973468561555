import {
    SET_PROPERTY_DETAIL,
    SET_UNIT_DETAIL,
    GET_PREVIOUS_PAGE,
    GET_CURRENT_PAGE,
    GET_LEASE_DATA,
    GET_ROLE_DATA,
    GET_INACTIVE_FILTER_DATA,
    GET_PAGINATION_LIST_MAN,
    GET_RENTED_PAGINATION,
    GET_INACTIVE_PAGINATION,
    GET_PROMOTED_PAGINATION,
    GET_NOTIFICATION_PAGINATION,
    GET_ACTIVELIST_STATUS,
    GET_INACTIVELIST_STATUS,
    GET_UPLOAD_MANUAL,
    GET_BACK_PATH,
    GET_LEASE_HEADING,
    GET_LEASE_DETAIL_PATH,
    GET_REQUEST_PAGE,
    GET_NOTICE_TYPE,
    SET_PAYMENT_SUMMARY
} from "../actions/Action";

export const initialState = {
    propertyDetails: null,
    unitDetails: null,
    currentPage: null,
    previousPage: null,
    leaseFetchedData: null,
    roleFetchedData: null,
    inactiveFilterFetch: null,
    previousPageListMan: null,
    previousPageRented: null,
    previousPagePromoted: null,
    previousPageInactive: null,
    previousPageNotification: null,
    activeListStatus: null,
    inactiveListStatus: null,
    manualData: null,
    backPath: null,
    leaseHeading: null,
    leasePath: null,
    requestPage: null,
    noticeType: null,
    getPaymentSummary: null
};

export default function propertyDetailsReducer(state = initialState, action) {
    const handlers = {
        [SET_PROPERTY_DETAIL]: (state) => ({
            ...state,
            propertyDetails: action.propertyDetails,
        }),
        [SET_UNIT_DETAIL]: (state) => ({
            ...state,
            unitDetails: action.unitDetails,
        }),
        [GET_CURRENT_PAGE]: (state) => ({
            ...state,
            currentPage: action.currentPage,
        }),
        [GET_PREVIOUS_PAGE]: (state) => ({
            ...state,
            previousPage: action.previousPage,
        }),
        [GET_LEASE_DATA]: (state) => ({
            ...state,
            leaseFetchedData: action.leaseFetchedData,
        }),
        [GET_ROLE_DATA]: (state) => ({
            ...state,
            roleFetchedData: action.roleFetchedData,
        }),
        [GET_INACTIVE_FILTER_DATA]: (state) => ({
            ...state,
            inactiveFilterFetch: action.inactiveFilterFetch,
        }),
        [GET_PAGINATION_LIST_MAN]: (state) => ({
            ...state,
            previousPageListMan: action.previousPageListMan,
        }),
        [GET_RENTED_PAGINATION]: (state) => ({
            ...state,
            previousPageRented: action.previousPageRented,
        }),
        [GET_INACTIVE_PAGINATION]: (state) => ({
            ...state,
            previousPageInactive: action.previousPageInactive,
        }),
        [GET_PROMOTED_PAGINATION]: (state) => ({
            ...state,
            previousPagePromoted: action.previousPagePromoted,
        }),
        [GET_NOTIFICATION_PAGINATION]: (state) => ({
            ...state,
            previousPageNotification: action.previousPageNotification,
        }),
        [GET_ACTIVELIST_STATUS]: (state) => ({
            ...state,
            activeListStatus: action.activeListStatus,
        }),
        [GET_INACTIVELIST_STATUS]: (state) => ({
            ...state,
            inactiveListStatus: action.inactiveListStatus,
        }),
        [GET_UPLOAD_MANUAL]: (state) => ({
            ...state,
            manualData: action.manualData,
        }),
        [GET_BACK_PATH]: (state) => ({
            ...state,
            backPath: action.backPath,
        }),
        [GET_LEASE_HEADING]: (state) => ({
            ...state,
            leaseHeading: action.leaseHeading,
        }),
        [GET_LEASE_DETAIL_PATH]: (state) => ({
            ...state,
            leasePath: action.leasePath,
        }),
        [GET_REQUEST_PAGE]: (state) => ({
            ...state,
            requestPage: action.requestPage,
        }),
        [GET_NOTICE_TYPE]: (state) => ({
            ...state,
            noticeType: action.noticeType,
        }),
        [SET_PAYMENT_SUMMARY]: (state) => ({
            ...state,
            getPaymentSummary: action.getPaymentSummary,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}