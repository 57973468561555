import logger from '../../Utils/logger';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_TABLEDATA = 'SET_TABLEDATA';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

const log = logger("Reuest Action");

export function setFilters(data) {
    return function (dispatch) {
        dispatch({ type: SET_FILTERS, filters: data });
    };
}

export function setTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_TABLEDATA, tableData: data });
    };
}

export function setRequestCurrentPage(data) {
    log.info("page", data);
    return function (dispatch) {
        dispatch({ type: SET_CURRENT_PAGE, currentPage: data });
    };
}