import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { Col, Row, Button } from 'react-bootstrap'
import { dateSanitizer } from '../../../Utils/DateSanitizer/DateSanitizer'
import { DatePickerComponent, SidePanel } from '../../components'
import Cookies from 'js-cookie';
import { toaster } from '../../../Utils'
import { setRelistingProperty } from './helper'
import { useSelector } from 'react-redux'

function RelistForm(props) {
    const { isShow, handleClose, propertyId,leaseData, propertyDetailsId, setShowRelist } = props
    const role = Number(Cookies.get('role'));
    const [nextAvailableDate, setNextAvailableDate] = useState(null)
    const [saveLoading, setSaveLoading] = useState(false)
    // const leaseData = useSelector(state => state.rawData.rentDetails)
    useEffect(() => {
        const newDate = moment(leaseData && leaseData?.lease_end_date).add(1, 'days').toDate()
        console.log(newDate)
        setNextAvailableDate(newDate)
    },[leaseData])

    const handleDateChange = (date, setStart_date) => {
        const dateValid = dateSanitizer(date);
        setNextAvailableDate(dateValid);
    };

    const relistHandler = async() => {
        setSaveLoading(true)
        const {status, message} = await setRelistingProperty(propertyId, propertyDetailsId, nextAvailableDate )
        if(status === 'success'){
            setShowRelist(false)
            toaster(status, message)
            handleClose()
        }else if(status === 'error'){
            console.log(message)
            for(const data in message){
                // console.log(message[data])
                toaster(status, message[data][0])
            }
        }
        setSaveLoading(false)
    }

    return (
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Relist Property"
        >
            <div className="sidepanel-body">
                {leaseData &&
                    <> 
                        <div className="lease-details-card mt-4">
                            <div className="d-flex justify-content-between">
                                <span className="rent-card-title bold">Current Lease End Date</span>
                            </div>
                            <div className="rent-amount">
                                <span>{moment(leaseData.lease_end_date).format('MMMM DD, YYYY')}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="rent-card-title bold">Please select the next available date</span>
                            </div>
                            <div className="rent-amount">
                                <span>
                                    <DatePickerComponent
                                        id='current_form_start_date_2'
                                        name='current_form_start_date'
                                        format={'dd/MM/yyyy'}
                                        extraClass={'calender-icon-none'}
                                        value={nextAvailableDate}
                                        handleDateChange={(date) => handleDateChange(date, setNextAvailableDate)}
                                        minDate={moment(leaseData.lease_end_date).add(1,'days').toDate()}
                                        displayText={'Select Date'}
                                        popperPlacement='bottom-start'
                                    />
                                </span>
                            </div>
                        </div>
                    </>          
                }            
            </div>
            <div className={role !== 1 ? 'fixed-bottom d-flex' : 'fixed-bottom'}>
                <Row>
                    <Col md={12}>
                        <Button style={{ width: '200px', float: 'right', margin: '20px 0px' }} onClick={() => relistHandler()} disabled={saveLoading === false ? false : true} variant='success' type='submit' >Save {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                    </Col>
                </Row>
            </div>
        </SidePanel>
    )
}

export default RelistForm
