import {
    SET_COUPON_COMPONENT,
    SET_COUPON_FILTERS,
    SET_ACTIVE_COUPON_CURRENT_PAGE,
    SET_INACTIVE_COUPON_CURRENT_PAGE,
    SET_ACTIVE_COUPON_TABLEDATA,
    SET_INACTIVE_COUPON_TABLEDATA,
} from '../actions/Action';

export const initialState = {
    currentComponent: '',
    activeCouponCurrentPage: null,
    inActiveCouponCurrentPage: null,
    activeCouponTableData: [],
    inActiveCouponTableData: [],
    filters: {
        coupon_name: '',
        created_date: 'desc',
        no_of_usage: '',
        start_date: '',
        expiry_date: '',
        coupon_code: '',
        coupon_value: '',
    }
}

export default function CouponReducer(state = initialState, action) {
    const handlers = {
        [SET_COUPON_COMPONENT]: (state) => ({
            ...state,
            currentComponent: action.currentComponent,
        }),
        [SET_COUPON_FILTERS]: (state) => ({
            ...state,
            filters: action.filters,
        }),
        [SET_ACTIVE_COUPON_CURRENT_PAGE]: (state) => ({
            ...state,
            activeCouponCurrentPage: action.activeCouponCurrentPage,
        }),
        [SET_INACTIVE_COUPON_CURRENT_PAGE]: (state) => ({
            ...state,
            inActiveCouponCurrentPage: action.inActiveCouponCurrentPage,
        }),
        [SET_ACTIVE_COUPON_TABLEDATA]: (state) => ({
            ...state,
            activeCouponTableData: action.activeCouponTableData,
        }),
        [SET_INACTIVE_COUPON_TABLEDATA]: (state) => ({
            ...state,
            inActiveCouponTableData: action.inActiveCouponTableData,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}