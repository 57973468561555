import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, Form, Image, Button } from 'react-bootstrap';
import { phoneNumberSanitizer } from '../../Utils/NumberSanitizer/NumberSanitizer';
import { Loader, ModalBox } from '../../App/components';
import { logger, validate } from '../../Utils';
import { postAPI } from '../../Api';
import { ForPropertyManagersSchema } from './ForPropertyManagersSchema';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

import './style.scss';

const toolsData = [
	{
		id: 1,
		icon: require("../../_assets/images/icons/property-managers-1.svg"),
		title: "post unlimited rentals",
		description: "we don't limit the amount of listings you can post, meaning all your properties can be listed at once",
	}, {
		id: 2,
		icon: require("../../_assets/images/icons/property-managers-2.svg"),
		title: "share your listing",
		description: "with one click (and a bit of cash) get your properties on Facebook, Kijiji, and to the top of the pack",
	}, {
		id: 3,
		icon: require("../../_assets/images/icons/property-managers-3.svg"),
		title: "review tenant profiles",
		description: "review tenant profiles before booking a showing to save you time",
	}, {
		id: 4,
		icon: require("../../_assets/images/icons/property-managers-4.svg"),
		title: "track your showings",
		description: "schedule and track all your listings all on one calendar",
	}, {
		id: 5,
		icon: require("../../_assets/images/icons/property-managers-5.svg"),
		title: "instant credit checks",
		description: "with a click of a button you get instant background and credit checks",
	}, {
		id: 6,
		icon: require("../../_assets/images/icons/property-managers-6.svg"),
		title: "approve tenants instantly",
		description: "manage tenant applications easily and get them approved faster",
	}, {
		id: 7,
		icon: require("../../_assets/images/icons/property-managers-7.svg"),
		title: "build and sign leases",
		description: "build and sign leases all in one spot to easily manage multiple properties and tenants",
	}, {
		id: 8,
		icon: require("../../_assets/images/icons/property-managers-8.svg"),
		title: "move in/move out tracking",
		description: "use the move in/move out tracking tool to track the state of your place at all times",
	}, {
		id: 9,
		icon: require("../../_assets/images/icons/property-managers-9.svg"),
		title: "get repair requests",
		description: "get repair requests and let tenants know about maintenance happening",
	}, {
		id: 10,
		icon: require("../../_assets/images/icons/property-managers-10.svg"),
		title: "serve notices easily",
		description: "serve all your notices and warnings easily",
	}, {
		id: 11,
		icon: require("../../_assets/images/icons/property-managers-11.svg"),
		title: "repost your listing quickly",
		description: "when tenants give notice, repost your ads with a single click",
	},
];

const log = logger("ForPropertyManagers");
const ForPropertyManagers = (props) => {
	log.info("props", props);
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone_number, setPhoneNumber] = useState('');
	const [saveLoading, setSaveLoading] = useState(false);
	const [formSubmitPop, setFormSubmitPop] = useState(false);

	const onSubmit = (e) => {
        e.preventDefault();
        if (validate(ForPropertyManagersSchema)) {
			const url = "/cms/save-property-managers";
            const data = {
                "first_name": first_name,
                "last_name": last_name,
                "email": email,
                "phone_number": phone_number,
            }
            setSaveLoading(true);
            postAPI(url, data).then(response => {
                setSaveLoading(false);
                setFormSubmitPop(true);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
            })
        }
    }

	return (
		<div className="forPropertyManagers">
			<Helmet>
				<title>For Property Managers | Rent Panda</title>
				<meta name="description" content="Pandas eat 26-48 pounds of bamboo a day. 
				They're busy and so are you. Rent Panda has ways to make your search for a tenant quick and easy. " />
			</Helmet>
			<Layout newHeader={true}>
				<section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda can save you time managing your portfolio</h1>
                    </div>
                </section>
				<section className="image-list-container cu-container-wrap">
					<Container>
						{toolsData &&
							(toolsData.length > 0) ?
							(<Row>
								{toolsData.map(data => {
									return <Col lg={6} className="mb-4">
											<div className="image-list-box">
												<div className="image-list-box-image">
													<Image src={data.icon} alt="" />
												</div>
												<div className="image-list-box-content">
													<h5 className="title">{data.title}</h5>
													<div className="discription">{data.description}</div>
												</div>
											</div>
										</Col>
								})}
							</Row>)
							: <Loader />
						}
					</Container>
				</section>
				<section className="actions-box cu-container-wrap">
					<Container>
						<Row>
							<Col md={4}>
								<Button href="/signup/landlord" className='btnAction'>post a listing now</Button>
							</Col>
						</Row>
					</Container>

					<Container className="form-container">
						<h2 className="subHead">need help uploading your entire portfolio?</h2>
						<Col md={12} lg={8} xl={7}>
							<Card>
								<h2 className="formTitle">Your Info</h2>
								<Form id="Form" autoComplete="off">
									<Row>
										<Col md={6} className="mb-3">
											<Form.Control
												type='text'
												placeholder="First Name"
												name={'first_name'}
												id={'first_name'}
												value={first_name}
												onChange={(e) => setFirstName(e.target.value)}
											/>
										</Col>
										<Col md={6} className="mb-3">
											<Form.Control
												type='text'
												placeholder="Last Name"
												name={'last_name'}
												id={'last_name'}
												value={last_name}
												onChange={(e) => setLastName(e.target.value)}
											/>
										</Col>
									</Row>
									<Row>
										<Col md={8} className="mb-3">
											<Form.Control
												type='text'
												placeholder="Email"
												name='email'
												id='email'
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</Col>
										<Col md={4} className="mb-3">
											<NumberFormat
												placeholder="Phone Number"
												format='###-###-####'
												mask='_'
												name='phone_number'
												id='phone_number'
												className={'form-control'}
												value={phone_number ? phone_number : ''}
												onChange={(e) => setPhoneNumber(phoneNumberSanitizer(e.target.value))}
											/>
										</Col>
									</Row>

									<Button className='btnFormAction' type='submit'
										onClick={(e) => onSubmit(e)} disabled={saveLoading === true ? true : false}>
										get in touch
										{saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
									</Button>
								</Form>
							</Card>
						</Col>
					</Container>
				</section>
			</Layout>
			<ModalBox
                show={formSubmitPop}
                onHide={() => setFormSubmitPop(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => setFormSubmitPop(false)}
            >
                <h4>Your request has been submitted successfully!</h4>
            </ModalBox>
		</div >
	)
}

export default withRouter(ForPropertyManagers);
