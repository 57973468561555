import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton, DatePickerComponent, CostNumberFormat } from '../../../components'
import { Form, Row, Col, Image,DropdownButton ,Dropdown} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { employment_statusData } from '../../../../Constants/tenantDetails'
import { logger, toaster } from '../../../../Utils'
import { validate } from '../../../../Utils/Validation/validation';
import { EmpInfoValidationSchema } from './ValidationSchema/EmpInfoValidationSchema';
import { EmpInfoPastValidationScheme } from './ValidationSchema/EmpInfoPastValidationScheme';
import { SchoolDataValidation } from './ValidationSchema/SchoolDataValidation';
import { SocialAssistanceValidation } from './ValidationSchema/SocialAssistanceValidation';
import { EmpInfoValidationPartTime } from './ValidationSchema/EmpInfoValidationPartTime';
import { postAPI } from '../../../../Api';
import { dateSanitizer } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { numberFormatSanitizerFloat, phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import NumberFormat from 'react-number-format';
import { Numbering } from '../../../../Utils/Numbering/Numbering';
import {salaryOptions} from '../../Sidebar/constant'
import Cookies from 'js-cookie';
import './style.scss';
import { updateProfile } from './helper';

const log = logger('EmploymentInfo');
const EmploymentInfo = (props) => {
  const { employment_info, isShow, handleClose, setPersonalReferencesInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const today_date = moment(new Date()).format('YYYY-MM-DD');
  const [statusError, setStatusError] = useState(false)
  const [isSubmitClicked,setIsSubmitClicked]=useState(false)
  const [empStatus, setEmpStatus] = useState(0)
  // Employement status = School
  // const [schoolName, setSchoolName] = useState('')
  // const [programName, setProgramName] = useState('')
  // const [schoolStartDate, setSchoolStartDate] = useState('')
  // const [schoolEndName, setSchoolEndName] = useState('')
  // Employement status = Socail Assiatance
  // const [monthlyIncome, setMonthlyIncome] = ('')

  const[schoolData, setSchoolData] = useState([
    {
      id: Math.random(),
      is_current: 1,
      employment_status: 3,
      school_attend: null,
      program_taking: null,
      start_date: today_date,
      end_date: today_date,
    }
  ])


  const[socialAssistance, setSocialAssistance] = useState([
    {
      id: Math.random(),
      is_current: 1,
      min_salary: null,
      employment_status: 4,
    }
  ])

  const[retired, setRetired] = useState([
    {
      id: Math.random(),
      is_current: 1,
      min_salary: null,
      employment_status: 5,
    }
  ])

  const [current_employment, setCurrent_employment] = useState([
    {
      id: Math.random(),
      is_current: 1,
      employment_status_current_emp: 0,
      min_salary_current_emp: '',
      max_salary_current_emp: '',
      job_title_current_emp: '',
      start_date_current_emp: today_date,
      employment_reference_name_current_emp: '',
      employment_reference_phone_current_emp: '',
      type: 'current'
    }
  ]);

  const [current_employment_part_time, setCurrent_employment_part_time] = useState([
    {
      id: Math.random(),
      is_current: 1,
      employment_status_current_emp_part_time: 2,
      min_salary_current_emp_part_time: '',
      max_salary_current_emp_part_time: '',
      job_title_current_emp_part_time: '',
      start_date_current_emp_part_time: today_date,
      employment_reference_name_current_emp_part_time: '',
      employment_reference_phone_current_emp_part_time: '',
      type: 'current'
    }
  ])

  const [past_employment, setPast_employment] = useState([
    {
      id: Math.random(),
      is_current: 0,
      employment_status_past_emp: 0,
      min_salary_past_emp: '',
      max_salary_past_emp: '',
      job_title_past_emp: '',
      start_date_past_emp: today_date,
      end_date_past_emp: today_date,
      employment_reference_name_past_emp: '',
      employment_reference_phone_past_emp: '',
      type: 'past'
    }
  ]);

  useEffect(() => {
    if (employment_info) {
      const current_employment = []
      const past_employment = []
      employment_info.map((data, i) => {
        if(data.is_current === 1){
          setEmpStatus(data.employment_status)
        }
        let renew = null
        const salary = [];
        salaryOptions.forEach((record) => {
          if (record.value === data.salary_range) {
            salary.push(record.display);
          }
        });
        if (data.is_current === 1 && data.employment_status === 1) {
          renew = {
            id: data.id,
            is_current: data.is_current,
            employment_status_current_emp: data.employment_status,
            min_salary_current_emp:salary[0],
            max_salary_current_emp: data.max_salary,
            job_title_current_emp: data.job_title,
            start_date_current_emp: data.start_date,
            employment_reference_name_current_emp: data.employment_reference_name,
            employment_reference_phone_current_emp: data.employment_reference_phone
          }
          current_employment.push(renew)
          data.employment_status === 1 ? setCurrent_employment(current_employment) : setCurrent_employment_part_time(current_employment)
        }else if (data.is_current === 1 && data.employment_status === 2) {
          renew = {
            id: data.id,
            is_current: data.is_current,
            employment_status_current_emp_part_time: data.employment_status,
            min_salary_current_emp_part_time: salary[0],
            max_salary_current_emp_part_time: data.max_salary,
            job_title_current_emp_part_time: data.job_title,
            start_date_current_emp_part_time: data.start_date,
            employment_reference_name_current_emp_part_time: data.employment_reference_name,
            employment_reference_phone_current_emp_part_time: data.employment_reference_phone
          }
          current_employment.push(renew)
          setCurrent_employment_part_time(current_employment)
        }else if(data.is_current === 1 && (data.employment_status === 3)){
          renew = {
            id: data.id,
            is_current: data.is_current,
            employment_status: data.employment_status,
            school_attend: data.school_attend,
            program_taking: data.program_taking,
            start_date: data.start_date,
            end_date: data.end_date,
          }
          current_employment.push(renew)
          setSchoolData(current_employment)
        }else if(data.is_current === 1 && (data.employment_status === 4)){
          renew = {
            id: data.id,
            is_current: data.is_current,
            min_salary: data.min_salary,
            employment_status: data.employment_status,
          }
          current_employment.push(renew)
          setSocialAssistance(current_employment)
        }else if(data.is_current === 1 && (data.employment_status === 5)){
          renew = {
            id: data.id,
            is_current: data.is_current,
            min_salary: data.min_salary,
            employment_status: data.employment_status,
          }
          current_employment.push(renew)
          setRetired(current_employment)
        }
        else {
          renew = {
            id: data.id,
            is_current: data.is_current,
            employment_status_past_emp: data.employment_status,
            min_salary_past_emp: data.min_salary,
            max_salary_past_emp: data.max_salary,
            job_title_past_emp: data.job_title,
            start_date_past_emp: data.start_date,
            end_date_past_emp: data.end_date ? data.end_date : today_date,
            employment_reference_name_past_emp: data.employment_reference_name,
            employment_reference_phone_past_emp: data.employment_reference_phone
          }
          past_employment.push(renew)
          setPast_employment(past_employment)
        }
        return data;
      })
    }
    setIsSubmitClicked(false)
  }, [employment_info])

  const addMore = (status) => {
    const dataCurrent = {
      id: Math.random(),
      is_current: 1,
      employment_status_current_emp: 0,
      min_salary_current_emp: '',
      max_salary_current_emp: '',
      job_title_current_emp: '',
      start_date_current_emp: today_date,
      employment_reference_name_current_emp: '',
      employment_reference_phone_current_emp: ''
    }
    const dataPast = {
      id: Math.random(),
      is_current: 0,
      employment_status_past_emp: 0,
      min_salary_past_emp: '',
      max_salary_past_emp: '',
      job_title_past_emp: '',
      start_date_past_emp: today_date,
      end_date_past_emp: today_date,
      employment_reference_name_past_emp: '',
      employment_reference_phone_past_emp: ''
    }
    if (status === 'current') {
      setCurrent_employment(current_employment => [...current_employment, dataCurrent])
    } else {
      setPast_employment(past_employment => [...past_employment, dataPast])
    }
  }

  const OnChangeInputArray = (event, index, type) => {
    const name = event.target.name ? event.target.name : event.target.getAttribute('name')
    if (type === 'current') {
      if(empStatus === 1){
        let items = empStatus ===1 ? [...current_employment] : [...current_employment_part_time] ;
        let item = { ...items[index] };
        // if (name === 'min_salary_current_emp' || name === 'max_salary_current_emp') {
        //   item[name] = numberFormatSanitizerFloat(event.target.value);
        // } else 
        if (name === 'employment_reference_phone_current_emp') {
          item[name] = phoneNumberSanitizer(event.target.value);
          log.info(phoneNumberSanitizer(event.target.value));
        } else {
          item[name] = event.target.value;
        }
        items[index] = item;
        setCurrent_employment(items)
      }else if(empStatus === 2){
        let items = [...current_employment_part_time] ;
        let item = { ...items[index] };
        // if (name === 'min_salary_current_emp_part_time' || name === 'max_salary_current_emp_part_time') {
        //   item[name] = numberFormatSanitizerFloat(event.target.value);
        //   log.info(numberFormatSanitizerFloat(event.target.value));
        // } else 
        if (name === 'employment_reference_phone_current_emp_part_time') {
          item[name] = phoneNumberSanitizer(event.target.value);
          log.info(phoneNumberSanitizer(event.target.value));
        } else {
          item[name] = event.target.value;
        }
        items[index] = item;
        setCurrent_employment_part_time(items)
      }else if(empStatus === 3){
        let items = [...schoolData]
        let item = {...items[index]}
        item[name] = event.target.value
        items[index] = item
        setSchoolData(items)
      }else if(empStatus === 4){
        let items = [...socialAssistance]
        let item = {...items[index]}
        item[name] = event.target.value
        if (name === 'min_salary') {
          item[name] = numberFormatSanitizerFloat(event.target.value);
        }else{
          item[name] = event.target.value
        }
        items[index] = item
        setSocialAssistance(items)
      }else if(empStatus === 5){
        let items = [...retired]
        let item = {...items[index]}
        item[name] = event.target.value
        if (name === 'min_salary') {
          item[name] = numberFormatSanitizerFloat(event.target.value);
        }else{
          item[name] = event.target.value
        }
        items[index] = item
        setRetired(items)
      }
    }else {
      let items = [...past_employment];
      let item = { ...items[index] };
      if (name === 'employment_reference_phone_past_emp') {
        item[name] = phoneNumberSanitizer(event.target.value);
        log.info(phoneNumberSanitizer(event.target.value));
      } else {
        item[name] = event.target.value;
      }
      items[index] = item;
      setPast_employment(items)
    }
    isSubmitClicked && setTimeout(()=>{
      onSubmit(false)
    },300)
  }

  const OnChangeDate = (name, date, index, type, emp_status, state) => {
    const dateValid = dateSanitizer(date);
    let items = type === 'current' && emp_status === 1 ? [...current_employment] : emp_status === 2 ? [...current_employment_part_time] : emp_status === 3 ? [...schoolData] : [...past_employment]
    let item = { ...items[index] };
    item[name] = dateValid;
    items[index] = item;
    state(items)
  }

  const OnRemove = (id, type) => {
    if (type === 'current') {
      let items = [...current_employment].filter((data, i) => {
        return data.id !== id
      });
      // items.map((item, index) => {
      //   if (item.id === id) {
      //     items.splice(index, 1);
      //   }
      //   return item;
      // })
      setCurrent_employment(items)
    } else {
      let items = [...past_employment].filter((data, i) => {
        return data.id !== id
      });
      // items.map((item, index) => {
      //   if (item.id === id) {
      //     items.splice(index, 1);
      //   }
      //   return item;
      // })
      setPast_employment(items)
    }
  }

  const onSubmit = (isSubmit=true) => {
    let pastJob = false
    let submit = false
    let status = false
    setIsSubmitClicked(true)
    // current_employment.forEach((el, i) => {
      if(empStatus === 0){
        setStatusError(true)
      }else{
        status = true
      }
    // })
      past_employment.forEach(element => {
        if((element.job_title_past_emp && element.job_title_past_emp.length > 0) || (element.employment_reference_phone_past_emp && element.employment_reference_phone_past_emp.length > 0) || (element.employment_reference_name_past_emp && element.employment_reference_name_past_emp.length > 0)){
          if(validate(EmpInfoPastValidationScheme)){
            pastJob = true
            submit = true
          }
        // }else if((element.job_title_past_emp && element.job_title_past_emp.length === 0) && (element.employment_reference_phone_past_emp && element.employment_reference_phone_past_emp.length === 0) && (element.employment_reference_name_past_emp && element.employment_reference_name_past_emp.length === 0)){
        }else if((!element.job_title_past_emp) && (!element.employment_reference_phone_past_emp) && (!element.employment_reference_name_past_emp)){
          submit = true
        }
      });
    let url = '';
    if (user_id) {
      url = '/admin/users/tenant/employment-info/' + user_id;
    } else {
      url = '/tenant/profile/employment-info';
    }
    let current = [];
    if(empStatus === 1){
      current_employment.map((data) => {
        const sal_range = [];
        salaryOptions.forEach((record) => {
          if (record.display === data.min_salary_current_emp) {
            sal_range.push(record.value);
          }
        });
      
        let currentRenew = {
          'employment_reference_name': data.employment_reference_name_current_emp,
          'employment_reference_phone': data.employment_reference_phone_current_emp,
          'employment_status': empStatus,
          'id': data.id,
          'is_current': data.is_current,
          'job_title': data.job_title_current_emp,
          'max_salary': data.max_salary_current_emp,
          'salary_range':sal_range[0],
          'start_date': data.start_date_current_emp,
          'type': data.type
        }
        current.push(currentRenew)
      })
    }else if(empStatus === 2){
      current_employment_part_time.map((data) => {
        const sal_range_past = [];
        salaryOptions.forEach((record) => {
          if (record.display === data.min_salary_current_emp_part_time) {
            sal_range_past.push(record.value);
          }
        });
        let currentRenew = {
          'employment_reference_name': data.employment_reference_name_current_emp_part_time,
          'employment_reference_phone': data.employment_reference_phone_current_emp_part_time,
          'employment_status': empStatus,
          'id': data.id,
          'is_current': data.is_current,
          'job_title': data.job_title_current_emp_part_time,
          'max_salary': data.max_salary_current_emp_part_time,
          'salary_range': sal_range_past[0],
          'start_date': data.start_date_current_emp_part_time,
          'type': data.type
        }
        current.push(currentRenew)
      })
    }else if(empStatus === 3){
      schoolData.map(data => {
        let currentRenew = {
          id: data.id,
          is_current: data.is_current,
          employment_status: data.employment_status,
          school_attend: data.school_attend,
          program_taking: data.program_taking,
          start_date: data.start_date,
          end_date: data.end_date,
        }
        current.push(currentRenew)
      })
    }else if(empStatus === 4){
      socialAssistance.map(data => {
        let currentRenew = {
          id: data.id,
            is_current: data.is_current,
            min_salary: data.min_salary,
            employment_status: data.employment_status
        }
        current.push(currentRenew)
      })
    }else if(empStatus === 5){
      retired.map(data => {
        let currentRenew = {
          id: data.id,
            is_current: data.is_current,
            min_salary: data.min_salary,
            employment_status: data.employment_status
        }
        current.push(currentRenew)
      })
    }
    let past = past_employment.map((data) => {
      return  {
        'employment_reference_name': data.employment_reference_name_past_emp,
        'employment_reference_phone': data.employment_reference_phone_past_emp,
        'employment_status': empStatus,
        'id': data.id,
        'is_current': data.is_current,
        'job_title': data.job_title_past_emp,
        'max_salary': data.max_salary_past_emp,
        'min_salary': data.min_salary_past_emp,
        'start_date': moment(data.start_date_past_emp).format('YYYY-MM-DD'),
        'end_date': data.end_date_past_emp,
        'type': data.type
      }
    })
    if (empStatus === 1 && validate(EmpInfoValidationSchema) && pastJob && status) {
      const data = [...current, ...past];
      isSubmit && postData(url, data)
    }else if(empStatus === 1 && validate(EmpInfoValidationSchema) && !pastJob && submit && status){
      let employment_info = [...current];
      const data = employment_info;
      isSubmit && postData(url, data)
    }else if (empStatus === 2 && validate(EmpInfoValidationPartTime) && pastJob && status) {
      const data = [...current, ...past];
      isSubmit && postData(url, data)
    }else if(empStatus === 2 && validate(EmpInfoValidationPartTime) && !pastJob && submit && status){
      const data = [...current];
      isSubmit && postData(url, data)
    }else if(empStatus === 3 && validate(SchoolDataValidation) && status){
      const data = [...current]
      isSubmit && postData(url, data)
      
    }else if((empStatus === 4 || empStatus === 5) && validate(SocialAssistanceValidation) && pastJob && status){
      const data = [...current, ...past]
      isSubmit && postData(url, data)
    }else if((empStatus === 4 || empStatus === 5) && validate(SocialAssistanceValidation) && status && !pastJob && submit){
      const data = [...current];
      isSubmit && postData(url, data)
    }

  }

  const postData = (url, data) => {
    updateProfile(url, data).then(response => {
      if (response === undefined) {
        toaster('error', 'Something went wrong!');
      } else if (response.status === 200) {
        handleClose()
        if (employment_info ==="" ) {
          setPersonalReferencesInfo(true)
        }
        toaster('success', response.data.message)
      }
    })
  }

  const clearPastData = (id) => {
    if(past_employment.length === 1){
      setPast_employment([{
        id: Math.random(),
        is_current: 0,
        employment_status_past_emp: 0,
        min_salary_past_emp: '',
        max_salary_past_emp: '',
        job_title_past_emp: '',
        start_date_past_emp: today_date,
        end_date_past_emp: today_date,
        employment_reference_name_past_emp: '',
        employment_reference_phone_past_emp: '',
        type: 'past'
      }])
    }
  }
  const onClickCategory = (value, display,index) => {
    if(empStatus === 1){
      let items = [...current_employment] ;
      let item = { ...items[index] };
        item['min_salary_current_emp'] = display;
        items[index] = item;
        setCurrent_employment(items)
      }
      if(empStatus === 2){
        let items = [...current_employment_part_time] ;
        let item = { ...items[index] };
          item['min_salary_current_emp_part_time'] = display;
        items[index] = item;
        setCurrent_employment_part_time(items)
    }
  }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Tell Us About Your Employment Status' : 'Employment Status'}>

      <div className='sidepanel-body'>
        <Form id='Form'>

        <Row>
          <Form.Group as={Col} md='12'>
            <Form.Label className="custom-lable">What is your current employment status?</Form.Label>
            <ul className='select-options'>
              {employment_statusData.map((employment_status) =>
                <li style={{ background: statusError ? '#FBDFDF' : '' }} onClick={(e) => setEmpStatus(e.target.value)} key={'employment_status_' + employment_status.value} value={employment_status.value} name='employment_status_current_emp'
                  className={(empStatus === employment_status.value) ? 'active' : ''}>{employment_status.title}</li>)}
                  {statusError && <div style={{ display: statusError ? 'inline-block' : 'none'}} id="error_job_status_current_emp0" className="invalid-feedback">Job status required</div>}
            </ul>
          </Form.Group>
        </Row>
          {current_employment ?
            current_employment.map((data, i) => {
              return (
                <div key={'current_employ_' + i}>
                  {i > 0 ?
                    <>
                      <hr />
                      <div className='sub-title d-flex justify-content-between align-items-center'>
                        <h3>{Numbering(i+1) + ' Current Job'}</h3>
                        <Image
                          key={i}
                          className='icon'
                          width='20px'
                          height='20px'
                          title='Remove'
                          onClick={() => OnRemove(data.id, 'current')}
                          src={require('../../../../_assets/images/delete-icon.svg')}
                        />
                      </div>
                    </> : ''}

                 
                  {/* Current employement status full time */}
                  { (empStatus===0 || empStatus === 1) &&
                    <>
                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">What is your current salary range?</Form.Label>
                          <Row>
                               <DropdownButton
                                    name={'min_salary_current_emp'}
                                    id={'min_salary_current_emp' + i}
                                    style={{ width: '100%' }}
                                    title={data.min_salary_current_emp?data.min_salary_current_emp:"Select salary range"}
                                >
                                    {salaryOptions?.map((options, id) => {
                                        return <Dropdown.Item eventKey={options.value}  key={id} onClick={(e) => onClickCategory(options.value, options.display,i)}>{options.display}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                          </Row>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">What Is Your Current Job Title?</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Type here'
                            name='job_title_current_emp'
                            id={'job_title_current_emp' + i}
                            value={data.job_title_current_emp}
                            onChange={(e) => OnChangeInputArray(e, i, 'current')}
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">When Did You Start At This Job?</Form.Label>
                          <DatePickerComponent
                            name='start_date_current_emp'
                            format={'dd/MM/yyyy'}
                            id={'start_date_current_emp' + i}
                            value={data.start_date_current_emp === '' ? data.start_date_current_emp : moment(data.start_date_current_emp).toDate()}
                            handleDateChange={(date) => OnChangeDate('start_date_current_emp', date, i, 'current',1, setCurrent_employment)}
                            maxDate={new Date()}
                            displayText={'Select Date'}
                            popperPlacement='bottom-end'
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} md='12'>
                          <Form.Label className="custom-lable">Provide a reference for this job</Form.Label>
                          <Row>
                            <Col>
                              <Form.Control
                                type='text'
                                placeholder='Name'
                                name='employment_reference_name_current_emp'
                                id={'employment_reference_name_current_emp' + i}
                                value={data.employment_reference_name_current_emp}
                                onChange={(e) => OnChangeInputArray(e, i, 'current')}
                              />
                            </Col>

                            <Col>
                              <NumberFormat
                                format='###-###-####'
                                mask='_'
                                placeholder='Phone'
                                name='employment_reference_phone_current_emp'
                                id='employment_reference_phone_current_emp'
                                value={data.employment_reference_phone_current_emp}
                                className='form-control'
                                onChange={(e) => OnChangeInputArray(e, i, 'current')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Row>
                    </>
                  }
                </div>)
            }) 
            : ''
          }

          {/* Part time job information */}
          {empStatus === 2 && current_employment_part_time ? 
            current_employment_part_time.map((data, i) => {
              return(
                <>
                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">What is your current salary range?</Form.Label>
                      <Row>
                     
                        <DropdownButton
                              name={'min_salary_current_emp_part_time'}
                              id={'min_salary_current_emp_part_time' + i}
                              style={{ width: '100%' }}
                              title={data.min_salary_current_emp_part_time?data.min_salary_current_emp_part_time:"Select salary range"}
                          >
                              { salaryOptions?.map((options, id) => {
                                  return <Dropdown.Item eventKey={options.value}  key={id} onClick={(e) => onClickCategory(options.value, options.display,i)}>{options.display}</Dropdown.Item>
                              })}
                          </DropdownButton>
                      </Row>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">What Is Your Current Job Title?</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Type here'
                        name='job_title_current_emp_part_time'
                        id={'job_title_current_emp_part_time' + i}
                        value={data.job_title_current_emp_part_time}
                        onChange={(e) => OnChangeInputArray(e, i, 'current')}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">When Did You Start At This Job?</Form.Label>
                      <DatePickerComponent
                        name='start_date_current_emp_part_time'
                        format={'dd/MM/yyyy'}
                        id={'start_date_current_emp_part_time' + i}
                        value={data.start_date_current_emp_part_time === '' ? data.start_date_current_emp_part_time : moment(data.start_date_current_emp_part_time).toDate()}
                        handleDateChange={(date) => OnChangeDate('start_date_current_emp_part_time', date, i, 'current', 2, setCurrent_employment_part_time)}
                        maxDate={new Date()}
                        displayText={'Select Date'}
                        popperPlacement='bottom-end'
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Provide a reference for this job</Form.Label>
                      <Row>
                        <Col>
                          <Form.Control
                            type='text'
                            placeholder='Name'
                            name='employment_reference_name_current_emp_part_time'
                            id={'employment_reference_name_current_emp_part_time' + i}
                            value={data.employment_reference_name_current_emp_part_time}
                            onChange={(e) => OnChangeInputArray(e, i, 'current')}
                          />
                        </Col>

                        <Col>
                          <NumberFormat
                            format='###-###-####'
                            mask='_'
                            placeholder='Phone'
                            name='employment_reference_phone_current_emp_part_time'
                            id='employment_reference_phone_current_emp_part_time'
                            value={data.employment_reference_phone_current_emp_part_time}
                            className='form-control'
                            onChange={(e) => OnChangeInputArray(e, i, 'current')}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </>
              )
            }) : ''
          }

          {/* Current employement status student */}
          {empStatus === 3 && schoolData ? 
            schoolData.map((data, i) => {
              return(
                <>
                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">What School Do You Attend?</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Type here'
                        name='school_attend'
                        id={'school_attend' + i}
                        value={data.school_attend}
                        onChange={(e) => OnChangeInputArray(e, i, 'current')}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">What Program Are You Taking?</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Type here'
                        name='program_taking'
                        id={'program_taking' + i}
                        value={data.program_taking}
                        onChange={(e) => OnChangeInputArray(e, i, 'current')}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Row>
                        <Col>
                          <Form.Label className="custom-lable">Start Date</Form.Label>
                          <DatePickerComponent
                            name='start_date'
                            format={'dd/MM/yyyy'}
                            id={'start_date' + i}
                            value={data.start_date === '' ? data.start_date : moment(data.start_date).toDate()}
                            handleDateChange={(date) => OnChangeDate('start_date', date, i, 'current',3, setSchoolData)}
                            maxDate={new Date()}
                            displayText={'Select Date'}
                            popperPlacement='top-end'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Label className="custom-lable">End Date</Form.Label>
                          <DatePickerComponent
                            name='end_date'
                            format={'dd/MM/yyyy'}
                            id={'end_date' + i}
                            value={data.end_date === '' ? data.end_date : moment(data.end_date).toDate()}
                            handleDateChange={(date) => OnChangeDate('end_date', date, i, 'current', 3, setSchoolData)}
                            minDate={data.start_date === '' ? moment(today_date).toDate() : moment(data.start_date).toDate()}
                            displayText={'Select Date'}
                            popperPlacement='top-end'
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </>
              )
            }):''
          }

          {/* Current employement status Social assiatance */}
          {empStatus === 4 && socialAssistance ?
            socialAssistance.map((data, i) => {
              return(
                <>
                  <Form.Group as={Col} md='12'>
                    <Row>
                      <Form.Label className="custom-lable">What Is Your Monthly Income?</Form.Label>
                      <CostNumberFormat
                        name={'min_salary'}
                        id={'min_salary' + i}
                        classname='form-control'
                        value={data.min_salary}
                        updateField={(e) => OnChangeInputArray(e,i,'current')}
                        pre='$'
                        placeholder='Min'
                        decimalScale={2}
                        decimalSeparator='.'
                      />
                    </Row>
                  </Form.Group>
                </>
              )
            }) : ''
          }
          {empStatus === 5 && retired ?
            retired.map((data, i) => {
              return(
                <>
                  <Form.Group as={Col} md='12'>
                    <Row>
                      <Form.Label className="custom-lable">What Is Your Monthly Income?</Form.Label>
                      <CostNumberFormat
                        name={'min_salary'}
                        id={'min_salary' + i}
                        classname='form-control'
                        value={data.min_salary}
                        updateField={(e) => OnChangeInputArray(e,i,'current')}
                        pre='$'
                        placeholder='Min'
                        decimalScale={2}
                        decimalSeparator='.'
                      />
                    </Row>
                  </Form.Group>
                </>
              )
            }) : ''
          }

          {(empStatus !== 3 && empStatus !== 4 && empStatus !== 5) &&
            <Row className='d-flex justify-content-center'>
              <Col md='10'>
                <LargeButton
                  variant='dark'
                  title='add more'
                  isIcon={true}
                  onClick={() => addMore('current')} />
              </Col>
            </Row>
          }

          {/* Past employement information */}
          { empStatus !== 3 && past_employment ?
            past_employment.filter((obj) => {
              let objVal = '';
              if (!obj.is_current) {
                objVal = obj;
              }
              return (objVal)
            }).map(function (data, i) {

              log.info(data)
              return (
                <div key={'past_employ_' + i}>
                  <hr />
                  <div className='sub-title d-flex justify-content-between align-items-center'>
                    <h3>{(i > 0 ? Numbering(i + 1) : '') + ' Past Job'}</h3>
                    {/* <h3>{Numbering((i + 1)) + ' Past Job'}</h3> */}
                    {i === 0 && past_employment.length === 1 &&
                      <h3 className='clearBtn-past' onClick={() => clearPastData(data.id)} >Clear</h3>  
                    }
                    {i >= 0 && past_employment.length > 1 &&
                      <Image
                        className='icon'
                        width='20px'
                        height='20px'
                        title='Remove'
                        onClick={() => OnRemove(data.id, 'past')}
                        src={require('../../../../_assets/images/delete-icon.svg')}
                      />}
                  </div>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">What Was Your Job Title?</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Type here'
                        name='job_title_past_emp'
                        id={'job_title_past_emp' + i}
                        value={data.job_title_past_emp}
                        onChange={(e) => OnChangeInputArray(e, i, 'past')}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='6'>
                      <Form.Label className="custom-lable">Start Date</Form.Label>
                      <DatePickerComponent
                        name='start_date_past_emp'
                        id={'start_date_past_emp' + i}
                        format={'dd/MM/yyyy'}
                        value={data.start_date_past_emp === '' ? data.start_date_past_emp : moment(data.start_date_past_emp).toDate()}
                        // value
                        handleDateChange={(date) => OnChangeDate('start_date_past_emp', date, i, 'past', 0, setPast_employment)}
                        maxDate={new Date()}
                        displayText={'Select Date'}
                        popperPlacement='bottom-start'
                      />
                    </Form.Group>

                    <Form.Group as={Col} md='6'>
                      <Form.Label className="custom-lable">End Date</Form.Label>
                      <DatePickerComponent
                        name='end_date_past_emp'
                        id={'end_date_past_emp' + i}
                        format={'dd/MM/yyyy'}
                        value={data.end_date_past_emp === '' ? data.end_date_past_emp : moment(data.end_date_past_emp).toDate()}
                        // value
                        handleDateChange={(date) => OnChangeDate('end_date_past_emp', date, i, 'past', 0, setPast_employment)}
                        maxDate={new Date()}
                        minDate={data.start_date_past_emp === '' ? data.start_date_past_emp : moment(data.start_date_past_emp).toDate()}
                        displayText={'Select Date'}
                        popperPlacement='bottom-end'
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Provide a reference for this job</Form.Label>
                      <Row>
                        <Col>
                          <Form.Control
                            type='text'
                            placeholder='Name'
                            name='employment_reference_name_past_emp'
                            id={'employment_reference_name_past_emp' + i}
                            value={data.employment_reference_name_past_emp}
                            onChange={(e) => OnChangeInputArray(e, i, 'past')}
                          />
                        </Col>

                        <Col>
                          <NumberFormat
                            format='###-###-####'
                            mask='_'
                            placeholder='Phone'
                            name='employment_reference_phone_past_emp'
                            id='employment_reference_phone_past_emp'
                            value={data.employment_reference_phone_past_emp}
                            className='form-control'
                            onChange={(e) => OnChangeInputArray(e, i, 'past')}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </div>)
            }) : ''}

          {empStatus !== 3 &&
            <Row className='d-flex justify-content-center'>
              <Col md='10'>
                <LargeButton
                  variant='dark'
                  title='add more'
                  isIcon={true}
                  onClick={() => addMore('past')} />
              </Col>
            </Row>
          }
        </Form>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default EmploymentInfo;