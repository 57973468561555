import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { logger } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import { frequencyTypeDisplay } from '../../../../Constants/NoticeConstants';
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView"
import Cookies from 'js-cookie';


const log = logger("N2DetailsView");
const N2DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  log.info('noticeData', noticeData);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Agreement Increasing the rent above the guideline (N2)</span>
          {noticeData && noticeData.is_notice_received
            ? role !== 3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "auto",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              When is the rent increasing?
            </span>
            <span className="field-value-text">{<Moment format="MMMM D, YYYY">{noticeData.when_rent_increase}</Moment>}</span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              New Rent Rate
            </span>
            <span className="field-value-text">${noticeData.new_rent_rate}</span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              Frequency
            </span>
            <span className="field-value-text">
              {frequencyTypeDisplay(noticeData.frequency)}
              {noticeData.frequency === 3 && ` - ${noticeData.frequency_text}`}
            </span>
          </Col>
        </Row>

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />

        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={'checked'}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default N2DetailsView;
