import React from 'react';
import { AppLayout, PageTitleHeader, LargeButton } from '../..';
import '../style.scss';
import { withRouter } from "react-router-dom";

const N10Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (<>
   <AppLayout>
    <PageTitleHeader
      isArrow={true}
      onClick={() => back()}
      title={'Important Information About the Law'}
      subTitle={'Agreement to increase the rent above the guideline (N10)'}/>

      <div className="notice-info-card">
        <ol>
          <li>If the tenant wants to cancel this agreement, the tenant can do so by giving written notice to the landlord within five calendar days after the agreement is signed. The agreement cannot come into effect until at least six calendar days after it is signed.</li>
          <li>A landlord can only agree to increase the rent if at least 12 months have passed since the last rent increase or since a new tenant moved into the unit.</li>
          <li>If the landlord and tenant sign this form, it takes the place of a Notice of Rent Increase. Therefore, the landlord does not have to give a notice to increase the rent.</li>
          <li>If the landlord does not do the work or provide the service that has been agreed to, the tenant may apply to the Landlord and Tenant Board and ask that all or part of the rent increase above the rent increase guideline be considered invalid. The tenant must apply no later than two years after the date the rent was increased pursuant to the agreement.</li>
          <li>If you have any questions about the law related to rent increases and how it applies to this notice, you may contact the Landlord and Tenant Board at 416-645-8080 or toll-free at 1-888-332-3234. Or, you may visit the Board's website at sjto.ca/LTB for further information.</li>
        </ol>
      </div>
      <div className="d-flex justify-content-end button-extra-margin">
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

        />
      </div>
  </AppLayout>
  </>);
}

export default withRouter(N10Info);