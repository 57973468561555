export const N13Schema = {
    terminationDate: [
        { rule: "required", message: "Date required" },
    ],   
    workPlanned: [
        { rule: "required", message: "required" },
    ],
    details1: [
        { rule: "required", message: "required" },
    ],
    firstName: [
        { rule: "required", message: "first name required" },
    ], 
    lastName: [
        { rule: "required", message: "last name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}