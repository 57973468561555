import React, { useState } from "react";
import { sort_price, sort_tenant, sort_status, sort_status_all } from "../../../Constants/adminConstant";
import ImageComponent from "../../Admin/ProfileDetail/ListUsableComponent/ImageComponent";
import DropDown from "../DropDown";
import ThreeDotsDropdown from "../ThreeDotsDropdown";

const ListingHeader = (props) => {
    const { data, component, setSortByPrice, sort_by_type, onSortTenant, sortType, sortStatus, sort_by_status } = props;
    const [status_filter, setStatusFilter] = useState(false);
    const showStatusFilter = () => {
        setStatusFilter(!status_filter);
    }
    const [status, setStatus] = useState(false);
    const showStatus = () => {
        setStatus(!status);
    }
    return (
        <>
            {(component === "listing") &&
                <>
                    {(() => {
                        switch (data.header) {
                            case 'isVerified':
                                return (
                                    <>
                                        <div className="row checkbox-left check-status all-listing">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox large">
                                                        <input type="checkbox" className="custom-control-input" id={"customCheck" + data.header}
                                                            name={`is_verified`}
                                                            checked={props.is_verified} onChange={props.setIsVerified}
                                                        />
                                                        <label className="custom-control-label pl-3 pt-1" style={{ textTransform: 'capitalize' }} htmlFor={"customCheck" + data.header}></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            case 'ADDRESS':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'PRICE':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {sort_price.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"sort_price" + i} onClick={() => setSortByPrice(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'TENANT':
                                return (
                                    <>
                                        { data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {sort_tenant.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"sort_tenant" + i} onClick={() => onSortTenant(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'TYPE':
                                return (
                                    <span className="d-flex justify-content-between">
                                        {data.header}
                                        <DropDown close={() => setStatusFilter(false)} open={status_filter} position={"right"} enableTopArrow={false} style={{ width: "208px", top: "20px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {sort_status.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={sort_by_type.includes(data.value)} onChange={sortType}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showStatusFilter}><ImageComponent /></div>
                                        </DropDown>
                                    </span>

                                )
                            case 'STATUS':
                                return (
                                    <span className="d-flex justify-content-between">
                                        {data.header}
                                        <DropDown close={() => setStatus(false)} open={status} position={"right"} enableTopArrow={false} style={{ width: "208px", top: "20px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {sort_status_all.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={sort_by_status.includes(data.value)} onChange={sortStatus}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showStatus}><ImageComponent /></div>
                                        </DropDown>
                                    </span>
                                )
                            default:
                                return null
                        }
                    })()} </>}
        </>
    );
}
export default ListingHeader;