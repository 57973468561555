import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../Store/actions/Action';
import SearchInput from '../../components/SearchInput/SearchInput';
import propTypes from 'prop-types';

const UserFilter = (props) => {
    const { component, data } = props;
    const dispatch = useDispatch();
    const roleOptions = [
        { display: 'Tenant Only', value: '3' }, { display: 'Landlord Only', value: '2' }, { display: 'View All', value: '' }
    ]
    const filters = useSelector(state => state.userReducer.filters);

    return (
        component === 'userManagement' &&
        <>
            {(() => {
                switch (data.header) {
                    case 'Role':
                        return (
                            <DropdownButton
                                // alignleft
                                title={filters.displayType}
                                id='dropdown-menu-align-left'
                                style={{ width: '100%' }}
                            >
                                {roleOptions.map((options, i) => {
                                    return <Dropdown.Item eventKey={options.value} onClick={() => dispatch(setFilters({ ...filters, displayType: options.display, user_type: options.value }))} key={i}>{options.display}</Dropdown.Item>
                                })}
                            </DropdownButton>
                        )
                    case 'Name of User':
                        return (
                            <SearchInput searchValue={filters.name} setSearchValue={(value) => dispatch(setFilters({ ...filters, name: value }))} placeHolder='Search' extraCalss='table-search-filter' />
                        )
                    case 'Phone Number':
                        return (
                            <NumberFormat
                                format='###-###-####'
                                mask='_'
                                placeholder='Enter Phone Number'
                                name={data.name}
                                className={'table-search-filter'}
                                value={filters.phone_number}
                                onChange={(event) => dispatch(setFilters({ ...filters, phone_number: event.target.value }))}
                            />
                        )
                    case 'Email':
                        return (
                            <SearchInput searchValue={filters.email} setSearchValue={(value) => dispatch(setFilters({ ...filters, email: value }))} placeHolder='Search' extraCalss='table-search-filter' />
                        )
                    default:
                        return null
                }
            })()}
        </>

    )
}
export default UserFilter;

UserFilter.propTypes = {
    component: propTypes.string.isRequired,
    data: propTypes.object.isRequired
};