import React, { useEffect, useState } from 'react';
import { Button, Form} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster, validate, dateFormat } from '../../../../Utils';
import { ModalBox } from '../../';
import { CreateLeaseDetailsSchema } from "./CreateLeaseSchema/CreateLeaseDetailsSchema";
import { subDays, addMonths, addYears, lastDayOfMonth, differenceInDays, getDaysInMonth } from 'date-fns';
import { useSelector } from 'react-redux';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import Loader from '../../Loader';
import { leaseSubmit, getLeaseInfoFromApi } from './helper';
import LeaseDetails from './CreateLeaseDetailsComponents/LeaseDetails';
import DetailsAboutRent from './CreateLeaseDetailsComponents/DetailsAboutRent';
import DepositsAndDiscounts from "./CreateLeaseDetailsComponents/DepositsAndDiscounts";
import PayUtilities from './CreateLeaseDetailsComponents/PayUtilities';
import CreateLeaseAmenities from './CreateLeaseDetailsComponents/CreateLeaseAmenities';
import FormFooter from './FormFooter/FormFooter';

const CreateLeaseDetails = (props) => {
    const { setCurrentStep, property_id, property_details_id, leaseData, lease_id } = props;

    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const profileDetails = useSelector(state => state.editProfile.editProfileList);
    
    const [leaseInfo, setLeaseInfo] = useState({
        lease_type : propertyDetails.details[0].lease_duration,
        lease_start_date : new Date(),
        lease_end_date : new Date(),
        tenancy_agreement : 0,
        lease_pdf : { file: '', percent: 0 },
        rent_paid_on : '1st',
        rent_paid_on_day_of_each : 1,
        rent_paid_on_day_of_each_other : '',
        rent : '',
        is_parking_available : 0,
        parking_type : 1,
        parking_spot : '',
        parking_price : '',
        rent_payable_to : null,
        rent_pay_method : [],
        first_rental_partial_period : 0,
        partial_cover_start_date : new Date(),
        partial_cover_end_date : new Date(),
        partial_cover_amount : '',
        partial_payment_date : new Date(),
        administrative_charge : 0,
        is_smoking_allowed : 0,
        is_rent_deposit : 1,
        is_rent_deposit_amount : 0,
        is_key_deposite : 1,
        key_deposite_amount : '',
        is_rent_discounted : 1,
        rent_discount_amount : '',
        rent_discount_description : '',
        utility_electricity_pay_by : 1,
        utility_heat_pay_by : 1,
        utility_water_pay_by : 1,
        other_service_utilities : ''
    });

    const [amenities, setAmenities] = useState([]);
    const [errorClass, setErrorClass] = useState(false);
    const [saveLoading, setBtnDisable] = useState(false);
    const [saveState, setSaveState] = useState('');
    const [is_lease_updated, setLease_updated] = useState(false);
    const [isSaveEnable, setSaveEnable] = useState(false);

    useEffect(() => {
        const updatedLeaseInfo = getLeaseInfoFromApi(leaseData, propertyDetails, unitDetails, profileDetails);
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, ...updatedLeaseInfo}));

        const leaseAmenities = leaseData?.lease_deatils?.amenities;
        const unitAmenities = unitDetails?.amenities;
        let leaseAmenitiesId = []
        let allowedAmenities = unitAmenities ? [...unitAmenities] : []
        if (leaseAmenities?.length > 0) {
            for (let el in leaseAmenities) {
                if (leaseAmenities[el].allow === 1) {
                    leaseAmenitiesId.push(leaseAmenities[el].id)
                }
            }
            allowedAmenities = [...leaseAmenitiesId]
        }

        (async () => {
            const { amenitiesData = [] } = await getUtilitiesAndAmenities()
            const newAmenities = amenitiesData.map((data) => {
                if (allowedAmenities.includes(data.id)) {
                    return ({ ...data, allow: 1 })
                }
                return ({ ...data, allow: 0 })
            })
            setAmenities(newAmenities)
        })()

    }, [leaseData, propertyDetails, unitDetails, profileDetails]);

    useEffect(() => {
        let endDate;
        if (leaseInfo.lease_type === 1) {
            endDate = (subDays(addYears(leaseInfo.lease_start_date, 1), 1))
        } else if (leaseInfo.lease_type === 2) {
            endDate = (subDays(addMonths(leaseInfo.lease_start_date, 1), 1))
        }
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, lease_end_date : endDate }));
    }, [leaseInfo.lease_start_date, leaseInfo.lease_type])

    const backToPrevPage = () => {
        setCurrentStep(0);
        props.history.push({
            pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
            state: {
                lease_id: lease_id,
                step: 0
            }
        });
    }

    const onAmenitiesToggle = (id) => {
        const amenitiesCopy = [...amenities]
        setAmenities(amenitiesCopy.map((i) => {
            if (i.id === id) {
                i.allow = i.allow ? 0 : 1
            }
            return i
        }))
    }

    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        let updatedOption = {};        
        setSaveEnable(true);
        if(name === 'is_rent_deposit'){
            if (Value === 1) updatedOption['is_rent_deposit_amount'] = leaseInfo.rent;
            updatedOption['is_rent_deposit'] =  Value;
            setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, ...updatedOption}));
            return;
        }
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [name] : Value}));
    }

    const handleDate = (eventDate, state, partialPeriod='') => {
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [state] : eventDate }));
        setSaveEnable(true);
        if(partialPeriod) partialCalculator(eventDate)
    }

    const partialCalculator = (eventDate, newRent=null) => {
        var result = lastDayOfMonth(new Date(eventDate));
        var noOfDaysRemain = differenceInDays(new Date(result), new Date(eventDate));
        var dayInMonth = getDaysInMonth(new Date(eventDate));
        // let rent = unitDetails && unitDetails?.price_per_month;
        let rent = leaseInfo?.rent;
        if(newRent !== null){
            rent = newRent
        }
        
        // (rent/daysin month) * No of days remaining
        let partialAmount = ((Number(rent) / Number(dayInMonth)) * (Number(noOfDaysRemain) + 1))
        setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, partial_cover_end_date : result, partial_cover_amount : partialAmount, partial_payment_date : eventDate }));
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (leaseInfo.tenancy_agreement === 1 && (leaseInfo.lease_pdf.file === "" || leaseInfo.lease_pdf === "")) {
            setErrorClass(true);
            toaster('error', 'Please upload a file before continuing!');
            return;
        }
        if (leaseInfo.rent_pay_method.length === 0) {
            document.getElementById('errorRentMethodClass').scrollIntoView({ behavior: 'smooth', block: 'start' })
            return;
        }
        if (validate(CreateLeaseDetailsSchema) && !errorClass) {
             setBtnDisable(true)
            const updatedAmenities = amenities.map((data) => {
                return { id: data.id, allow: data.allow }
            })
            const data = {
                ...leaseInfo,
                'lease_id': lease_id,
                'property_id': property_id,
                'property_detail_id': property_details_id,
                'lease_appendix': leaseInfo?.lease_pdf?.file,
                'parking_price': Number(leaseInfo.parking_price),
                'partial_cover_start_date': dateFormat(leaseInfo.partial_cover_start_date),
                'partial_cover_end_date': dateFormat(leaseInfo.partial_cover_end_date),
                'partial_payment_date': dateFormat(leaseInfo.partial_payment_date),
                'is_rent_deposit_amount': leaseInfo.is_rent_deposit ? leaseInfo.is_rent_deposit_amount : 0,
                'other_service_utilities': [
                    leaseInfo.other_service_utilities
                ],
                'amenities': updatedAmenities,                
                'action': saveState,
            }

            try {
                const res = await leaseSubmit(data, 'details')
                setBtnDisable(false)
                if (res.status === 200) {
                    if(saveState !== 'update' && isSaveEnable) toaster('success', 'Data Saved Successfully!');
                    if (saveState === 'next') {
                        setCurrentStep(2);
                        props.history.push({
                            pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
                            state: {
                                lease_id: lease_id,
                                step: 2
                            }
                        });
                    } else if (saveState === 'update') {
                        setLease_updated(true);
                    }
                } else {
                    toaster('error', res.message)
                }
            } catch (error) {
                setBtnDisable(false)
                toaster('error', 'Something went wrong!')
            }
        }
    }

    const onChangeHandler = (value, state) => setLeaseInfo(prevLeaseInfo => ({...prevLeaseInfo, [state] : value}));

    const onUpdateBaseRent = (rent) => {
        setLeaseInfo(prevLeaseInfo=> ({...prevLeaseInfo, rent}))
        if (leaseInfo.is_rent_deposit) {
            onChangeHandler(rent, 'is_rent_deposit_amount')
        }
        if(leaseInfo.first_rental_partial_period && leaseInfo.partial_cover_start_date){
            partialCalculator(leaseInfo.partial_cover_start_date, rent);
        }
    }

    return (<>
        {!leaseData ? <Loader /> :
            <Form id='Form' onSubmit={onSubmit}>
                <div className='inner-wrapper'>
                    <div className='body-container'>
                        <LeaseDetails 
                            leaseInfo={leaseInfo}
                            OnSingleOptionCheck={OnSingleOptionCheck}
                            handleDate ={handleDate}
                            leaseData = {leaseData}
                            errorClass={errorClass}
                            setErrorClass={setErrorClass}
                            isSaveEnable={isSaveEnable}
                            setSaveState={setSaveState}
                            setLeaseInfo={setLeaseInfo}
                            setSaveEnable={setSaveEnable}
                        />
                        <hr />
                        <DetailsAboutRent
                            leaseInfo={leaseInfo}
                            onChangeHandler={onChangeHandler}
                            OnSingleOptionCheck={OnSingleOptionCheck}
                            setLeaseInfo={setLeaseInfo}
                            handleDate={handleDate}
                            setSaveEnable={setSaveEnable}
                            onUpdateBaseRent={onUpdateBaseRent}
                        />
                        <hr />
                        <DepositsAndDiscounts
                            leaseInfo={leaseInfo}
                            OnSingleOptionCheck={OnSingleOptionCheck}
                            onChangeHandler={onChangeHandler}
                        />
                        <hr />
                        <PayUtilities
                            OnSingleOptionCheck={OnSingleOptionCheck}
                            leaseInfo={leaseInfo}
                        />
                        <hr />
                        <CreateLeaseAmenities
                            amenities={amenities}
                            onAmenitiesToggle={onAmenitiesToggle}
                            leaseInfo={leaseInfo}
                            onChangeHandler={onChangeHandler}
                        />
                    </div>
                </div>

                <FormFooter
                    backToPrevPage={backToPrevPage}
                    leaseData={leaseData}
                    saveLoading={saveLoading}
                    setSaveState={setSaveState}
                    id='BTN-create-lease-details'
                    dataDescription='Landlord input the details of their lease'
                />
            </Form>
        }
        <ModalBox
            show={is_lease_updated}
            onHide={() => setLease_updated(false)}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => {
                setLease_updated(false);
                setCurrentStep(3);
            }}
        >
            <h4>your lease has been updated</h4>
            <p>the tenant has been <br /> notified of the changes. </p>
        </ModalBox>
    </>);
}

export default withRouter(CreateLeaseDetails);