import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster } from '../../../../Utils';
import { ModalBox, Loader } from '../../';
import { validate } from '../../../../Utils/Validation/validation';
import { TenantDetailSchema } from './CreateLeaseSchema/TenantDetailSchema';
import { Prompt } from 'react-router';
import { leaseSubmit } from './helper';
import AddTenantDetails from './CreateLeaseTenantDetailsComponent/AddTenantDetails';
import FormFooter from './FormFooter/FormFooter';

const CreateLeaseTenantDetails = (props) => {
	const { callAddTenant, setCurrentStep, property_id, property_details_id, leaseData, lease_id, selectedTenant, setSelectedTenant } = props;
	const [required_tenant_insurance, setTenantInssurance] = useState(0);
	const [selectedTenantData, setSelectedTenantData] = useState(selectedTenant);
	const [saveLoading, setBtnDisable] = useState(false);
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const [isSaveEnable, setSaveEnable] = useState(false);
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	useEffect(() => {
		const fetchedData = leaseData;
		if (fetchedData?.tenant_details) {
			setTenantInssurance(fetchedData.tenant_details.required_tenant_insurance);
		}
	}, [leaseData]);

	useEffect(() => setSelectedTenantData(selectedTenant), [selectedTenant])

	const backToPrevPage = () => {
		setCurrentStep(1);
		props.history.push({
			pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
			state: {
				lease_id: lease_id,
				step: 1
			}
		});
	}

	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		setTenantInssurance(Value);
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	const addtenant = () => {
		callAddTenant(true);
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	const OnRemove = (index, data) => {
		let items = [...selectedTenantData];
		items.splice(index, 1);
		setSelectedTenantData(items);
		setSelectedTenant(items)
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		setShouldBlockNavigation(false);
		if (validate(TenantDetailSchema)) {
			setBtnDisable(true)
			const data = {
				'lease_id': lease_id,
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'required_tenant_insurance': required_tenant_insurance,
				'tenants': selectedTenantData,
				'action': saveState
			}
			
			const res = await leaseSubmit(data, 'tenant-details');

			setBtnDisable(false)
			if (res.status === 200) {
				if(saveState !== 'update' && isSaveEnable) toaster('success', 'Data Saved Successfully!');
				if (saveState === 'next') {
					setCurrentStep(3);
					props.history.push({
						pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
						state: {
							lease_id: lease_id,
							step: 3
						}
					});
				} else if (saveState === 'update') {
					setLease_updated(true);
				}
				return;
			}
			toaster('error', 'Please add tenant!');
		}
	}

	const handleClickInside = option => setIsPopoverOpen(option === 'yes' ? true : false);
	
	return (<>
		<Prompt
			when={shouldBlockNavigation}
			message="your information will be lost do you want to leave this page?"
		/>
		{!leaseData ? <Loader /> : <Form id='Form' onSubmit={onSubmit}>
			<AddTenantDetails
				leaseData={leaseData}
				isSaveEnable={isSaveEnable}
				setSaveState={setSaveState}
				selectedTenantData={selectedTenantData}
				OnRemove={OnRemove}
				addtenant={addtenant}
				handleClickInside={handleClickInside}
				isPopoverOpen={isPopoverOpen}
				OnSingleOptionCheck={OnSingleOptionCheck}
				required_tenant_insurance={required_tenant_insurance}
			/>
			<FormFooter
				backToPrevPage={backToPrevPage}
				leaseData={leaseData}
				saveLoading={saveLoading}
				setSaveState={setSaveState}
				id='BTN-create-lease-tenant-details'
				dataDescription='Landlord input tenant details into lease'
			/>
		</Form>}

		<ModalBox
			show={is_lease_updated}
			onHide={() => setLease_updated(false)}
			size="sm"
			actionbuttontitle={"got it"}
			buttonaction={() => {
				setLease_updated(false);
				setCurrentStep(3);
			}}
		>
			<h4>your lease has been updated</h4>
			<p>the tenant has been <br /> notified of the changes. </p>
		</ModalBox>
	</>);
}

export default withRouter(CreateLeaseTenantDetails);