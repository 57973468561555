import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Image } from 'react-bootstrap';
import { LargeButton, CancelButton, SidePanel, DatePickerComponent, CostNumberFormat, SingleOptionCheck, FileBrowse, ModalBox } from '../../../components';
import { getAPI, postAPI } from '../../../../Api';
import { withRouter } from 'react-router-dom';
import { validate } from '../../../../Utils/Validation/validation';
import { numberFormatSanitizerInt } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import { dateFormat } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { subDays, addMonths, addYears } from "date-fns";
import { leaseType } from '../LeaseDetails/LeaseConstants';
import { logger, toaster } from '../../../../Utils';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import Axios from 'axios';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { uploadManual } from '../../../../Store/actions/Action';
import './style.scss';
// import { Prompt } from 'react-router';

const log = logger('LeaseDuration');
const LeaseDurationSchema = {
	lease_start_date: [
		{ rule: "required", message: "Lease start date is required" },
	],
	lease_end_date: [
		{ rule: "required", message: "Lease end date is required" },
	],
	lease_rent_amount: [
		{ rule: "required", message: "Rent per month is required" },
	],
}

const LeaseDuration = (props) => {
	const { isShow, handleClose, openUploadLease, teantOutside, callAddTenant, selectedTenant, setSelectedTenant, lease_id, openConfirmationUploadLease } = props;
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [leaseId, setLeaseId] = useState('');
	const [lease_type, setLeaseType] = useState(1);
	const [lease_start_date, setStartDate] = useState(new Date());
	const [lease_end_date, setEndDate] = useState(new Date());
	const [lease_rent_amount, setAmount] = useState(propertyDetails ? propertyDetails.details[0].price_per_month : '');
	const [selectedTenantData, setSelectedTenantData] = useState([]);
	const [disableSave, setBtnDisableSave] = useState(false);
	const [leaseStatus, setLeaseStatus] = useState(null);
	const [disableBtn, setBtnDisable] = useState(false);
	const [lease_pdf, setLease_pdf] = useState({ file: '', percent: 0 });
	const [uploadData, setUploadData] = useState(null);
	const [errorClass, setErrorClass] = useState(false);
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [openPrompt, setPrompt] = useState(false);
	const dispatch = useDispatch();
	const selectorData = useSelector(state => state.propertyDetailsReducer.manualData);
	const [showImageLoading,setShowImageLoading]=useState(false)


	const OnChangeInput = (event) => {
		if (event.target.name === 'lease_rent_amount') {
			const sanitizeValue = numberFormatSanitizerInt(event.target.value);
			setAmount(sanitizeValue)
		}
		setShouldBlockNavigation(true);
	}

	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'lease_type') {
			log.info(Value)
			setLeaseType(Value);
		}
		setShouldBlockNavigation(true);
	}

	const handleDate = (eventDate, setState, setNextDate) => {
		setState(eventDate);
		if (lease_type === 1) {
			setNextDate(subDays(addYears(eventDate, 1), 1))
		} else if (lease_type === 2) {
			setNextDate(subDays(addMonths(eventDate, 1), 1))
		}
		setShouldBlockNavigation(true);
	}

	const handleDrop = (file, setFile) => {
		setShouldBlockNavigation(true);
		const token = `Bearer ${Cookies.get('access_token')}`
		const headers = {
			'Authorization': token,
			'Content-Type': 'multipart/form-data'
		}
		setShowImageLoading(true)

		file.map((dataFile) => {
			setUploadData(dataFile);
			let formdata = new FormData();
			formdata.append('file', dataFile);
			let url = FILE_API_URL;
			Axios.post(
				url, formdata, {
				headers: headers,
				onUploadProgress: progressEvent => {
					var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					if (percent >= 100) {
						setFile({ percent: percent });
						return file;
					} else {
						setFile({ percent: percent });
						return null;
					}
				}
			})
				.then(response => {
					setFile({ file: response.data.data.url })
					setErrorClass(false)
					setShowImageLoading(true)
				})
			return dataFile;
		})
	}
	const removeFile = (index, setFile) => {
		setFile('')
		setShouldBlockNavigation(true);
	}

	const OnRemove = (index, data) => {
		let items = [...selectedTenantData];
		items.splice(index, 1);
		setSelectedTenantData(items);
		setSelectedTenant(items);
		setShouldBlockNavigation(true);
	}
	const submit = (type) => {
		if (validate(LeaseDurationSchema)) {
			if (selectedTenantData && selectedTenantData.length > 0) {
				if (type === 1) {
					setBtnDisable(true)
				} else {
					setBtnDisableSave(false)
				}

				const url = '/admin/listing/lease/manual-upload';
				const data = {
					"lease_id": leaseId,
					"property_id": property_id,
					"property_detail_id": property_details_id,
					"lease_pdf": lease_pdf && lease_pdf.file,
					"lease_type": lease_type,
					"lease_start_date": dateFormat(lease_start_date),
					"lease_end_date": dateFormat(lease_end_date),
					"rent": lease_rent_amount,
					"tenants": selectedTenantData,
					"send_to_tenant": type //"0: Save, 1: Send To Tenant"
				}
				if (lease_pdf && lease_pdf.file === '') {
					setErrorClass(true)
					setBtnDisable(false)
					setBtnDisableSave(false)
				} else {
					postAPI(url, data).then(res => {
						log.info("res", res);
						setBtnDisable(false)
						setBtnDisableSave(false)
						setShouldBlockNavigation(false);
						if (res.status === 200) {
							type === 1 && handleClose('callBackApi');
							if (type === 1) {
								openConfirmationUploadLease(true)

								openUploadLease(false)
								localStorage.setItem('tenantDetailFrom', '')
								setLeaseType(1)
								setStartDate(new Date())
								setEndDate(new Date())
								setAmount('')
								setSelectedTenantData(null)
								setBtnDisableSave(false)
								setLeaseStatus(null)
								setBtnDisable(false)
								setLease_pdf({ file: '', percent: 0 })
								setUploadData(null)
							} else {
								toaster('success', res.data.message);
							}
							setErrorClass(false)
						} else if (res.response.status === 400) {
							log.info(res.response)
							toaster('error', res.response.data.message);
						} else if (res.response.status === 422) {
							toaster('error', res.response.data.message);
						}
					})
				}
			} else {
				toaster('error', 'Tenants Required')
			}
		}
	}
	const handleCloseConfirm = (status = true) => {
		if (shouldBlockNavigation === true && status === true) {
			setPrompt(true)
		} else {
			handleClose('callBackApi');
			// openUploadLease(true)
			props.setTitleUpload(false)
		}

	}
	const onClickAddTenant = () => {
		handleClose();
		callAddTenant(true)
		openUploadLease(false)
		props.setTitleUpload(true)
		const data = {
			lease_type: lease_type,
			lease_start_date: lease_start_date,
			lease_end_date: lease_end_date,
			lease_rent_amount: lease_rent_amount,
			lease_pdf: lease_pdf.file,
			uploadData: uploadData
		}
		dispatch(uploadManual(data))
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setLeaseId(lease_id);
	}, [props.match.params.property_id, lease_id, props.match.params.property_details_id]);
	useEffect(() => {
		if (property_details_id && leaseId !== 0) {
			const url = `/admin/listing/lease/get-manual-upload-lease-data/${property_details_id}/${leaseId}`;
			getAPI(url).then(res => {
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setLeaseType(fetchedData.lease_type);
					setStartDate(fetchedData.lease_start_date !== null ? moment(fetchedData.lease_start_date).toDate() : new Date());
					setEndDate(fetchedData.lease_end_date !== null ? moment(fetchedData.lease_end_date).toDate() : new Date());
					setAmount(fetchedData.rent);
					setSelectedTenantData(fetchedData.tenants);
					setLease_pdf({ file: fetchedData.lease_pdf });
					setLeaseStatus(fetchedData.status)
				}
			})
		} else if (selectorData !== null) {
			setLeaseType(selectorData.lease_type);
			setStartDate(moment(selectorData.lease_start_date).toDate());
			setEndDate(moment(selectorData.lease_end_date).toDate());
			setAmount(selectorData.lease_rent_amount);
			setLease_pdf({ file: selectorData.lease_pdf });
			setUploadData(uploadData)
		}

	}, [leaseId, selectorData, property_details_id, uploadData]);
	useEffect(() => {
		let merge = selectedTenant.concat(teantOutside)
		setSelectedTenantData(merge)
		setSelectedTenant(merge);
	}, [teantOutside]);

	let maxLength = 25;

	window.onbeforeunload = function () {
		handleCloseConfirm(true)
		return false;
	};

	return (<div className='lease-duration-details'>

		<ModalBox
			show={openPrompt}
			closeButtonHeader={false}
			size='sm'
			type='condition'
			actionbuttontitle={'Yes'}
			buttonaction={() => { handleCloseConfirm(false); setPrompt(false) }}
			backbuttontitle={'Cancel'}
			backaction={() => setPrompt(false)}
			extraClassUpper={'deactive3'}
			style={{ 'z-index': 10000 }}
		>
			<p className=''>
				<p className="modal-confirmation-text">wait! you have not saved this information yet! </p>
				<p className='modal-text'>{`your information will be lost do you want to leave this page?`}</p>
			</p>
		</ModalBox>
		<SidePanel
			isShow={isShow}
			isHide={() => handleCloseConfirm()}
			title='UPLOAD LEASE AND DETAILS'
			subTitle='please provide a few key details'
		>
			<div className='sidepanel-body mt-1 lease-pdf-view'>
				<Form.Label className='lease-label'>Lease</Form.Label>
				{(lease_pdf && lease_pdf.file) ?
					<Col className='confirm-section mb-2 d-flex justify-content-between'>
						<span className='d-flex justify-content-start'>
							<Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
							<div className='file-details d-flex'>
								{/* {lease_pdf.file.split('/').pop()} */}
								{lease_pdf.file.split('/').pop().length > maxLength ? lease_pdf.file.split('/').pop().substring(0, maxLength) + '...' : lease_pdf.file.split('/').pop()}
							</div>
						</span>
						{Number(leaseStatus) === 2 ? '' : <Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setLease_pdf)} src={require("../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />}
					</Col>
					: <FileBrowse
						handleDrop={(e) => handleDrop(e, setLease_pdf)}
						size={'sm'}
						title={'Upload Documents'}
						percent={lease_pdf.percent}
						multiple={false}
						fileType={'.pdf'}
						disable={false}
						showImageLoading={showImageLoading}
						imageData={lease_pdf.file && lease_pdf.file}
						removeFile={(e) => removeFile(e, setLease_pdf)}
						lableClass={'custom-lable'}
						section={'upload-lease'}
						uploadData={uploadData && uploadData}
						loaderStyle={{width:'100%',height:'100%'}}
						errorClass={errorClass === true ? "error-upload-box" : ""}
					/>}
				<Form id={Form}>
					<Row>
						<Form.Group as={Col} md="12">
							<Form.Label className='lease-label'>Rent per month</Form.Label>
							<CostNumberFormat
								name={"lease_rent_amount"}
								id={"lease_rent_amount"}
								classname="form-control"
								value={lease_rent_amount ? lease_rent_amount : ''}
								updateField={(e) => OnChangeInput(e)}
								pre="$"
								placeholder={"Enter your desired pricing"}
								decimalScale={2}
								decimalSeparator='.'
							/>
						</Form.Group>
						<Form.Group as={Col} md='12'>
							<Form.Label className='lease-label'>How long is the lease</Form.Label>
							<SingleOptionCheck
								mapData={leaseType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'lease_type'} setParam={lease_type}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="12">
							<Form.Label className='lease-label'>{lease_type === 2 ? 'Please indicate the start date' : 'Please indicate the start date and end date'}</Form.Label>
							<DatePickerComponent
								name="lease_start_date"
								id="lease_start_date"
								value={lease_start_date}
								handleDateChange={(date) => handleDate(date, setStartDate, setEndDate)}
								displayText={"Select or type"}
								minDate={new Date()}
								popperPlacement='bottom-start'
							/>
						</Form.Group>
						{(lease_type === 0 || lease_type === 1) &&
							<Form.Group as={Col} md="12">
								<DatePickerComponent
									name="lease_end_date"
									id="lease_end_date"
									value={lease_end_date}
									handleDateChange={(end_date) => handleDate(end_date, setEndDate)}
									displayText={"Select or type"}
									minDate={lease_start_date}
									popperPlacement='bottom-end'
									disableValue={lease_type === 0 ? false : true}
								/>
							</Form.Group>}
					</Row>
					<hr />
					<Form.Label className='lease-label'>Your tenant(s)</Form.Label>
					{selectedTenantData &&
						<ul className="select-options">
							{selectedTenantData.map((tenant, i) => {
								return (<li className="active">{tenant.name}
									{Number(leaseStatus) === 2 ? '' : <Image
										className="icon cursorPointer ml-3"
										width="14px"
										height="15px"
										title="Remove"
										onClick={() => OnRemove(i, tenant.id)}
										src={require("../../../../_assets/images/delete.svg")}
									/>}
								</li>)
							})}
						</ul>
					}
					{Number(leaseStatus) === 2 ? '' : <LargeButton isIcon={true} title='add tenant(s)' variant='dark' style={{ width: '300px', marginBottom: '100px' }} onClick={() => onClickAddTenant()} />}
				</Form>


			</div>
			<div className='fixed-bottom d-flex justify-content-between lease-upload'>
				<CancelButton onClick={() => handleCloseConfirm()} title='back' />
				<span className='d-flex justify-content-between align-items-center'>
					{Number(leaseStatus) === 2 ? '' :
						<>
							<button className='save-link' disabled={disableSave} style={{ color: '#8DC541', background: 'none', border: '0', padding: '0', marginRight: '20px' }} onClick={() => submit(0)}>Save</button>
							<LargeButton saveLoading={disableBtn} title='send to tenant' onClick={() => submit(1)} />
						</>}
				</span>
			</div>
		</SidePanel>
	</div>);
}
export default withRouter(LeaseDuration);