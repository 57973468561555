import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Nav } from "react-bootstrap";
import { NAVIGATIONLINKS } from "./constant";
import { setToggleMenu } from "../../../Store/actions/Action";

class Drawer extends Component {
  onCloseButton = (pathName) => {
    const element = document.getElementById("side-drawer");
    const overlayScreen = document.getElementById("overlay-screen");
    if (element) {
      element.style.width = "0";
    }
    if (overlayScreen) {
      overlayScreen.style.height = "0";
      document.body.style.overflow = "initial";
    }
    this.props.dispatch(setToggleMenu(false));
  };

  onLogout = () => {
    this.onCloseButton();
    Swal.showLoading();
    localStorage.clear();
    this.props.onLogout();
    // Utils.closeLoader();
  };

  renderLoginOrLogoutButton() {
    if (this.props.userData) {
      return (
        <Link
          to={{ pathname: "/login" }}
          className="drawer-nav-link drawer-signout"
          onClick={this.onLogout}
        >
          <svg
            className="drawer-signout-icon"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 512 640"
            enableBackground="new 0 0 512 512"
            xmlSpace="preserve"
          >
            <g>
              <path d="M245.055,333.438c-3.547,3.562-3.547,9.344,0,12.906c3.453,3.406,9.438,3.406,12.891-0.031l83.828-83.875   c3.547-3.562,3.547-9.344,0-12.906l0.422-0.406c-5.969-5.125-23.625-22.812-84.438-83.641c-3.312-3.203-9.266-3.203-12.703,0.156   c-3.484,3.5-3.531,9.25-0.094,12.797l68.422,68.438H9.242c-5.016,0-9.109,4.078-9.109,9.109s4.094,9.109,9.109,9.109l304.109,0.016   L245.055,333.438z" />
              <path d="M487.945,0H161.82c-6.375,0-12.391,2.484-16.906,7.031c-4.516,4.516-7,10.516-7,16.922v118.484   c0,5.031,4.078,9.109,9.094,9.109s9.094-4.078,9.094-9.109V18.234h337.562v475.531H156.102V369.547   c0-5.031-4.078-9.109-9.094-9.109s-9.094,4.078-9.094,9.109v118.5c0,13.203,10.719,23.953,23.906,23.953h326.125   c13.188,0,23.922-10.75,23.922-23.953V23.953C511.867,10.75,501.133,0,487.945,0z" />
            </g>
          </svg>
          Sign Out
        </Link>
      );
    } else {
      return (
        <Link
          to={{ pathname: "/login" }}
          className="nav-link font-weight-bold sidenav-login"
          onClick={() => this.onCloseButton}
        >
          Login
        </Link>
      );
    }
  }

  render() {
    const NavLinks = NAVIGATIONLINKS.map((navlink, index) => (
      <Link
        key={index}
        target={navlink.newTab ? "_blank" : ""}
        to={{ pathname: `${navlink.pathName}` }}
        className={`drawer-nav-link ${navlink.className}`}
        onClick={() => this.onCloseButton(navlink.pathName)}
      >
        {navlink.displayName}
      </Link>
    ));
    return (
      <div id="side-drawer" className="sidenav">
        <div className="sidenav-links-wrapper">
          <div style={{ width: "55%" }}>
            <Nav.Link
              className="closebtn"
              onClick={this.onCloseButton.bind(this, undefined)}
            >
              <span>&#10005;</span>
            </Nav.Link>
            {NavLinks}
            {/* {this.renderLoginOrLogoutButton()} */}
          </div>
        </div>
      </div>
    );
  }
}

const select = (state) => ({
  editProfileList: state.editProfile.editProfileList,
  toggleMenu: state.headerReducer.toggleMenu,
  auth: state.auth,
});

export default connect(select)(withRouter(Drawer));
