export const getBounds = (points, maps) => {
    if (maps && points) {
        console.log({ points })
        const bounds = new maps.LatLngBounds();
        points.forEach((i) => {
            bounds.extend(i);
        })
        return bounds
    }
}


export const getLocationsUnder20km = (markers, selectedLoation, maps) => {
    if (Object.keys(selectedLoation || {}).length) {

        const nearestLocations = markers.filter((marker) => {
            const distance = getDistanceFromLatLonInKm(selectedLoation.lat, selectedLoation.lng, marker.latitude, marker.longitude)
            return distance <= 1
        })

        return nearestLocations
    }
}


const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180)
}
