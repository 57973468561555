import React from "react";
import "../style.scss";
import { DebounceInput } from 'react-debounce-input';

const SearchInput = (props) => {
    const { searchLocation, setSearch, placeHolder, extraCalss } = props;
    return (
        // <Form.Control type="text" placeholder={placeHolder ? placeHolder : "Type to search location"} id="searchLocation" name="searchLocation"
        //     value={searchLocation === null ? "" : searchLocation}
        //     onChange={(e) => setSearch(e.target.value)}
        //     className={"searchBar mt-2 " + extraCalss}
        // />
        <DebounceInput
            type="text" placeholder={placeHolder ? placeHolder : "Type to search location"} id="searchLocation" name="searchLocation"
            minLength={3}
            debounceTimeout={1000}
            className={"form-control searchBar mt-2 " + extraCalss}
            value={searchLocation === null ? "" : searchLocation}
            onChange={event => setSearch(event.target.value)} />
    );
}

export default SearchInput;
