import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import {
  is_furnishedData,
  pet_policyData,
  is_parking_includedData,
  laundryForHouseData,
  laundryForUnitData,
  getUtilitiesAndAmenities
} from "../../../../Constants/propertyDetails"
import { logger, toaster } from "../../../../Utils"
import { postAPI } from "../../../../Api";
import { isEmptyArray } from "formik";
import { Prompt } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useLocation, useHistory } from 'react-router-dom';

const log = logger("StepThree");
const StepThree = (props) => {
  const { backToPrevPage } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_type = propertyDetails && propertyDetails.property_type;
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  log.info("currentPath",currentPath);

  const [is_furnished, setIs_furnished] = useState(0);
  const [pet_policy, setPet_policy] = useState(2);
  const [amenities, setAmenities] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [utilitiesData, setUtilitiesData] = useState([4]);
  const [is_parking_included, setIs_parking_included] = useState(0);
  const [parking_slot, setParking_slot] = useState(0);
  const [utilities, setUtilities] = useState([0]);
  const [laundry, setLaundry] = useState(0);
  const laundryData = (property_type === 1) ? laundryForHouseData : laundryForUnitData;
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  useEffect(() => {
    if (unitDetails) {
      setIs_furnished(unitDetails.is_furnished)
      setPet_policy(unitDetails.pet_policy || 2)
      setAmenities(unitDetails.amenities)
      setIs_parking_included(unitDetails.is_parking_included)
      setParking_slot(unitDetails.parking_slot);
      setUtilities(isEmptyArray(unitDetails.utilities) ? [4] : unitDetails.utilities);
      setLaundry(unitDetails.laundry);
    }
    (async() => {
      const {utilitiesData=[], amenitiesData=[]}=await getUtilitiesAndAmenities()
      setAmenitiesData(amenitiesData)
      setUtilitiesData(utilitiesData)
    })()
  }, [unitDetails])

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'is_furnished') {
      setIs_furnished(Value);
    } else if (name === 'pet_policy') {
      setPet_policy(Value);
    } else if (name === 'is_parking_included') {
      if (Value === 0) {
        setParking_slot(0)
      } else if (parking_slot === 0) {
        setParking_slot(1)
      }
      setIs_parking_included(Value)
    } else if (name === 'laundry') {
      setLaundry(Value)
    }
    setShouldBlockNavigation(true);
  }

  const OnMultipleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'amenities') {
      if (amenities.includes(Value)) {
        const index = amenities.indexOf(Value);
        amenities.splice(index, 1);
        setAmenities(amenities => [...amenities])
      } else {
        setAmenities(amenities => [...amenities, Value])
      }
      setShouldBlockNavigation(true);
    } else if (name === 'utilities') {
      const noneId = utilitiesData?.find((i) => i.name === 'None').id;
      if (utilities.includes(Value)) {
        const index = utilities.indexOf(Value);
        utilities.splice(index, 1);
        setUtilities(utilities => [...utilities.filter((i) => i !== noneId)])
      } else {
        setUtilities(utilities => [...utilities.filter((i) => i !== noneId), Value])
      }
      if (utilities.length < 1 || Value === noneId) {
        setUtilities([noneId]);
      }

      setShouldBlockNavigation(true);
    }
  }

  const OnChangeInput = (event) => {
    setParking_slot(event.target.value)
    setShouldBlockNavigation(true);
  }

  const onSubmit = (type) => {
    const url = '/landlord/create-listing/step-3';
    const data = {
      property_id: property_id,
      property_details_id: property_details_id,
      property_type: property_type,
      is_furnished: is_furnished,
      pet_policy: pet_policy,
      amenities: amenities,
      is_parking_included: is_parking_included,
      parking_slot: parking_slot,
      utilities: utilities,
      laundry: laundry
    }
    postAPI(url, data).then(response => {
      if (response.status !== 200) {
        toaster('error', response.response?.data?.message);
      } else if (response.status === 200) {
        toaster('success', 'Data Saved Successfully!')
        if (type === 'next') {
          dispatch(getCurrentPage(3));
        } else if (type === "save" && currentPath === "edit-listing") {
          history.goBack();
        }
        setShouldBlockNavigation(false);
      }
    })
  }

  const utilitiesView = (utilitiesData?.length > 0 && utilitiesData?.map((data) =>
    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"utilities_" + data.id} value={data.id} name="utilities" className={(utilities?.includes(data.id)) ? "active" : ""} data-name={data.name}>{data.name}</li>)
  )

  const amenitiesView = (amenitiesData?.map((data) =>
    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"amenities_" + data.id} value={data.id} name="amenities" className={(amenities?.includes(data.id)) ? "active" : ""}>{data.name}</li>)
  )

  return (<>
    <Prompt
        when={shouldBlockNavigation}
        message="your information will be lost do you want to leave this page?"
      />
    <div className="inner-wrapper cu-step-form-body">
      <div className="body-container">
        <div className="body-header">
          <h3>a few more standard details</h3>
          {!(currentPath === 'edit-listing') && <span className="save-link" onClick={() => onSubmit('save')}>Save</span>}
        </div>
        <Form>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Is This Property Furnished?</Form.Label>
              <ul className="select-options">
                {is_furnishedData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"is_furnished_" + data.value} value={data.value} name="is_furnished"
                    className={(is_furnished === data.value) ? "active" : ""}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>What Is The Pet Policy?</Form.Label>
              <ul className="select-options">
                {pet_policyData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"pet_policy_" + data.value} value={data.value} name="pet_policy"
                    className={(pet_policy === data.value) ? "active" : ""}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>

          {amenitiesView && amenitiesView.length > 0 && <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>What amenities are included? (Select all that apply)</Form.Label>
              <ul className="select-options">
                {amenitiesView}
              </ul>
            </Form.Group>
          </Row>}

          <Row>
            <Form.Group as={Col} md="4">
              <Form.Label>Is Parking Included?</Form.Label>
              <ul className="select-options">
                {is_parking_includedData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"is_parking_included_" + data.value} value={data.value} name="is_parking_included"
                    className={(is_parking_included === data.value) ? "active" : ""}>{data.title}</li>)}
              </ul>
            </Form.Group>
            {is_parking_included !== 0 &&
              <Form.Group as={Col} md="3">
                <Form.Label>How Many Parking Spots</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="1"
                  name="parking_slot"
                  min="1"
                  value={parking_slot}
                  onChange={(e) => OnChangeInput(e)} />
              </Form.Group>}
          </Row>

          {utilitiesView && utilitiesView.length > 0 && <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Utilities Included (Select All That Apply)</Form.Label>
              <ul className="select-options">
                {utilitiesView}
              </ul>
            </Form.Group>
          </Row>}

          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Laundry</Form.Label>
              <ul className="select-options">
                {laundryData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"laundry_" + data.value} value={data.value} name="laundry"
                    className={(laundry === data.value) ? "active" : ""}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>

        </Form>
      </div>
    </div>

    <div className="container-footer cu-step-form-footer">
      {currentPath === 'edit-listing' ?
        <>
          <Button variant="secondary" onClick={() => backToPrevPage()}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('save')}>Save</Button>
        </> :
        <>
          <Button variant="secondary" onClick={() => dispatch(getCurrentPage(1))}>Back</Button>
          <Button data-description="Landlord entered additional property details" id="BTN-listing-step-3-complete" variant="success" onClick={() => onSubmit('next')}>Next</Button>
        </>
      }
    </div>
  </>);
}

export default StepThree;
