import React from 'react'
import { Form, Row, Col, Image } from 'react-bootstrap';
import { leaseTypeDisplay, rentPaidDisplay, parkingTypeDisplay } from '../../../../../Constants/LeaseConstants';
import { EditButton, DollorNumberDisplay } from '../../../../components';
import { dateFormat3 } from '../../../../../Utils/DateSanitizer/DateSanitizer';
import Moment from 'react-moment';

const LeaseInfoCompleteDetails = props => {
    const {setCurrentStep, leaseDetailsData, viewAppendix, maxLength, otherDetails} = props;

    return (
        <>
            <div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>lease details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					<Row>
						{(leaseDetailsData) &&
							<>
								<Form.Group as={Col} md='2'>
									<Form.Label>Lease Type</Form.Label>
									<span>{(leaseDetailsData.lease_type !== null && leaseDetailsData.lease_type !== "") ? leaseTypeDisplay(leaseDetailsData.lease_type) : '--'}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Start  Date</Form.Label>
									<span>{leaseDetailsData.lease_start_date && <Moment format="MMM D, YYYY">{leaseDetailsData.lease_start_date}</Moment>}</span>
								</Form.Group>
								{leaseDetailsData.lease_type !== 2 &&
									<Form.Group as={Col} md='2'>
										<Form.Label>End Date</Form.Label>
										<span>{leaseDetailsData.lease_end_date && <Moment format="MMM D, YYYY">{leaseDetailsData.lease_end_date}</Moment>}</span>
									</Form.Group>
								}

								<Form.Group as={Col} md={(leaseDetailsData.tenancy_agreement === 1) ? 2 : 4}>
									<Form.Label>{(leaseDetailsData.tenancy_agreement === 1) ? "Additional Terms" : "Attached Terms"}</Form.Label>
									{(leaseDetailsData.tenancy_agreement === 1) ?
										<span>Yes</span> : (
											!!leaseDetailsData.rent_panda_lease_appendix ?
												<span onClick={() => viewAppendix()} className="view-appendix cursorPointer pdf-title">
													<Image className="icon" width='25' src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
													{leaseDetailsData.rent_panda_lease_appendix && <>
														{leaseDetailsData.rent_panda_lease_appendix.split('/').pop().length > maxLength ? leaseDetailsData.rent_panda_lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseDetailsData.rent_panda_lease_appendix.split('/').pop()}
													</>}
												</span> :
												<span>No</span>)
									}
								</Form.Group>

								{(leaseDetailsData.tenancy_agreement === 1) &&
									<Form.Group as={Col} md='4'>
										<Form.Label>Attached Terms</Form.Label>
										<span onClick={() => viewAppendix()} className="view-appendix cursorPointer pdf-title">
											<Image className="icon" width='25' src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
											{leaseDetailsData.lease_appendix && <>
												{leaseDetailsData.lease_appendix.split('/').pop().length > maxLength ? leaseDetailsData.lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseDetailsData.lease_appendix.split('/').pop()}
											</>}
										</span>
									</Form.Group>}
							</>}
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>more details about the rent</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{(leaseDetailsData !== null) &&
						<>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Rent is to be paid on:</Form.Label>
									<span>{(leaseDetailsData.rent_paid_on !== null || leaseDetailsData.rent_paid_on !== '') ? leaseDetailsData.rent_paid_on : '--'}</span>
								</Form.Group>
								<Form.Group as={Col} md='3'>
									<Form.Label>Base rent for the rental property</Form.Label>
									<span>{(leaseDetailsData.rent !== null || leaseDetailsData.rent !== '') ?
										<DollorNumberDisplay price={leaseDetailsData.rent} />
										: '--'}</span>
								</Form.Group>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is parking available?</Form.Label>
									<span>{leaseDetailsData.is_parking_available === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{leaseDetailsData.is_parking_available === 1 &&
									<>
										<Form.Group as={Col} md='2' className='pr-0 pl-0'>
											<Form.Label>Parking Type</Form.Label>
											<span>{parkingTypeDisplay(leaseDetailsData.parking_type)}</span>
										</Form.Group>
										<Form.Group as={Col} md='1'>
											<Form.Label># of spots</Form.Label>
											<span>{(leaseDetailsData.parking_spot !== null) ? leaseDetailsData.parking_spot : '--'}</span>
										</Form.Group>
										<Form.Group as={Col} md='2'>
											<Form.Label>Price for parkings</Form.Label>
											<span>{(leaseDetailsData.parking_price !== null) ?
												<DollorNumberDisplay price={leaseDetailsData.parking_price} />
												: '--'}</span>
										</Form.Group>
									</>
								}
								<Form.Group as={Col} md='2'>
									<Form.Label>Rent is payable to:</Form.Label>
									<span>{(leaseDetailsData.rent_payable_to !== null || leaseDetailsData.rent_payable_to !== '') ? leaseDetailsData.rent_payable_to : '--'}</span>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Payment Method</Form.Label>
									<span>{rentPaidDisplay(leaseDetailsData.rent_pay_method)}</span>
								</Form.Group>
								<Form.Group as={Col} md='4'>
									<Form.Label>Is the first rental period a partial period?</Form.Label>
									<span>{leaseDetailsData.first_rental_partial_period === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>

								{leaseDetailsData.first_rental_partial_period === 1 &&
									<>
										<Form.Group as={Col} md='3'>
											<Form.Label>Partial Period</Form.Label>
											<span>{dateFormat3(leaseDetailsData.partial_cover_start_date)} to {dateFormat3(leaseDetailsData.partial_cover_end_date)}</span>
										</Form.Group>
										<Form.Group as={Col} md='3'>
											<Form.Label>Partial Period Payment Amount</Form.Label>
											<span><DollorNumberDisplay price={leaseDetailsData.partial_cover_amount} />&nbsp;{'on'} {dateFormat3(leaseDetailsData.partial_payment_date)}</span>
										</Form.Group>
									</>}
							</Row>
							<Row>
								<Form.Group as={Col} md='6'>
									<Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
									<span>{leaseDetailsData.administrative_charge !== null ? <DollorNumberDisplay price={leaseDetailsData.administrative_charge} /> : '--'}</span>
								</Form.Group>
								{otherDetails &&
									<Form.Group as={Col} md='2'>
										<Form.Label>Is smoking allowed?</Form.Label>
										<span>{otherDetails.is_smoking_allowed === 1 ? 'Yes' : 'No'}</span>
									</Form.Group>}
							</Row>
						</>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>deposits and discounts</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{leaseDetailsData &&
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Is there a rent deposit?</Form.Label>
								<span>{leaseDetailsData.is_rent_deposit === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{leaseDetailsData.is_rent_deposit === 1 &&
								<Form.Group as={Col} md='4'>
									<Form.Label>Rent Deposit Amount</Form.Label>
									<DollorNumberDisplay price={leaseDetailsData.is_rent_deposit_amount} />
								</Form.Group>
							}
						</Row>}
					{otherDetails &&
						<>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is there a key deposit?</Form.Label>
									<span>{otherDetails.is_key_deposite === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{otherDetails.is_key_deposite === 1 &&
									<Form.Group as={Col} md='4'>
										<Form.Label>key Deposit amount (can only be amount to replace key)</Form.Label>
										<DollorNumberDisplay price={otherDetails.key_deposite_amount} />
									</Form.Group>
								}
							</Row>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is there a rent discount</Form.Label>
									<span>{otherDetails.is_rent_discounted === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{otherDetails.is_rent_discounted === 1 &&
									<>
										<Form.Group as={Col} md='2'>
											<Form.Label>Amount</Form.Label>
											<DollorNumberDisplay price={otherDetails.rent_discount_amount} />
										</Form.Group>
										<Form.Group as={Col} md='8'>
											<Form.Label>Rent Discount Description</Form.Label>
											<span>{otherDetails.rent_discount_description}</span>
										</Form.Group>
									</>
								}
							</Row>
						</>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>who pays for the utilities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Electricity</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_electricity_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Heat</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_heat_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Water</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_water_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>
					</Row>
				</div>
			</div>
        </>
    )
}

export default LeaseInfoCompleteDetails
