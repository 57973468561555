import React from "react";
import { withRouter } from "react-router-dom";
import "../style.scss";
import { BasicInfoDisplay, CurrentHomeInfoDisplay, PastHomeInfoDisplay, EmploymentInfoDisplay, PersonalReferencesInfoDisplay, PetsInfoDisplay, VehicleInfoDisplay, RoommateInfoDisplay } from '../../../components/TenantProfile/Display';

const FullProfile = (props) => {

    return (<>


        <div className="profile-container-box">
            <BasicInfoDisplay
                editaction={() => props.setBasicInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <CurrentHomeInfoDisplay
                editaction={() => props.setCurrentHomeInfo(true)}
                addaction={() => props.setCurrentHomeInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <PastHomeInfoDisplay
                editaction={() => props.setPastHomeInfo(true)}
                addaction={() => props.setPastHomeInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <EmploymentInfoDisplay
                editaction={() => props.setEmploymentInfo(true)}
                addaction={() => props.setEmploymentInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <PersonalReferencesInfoDisplay
                editaction={() => props.setPersonalReferencesInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <PetsInfoDisplay
                editaction={() => props.setPetsInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <VehicleInfoDisplay
                editaction={() => props.setVehicleInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />

            <RoommateInfoDisplay
                editaction={() => props.setRoommateInfo(true)}
                profileDetails={props.profileDetails}
                fromComponent="admin"
            />
        </div>
    </>);
}

export default withRouter(FullProfile);
