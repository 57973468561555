import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { LargeButton, CancelButton, SidePanel } from "../../../components";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { logger, toaster } from "../../../../Utils";

const log = logger("FinalCreateLease");
const FinalCreateLease = (props) => {
    const { isShow, handleClose, openSignLease, openConfirmation } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [lease_id, setLeaseId] = useState('');
    const reasonDeactivate = useSelector(state => state.deactiveReasonReducer.deactiveReason);
    log.info('reasonDeactivate', reasonDeactivate)
    const handleCloseConfirm = () => {
        handleClose();
        openSignLease(true);
    }
    const finalCreate = () => {
        const url = "/admin/listing/lease/create-lease";
        const data = {
            "lease_id": lease_id,
            "property_id": property_id,
            "property_detail_id": property_details_id,
        }
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                const url = "/admin/listing/deactive/" + property_details_id;
                const data = {
                    "deactivate_reason": reasonDeactivate && reasonDeactivate.order,
                    "deactivate_description": reasonDeactivate && reasonDeactivate.deactive_reason,
                    'is_active': 1
                }
                postAPI(url, data).then(res => {
                    if (res.status === 200) {
                        handleClose();
                        openConfirmation(true)
                    } else if (res.response.status === 400) {
                        log.info(res.response)
                        toaster('error', res.response.data.message);
                    } else if (res.response.status === 422) {
                        toaster('error', res.response.data.message);
                    }
                })
            }
        })
    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setLeaseId(props.match.params.lease_id);
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="CREATE A LEASE">
            <h4 className="edit-sub">Sign your  lease</h4>
            <div className="sidepanel-body">
                <Row>

                </Row>
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                <LargeButton title='create lease' onClick={() => finalCreate()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(FinalCreateLease);