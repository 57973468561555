import React from "react";
import "./style.scss";

function CityPages(props) {
 const { cityData } = props;
 var cityImages = {
  Toronto:
   "https://rentpanda.ca/static/media/toronto.677d3623.jpg",
  "Thunder Bay":
   "https://rentpanda.ca/static/media/thunderBay.3713e680.jpg",
  Guelph:
   "https://rentpanda.ca/static/media/guelph.0d68d522.jpg",
  Hamilton:
   "https://rentpanda.ca/static/media/hamilton.e7d408f3.jpg",
  Brampton:
   "https://rentpanda.ca/static/media/hamilton.e7d408f3.jpg",
  Ottawa:
   "https://rentpanda.ca/static/media/ottawa.6f618a4e.jpg",
  Brantford:
   "https://rentpanda.ca/static/media/brantford.216fa476.jpg",
  Barrie:
   "https://rentpanda.ca/static/media/barrie.b3a54ecc.jpg",
  Cambridge:
   "https://rentpanda.ca/static/media/barrie.b3a54ecc.jpg",
  Mississauga:
   "https://rentpanda.ca/static/media/mississauga.d3e151a6.jpg",
  London:
   "https://rentpanda.ca/static/media/newCity.b60d20b9.jpg",
 };
 function createMarkup() {
  return {
   __html: cityData.description2
    .split("\\n")
    .join("<br><br>"),
  };
 }

 function getCityImage(cityName) {
  if (cityImages.hasOwnProperty(cityName)) {
   return cityImages[cityName];
  }
  return false;
 }

 return (
  <>
   <div className="cityPagesContainer">
    <div className="img-container">
     <img
      src={getCityImage(cityData.city)}
      alt="City Image"
      className="cityImg"
     />
     <h1 className="cityName">{cityData.city}</h1>
    </div>
    <div className="cityDesc">
     {cityData.description.replace(
      /'\n'/g,
      "<br>"
     )}
    </div>
    <h2 className="citySubHeading">
     popular neighbourhoods in {cityData.city}
    </h2>
    <div
     className="cityDesc"
     dangerouslySetInnerHTML={createMarkup()}
    />

    <h2 className="citySubHeading">
     average rent
    </h2>
    <ul className="cityRent">
     {/* <li>BACH/studio: $1,468</li> */}
     <li>1-bedroom: ${cityData.oneBrRent}</li>
     <li>2-bedroom: ${cityData.twoBrRent}</li>
     <li>3-bedroom: ${cityData.threeBrRent}</li>
     <li>
      The average rent in {cityData.city} is $
      {cityData.averageRentInTheCity}
     </li>
    </ul>
    <h4 className="cmhc">rental pricing data is drawn directly from the 2021 cmhc rental market survey data.</h4>
   </div>
  </>
 );
}

export default CityPages;
