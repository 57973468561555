import logger from "../Logger/Logger";

const log = logger("validate");

export const validate = (obj, e = null, isOnChangedApplied = false) => {
    let errors = [];

    const Form = document.getElementById('Form');
    if (!Form.getAttribute("ListenEnvent") && Form !== null) {
        Form.addEventListener("change", (event) => {
            validate(obj, event, true);
        });
        Form.addEventListener("keyup", (event) => {
            validate(obj, event, true);
        });
        Form.addEventListener("click", (event) => {
            validate(obj, event, true);
        });
        Form.setAttribute("ListenEnvent", "change");
    }

    for (const [name, arr] of Object.entries(obj)) {
        if (e !== null) {
            // log.info("Event == ", `${e.target.name}: ${e.target.value} : ${name}`);
        }
        // if (e !== null && e.target.name !== id) {
        //     return false;
        // }

        let eles = document.getElementsByName(name);
        // if (errorElement && errorElement !== null) {
        //     errorElement.remove();
        // }
        console.log(eles)
        const errClass = "is-invalid";
        if (eles === null) {
            return false;
        }

        for (let j = 0; j < eles.length; j++) {

            let ele = eles[j];

            const id = ele.getAttribute("id");
            const timeEle = document.getElementById('timepickerwrapper');

            if (ele.className.includes('timepicker-container') && ele.value === 'Type or select') {
                timeEle.classList.add(errClass)
            }


            if (id !== null) {
                log.info("Ele Id ", name, id);
                let errorElement = document.getElementById('error_' + id);

                if (errorElement === null) {

                    if (ele.className.includes('timepicker-container') && ele.value === 'Type or select') {
                        timeEle.insertAdjacentHTML('afterend', '<div id="error_' + id + '" class="invalid-feedback"></div>');
                    }
                    else {
                        ele.insertAdjacentHTML('afterend', '<div id="error_' + id + '" class="invalid-feedback"></div>');
                    }
                    errorElement = document.getElementById('error_' + id);

                }
                if (errorElement === null) {
                    return false;
                }
                arr.map((obj) => {
                    if (!errors.includes(name)) {
                        log.info("ele", ele.value);
                        switch (obj.rule) {
                            case "required":
                                if (ele.className.includes('timepicker-container') && ele.value === 'Type or select') {
                                    const timeEle = document.getElementById('timepickerwrapper');

                                    let classNames = timeEle.className.split(" ");

                                    if (classNames.indexOf(errClass) === -1) {
                                        timeEle.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                }

                                if (ele.value === undefined || ele.value.trim() === "" || ele.value === null) {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                } else {
                                    if (errorElement !== null) {
                                        errorElement.innerHTML = "";
                                        let classNames = ele.className.split(" ");
                                        let errorIndex = classNames.indexOf(errClass);
                                        if (errorIndex !== -1) {
                                            classNames.splice(errorIndex, 1);
                                            ele.className = classNames.join(" ");
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "maxLength":

                                if (ele.value.length > obj.maxLength) {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                } else {
                                    if (errorElement !== null) {
                                        errorElement.innerHTML = "";
                                        let classNames = ele.className.split(" ");
                                        let errorIndex = classNames.indexOf(errClass);
                                        if (errorIndex !== -1) {
                                            classNames.splice(errorIndex, 1);
                                            ele.className = classNames.join(" ");
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "decValue":

                                if (ele.value.includes('.') && ele.value.split('.')[0].length > obj.maxLength) {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                } else if (!ele.value.includes('.') && ele.value.length > obj.maxLength) {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                }
                                else {
                                    if (errorElement !== null) {
                                        errorElement.innerHTML = "";
                                        let classNames = ele.className.split(" ");
                                        let errorIndex = classNames.indexOf(errClass);
                                        if (errorIndex !== -1) {
                                            classNames.splice(errorIndex, 1);
                                            ele.className = classNames.join(" ");
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "email":
                                let emailArray = ele.value.split(",");
                                let isValid = true;
                                emailArray.forEach(email => {
                                    const trimEmail = email.trim();
                                    if(isValid){
                                        if (trimEmail.length > 0) {
                                            if (!validateEmail(trimEmail)) {
                                                isValid=false;
                                                let classNames = ele.className.split(" ");
                                                if (classNames.indexOf(errClass) === -1) {
                                                    ele.className += " " + errClass;
                                                }
                                                errorElement.innerHTML = obj.message;
                                                errors.push(name);
                                                return false;
                                            } else {
                                                if (errorElement !== null) {
                                                    errorElement.innerHTML = "";
                                                    let classNames = ele.className.split(" ");
                                                    let errorIndex = classNames.indexOf(errClass);
                                                    if (errorIndex !== -1) {
                                                        classNames.splice(errorIndex, 1);
                                                        ele.className = classNames.join(" ");
                                                    }
                                                }
                                                return true;
                                            }
                                        }
                                    }
                                });
                                break;
                            case "phone":
                                  
                                let phone = ele.value.replace(/\D+/g, '')
                                if(obj.isEmpty && !phone?.length)
                                {
                                    return true
                                }
                                if (phone.length < obj.length) {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                } else {
                                    if (errorElement !== null) {
                                        errorElement.innerHTML = "";
                                        let classNames = ele.className.split(" ");
                                        let errorIndex = classNames.indexOf(errClass);
                                        if (errorIndex !== -1) {
                                            classNames.splice(errorIndex, 1);
                                            ele.className = classNames.join(" ");
                                        }
                                        return true;
                                    }
                                }
                                break;

                            case "alphanumeric":
                                if (ele.value.length > 0) {
                                    if (!alphanumeric(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "min":
                                 const value=ele.value.replaceAll('-','').replaceAll('_','');
                                if (value.length > 0) {
                                     if (!min(value, obj.min)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "minOneUppercase":
                                if (ele.value.length > 0) {
                                    if (!atleastOneCap(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML += obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "minOneNumber":
                                if (ele.value.length > 0) {
                                    if (!atleastOneNumber(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "sinNumber":
                                if (ele.value.length > 0) {
                                    if (ele.value.length !== 9) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "compare":
                                if (ele.value.length > 0) {
                                    if (!compareExternalValue(ele.value, obj.to)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "fullName":
                                if (ele.value.length > 0) {
                                    const spaceCount = ele.value.trim().split(' ').length - 1
                                    if (spaceCount < 1) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;

                            default:
                                return true;
                        }
                    }

                    return obj;

                })
            }

        }


    }
    log.info("Error :", errors);
    if (errors.length > 0 && !isOnChangedApplied) {
        if (document.getElementById(errors[0])) {

            document.getElementById(errors[0]).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        return false;
    } else {
        return true;
    }

}

const min = (inputtxt, minValue) => {
    return inputtxt.length >= minValue;
}

const compareExternalValue = (inputtxt, to) => {
    let extValue = document.getElementById(to).value;
    return inputtxt === extValue;
}

const atleastOneCap = (inputtxt) => {
    return inputtxt.search(/[A-Z]/) !== -1;
}

const atleastOneNumber = (inputtxt) => {
    return inputtxt.search(/[0-9]/) !== -1;
}

const validateEmail = (email) => {
    // const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const re = /^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return re.test(String(email).toLowerCase());
}

const alphanumeric = (inputtxt) => {
    var letterNumber = /^[0-9a-zA-Z\s]+$/;
    if (inputtxt.match(letterNumber)) {
        return true;
    } else {
        return false;
    }
}