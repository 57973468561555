export const SET_COMPONENT              = 'SET_COMPONENT';
// export const SET_CURRENT_PAGE           = 'SET_CURRENT_PAGE';
export const SET_FILTERS                = 'SET_FILTERS';
export const SET_LISTING_TABLEDATA      = 'SET_LISTING_TABLEDATA';
export const SET_RENTED_TABLEDATA       = 'SET_RENTED_TABLEDATA';
export const SET_PROMOTED_TABLEDATA     = 'SET_PROMOTED_TABLEDATA';
export const SET_INACTIVE_TABLEDATA     = 'SET_INACTIVE_TABLEDATA';
export const SET_LISTING_CURRENT_PAGE   = 'SET_LISTING_CURRENT_PAGE';
export const SET_RENTED_CURRENT_PAGE    = 'SET_RENTED_CURRENT_PAGE';
export const SET_PROMOTED_CURRENT_PAGE  = 'SET_PROMOTED_CURRENT_PAGE';
export const SET_INACTIVE_CURRENT_PAGE  = 'SET_INACTIVE_CURRENT_PAGE';

export function setComponent(data) {
    return function (dispatch) {
        dispatch({ type: SET_COMPONENT, currentComponent: data });
    };
}

export function setFilters(data) {
    return function (dispatch, getState) {
        dispatch({ type: SET_FILTERS, filters: data });
        const state = getState();
        if (state.listingReducer.currentComponent === 'all-listing') {
            dispatch(setListingCurrentPage(1));
        } else if (state.listingReducer.currentComponent === 'rented') {
            dispatch(setRentedCurrentPage(1));
        } else if (state.listingReducer.currentComponent === 'promoted') {
            return dispatch(setPromotedCurrentPage(1));
        } else if (state.listingReducer.currentComponent === 'inactive') {
            dispatch(setInactiveCurrentPage(1));
        }
    };
}

export function setListingTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_LISTING_TABLEDATA, listingTableData: data });
    };
}

export function setRentedTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_RENTED_TABLEDATA, rentedTableData: data });
    };
}

export function setPromotedTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_PROMOTED_TABLEDATA, promotedTableData: data });
    };
}

export function setInactiveTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_TABLEDATA, inactiveTableData: data });
    };
}

export function setListingCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_LISTING_CURRENT_PAGE, listingCurrentPage: data });
    };
}

export function setRentedCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_RENTED_CURRENT_PAGE, rentedCurrentPage: data });
    };
}

export function setPromotedCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_PROMOTED_CURRENT_PAGE, promotedCurrentPage: data });
    };
}

export function setInactiveCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_CURRENT_PAGE, inactiveCurrentPage: data });
    };
}

// export function setCurrentPage(data) {
//     return function (dispatch, getState) {
//         const state = getState();
//         if (state.listingReducer.currentComponent === 'all-listing') {
//             dispatch(setListingCurrentPage(data));
//         } else if (state.listingReducer.currentComponent === 'rented') {
//             dispatch(setRentedCurrentPage(data));
//         } else if (state.listingReducer.currentComponent === 'promoted') {
//             return dispatch(setPromotedCurrentPage(data));
//         } else if (state.listingReducer.currentComponent === 'inactive') {
//             dispatch(setInactiveCurrentPage(data));
//         }
//         dispatch({ type: SET_CURRENT_PAGE, getCurrentPage: data });
//     };
// }
