import React from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { AppLayout, PageTitleHeader, LargeButton } from "../../../components";

const ListManagementPropertyDetails = (props) => {

	const backToDashboard = () => {
		props.history.push('/listing-management/all-listing');
	}

	return (
		<AppLayout>
			<div className="profile">
				<PageTitleHeader
					title='property details'

					isArrow={true}
					onClick={() => backToDashboard()}
				/>
				<div className="container-detail">
					<Row>
						<Col md="12" className="card-detail">
							<div className="detail-left">
								<span className="user">Landlord</span>
								<span className="user-name">Cody Fisher</span>
								<span className="user-contact-title"><img className="user-icon" src={require('../../../../_assets/images/mobile-icon.svg')} alt="mobile-icon" />
                                Phone: <span className="title-text">647-420-420</span>
								</span>
								<span className="user-contact-title"><img className="user-icon" src={require('../../../../_assets/images/message-icon.svg')} alt="message-icon" />
                                Email: <span className="title-text">thecodyfisher@gmail.com</span>
								</span>
                                ID: <span className="title-text">None</span>
							</div>
							<div className="detail-right">
								<LargeButton className="suspend-btn" variant={'dark'} title="suspend user" style={{ width: '177px', float: 'right' }} />
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</AppLayout>
	)
};

export default withRouter(ListManagementPropertyDetails);