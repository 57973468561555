import React from 'react'
import { Col, Form, Image } from 'react-bootstrap'
import { AutoCompleteAddress, NavbarSortingDropdown } from '../../components'

function SearchListHeader(props) {
    const { searchLocation,
        sortOptions,
        handleMapVisibility,
        isDesktop,
        isMapVisible,
        handleChange,
        handlePlaceChanged,
        filter,
        handleDropdownSelect,
        sortName } = props
    return (
        <div className="profile-header">
            <div className="row headRow mt-4">
                <Col md='4' xs='12' className={(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-block pr-10'}>
                    <Form.Group className="display-flex-inline-elm search-elm">
                        <AutoCompleteAddress
                            className="searchBar"
                            placeholder="Type to search location"
                            id="searchLocation"
                            state={searchLocation === null ? "" : searchLocation}
                            updateField={(data) => handleChange(data)}
                            handlePlaceChanged={(data) => handlePlaceChanged(data)}
                            selectPlaceOnEnter
                        />
                    </Form.Group>
                </Col>
                {/* Visible only on dekstop */}
                <Col md='1' xs='2' className="pl-0 pr-0 prl-10 text-center d-none d-sm-block">
                    <div className="sorting cursorPointer" onClick={() => filter()}>
                        <img className="img-reponsive t-sort" src={require("../../../_assets/images/sorting-icon.svg")} alt="sort-btn" />
                    </div>
                </Col>
                {/* end Visible only on dekstop */}
                <Col md='5' lg='4' xs='8' className={`pl-0 pr-0 prl-10 ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-block'}`}>
                    <NavbarSortingDropdown handleDropdownSelect={(data, key) => handleDropdownSelect(data, key)} sortOptions={sortOptions} sortName={sortName} className="pl-mb-10" />
                </Col>
                {/* hide on dekstop */}
                <Col md='1' xs='2' className={`pl-0 pr-0 prl-10 text-center d-sm-none align-items-center ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-flex'}`}>
                    <div className="sorting cursorPointer" onClick={() => filter()}>
                        <img className="img-reponsive t-sort" src={require("../../../_assets/images/sorting-icon.svg")} alt="sort-btn" />
                    </div>
                </Col>
                <Col md='1' xs='2' className={`pl-0 pr-0 prl-10 text-center d-sm-none align-items-center ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-flex'}`}>
                    <div className="sorting cursorPointer">
                        <Image
                            className="img-reponsive t-sort"
                            src={require("../../../_assets/images/map-icon.png")}
                            alt="map-btn"
                            onClick={() => handleMapVisibility(true)}
                        />
                    </div>
                </Col>
                {/* end hide on dekstop */}

            </div>
        </div>
    )
}

export default SearchListHeader
