import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppLayout, Loader, PageTitleHeader, PDFViewerComponent } from '../../../components';
import { getAPI } from '../../../../Api';
import { logger } from '../../../../Utils';

const log = logger('ViewAppendixLandlord');
const ViewAppendixLandlord = (props) => {
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [lease_id, setLeaseId] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);

    const back = () => {
        props.history.push({
            pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
            state: {
                lease_id: lease_id,
                step: 3
            }
        });
    }

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setLeaseId(props.match.params.lease_id);
        setPdfUrl(props.location.state.pdfUrl);
    }, [props]);


    return (
        <AppLayout>
            <div >
                <PageTitleHeader
                    isArrow={true}
                    onClick={() => back()}
                    title={'lease appendix'}
                />
            </div>

            {pdfUrl ?
                <PDFViewerComponent PDFUrl={pdfUrl} />
                : <Loader />
            }
        </AppLayout>
    );
}
export default withRouter(ViewAppendixLandlord);