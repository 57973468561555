export const N12Schema = {
    terminationDate: [
        { rule: "required", message: "Date required" },
    ],   
    firstName: [
        { rule: "required", message: "first name required" },
    ], 
    lastName: [
        { rule: "required", message: "last name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}