export const StepOneValidationSchema = {
    address: [
        { rule: "required", message: "Address required" },
    ],
    address_2: [
        { rule: "required", message: "Address required" },
    ],
    property_type: [
        { rule: "required", message: "Property type required" },
    ],
    unit_no: [
        { rule: "required", message: "Unit number required" },
    ]
}