import React from 'react'
import { Row, Form, Col } from 'react-bootstrap';
import AutoCompleteAddress from '../../../AutoCompleteAddress';

function MailingAddress({ cols=[],id='mailing_current_address',name='mailing_current_address', displayTitle, contactInfo, handlePlaceChanged, setSaveEnable, updateState,header='landlord mailing address' }) {
     return (
        <div>
            <h3 className='text-left mt-4'>{header}</h3>
            <Row>
                <Form.Group as={Col} md={cols[0] || '8'}>
                     <AutoCompleteAddress
                        md='12'
                        as={Col}
                        id={id}
                        labelFont={16}
                        name={name}
                        state={contactInfo?.mailing_address}
                        updateField={(data) => updateState(data, 'mailing_address')}
                        handlePlaceChanged={(data) => handlePlaceChanged(data, 'mailing')}
                        addressTitle={displayTitle || 'Address'}
                    />
                 </Form.Group>
                {/* <Form.Group as={Col} md='2'>
                    <Form.Label>Unit</Form.Label>
                    <Form.Control
                        type='text'
                        name='mailing_unit_no'
                        id='mailing_unit_no'
                        value={contactInfo?.mailing_unit_no}
                        placeholder='Type here'
                        onChange={(e) => { setSaveEnable(true); updateState(e, 'mailing_unit_no'); }}
                        maxLength={15}
                    />
                </Form.Group> */}
            </Row>
            <Row>
                <Form.Group as={Col} md={cols[1] || '4'}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type='text'
                        name='mailing_city'
                        id='mailing_city'
                        value={contactInfo?.mailing_city || ''}
                        placeholder='City'
                        onChange={(e) => updateState(e,'mailing_city')}
                        // readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md={cols[2] || '2'}>
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                        type='text'
                        name='mailing_province'
                        id='mailing_province'
                        value={contactInfo?.mailing_province || ''}
                        placeholder='Province'
                        onChange={(e) => updateState(e, 'mailing_province')}
                        // readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md={cols[3] ||'2'}  >
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                        type='text'
                        name='mailing_postal_code'
                        id='mailing_postal_code'
                        value={contactInfo?.mailing_postal_code || ''}
                        placeholder='Postal Code'
                        onChange={(e) => updateState(e, 'mailing_postal_code')}
                        readOnly={false}
                    />
                </Form.Group>
            </Row>
        </div>
    )
}

export default MailingAddress
