import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {SingleOptionCheck} from '../../../'
import { utilitiesPaidBy } from '../../../../../Constants/LeaseConstants'

const PayUtilities = props => {

    const {OnSingleOptionCheck, leaseInfo} = props;

    return (
        <>
            <h3 className='text-left lease-heading'>who pays for utilities?</h3>
            <Row>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Electricity</Form.Label>
                    <SingleOptionCheck
                        mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_electricity_pay_by'} setParam={leaseInfo.utility_electricity_pay_by}
                    />
                </Form.Group>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Heat</Form.Label>
                    <SingleOptionCheck
                        mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_heat_pay_by'} setParam={leaseInfo.utility_heat_pay_by}
                    />
                </Form.Group>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Water</Form.Label>
                    <SingleOptionCheck
                        mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_water_pay_by'} setParam={leaseInfo.utility_water_pay_by}
                    />
                </Form.Group>
            </Row>
        </>
    )
}

export default PayUtilities
