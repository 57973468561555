import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { postAPI } from "../../../Api";
import { logger } from "../../../Utils";
import { LargeButton, CancelButton, ModalBox, SidePanel, SearchInput } from "../../components";

const log = logger("SearchTenentList");
const SearchTenentList = (props) => {
    const { isShow, handleClose, property_details_id, property_id, apiCallback, callAddTenant, openCreateLeaseOption, setOpenDeactivate, singleReasonData, setAddTenant } = props;

    const [tenantList, setRentpandaTenant] = useState([]);
    const [openForOrder1Confirm, setOrder1Confirm] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedData, setSelectedData] = useState(null);

    const toggleConfirm = () => {
        setOrder1Confirm(!openForOrder1Confirm)
    }
    const handleCloseConfirm = () => {
        apiCallback();
        handleClose();
    }
    const onValueChange = (event, data) => {
        setSelectedOption(event.target.value);
        setSelectedData(data);
    }
    const reasonTwoNext = () => {
        const url = "/landlord/listing/lease/confirm-tenant";
        const data = [
            {
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "tenant_id": selectedData.id,
            }
        ];
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                handleClose();
                setAddTenant(true);
            }
        })
    }
    const addtenant = () => {
        handleClose();
        setAddTenant(true);
    }
    useEffect(() => {
        if (searchValue !== null) {
            const timeout = setTimeout(() => {
                let url = '/landlord/users/get-tenants';
                const data = {
                    "search": searchValue
                }
                postAPI(url, data).then(res => {
                    if (res.status === 200) {
                        setRentpandaTenant(res.data.data)
                    } else {
                        setRentpandaTenant([])
                    }
                })
            }, 1000)
            return () => clearTimeout(timeout)
        }
    }, [searchValue])

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose("callBackApi")}
            title="Found a tenant on rent panda">
            <h4 className="edit-sub">Select tenant from landlord’s contact list</h4>
            <div className="deactive-reasons sidepanel-body">
                <SearchInput extraCalss={"mb-3"} searchValue={searchValue} placeHolder={"Type to search tenant"} setSearchValue={(data) => setSearchValue(data)} />
                {tenantList && tenantList.map((data, i) => {
                    return <Row className="p-3 border-reason custom-checkbox-styling" key={"tenant" + i}>
                        <Col md={12} className="pl-0 pr-0">
                            <div className="form-check">
                                <input className="form-check-input" type="radio"
                                    name="exampleRadios"
                                    id={"tenantName" + i}
                                    value={data.name}
                                    checked={selectedOption === data.name}
                                    onChange={(e) => onValueChange(e, data)}
                                />
                                <label className="form-check-label" htmlFor={"tenantName" + i}>
                                    {data.name}
                                </label>
                            </div>
                        </Col>
                    </Row>
                })}
                {(tenantList && tenantList.length === 0) && <h4 className="edit-sub text-center">There is no tenant found</h4>}

                <LargeButton isIcon={true} title='Add tenant outside of Rent Panda' variant="dark" onClick={() => addtenant()} />
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                <LargeButton title='next' onClick={() => reasonTwoNext()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
        <ModalBox
            show={openForOrder1Confirm}
            onHide={() => toggleConfirm()}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => toggleConfirm()}
        >
            <p className="deactive-confirm">This Listing has been deactivated </p>
        </ModalBox>
    </>);
}
export default SearchTenentList;