import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Image } from 'react-bootstrap';
import {
  // amenitiesData,
  // utilitiesData,
  laundryForHouseData,
  laundryForUnitData,
  getUtilitiesAndAmenities
} from '../../../../Constants/propertyDetails';
import EditButton from '../../Buttons/EditButton';
import Cookies from 'js-cookie';
import { furnishedDataTitle, laundryDataTitle, parkingIncludeTitle, petAndPolicyTitle } from '../../../../Utils/PropertyDetailsData/propertyDetailsReturn';
import { useSelector } from 'react-redux';
import './style.scss';

const UnitDetails = (props) => {
  const { editDetails } = props;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const laundryData = (propertyDetails && propertyDetails.property_type === 1) ? laundryForHouseData : laundryForUnitData;
  const role = Number(Cookies.get('role'));

  const [amenitiesData, setAmenitiesData] = useState([]);
  const [utilitiesData, setUtilitiesData] = useState([]);
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  useEffect(() => {
    if (history.location.pathname.indexOf("tour") > 0) {
      setIsTour(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    (async () => {
      const { utilitiesData = [], amenitiesData = [] } = await getUtilitiesAndAmenities()
      setAmenitiesData(amenitiesData)
      setUtilitiesData(utilitiesData)
    })()
  }, [])

  const amenities = (role === 1 || role === 2) && amenitiesData && amenitiesData.length > 0 && amenitiesData.map((data) => {
    let title = ''
    if (unitDetails && unitDetails?.amenities?.includes(data.id)) {
      title = data.name
    }
    return title
  }).filter((el) => {
    return el !== '' && el !== null && el !== undefined
  }).join(', ')

  const utilities = (role === 1 || role === 2) && utilitiesData && utilitiesData.length > 0 && utilitiesData.map((data) => {
    let title = ''
    if (unitDetails && unitDetails?.utilities?.includes(data.id)) {
      title = data.name
    }
    return title
  }).filter((el) => {
    return el !== '' && el !== null && el !== undefined
  }).join(', ')

  const amenitiesTenant = propertyDetails?.amenities?.length > 0 && propertyDetails.amenities.map((data) => {
    return data
  }).filter((el) => {
    return el !== null && el !== undefined && el !== ''
  }).join(', ')

  const utilitiesTenant = propertyDetails?.utilities?.length > 0 && propertyDetails.utilities.map((data) => {
    return data
  }).filter((el) => {
    return el !== null && el !== undefined && el !== ''
  }).join(', ')

  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <h6>Unit Details</h6>
      {(role === 2 || role === 1) &&
        <>
          {(editDetails && role === 1) &&
            <div className='button-wrapper for-admin'>
              <EditButton onClick={editDetails} />
            </div>}
          {unitDetails ?
            <>
              <ul className='details'>
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/furnished.svg')} alt='furnished' />
                  {unitDetails && furnishedDataTitle(unitDetails.is_furnished)}
                </li>
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/pet-policy.svg')} alt='pet' />
                  {unitDetails && petAndPolicyTitle(unitDetails.pet_policy)}
                </li>
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/parking.svg')} alt='parking' />
                  {unitDetails && parkingIncludeTitle(unitDetails.is_parking_included, unitDetails.parking_slot)}
                </li>
                {utilities ?
                  <li>
                    <Image className='icon' src={require('../../../../_assets/images/details/utility.svg')} alt='utility' />
                Utilities Included (
                  {utilities}
                  )
                </li> : ''}

                {unitDetails.laundry !== 0 ?
                  <li>
                    <Image className='icon' src={require('../../../../_assets/images/details/laundry.svg')} alt='laundry' />
                    {unitDetails && laundryDataTitle(laundryData, unitDetails.laundry)}
                  </li> : ''}

                {amenities ?
                  <li>
                    <Image className='icon' src={require('../../../../_assets/images/details/aminities.svg')} alt='aminities' />
                  Amenities (
                   {amenities}
                  )
                </li> : ''}
              </ul> </> : (!isTour ? <h6>click edit to include a details here</h6> : '')}
          {(editDetails && role === 2) &&
            <div className='button-wrapper'>
              <EditButton onClick={editDetails} />
            </div>}
        </>}

      {(!role || role === 3 || (role === 2 && isTour)) &&
        <>
          {propertyDetails ?
            <ul className='details'>
              <li>
                <Image className='icon' src={require('../../../../_assets/images/details/furnished.svg')} alt='furnished' />
                {propertyDetails && furnishedDataTitle(propertyDetails.is_furnished)}
              </li>
              <li>
                <Image className='icon' src={require('../../../../_assets/images/details/pet-policy.svg')} alt='pet' />
                {propertyDetails && petAndPolicyTitle(propertyDetails.pet_policy)}
              </li>
              <li>
                <Image className='icon' src={require('../../../../_assets/images/details/parking.svg')} alt='parking' />
                {propertyDetails && parkingIncludeTitle(propertyDetails.is_parking_included, propertyDetails.parking_slot)}
              </li>

              {(propertyDetails && propertyDetails.utilities && propertyDetails.utilities.length > 0 && utilitiesTenant)?
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/utility.svg')} alt='utility' />
                Utilities Included ({utilitiesTenant})
              </li> : ''}

              {propertyDetails.laundry !== 0 ?
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/laundry.svg')} alt='laundry' />
                  {propertyDetails && propertyDetails.formatted_laundry} {/* {laundryDataTitle(laundryData, propertyDetails.laundry)} */}
                </li> : ''}

              {propertyDetails && propertyDetails.amenities && propertyDetails.amenities.length > 0 && amenitiesTenant ?
                <li>
                  <Image className='icon' src={require('../../../../_assets/images/details/aminities.svg')} alt='aminities' />
                  Amenities (
                    {amenitiesTenant}
                  )
                </li> : ''}
            </ul> : ''}
        </>}
    </div>
  );
}
export default UnitDetails;