import { getAPI } from '../../../../Api';

const getLeaseDetails = async(lease_id,user_id) => {
    const url = `/admin/users/lease/details/${lease_id}/${user_id}`;
    try{
        const data = await getAPI(url);
        return data.data.data;
        }
    catch(error){
        return { response: null, error: error.message };
    }
};

const userSuspend = async(user_id) => {
    const url = '/admin/users/suspend/' + user_id;
    try{
        const data = await getAPI(url);
        return data;
    }
    catch(error){
        return { response: null, error: error.message };
    }
}

const userActivate = async(user_id) => {
    const url = '/admin/users/activate/' + user_id;
    try{
        const data = await getAPI(url);
        return data;
    }
    catch(error){
        return { response: null, error: error.message };
    }
}

export {getLeaseDetails,userSuspend,userActivate};