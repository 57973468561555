import React, { useEffect, useState } from 'react';
import { Alert, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppLayout, PageTitleHeader, ModalBox } from '../../components';
import './style.scss';
import {
  BasicInfoDisplay,
  CurrentHomeInfoDisplay,
  PastHomeInfoDisplay,
  EmploymentInfoDisplay,
  PersonalReferencesInfoDisplay,
  PetsInfoDisplay,
  VehicleInfoDisplay,
  RoommateInfoDisplay,
} from '../../components/TenantProfile/Display';
import {
  BasicInfo,
  CurrentHomeInfo,
  PastHomeInfo,
  EmploymentInfo,
  PersonalReferencesInfo,
  PetsInfo,
  VehicleInfo,
  RoommateInfo,
} from '../../components/TenantProfile/ProfileInfo';
import { useHistory, withRouter } from 'react-router-dom';
import { logger } from '../../../Utils';
import { getAPI } from '../../../Api';
import { setUserProfile } from '../../../Store/actions/UserProfileAction';
import { updateRawData } from '../../../Store/actions/RawDataAction';
import {Button} from "react-bootstrap";

const log = logger('Profile');
const Profile = (props) => {
  const [profileDetails, setProfileDetails] = useState([]);
  const [basicInfo, setBasicInfo] = useState(false);
  const [currentHomeInfo, setCurrentHomeInfo] = useState(false);
  const [newCurrentHomeInfo, setNewCurrentHomeInfo] = useState(false);
  const [pastHomeInfo, setPastHomeInfo] = useState(false);
  const [employmentInfo, setEmploymentInfo] = useState(false);
  const [personalReferencesInfo, setPersonalReferencesInfo] = useState(false);
  const [petsInfo, setPetsInfo] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState(false);
  const [roommateInfo, setRoommateInfo] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const conversation_id = props?.location?.state?.conversation_id;
  const forCertn = props?.location?.state?.forCertn;
  const creditCheckRequestId = props?.location?.state?.creditCheckRequestId;
  const profileStatuses = useSelector(
    (state) => state.UserProfileReducer?.userProfile?.profile_statuses
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const deleteClose = () => setShow(false);
  const deleteShow = () => setShow(true);

  useEffect(() => {
    getProfileDetails();
  }, [
    basicInfo,
    currentHomeInfo,
    newCurrentHomeInfo,
    pastHomeInfo,
    employmentInfo,
    personalReferencesInfo,
    petsInfo,
    vehicleInfo,
    roommateInfo,
  ]);

  useEffect(() => {
    if (conversation_id) {
      updateRawData({ conversation_id, forCertn });
    }
    if (creditCheckRequestId) {
      updateRawData({ creditCheckRequestId });
    }
  }, [conversation_id, forCertn, creditCheckRequestId]);

  useEffect(() => {
    document.querySelectorAll('.invalid-feedback').forEach((e) => e.remove());
    document.querySelectorAll('.is-invalid').forEach((e) => {
      e.classList.remove('is-invalid');
    });

    document.querySelectorAll('.form-control').forEach((e) => {
      e.value = '';
    });
  }, [
    basicInfo,
    currentHomeInfo,
    newCurrentHomeInfo,
    pastHomeInfo,
    employmentInfo,
    personalReferencesInfo,
    petsInfo,
    vehicleInfo,
    roommateInfo,
  ]);

  const getProfileDetails = () => {
    const url = '/tenant/profile';
    getAPI(url)
      .then((response) => {
        if (response.status === 200) {
          // debugger
          const res = response.data.data;
          setProfileDetails(res);
          dispatch(setUserProfile(res));
          if (
            res?.basic_info?.is_profile_completed &&
            props?.location?.state?.redirect &&
            !props?.location?.state?.isEdit
          ) {
            props.history.push({ pathname: props.location.state.redirect });
          }
        }
      })
      .catch((error) => {});
  };

  const backToDashboard = () => {
    history.push(
      props?.location?.state?.redirect
        ? props.location.state.redirect
        : '/dashboard/my-listing'
    );
  };

  return (
    <>
      <AppLayout>
        <div className="profile-header">

        <PageTitleHeader
          title="my profile"
          isArrow={true}
          onClick={() => backToDashboard()}
        />
                        <div>
                        <Button onClick={deleteShow} className="btn-primary" id="delete-btn" data-description="delete button" gtmdetails="Tab 1"> delete user </Button> 
                        </div>

      </div>

        <div className="profile-container">
          <div className="profile-container-box">
            {profileDetails &&
              profileDetails.basic_info &&
              !profileDetails.basic_info.is_profile_completed  && (
                <Alert variant="warning" className="d-flex align-items-center">
                  <Image
                    className="icon mr-3"
                    src={require('../../../_assets/images/alert-icon.svg')}
                    alt="add"
                  />
                  Pro tip: Build your profile below so landlords can get to know
                  you, you don’t have to complete multiple applications and to
                  increase the chances of getting that perfect home!
                </Alert>
              )}

            <BasicInfoDisplay
              editaction={() => setBasicInfo(true)}
              profileDetails={profileDetails}
            />

            <CurrentHomeInfoDisplay
              editaction={() => setCurrentHomeInfo(true)}
              addaction={() => setModalShow(true)}
              profileDetails={profileDetails}
            />

            <PastHomeInfoDisplay
              editaction={() => setPastHomeInfo(true)}
              addaction={() => setPastHomeInfo(true)}
              profileDetails={profileDetails}
            />

            <EmploymentInfoDisplay
              editaction={() => setEmploymentInfo(true)}
              addaction={() => setEmploymentInfo(true)}
              profileDetails={profileDetails}
              isComplete={profileStatuses?.employment_info}
            />

            <PersonalReferencesInfoDisplay
              editaction={() => {
                setPersonalReferencesInfo(true);
                updateRawData({ isEdit: true });
              }}
              profileDetails={profileDetails}
            />

            <PetsInfoDisplay
              editaction={() => setPetsInfo(true)}
              profileDetails={profileDetails}
            />

            <VehicleInfoDisplay
              editaction={() => setVehicleInfo(true)}
              profileDetails={profileDetails}
            />

            <RoommateInfoDisplay
              editaction={() => setRoommateInfo(true)}
              profileDetails={profileDetails}
            />
          </div>
        </div>

        <ModalBox
        show={show}
        onHide={deleteClose}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
        setShow(false)
        }}
        >
                        <p>If you’d like your account and information deleted, please send an email to <strong>hello@rentpanda.ca</strong> with the subject line <i>“Delete My Account”</i> and your account will be deleted within 48 hours.</p>
     </ModalBox> 

      </AppLayout>

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        type="condition"
        actionbuttontitle={'Yes'}
        buttonaction={() => {
          setModalShow(false);
          setNewCurrentHomeInfo(true);
        }}
        backbuttontitle={'No'}
        backaction={() => {
          setModalShow(false);
        }}
      >
        <h4>are you sure you want to add new info</h4>
        <p>
          Your current home address will be converted to a past home address
        </p>
      </ModalBox>

      <ModalBox
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
          setSuccessModalShow(false);
        }}
      >
        <h4>your current home address has been updated</h4>
      </ModalBox>

      <BasicInfo
        isShow={basicInfo}
        basic_info={profileDetails && profileDetails.basic_info}
        current_home={profileDetails && profileDetails.current_home}
        handleClose={() => setBasicInfo(false)}
        setCurrentHomeInfo={(flag) => setCurrentHomeInfo(flag)}
      />

      {currentHomeInfo && (
        <CurrentHomeInfo
          isShow={currentHomeInfo}
          current_home={profileDetails && profileDetails.current_home}
          handleClose={() => setCurrentHomeInfo(false)}
          setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
          type={'edit'}
        />
      )}
      {newCurrentHomeInfo && (
        <CurrentHomeInfo
          isShow={newCurrentHomeInfo}
          current_home={''}
          handleClose={() => setNewCurrentHomeInfo(false)}
          setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
          newCurrentHomeInfo={newCurrentHomeInfo}
          setSuccessModalShow={(flag) => setSuccessModalShow(flag)}
          type={'add'}
        />
      )}
      <PastHomeInfo
        isShow={pastHomeInfo}
        id={profileDetails && profileDetails.id}
        past_home={profileDetails && profileDetails.past_home}
        handleClose={() => setPastHomeInfo(false)}
        setEmploymentInfo={(flag) => setEmploymentInfo(flag)}
      />

      <EmploymentInfo
        isShow={employmentInfo}
        id={profileDetails && profileDetails.id}
        employment_info={profileDetails && profileDetails.employment_info}
        handleClose={() => setEmploymentInfo(false)}
        setPersonalReferencesInfo={(flag) => setPersonalReferencesInfo(flag)}
      />

      <PersonalReferencesInfo
        isShow={personalReferencesInfo}
        id={profileDetails && profileDetails.id}
        personal_reference={profileDetails && profileDetails.personal_reference}
        handleClose={() => {
          setPersonalReferencesInfo(false);
          updateRawData({ isEdit: false });
        }}
        setPetsInfo={(flag) => setPetsInfo(flag)}
      />

      <PetsInfo
        isShow={petsInfo}
        id={profileDetails && profileDetails.id}
        pets_info={profileDetails && profileDetails.pets_info}
        handleClose={() => setPetsInfo(false)}
        setVehicleInfo={(flag) => setVehicleInfo(flag)}
      />

      <VehicleInfo
        isShow={vehicleInfo}
        id={profileDetails && profileDetails.id}
        vehicle_info={profileDetails && profileDetails.vehicle_info}
        handleClose={() => setVehicleInfo(false)}
        setRoommateInfo={(flag) => setRoommateInfo(flag)}
      />

      <RoommateInfo
        isShow={roommateInfo}
        id={profileDetails && profileDetails.id}
        roommate_info={profileDetails && profileDetails.roommate_info}
        handleClose={() => setRoommateInfo(false)}
      />
    </>
  );
};
export default withRouter(Profile);
