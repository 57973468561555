import React, { Component } from "react";
import {
  Container,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { PageTitleHeader, AppLayout } from "../../";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect, withRouter } from "react-router";
import "./style.scss";
import { API_URL } from "../../../../Constants/endPoints";
import Cookies from "js-cookie";
import { logger, toaster } from "../../../../Utils";
import { validate } from "../../../../Utils/Validation/validation";
import { VerificationSchema } from "./VerificationSchema";
import { postAPI } from "../../../../Api";

const log = logger("Verification");
class VerificationLoggedIN extends Component {
  state = {
    modalOpen: false,
    activation_code: null,
    activated: false,
    user_id: null,
    flow: null,
  };

  onChangeActivationCode = (e) => {
    this.setState({
      activation_code: e.target.value,
    });
  };

  resendCode = (email) => {
    const role = Number(Cookies.get('role'));
    let userRole = '';
    if (role === 2) {
      userRole = 'landlord';
    } else if (role === 3) {
      userRole = 'tenant';
    }
    const url = `${API_URL}/${userRole}/resend-forgot-password-pin`;
    const token = `Bearer ${Cookies.get("access_token")}`;
    const headers = {
      Authorization: token,
    };

    const data = {
      email: email,
      type: Cookies.get('type')
    };

    axios
      .post(url, data, {
        headers: headers,
      })
      .then(function (response) {
        toaster("success", response.data.message);
      })
      .catch(function (error) {
        toaster("error", "Something went wrong!");
      });
  };

  renderInput = (type, id, placeholder, onChange) => {
    return (
      <FormGroup style={{ position: "relative" }}>
        <Input
          type={type}
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
        />{" "}
        <p
          style={{
            position: "absolute",
            top: "17px",
            right: "11px",
            fontSize: "11px",
            fontWeight: "600",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            this.resendCode(
              this.props.auth.email
                ? this.props.auth.email
                : this.props.location.state.data
            )
          }
        >
          Resend Code
        </p>
      </FormGroup>
    );
  };
  renderModal = () => {
    return (
      <Modal
        isOpen={this.state.modalOpen}
        toggle={() => this.setState({ modalOpen: false })}
      >
        <ModalHeader>notification</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col className="text-center">
                A great profile helps you stand out from the crowd and increases
                your chances of getting your perfect home.
              </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <button
                className="w-100 m-auto btn btn-primary text-bold mt-5"
                onClick={() => this.setState({ modalOpen: false })}
              >
                fill in profile
              </button>
            </Row>
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => this.setState({ modalOpen: false })}
              >
                Skip For Now
              </span>
            </p>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  cancelBackStep = () => {
    this.props.history.push("/forgot-password");
  };

  handleConfirm = async (e) => {
    e.preventDefault();
    if (validate(VerificationSchema)) {
      const role = Number(Cookies.get('role'));
      let userRole = '';
      if (role === 2) {
        userRole = 'landlord';
      } else if (role === 3) {
        userRole = 'tenant';
      }
      const url = `/${userRole}/confirm-forgot-password-pin`;
      const data = {
        "pin": this.state.activation_code,
        "type": Cookies.get("type")
      }
      postAPI(url, data).then(res => {
        if (res.status === 200) {
          Cookies.set("pin", this.state.activation_code, {
            expires: 1,
          });
          this.props.history.push({ pathname: '/forgetchangepassword', state: { toastMsg: res.data.message } })
        } else if (res.response) {
          if (res.response.status === 422) {
            if (res.response.data.errors.pin) {
              toaster('error', res.response.data.errors.pin[0]);
            }
          } else if (res.response.status === 400) {
            log.info(res.response)
            toaster('error', res.response.data.message);
          }
        }
      })
        .catch(err => {
          toaster("error", err.response.data.message);
        })
    }
  };

  render() {
    const auth = this.props.auth;

    if (this.state.activated && !this.props.auth.forgotPasswordFlowActive) {
      return <Redirect push to="/profile" />;
    }

    if (this.state.activated && this.props.auth.forgotPasswordFlowActive) {
      return <Redirect push to="/forgetchangepassword" />;
    }

    return (
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title="forgot password"

            isArrow={true}
            onClick={() => this.props.history.goBack()}
          />
          <div className="profile-container verify">
            <div className="profile-container-box">
              <div className="inner-wrapper">
                <div className="profile-item">
                  <div className="image-wrapper">
                    <img
                      width="67.08"
                      height="69.99"
                      alt="change"
                      src={require("../../../../_assets/images/entercode.png")}
                      className="d-inline-block align-top"
                    />
                    <span className="bl-img-title navbar-brand-name">
                      Enter four Digit Code
                    </span>
                  </div>

                  <Form id="Form" className="profile-form">
                    {(this.props.location.state) &&
                      (Number(this.props.location.state.type) === 2 ?
                        <p className="text-center">
                          A 4 digit PIN has been emailed at <b>{auth.email}</b>. Please enter
                        it here to confirm your account.
                      </p>
                        : <p className="text-center">
                          A 4 digit PIN has been sent at <b>{auth.phone}</b>. Please enter
                        it here to confirm your account.
                    </p>)
                    }
                    {this.renderInput(
                      "number",
                      "security_pin",
                      "Enter 4 Digit PIN here",
                      this.onChangeActivationCode
                    )}

                    <Button
                      className="btn btn-primary text-bold mt-5"
                      onClick={this.handleConfirm}
                      style={{ width: "100%" }}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="btn text-bold btn-default"
                      style={{ width: "100%" }}
                      onClick={() => this.cancelBackStep()}
                    >
                      Cancel
                    </Button>
                  </Form>
                  {this.renderModal()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(select)(VerificationLoggedIN));
