export const ForPandaPartnersSchema = {
    date: [
        { rule: "required", message: "Date required" },
    ],
    time: [
        { rule: "required", message: "Time required" },
    ],
    phone_number: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number is not valid" }
    ],
    first_name: [
        { rule: "required", message: "First name required" }
    ],
    last_name: [
        { rule: "required", message: "Last name required" }
    ],
    company_name: [
        { rule: "required", message: "Company name required" },
    ],
}
