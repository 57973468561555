import React, { useState } from 'react';
import { Card } from "react-bootstrap";
import { placeholderImg } from '../../../_assets/images/placeholder.jpg';

const RenderCardImg = ({src, onClick}) => {
	const [isImgLoaded, setIsImgLoaded] = useState(false);
	const [isValidSrc, setIsValidSrc] = React.useState(!!src);
	
	return (
		<div className='RenderCardImg'>
			{isValidSrc ? (
				<Card.Img
					variant='top'
					src={src}
					onClick={onClick}
					style={{ cursor: (onClick ? 'pointer' : 'default') }}
					className={`RenderCardImg-image ${isImgLoaded ? 'visible' : 'hidden'}`}
					onLoad={() => setIsImgLoaded(true)}
					onError={() => setIsValidSrc(false)}
				/>
			) : (
				<Card.Img
					variant='top'
					src={ placeholderImg }
					onClick={onClick}
					style={{ cursor: (onClick ? 'pointer' : 'default') }}
					className={`RenderCardImg-image ${isImgLoaded ? 'visible' : 'hidden'}`}
					onLoad={() => setIsImgLoaded(true)}
				/>
			)}
		</div>
	)
}

export default RenderCardImg;
