export const PaymentValidationSchema = {
    card_number: [
        { rule: "required", message: "Card number required" }
    ],
    card_name: [
        { rule: "required", message: "Card holder name required" },
    ],
    month: [
        { rule: "required", message: "Month required" },
    ],
    year: [
        { rule: "required", message: "Year required" },
    ],
    cvv: [
        { rule: "required", message: "CVV required" },
    ],
    billing_address: [
        { rule: "required", message: "Billing address required" },
    ],
    billing_country: [
        { rule: "required", message: "Country required" },
    ],
    billing_province: [
        { rule: "required", message: "Province required" },
    ],
    billing_postal_code: [
        { rule: "required", message: "Postal code required" },
    ],
}