import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Loader } from "../../App/components";
import { getAPI } from "../../Api";
import "./style.scss";

const Tips = (props) => {
    const [previewList, setPreviewList] = useState([]);

    useEffect(() => {
        getAPI(`/cms/photography-tips`).then(response => {
            if (response.status === 200) {
                setPreviewList(response.data.data)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <div className="photography-tips-grid">
                <Container>
                    {(previewList && previewList.length > 0) ? previewList.map((tip, i) => {
                        return <Row key={'tip' + i} className="tip-row align-items-center">
                                    <Col md={4} className="tip-img">
                                        <Image src={tip.image_url} alt="tip" />
                                    </Col>
                                    <Col md={8}>
                                        <h2 className="tip-heading">tip #{i + 1}</h2>
                                        <p className="tip-desc">{tip.description}</p>
                                    </Col>
                                </Row>
                    }) : <Loader />}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Tips);
