import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import { useSelector } from 'react-redux'
import {
  CostNumberFormat,
  DatePickerComponent,
  LargeButton,
  SingleOptionCheck,
} from "../../";
import { numberFormatSanitizerInt } from "../../../../Utils/NumberSanitizer/NumberSanitizer";
import { frequencyType } from "../../../../Constants/NoticeConstants";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { logger, toaster, dateFormat, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { N1Schema } from "./ValidationSchema/N1Schema";
import { transFormSignData } from './helper'
import { updateRawData } from "../../../../Store/actions/RawDataAction";
import { useGlobalState } from "../../../../StateContext";

const log = logger("N1Form");
const N1Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack, onNoticeDataUpdate
  } = props;
  const [errors, setErrors] = useState({})
  const [globalState, setGlobalState] = useGlobalState()
  const [date, setDate] = useState(null);
  const [rent, setRent] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [other, setOther] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [newRent, setNewRent] = useState("");
  const [newFrequency, setNewFrequency] = useState(1);
  const [newOther, setNewOther] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [rent_increase_reason, setRent_increase_reason] = useState(1);
  const [residential_tenancy_act, setResidential_tenancy_act] = useState("");

  //Signature Data
  const [signData, setSignData] = useState(null);

  useEffect(() => {
    setGlobalState({ onSubmit: onSubmit })
    return () => {
      setGlobalState({ onSubmit: null })
    }
  }, [])

  useEffect(() => {
    updateRawData({ allCleared: false })
    onNoticeDataUpdate({
      when_rent_increase: date ? dateFormat(date) : '',
      new_rent_rate: rent,
      agree_terms_and_conditions: signData && signData?.agree && "1",
      frequency: frequency,
      frequency_text: other,
      rent_increase_of: newRent,
      increase_rent_frequency: newFrequency,
      increase_rent_frequency_other_text: newOther,
      rent_increase_guideline: rent_increase_reason, //"1 or 2",
      residential_tenancy_act: [residential_tenancy_act],
      signData
    })
    setGlobalState({ onSubmit: onSubmit })
    if(isSubmitClicked)
      validate(N1Schema,null,true)

  }, [date, rent_increase_reason, residential_tenancy_act, rent, frequency, other, phoneError, signData, newRent, newFrequency, newOther, tenant_id.length, property_id])


  const onSubmit = () => {
      setIsSubmitClicked(true)
    if (frequency === 3 || newFrequency === 3) {

      setErrors({ other: !other && frequency === 3, newOther: !newOther && newFrequency === 3 })
    }

    if (rent_increase_reason === 2) {
      if (!residential_tenancy_act) {
        toaster("error", 'Select a rent increase guidline option');
        return;
      }
    }
    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }

    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N1Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const phone = signData && signData?.phoneNumber.replace(/\D+/g, '')
      const data = {
        notice_id: noticeData && noticeData.id,
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 1,
        when_rent_increase: dateFormat(date),
        new_rent_rate: rent,
        agree_terms_and_conditions: signData && signData?.agree && "1",
        frequency: frequency,
        frequency_text: other,
        rent_increase_of: newRent,
        increase_rent_frequency: newFrequency,
        increase_rent_frequency_other_text: newOther,
        rent_increase_guideline: rent_increase_reason, //"1 or 2",
        residential_tenancy_act: [residential_tenancy_act], //[1, 2],
        full_name: signData && signData?.signingName,
        phone_number: phone,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id, // [1,2,5]
        i_want_to: 15,
      };
      postAPI(url, data).then((response) => {
        if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        } else if (response.response) {
          if (response.response.status === 422) {
            toaster("error", 'Please fill all the data');
          } else if (response.response.status === 400) {
            toaster("error",);
          }
        }
      });
    }
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, Date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    if (name === "frequency") {
      setFrequency(Value);
    } else if (name === "newFrequency") {
      setNewFrequency(Value);
    }
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  useEffect(() => {
    if (noticeData) {
      if (noticeData.when_rent_increase && noticeData.when_rent_increase !== 'Invalid date' && noticeData.when_rent_increase.length)
        setDate(moment(noticeData.when_rent_increase).toDate());
        const rentValue=noticeData.new_rent_rate 
        // ? noticeData.new_rent_rate.toString().split(".")[0] : 0
      setRent(rentValue);
      setFrequency(noticeData.frequency || 1);
      setOther(noticeData.frequency_text);
      setNewRent(noticeData.rent_increase_of);
      setNewFrequency(noticeData.increase_rent_frequency || 1);
      setNewOther(noticeData.increase_rent_frequency_other_text);
      setRent_increase_reason(noticeData.rent_increase_guideline || 1);
      if (noticeData.residential_tenancy_act) {
        setResidential_tenancy_act(typeof noticeData.residential_tenancy_act === "string"
          ? JSON.parse(noticeData.residential_tenancy_act)[0] : Number(noticeData.residential_tenancy_act[0]));
      }
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice Of Rent Increase (N1 Form)</span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            extraClass={'get-paralegal-help-btn'}
            onClick={getParalegal}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>When Is The Rent Increasing?</Form.Label>
              <DatePickerComponent
                id="rentDate"
                name="rentDate"
                format={"dd/MM/yyyy"}
                value={date && date}
                handleDateChange={(date) => handleDateChange(date, setDate)}
                minDate={new Date()}
                displayText={"Select or type"}
                popperPlacement="bottom-end"
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>New Rent Rate ($)</Form.Label>
              <CostNumberFormat
                name={"rent"}
                id={"rent"}
                classname="form-control"
                value={rent ? rent : ""}
                updateField={(e) =>
                  setRent(e.target.value)
                  // setRent(numberFormatSanitizerInt(e.target.value))
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"frequency"}
                setParam={frequency}
                extra={
                  frequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="other"
                        id="other"
                        value={other}
                        style={{ borderColor: errors.other ? 'red' : '' }}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <div className="d-flex justify-content-between mb-3">
            <span className="form-title">Explanation Of The Rent Increase</span>
          </div>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>This Is A Rent Increase Of $</Form.Label>
              <CostNumberFormat
                name={"newRent"}
                id={"newRent"}
                classname="form-control"
                value={newRent ? newRent : ""}
                updateField={(e) =>
                  setNewRent( e.target.value)
                }
                pre="+$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"newFrequency"}
                setParam={newFrequency}
                extra={
                  newFrequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="newOther"
                        id="newOther"
                        value={newOther}
                        style={{ borderColor: errors.newOther ? 'red' : '' }}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setNewOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="6">
              <Form.Label>Choose One Of The Following</Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6">
              <Form.Check
                key={"rent_increase_reason_1"}
                type="radio"
                id={`rent_increase_reason_1`}
                name="rent_increase_reason"
                checked={rent_increase_reason === 1 && "checked"}
                onChange={() => {
                  setRent_increase_reason(1);
                }}
                label={
                  "This rent increase is less than or equal to the rent increase guideline and does not need approval by an order under the Residential Tenancies Act, 2006."
                }
              />
            </Form.Group>

            <Form.Group as={Col} md="6">
              <Form.Check
                key={"rent_increase_reason_2"}
                type="radio"
                id={`rent_increase_reason_2`}
                name="rent_increase_reason"
                checked={rent_increase_reason === 2 && "checked"}
                onChange={() => {
                  setRent_increase_reason(2);
                }}
                label={
                  "This rent increase is more than the rent increase guideline, but:"
                }
              />

              {rent_increase_reason === 2 && (
                <Form.Group as={Col} md="12">
                  <Form.Check
                    key={"residential_tenancy_act_1"}
                    type="radio"
                    id={`residential_tenancy_act_1`}
                    name="residential_tenancy_act"
                    checked={residential_tenancy_act === 1 && "checked"}
                    onChange={() => {
                      setResidential_tenancy_act(1);
                    }}
                    label={
                      "The rent increase has been approved by an order under the Residential Tenancies Act, 2006."
                    }
                  />

                  <Form.Check
                    key={"residential_tenancy_act_2"}
                    type="radio"
                    id={`residential_tenancy_act_2`}
                    name="residential_tenancy_act"
                    checked={residential_tenancy_act === 2 && "checked"}
                    onChange={() => {
                      setResidential_tenancy_act(2);
                    }}
                    label={
                      "The rent increase must be approved by an order under the Residential Tenancies Act, 2006. I have applied to the Board for a Rent Increase Above the Guideline."
                    }
                  />
                </Form.Group>
              )}
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            setSignData={(data) => setSignData(data)}
            noticeData={{ ...noticeData, ...transFormSignData(noticeData?.signData || {}) }}
            notice_type={1}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N1Form);
