import React, { useState, useEffect } from "react";
import {
  AppLayout,
  PageTitleHeader,
  FileBrowse,
  ModalBox,
  SidePanel,
} from "../../../components";
import { Image, Dropdown, DropdownButton } from "react-bootstrap";
import "../style.scss";
import { logger } from "../../../../Utils";
import { withRouter } from "react-router-dom";
import { Col, Form, Row, Button } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import axios from "axios";
import Cookies from "js-cookie";
import { IMAGE_API_URL } from "../../../../Constants/endPoints";
import { useSelector } from "react-redux";
import moment from "moment";
import SuggestedVendors from "./SuggestedVendors";
import { validate } from "../../../../Utils/Validation/validation";
import { ValidationSchema } from "./ValidationSchema";
import { Prompt } from "react-router";
import CompleteWarningMessage from '../../../components/CompleteWarningMessage/CompleteWarningMessage'


const log = logger("MaintainanceRepair");
const RequestRepair = (props) => {
  const role = Number(Cookies.get("role"));
  const [notes, setNotes] = useState("");
  const [tenantlist, setTenantlist] = useState([]);
  const [tenant_id, setTenant_id] = useState([]);
  const [requestOption, setRequestOption] = useState([]);
  const [request_type, setRequest_type] = useState("");
  const [requestTypeReq, setRequestTypeReq] = useState(false);
  const [request_id, setRequest_id] = useState(null);
  const [preview, setPreview] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredTenant, setFilteredTenant] = useState([]);
  const [imageUrlData, setImageUrlData] = useState([]);
  const [errorClass, setErrorClass] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [cancelSuccessModalShow, setCancelSuccessModalShow] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const path = props.history.location.pathname.split("/")[1];
  const [property_id, setProperty_id] = useState(
    props.match.params.property_id
  );
  const [property_details_id, setProperty_details_id] = useState(
    props.match.params.property_details_id
  );
  const [maintenanceId, setMaintenanceId] = useState(null);
  const [show, setShow] = useState(false);
  // const address = useSelector(
  //   (state) => state.maintenanceRepairReducer.address
  // );
  const [showImageLoading,setShowImageLoading]=useState(false)
  const [landlordAddress, setlandlordAddress] = useState("");
  const [landlord, setLandlord] = useState("");
  const [landlordId, setLandlordId] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [createdDate, setCreatedDate] = useState(null);
  const [completedDate, setCompletedDate] = useState("");
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null)
  const currentDate = Date();
  const id = useSelector((state) => state.auth.id);
  const [percent, setPercent] = useState(0);
  const removeImage = (index) => {
    imageUrlData.splice(index, 1);
    setImageUrlData((imageUrlData) => [...imageUrlData]);
  };
  const maintenanceData = useSelector(state => state.maintenanceRepairReducer)

  useEffect(() => {
    if (path === "view-repair-request") {
      setPreview(props.preview);
      setDelivered(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (path === "view-repair-request") {
      viewRequests();
      getSuggestedVendors();
      if (role === 3) {
        getLandlordProfile();
      }
    } else if (path === "repair-request") {
      getRequestTypes();
      if (role === 2) {
        getTenants(maintenanceData.address);
        setlandlordAddress()
      } else if (role === 3) {
        setlandlordAddress(maintenanceData.address);
        setLandlordId(maintenanceData.landlord.id)
        setLandlord(maintenanceData.landlord.name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request_id]);

  useEffect(() => {
    setFilteredTenant(
      tenantlist.filter((tenantLt) => {
        return tenantLt.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, tenantlist]);

  const OnChangeInput = (event) => {
    if (event.target.name === "notes") {
      setNotes(event.target.value);
      setShouldBlockNavigation(true);
    }
  };

  const viewRequests = () => {
    getAPI(`/maintenance-request/view/${props.match.params.requestId}`).then(
      (res) => {
        const response = res.data.data;
        setTenantlist(response.property_tenants);
        // setTenantlist([{id: 21, name: "Dipak Joni", pivot: {maintenance_repair_request_id: 29, tenant_id: 21}}, {id: 21, name: "Dipak Joni2", pivot: {maintenance_repair_request_id: 29, tenant_id: 21}}, {id: 21, name: "Dipak Joni3", pivot: {maintenance_repair_request_id: 29, tenant_id: 21}}]);
        setRequest_type(response.request_type_info.status_name);
        setLandlord(response.landlord.name);
        setRequest_id(response.request_type_info.status_id);
        setNotes(response.notes);
        setlandlordAddress(response.property.address);
        setCreatedDate(response.created_at);
        setCompletedDate(response.completed_at);
        setImageUrlData(response.images);
        setRequestStatus(response.status_info)
        setMaintenanceId(response.id);
        setProperty_id(response.property_id);
        setProperty_details_id(response.property_detail_id);

        if (response.status_info.status_id === 3) {
          setCompleted(true);
        }
      }
    );
  };

  const getRequestTypes = () => {
    getAPI("/maintenance-request/types").then((res) => {
      setRequestOption(res.data.data);
    });
  };

  const getLandlordProfile = () => {
    getAPI(`/tenant/${property_id}/property-unit-details`).then((res) => {
      setlandlordAddress(res?.data?.data.address);
      setLandlord(res?.data?.data?.landlord_name);
      setLandlordId(res?.data?.data.landlord_id);
    });
  };

  const getTenants = () => {
    getAPI(`/get-property-tenants/${property_id}/${property_details_id}`).then(
      (res) => {
        setTenantlist(res.data.data);
        const tempId = [];
        res.data.data.map((tenant) => {
          return tempId.push(tenant.tenant_id);
        });
        setTenant_id(tempId);
      }
    );
  };

  const handleDrop = (file, setPercent) => {
    const url = IMAGE_API_URL;
    const token = `Bearer ${Cookies.get("access_token")}`;
    const headers = {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    };
    setShowImageLoading(true)
    file.map((dataFile) => {
      let formdata = new FormData();
      formdata.append("image", dataFile);
      axios
        .post(url, formdata, {
          headers: headers,
          onUploadProgress: progressEvent => {
            var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
            log.info("image loader percent: ", percent);
            if (percent > 99) {
              setPercent(percent);
              return file;
            } else {
              setPercent(percent);
              return null;
            }
          }
        })
        .then(function (response) {
          setImageUrlData((imageUrlData) => [
            ...imageUrlData,
            response.data.data.url,
          ]);
          setErrorClass(false);
          setShouldBlockNavigation(true);
          setShowImageLoading(false)
        })
        .catch(function (error) { 
          setShowImageLoading(false)

        });
      return dataFile;
    });
  };

  const getSuggestedVendors = () => {
    const tempRequestId = [];
    tempRequestId.push(request_id);
    const data = {
      limit: 4,
      service_requests: tempRequestId,
    };
    postAPI("/cms/partners/list", data).then((res) => {
      setVendors(res.data.data);
    });
  };

  const handleSubmit = () => {
    const top = document.getElementsByClassName('profile-header')[0]
    top.scrollIntoView()
    setSuccessModalShow(false);
    const url = "/maintenance-request/create";
    let data;
    if (role === 2) {
      data = {
        property_id: property_id,
        property_detail_id: property_details_id,
        landlord_id: id,
        tenant_id: tenant_id,
        request_type: request_id,
        notes: notes,
        images: imageUrlData,
      };
    } else if (role === 3) {
      const tempId = [];
      tempId.push(id);
      data = {
        property_id: property_id,
        property_detail_id: property_details_id,
        landlord_id: landlordId,
        tenant_id: tempId,
        request_type: request_id,
        notes: notes,
        images: imageUrlData,
      };
    }

    postAPI(url, data).then((res) => {
      setShouldBlockNavigation(false);
      if (res.data.status_code === 200) {
        setMaintenanceId(res.data.data.id);
        if (role === 2) {
          setDelivered(true);
          props.history.push(
            `/maintainance-repair/${property_id}/${property_details_id}/repairs`
          );
        } else if (role === 3) {
          setDelivered(true);
          setSuccessModalShow(true);
        }
      }
    });
  };
  const previewHandler = () => {
    const top = document.getElementsByClassName('profile-header')[0]
    top.scrollIntoView()
    if (request_type === "") {
      setRequestTypeReq(true);
    }
    if (validate(ValidationSchema) && request_type !== "") {
      log.info(imageUrlData[0]);
      // if (!imageUrlData[0]) {
      //   document.querySelector(".uploadOuter").classList.add("is-invalid")
      //   return false;
      // }
      setPreview(true);
      getSuggestedVendors();
      // document.documentElement.scrollTop = 0
    }

  };

  const handleChangeStatus = (status) => {
    const data = {
      id: maintenanceId,
      status: status,
    };
    postAPI(`/maintenance-request/change-status`, data).then((res) => {
      setShouldBlockNavigation(false);
      if (res.data.status_code === 200) {
        if (status === 4) {
          setModalShow(false);
          setCancelSuccessModalShow(true);
        } else {
          setModalShow(false);
          props.history.push(
            `/maintainance-repair/${property_id}/${property_details_id}/repairs`
          );
        }

      }
    });
  };

  const back = () => {
    if (!preview) {
      props.history.goBack();
    }
    if (path === "repair-request") {
      setPreview(false);
    } else if (path === "view-repair-request") {
      props.history.goBack();
    }
  };

  const requestHandleChange = (id, display) => {
    setRequest_id(id);
    setRequest_type(display);
    setRequestTypeReq(false)
    setShouldBlockNavigation(true);
  };

  const handleSubmitlauncher = () => {
    const top = document.getElementsByClassName('profile-header')[0]
    top.scrollIntoView()
    setSuccessModalShow(true);
  };

  const openSidePanel = (selectedVendor) => {
    setShow(true);
    setSelectedVendors(selectedVendor);
  };

  const handleClose = () => {
    setShow(false);
  };

  const redirectToMessage = () => {
    props.history.push({
      pathname: `/property-details/${property_id}/${property_details_id}/messages`,
    });
  };

  useEffect(() => {
    (percent && percent > 99) && setPercent(0);
  }, [percent])

  return (
    <>
      <Prompt
        when={shouldBlockNavigation}
        message="your information will be lost do you want to leave this page?"
      />
      <AppLayout>
        <PageTitleHeader
          extraClass='text-lowercase'
          title={
            path === "repair-request"
              ? role === 2
                ? !preview ? "create new repair request" : 'review repair notification'
                : role === 3 && "maintenance and repair"
              : "repairs & maintenance"
          } 
          isArrow={true}
          onClick={() => back()}
          leftBtn={preview && !delivered && "true"}
          leftBtnText="Edit"
          leftBtnHandler={back}
          rightBtn={!delivered && "true"}
          rightBtnText={preview ? "Submit" : "Review"}
          rightBtnHandler={() => {
            if (preview) {
              if (role === 2) {
                handleSubmitlauncher();
              } else if (role === 3) {
                handleSubmit();
              }
            } else {
              previewHandler();
            }
          }}
        />
        {!completed && (requestStatus && requestStatus.status_name !== 'Delivered') && role === 3 && 
          <CompleteWarningMessage
            message="If this has been resolved"
            ctaText='message your landlord'
            extraMessage='to let them know!'
            onClick={() => redirectToMessage()} 
          />
        }
        <div className="request-repair-form ">
          <div className="d-block d-md-flex justify-content-between">
            <span className="address-text">
              {maintenanceData.address}
            </span>
            <span className="date-text">
              {completed ? "Completed On: " : "Date Created: "}
              {path === "repair-request"
                ? moment(currentDate).format("ll")
                : path === "view-repair-request" && !completed
                  ? moment(createdDate).format("ll")
                  : moment(completedDate).format("ll")}
            </span>
          </div>
          {preview && (
            <>
              {role === 2 && (
                <Row className="mt-2rem mt-3">
                  <Col md="12">
                    <div className="d-flex align-items-start flex-column">
                      <span className="request-title-name">
                        Present Tenant(s){" "}
                        {path === "view-repair-request" && (
                          <Image style={{ cursor: "pointer" }} onClick={() => redirectToMessage()}
                            src={require("../../../../_assets/images/msg-icon.svg")}
                            alt="Message"
                          />
                        )}
                      </span>
                      <div className='pt-2'>
                        {tenantlist.map((tenant, i) => {
                          return (
                              <span
                                key={i}
                                className="request-title-name extra-pad"
                              >
                                {tenant.name}
                                {i !== (tenantlist.length - 1) && ","} &nbsp;
                              </span>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <div className="row align-items-start mt-2rem mt-3 ">
                {role === 3 && (
                  <div className="d-flex flex-column col-md-3 align-items-start">
                    <span className="request-title-name">Landlord</span>
                    <span className="request-title-name extra-pad">
                      {(maintenanceData && maintenanceData.landlord?.name) || landlord?.name }&nbsp;&nbsp;
                      {path === "view-repair-request" && (
                        <Image style={{ cursor: "pointer" }} onClick={() => redirectToMessage()}
                          src={require("../../../../_assets/images/msg-icon.svg")}
                          alt="Message"
                        />
                      )}
                    </span>
                  </div>
                )}
                <div className="d-flex flex-column col-md-3 align-items-start mb-4">
                  <span className="request-title-name">Request Type</span>
                  <span className="request-title-name extra-pad">
                    {request_type}
                  </span>
                </div>
                <div className="d-flex flex-column col-md-3 align-items-start">
                  <span className="request-title-name">Notes</span>
                  <span className="request-title-name extra-pad">{notes}</span>
                </div>
              </div>

              <div className="d-flex align-items-start flex-column mt-2rem mt-4">
                <span className="attched-img pb-2">
                  {imageUrlData.length === 0
                    ? "No Images Attached"
                    : "Attached Image"}
                </span>
                <div className="d-flex flex-wrap">
                  {imageUrlData &&
                    imageUrlData.map((file, index) => {
                      return (
                        <img
                          className="attached-image mr-4"
                          src={
                            path === "view-repair-request" ? file.image : file
                          }
                          key={index} alt=""
                        />
                      );
                    })}
                </div>
              </div>
            </>
          )}
          {!preview && (
            <Form id="Form" style={{ marginTop: '2rem' }} >
              {role === 2 && (
                <Row>
                  <Col md="12">
                    <div className="d-flex align-items-start flex-column">
                      <span className="request-title-name">
                        Present Tenant(s)
                      </span>
                      <div className='pt-2'>
                        {tenantlist.map((tenant, i) => {
                          return (
                            <span
                              key={i}
                              className="request-title-name extra-pad"
                            >
                              {tenant.name}
                              {i !== tenantlist.length - 1 && ","} &nbsp;
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mt-2rem mt-3">
                {role === 3 && (
                  <Form.Group
                    as={Col}
                    md="2"
                    className="d-flex flex-column align-items-start"
                  >
                    <Form.Label>Landlord</Form.Label>
                    <span className="request-title-name extra-pad">
                      {landlord}
                    </span>
                  </Form.Group>
                )}
                <Form.Group as={Col} md="3" className="request-select">
                  <Form.Label>Request Type</Form.Label>
                  <DropdownButton
                    // alignLeft
                    title={request_type === "" ? "Select" : request_type}
                    id="dropdown-select"
                    name="request_type"
                    className={requestTypeReq ? "bg-error" : "bg-no-error"}
                    style={{ width: "100%", }}
                  >
                    {requestOption.map((options, i) => {
                      return (
                        <Dropdown.Item
                          eventKey={options.value}
                          onClick={() =>
                            requestHandleChange(options.id, options.name)
                          }
                          key={i}
                        >
                          {options.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  {requestTypeReq &&
                    <div className="invalid-select">Request Type Required</div>}
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    type="text"
                    name="notes"
                    id="notes"
                    value={notes}
                    placeholder="Input Notes"
                    onChange={(e) => OnChangeInput(e)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <FileBrowse
                    handleDrop={(e) => handleDrop(e, setPercent)}
                    title="&nbsp;&nbsp;&nbsp;Attach Images"
                    percent={percent}
                    multiple={true}
                    fixFor={'1843'}
                    fileType={".jpg,.png,.jpeg"}
                    disable={false}
                    imageData={imageUrlData}
                    removeImage={(e) => removeImage(e)}
                    showImageLoading={showImageLoading}
                    // errorClass={errorClass === true ? "error-upload-box" : ""}
                  />
                  {/* <div className="invalid-feedback">Image required</div> */}
                </Form.Group>
              </Row>
            </Form>
          )}
        </div>
        {preview && (
          <SuggestedVendors
            openSidePanel={openSidePanel}
            show={show}
            vendors={vendors}
          />
        )}
        {/* {preview && completed && (
          <SuggestedVendors
            openSidePanel={openSidePanel}
            show={show}
            vendors={vendors}
          />
        )} */}
        <ModalBox
          show={successModalShow}
          onHide={() => {
            setSuccessModalShow(false);
          }}
          size="sm"
          extraClass="pb-0"
          actionbuttontitle={"got it"}
          buttonaction={() => {
            if (role === 2) {
              handleSubmit();
            } else if (role === 3) {
              setSuccessModalShow(false);
              props.history.push(
                `/maintainance-repair/${property_id}/${property_details_id}/repairs`
              );
            }
          }}
        >
          {role === 2 && (
            <h5 className="created-success-msg">
              {tenantlist.map((tenant, index) => {
                return (
                  <span key={index}>
                    {" "}
                    {tenant.name}
                    {index !== tenantlist.length - 1 ? ", " : ' '}
                  </span>
                );
              })}
              will be notified of the upcoming maintenance/repair.
            </h5>
          )}
          {role === 3 && (
            <>
              <h5 className="created-success-msg">
                your request for a repair has been sent to the landlord.
              </h5>
              <span className="success-text-secoundary">
                if the issue is an emergency, please call them right away to
                make sure the repair is dealt with immediately.
              </span>
            </>
          )}
        </ModalBox>
        <ModalBox
          show={cancelSuccessModalShow}
          onHide={() => {
            setCancelSuccessModalShow(false);
          }}
          size="sm"
          actionbuttontitle={"got it"}
          buttonaction={() => {
            setCancelSuccessModalShow(false);
            props.history.push(
              `/maintainance-repair/${property_id}/${property_details_id}/repairs`
            );
          }}
        >
          <h5 className="created-success-msg">
            you have canceled this request
          </h5>
        </ModalBox>
        <ModalBox
          show={modalShow}
          onHide={() => setModalShow(false)}
          closeButtonHeader={false}
          size="md"
          type="condition"
          actionbuttontitle={"Yes"}
          buttonaction={() => handleChangeStatus(statusId)}
          backbuttontitle={"No"}
          backaction={() => {
            setModalShow(false);
          }}
        >
          {statusId === 3 ? (
            <>
              <p className="modal-confirmation-text">
                are you sure you want
                <br /> to mark this as
                <br /> complete?
              </p>
              <p className="modal-text">
                the tenant will receive a <br /> notification
              </p>
            </>
          ) : (
              <>
                <p className="modal-confirmation-text">
                  are you sure you want<br /> to
                  cancel this {role === 3 && "repair"} <br />request?
              </p>
                {/* {role === 3 ? (
                  <p className="success-text-secoundary" style={{ marginTop: '20px' }}>It cannot be undone</p>
                ) : (
                    <p className="modal-text">It cannot be undone</p>
                  )} */}
              </>
            )}
        </ModalBox>
        {!completed && (
          <>
            {delivered ? (
              <div className="container-footer button-extra-margin">
                {(requestStatus && requestStatus.status_name === 'Delivered') ?
                  <Button
                    variant="dark"
                    onClick={() => {
                      setStatusId(4);
                      setModalShow(true);
                    }}
                  >
                    Cancel Request
                </Button> : ''}
                {role === 2 && (
                  <Button
                    variant="success"
                    onClick={() => {
                      setStatusId(3);
                      setModalShow(true);
                    }}
                  >
                    Mark as Complete
                  </Button>
                )}
              </div>
            ) : (
                <div className="container-footer button-extra-margin">
                  {preview ? (
                    <Button
                      variant="success"
                      onClick={() => {
                        if (role === 2) {
                          setSuccessModalShow(true);
                        } else if (role === 3) {
                          handleSubmit();
                        }
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                      <>
                        <Button variant="secondary" onClick={back}>
                          Cancel
                    </Button>
                        <Button variant="success" onClick={previewHandler}>
                          Review
                    </Button>
                      </>
                    )}
                </div>
              )}
          </>
        )}
      </AppLayout>
      <SidePanel
        isShow={show}
        isHide={handleClose}
        title={selectedVendors.company_name}
      >
        <div className="vendor-sidepanel-body">
          <img src={selectedVendors.image_url} alt="" />
          <div className="vendor-bio">
            <span>{selectedVendors.company_bio}</span>
          </div>
          <div className="vendor-card d-flex align-items-start flex-column">
            <span className="mb-auto">Contact Details</span>
            <span>
              Phone:{" "}
              <span className="blue-color">
                {selectedVendors.contact_number}
              </span>
            </span>
            <span>
              Email:{" "}
              <span className="blue-color">
                {selectedVendors.contact_email}
              </span>
            </span>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default withRouter(RequestRepair);
