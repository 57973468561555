import React from "react";
import { Button } from "react-bootstrap";
import "./style.scss";

const CancelButton = (props) => {
  const { title, onClick, variant, style, type } = props
  return (    
      <Button variant={variant ? variant : 'light'} type={type ? type : 'button'} className='cancel-button' onClick={onClick} style={style}>
        {title}
      </Button>
  );
}
export default CancelButton;