export const AddingTenantSchema = {
    tenantFullName: [
        { rule: "required", message: "Full name is required" },
    ],
    tenantEmailId: [
        { rule: "required", message: "Email is required" },
        { rule: "email", message: "Email is invalid" }
    ],
    tenantPhone:[
        { rule: "required", message: "Phone Number is required" },
        { rule: "min", message: "Phone Number is invalid",min:10 }
    ]
}