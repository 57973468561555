import { getAPI, postAPI } from "../../../Api";

export const postNotes = async (data) => {
    try{
        let url = `/admin/requests/save-notes`;

        const res = await postAPI(url, data);

        if (res.status === 200) {
            return res
        }
    }catch(error){
        return error
    }
};

export const fetchTableData = async (pagination, currentPage, filterData) => {
    try {
        let url = '';
            if (currentPage === null) {
                url = `/admin/requests/list`;
            } else {
                url = `/admin/requests/list?page=${currentPage}`;
            }
            const response = await postAPI(url, filterData)
            if (response.status === 200) {
                return response;
            }
    }catch(error){
        return error;
    }
        
}

export const getQueryParams = (queryString, params) => {
    const parameters = new URLSearchParams(queryString);
    const value = params.map(param => parameters.get(param)).toString();
    return value;
}
