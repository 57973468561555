export const CurrentHomeInfoValidationSchema = {
    current_form_address_2: [
        { rule: "required", message: "Current address required" },
    ],
    current_form_start_date: [
        { rule: "required", message: "Start date required" },
    ],
    // current_end_date: [
    //     { rule: "required", message: "End date required" },
    // ],
}