export const EmpInfoValidationSchema = {
    min_salary_current_emp: [
        { rule: "required", message: "Min salary required" },
    ],
    // max_salary_current_emp: [
    //     { rule: "required", message: "Max salary required" },
    // ],
    job_title_current_emp: [
        { rule: "required", message: "Job title required" },
    ],
    start_date_current_emp: [
        { rule: "required", message: "Start Date required" },
    ],
    employment_reference_name_current_emp: [
        { rule: "required", message: "Reference name required" },
    ],
    employment_reference_phone_current_emp: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number must be of 10 digits" }
    ],
}
