export const EntryFormSchema = {
    date2: [
        { rule: "required", message: "date required" },
    ],   
    timeofentry: [
        { rule: "required", message: "time required" },
    ],
    // description: [
    //     { rule: "required", message: "required" },
    // ],
   
}