import React, {
 useEffect,
 useLayoutEffect,
 useState,
} from "react";
import axios from "axios";
import {
 Form,
 Row,
 Col,
 Image,
} from "react-bootstrap";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import {
 useSelector,
 useDispatch,
} from "react-redux";
import {
 setSearchFilter,
 setSearchLocation,
} from "../../../Store/actions/LandingPageAction";
import { getAPI, postAPI } from "../../../Api";
import { logger, toaster } from "../../../Utils";
import {
 AppLayout,
 NavbarSortingDropdown,
 PropertyCard,
 ModalBox,
 AutoCompleteAddress,
 Loader,
} from "../../components";
import ListBlockContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader";
import { FilterTenantProperty } from "./Filter";
import SearchMapListing from "./Filter/SearchMapListing";
import "./style.scss";
import HorizontalPropertyCard from "../../components/PropertyCard/HorizontalPropertyCard";
import {
 checkIsBottom,
 getMarkerData,
 getPropertyDetails,
 isPropertyPresent,
 setPlaceChanged,
} from "./helper";
import SearchListHeader from "./SearchListHeader";
import MobileListView from "./MobileListView";
import DesktopListView from "./DesktopListView";
import { getTenantProfile } from "../../components/Message/helper";
import { Helmet } from "react-helmet";

const log = logger("SearchListing");
let timeout;

const SearchListing = (props) => {
 const [cityData, setCityData] = useState(false);
 const [previewData, setPreviewData] = useState(
  ""
 );

 //  useEffect(() => {
 //   axios
 //    .get(
 //     `${process.env.REACT_APP_BASE_API_URL}/api/cms/rent-panda-cities`
 //    )
 //    .then((res) => {
 //     setCityData(res.data);
 //    });
 //  }, []);

 useEffect(() => {
  if (props.match.params.city && props.cityData) {
   setCityData(props.cityData);
  }
 }, [props.match.params.city]);

 const [metaData, setMetaData] = useState("");
 const setMeta = (previewData) => {
  if (!!previewData.excerpt) {
   setMetaData([
    {
     name: "description",
     content: previewData.excerpt,
    },
   ]);
  }
 };
 const horizontalRef = React.useRef();
 const [updateModal, setUpdateModal] = useState(
  false
 );
 const [isMapVisible, setIsMapVisible] = useState(
  false
 );
 const [isDesktop, setIsDesktop] = useState(true);
 const searchLocation =
  useSelector(
   (state) =>
    state.landingPageReducer.getSearchLocation
  ) || "";
 const getSearchFilter =
  useSelector(
   (state) =>
    state.landingPageReducer?.getSearchFilter
  ) || null;
 const {
  latitude,
  longitude,
  city_address_or_location,
 } = getSearchFilter || {
  latitude: null,
  longitude: null,
  city_address_or_location: null,
 };
 const dispatch = useDispatch();
 const [sortName, setSortName] = useState(
  "new to old"
 );
 const [tenantFilter, setTenantFilter] = useState(
  false
 );
 const [listData, setPropertyLists] = useState(
  false
 );
 const [isLoading, setIsLoading] = useState(true);
 const [selectedId, setSelectedId] = useState(
  null
 );
 const [
  confirmAction,
  setConfirmAction,
 ] = useState(false);
 const [currentPage, setCurrentPage] = useState(
  1
 );
 const [
  showBottomLoader,
  setShowBottomLoader,
 ] = useState(false);
 const [
  selectedLocation,
  setSelectedLocation,
 ] = useState();
 const [markerData, setMarkerData] = useState([]);
 const filterData = useSelector(
  (state) =>
   state.landingPageReducer.getSearchFilter
 );
 // const [address, setAddress] = useState('');
 const sortOptions = [
  { display: "new to old", key: "desc" },
  { display: "old to new", key: "asc" },
 ];
 const tokenCookie = Cookies.get("access_token");
 const UpdateInfoCookie = Cookies.get(
  "skip_update_info"
 );
 const role =
  useSelector((state) => state.auth.role) ||
  parseInt(Cookies.get("role"));
 const isLoggedIn = tokenCookie;
 const isProfileCompleted = useSelector(
  (state) =>
   state.UserProfileReducer.userProfile.basic_info
    ?.is_profile_completed
 );
 const viewProfile = () => {
  setUpdateModal(false);
  props.history.push("/profile");
 };
 useEffect(() => {
  checkIsDesktop();
  window.addEventListener(
   "resize",
   checkIsDesktop,
   false
  );
  getResults();
  setSelectedLocation(searchLocation);
  getMarkerDataHandler();
  getTenantProfile();
  return () => clearTimeout(timeout);
 }, []);

 useEffect(() => {
  getMarkerDataHandler();
 }, [filterData]);

 useEffect(() => {
  getResults();
 }, [selectedLocation]);

 useEffect(() => {
  const resetSearch =
   searchLocation === "" &&
   selectedLocation !== null;
  if (resetSearch && !tenantFilter) {
   getResults();
   log.info("Inside searchLocation");
   getMarkerDataHandler();
   setSelectedLocation(null);
   dispatch(
    setSearchFilter({
     ...getSearchFilter,
     city_address_or_location: "",
     search_term: "",
     latitude: null,
     longitude: null,
    })
   );
  }
 }, [searchLocation]);

 useLayoutEffect(() => {
  if (!!isLoggedIn && updateModal === false) {
   setUpdateModal(true);
  }
 }, []);

 const handleMapVisibility = (setAs) => {
  if (!isDesktop) {
   setIsMapVisible(setAs);
  }
 };

 const checkIsDesktop = () => {
  let desktopWidth = window.innerWidth;
  if (desktopWidth > 600) {
   setIsDesktop(true);
  } else {
   setIsDesktop(false);
   if (isMapVisible === null) {
    setIsMapVisible(false);
   }
  }
 };

 const getSignedUp = () => {
  setConfirmAction(false);
  props.history.push("/signup/tenant");
 };
 const getData = (url, search_data, page = 0) => {
  // setAddress(search_data);
  const data = {
   ...getSearchFilter,
   search_term: search_data,
  };
  if (page) {
   setShowBottomLoader(true);
  } else {
   setIsLoading(true);
  }

  postAPI(
   page ? `${url}?page=${page + 1}` : url,
   data
  )
   .then((response) => {
    if (page) {
     setCurrentPage(page);
    }
    const res = response.data.data;
    setPropertyLists(
     page
      ? {
         next_page_url: res.next_page_url,
         data: [...listData.data, ...res.data],
        }
      : res
    );
    setIsLoading(false);
    setShowBottomLoader(false);
   })
   .catch((error) => {
    log.info("error", error);
    setShowBottomLoader(false);
   });
 };

 const getResults = () => {
  setSelectedId(null);
  setCurrentPage(0);
  setIsLoading(true);
  setPropertyLists({});
  const url = "/tenant/search-property";
  getData(url, searchLocation);
 };

 const filter = () => {
  setTenantFilter(!tenantFilter);
 };
 const propertyDetails = (
  property_id,
  property_details_id
 ) => {
  if (
   isLoggedIn &&
   parseInt(Cookies.get("role")) === 3
  ) {
   const win = window.open(
    "/property-details/" +
     property_id +
     "/" +
     property_details_id +
     "/details"
   );
   win.focus();
  } else {
   const win = window.open(
    "/property-details/" +
     property_id +
     "/" +
     property_details_id +
     "/details/tour"
   );
   win.focus();
  }
 };

 const apiCallBack = (res) => {
  setPropertyLists(res.data.data);
  filter();
 };

 const onSkipUpdateInfo = () => {
  Cookies.set("skip_update_info", true);
  setUpdateModal(false);
 };

 const getClickedId = async (id) => {
  const isPropertyPresentInList = isPropertyPresent(
   listData.data,
   id
  );

  if (!isPropertyPresentInList) {
   setSelectedId(null);
   const res = await getPropertyDetails(id);
   // add new item at second last position
   const list = listData.data || [];
   const lastIndex = list.length - 1;
   list.push(list[lastIndex]);
   list[lastIndex] = res;
   // add new item at second last position

   setPropertyLists({ ...listData, data: list });
  }
  setTimeout(
   () => {
    setSelectedId(id);
    if (!isDesktop) {
     const seclectedItem =
      horizontalRef.current.children[
       "card_item_" + id
      ];
     seclectedItem.scrollIntoView({
      behavior: "smooth",
      block: "start",
     });
     return;
    }
    const proprty_id = "#property_card_" + id;
    const section = document.querySelector(
     proprty_id
    );
    section.scrollIntoView({
     behavior: "smooth",
     block: "start",
    });
   },
   !isPropertyPresentInList ? 300 : 0
  );
 };

 const handleDropdownSelect = (name, key) => {
  setSortName(name);
  setSelectedId(null);
  const url =
   "/tenant/search-property?sort_order=" + key;
  getData(url, searchLocation);
 };

 const handlePlaceChanged = (place) => {
  const updatedLocation = setPlaceChanged(
   place,
   dispatch,
   getSearchFilter
  );
  if (place.geometry) {
   setSelectedLocation({ ...updatedLocation });
  }
 };

 const handleChange = (event) => {
  event.target.setAttribute(
   "autocomplete",
   "off"
  );
  dispatch(setSearchLocation(event.target.value));
 };

 const handleClick = (e) => {
  if (
   showBottomLoader ||
   !listData.next_page_url
  ) {
   e.preventDefault();
   return;
  }
  // const isBottom = checkIsBottom(e);
  // if (isBottom) {
  const url = "/tenant/search-property";
  getData(url, searchLocation, currentPage + 1);
  // }
 };

 const handleBlur = (event) => {
  if (
   !event.currentTarget.contains(
    event.relatedTarget
   )
  ) {
   setSelectedId("");
  }
 };

 const getMarkerDataHandler = async () => {
  const markerDataList = await getMarkerData(
   filterData
  );
  setMarkerData(markerDataList);
 };

 const mapContainerClass = `map-container ${
  !isDesktop && isMapVisible === false
   ? "d-none"
   : "d-block"
 }`;

 return (
  <>
   <Helmet>
    <meta
     name="description"
     content="Search through verified houses, rooms, condos, or 
        apartments for rent to find your next home, like a Panda trying to find the perfect piece of bamboo."
    />
   </Helmet>
   <AppLayout
    customClass={`${
     isLoggedIn
      ? "search-listing-page"
      : "search-listing-no-auth"
    }`}
   >
    <div className="search-listing">
     <SearchListHeader
      searchLocation={searchLocation}
      sortOptions={sortOptions}
      handleMapVisibility={handleMapVisibility}
      isDesktop={isDesktop}
      isMapVisible={isMapVisible}
      handleChange={handleChange}
      handlePlaceChanged={handlePlaceChanged}
      filter={filter}
      handleDropdownSelect={handleDropdownSelect}
      sortName={sortName}
     />
     <div
      onBlur={handleBlur}
      className={`search-listing-body ${
       !isDesktop && isMapVisible === true
        ? "sm-map-view"
        : "t-mb-44"
      }`}
      // onScroll={handleScroll}
     >
      <DesktopListView
       showBottomLoader={showBottomLoader}
       propertyDetails={propertyDetails}
       isLoading={isLoading}
       isDesktop={isDesktop}
       isMapVisible={isMapVisible}
       listData={listData}
       selectedId={selectedId}
       handleClick={handleClick}
       cityData={cityData}
      />
      <div className={mapContainerClass}>
       <SearchMapListing
        selectedLocation={selectedLocation}
        height={"calc(100vh - 160px)"}
        details={markerData}
        getClickedId={(id) => getClickedId(id)}
        isMapVisible={isMapVisible}
        className="map-height-mobile"
       />

       {!isDesktop && (
        <>
         <div className="d-block d-sm-none">
          <div className="row  iconpanel">
           <div className="listingicon cursorPointer">
            <Image
             className="img-reponsive t-sort"
             src={require("../../../_assets/images/showlist.png")}
             alt="list-btn"
             onClick={() =>
              handleMapVisibility(false)
             }
            />
           </div>
           <div
            className="sorting cursorPointer"
            onClick={() => filter()}
           >
            <img
             className="img-reponsive t-sort"
             src={require("../../../_assets/images/whitefliter.png")}
             alt="sort-btn"
            />
           </div>
          </div>
         </div>
         <MobileListView
          listData={listData}
          propertyDetails={propertyDetails}
          selectedId={selectedId}
          horizontalRef={horizontalRef}
         />
        </>
       )}
      </div>
     </div>
    </div>
   </AppLayout>
   <FilterTenantProperty
    isShow={tenantFilter}
    setSelectedId={(data) => setSelectedId(data)}
    handleClose={() => filter()}
    apiCallBack={(data) => apiCallBack(data)}
   />
   <ModalBox
    show={confirmAction}
    onHide={() => setConfirmAction(false)}
    size="sm"
    actionbuttontitle={"get started"}
    buttonaction={() => getSignedUp()}
   >
    {role === 2 ? (
     <>
      <h4>Looks like you're a landlord</h4>
      <p>
       Please sign up as a tenant with another
       <br />
       email address to engage with a listing
      </p>
     </>
    ) : (
     <>
      <h4>Sign up for free</h4>
      <p>
       create an account and get all the tools
       <br />
       you need to find the perfect home
      </p>
     </>
    )}
   </ModalBox>

   <ModalBox
    show={
     updateModal &&
     !isProfileCompleted &&
     UpdateInfoCookie === undefined &&
     !UpdateInfoCookie
    }
    onHide={() => setUpdateModal(false)}
    size="sm"
    buttonaction={() => viewProfile()}
    // backaction={onSkipUpdateInfo}
    actionbuttontitle="review your profile"
    // backbuttontitle="skip"
   >
    <h4>important update</h4>
    <p style={{ marginBottom: -26 }}>
     We've done some updates to the
     <br /> profile criteria to make certain
     sections
     <br /> optional, while others are mandatory.
     <br /> These updates are meant to make the
     <br /> application process easier and faster.
     <br /> Review your profile and you'll be on
     <br /> your way to finding the perfect home
    </p>
   </ModalBox>
  </>
 );
};

export default withRouter(SearchListing);
