import "../ChartsStyle.scss"
import React from "react"


const PredictionComparison = ({data, name, chartData}) => {
  console.log(data)
  
  const camelCase = (str) => {
    const words = str.split(" ")
    const camelCasedWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
    })
    return camelCasedWords.join("")
  }

  const normalizeObjProperties = (obj) => {
    console.log(obj)
    // make all keys lowercase
    const normalizedObj = {}
    for (const key in obj) {
        normalizedObj[cleanString(camelCase(key))] = obj[key]
    }
    
    return normalizedObj
  }

  const cleanString = (str) => {
    if (str) {
      const regex = /[^a-zA-Z0-9]/g
      
      return str.replace(regex, "")
    }

  }

  // #B4202C - threshold > 60
  // #D55F67 - threshold 50-60
  // #EA863C - threshold 40-50
  // #FDE194 - threshold 30-40
  // #78A569 - threshold 20-30
  // #4C7E3C - threshold < 20
  const getColor = (number) => {
    number = parseInt(cleanString(number))
    console.log(number)
    if (number > 60) {
      return "#B4202C"
    } else if (number > 50) {
      return "#D55F67"
    } else if (number > 40) {
      return "#EA863C"
    } else if (number > 30) {
      return "#FDE194"
    } else if (number > 20) {
      return "#78A569"
    } else {
      return "#4C7E3C"
    }
  }

  const invertTextColor = (number) => {
     if (parseInt(cleanString(number)) <= 40) {
      return "#000"
     } else {
      return "#FFF"
     }
  }

  const renderRow = (row, index) => {
    const normalizedRow = normalizeObjProperties(row)
    console.log(normalizedRow)
    if (normalizedRow.city) {
      return (
          <tr key={index}>
            <td className={"cityRow"}>{normalizedRow.city}</td>
            <td className={"percentRow"} style={{ background: `${getColor(normalizedRow.forecastedRentChangeq12023)}`, color: `${invertTextColor(normalizedRow.forecastedRentChangeq12023)}`}}>
              {normalizedRow.forecastedRentChangeq12023}
            </td>
            <td className={"percentRow"} style={{ background: `${getColor(normalizedRow.actualRentChangeq12023)}`, color: `${invertTextColor(normalizedRow.actualRentChangeq12023)}`}}>
              {normalizedRow.actualRentChangeq12023}
            </td>
          </tr>
      )
    } else return null
  }


  return (
    <div className={"reportSection"} id={name}>
      <h3 className={"chapterHeader"}>{data.title}</h3>
      <div className={"sectionContent"}>
        <div className="tableContainer">
          <table id={"predictionComparisonTable"}>
            <thead>
            <tr>
              <th>Cities</th>
              <th>Forecasted Rent % Change (Q1 2023)</th>
              <th>Actual Rent % Change (Q1 2023)</th>
            </tr>
            </thead>
            <tbody>
            {chartData.data.map((row, index) => renderRow(row, index))}
            </tbody>
          </table>
        </div>
        
        <div className="appendix">
          <p className="appendixText">
            {data.data.appendixText}
          </p>
        </div>

      </div>
    </div>

  )
}

export default PredictionComparison