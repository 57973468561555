import {
    SET_DEACTIVE_REASON,
} from "../actions/Action";

export const initialState = {
    deactiveReason: null,
};

export default function deactiveReasonReducer(state = initialState, action) {
    const handlers = {
        [SET_DEACTIVE_REASON]: (state) => ({
            ...state,
            deactiveReason: action.deactiveReason,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}