import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { logger, toaster } from '../../../../Utils';
import Switch from "react-switch";
import './style.scss';
import { getAPI, postAPI } from '../../../../Api';
import { FileBrowse, LargeButton, ModalBox } from '../../../components';
import Axios from 'axios';
import { IMAGE_API_URL } from '../../../../Constants/endPoints';
import Cookies from 'js-cookie';

const log = logger('PhotographyTips');
const PhotographyTips = (props) => {
	const [showTips, setShowTips] = useState(false);
	const [tipsList, setTipsList] = useState([]);
	const [tipsListDuplicate, setTipsListDuplicate] = useState([]);
	const [imageData, setImageData] = useState({ file: '', percent: 0 });
	const [modal, setModal] = useState(false);
	const [openConfirm, setConfirm] = useState(false);
	const [loader, setLoader] = useState(false);
	const [showImageLoading, setShowImageLoading] = useState(false)
	log.info('tipsList===>', tipsList)
	const handleShowTips = () => {
		setShowTips(!showTips);
		setModal(true)
	}
	const tipsAddDelete = (action, index, tip) => {
		if (action === 'add') {
			setTipsList(tipsList => [...tipsList, { image_url: '', description: '', id: null, loader: false }])
		} else if (action === 'remove') {
			if (tip.id) {
				const data = {
					"id": tip.id,
				}
				postAPI(`/admin/cms/photography-tips/delete`, data).then(res => {
					if (res.status === 200) {
						tipsList.splice(index, 1);
						setTipsList(tipsList => [...tipsList])
						getAPI(`/admin/cms/photography-tips/list`).then(response => {
							if (response.status === 200) {
								log.info(response.data.data)
								const res = response.data.data;
								setShowTips(Number(res.show_hide_tips.value) === 0 ? false : true);
								setTipsList(res.tips)
							}
						})
					}
				})
			} else {
				tipsList.splice(index, 1);
				setTipsList(tipsList => [...tipsList])
			}

		}
	}
	const save = (data) => {
		log.info(data)
		const passData = {
			"image_url": data.image_url,
			"description": data.description
		}
		let url = '';
		let toast = '';
		if (data.id) {
			url = '/admin/cms/photography-tips/update';
			toast = 'Data updated successfully'
			passData.id = data.id
		} else {
			url = '/admin/cms/photography-tips/create';
			toast = 'Data saved successfully'
		}
		postAPI(url, passData).then(res => {
			if (res.status === 200) {
				toaster('success', toast);
				getAPI(`/admin/cms/photography-tips/list`).then(response => {
					if (response.status === 200) {
						log.info(response.data.data)
						const res = response.data.data;
						setShowTips(Number(res.show_hide_tips.value) === 0 ? false : true);
						setTipsList(res.tips)
					}
				})
			}
		})

	}
	const handleDrop = (file, index, tip) => {
		tipsList[index] = {
			image_url: '',
			description: tip.description,
			id: tip.id,
			loader: true
		};
		setTipsList(state => tipsList);
		setLoader(true);
		const token = `Bearer ${Cookies.get('access_token')}`
		const headers = {
			'Authorization': token,
			'Content-Type': 'multipart/form-data'
		}
		setShowImageLoading(true)
		file.map((dataFile) => {
			let formdata = new FormData();
			formdata.append('image', dataFile);
			let url = IMAGE_API_URL;
			Axios.post(
				url, formdata, {
				headers: headers,
				onUploadProgress: progressEvent => {
					var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					if (percent >= 100) {
						setImageData({ percent: percent });
						setShowImageLoading(false)
						return file;
					} else {
						setImageData({ percent: percent });
						setShowImageLoading(false)
						return null;
					}
				}
			})
				.then(response => {
					setImageData({ file: response.data.data.url })

					// setTipsList(tipsList);
					tipsList[index] = {
						image_url: response.data.data.url,
						description: tip.description,
						id: tip.id,
						loader: false
					};
					setTipsList(state => tipsList);
					setLoader(false);

				})
				.catch(error => {
				});
			return dataFile;
		})
	}
	useEffect(() => {
		setTipsListDuplicate(tipsList);
	}, [tipsList])
	const removeImage = (e, index, tip) => {
		const list = [...tipsList]
		list[index] = {
			image_url: '',
			description: tip.description,
			id: tip.id
		};
		setTipsList(list)
	}
	const OnChangeInput = (event, index, tip) => {
		const list = [...tipsList]
		list[index] = {
			image_url: tip.image_url,
			description: event,
			id: tip.id
		};
		setTipsList(list)
	}
	const preview = () => {
		props.history.push('/photography-tips');
	}
	const goBackActivate = () => {
		const data = {
			"value": showTips === false ? 0 : 1
		}
		setModal(false)
		postAPI(`/admin/cms/photography-tips/show-hide-tips`, data).then(response => {
			if (response.status === 200) {
				setConfirm(true)
				getAPI(`/admin/cms/photography-tips/list`).then(response => {
					if (response.status === 200) {
						log.info(response.data.data)
						const res = response.data.data;
						setShowTips(Number(res.show_hide_tips.value) === 0 ? false : true);
						setTipsList(res.tips);
					}
				})
			}
		})
	}
	const handleCloseActive = () => {
		setModal(false)
		getAPI(`/admin/cms/photography-tips/list`).then(response => {
			if (response.status === 200) {
				log.info(response.data.data)
				const res = response.data.data;
				setShowTips(Number(res.show_hide_tips.value) === 0 ? false : true);
				setTipsList(res.tips)
			}
		})
	}
	useEffect(() => {
		getAPI(`/admin/cms/photography-tips/list`).then(response => {
			if (response.status === 200) {
				log.info(response.data.data)
				const res = response.data.data;
				setShowTips(Number(res.show_hide_tips.value) === 0 ? false : true);
				setTipsList(res.tips)
				log.info("update tips list", tipsList);
			}
		})
	}, [props.componentLoad])

	return (
		<div className='photography-tips-main'>
			<Row className='mt-4'>
				<Col md={12}>
					<div className="d-flex justify-content-between align-items-center mobileblock">
						<div>
							<div className='inner-wrapper d-flex justify-content-between align-items-center'>
								<div>
									<h6>Show tips to Landlords  </h6>
									<h4 className="switch-heading">link inactive</h4>
								</div>
								<div>
									<Switch
										uncheckedIcon={false}
										checkedIcon={false}
										className="react-switch"
										onChange={() => handleShowTips()}
										checked={showTips === null ? false : showTips} height={48} width={87} />
								</div>
							</div>
						</div>
						<div>
							<Button type='button' onClick={() => preview()}><img src={require('../../../../_assets/images/white-eye.svg')} alt="whiteEye" /> preview</Button>
						</div>
					</div>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col md={12}>
					<div>
						{tipsListDuplicate.length === 0 ?
							<div className={'inner-wrapper second d-flex'}>
								<div className='create-flex'>
									<h4>You currently have no photography tips </h4>
									<Button className='btn-second' onClick={() => tipsAddDelete('add')}>create</Button>
								</div>
							</div> :
							<>
								{loader === true ? <div></div> : <span></span>}
								{tipsListDuplicate.length > 0 ?
									tipsListDuplicate.map((tip, index) => {
										log.info('tips', tip.loader)
										return (
											<div className={'inner-wrapper second mb-3'} key={'tip_' + index}>
												<Row>
													<Col md={12}>
														<Form.Group>
															<Form.Label className='d-flex justify-content-between'>
																<span className='video-link-text'>tip # {index + 1}</span>
																<span className='d-flex align-items-center'>
																	{log.info(tip.id)}
																	{tip.id ? <Image
																		className='cursorPointer ml-2'
																		width='15px'
																		height='15px'
																		src={require('../../../../_assets/images/edit-pen.svg')}
																		onClick={() => save(tip)}
																	/> :
																		<LargeButton
																			title={'save'}
																			isIcon={false}
																			variant='dark'
																			style={{ width: '83px', height: '24px', margin: '0px' }}
																			onClick={() => save(tip)} />}
																	<Image
																		className='cursorPointer ml-2'
																		width='13px'
																		height='15px'
																		src={require('../../../../_assets/images/delete-icon.svg')}
																		onClick={() => tipsAddDelete('remove', index, tip)}
																	/>
																</span>
															</Form.Label>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col md={4}>
														{/* {tip.loader === true ? 'Loading...' : 'Done'}
                                                        {log.info(index, tip.loader == true ? 'true' : 'false')} */}
														{/* {tip.loader === false && */}
														<FileBrowse
															handleDrop={(e) => handleDrop(e, index, tip)}
															size={'sm'}
															title={'Attach Image'}
															percent={0}
															multiple={false}
															fileType={'.jpg,.png,.jpeg'}
															disable={false}
															imageData={tip.image_url && tip.image_url}
															showImageLoading={showImageLoading}
															removeImage={(e) => removeImage(e, index, tip)}
															lableClass={'custom-lable'}
														/>
														{/* } */}
													</Col>

													<Col md={8}>
														<Form.Group as={Col} md='12'>
															<Form.Label className='ml-2'>Description:</Form.Label>
															<Form.Control
																as="textarea"
																rows="8"
																placeholder="Write your photography tips here"
																name="description"
																id={"descriptionFaq" + index}
																value={tip.description}
																onChange={(e) => OnChangeInput(e.target.value, index, tip)}
															/>
														</Form.Group>
													</Col>
												</Row>
											</div>
										)
									})
									: ''}
							</>
						}
					</div>
					{tipsList.length !== 0 && <LargeButton
						title={'Add another tip'}
						isIcon={true}
						variant='dark'
						style={{ width: '335px' }}
						onClick={() => tipsAddDelete('add')} />}
				</Col>
			</Row>

			<ModalBox
				show={openConfirm}
				onHide={() => setConfirm()}
				size="sm"
				actionbuttontitle={"got it"}
				buttonaction={() => setConfirm()}
				extraClassUpper={'verified'}
			>
				{showTips === true ?
					<p className="switching-details">Landlord now able to see Photography tips</p> :
					<p className="switching-details">Landlord now not able to see Photography tips</p>}
			</ModalBox>
			<ModalBox
				show={modal}
				closeButtonHeader={false}
				size="sm"
				type="condition"
				actionbuttontitle={"Yes"}
				buttonaction={() => goBackActivate()}
				backbuttontitle={'Cancel'}
				backaction={() => handleCloseActive()}
				extraClassUpper={'activate-deactivate'}
			>
				<p className="switching-details">Are you sure you want to {showTips !== false ? 'show' : 'hide'} photography tips {showTips !== false ? 'to' : 'from'} landlord? </p>
			</ModalBox>
		</div>
	)

}

export default withRouter(PhotographyTips);