import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import {
  CostNumberFormat,
  DatePickerComponent,
  LargeButton,
  SingleOptionCheck,
} from "../..";
import { numberFormatSanitizerInt } from "../../../../Utils/NumberSanitizer/NumberSanitizer";
import { frequencyType } from "../../../../Constants/NoticeConstants";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { logger, toaster, dateFormat, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { N3Schema } from "./ValidationSchema/N3Schema";
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const log = logger("N3Form");
const N3Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack,
    onNoticeDataUpdate
  } = props;
  const [errors, setErrors] = useState({})
  const [globalState,setGlobalState]=useGlobalState()
  const [date, setDate] = useState(null);
  const [rent, setRent] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [other, setOther] = useState("");
  const [need_approval, setNeed_approval] = useState(1);
  const [phoneError, setPhoneError] = useState(false);
  const [newRent, setNewRent] = useState("");
  const [newFrequency, setNewFrequency] = useState(1);
  const [newOther, setNewOther] = useState("");
  const [amount, setAmount] = useState("");
  
  //Signature Data
  const [signData, setSignData] = useState(null);
  log.info("signData", signData);


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(() => {
    onNoticeDataUpdate({
      when_rent_increase:date||'',
      new_rent_rate: rent,
      frequency, 
      frequency_text: other,
      need_approval, 
      phoneError, 
      charges_for_care_services:newRent, 
      signData, 
      rent_increase_pay_total_amount:amount, 
      frequency_for_additional_charges_other_text:newOther,
      frequency_for_additional_charges:newFrequency,
      notice_type:3
    })
    setGlobalState({ onSubmit: onSubmit })

  }, [date, rent, frequency, other, need_approval, phoneError, newRent, signData, amount, newOther, newFrequency,tenant_id.length,property_id])

  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})
    if (frequency === 3 || newFrequency === 3) {

      setErrors({ other: !other && frequency === 3, newOther: !newOther && newFrequency === 3 })
    }

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N3Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        notice_id: noticeData && noticeData.id,
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 3,
        when_rent_increase: dateFormat(date||''),
        new_rent_rate: rent,
        frequency: frequency,
        frequency_text: other,
        need_approval: need_approval,
        charges_for_care_services: newRent,
        frequency_for_additional_charges: newFrequency,
        frequency_for_additional_charges_other_text: newOther,
        rent_increase_pay_total_amount: amount,
        full_name: signData && (signData?.signingName || signData?.lastName),
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 15,
      };

      postAPI(url, data).then((response) => {
        if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, Date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    if (name === "frequency") {
      setFrequency(Value);
    } else if (name === "newFrequency") {
      setNewFrequency(Value);
    }
  };

  useEffect(() => {
    if (noticeData) {
      if(noticeData.when_rent_increase && noticeData.when_rent_increase!=='Invalid date')
        setDate(moment(noticeData.when_rent_increase).toDate());
      
      setRent(noticeData.new_rent_rate);
      setFrequency(noticeData.frequency || 1);
      setOther(noticeData.frequency_text);
      setNeed_approval(noticeData.need_approval || 1);
      setNewRent(noticeData.charges_for_care_services);
      setNewFrequency(noticeData.frequency_for_additional_charges);
      setNewOther(noticeData.frequency_for_additional_charges_other_text);
      setAmount(noticeData.rent_increase_pay_total_amount);
      setSignData(noticeData.signData)
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Rent Including Charges For Care Services And Meals (N3 Form)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            extraClass={'get-paralegal-help-btn'}
            onClick={getParalegal}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>When is the rent increasing?</Form.Label>
              <DatePickerComponent
                id="date"
                name="date"
                format={"dd/MM/yyyy"}
                value={date || ''}
                handleDateChange={(date) => handleDateChange(date, setDate)}
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-end"
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>New rent rate will increase to</Form.Label>
              <CostNumberFormat
                name={"rent"}
                id={"rent"}
                classname="form-control"
                value={rent ? rent : ""}
                updateField={(e) =>
                  setRent(e.target.value)
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"frequency"}
                setParam={frequency}
                extra={
                  frequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="other"
                        id="other"
                        value={other}
                        style={{ borderColor: errors.other ? 'red' : '' }}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Choose one of the following</Form.Label>
              <Form.Check
                key={"need_approval_1"}
                type="radio"
                id={`need_approval_1`}
                name="need_approval"
                checked={need_approval === 1 && 'checked'}
                onChange={() => {
                  setNeed_approval(1);
                }}
                label={"This new rent does not need approval."}
              />
              <Form.Check
                key={"need_approval_2"}
                type="radio"
                id={`need_approval_2`}
                name="need_approval"
                checked={need_approval === 2 && 'checked'}
                onChange={() => {
                  setNeed_approval(2);
                }}
                label={
                  "This new rent needs approval by an order under the Residential Tenancies Act, 2006."
                }
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="d-flex justify-content-between mb-3 mt-3">
            <span className="form-title">Additional Charges Information</span>
          </div>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                Charges for care services and/or meal will increase to
              </Form.Label>
              <CostNumberFormat
                name={"newRent"}
                id={"newRent"}
                classname="form-control"
                value={newRent ? newRent : ""}
                updateField={(e) =>
                  setNewRent(e.target.value)
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"newFrequency"}
                setParam={newFrequency}
                extra={
                  newFrequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="newOther"
                        id="newOther"
                        style={{ borderColor: errors.newOther ? 'red' : '' }}
                        value={newOther}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setNewOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                On the date of the increase, the tenant should pay a total
                amount of:
              </Form.Label>
              <CostNumberFormat
                name={"amount"}
                id={"amount"}
                classname="form-control"
                value={amount ? amount : ""}
                updateField={(e) =>
                  setAmount(e.target.value)
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
              <p className={'N-form3-p'}>This price will include rent, meals and care services</p>
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={3}
            type="lastName"
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N3Form);
