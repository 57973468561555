import React from "react";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Google_Map_API } from "../../../../Constants/endPoints";
import { Loader } from "../../../components";
import { logger } from "../../../../Utils";
import { getBounds, getLocationsUnder20km } from "./helper";

const log = logger("SearchMapListing");
const SearchMapListing = (props) => {
    const { details = [], height, getClickedId, selectedLocation = {}, isLoading = false } = props;
    const image = {
        url: require("../../../../_assets/images/map-pin-icon.svg"),
        size: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(0, 32),
    };
    const onClickMarker = (value) => {
        getClickedId(value);
    }

    const getMapBounds = React.useCallback((details) => {
        const nearestMarkers = getLocationsUnder20km(details, selectedLocation, props.google?.maps)
        const markers = Object.keys(selectedLocation || {}).length && nearestMarkers?.length ? nearestMarkers : details
        console.warn({ markers });
        const latLongs = markers && markers?.map((i) => ({ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) }))
        const bounds = getBounds(latLongs, props.google?.maps)
        return bounds
    }, [details, selectedLocation])

    const bounds = getMapBounds(details?.length ? details : selectedLocation ? [{ latitude: selectedLocation?.lat, longitude: selectedLocation?.lng }] : [])

    // default lat long ontario 
    const center =
        Object.keys(selectedLocation || {}).length ? selectedLocation :
            {
                lat: 51.28208131617533,
                lng: -85.50526684500822
            };
    return (
        <>
            {details?.length ? <Map
                google={props.google}
                bounds={bounds}
                zoom={3}
                initialCenter={center}
                center={center}
                centerAroundCurrentLocation={true}
                className="map-height-mobile"
                containerStyle={{
                    height: height ? height : '60vh',
                    position: 'relative',
                    width: '100%'
                }}>
                {details && details?.map((latlng, i) => {
                    return <Marker
                        key={"marker_" + i}
                        title={latlng.address}
                        icon={image}
                        position={{
                            lat: latlng.latitude,
                            lng: latlng.longitude
                        }}
                        onClick={() => onClickMarker(latlng.property_details_id)}
                    />
                })}

            </Map> :
                <Map
                    initialCenter={center}

                    google={props.google}
                    zoom={Object.keys(selectedLocation || {}).length ? 10 : 5}
                    center={{ ...selectedLocation }}
                    containerStyle={{
                        height: height ? height : '60vh',
                        position: 'relative',
                        width: '100%'
                    }} />

            }

        </>
    );
}

const MemorizedComponent = React.memo(GoogleApiWrapper({
    apiKey: (Google_Map_API),
    LoadingContainer: Loader
})(SearchMapListing), (prevProps, nextProps) => {
    const isSelectedLocationIsThere = nextProps?.selectedLocation || nextProps?.searchLocation?.length
    const isDetailsAreSame = prevProps.details?.length === nextProps.details?.length
    if (Object.keys(nextProps?.selectedLocation || {}).length) {
        if ((nextProps?.selectedLocation?.lat !== prevProps?.selectedLocation?.lat) && (nextProps?.selectedLocation?.lng !== prevProps?.selectedLocation?.lng)) {
            return false
        }
    }

 
    if(prevProps.isMapVisible!==nextProps.isMapVisible)
        return false

    if (isSelectedLocationIsThere && nextProps?.searchLocation !== prevProps?.searchLocation)
        return false

    return isDetailsAreSame

})

export default MemorizedComponent