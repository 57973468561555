import React from "react";
import { Button, Image } from "react-bootstrap";
import "./style.scss";

const EditButton = (props) => {
  const { onClick, extraClass } = props
  return (
    <Button variant={"dark"} className={`edit-btn ${extraClass}`} onClick={onClick}>
      <Image className="icon" src={require("../../../../_assets/images/edit-icon.svg")} alt="edit" />
          EDIT
    </Button>
  );
}
export default EditButton;