import React from 'react';
import { AppLayout, PageTitleHeader, LargeButton } from '../..';
import '../style.scss';
import { withRouter } from "react-router-dom";

const N2Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (<>
   <AppLayout>
    <PageTitleHeader
      isArrow={true}
      onClick={() => back()}
      title={'Important Information About the Law'}
      subTitle={'Unit Partially Exempt (N2 Form)'}/>

      <div className="notice-info-card">
        <ol>
          <li>The guideline for rent increases set by the Ministry of Municipal Affairs and Housing does not apply to tenants who live in rental units that are partially exempt from the Residential Tenancies Act, 2006. In these cases, the landlord can raise the rent by any amount.</li>
          <li>The landlord must give the tenant this notice at least 90 days before the date of the rent increase (where applicable).</li>
          <li>A tenant does not have to sign a new lease when a fixed term tenancy ends. If the tenant decides not to sign a new lease, the tenant does not have to move, but the tenancy becomes "month-to-month".</li>
          <li>If a tenant plans to move, the tenant must notify the landlord on Form N9 (Tenant's Notice to End the Tenancy) at least 60 days before the lease expires if the tenant has a fixed term tenancy or 60 days before the end of a monthly or yearly rental period. The tenant must notify the landlord on Form N9 at least 28 days before the end of a weekly rental period.</li>
          <li>If you have any questions about the law related to rent increases and how it applies to this notice, you may contact the Landlord and Tenant Board at <strong>416-645-8080</strong> or toll-free at <strong>1-888-332-3234</strong>. Or, you may visit the Board's website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a> for further information.</li>
        </ol>
      </div>
      <div className="d-flex justify-content-end button-extra-margin">
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}
        />
      </div>
  </AppLayout>
  </>);
}

export default withRouter(N2Info);