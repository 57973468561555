import React, { Component } from "react";

class RpImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIsReady: false,
    };
  }

  componentDidMount() {
    const img = new Image();
    img.src = this.props.src;
    img.onload = () => {
      this.setState({ imageIsReady: true });
    };
  }

  render() {
    const { imageIsReady } = this.state;
    if (!imageIsReady) {
      return <div>loading...</div>;
    } else {
      return (
        <img
          width={this.props.width}
          height={this.props.height}
          alt={this.props.alt || "Rent Panda"}
          src={this.props.src}
          style={this.props.style}
          className={"image-background-grey" && this.props.className}
        />
      );
    }
  }
}

export default RpImage;
