import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import "./style.scss";

const NavbarSortingDropdown = (props) => {
    const { handleDropdownSelect, sortOptions, sortName, extraClass } = props
    return (
        <Navbar className={"filter-nav " + extraClass}>
            <Navbar.Collapse className="d-flex justify-content-between">
                <Navbar.Text>posted date:</Navbar.Text>
                <NavDropdown title={sortName} id="nav-dropdown">
                    {sortOptions.map((options, i) => {
                        return <NavDropdown.Item onClick={() => handleDropdownSelect(options.display, options.key)} key={i}>{options.display}</NavDropdown.Item>
                    })}
                </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default NavbarSortingDropdown;