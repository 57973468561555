import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AppLayout } from "../../components";
import './style.scss';
import AllListing from './AllListing';
import Rented from './Rented';
import Promoted from './Promoted';
import Inactive from './Inactive';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, setComponent, setListingCurrentPage, setRentedCurrentPage, setPromotedCurrentPage, setInactiveCurrentPage } from '../../../Store/actions/ListingAction';
import { setCouponFilters } from '../../../Store/actions/CouponAction';

const ListingManagement = (props) => {
    const [componentLoad, setComponentLoad] = useState('all-listing');
    const dispatch = useDispatch();
    const currentComponent = useSelector(state => state.listingReducer.currentComponent)

    useEffect(() => {
        const slug = props.match.params.slug;
        setComponentLoad(slug);
        dispatch(setComponent(slug));
        if(currentComponent !== slug){
            dispatch(setFilters({
                searchCity: '',
                searchAddress: '',
                searchLandlord: '',
                searchTenant: '',
                searchRentpandaId: '',
                is_verified: false,
                sort_by_price_order: '',
                sort_by_status: ['all'],
                filterByExpiryDate: ''
            }));
        }
    }, [props, dispatch, currentComponent]);

    const pathName = window.location.pathname
    useEffect(() => {
        if (!pathName.includes('coupon-management')) {
            dispatch(setCouponFilters({
                coupon_name: '',
                created_date: 'desc',
                no_of_usage: '',
                total_no_of_usage: '',
                start_date: '',
                expiry_date: '',
                coupon_code: '',
                coupon_value: '',
            }))
        }
    },[pathName, dispatch])


    return (
        <AppLayout>
            <div className="userManagement">
                <Nav fill variant="tabs" className="user-tab-views list-management pt-2 tab-space-hover">
                    <NavLink to={{ pathname: "/listing-management/all-listing" }} activeclassname={'active'} className={componentLoad === "all-listing" || componentLoad === undefined ? "nav-link active" : "nav-link"}>all listing</NavLink>
                    <NavLink to={{ pathname: "/listing-management/rented" }} activeclassname={'active'} className={"nav-link"} >rented</NavLink>
                    <NavLink to={{ pathname: "/listing-management/promoted" }} activeclassname={'active'} className={"nav-link"} >promoted</NavLink>
                    <NavLink to={{ pathname: "/listing-management/inactive" }} activeclassname={'active'} className={"nav-link"} >inactive</NavLink>
                </Nav>

                <div className="table-container">
                    <h2 style={{ textTransform: 'uppercase' }}>Listing management</h2>
                    {componentLoad === "all-listing" || componentLoad === undefined ? <AllListing componentLoad={componentLoad} /> : ""}
                    {componentLoad === "rented" ? <Rented componentLoad={componentLoad} /> : ""}
                    {componentLoad === "promoted" ? <Promoted componentLoad={componentLoad} /> : ""}
                    {componentLoad === "inactive" ? <Inactive componentLoad={componentLoad} /> : ""}
                </div>
            </div>
        </AppLayout>
    )
}

export default withRouter(ListingManagement);
