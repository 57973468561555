import React, {
 useState,
 useEffect,
} from "react";
import { withRouter } from "react-router-dom";
import {
 Image,
 Accordion,
 Card,
 Button,
} from "react-bootstrap";
import Layout from "../../components/Layout";
import { getAPI } from "../../../Api";
import { Loader } from "../../../App/components";
import { logger } from "../../../Utils";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import "../style.scss";

const log = logger("HowItWorksScreening");
const HowItWorksScreening = (props) => {
 const [faqList, setFaqList] = useState([]);
 const [accordianId, setAccordionId] = useState(
  null
 );
 const [isLoading, setIsLoading] = useState(
  false
 );

 const clickExterior = (id) => {
  const check = id === accordianId ? null : id;
  setAccordionId(check);
 };
 useEffect(() => {
  setIsLoading(true);
  getAPI("/cms/faq/list?category=8").then(
   (res) => {
    if (res.status === 200) {
     setFaqList(res.data.data);
    }
    setIsLoading(false);
    log.info("/cms/faq/list > res ", res);
   }
  );
 }, []);

 return (
  <>
   <Helmet>
    <title>
     How It Works For Tenant Screening | Rent Panda
    </title>
    <meta
     name="description"
     content="Rent Panda is a free rental platform for tenants. 
            Browse through hundreds of verified houses, rooms, condos, or apartments for rent to near you."
    />
   </Helmet>
   <div className="howItWorksPage">
    <Layout newHeader={true}>
     <section className="sec-point-list cu-container-wrap">
      <div className="container">
       <div className="tenantHeader">
        <h1>
         tenant screening at your fingertips.
        </h1>
        <p className="tenantSubheader">
         get a full credit check, background check
         and social media analysis, so you can
         choose the best tenant for you.
        </p>
       </div>
       <div className="stepsContainer">
        <div className="stepsBox">
         <div className="number">1</div>
         <div className="imgContainer">
          <img
           src={require("./assets/signUp.png")}
          />
         </div>
         <h4>sign up</h4>
        </div>
        <div className="stepsBox">
         <div className="number">2</div>
         <div className="imgContainer">
          <img
           src={require("./assets/createListing.png")}
          />
         </div>
         <h4>create a listing</h4>
        </div>
        <div className="stepsBox">
         <div className="number">3</div>
         <div className="imgContainer">
          <img
           src={require("./assets/sendRequest.png")}
          />
         </div>
         <h4>send a screening request</h4>
        </div>
        <div className="stepsBox">
         <div className="number">4</div>
         <div className="imgContainer">
          <img
           src={require("./assets/signUp.png")}
          />
         </div>
         <h4>download the report</h4>
        </div>
       </div>
       <div className="text-center howItWork-btn-area">
        <Button
         href={`${
          Cookies.get("access_token") // if: user is logged in
           ? parseInt(Cookies.get("role")) === 2
             ? "/dashboard"
             : parseInt(Cookies.get("role")) ===
                3 && "/signup/landlord" // else if: logged in user is a tenant
           : "/signup/landlord" // else: for non logged in users
         }`}
         className="btn btn-primary btn-block f-secondary-r"
        >
         get started
        </Button>
        <h5 className="report f-secondary-b text-center">
         <a href="/sample_screening_report.pdf" target="_BLANK" className="sample-report-link">download a sample report</a>
        </h5>
       </div>
      </div>
     </section>

     <section className="sec-faq cu-container-wrap">
      <div className="container">
       <div className="creditCard">
        <div className="equifax">
         <img
          className="background"
          src={require("./assets/BlackGround.png")}
          alt=""
         />
         <img
          className="powered"
          src={require("./assets/PoweredBy.png")}
          alt=""
         />
         <img
          className="fax"
          src={require("./assets/EQUIFAX.png")}
          alt=""
         />
        </div>
        <h2>
         credit reports & background checks
        </h2>
        <h4 className="creditSub">
         with a couple of clicks you can get the
         confidence you need to choose the right
         tenant
        </h4>
        <div className="listBox">
         <div className="listBoxColumn">
          <ul>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Long-form Equifax credit report
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Employment info
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            RCMP background check
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Social media analysis
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Canlii database check
           </li>
          </ul>
         </div>
         <div className="listBoxColumn">
          <ul>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Tradelines, bankruptcies & collections
           </li>

           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Tenancy history
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Income
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Pet & vehicle info
           </li>
           <li>
            <img
             src={require("./assets/Check.png")}
             alt=""
            />
            Roommate info
           </li>
          </ul>
         </div>
        </div>
       </div>
       <div className="contact">
        <h2> contact us for any questions</h2>
        <Button
         href="mailto:hello@rentpanda.ca"
         className="contactButton"
        >
         contact us
        </Button>
       </div>
      </div>
     </section>
    </Layout>
   </div>
  </>
 );
};

export default withRouter(HowItWorksScreening);
