import React from "react";
import "./style.scss";

 
 
const PDFViewerComponent = (props) => {
  const { PDFUrl } = props;
  return (
    <iframe src={PDFUrl} width={"100%"} height={"480"} className="pdfIframe" />
  );
};
export default PDFViewerComponent;
