import React, { useState, useEffect } from "react";
import "./style.scss";
import { AppLayout, ModalBox, PageTitleHeader, RentOverview } from "../../components";
import { Nav } from "react-bootstrap";
import { logger, toaster } from "../../../Utils";
import { withRouter, NavLink } from "react-router-dom";
import Repairs from "./Repairs/Repairs";
import Inspection from "./Inspection/Inspection";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import Notices from "../Notices/Notices";
import LeaseOverviewPdfView from "../../components/RentOverview/LeaseOverviewPdfView";
import CompleteWarningMessage from "../../components/CompleteWarningMessage/CompleteWarningMessage";
import { getRentOverURL, relistProperty } from './helper'
import RelistForm from "./RelistForm";
import LeaseUploadSidePanel from "../../components/RentOverview/LeaseUploadSidePanel";
import { getLeaseData } from "./helper";
import { setOverviewDetails } from "../../../Store/actions/MaintenanceRepairAction";
import { uploadSignedLeaseAPI } from "../../../Store/api/leaseApi";
import { setCurrentPage } from "../../../Store/actions/UserAction";
import { setListingCurrentPage } from "../../../Store/actions/ListingAction";
import { setCurrentListingPage } from "../../../Store/actions/PropertyDetailAction";

const log = logger("MaintainanceRepair");
const MaintainanceRepair = (props) => {
  const [showUploadPDF, setShowUploadPDF] = useState(false)
  const [relistConfirmModal, setRelistConfirmModal] = useState(false)
  const [componentLoad, setProperty_component_load] = useState('');
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
  const [is_ViewPdf, setViewPdf] = useState(false);
  const [rentDetails, setRentDetails] = useState(null);
  const [showRelist, setShowRelist] = useState(true)
  const [is_Relist, setIsRelist] = useState(false)
  const role = Number(Cookies.get('role'));
  const address = useSelector(state => state.maintenanceRepairReducer.address)
  const userId = useSelector(state => state.auth.id);
  const dispatch = useDispatch();

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
    setProperty_component_load(props.match.params.slug);
  }, [props.match.params]);

  useEffect(() => {
    setShowRelist(rentDetails?.is_relist === 1 ? false : true)
  }, [rentDetails])

  useEffect(() => {
    if (componentLoad === "overview") {
      getRentOverview()
    }
  }, [componentLoad])

  const uploadPDF = () => setShowUploadPDF(true)

  const getRentOverview = async () => {
    const leaseId = rentDetails?.lease_id
    console.warn({rentDetails});
    console.warn({property_details_id});
    const url = getRentOverURL({ leaseId, role, property_id, property_details_id });
    const rentData = await getLeaseData(url)
    log.info("rentData",rentData);

    if(role===3){
      const foundTenantIndex = rentData.tenants.findIndex(tenant => tenant.tenant_id === userId)
      if(foundTenantIndex === -1){
        props.history.replace(userId ? "/search-listing" : "/search-result");
        toaster("error", "Sorry, this listing has been rented.");
      }
    }
    if(role===2){
      const isLandlord = rentData.landlord_id === userId;
      if(!isLandlord){
        props.history.replace(userId ? "/rented-listing" : "/search-result");
        toaster("error", "Sorry, this listing has been rented.");
      }
    }

    if (rentData && Object.keys(rentData).length > 0) {
      setRentDetails(rentData);
      dispatch(setOverviewDetails(rentData));
    } else {
      props.history.push({
        pathname: '/404',
        state: { message: 'This lease you are trying to view no longer exists.' }
      });
    }
  }

  const backToDashboard = () => {
    if (role === 2) {
      props.history.push(`/rented-listing`);
    } else if (role === 3) {
      props.history.push(`/dashboard/my-home`);
    }
  }

  const viewDownloadPdf = () => {
    setViewPdf(true);
  }

  const viewRelist = () => {
    setIsRelist(true)
  }

  const relistConfirm = () => {
    setRelistConfirmModal(true)
  }

  const redirectToEditListing = async () => {
    setRelistConfirmModal(false)
    const {new_property_id,new_property_detail_id}=await relistProperty(property_id,property_details_id)
    dispatch(setCurrentListingPage(0))

    props.history.push(`/create-listing/${new_property_id}/${new_property_detail_id}/?isRelisting=true`,{isRelisting:true});
  }

  const createLease = async (data) => {
    const leaseId = rentDetails.lease_id
    const res=await uploadSignedLeaseAPI({ lease_id: leaseId, lease_pdf: data.file })
    getRentOverview()
    setShowUploadPDF(false)
  }


  return (
    <>
      <AppLayout>
        <PageTitleHeader
          title={address}
          isArrow={true}
          onClick={() => backToDashboard()}
          threeDotNav={(role === 2 || role === 1) && (rentDetails || componentLoad === 'repairs' || componentLoad === 'inspections' || componentLoad === 'notices') && 'property-relist'}
          relistingProperty={(role === 2 || role === 1) && relistConfirm}
          propertyId={(role === 2 || role === 1) && property_id}
          propertyDetailsId={(role === 2 || role === 1) && property_details_id}
          showRelist={componentLoad === 'overview' && showRelist}
        />

        <>
          <Nav fill variant="tabs" className="rp-tab-views">
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/overview' }} className={componentLoad === "overview" || componentLoad === undefined ? "nav-link active" : "nav-link"} >Overview</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/inspections' }} className={"nav-link"} >Inspection</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/notices' }} className={"nav-link"} >Notices</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/repairs' }} className={"nav-link"} >Repairs</NavLink>
          </Nav>

          {(role === 2 || role === 1) && showRelist && rentDetails && componentLoad === 'overview' && <CompleteWarningMessage onClick={() => relistConfirm()} colorStyle={'success'} message={'This Property Is Currently Tenanted, '} ctaText={'Click Here To Relist'} />}

          {componentLoad === "overview" ?
            <RentOverview
              uploadPDF={uploadPDF}
              viewDownloadPdf={() => viewDownloadPdf()}
              rentDetails={rentDetails}
               
              /> : ""}
          {componentLoad === "repairs" ?
            <Repairs role={role} /> : ""}
          {componentLoad === "inspections" ?
            <Inspection role={role} /> : ""}
        </>
        {componentLoad === "notices" ?
          <Notices property_id={property_id}
            property_details_id={property_details_id}
            role={role} /> : ""}
      </AppLayout>

      <LeaseOverviewPdfView
        isShow={is_ViewPdf}
        handleClose={() => setViewPdf(false)}
        address={address}
        rentDetails={rentDetails} />

      {is_Relist &&
        <RelistForm
          isShow={is_Relist}
          handleClose={() => setIsRelist(false)}
          leaseData={rentDetails}
          propertyId={property_id}
          propertyDetailsId={property_details_id}
          setShowRelist={setShowRelist}
        />}
      { showUploadPDF &&
        <>
          <LeaseUploadSidePanel
            onHide={() => setShowUploadPDF(false)}
            onCreateLease={createLease}
          />
        </>}
        <ModalBox
          show={relistConfirmModal}
          onHide={() => setRelistConfirmModal(false)}
          size='sm'
          closeButtonHeader={''}
          actionbuttontitle={'got it'}
          buttonaction={() => redirectToEditListing()}
        >
        <div style={{fontFamily:'NunitoSans-Regular',padding:'1rem 1rem 0 1rem',fontSize:'20px'}}>
          <p>
            Please edit your listing to input the correct date of availability, lease duration, etc.
          </p>
          <p>
            Once complete your listing will be copied into the "for rent" section of your dashboard and approved by our team to go live.
          </p>
        </div>

        </ModalBox>
    </>
  );
}

export default withRouter(MaintainanceRepair);
