import React from "react";
import { Row, Col } from "react-bootstrap";
import { PhoneNumberFormat } from "..";
import { logger } from "../../../Utils";

const log = logger('LandlordDetailBlock')
const LandlordDetailBlock = (props) => {
    const { leaseData, extraClass } = props;
    log.info(leaseData)
    return (
        <div className='card-detail'>
            <h4 className={'user-name text-left ' + extraClass}>LANDLORD</h4>
            <React.Fragment>
                <span className='tenant-name' style={{ display: 'block' }}>{leaseData && leaseData.lease_landlord.name}</span>
                <div className='contact-details'>
                    <span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/mobile-icon.svg')} alt='mobile-icon' />
        Phone: <span className='title-text'><PhoneNumberFormat phoneNumber={leaseData && leaseData.lease_landlord.phone_number} /> </span>
                    </span>
                    <span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/message-icon.svg')} alt='message-icon' />
        Email: <span className='title-text'>{leaseData && leaseData.lease_landlord.email}</span>
                    </span>
                </div>
            </React.Fragment>
        </div>
    )

}
export default LandlordDetailBlock;