import axios from "axios";
import Cookies from 'js-cookie';
import { FILE_API_URL } from "../../../Constants/endPoints";

export const uploadLeaseFile = (file = [], setUploadData, setImage, setErrorClass, setShowImageLoading) => {
    const token = `Bearer ${Cookies.get('access_token')}`
    const headers = {
        'Authorization': token,
        'Content-Type': 'multipart/form-data'
    }

    file.map((dataFile) => {
        setUploadData(dataFile);
        let formdata = new FormData();
        formdata.append('file', dataFile);
        let url = FILE_API_URL;
        axios.post(
            url, formdata, {
            headers: headers,
            onUploadProgress: progressEvent => {
                var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                if (percent >= 100) {
                    setImage({ percent: percent });
                    return file;
                } else {
                    setImage({ percent: percent });
                    return null;
                }
            }
        })
            .then(response => {
                setImage({ file: response.data.data.url })
                setErrorClass(false)
                setShowImageLoading(false)

            })
            .catch(error => {
                setShowImageLoading(false)
            });
        return dataFile;
    })
}