import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import { LargeButton, SidePanel, SingleOptionCheck } from '../../../components';
import { promotionPlansList } from '../LeaseDetails/LeaseConstants';

const log = logger('PromoteListing');
const PromoteListing = (props) => {
    const { isShow, handleClose, apiCallback, setPromotionModal } = props;
    // const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    // const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState("");
    const [promotionPlan, setPlan] = useState(1);

    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'promotionPlan') {
            setPlan(Value);
        }
    }
    const onConfirm = () => {

        const data = {
            "property_id": property_id,
            "property_details_id": property_details_id,
            "package_id": promotionPlan
        }
        postAPI('/admin/listing/promotion/create', data).then(res => {
            if (res.status === 200) {
                setPromotionModal(true);
                apiCallback();
                handleClose(promotionPlan);
                // toaster('success', `Listing promoted successfully for ${getPromotionPlan(promotionPlan)}`);
            }
        })
    }

    useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
        setProperty_id(Number(props.match.params.property_id));
        if (props.match.params.property_details_id) {
            getAPI('/admin/listing/promote-packages').then(res => {
                log.info(res.data.data)
            })
        }
    }, [props.match.params.property_details_id, props.match.params.property_id]);

    return (
        <>
            <SidePanel
                isShow={isShow}
                isHide={handleClose}
                title={'Promote Property'}
            >
                <div className='sidepanel-body promote-list-class'>
                    <Form id='Form'>
                        <Row>
                            <Form.Group as={Col} md='12'>
                                <Form.Label>select promotion plan</Form.Label>
                                <SingleOptionCheck
                                    mapData={promotionPlansList} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'promotionPlan'} setParam={promotionPlan}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>

                <Row>
                    <Col md={12} className='fixed-bottom'>
                        <LargeButton title='confirm' onClick={() => onConfirm()} style={{ width: '200px', float: 'right' }} />
                    </Col>
                </Row>
            </SidePanel>
        </>
    )
}

export default withRouter(PromoteListing);