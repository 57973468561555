import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import { multiCheckbox } from '../../../../Utils/MultiCheckbox/MultiCheckbox';
import { TableLandlord } from '../../../components';
import UserManagementTable from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import '../style.scss';

const log = logger('Maintainance');
const Maintainance = (props) => {
    const { user_id, role } = props;
    const [tableData, setTableList] = useState(null);
    const [searchLocation, setSearch] = useState(null);
    const [path] = useState('');
    const [maintainanceType, setType] = useState([]);
    const [createdAt, sortCreated] = useState('');
    const [completedOn, sortCreatedOn] = useState('');
    const [status, sortMaintainanceStatus] = useState('all');
    const [isLoading, setIsLoading] = useState(true);
    let userColumns = [];
    userColumns = [
        {
            header: 'ADDRESS',
            width: '30%'
        },
        {
            header: 'TYPE',
            width: '15%'
        },
        {
            header: 'CREATED',
            width: '15%'
        },
        {
            header: 'COMPLETED ON',
            width: '20%'
        },
        {
            header: 'STATUS',
            width: '25%'
        }
    ];
    const filterData = {
        "address": searchLocation,
        "type": maintainanceType,
        "sort_created": createdAt, //asc or desc
        "sort_completed": completedOn, //asc or desc
        "status": status === 'all' ? '' : status //0 for pending, 3 for Completed
    }
    let url = '';
    if (role === 'landlord') {
        url = `/admin/users/landlord/maintenances/${user_id}`;
    } else {
        url = `/admin/users/tenants/maintenances/${user_id}`;
    }


    const getData = (fetchedUrl) => {
        if (user_id !== '') {
            postAPI(fetchedUrl ? fetchedUrl : url, filterData)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        const res = response.data.data.maintenances;
                        setTableList(res)
                    }
                })
        }
    }

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        let urlNext = '';
        if (role === 'landlord') {
            urlNext = `/admin/users/landlord/maintenances/${user_id}?page=${nextPage}`;
        } else {
            urlNext = `/admin/users/tenants/maintenances/${user_id}?page=${nextPage}`;
        }

        getData(urlNext);
    }
    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        let urlPrev = '';
        if (role === 'landlord') {
            urlPrev = `/admin/users/landlord/maintenances/${user_id}?page=${prevPage}`;
        } else {
            urlPrev = `/admin/users/tenants/maintenances/${user_id}?page=${prevPage}`;
        }

        getData(urlPrev);
    }
    const goToProfileDetail = (userData) => {
        props.history.push({ pathname: `/maintainance-details/${role}/${userData.id}/${user_id}`, state: { path: path } })
    }
    const sortByMaintainanceType = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(maintainanceType, item, isChecked);
        setType(fetchedFilter)
        if (user_id !== '') {
            const filterDataInside = {
                "address": searchLocation,
                "type": fetchedFilter,
                "sort_created": createdAt, //asc or desc
                "sort_completed": completedOn, //asc or desc
                "status": status === 'all' ? '' : status //0 for pending, 3 for Completed
            }
            postAPI(url, filterDataInside)
                .then(response => {
                    log.info('get user', response);
                    if (response.status === 200) {
                        setIsLoading(false);
                        const res = response.data.data.maintenances;
                        setTableList(res)
                    }
                })
        }
    }

    useEffect(() => {
        if (user_id !== '' || user_id !== undefined) {
            let urlInside = '';
            if (role === 'landlord') {
                urlInside = `/admin/users/landlord/maintenances/${user_id}`;
            } else {
                urlInside = `/admin/users/tenants/maintenances/${user_id}`;
            }
            const data = {
                "address": searchLocation,
                "type": maintainanceType,
                "sort_created": createdAt, //asc or desc
                "sort_completed": completedOn, //asc or desc
                "status": status === 'all' ? '' : status //0 for pending, 3 for Completed
            }
            postAPI(urlInside, data)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        const res = response.data.data.maintenances;
                        setTableList(res)
                    }
                })
        }
        // setPath(props.location.state.path)
    }, [user_id, searchLocation, maintainanceType, createdAt, completedOn, status, role])
    return (
        isLoading === true ? <UserManagementTable columns={userColumns} component={'lease'} /> :
            <>
                {tableData && tableData.data ?
                    <TableLandlord
                        columns={userColumns}
                        tableData={tableData.data}
                        to={tableData.to}
                        from={tableData.from}
                        current_page={tableData.current_page}
                        last_page={tableData.last_page}
                        per_page={tableData.per_page}
                        total={tableData.total}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        clickNext={() => onClickNext(tableData.current_page)}
                        clickPrev={() => onClickPrev(tableData.current_page)}
                        setSearch={(data) => setSearch(data)}
                        searchLocation={searchLocation}
                        maintainanceType={maintainanceType}
                        sortByMaintainanceType={(data) => sortByMaintainanceType(data)}
                        sortCreated={(data) => sortCreated(data)}
                        sortCreatedOn={(data) => sortCreatedOn(data)}
                        sortMaintainanceStatus={(data) => sortMaintainanceStatus(data)}
                        component='maintainance'
                    />
                    : ''}
            </>
    );
}

export default withRouter(Maintainance);
