import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const LeaseTenantDetailLoader = (props) => {
	return (
		<>
			<Form className='higher-level-lease'>
				<div className='inner-wrapper'>
					<div className='body-container'>
						<div className='body-header'>
							<h3 className='text-left lease-heading'>tenant details</h3>
						</div>
						<Row>

							<Form.Group as={Col} md='12'>
								<Form.Label>Your tenant(s)</Form.Label>
								<ul className='select-options'>
									<li className="list-btn">
										<RowList />
									</li>
								</ul>

							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md='6'>
								<Form.Label className='m-0'>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
								<RowList />
							</Form.Group>
						</Row>
					</div>
				</div>
			</Form>
		</>
	)
}
export default LeaseTenantDetailLoader;


const RowList = (props) => {
	return (
		<ContentLoader height={40} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
			<rect x="0" y="0" rx="0" ry="0" width="100%" height="40" />
		</ContentLoader>
	)
}