import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { numberFormatSanitizerInt } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import { getAPI, postAPI } from '../../../../Api';
import { CostNumberFormat, SingleOptionCheck, DatePickerComponent, FileBrowse, ModalBox } from '../../../components';
import history from '../../../../history';
import { validate } from '../../../../Utils/Validation/validation';
import { CreateLeaseDetailsSchema } from './CreateLeaseSchema/CreateLeaseDetailsSchema';
import { subDays, addMonths, addYears, lastDayOfMonth, differenceInDays, getDaysInMonth } from 'date-fns';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import { rentDeposite, leaseType, rentPaid, utilitiesPaidBy, parkingType, getUtilitiesHydro, getUtilitiesHeat, getUtilitiesWater, rentPaidType } from '../../../../Constants/LeaseConstants';
import { dateFormat } from '../../../../Utils/DateSanitizer/DateSanitizer';
import moment from "moment";
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import LeaseDetailFormContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/LeaseDetailFormContentLoader';
import { Prompt } from 'react-router';


const log = logger('CreateLeaseDetails');
const CreateLeaseDetails = (props) => {
	const [isLoading, setLoading] = useState(false);
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState('');
	const [user_id, setUserId] = useState('');
	const [rent, setRent] = useState('');
	const [lease_type, setLeaseType] = useState(1);
	const [utility_electricity_pay_by, setUtilityElectric] = useState(1);
	const [utility_heat_pay_by, setUtilityHeat] = useState(1);
	const [utility_water_pay_by, setUtilityWater] = useState(1);
	const [is_parking_available, setParkingAvailable] = useState(0);
	const [parking_type, setParkingType] = useState(1);
	const [parking_spot, setParkingSpot] = useState('');
	const [parking_price, setParkingPrice] = useState('');
	const [aminities, setAminities] = useState([1]);
	const [other_animities_type] = useState('');
	const [reasondata] = useState(null);
	const [start_date, setStartDate] = useState(new Date());
	const [end_date, setEndDate] = useState(new Date());
	const [tenancy_agreement, setTenancy_agreement] = useState(0);
	const [rent_pay_method, setRent_pay_method] = useState('');
	const [rent_paid_on_day_of_each, setRent_paid_on_day_of_each] = useState(1);
	const [rent_paid_on, setRent_paid_on] = useState('1st');
	const [rent_payable_to, setRent_payable_to] = useState('');
	const [administrative_charge, setAdministrative_charge] = useState('20');
	const [is_rent_deposit, setRentDeposit] = useState(1);
	const [is_rent_deposit_amount, setRent_deposit_amount] = useState(0);


	const [is_rent_discounted, setRentDiscounted] = useState(1);
	const [rent_discount_amount, setDiscountedAmount] = useState('');
	const [rent_discount_description, setRentDiscountDesc] = useState('');
	const [is_key_deposite, setKeyDeposite] = useState(1);
	const [key_deposite_amount, setKeyDepositeAmount] = useState('');
	const [is_smoking_allowed, setSmoking_allowed] = useState(0);
	const [lease_pdf, setLease_pdf] = useState({ file: '', percent: 0 });
	const [errorClass, setErrorClass] = useState(false);
	const [uploadData, setUploadData] = useState(null);
	const [gas, setGas] = useState(0);
	const [AC, setAc] = useState(0);
	const [storageSpace, setStorageSpace] = useState(0);
	const [laundry, setLaundry] = useState(0);
	const [guestParking, setGuestParking] = useState(0);

	const [first_rental_partial_period, setFirstRentalPartialPeriod] = useState(0);
	const [partial_cover_start_date, setCoverStartDate] = useState(new Date());
	const [partial_cover_end_date, setCoverEndDate] = useState(new Date());
	const [partial_payment_date, setPartialPaymentDate] = useState(new Date());
	const [partial_cover_amount, setPartialCoverAmount] = useState('');
	const [saveLoading, setBtnDisable] = useState(false);
	const [other_service_utilities, setOther_service_utilities] = useState([]);
	const [other_service_utilities_extra, setOther_service_utilities_extra] = useState('');
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const [leaseData, setCompleteLeaseData] = useState(null);
	const [saveClick, setSaveClick] = useState(false);
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [errorRentMethodClass, setErrorRentMethodClass] = useState(false);
	const [showImageLoading, setShowImageLoading] = useState(false)
	const [amenitiesData, setAmenitiesData] = useState([]);

	const backToPrevPage = () => {
		props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/contact-details/${lease_id}`, state: { singleReasonData: reasondata } });
		history.go(0);
	}
	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'tenancy_agreement') {
			setTenancy_agreement(Value);
		} else if (name === 'rent_pay_method') {
			setRent_pay_method(Value);
			setErrorRentMethodClass(false);
		} else if (name === 'rent_paid_on_day_of_each') {
			setRent_paid_on_day_of_each(Value);
		} else if (name === 'is_smoking_allowed') {
			setSmoking_allowed(Value);
		} else if (name === 'is_key_deposite') {
			setKeyDeposite(Value);
		} else if (name === 'is_rent_deposit') {
			setRentDeposit(Value);
		} else if (name === 'is_rent_discounted') {
			setRentDiscounted(Value);
		} else if (name === 'lease_type') {
			log.info(Value)
			setLeaseType(Value);
		} else if (name === 'utility_electricity_pay_by') {
			setUtilityElectric(Value);
		} else if (name === 'utility_heat_pay_by') {
			setUtilityHeat(Value);
		} else if (name === 'utility_water_pay_by') {
			setUtilityWater(Value);
		} else if (name === 'is_parking_available') {
			setParkingAvailable(Value);
		} else if (name === 'parking_type') {
			setParkingType(Value);
		} else if (name === 'gas') {
			setGas(Value);
		} else if (name === 'AC') {
			setAc(Value);
		} else if (name === 'storageSpace') {
			setStorageSpace(Value);
		} else if (name === 'laundry') {
			setLaundry(Value);
		} else if (name === 'guestParking') {
			setGuestParking(Value);
		} else if (name === 'first_rental_partial_period') {
			setFirstRentalPartialPeriod(Value)
		}
		setShouldBlockNavigation(true);
	}
	const handleDate = (eventDate, setState, setNextDate) => {
		setState(eventDate);
		if (lease_type === 1) {
			setNextDate(subDays(addYears(eventDate, 1), 1))
		} else if (lease_type === 2) {
			setNextDate(subDays(addMonths(eventDate, 1), 1))
		}
		setShouldBlockNavigation(true);
	}
	const handleDate2 = (eventDate, setState) => {
		setState(eventDate);
		var result = lastDayOfMonth(new Date(eventDate))
		// log.info(result)
		setCoverEndDate(result)
		setPartialPaymentDate(eventDate)
		partialCalculator(eventDate, result)
		setShouldBlockNavigation(true);
	}
	const partialCalculator = (eventDate, result) => {
		var noOfDaysRemain = differenceInDays(
			new Date(result), new Date(eventDate)
		)

		var dayInMonth = getDaysInMonth(new Date(eventDate));
		log.info('dayInMonth', noOfDaysRemain + 1)
		// (rent/daysin month) * No of days remaining
		let rent = unitDetails && unitDetails.price_per_month
		let partialAmount = ((Number(rent) / Number(dayInMonth)) * (Number(noOfDaysRemain) + 1))
		log.info('partialAmount', partialAmount)
		setPartialCoverAmount(partialAmount)
		setShouldBlockNavigation(true);
	}
	const handleDrop = (file, setImage) => {
		setShouldBlockNavigation(true);
		const token = `Bearer ${Cookies.get('access_token')}`
		const headers = {
			'Authorization': token,
			'Content-Type': 'multipart/form-data'
		}
		setShowImageLoading(true)

		file.map((dataFile) => {
			setUploadData(dataFile);
			let formdata = new FormData();
			formdata.append('file', dataFile);
			let url = FILE_API_URL;
			Axios.post(
				url, formdata, {
				headers: headers,
				onUploadProgress: progressEvent => {
					var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					if (percent >= 100) {
						setImage({ percent: percent });
						return file;
					} else {
						setImage({ percent: percent });
						return null;
					}
				}
			})
				.then(response => {
					setImage({ file: response.data.data.url })
					setErrorClass(false)
					setShowImageLoading(false)

				})
				.catch(error => {
					setShowImageLoading(false)
				});
			return dataFile;
		})
	}
	const removeFile = (index, setFile) => {
		setFile('')
		setShouldBlockNavigation(true);
	}

	const onSubmit = (event) => {
		if (rent_pay_method === '') {
			setErrorRentMethodClass(true);
		}
		event.preventDefault();
		setShouldBlockNavigation(false);
		if (validate(CreateLeaseDetailsSchema) && !errorRentMethodClass) {
			setBtnDisable(true)
			const data = {
				'lease_id': lease_id,
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'lease_type': lease_type,
				'lease_start_date': start_date,
				'lease_end_date': end_date,
				'rent': rent,
				'other_lease_type': 'test',
				'utility_electricity_pay_by': utility_electricity_pay_by,
				'utility_heat_pay_by': utility_heat_pay_by,
				'utility_water_pay_by': utility_water_pay_by,
				'is_parking_available': is_parking_available,
				'parking_type': parking_type,
				'parking_spot': parking_spot,
				'parking_price': parking_price,
				'aminities': aminities,
				'other_animities_type': other_animities_type,
				'is_smoking_allowed': is_smoking_allowed,
				'tenancy_agreement': tenancy_agreement,
				'lease_appendix': lease_pdf && lease_pdf.file,
				'rent_paid_on': rent_paid_on,
				'rent_paid_on_day_of_each': rent_paid_on_day_of_each,

				'rent_payable_to': rent_payable_to,
				'rent_pay_method': rent_pay_method,

				'first_rental_partial_period': first_rental_partial_period,
				'partial_cover_start_date': dateFormat(partial_cover_start_date),
				'partial_cover_end_date': dateFormat(partial_cover_end_date),
				'partial_cover_amount': partial_cover_amount,
				'partial_payment_date': dateFormat(partial_payment_date),

				'administrative_charge': administrative_charge,
				'is_rent_deposit': is_rent_deposit,
				'is_rent_deposit_amount': is_rent_deposit_amount,
				'is_key_deposite': is_key_deposite,
				'key_deposite_amount': key_deposite_amount,
				'is_rent_discounted': is_rent_discounted,
				'rent_discount_amount': rent_discount_amount,
				'rent_discount_description': rent_discount_description,

				'other_service_utilities': [other_service_utilities_extra],
				'amenities': [
					{
						'id': 8,
						'allow': gas
					},
					{
						'id': 9,
						'allow': AC
					},
					{
						'id': 10,
						'allow': storageSpace
					},
					{
						'id': 11,
						'allow': laundry
					},
					{
						'id': 12,
						'allow': guestParking
					}
				]
			}
			const url = '/admin/listing/lease/details';
			if (tenancy_agreement === 1 && (lease_pdf && lease_pdf.file === '')) {
				setErrorClass(true)
				setBtnDisable(false)
			} else {
				postAPI(url, data).then(res => {
					setBtnDisable(false)
					setSaveClick(false)
					if (res.status === 200) {
						toaster('success', 'Data Saved Successfully!');
						if (saveState === 'next') {
							props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/tenant-details/${lease_id}` })
							history.go(0);
						} else if (saveState === 'update') {
							setLease_updated(true);
						}
					} else if (res.response.status === 400) {
						log.info(res.response)
						toaster('error', res.response.data.message);
					} else if (res.response.status === 422) {
						toaster('error', res.response.data.message);
					}
				})
			}

		}
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setLeaseId(Number(props.match.params.lease_id));
		setUserId(props.match.params.user_id);
	}, [
		props.match.params.property_id,
		props.match.params.property_details_id,
		props.match.params.user_id,
		props.location.state,
		props.match.params.lease_id]);
	const redirectLast = () => {
		setLease_updated(false)
		props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/complete-lease-details/${lease_id}` });
	}

	useEffect(() => {
		if (property_details_id && lease_id) {
			setLoading(true)
			const url = `/admin/listing/lease/get-lease-details/${property_details_id}/${lease_id}`;
			getAPI(url).then(res => {
				setLoading(false)
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setCompleteLeaseData(fetchedData)
					log.info('fetchedData.lease_deatils', fetchedData.lease_deatils)
					if (fetchedData && fetchedData.lease_deatils) {
						setRent(fetchedData.lease_deatils.rent === '0.0' ? fetchedData.lease_deatils.rent :
							unitDetails !== null ? unitDetails.price_per_month : ''
						);
						// setDeposit(fetchedData.lease_deatils.is_rent_deposit !== null ? fetchedData.lease_deatils.is_rent_deposit : 0);
						setLeaseType(fetchedData.lease_deatils.lease_type !== null ? fetchedData.lease_deatils.lease_type : 1);
						setUtilityElectric(fetchedData.lease_deatils.utility_electricity_pay_by !== null ? Number(fetchedData.lease_deatils.utility_electricity_pay_by) :
							unitDetails !== null ? getUtilitiesHydro(unitDetails.utilities) : 2
						);
						setUtilityHeat(fetchedData.lease_deatils.utility_heat_pay_by !== null ? Number(fetchedData.lease_deatils.utility_heat_pay_by) :
							unitDetails !== null ? getUtilitiesHeat(unitDetails.utilities) : 2
						);
						setUtilityWater(fetchedData.lease_deatils.utility_water_pay_by !== null ? Number(fetchedData.lease_deatils.utility_water_pay_by) :
							unitDetails !== null ? getUtilitiesWater(unitDetails.utilities) : 2
						);
						setParkingAvailable(fetchedData.lease_deatils.is_parking_available !== null ? fetchedData.lease_deatils.is_parking_available : unitDetails !== null ? unitDetails.is_parking_included : 0);
						setParkingType(fetchedData.lease_deatils.parking_type !== null ? fetchedData.lease_deatils.parking_type : 1);
						setParkingSpot(fetchedData.lease_deatils.parking_spot !== null ? fetchedData.lease_deatils.parking_spot : "");
						setParkingPrice(fetchedData.lease_deatils.parking_price !== null ? fetchedData.lease_deatils.parking_price : "");
						setAminities(fetchedData.lease_deatils.amenities.length !== 0 ? fetchedData.lease_deatils.amenities : [1]);
						setRent_payable_to(fetchedData.lease_deatils.rent_payable_to !== null ? fetchedData.lease_deatils.rent_payable_to : propertyDetails !== null ? propertyDetails.user.name : '');
						setPartialCoverAmount(fetchedData.lease_deatils.partial_cover_amount !== null ? fetchedData.lease_deatils.partial_cover_amount : "");
						setAdministrative_charge(fetchedData.lease_deatils.administrative_charge !== null ? fetchedData.lease_deatils.administrative_charge : "20")
						setEndDate(fetchedData.lease_deatils.lease_end_date !== null ? moment(fetchedData.lease_deatils.lease_end_date).toDate() : "")
						setTenancy_agreement(fetchedData.lease_deatils.tenancy_agreement)
						setRent_paid_on(fetchedData.lease_deatils.rent_paid_on !== null ? fetchedData.lease_deatils.rent_paid_on : "1st")
						setRent_paid_on_day_of_each(fetchedData.lease_deatils.rent_paid_on_day_of_each)
						setRent_pay_method(fetchedData.lease_deatils.rent_pay_method !== null ? fetchedData.lease_deatils.rent_pay_method : '')
						setFirstRentalPartialPeriod(fetchedData.lease_deatils.first_rental_partial_period)
						setCoverStartDate(fetchedData.lease_deatils.partial_cover_start_date !== null ? moment(fetchedData.lease_deatils.partial_cover_start_date).toDate() : "")
						setCoverEndDate(fetchedData.lease_deatils.partial_cover_end_date !== null ? moment(fetchedData.lease_deatils.partial_cover_end_date).toDate() : "")
						setPartialPaymentDate(fetchedData.lease_deatils.partial_payment_date !== null ? moment(fetchedData.lease_deatils.partial_payment_date).toDate() : "")

						setRentDeposit(fetchedData.lease_deatils.is_rent_deposit !== null ? fetchedData.lease_deatils.is_rent_deposit : 1)
						setRent_deposit_amount(fetchedData.lease_deatils.is_rent_deposit_amount !== null ? fetchedData.lease_deatils.is_rent_deposit_amount : unitDetails !== null ? unitDetails.price_per_month : '')
						if (fetchedData.lease_deatils.amenities.length !== 0) {
							fetchedData.lease_deatils.amenities.map((data) => {
								if (data.id === 8) {
									setGas(data.allow)
								}
								if (data.id === 9) {
									setAc(data.allow)
								}
								if (data.id === 10) {
									setStorageSpace(data.allow)
								}
								if (data.id === 11) {
									setLaundry(data.allow)
								}
								if (data.id === 12) {
									setGuestParking(data.allow)
								}
								return data;
							})
						} else {
							if (unitDetails && unitDetails.amenities.includes(1)) {
								setAc(1)
							} else {
								setAc(0)
							}
							log.info('unitDetails.laundry', unitDetails.laundry)
							if (unitDetails && unitDetails.laundry !== 0) {
								setLaundry(1)
							} else {
								setLaundry(0)
							}
						}

						setLease_pdf({
							file: fetchedData.lease_deatils.lease_appendix !== null ? fetchedData.lease_deatils.lease_appendix : ""
						})
						setOther_service_utilities_extra(fetchedData.lease_deatils.other_service_utilities && fetchedData.lease_deatils.other_service_utilities[0])
					}

					if (fetchedData && fetchedData.other_details) {
						setRentDiscounted(fetchedData.other_details.is_rent_discounted !== null ? fetchedData.other_details.is_rent_discounted : 1);
						setDiscountedAmount(fetchedData.other_details.rent_discount_amount);
						setRentDiscountDesc(fetchedData.other_details.rent_discount_description);
						setKeyDeposite(fetchedData.other_details.is_key_deposite !== null ? fetchedData.other_details.is_key_deposite : 1);
						setKeyDepositeAmount(fetchedData.other_details.key_deposite_amount);
						setSmoking_allowed(fetchedData.other_details.is_smoking_allowed)
					}
				}
			})
		}
		(async () => {
			const { amenitiesData = [] } = await getUtilitiesAndAmenities()
			setAmenitiesData(amenitiesData)
		})()
	}, [property_details_id, lease_id, unitDetails, propertyDetails]);

	useEffect(() => {
		unitDetails && amenitiesData.filter(data => data.value !== 1).map(filter => {
			if (unitDetails.amenities.includes(filter.value)) {
				setOther_service_utilities(amenities => [...amenities, filter.title])
			}
			return filter
		})
	}, [unitDetails])

	const otherUtilities = (data) => {
		setOther_service_utilities_extra(data)
	}
	let maxLength = 25;
	return (
		isLoading === true ? <LeaseDetailFormContentLoader /> :
			<>
				<Prompt
					when={shouldBlockNavigation}
					message="your information will be lost do you want to leave this page?"
				/>
				<Form id='Form' onSubmit={onSubmit} className='higher-level-lease'>
					<div className='inner-wrapper'>
						<div className='body-container'>
							<div className='body-header'>
								<h3 className='text-left lease-heading'>lease details</h3>
								{/* <button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit'>Save</button> */}
								{leaseData && leaseData.is_sent_to_tenant ?
									<button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => { setSaveState('update'); setSaveClick(true); }}>Save</button>
									: <button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => { setSaveState(''); setSaveClick(true); }}>Save</button>}
							</div>

							<Row>
								<Form.Group as={Col} md='5'>
									<Form.Label className='transform-none'>How long is the lease</Form.Label>
									<SingleOptionCheck
										mapData={leaseType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'lease_type'} setParam={lease_type}
									/>
								</Form.Group>
								<Form.Group as={Col} md='6'>
									<Form.Label className='transform-none'>Please indicate the start date {(lease_type === 0 || lease_type === 1) ? ' and end date' : ''}</Form.Label>
									<Row>
										<Col md='6'>
											<DatePickerComponent
												name="start_date"
												id="start_date"
												value={start_date || ''}
												handleDateChange={(date) => handleDate(date, setStartDate, setEndDate)}
												displayText={"Start Date"}
												minDate={new Date()}
											/>
										</Col>
										{(lease_type === 0 || lease_type === 1) &&
											<Col md='6'>
												<DatePickerComponent
													name="end_date"
													id="end_date"
													value={end_date}
													handleDateChange={(end_date) => handleDate(end_date, setEndDate)}
													displayText={"End Date"}
													minDate={start_date}
													disableValue={lease_type === 0 ? false : true}
												/>
											</Col>}

									</Row>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} md='10'>
									<Form.Label className='transform-none'>Are there any additional terms about the tenancy agreement?</Form.Label>
									<Row>
										<Col md='2'>
											<div className='d-flex justify-content-between align-items-center'>
												<SingleOptionCheck
													mapData={rentDeposite}
													OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
													name={'tenancy_agreement'}
													setParam={tenancy_agreement}
													extraClass={'d-inline-flex mb-0'}
												/>
											</div>
										</Col>
										{tenancy_agreement === 1 &&
											<Col md='5'>
												{(lease_pdf && lease_pdf.file) ?
													<Col className='confirm-section mb-2 d-flex justify-content-between details'>
														<span className='d-flex justify-content-start'>
															<Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
															<div className='file-details d-flex'>
																{/* {lease_pdf.file.split('/').pop()} */}
																{lease_pdf.file.split('/').pop().length > maxLength ? lease_pdf.file.split('/').pop().substring(0, maxLength) + '...' : lease_pdf.file.split('/').pop()}
															</div>
														</span>
														<Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setLease_pdf)} src={require("../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
													</Col>
													: <FileBrowse
														handleDrop={(e) => handleDrop(e, setLease_pdf)}
														size={'sm'}
														title={'Upload Documents'}
														percent={lease_pdf.percent}
														multiple={false}
														fileType={'.pdf'}
														showImageLoading={showImageLoading}
														disable={false}
														imageData={lease_pdf.file && lease_pdf.file}
														removeFile={(e) => removeFile(e, setLease_pdf)}
														lableClass={'custom-lable'}
														section={'upload-lease'}
														uploadData={uploadData && uploadData}
														errorClass={errorClass === true ? "error-upload-box" : ""}
													/>}
											</Col>
										}
									</Row>
								</Form.Group>
							</Row>
							<hr />

							<h3 className='text-left lease-heading'>more details about the rent</h3>
							<Row>
								<Form.Group as={Col} md='5'>
									<Form.Label className='transform-none'>Rent is to be paid on the:</Form.Label>
									<Form.Control
										type='text'
										name='rent_paid_on'
										id='rent_paid_on'
										value={rent_paid_on}
										placeholder='Type here'
										onChange={(e) => setRent_paid_on(e.target.value)}
									/>
								</Form.Group>

								<Form.Group as={Col} md='4'>
									<Form.Label className='transform-none'>Day of each:</Form.Label>
									<SingleOptionCheck
										mapData={rentPaidType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'rent_paid_on_day_of_each'} setParam={rent_paid_on_day_of_each}
									/>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} md='5'>
									<Form.Label className='transform-none'>Base rent for the rental property</Form.Label>
									<CostNumberFormat
										name={'rent'}
										id={'rent'}
										classname='form-control'
										value={rent ? rent : ''}
										updateField={(e) => setRent(numberFormatSanitizerInt(e.target.value))}
										pre='$'
										placeholder={'Please indicate $ amount'}
										// disabled={true}
									/>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label className='transform-none'>Is parking available</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_parking_available'} setParam={is_parking_available}
									/>
								</Form.Group>
								{is_parking_available === 1 &&
									<>
										<Form.Group as={Col} md='3'>
											<Form.Label className='transform-capitalize'>Parking Type</Form.Label>
											<SingleOptionCheck
												mapData={parkingType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'parking_type'} setParam={parking_type}
											/>
										</Form.Group>
										<Form.Group as={Col} md='2'>
											<Form.Label className='transform-none'># of spots</Form.Label>
											<Form.Control
												type='text'
												name='parking_spot'
												id='parking_spot'
												value={parking_spot}
												placeholder='Number'
												onChange={(e) => setParkingSpot(e.target.value)}
											/>
										</Form.Group>
										<Form.Group as={Col} md='3'>
											<Form.Label className='transform-none'>Price for Parking  </Form.Label>
											<CostNumberFormat
												name={'parking_price'}
												id={'parking_price'}
												classname='form-control'
												value={parking_price ? parking_price : ''}
												updateField={(e) => setParkingPrice(numberFormatSanitizerInt(e.target.value))}
												pre='$'
												placeholder={'Indicate $ amount'}
												decimalScale={2}
												decimalSeparator='.'
											/>
										</Form.Group>

									</>
								}
							</Row>
							<Row>
								<Form.Group as={Col} md='5'>
									<Form.Label className='transform-none'>Rent is payable to:</Form.Label>
									<Form.Control
										type='text'
										name='rent_payable_to'
										id='rent_payable_to'
										value={rent_payable_to}
										placeholder='Type here'
										onChange={(e) => setRent_payable_to(e.target.value)}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} md='8'>
									<Form.Label className='transform-none'>Rent will be paid using the following methods:</Form.Label>
									<SingleOptionCheck
										mapData={rentPaid} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'rent_pay_method'} setParam={rent_pay_method} errMessage={errorRentMethodClass ? 'Rent payment method is required' : ''} isInvalid={errorRentMethodClass}
									/>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} md='3'>
									<Form.Label className='transform-none'>Is the first rental period a partial period?</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'first_rental_partial_period'} setParam={first_rental_partial_period}
									/>
								</Form.Group>
								{first_rental_partial_period === 1 &&
									<Form.Group as={Col} md='9'>
										<Form.Label className='transform-none'>This partial covers the rental of the unit from:</Form.Label>
										<Row>
											<Col md='5'>
												<DatePickerComponent
													name="partial_cover_start_date"
													id="partial_cover_start_date"
													value={partial_cover_start_date}
													handleDateChange={(date) => handleDate2(date, setCoverStartDate)}
													displayText={"Start Date"}
													minDate={new Date()}
												/>
											</Col>
											<span className='mt-2'>to</span>
											<Col md='5'>
												<DatePickerComponent
													name="partial_cover_end_date"
													id="partial_cover_end_date"
													value={partial_cover_end_date}
													// handleDateChange={(end_date) => handleDate2(end_date, setCoverEndDate)}
													displayText={"End Date"}
													disableValue={true}
													minDate={partial_cover_start_date}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='5'>
												<Form.Label className='mt-4 transform-none'>The tenant will have to pay an amount of:</Form.Label>
												<CostNumberFormat
													name={'partial_cover_amount'}
													id={'partial_cover_amount'}
													classname='form-control'
													value={partial_cover_amount ? partial_cover_amount : ''}
													pre='$'
													placeholder='Type here'
													disabled={true}
													decimalScale={2}
													decimalSeparator='.'
												/>
											</Col>
											<span className='mt-6'>on</span>
											<Col md='5'>
												<Form.Label className='mt-4 transform-none'>Date of partial payment</Form.Label>
												<DatePickerComponent
													name="partial_payment_date"
													id="partial_payment_date"
													value={partial_payment_date}
													displayText={"Date"}
													disableValue={true}
												/>
											</Col>
										</Row>
									</Form.Group>}
							</Row>

							<Row>
								<Form.Group as={Col} md='6'>
									<Form.Label className='transform-none'>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
									<NumberFormat
										thousandSeparator={true}
										prefix='$'
										allowNegative={false}
										allowEmptyFormatting={false}
										allowLeadingZeros={false}
										value={administrative_charge ? administrative_charge : ''}
										onChange={(e) => setAdministrative_charge(numberFormatSanitizerInt(e.target.value))}
										className='form-control'
										placeholder={'$20'}
										name={'administrative_charge'}
										id={'administrative_charge'}
										isAllowed={(value) => {
											const { formattedValue, floatValue } = value;
											return formattedValue === '' || (floatValue > 0 && floatValue <= 20);
										}}
									/>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} md='7'>
									<Form.Label className='transform-none'>Is smoking allowed? (If allowed, please ensure to include it to your lease appendix)</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_smoking_allowed'} setParam={is_smoking_allowed}
									/>
								</Form.Group>
							</Row>

							<hr />
							<h3 className='text-left lease-heading transform-none'>deposits and discounts</h3>
							<Row>
								<Form.Group as={Col} md='3'>
									<Form.Label>Is there a rent deposit?</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_deposit'} setParam={is_rent_deposit}
									/>
								</Form.Group>

								{is_rent_deposit === 1 &&
									<Form.Group as={Col} md='5'>
										<Form.Label>Rent Deposit Amount</Form.Label>
										<NumberFormat
											thousandSeparator={true}
											prefix='$'
											allowNegative={false}
											allowEmptyFormatting={false}
											allowLeadingZeros={false}
											value={is_rent_deposit_amount ? is_rent_deposit_amount : ''}
											onChange={(e) => setRent_deposit_amount(numberFormatSanitizerInt(e.target.value))}
											className='form-control'
											placeholder={'$$$'}
											name={'is_rent_deposit_amount'}
											id={'is_rent_deposit_amount'}
											isAllowed={(value) => {
												const { formattedValue, floatValue } = value;
												return formattedValue === '' || (floatValue > 0 && floatValue <= (unitDetails && unitDetails.price_per_month));
											}}
										/>
									</Form.Group>}
							</Row>

							<Row>
								<Form.Group as={Col} md='3'>
									<Form.Label className='transform-none'>Is there a key deposit?</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_key_deposite'} setParam={is_key_deposite}
									/>
								</Form.Group>

								{is_key_deposite === 1 &&
									<Form.Group as={Col} md='5'>
										<Form.Label className='transform-none'>Key Deposit Amount <em>(can only be amount to replace key)</em></Form.Label>
										<CostNumberFormat
											name={'key_deposite_amount'}
											id={'key_deposite_amount'}
											classname='form-control'
											value={key_deposite_amount ? key_deposite_amount : ''}
											updateField={(e) => setKeyDepositeAmount(numberFormatSanitizerInt(e.target.value))}
											pre='$'
											placeholder={'$$$'}
											decimalScale={2}
											decimalSeparator='.'
										/>
									</Form.Group>}
							</Row>

							<Row>
								<Form.Group as={Col} md='3'>
									<Form.Label className='transform-none'>Is there a rent discount</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_discounted'} setParam={is_rent_discounted}
									/>
								</Form.Group>
								{is_rent_discounted === 1 &&
									<>
										<Form.Group as={Col} md='5'>
											<Form.Label>Amount</Form.Label>
											<CostNumberFormat
												name={'rent_discount_amount'}
												id={'rent_discount_amount'}
												classname='form-control'
												value={rent_discount_amount ? rent_discount_amount : ''}
												updateField={(e) => setDiscountedAmount(numberFormatSanitizerInt(e.target.value))}
												pre='$'
												placeholder={'$$$'}
												decimalScale={2}
												decimalSeparator='.'
											/>

											<Form.Label className="mt-3">Rent Discount Description</Form.Label>
											<Form.Control
												type='text'
												as='textarea'
												rows={5}
												maxLength={200}
												name='rent_discount_description'
												id='rent_discount_description'
												value={rent_discount_description}
												placeholder='Write description here...'
												onChange={(e) => setRentDiscountDesc(e.target.value)}
											/>
										</Form.Group>
									</>
								}
							</Row>
							<hr />
							<h3 className='text-left lease-heading'>who pays for utilities?</h3>
							<Row>
								<Form.Group as={Col} md='3'>
									<Form.Label>Electricity</Form.Label>
									<SingleOptionCheck
										mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_electricity_pay_by'} setParam={utility_electricity_pay_by}
									/>
								</Form.Group>
								<Form.Group as={Col} md='3'>
									<Form.Label>Heat</Form.Label>
									<SingleOptionCheck
										mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_heat_pay_by'} setParam={utility_heat_pay_by}
									/>
								</Form.Group>
								<Form.Group as={Col} md='3'>
									<Form.Label>Water</Form.Label>
									<SingleOptionCheck
										mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_water_pay_by'} setParam={utility_water_pay_by}
									/>
								</Form.Group>
							</Row>
							<hr />

							<h3 className='text-left lease-heading'>amenities</h3>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Gas</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'gas'} setParam={gas}
									/>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>A/C</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'AC'} setParam={AC}
									/>
								</Form.Group>

								<Form.Group as={Col} md='3'>
									<Form.Label>Additional Storage Space</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'storageSpace'} setParam={storageSpace}
									/>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Laundry</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'laundry'} setParam={laundry}
									/>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Guest Parking</Form.Label>
									<SingleOptionCheck
										mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'guestParking'} setParam={guestParking}
									/>
								</Form.Group>
							</Row>

							<Form.Label>Others</Form.Label>
							{other_service_utilities.map((data, i) => {
								return <Row key={i}><Form.Group as={Col} md='4'>

									<Form.Control
										type='text'
										name='other_service_utilities'
										id='other_service_utilities'
										value={data}
										placeholder='Type here'
										disabled={true}
									// onChange={(e) => setOther_service_utilities(e.target.value)}
									/>
								</Form.Group></Row>
							})}
							<Row>
								<Form.Group as={Col} md='4'>
									<Form.Control
										type='text'
										name='other_service_utilities_extra'
										id='other_service_utilities_extra'
										value={other_service_utilities_extra}
										placeholder='Specify services'
										onChange={(e) => otherUtilities(e.target.value)}
									/>
								</Form.Group>
							</Row>
						</div>
					</div>

					<div className='container-footer'>
						<Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
						{leaseData && leaseData.is_sent_to_tenant ?
							<Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('update')}>Update {saveLoading === true && saveClick === false && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
							: <Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('next')}>Next {saveLoading === true && saveClick === false && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
						}
					</div>
				</Form>
				<ModalBox
					show={is_lease_updated}
					onHide={() => redirectLast()}
					size="sm"
					actionbuttontitle={"got it"}
					buttonaction={() => redirectLast()}
				>
					<h4>your lease has been updated</h4>
					<p>the tenant has been <br /> notified of the changes. </p>
				</ModalBox>
			</>);
}

export default withRouter(CreateLeaseDetails);
