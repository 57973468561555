import React from 'react'
import { CancelButton, LargeButton } from '../../..';

const LeasePdfFooter = props => {
    const {role, handleCloseConfirm, loading, submit, is_signOffline, is_sent_to_tenant, isUpdated} = props;
    
    return (
        <>
            {role === 2 &&
                <div className="fixed-bottom">
                    {is_sent_to_tenant && isUpdated === false && <div className="d-block disclaimer font-italic pt-2 text-center">You have already sent the lease to the tenant(s)</div>}
                    <div className="d-flex justify-content-between">
                        <CancelButton onClick={() => handleCloseConfirm('back')} title='Back' />
                        <LargeButton 
                        saveLoading={loading} 
                        isDisable={is_sent_to_tenant && isUpdated === false ? true : false} 
                        title={is_signOffline?'continue':'Send to tenants'} 
                        id={is_sent_to_tenant && isUpdated === false ? null :'BTN-create-lease-complete'}
                        dataDescription={is_sent_to_tenant && isUpdated === false ? null : 'Landlord completed and sent lease. Property is now rented.'}
                        onClick={(e) => submit(e)} style={{ width: '200px' }} 
                        extraClass={is_sent_to_tenant && isUpdated === false ? 'disabled' : ''} />
                    </div>
                </div>
            }

            {(role === 3 && !is_signOffline) &&
                <div className="fixed-bottom d-flex justify-content-between">
                    <CancelButton onClick={() => handleCloseConfirm('back')} title='Back' />
                    <LargeButton title={'submit'} onClick={() => submit()} style={{ width: '200px' }} />
                </div>
            }
        </>
    )
}

export default LeasePdfFooter
