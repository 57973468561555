import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import { SidePanel, LargeButton, AutoCompleteAddress, CostNumberFormat, DatePickerComponent } from '../../../../components';
import { logger, toaster } from '../../../../../Utils';
import { bathroomsData, bedroomsData } from '../../../../../Constants/propertyDetails';
import { numberFormatSanitizerInt } from '../../../../../Utils/NumberSanitizer/NumberSanitizer';
import { validate } from '../../../../../Utils/Validation/validation';
import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormat } from '../../../../../Utils/DateSanitizer/DateSanitizer';
import { postAPI } from '../../../../../Api';
import { Google_Map_API } from '../../../../../Constants/endPoints';
import Geocode from 'react-geocode';
import { MainDetailSchema } from './Schema/MainDetailSchema';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const log = logger('MainDetails');
const MainDetails = (props) => {
    const { isShow, handleClose, apiCallback } = props;
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const leaseFetchedData = useSelector(state => state.propertyDetailsReducer.leaseFetchedData);

    const [property_details_id, setProperty_details_id] = useState('');
    const [bedroomsAdmin, setBedrooms] = useState(1);
    const [bathroomsAdmin, setBathrooms] = useState(1);
    const [addressAdmin, setAddress] = useState('');
    const [placeAdmin, setPlace] = useState('');
    const [address_line_1Admin, setAddress_line_1] = useState('');
    const [address_line_2Admin, setAddress_line_2] = useState('');
    const [cityAdmin, setCity] = useState('');
    const [provinceAdmin, setProvince] = useState('');
    const [countryAdmin, setCountry] = useState('');
    const [postal_codeAdmin, setPostal_code] = useState('');
    const [latitudeAdmin, setLatitude] = useState(34.059849);
    const [longitudeAdmin, setLongitude] = useState(-118.455551);
    const [dateAdmin, setDate] = useState(new Date());
    const [lease_durationAdmin, setLease_duration] = useState('');
    const [price_per_monthAdmin, setPrice_per_month] = useState('');
    const [unit_numberAdmin, setUnit_number] = useState('');
    const [is_condoAdmin, setIs_condo] = useState(false);
    const [property_type, setProperty_type] = useState(false);
    const [hide_exact_addressAdmin, setHide_exact_address] = useState(false);
    const [disableBtn, setBtnDisable] = useState(false);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const handleClickInside = (option) => {
        setIsPopoverOpen(option === 'yes' ? true : false);
    }
    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'bedroomsAdmin') {
            setBedrooms(Value);
        } else if (name === 'bathroomsAdmin') {
            setBathrooms(Value);
        }
    }
    const onSubmit = (event, props) => {
        event.preventDefault();
        if (validate(MainDetailSchema)) {
            setBtnDisable(true)
            const dataStep1 = {
                'address': addressAdmin,
                'address_line_1': address_line_1Admin,
                'address_line_2': address_line_2Admin,
                'city': cityAdmin,
                'province': provinceAdmin,
                'country': countryAdmin,
                'postal_code': postal_codeAdmin,
                'latitude': latitudeAdmin,
                'longitude': longitudeAdmin,
                'unit_no': unit_numberAdmin,
                'hide_exact_address': hide_exact_addressAdmin,
                'property_type': property_type,
                'is_condo': is_condoAdmin,
                'property_id': propertyDetails.id
            }
            const dataStep2 = {
                'available_date': dateFormat(dateAdmin),
                'lease_duration': lease_durationAdmin,
                'bedrooms': bedroomsAdmin === null ? '' : bedroomsAdmin,
                'bathrooms': bathroomsAdmin === null ? '' : bathroomsAdmin,
                'unit_name': unit_numberAdmin,
                'unit_number': unit_numberAdmin,
                'price_per_month': price_per_monthAdmin,
                'property_type': property_type,
                'property_details_id': property_details_id,
                'property_id': propertyDetails.id
            }
            const urlStep1 = '/admin/listing/update/step-1';
            postAPI(urlStep1, dataStep1).then(response => {
                setBtnDisable(false)
                if (response.response && response.response.status === 422) {
                    toaster('error', response.response.data.message);
                } else if (response.status !== 200) {
                    toaster('error', 'Something went wrong!');
                } else if (response.status === 200) {
                }
            })
            const urlStep2 = '/admin/listing/update/step-2';
            postAPI(urlStep2, dataStep2).then(response => {
                setBtnDisable(false)
                if (response.status === 200) {
                    apiCallback();
                    handleClose();
                } else if (response.response.status === 400) {
                    log.info(response.response)
                    toaster('error', response.response.data.message);
                } else if (response.response.status === 422) {
                    toaster('error', response.response.data.message);
                }
            })
        }
    }
    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        setAddress(address);
        const latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
        setPlace(placeAdmin)
        setLatitude(latValue)
        setLongitude(lngValue)
    }
    const handleChange = event => {
        setAddress(event.target.value);
    }
    const OnChangeInput = (event) => {
        if (event.target.name === 'lease_durationAdmin') {
            setLease_duration(event.target.value)
        } else if (event.target.name === 'price_per_monthAdmin') {
            const sanitizeValue = numberFormatSanitizerInt(event.target.value);
            setPrice_per_month(sanitizeValue)
        } else if (event.target.name === 'unit_numberAdmin') {
            setUnit_number(event.target.value)
        }
    }
    const getPlaceDetails = (addressArray) => {
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
                setCity(addressArray[i].long_name)
            } else if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                setProvince(addressArray[i].long_name)
            } else if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                setCountry(addressArray[i].long_name)
            } else if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
                setPostal_code(addressArray[i].long_name)
            }
        }
    };
    const getAddress = (latitude, longitude) => {
        Geocode.setApiKey(Google_Map_API);
        Geocode.fromLatLng(latitude, longitude).then(
            response => {
                const address = response.results[0];
                setPlace(address)
            },
            error => {
                console.error(error);
            }
        );
    }
    useEffect(() => {
        if (placeAdmin.formatted_address) {
            const address_array = placeAdmin.formatted_address.split(',');
            setAddress_line_1(address_array[0])
            setAddress_line_2(address_array[1])
            getPlaceDetails(placeAdmin.address_components)
        }
    }, [placeAdmin])

    useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_details_id]);

    useEffect(() => {
        if (propertyDetails && propertyDetails.id) {
            getAddress(propertyDetails.latitude, propertyDetails.longitude)
            setAddress_line_1(propertyDetails.address_line_1)
            setAddress_line_2(propertyDetails.address_line_2)
            setCity(propertyDetails.city)
            setProvince(propertyDetails.province)
            setCountry(propertyDetails.country)
            setPostal_code(propertyDetails.postal_code)
            setLatitude(propertyDetails.latitude)
            setLongitude(propertyDetails.longitude)
            setUnit_number(propertyDetails.unit_no)
            setHide_exact_address(propertyDetails.hide_exact_address)
            setAddress(propertyDetails.address);
            setProperty_type(propertyDetails.property_type);
            setIs_condo(propertyDetails.is_condo);
        }
        if (unitDetails) {
            setDate(new Date(unitDetails.available_date))
            setBedrooms(unitDetails.bedrooms !== null ? unitDetails.bedrooms : 1)
            setBathrooms(unitDetails.bathrooms !== null ? unitDetails.bathrooms : 1)
            setLease_duration(unitDetails.lease_duration)
            setPrice_per_month(unitDetails.price_per_month);
        }
    }, [propertyDetails, unitDetails])
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title='Edit Information'>
            <h4 className='edit-main mt-3'>main details</h4>
            <form id='Form' onSubmit={onSubmit} autoComplete='off'>
                <div className='sidepanel-body filter-tenant main-details'>
                    <Row>
                        <Form.Group as={Col} md='12' className='mt-4 autoAddress'>
                            <AutoCompleteAddress
                                gridClass='mt-4 email-elm'
                                md='12'
                                as={Col}
                                id={'addressAdmin'}
                                name={'addressAdmin'}
                                state={addressAdmin}
                                updateField={(data) => handleChange(data)}
                                handlePlaceChanged={(data) => handlePlaceChanged(data)}
                                placeholder='Example: 123 John Street West'
                                addressTitle={'Property Address Display'}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>Unit Number Or Name</Form.Label>
                            <Form.Control
                                type='text'
                                name='unit_numberAdmin'
                                id='unit_numberAdmin'
                                value={unit_numberAdmin}
                                placeholder='Example: Unit 202'
                                onChange={(e) => OnChangeInput(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>How many Bedrooms in This Specific Unit?</Form.Label>
                            <ul className='select-options'>
                                {bedroomsData.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'bedrooms_' + data.value} value={data.value} name='bedroomsAdmin' id='bedroomsAdmin'
                                        className={(bedroomsAdmin === data.value) ? 'active' : ''}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>How many Bathrooms in This Unit?</Form.Label>
                            <ul className='select-options'>
                                {bathroomsData.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'bathrooms_' + data.value} value={data.value} name='bathroomsAdmin' id='bathroomsAdmin'
                                        className={(bathroomsAdmin === data.value) ? 'active' : ''}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>When Will The Unit Be Available?</Form.Label>
                            <DatePickerComponent
                                name='dateAdmin'
                                id='dateAdmin'
                                value={dateAdmin}
                                handleDateChange={date => setDate(date)}
                                displayText={'Select or type'}
                                minDate={new Date()}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>Lease Duration</Form.Label>
                            <Form.Control
                                as='select'
                                className='my-1 mr-sm-2'
                                id='lease_durationAdmin'
                                custom
                                name='lease_durationAdmin'
                                value={lease_durationAdmin}
                                onChange={(e) => OnChangeInput(e)}
                            >
                                <option value=''>Select one</option>
                                <option value='2'>Month to month</option>
                                <option value='1'>1 Year lease</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md='12'>
                            <div className='display-flex-inline-elm'>
                                <Form.Label>Price Per Month</Form.Label>
                                <span onMouseEnter={() => handleClickInside('yes')} onMouseLeave={() => handleClickInside('no')}>
                                    <Image width='20' height='20' id='button' src={require('../../../../../_assets/images/que-icon.svg')} alt='close' />
                                </span>
                                {<div className='custom-popover' style={{ 'display': `${isPopoverOpen === true ? 'block' : 'none'}` }}>
                                    <p> {leaseFetchedData && leaseFetchedData.status === "Delivered" ? "Lease has been submitted and awaiting for signature" : "Don’t know what to charge for rent or what your place is worth? our team can come by for a free rent assessment of your unit at your convenience."}</p>
                                </div>}
                            </div>
                            <CostNumberFormat
                                name={'price_per_monthAdmin'}
                                id={'price_per_monthAdmin'}
                                classname='form-control'
                                value={price_per_monthAdmin ? price_per_monthAdmin : ''}
                                updateField={(e) => OnChangeInput(e)}
                                pre='$'
                                placeholder={'Enter your desired pricing'}
                            />
                        </Form.Group>
                    </Row>
                </div>
                <div className='fixed-bottom d-flex justify-content-end'>
                    <LargeButton saveLoading={disableBtn} title='save changes' type='submit' style={{ width: '200px' }} />
                </div>
            </form>
        </SidePanel>
    </>
    );
}
export default withRouter(MainDetails);