import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import AppLayout from "../../AppLayout";
import PageTitleHeader from "../../PageTitleHeader";
import { logger, toaster, validate } from "../../../../Utils";
import { withRouter } from 'react-router-dom';
import { postAPI } from "../../../../Api";
import { connect } from "react-redux";
import { VerificationSchema } from "../../../../Website/Authentication/Verification/VerificationSchema";

const log = logger('Authenticate')
class Authenticate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activation_code: null,
      activated: false,
      email: '',
      flow: ''
    };

    this.goBack = this.goBack.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

  }
  componentDidMount = (props) => {
    this.setState({
      email: this.props?.location?.state?.data ? this.props?.location?.state?.data : '',
      flow: this.props?.location?.state !== undefined ? this.props?.location?.state?.flow : '',
    }, () => {
      log.info(this.state.email)
    })
  }
  onChangeActivationCode = (e) => {
    this.setState({
      activation_code: e.target.value,
    });
  };

  resendCode = (email) => {
    const data = {
      email: email
    }
    postAPI(`/resend-activation-code`, data).then(res => {
      if (res.status === 200) {
        toaster("success", res.data.message);
      } else if (res.response.status === 400) {
        toaster('error', res.response.data.message);
      } else if (res.response.status === 422) {
        toaster('error', res.response.data.message);
      } else if (res.response.status === 404) {
        toaster('error', res.response.data.message);
      }
    })
  }

  renderInput = (type, id, placeholder, onChange) => {
    return (
      <FormGroup style={{ position: 'relative' }}>
        <Input
          type={type}
          name={id}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
        /> <p style={{
          position: 'absolute',
          top: '10px',
          right: '11px',
          fontSize: '11px',
          fontWeight: '600',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
          onClick={() => this.resendCode(this.state.email)}>Resend Code</p>
        {log.info(this.props)}
      </FormGroup>
    );
  };


  cancelBackStep = () => {
    this.props.history.push("/editprofile")
  }


  handleConfirm = (e) => {
    e.preventDefault();
    if(validate(VerificationSchema)){
      const data = {
        activation_code: this.state.activation_code,
      };
      const url = "/verify-change-email";
      postAPI(url, data)
        .then((res) => {
          if(res.status !== 200){
            toaster('error', res.response.data.message)
          }else if(res.status === 200){
            this.props.history.push('/profile')
            toaster("success", res.data.message);
          }
        })
        .catch(function (error) {
          // toaster('error', error.response.data.message)
        });
    }
  };

  goBack() {
    this.props.history.push('/profile')
  }

  render() {
    const { email } = this.state

    return (
      <AppLayout>
        <PageTitleHeader
          title='edit profile'

          isArrow={true}
          onClick={this.goBack}
        />
        <div className="profile-container">
          <div className="profile-container-box">
            <div className="inner-wrapper">
              <div className="profile-item">
                <Form id='Form' className="profile-form">
                  <div className="image-wrapper">
                    <img
                      width="67.08"
                      height="69.99"
                      alt="entercode"
                      src={require("../../../../_assets/images/entercode.png")}
                      className="d-inline-block align-top"
                    />

                    <span className=" bl-img-title navbar-brand-name">
                      Enter the four digit code
                    </span>
                    <span style={{ marginTop: '20px', textAlign: 'center' }}>
                      A 4 digit PIN has been emailed at {email}.<br />
                      Please enter it here to confirm your account.
                    </span>
                  </div>
                  {this.renderInput(
                    "text",
                    "security_pin",
                    "Enter 4 Digit PIN here",
                    this.onChangeActivationCode
                  )}

                  <Button
                    type='submit'
                    className="btn btn-primary text-bold mt-5"
                    onClick={this.handleConfirm}
                    style={{ width: '100%' }}
                  >
                    Confirm
                    </Button>
                  <Button className="btn text-bold btn-default"
                    style={{ width: '100%' }} onClick={() => this.cancelBackStep()}>Cancel</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}

const select = (state) => ({
  auth: state.auth
});
export default connect(select)(withRouter(Authenticate));