import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DollorNumberDisplay, PhoneNumberFormat, ProfileCard } from '../..';
import Moment from 'react-moment';
import Cookies from 'js-cookie';
import { EMPLOYMENT_STATUS } from '../../../../Constants/profileConstants';
import {salaryOptions} from '../../Sidebar/constant'

const EmploymentInfoDisplay = (props) => {
  const { profileDetails, editaction, addaction } = props
  const role = Number(Cookies.get('role'));


  const getEmploymentInfo = (data) => {
    // this function needs to refactor 
    const salary = [];
    salaryOptions.forEach((single) => {
      if (single.value === data.salary_range) {
        salary.push(single.display);
      }
    });
    
    if (data.is_current && (data.employment_status === 4 || data.employment_status === 5)) {
      return <Row>
        <Col md={2}>
          {
            <>
              <label>Employment Status</label>
              <h3>{data.employment_status === 4 ? "Social Assistance" : "Retired"}</h3>
            </>
          }
        </Col>
        <Col md={3}>
          {
            <>
              <label>Monthly Income</label>
              <h3> <DollorNumberDisplay price={data.min_salary} /></h3>
            </>
          }
        </Col>
      </Row>
    }

    return <Row>
      <Col>
        {
          <>
            <label>Employment Status</label>
            {<h3>{data.is_current ? EMPLOYMENT_STATUS[data.employment_status] : "Past Job"}</h3>}
            {/* <h3>{(data.employment_status === 1 || data.employment_status === 2 || data.employment_status === 4) && data.is_current ? 'Full Time' : data.employment_status === 3 ? 'Student' : 'Previous Job'}</h3> */}
          </>
        }
      </Col>
      <Col>
        <label>{data.employment_status !== 3 ? 'Job Title' : 'School Name'}</label>
        <h3>{data.job_title ? data.job_title : data.school_attend}</h3>
      </Col>
      <Col md='3'>
        <label>Period</label>
        {/* <h3><Moment format='MMM D, YYYY'>{data.start_date}</Moment> - {data.is_current === 1 ? 'Present' : 'Past'}</h3> */}
        {data.employment_status === 4 ? <h3>--</h3> : <h3><Moment format='MMM D, YYYY'>{data.start_date}</Moment> - {data.end_date ? <Moment format='MMM D, YYYY'>{data.end_date}</Moment> : data.is_current === 1 ? 'Present' : 'Past'} </h3>}
      </Col>

      <Col>
        
        <label>Annual income</label>
        <h3>
          {data.salary_range ?
            <>
              {salary[0]}
            </> : '--'}
        </h3>
      </Col>

      <Col>
        <label>Reference</label>
        <h3>{data.employment_reference_name ? data.employment_reference_name : '--'}</h3>
      </Col>
      <Col md="2">
        <label>Contact</label>
        <h3 className='highlight'><PhoneNumberFormat phoneNumber={String(data.employment_reference_phone).length === 10 ? data.employment_reference_phone : '--'} /></h3>
      </Col>
    </Row>
    // this function needs to refactor 

  }

  return (
    <ProfileCard
    isComplete={ props?.isComplete}

      title={role !== 1 ? 'Employment Info' : 'Employment Status'}
      editaction={editaction}
      addaction={addaction}>

      <Container fluid>
        {profileDetails.employment_info ?
          profileDetails.employment_info.reverse().map((data, i) => {
            return (<div key={'employee_status_' + i}>
              {i > 0 && <hr />}{getEmploymentInfo(data)}
            </div>)
          }) :
          <Row>
            <Col md='12' className='d-flex justify-content-center'>
              <label>No Information Provided</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default EmploymentInfoDisplay;