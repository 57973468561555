export const FormsValidationSchema = {
    cms_category_id: [
        { rule: "required", message: "category ID is required" }
    ],
    title: [
        { rule: "required", message: "headline is required" }
    ],
    status: [
        { rule: "required", message: "status is required" },
    ],
}