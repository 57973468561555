import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import "./style.scss";
import { AppLayout, PageTitleHeader, RequestShowing, ConfirmShowing, TenancyForm } from "../../components";
import { logger, toaster } from "../../../Utils";
import { getAPI } from "../../../Api";
import { Nav } from "react-bootstrap";
import TenantPropertyDetails from "./TenantPropertyDetails";
import { MessageTenant } from "./MessageTenant.js";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { setPropertyDetail } from "../../../Store/actions/PropertyDetailAction";
import { getCalenderData, setHeaderContent, setReceiverData, setTenancyApplicationId, setViewingRequestId } from "../../../Store/actions/MessageDetailAction";
import { LeasePdfView } from '../../components/LeaseManagement/CreateLeaseSteps';
import { isRentedToTenant } from "./helper";
import PropertyDrawer from "../../components/PropertyDrawer/PropertyDrawer";
import { Helmet } from 'react-helmet';

const log = logger("RootTenantPropertyDetails");
const RootTenantPropertyDetails = (props) => {
    const role = Number(Cookies.get('role'));
    const [is_showingRequest, setShowingRequest] = useState(false);
    const [componentLoad, setProperty_component_load] = useState('');
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [is_confirmShowing, setConfirmShowing] = useState(false);
    const [is_reSchedule, setReSchedule] = useState(false);
    const [is_apply_for_tenancy, setApply_for_tenancy] = useState(false);
    const dispatch = useDispatch();
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [is_LeasePdfViewScreen, setLeasePdfViewScreen] = useState(false);
    const [showPropertyDetails, setShowPropertyDetails] = useState(false)
    const receiverData = useSelector(state => state.messageDetailsReducer.receiverData);
    const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
    const tenantId = useSelector(state => state.auth.id);

    log.info('receiverData', receiverData)
    log.info('props', props)

    useEffect(() => {
        const eventObj = props.location.state
        const tenancy_application_id = eventObj?.tenancy_application_id

        if (tenancy_application_id) {
            dispatch(setReceiverData(null));
            dispatch(getCalenderData(null));
            dispatch(setViewingRequestId(null));
            dispatch(setTenancyApplicationId(tenancy_application_id));
            setApply_for_tenancy(true)
            return
        }
        if (eventObj && Object.keys(eventObj).length) {
            dispatch(getCalenderData(eventObj));
            dispatch(setReceiverData(null));
            dispatch(setViewingRequestId(eventObj.viewingRequestId))
            if (eventObj.viewingRequestId)
                setConfirmShowing(true);
        }
        if (eventObj?.property_lease_id) {
            dispatch(setReceiverData(eventObj));
            dispatch(getCalenderData(null));
            setLeasePdfViewScreen(true)
        }
    }, [])

    useEffect(() => {
        if (property_details_id) {
            const url = '/tenant/' + property_details_id + '/property-unit-details';
            getAPI(url).then(response => {
                if (response.status !== 200) {
                    backToDashboard();
                    toaster('error', response.response.data.message);
                } else if (response.status === 200) {
                    const res = response.data.data;
                    if (res.status === 4 && !isRentedToTenant(res.property_tenants, tenantId) && !res.is_relist) {
                        props.history.push(tenantId ? "/search-listing" : "/search-result");
                        toaster('error', "Sorry, this listing has been rented.");
                    } else {
                        dispatch(setPropertyDetail(res));
                        dispatch(setHeaderContent({
                            name: res.landlord_name
                        }));
                    }
                }
            })
                .catch(error => {
                });
        }
        return () => {
            dispatch(setPropertyDetail({}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property_details_id])




    const backToDashboard = () => {
        if (Cookies.get('access_token')) {
            if (props?.location?.state?.backPath) {
                props.history.push(props?.location?.state?.backPath);
            } else if (role === 1) {
                props.history.push("/dashboard/all-user");
            } else if (role === 2) {
                props.history.push("/dashboard");
            } else if (role === 3) {
                if (propertyDetails?.status === 4) {
                    props.history.push("/dashboard/my-home");
                } else {
                    props.history.push("/dashboard/my-listing");
                }
            }
        } else {
            if (props?.location?.state?.backpath) {
                props.history.push(props?.location?.state?.backpath);
            } else {
                props.history.push("/search-result");
            }
        }
    }

    const requestShowingCallback = (value) => {
        setShowingRequest(value);
    }
    useEffect(() => {
        setProperty_component_load(props.match.params.slug);
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props]);

    const handleApplyForTenancy = (flag) => {
        setApply_for_tenancy(flag);
        props.history.push(props.history.pathname);
    }

    const propertyTypeAddress = {
        1: 'full property',
        2: 'Unit in building/house',
        3: 'Room for rent',
    }

    let finalAddress = ''
    if (propertyDetails?.address) {
        if (propertyDetails?.address[propertyDetails?.address.trim().length - 1] === ',') {
            finalAddress = propertyDetails?.address.replace(propertyDetails?.address[propertyDetails?.address.trim().length - 1], '')
            if (propertyDetails?.property_type === 2) {
                finalAddress = finalAddress + ' - ' + propertyDetails?.unit_no
            } else if (propertyDetails?.property_type === 3) {
                finalAddress = finalAddress + ' - ' + propertyDetails?.unit_no
            }
        } else {
            if (propertyDetails?.property_type === 2) {
                finalAddress = propertyDetails?.address + ' - ' + propertyDetails?.unit_no
            } else if (propertyDetails?.property_type === 3) {
                finalAddress = propertyDetails?.address + ' - ' + propertyDetails?.unit_no
            } else {
                finalAddress = propertyDetails?.address
            }
        }
    } else {
        finalAddress = 'property details'
    }

    return (
        <>
            <Helmet>
              <title>{finalAddress} | Apartments and Homes for Rent | Rent Panda</title>
            </Helmet>
            <AppLayout>
                <div className="profile">
                    <PageTitleHeader
                        title={finalAddress}
                        isArrow={true}
                        onClick={() => backToDashboard()}
                    />
                    {(Cookies.get('access_token') && role === 3) &&
                        <Nav fill variant="tabs" className="rp-tab-views">
                            <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/details' }}
                                className={componentLoad === "details" || componentLoad === undefined ? "nav-link active" : "nav-link"} >
                                Details
                            </NavLink>
                            <NavLink to={{ pathname: '/property-details/' + property_id + '/' + property_details_id + '/messages' }} className={"nav-link"} >Messages</NavLink>
                        </Nav>}
                    {componentLoad === "details" || componentLoad === undefined ?
                        <TenantPropertyDetails callbackRequestShowing={(data) => requestShowingCallback(data)} /> : ""}
                    {componentLoad === "messages" ?
                        <MessageTenant
                            setShowPropertyDetails={setShowPropertyDetails}
                            setShowingRequest={(flag) => setShowingRequest(flag)}
                            setReSchedule={(flag) => setReSchedule(flag)}
                            is_reSchedule={is_reSchedule}
                            componentLoad={componentLoad === "messages"}
                            setConfirmShowing={(flag) => setConfirmShowing(flag)}
                            setApply_for_tenancy={(flag) => setApply_for_tenancy(flag)}
                            setLeasePdfViewScreen={(flag) => setLeasePdfViewScreen(flag)} />
                        : ""}
                </div>
            </AppLayout>

            <RequestShowing
                isShow={is_showingRequest}
                title={is_reSchedule ? 'submit a different schedule' : 'request a showing'}
                handleClose={() => setShowingRequest(false)}
                is_reSchedule={is_reSchedule}
                setReSchedule={(flag) => setReSchedule(flag)}
                property_details_id={property_details_id}
            />

            <ConfirmShowing
                isShow={is_confirmShowing}
                title={'showing invitation'}
                handleClose={() => setConfirmShowing(false)}
                setShowingRequest={(flag) => setShowingRequest(flag)}
                setReSchedule={(flag) => setReSchedule(flag)}
            />

            <TenancyForm
                isShow={is_apply_for_tenancy}
                title={'Apply for tenancy'}
                handleClose={() => handleApplyForTenancy(false)}
                role={role}
                name={headerContent && headerContent.name}
                property_id={property_id}
                property_details_id={property_details_id}
            />

            <LeasePdfView
                isShow={is_LeasePdfViewScreen}
                handleClose={() => setLeasePdfViewScreen(false)}
                lease_id={receiverData && receiverData.property_lease_id}
                role={3}
                is_signOffline={receiverData && receiverData.is_offline_lease}
            />
            <PropertyDrawer
                finalAddress={finalAddress}
                isShow={showPropertyDetails}
                handleClose={() => {
                    setShowPropertyDetails(false)
                }}
            />


        </>
    );
}

export default withRouter(RootTenantPropertyDetails);