import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
// import { activeStatus, listManagemantPagination } from '../../../../Store/actions/Action';
// import { useDispatch, useSelector } from 'react-redux';
import { HighlightContent, SearchInput, ModalBox } from '../../../components';
import { logger, toaster } from '../../../../Utils';
import { CardDeck, Card, Form, Col, Button, ListGroup } from 'react-bootstrap';
import { useFormik } from "formik";
import '../style.scss';

const log = logger('Category and Tags Management');
const CategoryTags = (props) => {
    // const { componentLoad } = props;
    // const [componentLoad, setComponentLoad] = useState('content-management');
    // useEffect(() => {
    //     const slug = props.match.params.slug;
    //     setComponentLoad(slug)
    // }, [props]);
    // const [isLoading, setIsLoading] = useState(true);
    const [categoryName, setCategoryName] = useState('');
    const [tagName, setTagName] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [deletePrompt, setDeletePrompt] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteType, setDeleteType] = useState('');
    const [deletedModalShow, setDeletedModalShow] = useState(false);
    const [reload, setReload]= useState(false);
    const [successModalShow, setSuccessModalShow]= useState(false);
    const [typeOfAdded, setTypeOfAdded] = useState('');

    const handleFormSubmit = (data) => {
        const createUrl = '/admin/cms/create-category-tags';
        setReload(false);
        postAPI(createUrl, data).then((response) => {
            if (response === undefined) {
                toaster("error", "Something went wrong!");
            } else if (response.status === 200) {
                const res = response.data.data;
                log.info("Sharing ",res);
                setSuccessModalShow(true);
                addCategory.values.name = '';
                addTag.values.name = '';
                setReload(true);
            }
        });
    }
    const addCategory = useFormik({
        initialValues: {
            type: 1,
            name: '',
        },
        onSubmit: values => {
            setTypeOfAdded('Category');
            handleFormSubmit(values);
        },
    });
    const addTag = useFormik({
        initialValues: {
            type: 2,
            name: '',
        },
        onSubmit: values => {
            setTypeOfAdded('Tag');
            handleFormSubmit(values);
        },
    });
    
    const deleteCategoryTag=(id, confirm)=>{
        setDeleteId(id);
        setDeletePrompt(true);
        setReload(false);
        if (confirm === "yes") {
            const deleteUrl = "/admin/cms/delete-category-tags"
            const data = { id:id }
            postAPI(deleteUrl, data).then(res => {
                log.info("Delete Message:", res.data.message);
                setDeletePrompt(false);
                setDeletedModalShow(true);
                setReload(true);
            })
        }
    }

    useEffect(() => {
        const categoryUrl = '/admin/cms/get-categories';
        const tagsUrl = '/admin/cms/get-tags';

        postAPI(categoryUrl, { "name": categoryName }).then(response => {
            if (response.status === 200) {
                // setIsLoading(false)
                setCategoryData(response.data.data);
            }
        })
        postAPI(tagsUrl, { "name": tagName }).then(response => {
            if (response.status === 200) {
                // setIsLoading(false)
                setTagsData(response.data.data);
            }
        });
        setReload(false);
    }, [reload, categoryName, tagName]);

    return (
        <>
            <CardDeck className="categoryTagsPage">
                <Card className="border-0">
                    <Card.Title>Categories</Card.Title>
                    <Card.Body>
                        <Form onSubmit={addCategory.handleSubmit}>
                            <Form.Group id="formCategory">
                                <Form.Label>New Category</Form.Label>
                                <Form.Row>
                                    <Col md={7} lg={9}>
                                        <Form.Control name="name" id="name" type="input" placeholder="Input Name Here"
                                            onChange={addCategory.handleChange}
                                            value={addCategory.values.name}
                                        />
                                    </Col>
                                    <Col md={5} lg={3}>
                                        <Button className="m-0" variant="primary" type="submit">Add</Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        </Form>
                        
                        <SearchInput searchValue={categoryName} setSearchValue={setCategoryName} placeHolder="Search" extraCalss="search-filter mb-4" />

                        <ListGroup variant="flush">
                            {
                                categoryData && categoryData.map(category => {
                                    return (
                                        <ListGroup.Item key={category.id} className="border-bottom-0 d-flex justify-content-between">
                                            <HighlightContent searchWord={categoryName} highlightWord={category.name} />
                                            <img onClick={() => {deleteCategoryTag(category.id); setDeleteType("category")}} className="cursorPointer" src={require('../../../../_assets/images/red-delete-icon.svg')} alt="" />
                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </Card.Body>
                </Card>
                <Card className="border-0">
                    <Card.Title>Tags</Card.Title>
                    <Card.Body>
                        <Form onSubmit={addTag.handleSubmit}>
                            <Form.Group id="formTags">
                                <Form.Label>New Tags</Form.Label>
                                <Form.Row>
                                    <Col md={7} lg={9}>
                                        <Form.Control name="name" id="name" type="input" placeholder="Input Name Here"
                                            onChange={addTag.handleChange}
                                            value={addTag.values.name}
                                        />
                                    </Col>
                                    <Col md={5} lg={3}>
                                        <Button className="m-0" variant="primary" type="submit">Add</Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        </Form>

                        <SearchInput searchValue={tagName} setSearchValue={setTagName} placeHolder="Search" extraCalss="search-filter mb-4" />

                        <ListGroup variant="flush">
                            {
                                tagsData && tagsData.map(tag => {
                                    return (
                                        <ListGroup.Item key={tag.id} className="border-bottom-0 d-flex justify-content-between">
                                            <HighlightContent searchWord={tagName} highlightWord={tag.name} />
                                            <img onClick={() =>{ deleteCategoryTag(tag.id); setDeleteType("tag")}} className="cursorPointer" src={require('../../../../_assets/images/red-delete-icon.svg')} alt="" />
                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </Card.Body>
                </Card>

            </CardDeck>

            <ModalBox
                show={successModalShow}
                onHide={() => setSuccessModalShow(false) }
                size="sm"
                actionbuttontitle={"got it"}
                buttonaction={() => setSuccessModalShow(false)}
            >
                <p className="coupon-success-text">New {typeOfAdded} added<br/> successfully</p>
            
            </ModalBox>
            <ModalBox
                show={deletePrompt}
                closeButtonHeader={false}
                onHide={() => setDeletePrompt(false)}
                size="sm"
                type="condition"
                actionbuttontitle={"Yes"}
                buttonaction={() => {
                    setDeletePrompt(false);
                    deleteCategoryTag(deleteId, "yes");
                }}
                backbuttontitle={"Not now"}
                backaction={() => {
                    setDeletePrompt(false);
                }}
            >
                <p className="modal-confirmation-text-coupon">
                    Are you sure you want to delete content? This cannot<br /> be undo.
                    {/* {deleteType} */}
                </p>
            </ModalBox>

            <ModalBox
                show={deletedModalShow}
                onHide={() => setDeletedModalShow(false)}
                size="sm"
                actionbuttontitle={"got it"}
                buttonaction={() => setDeletedModalShow(false)}
            >
                <p className="coupon-success-text">
                    {deleteType} deleted<br/>
                    successfully!
                </p>
            </ModalBox>
        </>
    )
}

export default withRouter(CategoryTags);