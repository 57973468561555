import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, InputGroup, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getAPI, postAPI } from '../../../../../Api';
import { IMAGE_API_URL } from '../../../../../Constants/endPoints';
import { cmsClickedData } from '../../../../../Store/actions/Action';
import { logger, toaster } from '../../../../../Utils';
import { validate } from '../../../../../Utils/Validation/validation';
import { AppLayout, DropDown, FileBrowse, ModalBox, PageTitleHeader } from '../../../../components';
import Footer from "../../../../../Website/components/Footer";
import ImageComponent from '../../../ProfileDetail/ListUsableComponent/ImageComponent';
import './style.scss';
import Cookies from 'js-cookie';
import NumberFormat from 'react-number-format';
import { phoneNumberSanitizer } from '../../../../../Utils/NumberSanitizer/NumberSanitizer';
import { PartnerValidationSchemal } from './PartnerValidationSchemal';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';

const log = logger('CreatePartners');
const CreatePartners = (props) => {
    const [showImageLoading,setShowImageLoading]=useState(false)
    const [componentLoad, setComponentLoad] = useState('');
    const [errorClass, setErrorClass] = useState(false);
    const [imageData, setImageData] = useState({ file: '', percent: 0 });
    const [company_name, setCompanyName] = useState('');
    const [company_address, setCompanyAddress] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [contact_number, setPhoneNumber] = useState('');
    const [email_partner, setEmail] = useState('');
    const [partner_desc, setDescription] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [draftModal, setDraftModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [action, setAction] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null)
    const [servicesList, setServicesList] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [serviceError, setServiceError] = useState(false);
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    const dispatch = useDispatch();

    const back = () => {
        props.history.push(`/cms/content-management`);
        dispatch(cmsClickedData(null));
    }
    const getData = (id) => {
        getAPI(`/admin/cms/partners/view/${id ? id : viewData.id}`).then(resData => {
            log.info(resData)
            const resDataView = resData.data.data;
            if (resData.status === 200) {
                setViewData(resDataView)
                setSelectedData([])
            }
        })
    }

    const onSubmit = (e, val) => {
        setSubmitStatus(val)
        e.preventDefault();
        if (validate(PartnerValidationSchemal)) {
            let serviceable_requests = [];
            if (selectedData.length !== 0) {
                selectedData.map((data) => serviceable_requests.push(data.id))
            }
            const data = {
                "company_name": company_name,
                "contact_email": email_partner,
                "serviceable_requests": serviceable_requests,
                "company_address": company_address,
                "contact_number": contact_number,
                "company_bio": partner_desc,
                "image_url": imageData.file,
                "status": val
            }
            let url = '';
            if (componentLoad === 'create') {
                url = `/admin/cms/partners/create`
            } else {
                url = `/admin/cms/partners/update`;
                data.id = viewData.id;
            }
            setSaveLoading(true);
            // if (selectedData.length !== 0) {
                postAPI(url, data).then(response => {
                    setSaveLoading(false);
                    if (response.status === 200) {
                        const res = response.data.data;
                        setDraftModal(true)
                        if (val === '0') {
                            setModalContent('draft')
                        } else {
                            setModalContent('publish')
                        }
                        getData(res.id);
                    } else if (response.response) {
                        toaster("error", response.response.data.message);
                    } else {
                        toaster("error", response.data.message);
                    }
                })
            // } else {
            //     setServiceError(true)
            // }
        }
    }
    const openConfirmationModal = (val) => {
        setAction(val)
        setDeleteModal(true);
    }
    const onClickYes = () => {
        if (action === 'delete') {
            const data = {
                'id': viewData.id
            }
            postAPI(`/admin/cms/partners/delete`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                }
            })
        }
        if (action === 'statusChange') {
            const data = {
                "id": viewData.id,
                "status": (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? '1' : '2'
            }
            postAPI(`/admin/cms/partners/change-status`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                    getData();
                }
            })
        }
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }
    const togggleDraftModal = () => {
        setDraftModal(false);
        if (clickedData !== null) {
            getData();
        } else {
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        }
    }
    const toggleConfirm = () => {
        if (action === 'delete') {
            setConfirmAction(false)
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        } else if (action === 'statusChange') {
            setConfirmAction(false)
        }
    }
    useEffect(() => {
        if (viewData !== null) {
            setCompanyName(viewData.company_name);
            setCompanyAddress(viewData.company_address);
            setDescription(viewData.company_bio);
            setPhoneNumber(viewData.contact_number)
            setEmail(viewData.contact_email)
            if (viewData.service_requests && viewData.service_requests.length !== 0) {
                viewData.service_requests.map((data) => {
                    const obj = {
                        id: data.request_type_id,
                        name: data.request_type
                    }
                    setSelectedData((state) => ([...state, obj]))
                })
            }
            setImageData({ file: viewData.image_url })
            setTimeout(() => {
                document.querySelector(".image-outer-wrapper").style.background = (`url(${viewData.image_url}) no-repeat center center`);
            }, 100)
        }
    }, [viewData])
    useEffect(() => {
        if (clickedData !== null) {
            getAPI(`/admin/cms/partners/view/${clickedData.id}`).then(resData => {
                if (resData.status === 200) {
                    log.info(resData)
                    const resDataView = resData.data.data;
                    setViewData(resDataView)
                }
            })
        }
    }, [clickedData])
    let status = '';
    if (viewData) {
        if (viewData.status_info.status_name === 'Publish') {
            status = 'published'
        } else if (viewData.status_info.status_name === 'Unpublish') {
            status = 'unpublished'
        } else if (viewData.status_info.status_name === 'Draft') {
            status = 'draft'
        }
    }
    const OnChangeInput = (event) => {
        const change = phoneNumberSanitizer(event.target.value);
        if (isNaN(Number(change))) {
            setPhoneError(true)
        } else {
            setPhoneError(false)
        }
        setPhoneNumber(change)
    }
    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }
        setShowImageLoading(true)

        file.map((dataFile) => {
            let formdata = new FormData();
            formdata.append('image', dataFile);
            let url = IMAGE_API_URL;
            Axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImageData({ percent: percent });
                        return file;
                    } else {
                        setImageData({ percent: percent });
                        return null;
                    }
                }
            })
                .then(response => {
                    setImageData({ file: response.data.data.url })
                    setTimeout(() => {
                        document.querySelector(".image-outer-wrapper").style.background = (`url(${response.data.data.url}) no-repeat center center`);
                    }, 100)
                    setErrorClass(false)
                    setShowImageLoading(true)

                })
                .catch(error => {
                    setShowImageLoading(true)

                });
            return dataFile;
        })
    }
    const removeImage = (index, setFile) => {
        setImageData({ file: '', percent: 0 })
    }
    const [tagDropdown, setTagDropdown] = useState(false);
    const showTagDropdown = () => {
        setTagDropdown(!tagDropdown);
    }
    const onValueChange = (e, data) => {
        const isChecked = e.target.checked;
        setServiceError(false)
        isChecked === true ?
            setSelectedData((state) => ([...state, data]))
            :
            setSelectedData((state) => {
                return state.filter(filterData => filterData.id !== data.id)
            })
    }
    useEffect(() => {
        const slug = props.match.params.slug;
        setComponentLoad(slug);
        getAPI(`/maintenance-request/types`).then(response => {
            if (response.status === 200) {
                log.info(response.data.data)
                setServicesList(response.data.data)
            }
        })
    }, [props]);
    log.info(componentLoad)
    const clickPreview = () => {
        props.history.push(`/preview-partner/${viewData.id}`)
    }

    const modules = {
		toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link'],
            ['image'],
            ['clean']
	    ]
	};
    const formats = [
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'indent',
        'align',
        'link',
        'image',
    ];
    const rteChange = (content, delta, source, editor) => {
        log.info("onChange function content :", content); // rich text
		log.info("onChange function html    :", editor.getHTML()); // rich text
        setDescription(editor.getHTML());
	}
    
    return (
        <div className="createEditBlog">
            <AppLayout noSidebar={true}>
                <div className='create-faq'>
                    <PageTitleHeader
                        isArrow={true}
                        onClick={() => back()}
                        title={componentLoad === 'create' ? 'Creating new partner content' : 'partner content'}
                        status={status}
                        extraElm={
                            clickedData !== null ? <div className='header-right'>
                                <div className='ml-3 cursorPointer' onClick={() => clickPreview()}><img src={require('../../../../../_assets/images/green-eye-view-icon.svg')} alt="" /> Preview</div>
                                <Button onClick={() => openConfirmationModal('delete')} className='btn-cancel delete ml-3' type='button' >delete</Button>
                                <Button className='btn-cancel ml-3' type='button' onClick={() => openConfirmationModal('statusChange')}>{viewData && (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? 'publish' : 'unpublish'}</Button>
                            </div> : ''
                        }
                    />
                    <div className="bannerImage" data-img-url={imageData.file}>
                        <FileBrowse
                            handleDrop={(e) => handleDrop(e, setImageData)}
                            size={'sm'}
                            title={'Drag or Browse to upload Header Image'}
                            percent={imageData.percent}
                            multiple={false}
                            fileType={'.jpg,.png,.jpeg'}
                            disable={false}
                            showImageLoading={showImageLoading}
                            imageData={imageData.file && imageData.file}
                            removeImage={(e) => removeImage(e, setImageData)}
                            lableClass={'custom-lable'}
                            errorClass={errorClass === true ? "error-upload-box" : ""}
                        />
                    </div>
                    <div className="container">
                    <p>Recommended dimensions: 430*221px</p>
                        <Form id='Form'>
                            <Row>
                                <Form.Group as={Col} md='6'>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className='title-inside'>Company name:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type='text'
                                            name={'company_name'}
                                            id={'company_name'}
                                            value={company_name}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className='title-inside'>Company address:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type='text'
                                            name={'company_address'}
                                            id={'company_address'}
                                            value={company_address}
                                            onChange={(e) => setCompanyAddress(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md='6'>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className='title-inside'>Contact Number:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat
                                            format='###-###-####'
                                            mask='_'
                                            name='contact_number'
                                            id='contact_number'
                                            className={phoneError === true ? 'form-control is-invalid' : 'form-control'}
                                            value={contact_number ? contact_number : ''}
                                            onChange={(e) => OnChangeInput(e)}
                                        />
                                        {phoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md='6'>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className='title-inside'>Contact email:</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type='text'
                                            name='email_partner'
                                            id='email_partner'
                                            value={email_partner}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>

                            </Row>
                            <Row className='mb-4'>
                                <Col md={6}>
                                    <div className='d-flex justify-content-between extra-class-dropdown'>
                                        <span>Serviceable Requests:&nbsp;
                                {selectedData.map(function (obj) {
                                            return obj.name;
                                        }).join(", ")}
                                        </span>
                                        <DropDown close={() => setTagDropdown(false)} open={tagDropdown} position={"right"} enableTopArrow={false} style={{ width: "100%" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {servicesList && servicesList.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                                        name={data.id}
                                                                        checked={selectedData.find(elm => elm.id === data.id)} onChange={(e) => onValueChange(e, data)}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.id}>{data.name}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <div onClick={showTagDropdown}><ImageComponent /></div>
                                        </DropDown>
                                    </div>
                                    {serviceError === true && <p style={{ color: 'red' }}>services required</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md='12'>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        value={partner_desc || ''}
                                        onChange={rteChange}
                                    />
                                    {/* <Form.Control
                                        as="textarea"
                                        placeholder="Company Bio"
                                        rows={10}
                                        name={'partner_desc'}
                                        id={'partner_desc'}
                                        value={partner_desc}
                                        onChange={(e) => setDescription(e.target.value)}
                                    /> */}
                                </Form.Group>
                            </Row>
                            <Row className='buttons-container'>
                                <Col md='12' className='d-flex justify-content-between'>
                                    {clickedData !== null ? <div></div> :
                                        <Button className='btn-cancel' type='button' onClick={() => togggleDraftModal()} disabled={saveLoading === true ? true : false}>cancel</Button>
                                    }
                                    <div className='d-flex justify-content-between'>
                                        <Button className='btn-save-draft' type='submit' onClick={(e) => onSubmit(e, '0')} disabled={saveLoading === true ? true : false}>save as draft {(submitStatus === '0' && saveLoading === true) && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                        <Button className='btn-publish' type='submit' onClick={(e) => onSubmit(e, '1')} disabled={saveLoading === true ? true : false}>publish {(submitStatus === '1' && saveLoading === true) && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <Footer />
            </AppLayout>
            <ModalBox
                show={draftModal}
                onHide={() => togggleDraftModal(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => togggleDraftModal(false)}
            >
                <h4>{modalContent === 'draft' ? 'content saved' : 'Content is published'}</h4>
            </ModalBox>
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={action === 'statusChange' ? 'Not now' : 'Cancel'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    {action === 'delete' && 'are you sure you want to delete this partner?'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 0 ?
                                'Are you sure you want to publish content? ' :
                                'Are you sure you want to unpublish content?'}
                        </>
                    }
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => toggleConfirm(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => toggleConfirm(false)}
            >
                <h4>
                    {action === 'delete' && 'Partner has been deleted.'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 0 ?
                                'Content published' :
                                'Content unpublish'}
                        </>
                    }
                </h4>
            </ModalBox>
        </div>
    )

}

export default withRouter(CreatePartners);