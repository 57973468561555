import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from "react-router-dom";
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import { phoneNumberSanitizer } from '../../Utils/NumberSanitizer/NumberSanitizer';

import { dateSanitizer } from "../../Utils/DateSanitizer/DateSanitizer";
import { ModalBox, DatePickerComponent } from '../../App/components';

import { validate } from '../../Utils';
import { postAPI } from '../../Api';
import { ForPandaPartnersSchema } from './ForPandaPartnersSchema';
import Layout from '../components/Layout';
import { Helmet } from "react-helmet";

import './style.scss';

const ForPandaPartners = () => {

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
	const [formSubmitPop, setFormSubmitPop] = useState(false);


    const handleDateChange = (date, setStart_date) => {
        const dateValid = dateSanitizer(date);
        setStart_date(dateValid);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validate(ForPandaPartnersSchema)) {
			const url = "/cms/save-panda-partner";
            const data = {
                "first_name": first_name,
                "last_name": last_name,
                "phone_number": phone_number,
                "contact_date": date,
                "contact_time": time,
                "panda_partner_brand": company_name,
            }
            setSaveLoading(true);
            postAPI(url, data).then(response => {
                setSaveLoading(false);
                setFormSubmitPop(true);
                setDate('');
                setTime('');
                setPhoneNumber('');
                setFirstName('');
                setLastName('');
                setCompanyName('');
            })
        }
    }

    return (
        <div className="forPandaPartnersPage">
            <Helmet>
                <title>For Partners | Rent Panda</title>
                <meta name="description" content="Pandas are picky. But once they find their partner, it's for life. 
                Let's chat about your business and how we can partner up." />
            </Helmet>
            <Layout newHeader={true}>
                <section className="sec-panda-partners-form cu-container-wrap">
                    <div className="container">                    
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h2 className="sec-panda-partners-title text-sm-left">rent panda gets your brand in front of tenants and landlords.</h2>
                                <p className="sec-panda-partners-disc">When tenants are moving to a new home or neighbourhood they spend big. They buy new, upgrade services, change providers and explore new options from their local business community. Get in front of the thousands of tenants who use rent panda every day as they search for a new place to live.</p>
                                <p className="sec-panda-partners-disc">Landlords are constantly spending as they care for multiple homes that are regularly in need maintenance and repair. They are always looking for their next investment and need help finding and paying for that upcoming real estate deal. Be the go-to resources for our landlord community and make their lives easier.</p>
                                <p className="sec-panda-partners-disc">Get in touch today to learn more about all the advertising opportunities we’ve got ready for you.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="become-panda-area">
                                <Image className="become-panda-area-img" src={require("../../_assets/images/banner/become-panda-area-bg.png")} alt="rent icon" />
                                <div className="card">
                                    <div className="card-body">
                                    <h2 className="subHead text-sm-left">become a panda partner today</h2>
                                    <Form id="Form" autoComplete="off">
                                        <Row className="mb-3">
                                            <Col md={12}>                                                
                                                <Form.Label className='custom-lable'>what date can we get in touch with you?</Form.Label>
                                            </Col>                                            
                                            <Col md={6}>
                                                <DatePickerComponent
                                                    id="date"
                                                    name="date"
                                                    format={"dd/MM/yyyy"}
                                                    value={date && date}
                                                    handleDateChange={(date) => handleDateChange(date, setDate)}
                                                    minDate={new Date()}
                                                    displayText={"Select Date"}
                                                    popperPlacement="bottom-start"
                                                    openToDate={new Date()}
                                                    highlightDates={[new Date()]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className="col-md-12">
                                                <Form.Label className='custom-lable'>when would be best to chat?</Form.Label>
                                            </div>                                                
                                            <div className="col-md-6">
                                                <div className="ddlParent">
                                                    <select
                                                        name="time"
                                                        id="time"
                                                        value={time}
                                                        onChange={(e) => setTime(e.target.value)}
                                                        className="selectdropdown"
                                                    >
                                                        <option value="">Select</option>

                                                        <option value="Daytime">Daytime</option>

                                                        <option value="Morning">Morning</option>
                                                        <option value="Afternoon">Afternoon</option>
                                                        <option value="Evening">Evening</option>
                                                    </select>
                                                </div>                                                
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                 <Form.Label className='custom-lable'>what number is best to reach you?</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <NumberFormat
                                                    placeholder="phone number"
                                                    format='###-###-####'
                                                    mask='_'
                                                    name='phone_number'
                                                    id='phone_number'
                                                    className={'form-control'}
                                                    value={phone_number ? phone_number : ''}
                                                    onChange={(e) => setPhoneNumber(phoneNumberSanitizer(e.target.value))}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Label className='custom-lable'>what is your name?</Form.Label>
                                            </Col>
                                            <Col sm={6} className="mb-3">
                                                <Form.Control
                                                    type='text'
                                                    placeholder="first name"
                                                    name={'first_name'}
                                                    id={'first_name'}
                                                    value={first_name}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </Col>
                                            <Col sm={6} className="mb-3">
                                                <Form.Control
                                                    type='text'
                                                    placeholder="last name"
                                                    name={'last_name'}
                                                    id={'last_name'}
                                                    value={last_name}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label className='custom-lable'>What brand are you looking to advertise?</Form.Label>
                                            </Col>
                                            <Col md={12}>                                                
                                                <Form.Control
                                                    type='text'
                                                    placeholder="company name"
                                                    name={'company_name'}
                                                    id={'company_name'}
                                                    value={company_name}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center">
                                        <Button className='btnFormAction' type='submit'
                                            onClick={(e) => onSubmit(e)} disabled={saveLoading === true ? true : false}>
                                                let’s chat
                                            {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
                                        </Button>
                                        </div>

                                    </Form>
                                    <ModalBox
                                        show={formSubmitPop}
                                        onHide={() => setFormSubmitPop(false)}
                                        size='sm'
                                        actionbuttontitle={'got it'}
                                        buttonaction={() => setFormSubmitPop(false)}
                                    >
                                        <h4>Your request has been submitted successfully!</h4>
                                    </ModalBox>
                                    </div>
                                </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </section>


                <section className="sec-become-panda cu-container-wrap">
                    <div className="container">
                        <h2 className="sub-title">just a few more reasons to become a panda partner.</h2>
                        <div className="row">
                            <div className="mb-4 col-lg-6">
                                <div className="image-list-box">
                                    <div className="image-list-box-image">
                                        <Image src={require("../../_assets/images/icons/panda-partners-1.svg")} alt="rent icon" />
                                    </div>
                                    <div className="image-list-box-content">
                                        <h5 className="title">post unlimited rentals</h5>
                                        <div className="discription">we don't limit the amount of listings you can post, meaning all your properties can be listed at once</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 col-lg-6">
                                <div className="image-list-box">
                                    <div className="image-list-box-image">
                                        <Image src={require("../../_assets/images/icons/panda-partners-2.svg")} alt="rent icon" />
                                    </div>
                                    <div className="image-list-box-content">
                                        <h5 className="title">reach landlords in need of your services</h5>
                                        <div className="discription">Our landlords are constantly searching for the right providers who can help them increase the value of their investment and protect their property.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 col-lg-6">
                                <div className="image-list-box">
                                    <div className="image-list-box-image">
                                        <Image src={require("../../_assets/images/icons/panda-partners-3.svg")} alt="rent icon" />
                                    </div>
                                    <div className="image-list-box-content">
                                        <h5 className="title">Over 15,000 users a month</h5>
                                        <div className="discription">Rent Panda draws over 15,000 users a month to our pages, meaning you get bang for your buck when you become a partner.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 col-lg-6">
                                <div className="image-list-box">
                                    <div className="image-list-box-image">
                                        <Image src={require("../../_assets/images/icons/panda-partners-4.svg")} alt="rent icon" />
                                    </div>
                                    <div className="image-list-box-content">
                                        <h5 className="title">leverage the trust users have in the panda</h5>
                                        <div className="discription">We don’t just partner with any brand who is willing to pay, so becoming a partner means our users trust your service like they trust the panda brand.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            
        </div>
    )
}

export default withRouter(ForPandaPartners);
