import "./DOMSChartStyles.scss"
import "../ChartsStyle.scss"
import "../../ReportSections/ReportSection.scss"
import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const DOMSChart = ({data, chartData, name}) => {
  const [labels, setLabels] = useState([])
  const [dataSet, setDataSet] = useState([])
  const [dataDiff, setDataDiff] = useState([])
  const [chartLabelHeaders, setChartLabelHeaders] = useState([])


  useEffect(() => {
    const headers = Object.keys(chartData[0])
    const dataLength = chartData.length
    let labels = []
    let avgDOMS = []
    let diffPast = []

    for (let i = 0; i < dataLength ; i++) {
      // NOTE NEED BETTER NAME FOR THIS, as "change from oct" obviously won't be the same.
      labels.push(chartData[i][headers[0]])
      avgDOMS.push(chartData[i][headers[1]])
      diffPast.push(chartData[i][headers[2]])
    }
    setLabels(labels)
    setDataSet(avgDOMS)
    setDataDiff(diffPast)
    setChartLabelHeaders(headers)
  }, [])


  const roundUp = () => {
    // This is to match how the first chart was formatted
    const highest = Math.max(...dataSet)
    return Math.ceil(highest / 10) * 10 + 10
  }

  const getLowest = () => {
    if  (dataDiff.length > 0) {
      const filtered = dataDiff.filter((item) => {
        let myInt = parseInt(item)
        if (myInt) {
          return myInt
        }
      })
      const lowest = Math.min(...filtered)
      return Math.floor(lowest / 5) * 5
    }
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: 90,
          minRotation: 90
        },
      },
      y: {
        stacked: true,
        max: roundUp(),
        min: getLowest(),
        ticks: {
          stepSize: 5,
          autoSkip: false
        }
      }
    }
  }

  const chartDataActual = {
    labels: labels,
    datasets: [{
      label: "Average DOMS",
      data: dataSet,
      backgroundColor: [
        '#93C47D',
      ],
      borderColor: [
        "#000"
      ],
      borderWidth: 1
    },
      {
        label: chartLabelHeaders[2],
        data: dataDiff,
        backgroundColor: [
          '#FCE5CD',
        ],
        borderColor: [
          "#bbbbbb"
        ],
        borderWidth: 1
      },
    ]
  }

  return (
      <div className={"reportSection"} id={name}>
        <h3 className={"chapterHeader"}>{data.title}</h3>
        <div className={"sectionContent"}>
          {/*<p>{data.data.textContent}</p>*/}
          <div className="domsTableCont">
            <Bar options={options} data={chartDataActual} id={"domsTableAlpha"} />
          </div>

          <p className={"appendixText"}>{data.data.appendixText}</p>
        </div>
      </div>
  )
}
