import React from "react";
import { Row, Col } from "react-bootstrap";

const TenantRoomatesBlock = (props) => {
	const { leaseData, title } = props;
	return (
		<div className={title ? 'inner-wrapper' : 'card-detail'}>
			<h4 className='user-name text-left'>{title ? title : "TENANT'S ROOMMATES"}</h4>
			{(leaseData && leaseData.LeaseTenants) &&
				(leaseData.LeaseTenants.length !== 0 ?
					<>
						{leaseData.LeaseTenants.map((tenant, i) => {
							return tenant.is_primary !== 1 && <Row className='border-bottom mt-2 mb-2 ml-1 mr-1' key={'tenant' + i}>
								<Col md={5} className='p-0'>
									<p className='normal-text'>{tenant.name}</p>
								</Col>
								<Col md={7} className='p-0 '>
									<p className='blue-text text-right'>{tenant.email}</p>
								</Col>
							</Row>
						})}
					</>
					: <h4 className='text-left'>There is no tenant added</h4>)
			}
			{(leaseData && leaseData.lease_tenants) &&
				(leaseData.lease_tenants.length !== 0 ?
					<>
						{leaseData.lease_tenants.map((tenant, i) => {
							return tenant.is_primary !== 1 && <Row className='border-bottom mt-2 mb-2 ml-1 mr-1' key={'tenant' + i}>
								<Col md={7} className='p-0'>
									<p className='normal-text'>{tenant.name}</p>
								</Col>
								<Col md={5} className='p-0'>
									<p className='blue-text'>{tenant.email}</p>
								</Col>
							</Row>
						})}
					</>
					: <h4 className='text-left'>There is no tenant added</h4>)
			}
		</div>
	)

}
export default TenantRoomatesBlock;