import "react-lazy-load-image-component/src/effects/blur.css";
import React from "react";
import { Container, Row, Col, Nav, Image, Button } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "../../App/components";
import "./style.scss";
const placeHolderImg = require("../../_assets/images/placeholder.jpg");

const FormsCardList = (props) => {
  const {
    formsList,
    showLoader,
    numberOfCards,
    setPdfModal,
    setPdfUrl
  } = props;

  const handleFormClick = (form_url) => {
    setPdfUrl(form_url);
    setPdfModal(true);
  }

  const formsCardBox = () => {
    return formsList.length > 0 ? (
      formsList.map((form, i) => {
        return (
          <Col key={"article" + i} className="grid mb-4 col-12 col-md-4">
            <div className="resources-box">
              <div className="resources-box-image">
                <LazyLoadImage
                  effect="blur"
                  className="grid-img"
                  src={form.thumbnail_url ? form.thumbnail_url : placeHolderImg}
                  alt="grid-img"
                />
              </div>
              <h4 className="grid-name">{form.title}</h4>
              <p class="grid-content">
                {form.description.replace(/<[^>]*>?/gm, "")}
              </p>

              <div className="mt-auto">
                {form.form_url && (
                  <Button
                    variant="link"
                    className="date-text"
                    onClick={() => handleFormClick(form.form_url)}
                  >
                    Read more
                    <Image
                      className="pl-2 next"
                      src={require("../../_assets/images/arrow-forward.svg")}
                      alt="View More"
                    />
                    {showLoader && (
                      <i className="fa fa-spinner fa-spin ml-2"></i>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        );
      })
    ) : showLoader ? (
      <Loader />
    ) : (
      <h2>No Data Found</h2>
    );
  };

  return (
    <section>
      <Container>
        <Row>
          {formsList && formsList.length >= numberOfCards && (
            <Col className="text-right viewMore col-auto">
              <Nav.Link className="date-text" href={`/resources/forms`}>
                View More
                <Image
                  className="pl-2 next"
                  src={require("../../_assets/images/arrow-forward.svg")}
                  alt="View More"
                />
              </Nav.Link>
            </Col>
          )}
          {formsList && formsCardBox()}
        </Row>
      </Container>
    </section >
  );
};

export default FormsCardList;
