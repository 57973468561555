import React from 'react'
import { Image, Col, Form } from 'react-bootstrap';
import Loader from "../../../Loader";

const LeasePdf = props => {
    const {pdfData, onDownload, role, is_signOffline, onChangeInput, full_name_sign_Lease, full_name_sign_LeaseUrl} = props;
    return (
        <div className="sidepanel-body lease-pdf-view">
                {pdfData ?
                <>
                        {pdfData?.lease_pdf_url !== 'NOT_FOUND' ? <Col className='confirm-section mt-3 d-flex justify-content-start'>
                            <Image className="icon" src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                            <div className='file-details'>
                                {pdfData?.lease_pdf_url.split('/').pop()}<br />
                                <Image className="icon" src={require("../../../../../_assets/images/download.svg")} alt="Download" />
                                <span className='file-details-link' onClick={() => onDownload()}>Download</span>

                            </div>
                        </Col> : <span style={{color:'red'}}>Lease Not Found !</span>
                        
                    }
                </>
                : <Loader />}
                {role === 2 &&
                    <p className="mt-3 disclaimer">Disclaimer: Please ensure that you and your tenant have signed the lease (digitally or in person) and both of you keep a digital and hard copy of the lease for your records.
                    If you have signed the lease offline, ensure you provide your tenant with a copy of the signed lease within 21 days of signing.</p>}

                {role === 3 &&
                    (is_signOffline ?
                        <p className="mt-2">The landlord has indicated that this lease will be signed in person (offline). This is just your copy for review.</p>
                        : <>
                            <Form.Group className="mt-3">
                                <Form.Label style={{ color: "black" }} className="text-capitalize">Type your full name for digital signature</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="full_name_sign_Lease"
                                    id="full_name_sign_Lease"
                                    value={full_name_sign_Lease}
                                    placeholder="e.g. John Doe"
                                    maxLength="30"
                                    onChange={(e) => onChangeInput(e)}
                                />
                            </Form.Group>
                            <p style={{ color: "#646464" }}>If you have any problems, please contact the landlord before signing</p>

                            {full_name_sign_LeaseUrl &&
                                <Form.Group className="mt-3 signature">
                                    <Form.Label style={{ color: "black" }}>Esignature</Form.Label>
                                    <Image src={full_name_sign_LeaseUrl} alt="Sign Image" />
                                </Form.Group>}
                        </>)}
            </div>
    )
}

export default LeasePdf
