import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Loader } from "../../../App/components";
import "./style.scss";
import { Image } from "react-bootstrap";
import { getAds } from "../../../Utils/Ads";
import { FreeRentAssessment } from "../../../App/Landlord/CreateListing/Steps";
import {
 JombotronSection,
 InformationSection,
 TabsSection,
} from "./components";
import {
 HeaderLandingPage,
 FooterLandingPage,
} from "../../components";
import { Helmet } from "react-helmet";

class Home extends Component {
 constructor(props) {
  super(props);
  this.state = {
   freeRentAssessment: false,
   free_rent_assessment_id: "",
   inquiry_type: "",
   bannerImage: null,
  };
 }

 componentDidMount = async () => {
  const adImages = await getAds();
  if (adImages?.length)
   this.setState({ bannerImage: adImages[0] });
 };

 handleClose = () => {
  this.setState({ freeRentAssessment: false });
 };

 render() {
  return (
   <React.Fragment>
    <Helmet>
     <title>
      Thunder Bay Landlords | Rent Panda
     </title>
     <meta
      name="description"
      content="Rent Panda is a free rental platform for landlords in 
          Ontario with the tools you'll need to find and manage the best tenants."
     />
    </Helmet>
    <div className="landing-page-main">
     <HeaderLandingPage />
     <JombotronSection />
     <TabsSection LandlordHomeComponent={this} />
     <div
      style={{
       marginBottom: 40,
       cursor: "pointer",
       display: "flex",
       justifyContent: "center",
      }}
     >
      {this.state.bannerImage ? (
       <a
        href={this.state.bannerImage?.image_link}
       >
        <Image
         src={this.state.bannerImage?.image_url}
         alt=""
        />
       </a>
      ) : (
       <Loader />
      )}
     </div>
     <InformationSection
      LandlordHomeComponent={this}
     />
     <FooterLandingPage />

     {/* Free Rent Assessment Form */}
     <FreeRentAssessment
      isShow={this.state.freeRentAssessment}
      requestType={this.state.freeRentAssessment}
      handleClose={this.handleClose}
      landlordLanding={true}
      tabsSection={true}
      inquiryType={this.state.inquiry_type}
      setFree_rent_assessment_id={(data) =>
       this.setState({
        free_rent_assessment_id: data,
       })
      }
     />
    </div>
   </React.Fragment>
  );
 }
}

export default withRouter(Home);
