import React from "react";
import "./style.scss";
import { Image } from "react-bootstrap";

const SidePanel = (props) => {
  const { title, subTitle, children, isShow, isHide, extraClasses, headerClass } = props
  return (<>
    <div className={extraClasses ? extraClasses + ' side-panel' : 'side-panel'} style={{ visibility: isShow ? 'visible' : 'hidden', right: isShow ? "0px" : "-450px" }}>
      <header className={headerClass}>
        <h3 className="text-left mr-2">{title}</h3>
        <Image className="close-icon" title="close" onClick={isHide} src={require("../../../_assets/images/close-icon.svg")} alt="close" />
      </header>

      {subTitle && <p className='edit-sub'>{subTitle}</p>}
      {children}
    </div>
    <div className='overlay-layer' onClick={isHide} style={{ display: isShow ? 'block' : 'none' }}></div>
  </>);
}
export default SidePanel;