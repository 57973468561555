import React from 'react';
import "./style.scss"
import Carousel from "../ReviewCarousel/ReviewCarousel";

const LLReviews = () => {
    const review1 = {
        name: "yasin p.",
        location: "Ontario",
        reviewHeadline: "I will be using their service again",
        reviewBody: "Rent Panda took care of everything from showing to screening to Lease signing. I am very happy that I found Rent Panda and I will be using their service again for sure!! Keep up the good work Rent Panda!!",
        rating: 5,
    }
    const review2 = {
        name: "geoff l.",
        location: "Ontario",
        reviewHeadline: "the most useful and professional service",
        reviewBody: "As a landlord I have used Rent Panda since they began operations in Thunder Bay, ON.  They are by far the most useful and professional service I have used over the years.  Most importantly, they produce positive results with the many quality tenant applicants for me to interview.  Rent Panda work to manage a great deal of the risk involved in vetting new tenants. I recommend  their service.",
        rating: 5,
    }
    const review3 = {
        name: "sanika b.",
        location: "Ontario",
        reviewHeadline: "couldn't be happier",
        reviewBody: "I highly recommend rent panda! I was dealing with some people who made the experience being a new landlord more stressful than it should be! Hart helped me through the process, in a very detailed manner.  Very communicative, he did everything he said he would do- in less than a month, my place is rented. Couldn't be happier - thanks Hart!",
        rating: 5,
    }
    const review4 = {
        name: "stephen g.",
        location: "Ontario",
        reviewHeadline: "always connects great tenants with great landlords",
        reviewBody: "Just back to say the same thing again - nothing but good things to say about Rent Panda.  Always connects great tenants with great landlords.  I wouldn't even consider using any other platform - they're vetting the units personally and ensuring that both the tenants and the landlords know their rights and responsibilities.  An amazing resource run by some amazing people.",
        rating: 5,
    }
    const reviews = [review1, review2, review3, review4]

    return (
        <div className={"reviewContainer"}>
            <span className="reviewTitle">
                <span>what landlords are saying about <b className={"greenReviewTitle"}>rent panda</b></span>
            </span>

            <Carousel reviews={reviews}/>
        </div>
    )
}

export default LLReviews;