export const N4Schema = {
    terminationDate: [
        { rule: "required", message: "Date required" },
    ],
    rent: [
        { rule: "required", message: "rent required" },
    ],
    missedPaymentStart_0: [
        { rule: "required", message: "start date required" },
    ], 
    // missedPaymentStart_1: [
    //     { rule: "required", message: "start date required" },
    // ], 
    // missedPaymentStart_2: [
    //     { rule: "required", message: "start date required" },
    // ],
    missedPaymentEnd_0:[
        { rule: "required", message: "end date required" },
    ], 
    // missedPaymentEnd_1:[
    //     { rule: "required", message: "end date required" },
    // ], 
    // missedPaymentEnd_2:[
    //     { rule: "required", message: "end date required" },
    // ], 
    rent_0:[
        { rule: "required", message: "required" },
    ],
    // rent_1:[
    //     { rule: "required", message: "required" },
    // ], 
    // rent_2:[
    //     { rule: "required", message: "required" },
    // ],
    periodRent_0:[
        { rule: "required", message: "required" },
    ],
    // periodRent_1:[
    //     { rule: "required", message: "required" },
    // ], 
    // periodRent_2:[
    //     { rule: "required", message: "required" },
    // ],  
    amount: [
        { rule: "required", message: "amount required" },
    ],
    description: [
        { rule: "required", message: "description required" },
    ],
    firstName: [
        { rule: "required", message: "first name required" },
    ], 
    lastName: [
        { rule: "required", message: "last name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}