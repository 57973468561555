import React, { useRef, useState, useEffect } from "react";
import { AppLayout, PageTitleHeader, FileBrowse, ModalBox, SingleOptionCheckInspection, LargeButton, Loader, } from "../../../components";
import "../style.scss";
import { logger } from "../../../../Utils";
import { withRouter } from "react-router-dom";
import { Col, Form, Row, Button } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { IMAGE_API_URL } from "../../../../Constants/endPoints";
import ReviewMoveInInspection from "./ReviewMoveInInspection";
import ImageGallery from "react-image-gallery";
import { validate } from "../../../../Utils/Validation/validation";
import moment from "moment";
import * as yup from "yup";
import { ValidationSchema } from "../Repairs/ValidationSchema";
import InsertSections from "./InsertSections";
import CustomizedSection from "./CustomizedSection";
import InsertItem from "./InsertItem";
import ProvideSignature from "./ProvideSignature";

const sectionRating = [
  { title: "Good", value: 1 },
  { title: "Issue", value: 2 },
  { title: "Repair", value: 3 },
];

const MoveInInspection = (props) => {
  const role = Number(Cookies.get("role"));
  const [showImageLoading,setShowImageLoading]=useState(false)
  const [notes, setNotes] = useState("");
  const [tenants, setTenants] = useState([]);
  const [preview, setPreview] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [accordianId, setAccordionId] = useState("Exterior");
  const [sections, setSections] = useState([]);
  const [imageUrlData, setImageUrlData] = useState([]);
  const [errorClass, setErrorClass] = useState(false);
  const [showNewSection, setShowNewSection] = useState(false);
  const [showCustomizeSection, setShowCustomizeSection] = useState(false);
  const [showAddItemSection, setShowAddItemSection] = useState(false);
  const [newItemText, setNewItemText] = useState("");
  const [hideBackButton, setHideBackButton] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [sectionName, setSectionName] = useState("");
  const [search, setSearch] = useState("");
  const [tenantlist, setTenantlist] = useState([]);
  const [tenant_id, setTenant_id] = useState([]);
  const [filteredTenant, setFilteredTenant] = useState([]);
  const [addSign, setAddSign] = useState(false);
  const [landlordSign, setLandlordSign] = useState(null);
  const [tenantSign, setTenantSign] = useState(null);
  const [sign, setSign] = useState("landlord");
  const [cancelSuccessModalShow, setCancelSuccessModalShow] = useState(false);
  const [successModalFlag, setSuccessModalFlag] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [completed, setCompleted] = useState("");
  const [error, setError] = useState(false);
  const [imagesGallary, setImagesGallary] = useState(null);
  const [is_showGallery, setShowGallery] = useState(false);
  const [tenantReq, setTenantReq] = useState(false);
  const [landlord, setLandlord] = useState("");
  const address = useSelector((state) => state.maintenanceRepairReducer.address);
  const [landlordAddress, setLandlordAddress] = useState("");
  const currentDate = Date();
  const [createdDate, setCreatedDate] = useState(Date());
  const [showLoader,setShowLoader]=useState(false)
  const [items, setItems] = useState([
    {
      inspection_section_item_id: 0,
      master_inspection_section_id: 2,
      item_name: "",
      is_active: 1,
      item_status: 0,
      notes: "",
      images: [],
    },
  ]);
  const [property_id, setProperty_id] = useState(
    props.match.params.property_id
  );
  const [property_details_id, setProperty_details_id] = useState(
    props.match.params.property_details_id
  );
  const path = props.history.location.pathname.split("/")[1];
  const inspectionId = props.history.location.pathname.split("/")[2];

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSelectDropdown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);


  useEffect(() => {
    if (path === "move-in-inspection") {
      getDefaultSections();
      getTenants();
    } else if (path === "move-out-inspection") {
      getTenants();
    } else if (
      path === "view-move-in-inspection" ||
      path === "view-move-out-inspection"
    ) {
      if (role === 2) {
        getInspectionForLandlord();
      } else if (role === 3) {
        getInspectionForTenant();
      }
    }
  }, []);

  const getDefaultSections = () => {
    const url = "/landlord/inspections/get-default-sections";
    getAPI(url).then((res) => {
      setSections(res.data.data);
    });
  };

  const getInspectionForLandlord = () => {
    const url = `/landlord/inspections/view/${inspectionId}`;
    setShowLoader(true)
    getAPI(url).then((res) => {
      setSections(res.data.data.sections);
      setCompleted(res.data.data.status_info.status_name);
      setProperty_id(res.data.data.property_id);
      setProperty_details_id(res.data.data.property_detail_id);
      setNotes(res.data.data.comment);
      setLandlordAddress(res.data.data.property.address);
      setCreatedDate(res.data.data.created_at);
      let tenants = res.data.data.property_tenants;
      const tempId = [];
      const tempName = [];
      for (var tenant in tenants) {
        var obj = tenants[tenant];
        Object.assign(obj, {
          checked: true,
        });

        tempId.push(tenants[tenant].id);
        tempName.push(tenants[tenant].name);
      }
      setTenant_id(tempId);
      setTenants(tempName);
      setTenantlist(tenants);
      setShowLoader(false)

    }).catch(err=>{
      setShowLoader(false)
    });
  };

  const getInspectionForTenant = () => {
    const url = `/tenant/inspections/view/${inspectionId}`;
    setShowLoader(true)
    getAPI(url).then((res) => {
      setSections(res.data.data.sections);
      setCompleted(res.data.data.status_info.status_name);
      setProperty_id(res.data.data.property_id);
      setProperty_details_id(res.data.data.property_detail_id);
      setNotes(res.data.data.comment);
      setLandlordAddress(res.data.data.property.address);
      setCreatedDate(res.data.data.created_at);
      setLandlord(res.data.data.landlord.name);
      setShowLoader(false)
    }).catch((err) => {
      setShowLoader(false)
    })
  };

  const removeImage = (index, section_id, item_id) => {
    const tempArray = [...sections];
    tempArray[section_id].items[item_id].images.splice(index, 1);
    setSections(tempArray);
  };

  const id = useSelector((state) => state.auth.id);

  const toggleSelect = (type) => {
    setSelectDropdown(type);
  };

  const back = () => {
    if (!preview) {
      props.history.goBack();
    }
    if (
      path === "move-in-inspection" ||
      path === "view-move-in-inspection" ||
      path === "move-out-inspection" ||
      path === "view-move-out-inspection"
    ) {
      setPreview(false);
    } else if (path === "view-repair-request") {
      props.history.goBack();
    }
  };

  const OnChangeInput = (event) => {
    if (event.target.name === "notes") {
      setNotes(event.target.value);
    }
  };
  const OnSingleOptionCheck = (event, section_id, item_id) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    // if (name === "promotionPlan") {
    //   setPlan(Value);
    // }
    const tempArray = [...sections];
    tempArray[section_id].items[item_id].item_status = Value;
    setSections(tempArray);
  };

  const clickExterior = (name) => {
    const check = name === accordianId ? null : name;
    setAccordionId(check);
  };

  const handleDrop = (file, section_id, item_id) => {
    const url = IMAGE_API_URL;
    const token = `Bearer ${Cookies.get("access_token")}`;
    const headers = {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    };
    setShowImageLoading(true)
    file.map((dataFile) => {
      let formdata = new FormData();
      formdata.append("image", dataFile);

      axios
        .post(url, formdata, {
          headers: headers,
        })
        .then(function (response) {
          setImageUrlData((imageUrlData) => [
            ...imageUrlData,
            response.data.data.url,
          ]);

          let tempImageArray = [...sections[section_id].items[item_id].images];
          tempImageArray.push(response.data.data.url);
          const tempArray = [...sections];
          tempArray[section_id].items[item_id].images = tempImageArray;
          setSections(tempArray);
          setErrorClass(false);
          setShowImageLoading(false)

        })
        .catch(function (error) {
          setShowImageLoading(false)

         });
      return dataFile;
    });
  };

  const previewHandler = () => {
    const top = document.getElementsByClassName('profile-header')[0]
    top.scrollIntoView({behavior: "smooth"})
    if (validate(ValidationSchema) && tenant_id.length !== 0) {
      // const tempArray = [...sections];
      // tempArray.forEach((section) => {
      //   delete section.requiredFieldError;
      // });
      // const checkFalseValue = [];
      // for (let section in sections) {
      //   for (let item in sections[section].items) {
      //     if (sections[section].items[item].item_status === 0) {
      //       var obj = tempArray[section];
      //       Object.assign(obj, {
      //         requiredFieldError: `You have fields not filled yet, please finish filling the information`,
      //       });
      //     }
      //     const arrayOfObject = sections[section].items;
      //     const checkUsername = (obj) => obj.item_status === 0;
      //     checkFalseValue.push(arrayOfObject.some(checkUsername));
      //   }
      // }
      // const checkFalse = (value) => {
      //   return value === true;
      // };
      // if (checkFalseValue.some(checkFalse) === true) {
      //   setPreview(false);
      // } else {
      //   setPreview(true);
      // }
      setPreview(true);
      // setSections(tempArray);
    }
    if (tenant_id.length === 0) {
      setTenantReq(true);
    }
  };

  const deleteSection = (id) => {
    const tempArray = [...sections];
    tempArray.splice(
      tempArray.findIndex((a) => a.inspection_section_id === id),
      1
    );
    setSections(tempArray);
  };

  const deleteSectionItem = (sectionId, itemId) => {
    const tempArray = [...sections];
    const sectionIndex = tempArray.findIndex(
      (a) => a.inspection_section_id === sectionId
    );
    if (sectionIndex !== -1) {
      tempArray[sectionIndex].items.splice(
        tempArray[sectionIndex].items.findIndex(
          (b) => b.inspection_section_item_id === itemId
        ),
        1
      );
    }
    setSections(tempArray);
  };

  const addSection = () => {
    setShowNewSection(true);
  };

  const handleClose = (panel) => {
    if (panel === "customizeSection") {
      setShowCustomizeSection(false);
    } else if (panel === "newSection") {
      setShowNewSection(false);
    } else if (panel === "addSectionItem") {
      setShowAddItemSection(false);
    } else if (panel === "addSign") {
      setAddSign(false);
    }
  };

  const openCustomizePanel = () => {
    setShowCustomizeSection(true);
  };

  const openAddItemSection = (name) => {
    setShowAddItemSection(true);
    setNewItemText(name);
  };

  const addNewSection = () => {
    const tempArray = [...sections];
    const obj = {
      inspection_section_id: sections.length + 1,
      is_active: 1,
      section_name: sectionName,
      items: [],
    };
    setSections((tempArray) => [...tempArray, obj]);
    setSectionName("");
    handleClose("customizeSection");
    handleClose("newSection");
  };

  const handleChange = (event, index) => {
    let values = [...items];
    let value = { ...items[index] };
    const name = event.target.name
      ? event.target.name
      : event.target.getAttribute("name");
    value[name] = event.target.value;
    values[index] = value;
    setItems(values);
  };

  const handleAdd = () => {
    const obj = {
      inspection_section_item_id: items.length,
      master_inspection_section_id: null,
      item_name: "",
      is_active: 1,
      item_status: 0,
      notes: "",
      images: [],
    };
    setItems((items) => [...items, obj]);
  };

  const handleRemove = (i) => {
    const values = [...items];
    values.splice(
      values.findIndex((a) => a.inspection_section_item_id === i),
      1
    );
    setItems(values);
  };

  const addItemsToSection = () => {
    const sectionIndex = sections.findIndex(
      (a) => a.inspection_section_id === sectionId
    );
    for (let item in items) {
      items[item].inspection_section_item_id =
        sections[sectionIndex].items.length + 1;
      sections[sectionIndex].items.push(items[item]);
    }
    handleClose("addSectionItem");
    setItems([
      {
        inspection_section_item_id: 0,
        master_inspection_section_id: 2,
        item_name: "",
        is_active: 1,
        item_status: 0,
        notes: "",
        images: [],
      },
    ]);
  };

  const handleChangeNotes = (event, section_id, item_id) => {
    const tempArray = [...sections];
    tempArray[section_id].items[item_id].notes = event.target.value;
    setSections(tempArray);
  };

  const handleSubmit = (saveType) => {
    // const top = document.getElementsByClassName('profile-header')[0]
    // top.scrollIntoView({behavior: "smooth"})
    if (tenant_id.length === 0) {
      setTenantReq(true);
    }
    if (validate(ValidationSchema) && tenant_id.length !== 0) {
      let inspectionType;
      let url;
      let data = {
        property_id: property_id,
        property_detail_id: property_details_id,
        tenant_id: tenant_id,
        type: inspectionType,
        comment: notes,
        landlord_sign: landlordSign,
        tenant_sign: tenantSign,
        sections: sections,
      };
      if (path === "move-in-inspection") {
        url = "/landlord/inspections/create";
        inspectionType = 1;
        Object.assign(data, { type: inspectionType });
      } else if (path === "move-out-inspection") {
        url = "/landlord/inspections/create";
        inspectionType = 2;
        Object.assign(data, { type: inspectionType });
      } else if (path === "view-move-in-inspection") {
        url = "/landlord/inspections/update";
        inspectionType = 1;
        Object.assign(data, { id: inspectionId }, { type: inspectionType });
      } else if (path === "view-move-out-inspection") {
        inspectionType = 2;
        url = "/landlord/inspections/update";
        Object.assign(data, { id: inspectionId }, { type: inspectionType });
      }

      setError(false);
      const tempArray = [...sections];
      tempArray.forEach((section) => {
        delete section.requiredFieldError;
      });
      setSections(tempArray);
      setSaveLoading(true);
      postAPI(url, data).then((res) => {
        handleClose("addSign");
        setSaveLoading(false);
        if (res.response && res.response.status === 422) {
          // setError(true);
          // const errorArray = Object.keys(res.response.data.errors);
          // const tempArray = [...sections];
          // errorArray.forEach((errorMsg) => {
          //   const sectionIndex = Number(errorMsg.split(".")[1]);
          //   var obj = tempArray[sectionIndex];
          //   Object.assign(obj, {
          //     requiredFieldError: `You have fields not filled yet, please finish filling the information`,
          //   });
          // });
          // setSections(tempArray);
          setSign("landlord");
        } else if (res.response && res.response.status === 400) {
          setSuccessModalFlag(true);
          setCancelSuccessModalShow(true);
          setSuccessModalText(res.response.data.message);
          setSign("landlord");
        }
        if (res.data && res.data.status_code === 200) {
          if (saveType === "directSave") {
            setSuccessModalFlag(true);
            setSuccessModalText(res.data.message);
          }
          setCancelSuccessModalShow(true);
          setSign("landlord");
        }
      });
    }
  };

  const getTenants = (p, pd) => {
    let url;
    if (
      path === "view-move-in-inspection" ||
      path === "view-move-out-inspection"
    ) {
      url = `/get-property-tenants/${p}/${pd}`;
    } else {
      url = `/get-property-tenants/${property_id}/${property_details_id}`;
    }
    getAPI(url).then((res) => {
      let tenants = res.data.data;
      for (var tenant in tenants) {
        var obj = tenants[tenant];
        Object.assign(obj, {
          checked: false,
        });
      }
      setTenantlist(tenants);
    });
  };

  const tenantHandleChange = (id, display, index, isChecked) => {
    const tenants = [...tenantlist];
    var obj = tenants[index];
    Object.assign(obj, {
      checked: !isChecked,
    });
    setTenantlist(tenants);
    setTenant_id([id]);
    const tempId = [];
    const tempName = [];
    for (var tenant in tenantlist) {
      if (tenantlist[tenant].checked) {
        tempId.push(tenantlist[tenant].tenant_id);
        tempName.push(tenantlist[tenant].name);
      }
    }
    if (tempId.length !== 0) {
      setTenantReq(false);
    }
    setTenant_id(tempId);
    setTenants(tempName);
    if (path === "move-out-inspection") {
      getMoveOutSections(tempId);
    }
  };

  const getMoveOutSections = (tempId) => {
    const url = "/landlord/inspections/get-move-in";
    const data = {
      property_id: property_id,
      property_detail_id: property_details_id,
      tenant_id: tempId,
    };
    postAPI(url, data).then((res) => {
      if (res.data && res.data.status_code === 200) {
        setSections(res.data.data.sections);
      }
    });
  };

  useEffect(() => {
    setFilteredTenant(
      tenantlist.filter((tenantLt) => {
        return tenantLt.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  }, [search, tenantlist]);

  const viewGallery = () => {
    setShowGallery(true);
  };

  const modalImageGallery = (images) => {
    var imageArray = [];
    for (var img in images) {
      imageArray.push(images[img].image);
    }

    setShowGallery(true);
    let imagesGallaryArray = [];
    imageArray &&
      imageArray.map((image, i) => {
        imagesGallaryArray.push({
          original: image,
          thumbnail: image,
        });
      });
    setImagesGallary(imagesGallaryArray);
  };

  const closeSelect = () => {
    if (selectDropdown) {
      toggleSelect(false);
    }
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          title={
            path === "move-in-inspection" || path === "view-move-in-inspection" ? role === 2 && preview ? 'review move in inspection'  : 'move in inspection' :  path === "move-out-inspection" || path === "view-move-out-inspection" ? role === 2 && preview ? 'review move out inspection'  : 'move out inspection' : ''
            // path === "move-in-inspection" || path === "view-move-in-inspection"
            //   ? "move in inspection "
            //   : (path === "move-out-inspection" ||
            //     path === "view-move-out-inspection") &&
            //   "move out inspection"
          }

          isArrow={true}
          onClick={() => back()}
          leftBtn={role === 2 && completed !== "Completed" && "true"}
          leftBtnText={preview ? "Edit" : "Save"}
          leftBtnHandler={() => {
            if (preview) {
              back();
            } else {
              handleSubmit("directSave");
            }
          }}
          saveLoading={saveLoading}
          rightBtn={role === 2 && completed !== "Completed" && "true"}
          rightBtnText={preview ? "Sign" : "Review"}
          rightBtnHandler={() => {
            if (preview) {
              setAddSign(true);
            } else {
              previewHandler();
            }
          }}
        />
      {showLoader ? <Loader/> :
        <div className="request-repair-form">
          <div className="d-flex justify-content-between">
            <span className="address-text">
              {path === "view-move-in-inspection" ||
                path === "view-move-out-inspection"
                ? landlordAddress
                : address}
            </span>
            <span className="date-text">
              Date created:{" "}
              {path === "view-move-in-inspection" ||
                path === "view-move-out-inspection"
                ? moment(createdDate).format("ll")
                : moment(currentDate).format("ll")}
            </span>
          </div>
          {(preview || completed === "Completed") && (
            <>
              <div className="d-flex align-items-start mt-3">
                {role === 2 ? (
                  <div className="d-flex align-items-start flex-column mr-5">
                    <span className="request-title-name">
                      Present Tenant {tenants.length > 1 ? "Names" : "Name"}
                    </span>
                    <div>
                      {tenants.map((tenant, i) => {
                        return (
                          <span
                            key={i}
                            className="request-title-name extra-pad"
                          >
                            {i !== 0 && ", "}
                            {tenant}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                    role === 3 && (
                      <div className="d-flex align-items-start flex-column mr-5">
                        <span className="request-title-name">Landlord Name</span>
                        <div>
                          <span className="request-title-name extra-pad">
                            {landlord}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                <div className="d-flex align-items-start flex-column mr-5">
                  <span className="request-title-name">General Comments</span>
                  <span className="request-title-name">{notes}</span>
                </div>
              </div>
            </>
          )}
          {!preview && completed !== "Completed" && !path.includes('view-move-out-inspection') && (
            <Form id="Form">
              <Form.Group></Form.Group>
              <Row>
                <Form.Group as={Col} md="3" className="request-select">
                  <Form.Label>Select Present Tenant(s)</Form.Label>
                  <div style={{ position: "relative" }} ref={wrapperRef}>
                    <div
                      className="select-tenant"
                      onClick={() => toggleSelect(!selectDropdown)}

                    >
                      <div className="d-flex justify-content-between">
                        <span>
                          {tenants.length === 0 && "Select"}
                          {tenants &&
                            tenants.map((tenant, index) => {
                              return (
                                <>
                                  <span key={index}>
                                    {tenant}
                                  </span> {(tenants.length > 1) && ", "}</>
                              );
                            })}
                        </span>
                        <img
                          src={require("../../../../_assets/images/select-icon.svg")}
                        />
                      </div>
                    </div>
                    {selectDropdown && (
                      <div className="select-dropdown-box"  >
                        <input
                          className="select-search-filter"
                          placeholder="Search Tenant Name"
                          onChange={(event) => setSearch(event.target.value)}
                          value={search}
                          name="search"
                        />
                        {filteredTenant.map((options, i) => {
                          return (
                            <div
                              className="select-checkbox-wrapper d-flex justify-content-start"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="checkit"
                                id={options.tenant_id}
                                name={options.tenant_id}
                                checked={options.checked}
                                onChange={() =>
                                  tenantHandleChange(
                                    options.tenant_id,
                                    options.name,
                                    i,
                                    options.checked
                                  )
                                }
                                hidden
                              />
                              <label
                                for={options.tenant_id}
                                className="checkmark"
                                style={{ cursor: 'pointer' }}
                              ></label>
                              <span className="checkbox-title"
                                onClick={() =>
                                  tenantHandleChange(
                                    options.tenant_id,
                                    options.name,
                                    i,
                                    options.checked
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {options.name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {tenantReq && (
                      <div className="invalid-select">Tenant Required</div>
                    )}
                  </div>
                  {/* ---multiple select with search */}
                </Form.Group>

                <Form.Group as={Col} md="9">
                  <Form.Label>General Comments</Form.Label>
                  <Form.Control
                    type="text"
                    name="notes"
                    id="notes"
                    value={notes}
                    placeholder="Input Notes"
                    onChange={(e) => OnChangeInput(e)}
                  />
                </Form.Group>
              </Row>
            </Form>
          )}
        </div>}
        {(preview || completed === "Completed") && (
          <ReviewMoveInInspection sections={sections} />
        )}
        {!preview && completed !== "Completed" && (
          <div className="inspection">
            <Accordion defaultActiveKey={"Exterior"} className="accordian">
              {sections.map((section, i) => {
                return (
                  <>
                    <div className="accordian-main" key={i}>
                      <div className="accordian-head d-flex justify-content-between">
                        <div className="accordian-text-box">
                          <Accordion.Toggle
                            // as={Button}
                            style={{ background: 'transparent', border: 'none' }}
                            // variant="link"
                            eventKey={section.section_name}
                            onClick={() => clickExterior(section.section_name)}
                          >
                            <span>{section.section_name}</span>
                          </Accordion.Toggle>
                          {section.hasOwnProperty("requiredFieldError") && (
                            <span className="error-text-inspection">
                              <img
                                className="error-icon"
                                src={require("../../../../_assets/images/error-icon.svg")}
                              />
                              {section.requiredFieldError}
                            </span>
                          )}
                        </div>
                        <div>
                          {role === 2 &&
                            (path === "move-in-inspection" ||
                              path === "view-move-in-inspection") && (
                              <img
                                className="cursor-pointer"
                                onClick={() =>
                                  deleteSection(section.inspection_section_id)
                                }
                                src={require("../../../../_assets/images/delete.svg")}
                              />
                            )}
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={section.section_name}
                            onClick={() => clickExterior(section.section_name)}
                          >
                            <img
                              className="next-arrow"
                              src={
                                accordianId === section.section_name
                                  ? require("../../../../_assets/images/nextUp.svg")
                                  : require("../../../../_assets/images/nextDown.svg")
                              }
                              alt="next"
                            />
                          </Accordion.Toggle>
                        </div>
                      </div>
                      <Accordion.Collapse eventKey={section.section_name}>
                        <div className="accordian-body">
                          <Row>
                            {section.items.map((item, index) => {
                              return (
                                <Col md="6" lg="6" xl="4" key={index}>
                                  <div className="item-card">
                                    <div className="item-card-title d-flex justify-content-between align-items-stretch">
                                      <span>{item.item_name}</span>
                                      {role === 2 &&
                                        (path === "move-in-inspection" ||
                                          path ===
                                          "view-move-in-inspection") && (
                                          <img
                                            className="cursor-pointer"
                                            onClick={() =>
                                              deleteSectionItem(
                                                section.inspection_section_id,
                                                item.inspection_section_item_id
                                              )
                                            }
                                            src={require("../../../../_assets/images/delete.svg")}
                                            alt='rent-panda-inspection'
                                          />
                                        )}
                                    </div>
                                    {(path === "view-move-out-inspection" ||
                                      path === "move-out-inspection") && (
                                        <div className="d-flex justify-content-between move-in-status-block">
                                          <div className="d-flex align-items-start flex-column move-in-status-text">
                                            <span>
                                              Move In Status:{" "}
                                              {item.move_in.status}
                                            </span>
                                            <span>
                                              Note:{" "}
                                              {item.move_in.notes === null
                                                ? "N/A"
                                                : item.move_in.notes}
                                            </span>
                                          </div>
                                          <div
                                            className="move-in-status-image"
                                            onClick={() => {
                                              if (
                                                item.move_in.images &&
                                                item.move_in.images.length !== 0
                                              ) {
                                                modalImageGallery(
                                                  item.move_in.images
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              src={
                                                item.move_in.images &&
                                                  item.move_in.images.length === 0
                                                  ? require("../../../../_assets/images/image-icon.svg")
                                                  : require("../../../../_assets/images/active-image-icon.svg")
                                              }
                                            />
                                            {item.move_in.images &&
                                              item.move_in.images.length !==
                                              0 && (
                                                <span className="image-count-inspection">
                                                  {item.move_in.images.length}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      )}
                                    <SingleOptionCheckInspection
                                      mapData={sectionRating}
                                      OnSingleOptionCheck={(data) =>
                                        OnSingleOptionCheck(data, i, index)
                                      }
                                      name={"promotionPlan"}
                                      setParam={item.item_status}
                                    />
                                    <input
                                      className="item-card-input"
                                      placeholder="Note: N/A"
                                      onChange={(e) =>
                                        handleChangeNotes(e, i, index)
                                      }
                                      value={item.notes}
                                      name="search"
                                    />
                                    <FileBrowse
                                      handleDrop={(e) =>
                                        handleDrop(e, i, index)
                                      }
                                      title="&nbsp;&nbsp;&nbsp;Attach Images"
                                      percent={0}
                                      multiple={true}
                                      fileType={".jpg,.png,.jpeg"}
                                      disable={false}
                                      imageData={item.images}
                                      removeImage={(e) =>
                                        removeImage(e, i, index)
                                      }
                                      errorClass={
                                        errorClass === true
                                          ? "error-upload-box"
                                          : ""
                                      }
                                      showImageLoading={showImageLoading}
                                    />
                                  </div>
                                </Col>
                              );
                            })}
                            {(path === "move-in-inspection" ||
                              path === "view-move-in-inspection") && (
                                <Col md="6" lg="6" xl="4">
                                  <div
                                    onClick={() => {
                                      openAddItemSection(section.section_name);
                                      setHideBackButton(true);
                                      setSectionId(section.inspection_section_id);
                                    }}
                                    className="insert-item-box d-flex justify-content-center"
                                  >
                                    <div className="d-flex flex-column">
                                      <img
                                        src={require("../../../../_assets/images/plus.svg")}
                                      />
                                      <span>Insert Item</span>
                                    </div>
                                  </div>
                                </Col>
                              )}
                          </Row>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </>
                );
              })}
            </Accordion>
          </div>
        )}
        {/* show on dekstop only */}
        <div className="d-none d-sm-block">
          {completed !== "Completed" && !showLoader && (
            <div className="d-flex justify-content-between mb-5">
              {role === 2 && !preview && (
                <>
                  {(path === "move-in-inspection" ||
                    path === "view-move-in-inspection") && (
                      <LargeButton
                        onClick={addSection}
                        variant={"dark"}
                        isIcon={true}
                        title={"Add New Section"}
                        style={{
                          width: "30%",
                          height: "48px",
                          marginLeft: "42px",
                          marginTop: "21px",
                        }}
                      />
                    )}
                </>
              )}
              {role === 2 &&
                (path === "move-out-inspection" ||
                  path === "view-move-out-inspection" ||
                  preview) && (
                  <div
                    style={{
                      width: "30%",
                      height: "48px",
                      marginLeft: "42px",
                      marginTop: "21px",
                    }}
                  ></div>
                )}
              {role === 2 && !showLoader && (
                <div className="container-footer button-extra-margin mt-0 mb-0">
                  {!preview && (
                    <Button variant="secondary" onClick={back}>
                      Cancel
                    </Button>
                  )}
                  <Button
                    variant="success"
                    onClick={() => {
                      if (preview) {
                        setAddSign(true);
                      } else {
                        previewHandler();
                      }
                    }}
                  //  onClick={handleSubmit}
                  >
                    {preview ? "Sign" : "Review"}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* end */}

        {/* show on mobile only */}
        <div className="d-block d-sm-none">
          {completed !== "Completed" && (
            <div className="justify-content-between mb-5">
              <div className="row">
                <div className="col-md-12">
                  {role === 2 && !preview && !showLoader && (
                    <>
                      {(path === "move-in-inspection" ||
                        path === "view-move-in-inspection") && (
                          <LargeButton
                            onClick={addSection}
                            variant={"dark"}
                            isIcon={true}
                            title={"Add New Section"}
                            style={{
                              height: "48px",
                              marginLeft: "20px",
                              marginTop: "21px",
                              width: "86%"
                            }}
                          />
                        )}
                    </>
                  )}
                  {role === 2 &&
                    (path === "move-out-inspection" ||
                      path === "view-move-out-inspection" ||
                      preview) && (
                      <div
                        style={{
                          height: "48px",
                          marginLeft: "20px",
                          marginTop: "21px",
                          width: "86%"
                        }}
                      ></div>
                    )}
                </div>
              </div>
              {role === 2   && (
                <div className="container-footer button-extra-margin mt-0 mb-0">
                  {!preview && (
                    <Button variant="secondary" onClick={back}>
                      cancel
                    </Button>
                  )}
                  <Button
                    variant="success"
                    onClick={() => {
                      if (preview) {
                        setAddSign(true);
                      } else {
                        previewHandler();
                      }
                    }}
                  //  onClick={handleSubmit}
                  >
                    {preview ? "Sign" : "Review"}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* end */}
      </AppLayout>

      <InsertSections
        isShow={showNewSection}
        handleClose={() => handleClose("newSection")}
        sections={sections}
        openAddItemSection={(data) => openAddItemSection(data)}
        setSectionId={(data) => setSectionId(data)}
        openCustomizePanel={(data) => openCustomizePanel(data)}
      />
      <CustomizedSection
        isShow={showCustomizeSection}
        handleClose={(data) => handleClose(data)}
        sectionName={sectionName}
        setSectionName={(data) => setSectionName(data)}
        addNewSection={(data) => addNewSection(data)}
      />
      <InsertItem
        isShow={showAddItemSection}
        newItemText={newItemText}
        hideBackButton={hideBackButton}
        handleClose={(data) => handleClose(data)}
        items={items}
        handleRemove={(data) => handleRemove(data)}
        handleChange={(data, idx) => handleChange(data, idx)}
        handleAdd={(data) => handleAdd(data)}
        addItemsToSection={(data) => addItemsToSection(data)}
      />
      <ProvideSignature
        isShow={addSign}
        handleClose={(data) => handleClose(data)}
        sign={sign}
        tenantSign={tenantSign}
        setLandlordSign={(data) => setLandlordSign(data)}
        landlordSign={landlordSign}
        setTenantSign={(data) => setTenantSign(data)}
        setSign={(data) => setSign(data)}
        hideBackButton={(data) => hideBackButton(data)}
        handleSubmit={(data) => handleSubmit(data)}

      />
      <ModalBox
        show={cancelSuccessModalShow}
        onHide={() => {
          setCancelSuccessModalShow(false);
        }}
        size="md"
        actionbuttontitle={"got it"}
        buttonaction={() => {
          setCancelSuccessModalShow(false);
          props.history.push(
            `/maintainance-repair/${property_id}/${property_details_id}/inspections`
          );
        }}
      >
        <div className="inspection-succcess-modal">
          {successModalFlag ? (
            <h5 className="inspection-created-title">{successModalText}</h5>
          ) : (
              <>
                <h5 className="inspection-created-title">
                  {path === "view-move-out-inspection" ||
                    path === "move-out-inspection"
                    ? "move out inspection complete"
                    : "move in inspection complete"}
                </h5>
                <p className="inspection-created-text">
                  the{" "}
                  {path === "view-move-out-inspection" ||
                    path === "move-out-inspection"
                    ? "move-out"
                    : "move-in"}{" "}
                inspection has been saved to this listing for your records.
              </p>
                {(path === "view-move-in-inspection" ||
                  path === "move-in-inspection") && (
                    <p className="inspection-created-text">
                      this should be used as reference when a tenant is moving out,
                      or if you ever get into a situation where the state of the
                      unit upon move in is being questioned.
                    </p>
                  )}
              </>
            )}
        </div>
      </ModalBox>
      <ModalBox
        show={is_showGallery}
        onHide={() => setShowGallery(false)}
        size="lg"
      >
        {imagesGallary && (
          <ImageGallery
            className="inspection-image-gallery"
            items={imagesGallary}
          />
        )}
      </ModalBox>
    </>
  );
};

export default withRouter(MoveInInspection);
