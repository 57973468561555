import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button ,OverlayTrigger,Tooltip} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { numberFormatSanitizerInt, toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import CostNumberFormat from "../../CostNumberFormat";
import DatePickerComponent from "../../DatePickerComponent";
// import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import exclaim from '../../../../_assets/images/exclaim.svg'
import { N4Schema } from "./ValidationSchema/N4Schema";
import moment from "moment";
import { transFormSignData } from './helper'
import { useGlobalState } from "../../../../StateContext";
import { useSelector } from "react-redux";


const N4Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack, onNoticeDataUpdate
  } = props;
  
  const { overviewDetails } = useSelector(state => state.maintenanceRepairReducer);
  const [globalState, setGlobalState] = useGlobalState()
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [owesAmount, setOwesAmount] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [missedPayments, setMissedPayments] = useState([
    {
      start_date: null,
      end_date: null,
      charges: parseInt(overviewDetails?.rent),
      paid_for_period: 0,
    },
    {
      start_date: null,
      end_date: null,
      charges: 0,
      paid_for_period: 0,
    },
    {
      start_date: null,
      end_date: null,
      charges: 0,
      paid_for_period: 0,
    },
  ]);
  //Signature Data
  const [signData, setSignData] = useState(null);

  useEffect(() => {
    setGlobalState({ onSubmit: onSubmit })
    return () => {
      setGlobalState({ onSubmit: null })
    }
  }, [])

  useEffect(() => {
    onNoticeDataUpdate({
      tenant_move_out: terminationDate,
      total_amount_tenant_owes: owesAmount,
      deadline_of_payment: terminationDate,
      missed_payment_information: missedPayments,
      signData,
      notice_type:4
    })
    setGlobalState({ onSubmit: onSubmit })
  }, [terminationDate, owesAmount, phoneError, missedPayments, signData, tenant_id.length, property_id])


  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})
    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N4Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 4,
        tenant_move_out: terminationDate,
        total_amount_tenant_owes: owesAmount,
        deadline_of_payment: terminationDate,
        missed_payment_information: missedPayments,
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 14,
      };
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };



  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  const handleDateChange = (date, type, index) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    } else if (type === "missedPaymentStart") {
      let tempArray = [...missedPayments];
      tempArray[index].start_date = dateValid;
      setMissedPayments(tempArray);
    } else if (type === "missedPaymentEnd") {
      let tempArray = [...missedPayments];
      tempArray[index].end_date = dateValid;
      setMissedPayments(tempArray);
    }
  };

  useEffect(() => {
    if (noticeData) {
      if (noticeData.deadline_of_payment)
        setTerminationDateDate(moment(noticeData.deadline_of_payment).toDate());
      setOwesAmount(noticeData.total_amount_tenant_owes);
      const tempArray = [...(noticeData.missed_date_and_owing || [])];
      tempArray.forEach((section) => {
        if (section.start_date)
          section.start_date = moment(section.start_date).toDate();
        if (section.end_date)
          section.end_date = moment(section.end_date).toDate();

      });
      if (tempArray.length)
        setMissedPayments(tempArray);
    }
  }, [noticeData.notice_type]);



  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Non Payment Of Rent (N4 form)</span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                How much is the total amount ($) your tenant owes you?
              </Form.Label>
              <CostNumberFormat
                name={"rent"}
                id={"rent"}
                classname="form-control"
                value={owesAmount ? owesAmount : ""}
                updateField={(e) =>
                  setOwesAmount(e.target.value)
                }
                pre="$"
                placeholder={"$2300"}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="5">
              <div className='payment-deadline-wrapper'>
                <Form.Label>When is the deadline of payment?</Form.Label>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom-start"
                  rootClose={true}
                  overlay={
                    <Tooltip className="info-tooltip">
                     If your tenant pays rent by the month or year, you must give at least 14 days notice. If your tenant pays rent by the day or week, you must give at least 7 days notice.
                       When counting the days, do not include the date you are giving the notice to the tenant. For example, if you give the notice to the tenant by hand on March 3rd, the first day of the 14-day notice period is March 4th; in this example, the earliest termination date would be March 17th. If you are giving the notice to the tenant by mail or courier, you have to add extra days in calculating the termination date. Read the Instructions to this form to see how many days you have to add.
                       </Tooltip>
                  }
                >
                <img src={exclaim} alt='exlaim'  ></img>
                </OverlayTrigger>




              </div>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
              <div className={'subtext'}>This will also be the termination date if your tenant has failed to pay the balance owing</div>

            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">
              Indicate the missed date and balance owing
            </span>
          </div>
          {missedPayments.map((missedPayment, index) => {
            return (
              <div className="missed-information">
                <div className="missed-info-title">
                  <span>Missed Payment Information {index + 1}</span>
                </div>
                <Row>
                  <Form.Group as={Col} md="5">
                    <Form.Label>Rent Period</Form.Label>
                    <DatePickerComponent
                      id={"missedPaymentStart_" + index}
                      name={"missedPaymentStart_" + index}
                      format={"dd/MM/yyyy"}
                      value={
                        missedPayment.start_date && missedPayment.start_date
                      }
                      handleDateChange={(date) =>
                        handleDateChange(date, "missedPaymentStart", index)
                      }
                      // minDate={new Date()}
                      displayText={"Start Date"}
                      popperPlacement="bottom-start"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="5">
                    <Form.Label>&nbsp;</Form.Label>
                    <DatePickerComponent
                      id={"missedPaymentEnd_" + index}
                      name={"missedPaymentEnd_" + index}
                      format={"dd/MM/yyyy"}
                      value={missedPayment.end_date && missedPayment.end_date}
                      handleDateChange={(date) =>
                        handleDateChange(date, "missedPaymentEnd", index)
                      }
                      minDate={new Date(missedPayment.start_date)}
                      displayText={"End Date"}
                      popperPlacement="bottom-start"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="5">
                    <Form.Label>How much was charged?</Form.Label>
                    <CostNumberFormat
                      name={"rent_" + index}
                      id={"rent_" + index}
                      classname="form-control"
                      value={missedPayment.charges ? missedPayment.charges : ""}
                      updateField={(e) => {
                        let tempArray = [...missedPayments];
                        tempArray[index].charges = 
                          e.target.value
                        
                        setMissedPayments(tempArray);
                      }}
                      pre="$"
                      placeholder={"$2300"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="5">
                    <Form.Label>How much was paid for this period?</Form.Label>
                    <CostNumberFormat
                      name={"periodRent_" + index}
                      id={"periodRent_" + index}
                      classname="form-control"
                      minValue={-1}
                      value={missedPayment.paid_for_period}
                      updateField={(e) => {
                        let tempArray = [...missedPayments];
                        tempArray[
                          index
                        ].paid_for_period = e.target.value
                        
                        setMissedPayments(tempArray);
                      }}
                      pre="$"
                      placeholder={"$2300"}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="5">
                    <Form.Label>Rent Owing</Form.Label>
                    <div className="rent-owing">
                      <span>
                        <CostNumberFormat
                          classname="disbaled-rent-owing-input"
                          disabled
                          allowNegative={true}
                          value={
                            missedPayment.charges -
                            missedPayment.paid_for_period
                          }
                          pre="$"
                        />
                      </span>
                    </div>
                  </Form.Group>
                </Row>
              </div>
            );
          })}
          <div className="rent-owing-total">
            <span>
              Your tenant(s) owe you a total of: $
              {(
                Number(missedPayments[0].charges) +
                Number(missedPayments[1].charges) +
                Number(missedPayments[2].charges) -
                (Number(missedPayments[0].paid_for_period) +
                  Number(missedPayments[1].paid_for_period) +
                  Number(missedPayments[2].paid_for_period))
              ).toLocaleString()}
            </span>
          </div>
          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            hideAgreement={true}
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            type={"lastName"}
            setSignData={(data) => setSignData(data)}
            noticeData={{ ...noticeData, ...transFormSignData(noticeData?.signData || {}) }}
            notice_type={4}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>

    </>
  );
};

export default withRouter(N4Form);
