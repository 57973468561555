let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    {
        id: createEventId(),
        title: 'All-day event',
        start: todayStr + 'T13:00:00',
        data: {
            unit: 'Unit 502',
            address: '1526 Pape Avenue1526 Pape Avenue1526 Pape Avenue',
            landlord: 'Dwight Howard',
            status: 'Confirmed'
        },
        backgroundColor: "#DEDEDE",
        borderColor: "#DEDEDE",
    },
    {
        id: createEventId(),
        title: 'Timed event',
        start: todayStr + 'T12:00:00',
        data: {
            unit: 'Unit 502',
            address: '1526 Pape Avenue',
            landlord: 'Dwight Howard',
            status: 'Delivered'
        },
        backgroundColor: "#f6bfbf",
        borderColor: "#f6bfbf",
    },
    {
        id: createEventId(),
        title: 'Timed event',
        start: todayStr + 'T22:00:00',
        data: {
            unit: 'Unit 502',
            address: '1526 Pape Avenue',
            landlord: 'Dwight Howard',
            status: 'Needs attention'
        },
        backgroundColor: "#bfe7c3",
        borderColor: "#bfe7c3",
    }
]

export function createEventId() {
    return String(eventGuid++)
}