import React from 'react';
import './style.scss';
import { Card, Image } from 'react-bootstrap';
import EditButton from '../Buttons/EditButton';

const ProfileCard = (props) => {
  const {
    title,
    children,
    editaction,
    addaction,
    classes,
    isComplete = true,
  } = props;
  return (
    <Card className={`profile-card ${!isComplete && 'incomplete_section'}`}>
      <Card.Header className="profile-card-title d-flex  align-items-center">
        <div style={{ flex: 1 }}>
          {title} {!isComplete && <span style={{color:'#DB0000'}}>- Additional Information Needed</span>}
        </div>
        <span className="d-flex justify-content-flex-end align-items-center">
          {addaction && (
            <span className="add-link" onClick={addaction}>
              <Image
                className="icon"
                src={require('../../../_assets/images/plus-black-icon.svg')}
                alt="add"
              />{' '}
              Add New Info
            </span>
          )}
          <EditButton onClick={editaction} />
        </span>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};
export default ProfileCard;
