import React from "react";
import { withRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Tips from "./Tips";

import "./style.scss";

const PhotographyTips = (props) => {
    return (
        <div className="photographyTipsPage">
            <Layout newHeader={true}>
                <section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda knows how to make your place look its best.</h1>
                    </div>
                </section>
                
                <Tips />
            </Layout>
        </div>
    );
}

export default withRouter(PhotographyTips);
