import React from "react";
import { Col, Spinner } from "react-bootstrap";
import "./style.scss";

const Loader = () => {
    return (
      <Col md='12' className='d-flex justify-content-center'>
        <div className='loading-wrapper d-flex justify-content-center align-items-center'>
          <Spinner animation="border" variant="success" /> 
          <span>Loading...</span>
        </div>
      </Col>
    )
  }

export default Loader;
