import {
  POST_CHANGE_PASSWORD_INIT,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_FAILED,
} from "../actions/Action";

export const initialState = {
  updatedChangePasswordList: [],
};
export default function editProfileReducer(state = initialState, action) {
  const handlers = {
    [POST_CHANGE_PASSWORD_INIT]: (state) => ({
      ...state,
      passwordChangeError: null,
      passwordChangeSuccess: null,
    }),
    [POST_CHANGE_PASSWORD_SUCCESS]: (state, action) => ({
      ...state,
      updatedChangePasswordList: action.response,
      passwordChangeSuccess: true,
    }),
    [POST_CHANGE_PASSWORD_FAILED]: (state) => ({
      ...state,
      updatedChangePasswordList: [],
      passwordChangeSuccess: false,
      passwordChangeError: action.error,
    }),
  };
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}

//  export default function updatedEditProfileReducer(state=initialState, action){
//     const handlers = {
//         [POST_EDIT_PROFILE_INIT]: (state) => ({
//             ...state
//         }),
//         [POST_EDIT_PROFILE_SUCCESS]: (state, action) => ({
//             ...state,
//             updatedEditProfileList: action.response
//         }),
//         [POST_EDIT_PROFILE_FAILED]: (state) => ({
//             ...state,
//             updatedEditProfileList: [],
//         })
//     };
//     const handler = handlers[action.type];
//     if(!handler) return state;
//     return {...state, ...handler(state, action)};
// }
