import React from "react";
import "./style.scss";

const ThreeDotsDropdown = (props) => {
    return (
        <div className={`custom dropdown ${props.extraClass}`}>
            <span
                className="dropdown-toggle cursor-pointer buttonappnone"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
            >
                {props.icon}
            </span>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                {props.actionsButton}
            </div>
        </div >
    )

}
export default ThreeDotsDropdown;