export let userColumns = [
		{
			header: 'ACTIONS',
			name: 'action',
			width: '12.5%'
		},
		{
			header: 'NAME OF USER',
			name: 'name',
			width: '12.5%'
		},
		{
			header: 'ROLE',
			name: 'role',
			width: '12.5%'
		},
		{
			header: 'REQUEST TYPE',
			name: 'request_type',
			width: '12.5%'
		},
		{
			header: 'CITY',
			name: 'city',
			width: '12.5%'
		},
		{
			header: 'DATE AND TIME',
			name: 'date_time',
			width: '12.5%'
		},
		{
			header: 'Created date',
			name: 'search_date',
			width: '12.5%'
		},
		{
			header: 'Notes',
			name: 'Notes',
			width: '12.5%'
		},
		
	];