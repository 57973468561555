import React from 'react';

function TenantHeader({ propertyDetails, setShowPropertyDetails = () => {} }) {
  console.log({ propertyDetails });
  return (
    <div className={'tenant_header'}>
      <b>
        {propertyDetails?.address_line_1}{' '}
        {propertyDetails?.unit_no ? `- ${propertyDetails?.unit_no}` : ''}
      </b>
      <span>Landlord : {propertyDetails?.landlord_name}</span>
      <button
      onClick={()=>{
        setShowPropertyDetails(true)
      }}
        className={'request-message-btn'}
        style={{ textAlign: 'start', padding: 0 }}
        id="BTN-view-property"
        data-description="Tenant chose to view property from messages"
      >
        View Property
      </button>
    </div>
  );
}

export default TenantHeader;
