import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import editProfile from "./Store/reducers/EditProfileReducer";
import updatedEditProfile from "./Store/reducers/UpdateEditProfileReducer";
import changePassword from "./Store/reducers/ChangePasswordReducer";
import auth from "./Store/reducers/AuthReducer";
import propertyDetailsReducer from './Store/reducers/PropertyDetailReducer';
import CMSReducer from './Store/reducers/CMSReducer';
import maintenanceRepairReducer from './Store/reducers/MaintenanceRepairReducer';
import addedTenantReducer from './Store/reducers/AddedtenantReducer';
import deactiveReasonReducer from './Store/reducers/DeactivateReasonReducer';
import messageDetailsReducer from './Store/reducers/MessageDetailReducer';
import userReducer from './Store/reducers/UserReducer';
import requestReducer from './Store/reducers/RequestReducer';
import listingReducer from './Store/reducers/ListingReducer';
import headerReducer from './Store/reducers/HeaderReducer';
import landingPageReducer from './Store/reducers/LandingPageReducer';
import UserProfileReducer from "./Store/reducers/UserProfileReducer";
import RawDataReducer from './Store/reducers/RawDataReducer'
import CouponReducer from "./Store/reducers/CouponReducer";
import showingDetailReducer from "./Store/reducers/ShowingDetailReducer";

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['rawData', 'landingPageReducer', 'messageDetailsReducer'],

}

const reducers = combineReducers({
  editProfile,
  updatedEditProfile,
  changePassword,
  auth,
  propertyDetailsReducer,
  CMSReducer,
  addedTenantReducer,
  deactiveReasonReducer,
  maintenanceRepairReducer,
  messageDetailsReducer,
  userReducer,
  requestReducer,
  UserProfileReducer,
  listingReducer,
  landingPageReducer,
  rawData: RawDataReducer,
  headerReducer,
  CouponReducer,
  showingDetailReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers)
const initialState = {};
const enhancers = [];
const middleware = [thunk];

const { devToolsExtension } = window;
if (typeof devToolsExtension === "function" && process.env.NODE_ENV === 'development') {
  enhancers.push(devToolsExtension());
}


const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(persistedReducer, initialState, composedEnhancers);

export default store;
