import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N6Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={"Notice to End your Tenancy For Illegal Acts or Misrepresenting Income in a Rent-Geared-to-Income Rental Unit (N6 Form)"}
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
              The termination date is different depending on your landlord's
              reason for giving you this notice and whether this is your first
              or second Notice to End your Tenancy in the past 6 months. For
              <b>Reason 1</b>, the termination date the landlord sets out in this
              notice must be at least <b>10 days</b> after the landlord gives you this
              notice.
            </span>
          </div>
          <div className="notice-info-text">
            <span>For reasons 2 and 3</span>
            <ul>
              <li>
                <span>
                  If this is your first Notice to End your Tenancy in the past 6
                  months, the termination
                  <br /> date must be at least 20 days after the landlord gave
                  you this notice.
                </span>
              </li>
              <li>
                <span>
                  If this is your second Notice to End your Tenancy in the past
                  6 months and the first
                  <br /> notice had a 7 day correction period, the termination
                  date must be at least 14 days after the landlord gives you
                  this notice.
                </span>
              </li>
            </ul>
          </div>
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord
              has put in this notice. However, the landlord can apply to the
              Board for an order to evict you. The Board will schedule a hearing
              where you can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If you move out of the rental unit by the termination date, your
              tenancy ends on that date. However, if your landlord gave you this
              notice because you misrepresented your income or the income of
              family members living in the rental unit, you may still owe the
              landlord money for the amount you would have been required to pay
              if you had not misrepresented your income.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the board?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If the landlord applies to the Board to evict you, the Board will
              schedule a hearing and send you a copy of the application and the
              Notice of Hearing. The Notice of Hearing sets out the date, time
              and location of the hearing. At the hearing, the landlord will
              have to prove the claims they made in this Notice to End your
              Tenancy and in the application and you can respond to the claims
              your landlord makes.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
            For more information about this notice or your rights, you can
              contact the Landlord and Tenant Board. You can reach the Board by
              phone at <b>416-645-8080</b> or <b>1-888-332-3234</b>. You can visit the Board's
              website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a>.
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}
          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N6Info);
