import {
    SET_USER_PROFILE
 } from '../actions/Action'

 const initialState = {
     userProfile: []
 }

 export default function UserProfileReducer(state = initialState, action) {
    const handlers = {
        [SET_USER_PROFILE]: (state) => ({
            ...state,
            userProfile: action.userProfile,
        })
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}
