import { toast } from 'react-toastify';

const toaster = (type,msg) =>{
    const settings = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    if(type === 'success'){
        return toast.success(msg, settings);
    }else if(type === 'error'){
        return toast.error(msg, settings);
    } 
}

export default toaster;
