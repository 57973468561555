export const N10Schema = {
    date: [
        { rule: "required", message: "Date required" },
    ],
    rent: [
        { rule: "required", message: "rent required" },
    ],
    newRent: [
        { rule: "required", message: "new rent required" },
    ],   
    amount: [
        { rule: "required", message: "amount required" },
    ],
    description: [
        { rule: "required", message: "description required" },
    ],
    firstName: [
        { rule: "required", message: "first name required" },
    ], 
    lastName: [
        { rule: "required", message: "last name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}