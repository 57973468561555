import { getAPI } from "../Api"

// bedroomsData
export const bedroomsData = [
    { value: 1, title: '1' },
    { value: 2, title: '2' },
    { value: 3, title: '3' },
    { value: 4, title: '4' },
    { value: 5, title: '5+' }
]

// isSharedBathroomData
export const isSharedBathroomData = [
    { value: 0, title: 'shared' },
    { value: 1, title: 'private' }
]

// bathroomsData
export const bathroomsData = [
    { value: 1, title: '1' },
    { value: 2, title: '1.5' },
    { value: 3, title: '2' },
    { value: 4, title: '3+' }
]

// is_furnishedData
export const is_furnishedData = [
    { value: 1, title: 'Yes', display: 'Furnished' },
    { value: 0, title: 'No', display: 'Not-Furnished' },
    { value: 2, title: 'Partially', display: 'Semi-Furnished' }
]

// pet_policyData
export const pet_policyData = [
    { value: 1, title: 'Pets Allowed', display: 'Pets Allowed' },
    { value: 0, title: 'Pets Not Allowed', display: 'Pets Not Allowed' },
    { value: 2, title: 'Negotiable', display: 'Negotiable Pet Policy' }
]

export const getUtilitiesAndAmenities = async () => {
    try {
        const urlAmenities = '/get-amenities'
        const urlUtilities = '/get-utilities'
        const utilitiesDataRes = await getAPI(urlUtilities)
        const amenitiesDataRes = await getAPI(urlAmenities)
        return {utilitiesData: utilitiesDataRes?.data?.data, amenitiesData: amenitiesDataRes?.data?.data,}
    } catch (error) {
        return {utilitiesData: [], amenitiesData: []}
    }
}

// is_parking_includedData
export const is_parking_includedData = [
    { value: 1, title: 'Yes', display: 'Parking' },
    { value: 0, title: 'No', display: 'Parking not allowed' },
    { value: 2, title: 'At extra cost', display: 'Parking at extra cost' }
]
export const is_parking_includedDataAdmin = [
    { value: 1, title: 'Yes', display: 'Parking' },
    { value: 0, title: 'No', display: 'Parking not allowed' },
    { value: 2, title: 'At Extra Cost', display: 'Parking at extra cost' }
]

// laundryData 
export const laundryForHouseData = [
    { value: 1, title: 'In the house' },
    { value: 0, title: 'None' }
]

export const laundryForUnitData = [
    { value: 2, title: 'In Suite' },
    { value: 3, title: 'In the Building' },
    { value: 0, title: 'None' }
]

//Promote Listing Data
// promoMedium
export const promoMedium = [
    { value: 0, title: 'Select All' },
    { value: 1, title: 'Facebook Marketplace' },
    { value: 2, title: 'Google' },
    { value: 3, title: 'Kijiji' }
]

// promoMedium
export const promoAmount = [
    { value: 1, amount: "5.00", title: '1 day ($5)', display: '1 day' },
    { value: 2, amount: "50.00", title: '1 month ($50)', display: '1 month' },
    { value: 3, amount: "25.00", title: '1 week ($25)', display: '1 week' }
]