export const STORE_ADDRESS = 'STORE_ADDRESS';
export const STORE_LANDLORD = 'STORE_LANDLORD';
export const STORE_OVERVIEW_DETAILS = 'STORE_OVERVIEW_DETAILS';

export function rentedAddress(data) {    
    return function (dispatch) {
        dispatch({ type: STORE_ADDRESS, address: data });
    };
}

export function landlordInfo(data) {    
    return function (dispatch) {
        dispatch({ type: STORE_LANDLORD, landlord: data });
    };
}

export function setOverviewDetails(data) {    
    return function (dispatch) {
        dispatch({ type: STORE_OVERVIEW_DETAILS, overviewDetails: data });
    };
}

