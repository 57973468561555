import React from "react";
import ContentLoader from 'react-content-loader';

const UnitListContentLoader = (props) => {
    const loop = 4;
    return (
        <div className="inner-wrapper">
            <h6>
                <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="10%" height="10" />
                </ContentLoader>
            </h6>
            <ul className='details'>
                {(() => {
                    const options = [];
                    for (let i = 0; i < loop; i++) {
                        options.push(<React.Fragment key={i}><RowList /></React.Fragment>);
                    }
                    return options;
                })()}
            </ul>
        </div>
    )
}
export default UnitListContentLoader;

const RowList = (props) => {
    return (
        <li>
            <ContentLoader height={50} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                <circle cx="30" cy="20" r="18" />
                <rect x="80" y="10" rx="0" ry="0" width="40%" height="15" />
            </ContentLoader>
        </li>
    )
}