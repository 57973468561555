import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import "./style.scss";
import { Row, Col } from "react-bootstrap";
import { MapContainer, LargeButton, UnitVerified, UnitAddress, UnitDescription, UnitDetails, UnitImageDisplay, Loader, ModalBox } from "../../components";
import { toaster, logger } from "../../../Utils";
import { postAPI } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../Store/actions/PropertyDetailAction";
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player/lazy'
import StaticMap from "../../components/StaticMap";

const log = logger("PropertyDetails");
const PropertyDetails = (props) => {
  const { setGetVerified, loader } = props;
  const [is_modelActive, setModelActive] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [is_publishSuccess, setPublishSuccess] = useState(false);
  const [is_showGallery, setShowGallery] = useState(false);
  const [imageGallary, setImageGallary] = useState(null);
  const [deleteListingPop, setDeleteListingPop] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showVideo,setshowVideo]=useState({})


  const dispatch = useDispatch();
  // const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const  VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const { propertyDetails = {}, unitDetails = {} } = useSelector(state => state.propertyDetailsReducer);
  const property_id = propertyDetails && propertyDetails.id;
  // const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const is_active = unitDetails && unitDetails.is_active;
  const imageList = unitDetails?.images || []
  const videoList = unitDetails?.videos || []


  // useEffect(() => {
  //   return () => {
  //     dispatch(getUnitDetail({}))
  //   }
  // },[property_id, property_details_id])

  useEffect(() => {
    // debugger
    let imageArray = imageList?.length > 0 ? imageList.map((image, i) => {
      return {
        original: image,
        thumbnail: image,
      }
    }) : [];
    let videoArray = videoList?.length > 0 ? videoList.map((video, i) => {
      
      const videoThumbnailRegx=video.match(VID_REGEX);
      return{
          original: videoThumbnailRegx?`https://i.ytimg.com/vi_webp/${video.match(VID_REGEX)[1]}/0.webp`:'',
          thumbnail: videoThumbnailRegx ? `https://i.ytimg.com/vi_webp/${video.match(VID_REGEX)[1]}/sddefault.webp`:'',
          embedUrl:video,
          renderItem: renderVideo.bind(video)
      }
  }) : [];
  console.log(isVideo);
  if(isVideo === true){
    console.log('Right happende');
    setImageGallary([...(videoArray||[]),...(imageArray||[])])
  }else{
    setImageGallary([...(imageArray||[]),...(videoArray||[])])
  }
  }, [imageList?.length, videoList?.length, isVideo]);

  const  toggleShowVideo=(url)=> {
    showVideo[url] = !Boolean(showVideo[url]);
   setshowVideo(showVideo)
  }
  const renderVideo = (item) => {
    return (
       <div>
       {showVideo&&showVideo[item.embedUrl] ? (
         <div className="video-wrapper">
           <ReactPlayer
             url={item.embedUrl}
             id="ytplayer"
             title="test"
             width="100%"
           />
         </div>
       ) : (
         <a onClick={()=>toggleShowVideo(item.embedUrl)}>
           <div className='video-thumbnail'>
           <img className="image-gallery-image" src={item.original} />
           </div>
       
         </a>
       )}
     </div>
    );
  };

  const editDetails = (step) => {
    let url = `/create-listing/${property_id}`;
    if (property_details_id) {
      if (unitDetails?.status === 2) {
        url = `/create-listing/${property_id}/${property_details_id}`;
      } else {
        url = `/edit-listing/${property_id}/${property_details_id}`;
      }
    }
    dispatch(getCurrentPage(step));
    props.history.push({
      pathname: url
    });
  }

  const activeListing = () => {
    const url = `/landlord/listing/active/${property_details_id}`;
    const data = null;
    postAPI(url, data).then(response => {
      if (response.status === 200) {
        dispatch(getCurrentPage(0));
        props.history.push({
          pathname: '/create-listing/' + property_id + '/' + property_details_id,
        });
        toaster('success', response.data.message);
      } else {
        toaster('error', 'Something went wrong!')
      }
    })
  }

  const viewGallery = (type) => {
    if(type === 'video'){
    console.log(type,'Type is it');
      setShowGallery(true);
      setIsVideo(true)
    }else{
      console.log("Type is no");
      setIsVideo(false)
      setShowGallery(true);
    }
  }

  const onSubmit = () => {
    const url = '/landlord/publish-listing';
    const data = {
      property_id: property_id,
      property_details_id: property_details_id
    }
    postAPI(url, data).then(response => {
      if (response.status === 200) {
        setPublishSuccess(true);
        //toaster('success', response.data.message);
      } else {
        toaster('error', 'Something went wrong!')
      }
    })
  }

  const deleteListing = () => {
    const url = '/landlord/delete-listing';
    const data = {
      property_id: property_id,
      property_detail_id: property_details_id
    }
    postAPI(url, data).then(response => {
      if (response.status === 200) {
        setConfirmMessage(response?.data?.message);
        setConfirmAction(true);
        // toaster('success', response?.data?.message);
      } else {
        toaster('error', response?.response?.data?.message);
      }
    })
  }

  return (

    <>
      <div className="profile-container">
        <div className={`profile-container-box property-details ${is_active === 0 ? 'space-for-status' : ''}`}>
          {is_active === 0 &&
              <div className="deactive-status">Listing Deactivated, <span className='link' onClick={() => setModelActive(true)}>Click here to activate</span></div>}
          {!loader ?
            <Row>

              <Col md='12' className='d-flex flex-wrap'>
                {(unitDetails && (unitDetails?.images?.length !== 0 || unitDetails.videos?.length)) ?
                  <UnitImageDisplay
                    imagesList={unitDetails.images || []}
                    videosList={unitDetails.videos || []}
                    viewGallery={(type) => viewGallery(type)}
                    actionDetails={() => editDetails(5)} />
                  : <div className='no-image'>
                    <p>No Images, <span onClick={() => editDetails(5)}>Click here</span> to upload</p>
                  </div>}
              </Col>
              <Col md='7'>

{/*                 {(unitDetails && unitDetails.is_verified === 'unverified') &&
                  <UnitVerified setGetVerified={setGetVerified} />
                } */}

                <UnitAddress
                  propertyDetails={propertyDetails}
                  unitDetails={unitDetails}
                  editDetails={() => editDetails(0)} />

                {unitDetails &&
                  <UnitDetails
                    propertyDetails={propertyDetails}
                    unitDetails={unitDetails}
                    editDetails={() => editDetails(2)} />
                }

                {unitDetails &&
                  <UnitDescription
                    propertyDetails={propertyDetails}
                    unitDetails={unitDetails}
                    editDetails={() => editDetails(3)} />
                }

              </Col>

              <Col md='5'>
                {propertyDetails?.latitude && propertyDetails?.longitude &&
                  <div className="inner-wrapper" style={{ height: '70vh', padding: '0', overflow: 'hidden', border: '2px solid #ccc' }}>
                    {/* <MapContainer
                      height='70vh'
                      latitude={propertyDetails?.latitude}
                      longitude={propertyDetails?.longitude} /> */}
                      <StaticMap
                        height='700'
                        latitude={propertyDetails?.latitude}
                        longitude={propertyDetails?.longitude}
                      />
                  </div>
                }


                {/* Edit Property */}
                {!unitDetails ?
                  <LargeButton
                    title="continue editing this property"
                    onClick={() => editDetails(0)} />

                  : unitDetails && unitDetails?.utilities?.length < 1 && unitDetails.status === 2 ?
                    <LargeButton
                      title="continue editing this property"
                      onClick={() => editDetails(2)} />

                    : unitDetails && !unitDetails.description && unitDetails.status === 2 ?
                      <LargeButton
                        title="continue editing this property"
                        onClick={() => editDetails(3)} />

                      : (unitDetails && unitDetails?.images?.length === 0 && unitDetails.status === 2) ?
                        <LargeButton
                          title="continue editing this property"
                          onClick={() => editDetails(4)} />

                        : (unitDetails && unitDetails.status === 2) &&
                        <LargeButton
                          extraClass="publishBtn"
                          title={"publish now"}
                          onClick={() => onSubmit()} />
                }

                {(unitDetails?.status === 2 && unitDetails?.formatted_status === 'in progress') &&
                  <LargeButton
                    extraClass="deleteBtn"
                    variant="dark"
                    title="delete listing"
                    onClick={() => setDeleteListingPop(true)} />
                }

                {/* Published & Pending for Approval */}
                {
                  (unitDetails && unitDetails.is_promoted)
                    ? (
                      unitDetails.status === 3
                        ? <div className='buttonLikeText grayButton'>
                          <p>promotion pending approval</p>
                        </div>
                        : (
                          unitDetails.days_left > 0 &&
                          <div className='buttonLikeText'>
                            <p>Listing Promoted</p>
                            <span>({unitDetails.days_left} {unitDetails.days_left > 1 ? 'days' : 'day'} remaining)</span>
                          </div>
                        )
                    )
                    : unitDetails && (unitDetails.status === 3 || unitDetails.status === 1) &&
                    <LargeButton
                      title={"promote your listing"}
                      onClick={() => editDetails(6)} />
                }
              </Col>
            </Row> : <Loader />}
        </div>
      </div>

      <ModalBox
        show={is_modelActive}
        onHide={() => {
          setModelActive(false)
        }}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
          setModelActive(false)
          activeListing()
        }}
      >
        <h4>please edit the necessary details.</h4>
        <p>these details are lease start date, description, etc. and publish the listing. Once you hit “publish” our team will review and approve the listing to go live.</p>
      </ModalBox>

      <ModalBox
        show={is_publishSuccess}
        onHide={() => {
          setPublishSuccess(false)
        }}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
          setPublishSuccess(false)
          props.history.push('/dashboard');
        }}
      >
        <h4>your listing is being reviewed by our team</h4>
        <p>you will get an email as soon as it’s approved and goes live.</p>
      </ModalBox>

      <ModalBox show={is_showGallery}
        onHide={() => setShowGallery(false)}
        size='lg'>
        {imageGallary ?
           <ImageGallery 
           items={imageGallary}
           lazyLoad={false}
           onSlide={()=>setshowVideo({})} /> : <Loader />
        }
      </ModalBox>

      <ModalBox
        show={deleteListingPop}
        closeButtonHeader={false}
        size='sm'
        type='condition'
        actionbuttontitle={'Yes'}
        buttonaction={() => {setDeleteListingPop(false); deleteListing()}}
        backbuttontitle={'Not Now'}
        backaction={() => setDeleteListingPop(false)}
      >
        <p className='order-one-modal'>
            Are you sure you want to delete content? This cannot<br /> be undo.
        </p>
      </ModalBox>
      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'got it'}
        buttonaction={() => {setConfirmAction(false); props.history.push('/dashboard')} }
      >
        <h4>{confirmMessage === '' ? 'Content has been deleted.' : confirmMessage}</h4>
      </ModalBox>
    </>
  );
}

export default withRouter(PropertyDetails);