import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { LargeButton } from "../..";
import NumberFormat from "react-number-format";
import "../style.scss";
import { logger, toaster, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { WarningFormSchema } from "./ValidationSchema/WarningFormSchema";
import { useGlobalState } from "../../../../StateContext";

const log = logger("WarningForm");
const WarningForm = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    requestData,
    getParalegal,
    triggerReqTenant,
    phoneError,
    onBack, 
    // onNoticeDataUpdate
  } = props;
  const [reason, setReason] = useState('');
  const [other, setOther] = useState('');
const [error, setError] = useState({})
const [title,setTitle]=useState('')
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState(''); 
  const [originalDescription, setOriginalDescription] = useState(''); 
  const [globalState,setGlobalState]=useGlobalState()
  const [isSubmited, setisSubmited] = useState(false)
  const profileDetails = useSelector(state => state.editProfile.editProfileList);
  useEffect(() => {
    setGlobalState({ onSubmit: onSubmit })
    if(isSubmited)
    validate(WarningFormSchema)
  }, [fullName,phoneNumber,description])
 
  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])

  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    setisSubmited(true)
    if(!reason)
    {
      setError({reason:'Please select atleast one warning '})
      return
    }
    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (validate(WarningFormSchema) && tenant_id.length !== 0) {
      const url = '/landlord/notice/store-notice';
      const data = {
          "notice_id": noticeData && noticeData.id,
          "property_id":property_id,
          "property_detail_id":property_detail_id,
          "notice_type":17,
          "reasons_for_warning" : reason,
          "reason_text":other,
          "notes": originalDescription,
          "tenants": tenant_id,
          "i_want_to": 17
      }

      postAPI(url, data).then(response => {
        if (response.status === 200) {          
          const notice_id =  response.data.data.notice_id;       
          toaster('success', 'Data Saved Successfully!');
          viewNotice(notice_id)
        }
      })
    }
  }

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`
    });
  }
  

  // const OnMultipleOptionCheck = (event) => {
  //   const Value = event.target.value;
  //   const name = event.target.getAttribute('name');
  //   if (name === 'reason') {
  //     if (reason.includes(Value)) {
  //       const index = reason.indexOf(Value);
  //       reason.splice(index, 1);
  //       setReason(reason => [...reason])
  //     } else {
  //       setReason(reason => [...reason, Value])
  //     }
  //   }
  // }
 const OnMultipleOptionCheck = (event,data) => {
    const {value}=event.target || {}
    setError({reason:''})
    setTitle(data.headline)
    const str=data.body.replace(/<[^>]*>?/gm, '')
    setDescription(str.replaceAll(`&nbsp;`,' '))
    setOriginalDescription(data.body)
    // const name = event.target.getAttribute('name');
    setReason([value])
}


  useEffect(() => {
    if (profileDetails) {
        setFullName(profileDetails ? profileDetails.data.name : '');
        setPhoneNumber(profileDetails ? profileDetails.data.phone : '');
    }
  }, [profileDetails]);

  useEffect(() => {
    if (noticeData && noticeData.notice_type === 17) {
      // setFullName(noticeData.full_name);
      // setPhoneNumber(noticeData.phone_number)
      setReason(noticeData.reasons_for_warning);
      setOther(noticeData.reason_text);
      const str=noticeData.notes.replace(/<[^>]*>?/gm, '')
      setDescription(str.replaceAll(`&nbsp;`,' '))
      setOriginalDescription(noticeData.notes)
    }
  }, [noticeData]);

  return (<>
    <div className="N-form-card form-pad">
      <div className="d-flex justify-content-between">
        <span className="form-title">Notice of Warning</span>
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}
        />
      </div>
      <Form id="Form" className="mt-2">
        <div className="d-flex justify-content-between mb-3">
          <span className="form-title">Landlord Info</span>
        </div>
        <Row>
          <Form.Group as={Col} md="5">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              name="landlord_fullName"
              id="landlord_fullName"
              value={fullName}
              maxLength="30"
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} md="5">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              placeholder="123-123-1234"
              name="landlord_phoneNumber"
              id="landlord_phoneNumber"
              className={
                phoneError === true ? "form-control is-invalid" : "form-control"
              }
              value={phoneNumber ? phoneNumber : ""}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Group>
        </Row>

        {/* <div className="d-flex justify-content-between mb-3">
          <span className="form-title"></span>
        </div> */}
        
        <Row>
          <Form.Group as={Col} md='10'>
            <Form.Label>Select the warning</Form.Label>
            {error['reason'] ? 
                  <>
                  <br/>
                  <span style={{color:'red'}}>{error['reason']}</span>
                  </>
                  :''}
            <ul className="select-options">
                {requestData && requestData.map((data) =>
                  <li onClick={(e) => OnMultipleOptionCheck(e, data)} key={"reasonOption_" + data.id} value={data.id} name="reason"
                    className={reason.includes(data.id) ? "active" : ""}>{data.title}</li>)}

                {reason.includes(29) &&
                  <li className="other-fild">
                    <Form.Control
                        type='text'
                        name='other'
                        id='other'
                        value={other}
                        placeholder='Type here'
                        onChange={(e) => setOther(e.target.value)}
                    />
                  </li>}
                 
              </ul>        
          </Form.Group>
        </Row>


        <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">{title || 'Warning'}</span>
          </div>
          <Row>
            <Form.Group as={Col} md='10'>
              <Form.Label>Edit your warning</Form.Label>
              <Form.Control
                as="textarea"
                rows="8"
                placeholder="This is where you can provide all the details you need to about the notice you are sending."
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
          </Row>
      </Form>     
    </div>
    <div className="container-footer button-extra-margin">
      <Button variant="secondary" onClick={() => onBack()}>
        Back
      </Button>
      <Button variant="success" onClick={() => onSubmit()}>
        Review
      </Button>
    </div>
</>);
};

export default withRouter(WarningForm);
