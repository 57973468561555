import {
    GET_ACTIVE_CMS_FILTER,
    GET_CMS_CLICKED_DATA,
    GET_FORMS_PDF_URL
} from "../actions/Action";

export const initialState = {
    activeCMSFilterStatus: null,
    clickedData: null,
    formsPdfUrl: null
}

export default function CMSReducer(state = initialState, action) {
    const handlers = {
        [GET_ACTIVE_CMS_FILTER]: (state) => ({
            ...state,
            activeCMSFilterStatus: action.activeCMSFilterStatus,
        }),
        [GET_CMS_CLICKED_DATA]: (state) => ({
            ...state,
            clickedData: action.clickedData,
        }),
        [GET_FORMS_PDF_URL]: (state) => ({
            ...state,
            formsPdfUrl: action.formsPdfUrl,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}