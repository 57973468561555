import {
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  FORGOT_PASSWORD,
  SET_PROFILE,
  SET_FORGOT_PASSWORD_PIN,
} from "../actions/Action";

export const initialState = {
  userId: null,
  email: null,
};

export default function authReducer(state = initialState, action) {
  const handlers = {
    [SIGNUP_SUCCESS]: (state) => ({
      ...state,
      userId: action.userId,
      email: action.email,
      forgotPasswordFlowActive: false,
    }),
    [LOGIN_SUCCESS]: (state) => ({
      ...state,
      loggedIn: true,
      token: action.token,
      role: action.role,
      forgotPasswordFlowActive: false,
    }),
    [LOGOUT]: (state) => ({
      ...state,
      loggedIn: false,
      userId: null,
      email: null,
      token: null,
      role: null,
      forgotPasswordFlowActive: false,
      id: null,
      name: null,
      first_name: null,
      last_name: null,
      phone: null,
    }),
    [FORGOT_PASSWORD]: (state) => ({
      ...state,
      forgotPasswordFlowActive: true,
      forgotPasswordEmail: action.email,
    }),
    [SET_PROFILE]: (state) => ({
      ...state,
      ...action.data.data,
    }),
    [SET_FORGOT_PASSWORD_PIN]: (state) => ({
      ...state,
      forgotPasswordPin: action.pin,
    }),
  };
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}
