import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {SingleOptionCheck} from '../../../';
import { rentDeposite } from '../../../../../Constants/LeaseConstants';

const CreateLeaseAmenities = props => {

    const {amenities, onAmenitiesToggle, leaseInfo, onChangeHandler} = props;

    return (
        <>
            <h3 className='text-left lease-heading'>amenities</h3>
            <Row>
                {
                    amenities && amenities.map((data, i) => {
                        return (
                            <Form.Group key={i} as={Col} md='2'>
                                <Form.Label>{data.name}</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={() => onAmenitiesToggle(data.id)} name={data.name} setParam={data.allow}
                                />
                            </Form.Group>
                        )
                    })
                }
            </Row>
            <Row>
                <Form.Label className="w-100 m-2">Others</Form.Label>
                <Form.Group as={Col} md='4'>
                    <Form.Control
                        type='text'
                        name='other_service_utilities'
                        id='other_service_utilities'
                        value={leaseInfo.other_service_utilities}
                        placeholder='Fan, TV, Sofa'
                        onChange={(e) => onChangeHandler(e.target.value, 'other_service_utilities')}
                    />
                </Form.Group>
            </Row>
                    
        </>
    )
}

export default CreateLeaseAmenities
