import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";

import "./style.scss";

const CookiePolicyBanner = () => {
    const [isShowCookiePolicy, setIsShowCookiePolicy] = useState(Boolean(Cookie.get("isCookiePolicyAccepted")) || true);
    
    useEffect(() => {
        if (Boolean(Cookie.get("isCookiePolicyAccepted"))) {
            setIsShowCookiePolicy(false)
        }
    }, [isShowCookiePolicy]);

    const acceptCookiePolicy = () => {
        Cookie.set("isCookiePolicyAccepted", true);
        setIsShowCookiePolicy(false)
    }

    return (
        <>{
            isShowCookiePolicy && <div className="acceptCookieBanner">
                <div className="acceptCookieContent">
                    We use cookies to provide a better experience, analyze site traffic and serve you relevant advertisements.<br />
                    By continuing to use the website you are agreeing to use of cookies as outlined in our <Link to="/privacy-policy">Privacy Policy</Link>.
                </div>
                <Button onClick={acceptCookiePolicy}>I accept</Button>
            </div>
        }</>
    )
}

export default CookiePolicyBanner;
