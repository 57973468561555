import React,{useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Map, InfoWindow, Marker } from 'google-maps-react';
import Autocomplete from "react-google-autocomplete";
import MailingAddress from "../../../components/LeaseManagement/CreateLeaseSteps/ContactInfoComponents/MailingAddress";
import {getAddressFromPlace,getContactInfo} from '../../../components/LeaseManagement/CreateLeaseSteps/helper';
export const AutoCompleteWithMap = (props) => {
    const {propertyDetails, reset,updateState=()=>{},displayTitle, address, place, mapPosition, showingInfoWindow, selectedPlace, activeMarker, updateField, handlePlaceChanged, onMarkerClick, onInfoWindowClose, onMarkerDrag,showFullAddressForm=false } = props
    const [addressInfo,setAddressInfo]=useState({});
     

    useEffect(() => {
        if(propertyDetails)
        {
      const {
        address_line_1,
        address_line_2,
        city,
        province,
        country,
        postal_code,
        latitude,
        longitude,
      } = propertyDetails;
      const info = {
        mailing_address: address_line_1,
        mailing_city: city,
        mailing_country: country,
        mailing_latitude: latitude,
        mailing_longitude: longitude,
        mailing_postal_code: postal_code,
        mailing_province: province,
      };
      setAddressInfo({...info})
    }
    }, [propertyDetails]);


    useEffect(() => {
      if (addressInfo) {
        const {
          mailing_address,
          mailing_city,
          mailing_country,
          mailing_latitude,
          mailing_longitude,
          mailing_postal_code,
          mailing_province,
        } = addressInfo;

        updateState({
          address_line_1: mailing_address,
          address_line_2: "",
          city: mailing_city,
          province: mailing_province,
          country: mailing_country,
          postal_code: mailing_postal_code,
          latitude: mailing_latitude,
          longitude: mailing_longitude,
        });
      }
    }, [addressInfo]);
   
    const onPasteData = (e) => {
        e.preventDefault();
        return false;
    }

    const handlePlaceChangedForFullAddress=(place,type)=>{
        const address = getAddressFromPlace(place);
        handlePlaceChanged(place);
        const addresInfo=getContactInfo(place,address,'mailing');
        setAddressInfo(addresInfo);

    }
     return (
      <>
        <Row>
          <Form.Group as={Col} md="6">
            {!showFullAddressForm ? (
              <>
                <Form.Label>
                  {displayTitle ? displayTitle : "Address"}
                </Form.Label>

                <Autocomplete
                  onPlaceSelected={(place) => handlePlaceChanged(place)}
                  types={[]}
                  componentRestrictions={{ country: ["ca"] }}
                  value={place.formatted_address}
                  onChange={(e) => updateField(e)}
                  className="form-control form-control-user"
                  placeholder="Type or Select Your Address"
                  id="address"
                  name="address"
                  onPaste={(e) => onPasteData(e)}
                  maxLength={125}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) e.preventDefault();
                  }}
                />
              </>
            ) : (
              <MailingAddress
                id="address"
                name="address"
                displayTitle={displayTitle}
                header={"enter property location"}
                contactInfo={reset ? {}:addressInfo}
                setSaveEnable={() => {}}
                updateState={(e, flag) => {
                  const newInfo = { ...addressInfo, [flag]: e.target.value };
                  setAddressInfo(newInfo);
                }}
                handlePlaceChanged={handlePlaceChangedForFullAddress}
                OnSingleOptionCheck={() => {}}
                cols={['12','6','3','3']}
              />
            )}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            md="10"
            style={{ height: "35vh" }}
            className="map-display-elm"
          >
            <Map
              google={props.google}
              zoom={14}
              center={mapPosition}
              centerAroundCurrentLocation={false}
              containerStyle={{
                height: "35vh",
                position: "relative",
                width: "100%",
              }}
            >
              <Marker
                onClick={onMarkerClick}
                position={mapPosition}
                name={address}
                draggable={true}
                onDragend={onMarkerDrag}
              />

              <InfoWindow
                marker={activeMarker}
                onClose={onInfoWindowClose}
                visible={showingInfoWindow}
              >
                <h4 className="mb-1">{selectedPlace.name}</h4>
              </InfoWindow>
            </Map>
          </Form.Group>
        </Row>
      </>
    );
}