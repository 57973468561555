import React from "react";
import ContentLoader from 'react-content-loader';

const UserManagementTable = (props) => {
    const { loop = 4, component } = props;
    return (
        <div className='table-component-wrapper'>
            <table className='table-component'>
                <thead>
                    <tr>
                        <td className="pb-0">
                            <ContentLoader height={50} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                <rect x="0" y="5" rx="5" ry="5" width="100%" height="45" />
                            </ContentLoader>
                        </td>
                    </tr>
                    <tr>
                        {props.columns.map((data, i) => {
                            return (
                                <th width={data.width} key={i}>
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="40%" height="15" />
                                    </ContentLoader>
                                </th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        const options = [];
                        for (let i = 0; i < loop; i++) {
                            options.push(<React.Fragment key={i}><RowList component={component} /></React.Fragment>);
                        }
                        return options;
                    })()}
                </tbody>
            </table>
            <div className='table-footer'>
                <div>
                    <span className='left'>
                        <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="0" y="0" rx="0" ry="0" width="50%" height="15" />
                        </ContentLoader>
                    </span>
                </div>
                <div>
                    <span className='right'>
                        <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="150" y="0" rx="0" ry="0" width="50%" height="15" />
                        </ContentLoader>
                    </span>
                </div>
            </div>

        </div>
    )
}
export default UserManagementTable;

const RowList = (props) => {
    return (
        <>
            {props.component === 'lease' &&
                <tr className='cursorPointer'>
                    <RowTD />
                    <RowTD />
                    <RowTD />
                    <RowTD />
                    <RowTD />
                </tr>}
            {/*  {props.component === 'listingManagementInactive' &&
                <tr className='cursorPointer'>
                    <RowTD />
                    <RowTD />
                    <RowTD />
                    <RowTD />
                    <RowTD />
                    <RowTD />
                </tr>} */}
        </>
    )
}
const RowTD = (props) => {
    return (
        <td><ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="15" />
        </ContentLoader></td>
    )
}