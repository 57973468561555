import React, { Component } from 'react';
import { Form, Nav, Navbar, NavDropdown, Image, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { getEditProfile, setToggleMenu } from '../../../Store/actions/Action';
import { setViewingRequestId } from '../../../Store/actions/MessageDetailAction';
import DropDown from '../DropDown';
import './style.scss';
import Cookies from 'js-cookie';
import { getAPI } from '../../../Api';
import SidePanel from '../SidePanel';
import { logger } from '../../../Utils';
import { withRouter } from 'react-router-dom';
import { markAllAsRead, redirectTo } from './helper';
import { SOCKET_URL } from '../../../Constants/endPoints';

const log = logger('Header');
class Header extends Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      notificationShow: false,
      isShow: false,
      lists: [],
      role: null,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.popover = this.popover.bind(this);
    this.socket = null;
  }

  getRole = () => {
    return parseInt(Cookies.get('role') || 0);
  };

  componentDidMount() {
    const { conversation_id, auth } = this.props;
    this.setState({ role: Cookies.get('role') });
    console.log(this.state);
    this.props.dispatch(getEditProfile());
    this.updateNotifications();
    this.socket = io(SOCKET_URL);
    const socket = this.socket;
    log.info('socket', socket);
    socket.on('connect', () => {
      log.info('socket connected', socket.connected); // true
    });

    socket.on('reconnect', (attemptNumber) => {
      log.info('socket reconnected -', attemptNumber);
    });

    socket.on('disconnect', (reason) => {
      log.info('socket disconnected', reason, '===>', socket.disconnected); // false
      socket.open();
    });

    socket.on('conversation-' + conversation_id + ':MessageEvent', () => {
      this.updateNotifications();
    });

    socket.on('notification-' + auth?.id + ':NotificationEvent', () => {
      this.updateNotifications();
    });
  }

  updateNotifications() {
    const url = '/notifications/list';
    getAPI(url)
      .then((res) => {
        console.warn('list', res.data.data.list);
        if (res.status === 200) {
          this.setState({
            lists: res.data.data.list.filter((i) => !i.seen_at),
          });
        }
      })
      .catch((err) => {});
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  clickNotification = (event) => {
    this.setState({
      notificationShow: !this.state.notificationShow,
    });
  };

  handleClose = () => {
    this.setState({
      isShow: false,
    });
  };
  openNotificationPanel = () => {
    this.setState({
      notificationShow: !this.state.notificationShow,
      isShow: true,
    });
  };

  setRedirectionWithId = (data) => {
    let url = '';
    const request_type = data.request_type;
    if (
      request_type === 1 ||
      request_type === 7 ||
      request_type === 9 ||
      request_type === 14 ||
      request_type === 15 ||
      request_type === 17
    ) {
      // 'request_type' - Showings 1, 7, 9, 14, 15, 17
      url =
        Number(this.state.role) === 2
          ? `/property-details/${data.property_id}/${data.property_detail_id}/showings`
          : `/dashboard/showings`;
    } else if (
      request_type === 8 ||
      request_type === 10 ||
      request_type === 16 ||
      request_type === 18
    ) {
      // 'request_type' - Showings (Reject/Cancel) 8, 10, 16, 18
      url = `/property-details/${data.property_id}/${data.property_detail_id}/messages`;
    }
    this.props.dispatch(setViewingRequestId(data.viewing_request_id));
    this.props.history.push(url);
  };

  notificationAction = (data) => {
    log.info('notification Data', data);
    let url = '';
    /*
    'request_type'
    1 => 'Messages',
    2 => 'Lease',
    3 => 'Maintance Request'
    4 => '?'
    5 => 'Repairs'
    */
    // if (data.request_type === 2) {
    //   url = '/property-details/' + data.property_id + '/' + data.property_detail_id + '/leases'
    // } else if (data.request_type === 3) {
    //   // url = '/maintainance-repair/' + data.property_id + '/' + data.property_detail_id + '/my-panda'
    //   url = '/maintainance-repair/' + data.property_id + '/' + data.property_detail_id + '/overview/' + data.property_lease_id
    // } else if (data.request_type === 5) {
    //   url = '/maintainance-repair/' + data.property_id + '/' + data.property_detail_id + '/repairs'
    // } else {
    //   const redirections = redirectTo(this.props.history.replace, this.props.history.push, data);
    //   redirections[data.request_type] && redirections[data.request_type]()
    //   this.updateNotifications()
    //   return
    // }
    // this.setRedirectionWithId(data);
    const redirections = redirectTo(
      this.props.history.replace,
      this.props.history.push,
      data
    );
    redirections[data.request_type] && redirections[data.request_type]();
    this.updateNotifications();
    setTimeout(() => this.setState({ notificationShow: false }), 1000);

    return;

    // this.props.history.push({
    //   pathname: url,
    //   tenant_id: data.tenant_id
    // });
  };

  onMarkAllAsRead = async () => {
    const res = await markAllAsRead();
    if (res) {
      this.setState({
        lists: [],
        isShow: false,
      });
    }
  };

  popover() {
    return (
      <div>
        <div className="popover-body p-0">
          {this.state.lists &&
            this.state.lists.map((list, index) => {
              return (
                <Form
                  key={'notification_' + index}
                  style={{ padding: '.5rem .75rem' }}
                  className={'cursor-pointer notification-box'}
                  onClick={() => this.notificationAction(list)}
                >
                  <Form.Group>
                    <Form.Label className="popover-form-headline">
                      {list.title}
                    </Form.Label>
                    <Form.Label className="popover-form-content">
                      {list.notification}
                    </Form.Label>
                    <div className="d-flex justify-content-between">
                      <Form.Text className="popover-form-footer-content text-muted">
                        From: {!!list.from && list.from}
                      </Form.Text>
                      <Form.Text
                        className="popover-form-footer-content text-muted"
                        style={{ marginLeft: '70px' }}
                      >
                        {list.date}
                      </Form.Text>
                    </div>
                  </Form.Group>
                </Form>
              );
            })}
          <div className="popover-title" onClick={this.toggleModal}>
            <span
              className="popover-title-typography"
              onClick={this.openNotificationPanel}
            >
              All Notifications
            </span>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.socket && this.socket.removeAllListeners) {
      this.socket.removeAllListeners();
    }
  }

  render() {
    const role = this.getRole();
    console.log(role);
    var name = this.props.editProfileList.data
      ? this.props.editProfileList.data.name
      : 'User';
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return (
      <React.Fragment>
        <Navbar
          className="d-flex justify-content-between navbar sticky-top auth-menu"
          id="header-navbar"
          expand="lg"
          sticky="top"
          collapseOnSelect
        >
          <Nav
            className={`nav d-lg-none d-xl-none p-0 ${
              this.props.hideNavBtn ? 'd-none' : ''
            }`}
          >
            {/* Please added below toggle class "active" */}
            <Button
              className={`menu-icon ${this.props.toggleMenu ? 'active' : ''}`}
              onClick={() =>
                this.props.dispatch(setToggleMenu(!this.props.toggleMenu))
              }
            >
              <div></div>
              <div></div>
              <div></div>
            </Button>
          </Nav>
          {/* <Navbar.Brand href={this.state.role === '1' ? '/dashboard/all-user' : this.state.role === '2' ? '/dashboard' : this.state.role === '3' ? '/search-listing' : ''}> */}
          <Navbar.Brand
            href={
              parseInt(this.state.role) === 1
                ? '/dashboard/all-user'
                : parseInt(this.state.role) === 2
                ? '/landlord'
                : '/'
            }
          >
            <Image
              alt="Rent Panda"
              src={require('../../../_assets/images/logo-color.svg')}
              style={{ maxHeight: '50px' }}
              className="d-inline-block align-top py-2"
            />
          </Navbar.Brand>

          <div className="d-flex">
            <div className="notification-area">
              {this.props.auth.role !== 1 && (
                <DropDown
                  open={this.state.notificationShow}
                  position={'right'}
                  enableTopArrow={true}
                  close={() => {
                    this.setState({ notificationShow: false });
                  }}
                  style={{ width: '350px', top: '50px' }}
                  className={'hello'}
                  content={
                    this.state.lists.length === 0 ? (
                      <div
                        style={{
                          width: '100%',
                          minHeight: '50px',
                          padding: '12px',
                        }}
                      >
                        <span className="d-flex justify-content-center">
                          No Notifications
                        </span>
                      </div>
                    ) : (
                      <div id="popover-basic">{this.popover()}</div>
                    )
                  }
                >
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      marginTop: '10px',
                      marginRight: '15px',
                      position: 'relative',
                    }}
                    onClick={() => this.clickNotification(this)}
                    onMouseOver={() =>
                      this.setState({ notificationShow: true })
                    }
                  >
                    {this.state.lists.length <= 0 ? (
                      <Image
                        width="24px"
                        height="24px"
                        alt="Bell"
                        src={require(`../../../_assets/images/bell-icon.svg`)}
                        className="d-inline-block align-top"
                      />
                    ) : (
                      <>
                        <Image
                          width="27px"
                          height="27px"
                          alt="Bell"
                          src={require(`../../../_assets/images/bell-icon.svg`)}
                          className="d-inline-block align-top"
                        />
                        <span className="bellnotificationActive"></span>
                      </>
                    )}
                  </div>
                </DropDown>
              )}
            </div>
            <div
              className="line-devider"
              style={{ borderLeft: '3px solid #EAEDF0', left: '18px' }}
            ></div>
            <div
              className="user-sort-name"
              style={{ width: '50px', height: '50px', marginLeft: '13px' }}
            >
              <div
                className="w3-circle"
                style={{
                  background: '#4B4B4B',
                  marginTop: '5px',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  left: '4.7px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span style={{ color: 'white' }}>{initials}</span>
              </div>
            </div>
            <NavDropdown
              style={{}}
              title={
                this.props.editProfileList.data
                  ? Object.values(
                      this.props.editProfileList.data.name.toLowerCase()
                    )
                      .join('')
                      .toString()
                  : 'User'
              }
              id="nav-dropdown"
              className="profile-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      role === 2
                        ? '/dashboard'
                        : role === 1
                        ? '/dashboard/all-user'
                        : '/dashboard/my-listing',
                  });
                }}
              >
                my dashboard
              </NavDropdown.Item>
              {this.props.auth.role !== 1 && (
                <NavDropdown.Item href="/profile">my profile</NavDropdown.Item>
              )}

              <NavDropdown.Item onClick={this.props.onLogout}>
                sign out
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar>
        <SidePanel
          isShow={this.state.isShow}
          isHide={this.handleClose}
          title={'Notifications'}
        >
          <div className="sidepanel-body p-0 notification-side-panel-body">
            {!!this.state.lists.length && (
              <a onClick={this.onMarkAllAsRead} className={'mark-all-as-read'}>
                Mark all as read
              </a>
            )}
            {this.state.lists &&
              this.state.lists.map((list, index) => {
                return (
                  <Form
                    key={'notification_list_' + index}
                    style={{ borderBottom: '1px solid lightgrey' }}
                    className={'notification-box'}
                    onClick={() => this.notificationAction(list)}
                  >
                    <Form.Group style={{ padding: '10px 20px 0 20px' }}>
                      <Form.Label className="popover-form-headline d-flex justify-content-between">
                        <span>{list.title}</span>{' '}
                        <span className="text-muted">{list.property_info}</span>
                      </Form.Label>
                      <Form.Label className="popover-form-content">
                        {list.notification}
                      </Form.Label>
                      <div className="d-flex justify-content-between">
                        <Form.Text className="popover-form-footer-content text-muted">
                          {/* {this.props.auth.role === 2 ?
                            'Tenant: ' + list.from
                            : 'Landlord: ' + list.landlord} */}
                          From : {list.from}
                        </Form.Text>
                        <Form.Text
                          className="popover-form-footer-content text-muted"
                          style={{ marginLeft: '70px' }}
                        >
                          {list.date}
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Form>
                );
              })}
          </div>
        </SidePanel>
      </React.Fragment>
    );
  }
}
const select = (state) => ({
  editProfileList: state.editProfile.editProfileList,
  toggleMenu: state.headerReducer.toggleMenu,
  auth: state.auth,
  conversation_id: state.messageDetailsReducer.conversationId,
});
export default connect(select)(withRouter(Header));
