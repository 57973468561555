import React, {
 useEffect,
 useLayoutEffect,
 useState,
} from "react";
import axios from "axios";
import {
 Form,
 Row,
 Col,
 Image,
} from "react-bootstrap";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import {
 useSelector,
 useDispatch,
} from "react-redux";
import {
 setSearchFilter,
 setSearchLocation,
} from "../../../Store/actions/LandingPageAction";
import { getAPI, postAPI } from "../../../Api";
import { logger, toaster } from "../../../Utils";
import {
 AppLayout,
 NavbarSortingDropdown,
 PropertyCard,
 ModalBox,
 AutoCompleteAddress,
 Loader,
} from "../../components";
import ListBlockContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader";
import { FilterTenantProperty } from "./Filter";
import SearchMapListing from "./Filter/SearchMapListing";
import "./style.scss";
import HorizontalPropertyCard from "../../components/PropertyCard/HorizontalPropertyCard";
import {
 checkIsBottom,
 getMarkerData,
 getPropertyDetails,
 isPropertyPresent,
 setPlaceChanged,
} from "./helper";
import SearchListHeader from "./SearchListHeader";
import MobileListView from "./MobileListView";
import DesktopListView from "./DesktopListView";
import { getTenantProfile } from "../../components/Message/helper";
import { Helmet } from "react-helmet";
import SearchListing from "./SearchListing";

const log = logger("CityPageSearchListing");
let timeout;

const CityPageSearchListing = (props) => {
 const [isLoading, setIsLoading] = useState(true);
 const [cityData, setCityData] = useState(null);
 const [cityFilterSet, setCityFilterSet] = useState(null);
 const dispatch = useDispatch();
 const getSearchFilter =
  useSelector(
   (state) =>
    state.landingPageReducer?.getSearchFilter
  ) || null;
 const searchLocation =
   useSelector(
   (state) =>
    state.landingPageReducer.getSearchLocation
  ) || "";
 
 useEffect(() => {
  setIsLoading(true);
  if (props.match.params.city) {
   getAPI(
    "/cms/rent-panda-cities?city=" +
     props.match.params.city
   ).then((resData) => {
    if (resData.status === 200) {
     const resDataView = resData.data.data;
     if (
      resDataView.length > 0 &&
      typeof resDataView[0].city != "undefined" &&
      resDataView[0].city != null
     ) {
      const cityData = resDataView[0];
      setCityData(cityData);
      console.log('city data', cityData);
      
      if (
          resDataView.length > 0 &&
          typeof cityData.city != "undefined" &&
          cityData.city != null
      ) {
           dispatch(setSearchLocation(cityData.city_details));
           const latValue = null, lngValue = null;
           dispatch(setSearchFilter({...getSearchFilter, city_address_or_location: cityData.city_details, search_term: cityData.city_details, latitude: latValue, longitude: lngValue}));
           
           console.log('setting city filter set to true');
           dispatch(setCityFilterSet(true));
      }
      
      setIsLoading(false);
     }
    }
   });
  }
 }, [props.match.params.city]);
 
 return (
  <>
      {cityData && setCityFilterSet && searchLocation !== null ? 
       <SearchListing cityData={cityData} />
      : ''}
  </>
 );
}


export default withRouter(CityPageSearchListing);