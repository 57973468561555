import React from 'react'
import ErrorPage from './App/components/NotFound/ErrorPage'
// import Rollbar from 'rollbar'

const ROLL_BAR_TOKEN = 'de9b83045bdc45e097c81d0bbc442168'

class ErrorBoundry extends React.Component {
    state = { isError: false }
     
    componentWillUnmount() {
        this.setState({ isError: false })
    }
    componentDidCatch(e) {
        try {
            console.error('error is ', e)
            console.warn({ e });
            this.setState({ isError: true })
            // this.state.rollBar.info(e)
        } catch (err) {
        }
    }
    render() {
        const { isError } = this.state;
        console.warn({ isError });
        return <>{!isError ? this.props.children : <ErrorPage />}</>
    }
}
export default ErrorBoundry