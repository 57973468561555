import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N13Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={
            "Notice to End your Tenancy Because the Landlord Wants to Demolish the Rental Unit, Repair it or Convert it to Another Use (N13 Form)"
          }
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
              The termination date the landlord sets out in this notice must be
              at least <b> 120 days </b> after the landlord gives you the notice.
              Exception: If you live in a mobile home park or land lease
              community and you own the mobile home or land lease home, the
              termination date must be at least <b> 1 year </b> after the landlord gave
              you this notice. Also, the termination date must be the last day
              of the rental period. For example, if you pay rent on the first of
              each month, the termination da te must be the last day of a month.
              Finally, if the tenancy is for a fixed term the termination date
              cannot be earlier than the last day of the fixed term. For
              example, if you signed a one-year lease, the terminationdate
              cannot be earlier than the last day of the one-year period set out
              in the lease.
            </span>
          </div>

          <div className="notice-info-title">
            <span>A tenant can give  <b>  10 days' </b> notice to end the tenancy</span>
          </div>
          <div className="notice-info-text">
            <span>
              You can terminate the tenancy sooner than the date set out in this
              notice as long as you give the landlord at least<b>   10 days' </b> notice
              in writing that you intend to move out of the rental unit. You
              must use the Landlord and Tenant Board's Form N9 Tenant's Notice
              to End the Tenancy to give your written notice to the landlord.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord
              has put in this notice. However, the landlord can apply to the LTB
              to evict you. The LTB will schedule a hearing where you can
              explain why you disagree
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If you move out of the rental unit by the termination date, your
              tenancy ends on that date.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the LTB?</span>
          </div>
          <div className="notice-info-text">
            <span>
              The landlord can apply to the LTB to evict you immediately after
              giving you this notice. If the landlord applies to the LTB to
              evict you, the LTB will schedule a hearing and send you a copy of
              the application and the Notice of Hearing. The Notice of Hearing
              sets out the date, time and location of the hearing. At the
              hearing, the landlord will have to prove the claims they made in
              this Notice to End your Tenancy and in the application and you can
              respond to the claims your landlord makes. If the LTB issues an
              order ending your tenancy and evicting you, the order will not
              require you to move out any earlier than the termination date
              included in this notice.
            </span>
          </div>
          <div className="notice-info-title">
            <span>Compensation or another unit</span>
          </div>
          <div className="notice-info-text">
            <span>
              The landlord may have to pay you compensation for moving out
              because of this notice. If the landlord is giving you this notice
              for Reason 1 or Reason 3: If you live in a residential complex
              that has at least 5 residential units, the landlord must:
            </span>
          </div>
          <div className="notice-info-text">
            <ul>
              <li>
                <span>pay you an amount equal to 3 months' rent, or</span>
              </li>
              <li>
                <span>
                  offer you another rental unit that is acceptable to you.
                </span>
              </li>
            </ul>
            <span>
              If you live in a residential complex that has fewer than 5
              residential units, the landlord must:
            </span>
            <ul>
              <li>
                <span>pay you an amount equal to 1 months' rent, or</span>
              </li>
              <li>
                <span>
                  offer you another rental unit that is acceptable to you.
                </span>
              </li>
            </ul>
          </div>
          <div className="notice-info-title">
            <span>Compensation or another unit (continued)</span>
          </div>
          <div className="notice-info-text">
            <span>
              If the landlord is giving you this notice for Reason 2:
              <br />
              If you live in a residential complex that has at least 5
              residential units and you do not plan to move back in once the
              repairs or renovations are done, the landlord must:
            </span>
          </div>
          <div className="notice-info-text">
            <ul>
              <li>
                <span>pay you an amount equal to 3 months' rent, or</span>
              </li>
              <li>
                <span>
                  offer you another rental unit that is acceptable to you.
                </span>
              </li>
            </ul>
            <span>
              If you live in a residential complex that has fewer than 5
              residential units and you do not plan to move back in once the
              repairs or renovations are done, the landlord must:
            </span>
            <ul>
              <li>
                <span>pay you an amount equal to 1 months' rent, or</span>
              </li>
              <li>
                <span>
                  offer you another rental unit that is acceptable to you.
                </span>
              </li>
            </ul>
            <span>
              If you live in a residential complex that has at least 5
              residential units and you plan to move back in once the repairs or
              renovations are done, the landlord must pay you:
            </span>
            <ul>
              <li>
                <span>an amount equal to 3 months' rent, or</span>
              </li>
              <li>
                <span>
                  the rent for the period of time the rental unit is being
                  repaired or renovated, whichever is less.
                </span>
              </li>
            </ul>
            <span>
              If you live in a residential complex that has fewer than 5
              residential units and you plan to move back in once the repairs or
              renovations are done, the landlord must pay you:
            </span>
            <ul>
              <li>
                <span>an amount equal to 1 months' rent, or</span>
              </li>
              <li>
                <span>
                  the rent for the period of time the rental unit is being
                  repaired or renovated, whichever is less.
                </span>
              </li>
            </ul>
            <span>
           <b>  Exception for mobile homes and land lease communities: If:</b>  
            </span>
            <ul>
              <li>
                <span>
                  you live in a mobile home park or land lease community,
                </span>
              </li>
              <li>
                <span>you own the mobile home or land lease home, and</span>
              </li>
              <li>
                <span>
                  the landlord is giving you this notice for either Reason 1,
                  Reason 2 or Reason 3, the landlord must pay you:
                </span>
              </li>
              <li>
                <span>an amount equal to one years' rent, or</span>
              </li>
              <li>
                <span>$3,000, whichever is less.</span>
              </li>
            </ul>
            <span>
              <strong>Where compensation has to be paid,</strong> it must be
              paid by the termination date in this notice.
            </span>
            <span>
              <br/>
              <strong>
                The landlord is not required to pay you compensation:
              </strong>{" "}
              Your landlord is not required to pay you compensation for moving
              out because of this notice if your landlord is giving you this
              notice because they were ordered to demolish or repair the rental
              unit under any Act or law.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
              Both the landlord and the tenant should keep a copy of this
              agreement for their records.
            </span>
          </div>
           
          <div className="notice-info-text">
            <span>
              For more information about this notice or your rights, you can
              contact the Landlord and Tenant Board. You can reach the LTB by
              phone at <b>  416-645-8080 or 1-888-332-3234. </b> You can visit the LTB
              website at <a target="_blank" href="https:/tribunalsontario.ca/ltb"> tribunalsontario.ca/ltb. </a>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N13Info);
