import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Cookies from 'js-cookie';
import { logger } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import { frequencyTypeDisplay } from "../../../../Constants/NoticeConstants";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";




const N1DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));
  const tenacyActs = {
    2: 'The rent increase must be approved by an order under the Residential Tenancies Act, 2006. I have applied to the Board for a Rent Increase Above the Guideline.',
    1: 'The rent increase has been approved by an order under the Residential Tenancies Act, 2006.'
  }

  const { residential_tenancy_act } = noticeData || {}
  const act = residential_tenancy_act?.length ? JSON.parse(residential_tenancy_act) : []


  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice Of Rent Increase (N1 Form)</span>
          {noticeData && noticeData.is_notice_received
            ? role !== 3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "auto",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              When Is The Rent Increasing?
            </span>
            <span className="field-value-text">
              {
                <Moment format="MMMM D, YYYY">
                  {noticeData.when_rent_increase}
                </Moment>
              }
            </span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">New Rent Rate</span>
            <span className="field-value-text">
              ${noticeData.new_rent_rate}
            </span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">Frequency</span>
            <span className="field-value-text">
              {frequencyTypeDisplay(noticeData.frequency)}
              {(noticeData.frequency === 3) &&
                ` - ${noticeData.frequency_text}`}
            </span>
          </Col>
        </Row>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Explanation Of The Rent Increase</span>
        </div>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">This Is A Rent Increase Of</span>
            <span className="field-value-text">
              ${noticeData.rent_increase_of}
            </span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">Frequency</span>
            <span className="field-value-text">
              {frequencyTypeDisplay(noticeData.increase_rent_frequency)}
              {(noticeData.increase_rent_frequency === 3) &&
                ` - ${noticeData.increase_rent_frequency_other_text}`}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column">
            <span className="field-title-text">More Details</span>
            <span className="field-value-text">
              {noticeData.rent_increase_guideline === 1
                ? "This rent increase is less than or equal to the rent increase guideline and does not need approval by an order under the Residential Tenancies Act, 2006."
                : <>This rent increase is more than the rent increase guideline, but:  <br />  {tenacyActs[act[0]]} </>


              }
            </span>
          </Col>
        </Row>

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />

        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default N1DetailsView;
