import React, { useState, useEffect } from 'react';
import './style.scss';
import { Nav } from 'react-bootstrap';
import {
  AppLayout,
  PageTitleHeader,
  Calender,
  RequestShowing,
  ConfirmShowing,
} from '../../components';
import { NavLink, withRouter } from 'react-router-dom';
import MyListing from './MyListing';
import MyHome from './MyHome';
import { logger } from '../../../Utils';
import PropertyDrawer from '../../components/PropertyDrawer/PropertyDrawer';
import { useSelector } from 'react-redux';
import { getFullAddress } from '../TenantPropertyDetails/helper';
import { LeasePdfView } from '../../components/LeaseManagement/CreateLeaseSteps';

const TenantDashboard = (props) => {
  const [componentLoad, setComponentLoad] = useState(null);
  const [is_showingRequest, setShowingRequest] = useState(false);
  const [is_confirmShowing, setConfirmShowing] = useState(false);
  const [is_reSchedule, setReSchedule] = useState(false);
  const [viewingRequestId, setViewingRequestId] = useState(null);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [is_LeasePdfViewScreen, setLeasePdfViewScreen] = useState(false);
  const receiverData = useSelector(
    (state) => state.messageDetailsReducer.receiverData
  );

  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );
  useEffect(() => {
    const slug = props.match.params.slug;
    const state = props.location?.state;
    if (
      state?.conversation_id &&
      (state.viewing_request_id || state.viewingRequestId)
    ) {
      setConfirmShowing(true);
      setViewingRequestId(state.viewing_request_id || state.viewingRequestId);
    }
    setComponentLoad(slug);
  }, [props]);
  const finalAddress = getFullAddress(propertyDetails);
  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader title="your dashboard" />

          <Nav fill variant="tabs" className="rp-tab-views">
            <NavLink
              to={{ pathname: '/dashboard/my-listing' }}
              className={
                componentLoad === 'my-listing' || componentLoad === undefined
                  ? 'nav-link active'
                  : 'nav-link'
              }
            >
              Messages
            </NavLink>
            <NavLink
              to={{ pathname: '/dashboard/my-home' }}
              className={'nav-link'}
            >
              My Home
            </NavLink>
            <NavLink
              to={{ pathname: '/dashboard/showings' }}
              className={'nav-link'}
            >
              Showings
            </NavLink>
          </Nav>
          {componentLoad === 'my-listing' || componentLoad === undefined ? (
            <MyListing
              setShowingRequest={setShowingRequest}
              setConfirmShowing={(flag) => setConfirmShowing(flag)}
              setShowPropertyDetails={setShowPropertyDetails}
              setLeasePdfViewScreen={setLeasePdfViewScreen}
            />
          ) : (
            ''
          )}
          {componentLoad === 'my-home' ? <MyHome /> : ''}
          {componentLoad === 'showings' ? (
            <Calender
              user_type={'tenant'}
              setConfirmShowing={(flag) => setConfirmShowing(flag)}
              setViewingRequestId={(data) => setViewingRequestId(data)}
            />
          ) : (
            ''
          )}
        </div>
      </AppLayout>

      <RequestShowing
        isShow={is_showingRequest}
        title={
          is_reSchedule
            ? 'submit a different schedule'
            : 'creating showing invitation'
        }
        handleClose={() => setShowingRequest(false)}
        is_reSchedule={is_reSchedule}
        setReSchedule={(flag) => setReSchedule(flag)}
        viewingRequestId={viewingRequestId}
      />

      <ConfirmShowing
        isShow={is_confirmShowing}
        title={'showing invitation'}
        handleClose={() => setConfirmShowing(false)}
        setShowingRequest={(flag) => setShowingRequest(flag)}
        setReSchedule={(flag) => setReSchedule(flag)}
        viewingRequestId={viewingRequestId}
      />

      <PropertyDrawer
        finalAddress={finalAddress}
        isShow={showPropertyDetails}
        handleClose={() => {
          setShowPropertyDetails(false);
        }}
      />

      <LeasePdfView
        isShow={is_LeasePdfViewScreen}
        handleClose={() => setLeasePdfViewScreen(false)}
        lease_id={receiverData && receiverData.property_lease_id}
        role={3}
        is_signOffline={receiverData && receiverData.is_offline_lease}
      />
    </>
  );
};

export default withRouter(TenantDashboard);
