import React from "react";
import { Button } from "react-bootstrap";

const ToolsToHelp = (props) => {
    return (
        <React.Fragment>
          <section className="tools-to-help">
            <div className="container">
              <div className="text-center">
                <h3 className="landing-title f-secondary-b">whether you’re a landlord or a tenant,<br/>
                we’ve got the tools to help. </h3>
              </div>
  
              <div className="tool-box-wrap">
                <div className="row">
                  <div className="col-lg-6 tool-box-right mb-4">
                    <div className="tool-box-inner">
                    <img className="tool-help-icon" src={require("../../../../src/_assets/images/landing-pages/LandlordIconHomeCTA.svg")} alt="Landlord Icon" />
                      <h2 className="tool-box-title text-left">landlords</h2>
                      <ul>
                        <li>post unlimited rentals free</li>
                        <li>access tenant profiles</li>
                        <li>schedule showings online</li>
                        <li>get instant credit &amp; background checks</li>
                        <li>build, sign &amp; store leases online</li>
                      </ul>
                      <Button href={"/landlord"} className="btn-block mt-auto" id="BTN-landlord-get-started-home" data-description="Visitor chose landlord get started CTA">get started</Button>
                    </div>
                  </div>
                  <div className="col-lg-6 tool-box-left mb-4">
                    <div className="tool-box-inner">
                    <img className="tool-help-icon" src={require("../../../../src/_assets/images/landing-pages/TenantIconHomeCTA.svg")} alt="Landlord Icon" />
                      <h2 className="tool-box-title text-left">tenants</h2>
                      <ul>
                        <li>search verified properties</li>
                        <li>create a profile &amp; apply in seconds</li>
                        <li>request &amp; book showings online</li>
                        <li>sign &amp; store your lease </li>
                        <li>easily chat with landlords</li>
                      </ul>
                        <Button href={"/search-result"} className="btn-block mt-auto" id="BTN-tenant-start-search-home" data-description="Visitor chose tenant start search CTA">start searching</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
}

export default ToolsToHelp;

