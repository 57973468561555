import React, { useEffect, useState } from "react";
import {
    StaticGoogleMap,
    Marker,
    Path,
  } from 'react-static-google-map';
  
import { Google_Map_API } from "../../../Constants//endPoints";

const StatickMap = (props) => {
  const { height, latitude, longitude } = props;
   return (
    <div>
      <img
      style={{width:'100%'}}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=800x700&scale=1&format=png&maptype=roadmap&markers=size:normal%7Ccolor:red%7C${latitude},${longitude}&key=${Google_Map_API}`}
      />
    </div>
  );
};
export default StatickMap;
