import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { getAPI } from '../../../../../Api';
import "./style.scss";
class InformationSection extends Component {
  constructor(props) {
    super(props);
    this.LandlordHomeComponent = props.LandlordHomeComponent;
  }

  render() {

    return (
      <React.Fragment>
        <section id="letUsDoWork" className="sec-information-section">
          <div className="container">
            <div className="text-center">
              <h3 className="landing-title f-secondary-b">we love to partner with realtors 
              &amp; property managers</h3>
            </div>            

            <div className="work-box-wrap">
              <div className="row">
                <div className="col-lg-6 work-box-right mb-4">
                  <div className="work-box-inner">
                  <img className="work-box-icon" src={require("../../../../../../src/_assets/images/landing-pages/realtors-card-icon.svg")} alt="Landlord Icon" />
                    <h2 className="work-box-title text-left">realtors</h2>
                    <p>
                    Did your client just purchase an investment 
                    property? refer them our way and we will take 
                    care of them like family and send you a little 
                    something as thanks!
                    </p>
                    <Button onClick={() => this.LandlordHomeComponent.setState({ freeRentAssessment: 12, gtm_track_id: 'LandlordTBayRealtor' })} className="btn-block mt-auto" id="BTN-landlord-getintouch-realtor-llp" data-description="Get in touch landlord landing page" gtmdetails="Realtor CTA">get in touch</Button>
                  </div>
                </div>
                <div className="col-lg-6 work-box-left mb-4">
                  <div className="work-box-inner">
                  <img className="work-box-icon" src={require("../../../../../../src/_assets/images/landing-pages/property-managers-card-icon.svg")} alt="Landlord Icon" /> 
                    <h2 className="work-box-title text-left">property managers</h2>
                    <p>
                    We can save you time, money and a ton of 
                    headaches with easy to use tools and access 
                    to expert resources at your fingertips.
                    </p>
                    <Button onClick={() => this.LandlordHomeComponent.setState({ freeRentAssessment: 13, gtm_track_id: 'LandlordTBayPropertyManagement' })} className="btn-block mt-auto" id="BTN-landlord-getintouch-PM-llp" data-description="Get in touch landlord landing page" gtmdetails="Property Manager CTA">get in touch</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default InformationSection;
