import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { rentDeposite } from '../../../../../Constants/LeaseConstants'
import SingleOptionCheck from '../../../SingleOptionCheck'

const LandlordContactInfo = (props) => {

    const { contactInfo, updateState, OnSingleOptionCheck, phoneError, communicationPhoneError, OnChangeInput } = props

    return (
        <div>
            <Row>
                <Form.Group as={Col} md='4'>
                    <Form.Label>Your Full Name</Form.Label>
                    <Form.Control
                        type='text'
                        name='full_name_contact'
                        id='full_name_contact'
                        value={contactInfo?.full_name}
                        placeholder='Type here'
                        onChange={(e) => updateState(e, 'full_name')}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md='4'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type='text'
                        name='emailAddress_contact'
                        id='emailAddress_contact'
                        value={contactInfo?.email}
                        placeholder='Type here'
                        onChange={(e) => updateState(e, 'email')}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md='4'>
                    <Form.Label>Phone Number</Form.Label>
                    <NumberFormat
                        format='###-###-####'
                        mask='_'
                        placeholder='Enter Phone Number'
                        name='phone_number_contact'
                        id='phone_number_contact'
                        className={phoneError === true ? 'form-control is-invalid' : 'form-control'}
                        value={contactInfo?.phone_number ? contactInfo?.phone_number : ''}
                        onChange={(e) => OnChangeInput(e)}
                    />
                    {phoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='12'>
                    <Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
                    <div className='d-flex justify-content-flex-start align-items-center'>
                        <SingleOptionCheck
                            mapData={rentDeposite}
                            OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                            name={'agree_to_received_notice'}
                            setParam={contactInfo?.agree_to_received_notice}
                            extraClass={'d-inline-flex mb-0'}
                        />
                        {contactInfo?.agree_to_received_notice ?
                            <Form.Group as={Col} md='4' className='mb-0'>
                                <Form.Control
                                    type='text'
                                    name='agree_to_received_notices_email'
                                    id='agree_to_received_notices_email'
                                    value={contactInfo?.agree_to_received_notices_email}
                                    placeholder='name@mail.com'
                                    onChange={(e) => updateState(e,'agree_to_received_notices_email')}
                                />
                            </Form.Group> : ''}
                    </div>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md='12'>
                    <Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
                    <div className='d-flex justify-content-flex-start align-items-center'>
                        <SingleOptionCheck
                            mapData={rentDeposite}
                            OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                            name={'day_to_day_communication'}
                            setParam={contactInfo?.day_to_day_communication}
                            extraClass={'d-inline-flex mb-0'}
                        />
                        {contactInfo?.day_to_day_communication ?
                            <>
                                <Form.Group as={Col} md='4' className='mb-0'>
                                    <Form.Control
                                        type='text'
                                        name='day_to_day_communication_email'
                                        id='day_to_day_communication_email'
                                        value={contactInfo?.day_to_day_communication_email}
                                        placeholder='name@mail.com'
                                        onChange={(e) => updateState(e, 'day_to_day_communication_email')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md='4' className='mb-0'>
                                    <NumberFormat
                                        format='###-###-####'
                                        mask='_'
                                        placeholder='Enter Phone Number'
                                        name='day_to_day_communication_phone_number'
                                        id='day_to_day_communication_phone_number'
                                        className={communicationPhoneError === true ? 'form-control is-invalid' : 'form-control'}
                                        value={contactInfo?.day_to_day_communication_phone_number}
                                        onChange={(e) => OnChangeInput(e)}
                                    />
                                    {communicationPhoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
                                </Form.Group>
                            </> : ''}
                    </div>
                </Form.Group>
            </Row>
        </div>
    )
}

export default LandlordContactInfo
