import React, { Component } from "react";
import AppLayout from "../../AppLayout";
import "./style.scss";
import "../../Profile/style.scss";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import PageTitleHeader from "../../PageTitleHeader";
import { logger, toaster } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from "../../../../Api";
import { validate } from "../../../../Utils/Validation/validation";
import { EditProfileValidationSchema } from "./EditProfileValidationSchema";
import NumberFormat from "react-number-format";
import { phoneNumberSanitizer } from "../../../../Utils/NumberSanitizer/NumberSanitizer";

const log = logger("EditProfile");
class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      phone_number: "",
      email: "",
      submitted: false,
      change_email_requested: false,
      emailError: false,
      emailErrorDesc: "",
      phoneErrorDesc: "",
      phoneError: "",
    };
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  onFormChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  onChangeInput = (event) => {
    const change = phoneNumberSanitizer(event.target.value);
    if (isNaN(Number(change))) {
      this.setState({
        phoneError: true
      });
    } else {
      this.setState({
        phoneError: false
      });
    }
    this.setState({
      phone_number: change,
    });
  }

  componentDidMount(props) {
    const url = '/edit-profile';
    getAPI(url).then(response => {
      log.info(response.data.data.phone);
      this.setState({
        firstName: response?.data?.data?.first_name,
        lastName: response?.data?.data?.last_name,
        phone_number: response?.data?.data?.phone,
        email: response?.data?.data?.email,
      });
    })
      .catch(error => {
      });
  }

  onSubmitClick(event) {
    event.preventDefault();
    if (validate(EditProfileValidationSchema) && !this.state.phoneError) {
      const url = '/update-profile';
      const data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone_number: phoneNumberSanitizer(this.state.phone_number),
        email: this.state.email
      }
      postAPI(url, data).then(response => {
        if (response?.response?.status === 422) {
          const errorObject = response?.response?.data?.errors
          if (errorObject?.email?.length) {
            this.setState({ emailErrorDesc: errorObject?.email[0], emailError: true })
          }
          if (errorObject?.phone_number?.length) {
            this.setState({ phoneErrorDesc: errorObject?.phone_number[0], phoneError: true })
          }
        } else if (response?.status === 200) {
          const res = response.data.data
          this.setState({
            change_email_requested: res?.email_verification_required
          })
          if (res?.email_verification_required) {
            this.props.history.push({
              pathname: "/authenticate", state: { data: this.state.email, flow: 'authentication' }
            })
            toaster('success', 'Verification PIN Sent Successfully!')
          } else {
            this.props.history.push("/profile")
            toaster('success', 'Profile updated Successfully!')
          }
        }
      })
    } else {
      this.state.phoneError === true && toaster('error', 'Please provide correct phone number!');
      this.setState({ phone_number: this.state.phone_number })
    }
  }

  goBack = () => {
    this.props.history.push('/profile')
  }

  render() {
    const { firstName, lastName, email, phone_number, submitted, phoneErrorDesc, emailErrorDesc, phoneError, emailError } = this.state;

    if (submitted) {
      return <Redirect to={"/profile"} />;
    }

    return (
      <>
        <AppLayout>
          <div className="profile">
            <PageTitleHeader
              title='edit profile'

              isArrow={true}
              onClick={this.goBack}
            />
            <div className="profile-container">
              <div className="profile-container-box">
                <div className="inner-wrapper">
                  <div className="profile-item" >
                    <div className="image-wrapper">
                      <img
                        width="67.08"
                        height="69.99"
                        alt="edit-icon"
                        src={require("../../../../_assets/images/a-edit.png")}
                        className="d-inline-block align-top"
                      />
                      <span className="bl-img-title navbar-brand-name">
                        Edit Profile
                      </span>
                    </div>

                    <Form className="profile-form" id="Form">
                      <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          className=" bg-pf-ip profile-input"
                          type="string"
                          id="firstName"
                          name="firstName"
                          placeholder="First name"
                          onChange={this.onFormChange}
                          value={firstName}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          className=" bg-pf-ip profile-input"
                          type="string"
                          id="lastName"
                          name="lastName"
                          placeholder="Last name"
                          onChange={this.onFormChange}
                          value={lastName}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Phone</Form.Label>
                        <NumberFormat
                          format="###-###-####"
                          mask="_"
                          className={this.state.phoneError === true ? 'form-control is-invalid2 bg-pf-ip profile-input' : 'form-control bg-pf-ip profile-input'}
                          type="phone"
                          id="phone_number"
                          name="phone_number"
                          placeholder="Input Here"
                          onChange={this.onChangeInput}
                          value={phone_number}
                        />
                        {phoneError && <div id="error_phone_num" className="invalid-feedback2">{phoneErrorDesc}</div>}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          className="  bg-pf-ip profile-input"
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Input Here"
                          onChange={this.onFormChange}
                          value={email}
                          // disabled
                        />
                        {emailError && <div id="error_email" className="invalid-feedback2">{emailErrorDesc}</div>}
                      </Form.Group>

                      <div className="button-wrapper">
                        <LargeButton
                          title="save changes"
                          onClick={(e) => this.onSubmitClick(e)} />

                        <LargeButton
                          variant="secondary"
                          title="cancel"
                          onClick={this.goBack} />
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppLayout>
      </>
    );
  }
}

export default withRouter(EditProfile);
