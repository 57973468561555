import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, NavDropdown, Image } from "react-bootstrap";
import { AppLayout, PageTitleHeader, PropertyCard, Loader } from "../../components";
import { withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
import Bed from '../../../_assets/images/bed.svg';
import Bath from '../../../_assets/images/bath.svg';
import Home from '../../../_assets/images/home.svg';
import { Helmet } from "react-helmet";

const log = logger("FavouritesList");
const FavouritesList = (props) => {
	const [propertyLists, setPropertyLists] = useState(null);
	const [activeKey, setActiveKey] = useState('new_to_old');

	useEffect(() => {
		const url = '/tenant/favorite-list';
		getData(url);
	}, [props])

	const getData = (url) => {
		getAPI(url).then(response => {
			const res = response.data.data;
			log.info(res);
			setPropertyLists(res)
		})
		.catch(error => {
		});
	}

	const propertyDetails = (details) => {
		props.history.push({
			pathname: '/property-details/' + details.property_id + '/' + details.property_details_id + '/details',
			state: { backPath: props?.history?.location?.pathname }
		});
	}

	const formattedDetails = (data) => {
		const {
		  unit_no,
		  bedroom,
		  price_per_month,
		  property_type,
		  is_shared_bathroom,
		  bathroom
		} = data;
		const bedRoom =
		  bedroom &&
		  (parseInt(bedroom) > 1 ? bedroom + " Bedrooms" : bedroom + " Bedroom");
		const price = price_per_month && `$${price_per_month}/Month`;
		const propertyTypes= {
		  1 : 'Full Property',
		  2 : 'Unit',
		  3 : 'Room for Rent'
		}
		return (
		  <div style={{ display: "flex" , fontSize:16,fontWeight:500,color:'black'}}>
			{!! bedroom &&<div style={{ display: "flex" }}>
			  <Image src={Bed}/>
			  &nbsp;
			  <span>{bedroom} Bed</span>
			</div>}
		   {!!bathroom && <div style={{ display: "flex" ,marginLeft:20}}>
		   <Image src={Bath}/>
			  &nbsp;
			  <span>{bathroom} Bath</span>
			</div>}
			<div style={{ display: "flex" ,marginLeft:!!bathroom ? 15 : 0}}>
			<Image src={Home}/>
			  &nbsp;
			  <span>{propertyTypes[property_type]}</span>
			</div>
		  </div>
		);
	  }

	const handleDropdownSelect = (key) => {
		setActiveKey(key);
		let url = '/tenant/favorite-list?sort_by=post_date&sort_order=' + key;
		if (key === 'low_to_high' || key === 'high_to_low') {
			url = '/tenant/favorite-list?sort_by=price&sort_order=' + key;
		}
		getData(url)
	}

	return (
		<AppLayout>
			<Helmet>
				<title>Dashboard | My Favourites</title>
			</Helmet>
			<div className="profile">
				<PageTitleHeader
					title='favourite listings'

				/>
			</div>
			<div className="profile-container">
				<div className="profile-container-box">
					<div
						style={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							zIndex: "20",
							padding: "0 5px",
							marginBottom: '16px'
						}}
					>
						<Navbar className="filter-nav">
							<Navbar.Collapse className="d-flex justify-content-between">
								<Navbar.Text>posted date:</Navbar.Text>
								<NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('low_to_high')}>price: low to high</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('high_to_low')}>price: high to low</NavDropdown.Item>
								</NavDropdown>
							</Navbar.Collapse>
						</Navbar>
					</div>

					<Container fluid>
						<Row>
							{propertyLists ?
								propertyLists.length > 0 ?
									propertyLists.map((list, i) => {
										return (
											<PropertyCard
												key={"propery_" + i}
												onFavouriteChange={(isFavourite, id) => {
													setPropertyLists(propertyLists.filter((i) => i.property_details_id !== id))
												}}
												id={list.property_details_id}
												price={list.price_per_month}
												image_url={list.image_url}
												isVerifed={list.is_verified}
												title={list.address}
												details={formattedDetails(list)}
												onClick={() => propertyDetails(list)}
												isLoveIcon={true}
												isFavListPage={true}
												{...list}
											/>
										)
									}) :
									<Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '0 0 1rem' }}>
										<Image src={require("../../../_assets/images/RP-Illustration.svg")} style={{ marginBottom: '1.5rem', maxHeight: '50vh', maxWidth: '60vw' }} />
										<p style={{ fontSize: '25px', color: '#979797' }}>You’ve Got No Favourite Places. </p>
									</Col>
								: <Loader />}

						</Row>
					</Container>
				</div>
			</div>
		</AppLayout>

	);
}

export default withRouter(FavouritesList);