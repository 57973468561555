import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { activeCMSFilter, cmsClickedData, listManagemantPagination } from '../../../../Store/actions/Action';
import { ModalBox } from '../../../components';
import ContentManagementTable from '../ContentManagementTable';
import { useDispatch, useSelector } from 'react-redux';
import TableContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader';

const CMS = (props) => {
    const { componentLoad } = props;
    const [tableData, setTableData] = useState([]);
    const [sort_by_date, setSortByDate] = useState('all');
    const [sortDateState, setSortDateState] = useState('View All');
    const [sort_by_type, setSortByType] = useState(['all']);
    const dispatch = useDispatch();
    const pagination = useSelector(state => state.propertyDetailsReducer.previousPageListMan);
    const activeCMSFilterStatus = useSelector(state => state.CMSReducer.activeCMSFilterStatus);
    const [sort_by_status, setStatus] = useState(activeCMSFilterStatus !== null ? activeCMSFilterStatus : ['all']);
    const [isLoading, setIsLoading] = useState(true);
    const [searchName, setSearchName] = useState('');
    const [searchCategory, setSeachCategory] = useState('');
    const [searchCreator, setSearchCreator] = useState('');
    const [element2Delete, setElement2Delete] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);

    let filterData = {
        "name": searchName,
        "created_date": sort_by_date,
        "content_type": sort_by_type.includes('all') ? [] : sort_by_type,
        "category": searchCategory,
        "creator": searchCreator,
        "status": sort_by_status.includes('all') ? [] : sort_by_status,
    }

    const sortContentType = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let arraydata = [...sort_by_type]
        let returnVal = [];
        if (item === 'all') {
            let arr = []
            if (isChecked === true) {
                arr = ['all']
            } else {
                arr = []
            }
            returnVal = arr;
        } else if (item !== 'all') {
            isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
            if (arraydata.includes('all')) {
                const indexElm = arraydata.indexOf('all');
                arraydata.splice(indexElm, 1);
            }
            returnVal = arraydata;
        }
        setSortByType(returnVal)
    }

    const sortStatus = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let arraydata = [...sort_by_status]
        let returnVal = [];
        if (item === 'all') {
            let arr = []
            if (isChecked === true) {
                arr = ['all']
            } else {
                arr = []
            }
            returnVal = arr;
        } else if (item !== 'all') {
            isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
            if (arraydata.includes('all')) {
                const indexElm = arraydata.indexOf('all');
                arraydata.splice(indexElm, 1);
            }
            returnVal = arraydata;
        }
        setStatus(returnVal)
    }
    const sortDate = (event) => {
        setSortByDate(event.target.dataset.value)
        setSortDateState(event.target.dataset.display)
    }

    const previewPage = (data) => {
        if (data.content_type === 'FAQ') {
            props.history.push('/preview-faq')
        } else if (data.content_type === 'Article') {
            props.history.push({
                pathname: `/cms/preview-blog/${data.id}`,
                state: { path: componentLoad }
            });
            dispatch(activeCMSFilter(sort_by_status));
        } else if (data.content_type === 'Partner') {
            props.history.push(`/preview-partner/${data.id}`);
        } else if (data.content_type === 'Form') {
            dispatch(cmsClickedData(data))
            props.setPreviewForm(true);
        }
        
    }
    
    const editArticle = (data) => {
        props.history.push({
            pathname: `/cms/blog/${data.id}`,
            state: { path: componentLoad }
        });
        dispatch(activeCMSFilter(sort_by_status));
    }
    const confirmDeleteModal = (elem) => {
        setElement2Delete(elem)
        setDeleteModal(true);
    }
    const onClickYes = () => {
        const data = {
            'id': element2Delete.id
        }
        let url = '';
        if (element2Delete.content_type === 'FAQ') {
            url = '/admin/cms/faq/delete'
        } else if (element2Delete.content_type === 'Article') {
            url = '/admin/cms/articles/delete'
        } else if (element2Delete.content_type === 'Partner') {
            url = '/admin/cms/partners/delete'
        } else if (element2Delete.content_type === 'Form') {
            url = '/admin/cms/forms/delete'
        }
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                setDeleteModal(false);
                setConfirmAction(true)
            }
        })
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }

    const goToPageDetail = (data) => {
        dispatch(cmsClickedData(data))
        if (data.content_type === 'FAQ') {
            props.history.push('/create-faq')
        } else if (data.content_type === 'Article') {
            props.history.push('/cms/blog/edit');
        } else if (data.content_type === 'Partner') {
            props.history.push('/cms/partners/edit');
        } else if (data.content_type === 'Form') {
            props.history.push('/cms/forms/edit');
        }
    }
    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        dispatch(listManagemantPagination(nextPage));
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        dispatch(listManagemantPagination(prevPage));
    }

    useEffect(() => {
        let url = '/admin/cms/list';
        let urlInside = pagination !== null ? `/admin/cms/list?page=${pagination}` : url;
        
        postAPI(urlInside, filterData).then(response => {
            if (response.status === 200) {
                setIsLoading(false)
                setTableData(response.data.data);
            }
        })
        if (sort_by_status.length === 0) {
            setStatus(['all'])
        }
        if (sort_by_type.length === 0) {
            setSortByType(['all'])
        }
        if (sort_by_date.length === 0) {
            setSortByDate('all')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, sort_by_type, sort_by_status, sort_by_date, searchCreator, searchName, searchCategory, confirmAction]);

    let userColumns = [];

    userColumns = [
        {
            header: 'Name',
            name: 'name',
            width: '15%'
        },
        {
            header: 'Created Date',
            name: 'created_date',
            width: '15%'
        },
        {
            header: 'Content Type',
            name: 'content_type',
            width: '15%'
        },
        {
            header: 'Category',
            name: 'category',
            width: '15%'
        },
        {
            header: 'Creator',
            name: 'creator',
            width: '15%'
        },
        {
            header: 'Status',
            name: 'status',
            width: '15%'
        },
        {
            header: '',
            name: 'actions',
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'listingManagement'} /> : <>
                {tableData.data ?
                    <ContentManagementTable
                        columns={userColumns}
                        tableData={tableData.data}
                        to={tableData.to}
                        from={tableData.from}
                        current_page={tableData.current_page}
                        last_page={tableData.last_page}
                        per_page={tableData.per_page}
                        total={tableData.total}
                        sort_by_status={sort_by_status}
                        sortStatus={(data) => sortStatus(data)}
                        searchCreator={searchCreator}
                        setSearchCreator={(data) => setSearchCreator(data)}
                        searchName={searchName}
                        setSearchName={(data) => setSearchName(data)}
                        searchCategory={searchCategory}
                        setSeachCategory={(data) => setSeachCategory(data)}
                        sort_by_date={sort_by_date}
                        sortDateState={sortDateState}
                        sortDate={(data) => sortDate(data)}
                        sort_by_type={sort_by_type}
                        sortContentType={(data) => sortContentType(data)}
                        editArticle={(data) => editArticle(data)}
                        previewPage={(data) => previewPage(data)}
                        confirmDeleteModal={(elemId) => confirmDeleteModal(elemId)}
                        clickNext={() => onClickNext(tableData.current_page)}
                        clickPrev={() => onClickPrev(tableData.current_page)}
                        componentLoad={componentLoad}
                        goToPageDetail={(data) => goToPageDetail(data)}
                    /> : ''}
            </>}
            
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={'Not now'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    Are you sure you want to delete content? This cannot<br /> be undo.
                    {/* {element2Delete.content_type}? */}
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => setConfirmAction(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => setConfirmAction(false)}
            >
                <h4>{element2Delete.content_type} has been deleted.</h4>
            </ModalBox>

        </React.Fragment>
    )
};

export default withRouter(CMS);