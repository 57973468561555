import {
    SET_COMPONENT,
    SET_FILTERS,
    SET_LISTING_CURRENT_PAGE,
    SET_RENTED_CURRENT_PAGE,
    SET_PROMOTED_CURRENT_PAGE,
    SET_INACTIVE_CURRENT_PAGE,
    SET_LISTING_TABLEDATA,
    SET_RENTED_TABLEDATA,
    SET_PROMOTED_TABLEDATA,
    SET_INACTIVE_TABLEDATA,
} from '../actions/Action';

export const initialState = {
    currentComponent: '',
    listingCurrentPage: null,
    rentedCurrentPage: null,
    promotedCurrentPage: null,
    inactiveCurrentPage: null,
    listingTableData: [],
    rentedTableData: [],
    promotedTableData: [],
    inactiveTableData: [],
    filters: {
        searchCity: '',
        searchAddress: '',
        searchLandlord: '',
        searchTenant: '',
        searchRentpandaId: '',
        is_verified: false,
        sort_by_price_order: '',
        sort_by_status: ['all'],
        filterByExpiryDate: '',
        filterByUpdatedDate:'',
    }

}

export default function ListingReducer(state = initialState, action) {
    const handlers = {
        [SET_COMPONENT]: (state) => ({
            ...state,
            currentComponent: action.currentComponent,
        }),
        [SET_FILTERS]: (state) => ({
            ...state,
            filters: action.filters,
        }),
        [SET_LISTING_CURRENT_PAGE]: (state) => ({
            ...state,
            listingCurrentPage: action.listingCurrentPage,
        }),
        [SET_RENTED_CURRENT_PAGE]: (state) => ({
            ...state,
            rentedCurrentPage: action.rentedCurrentPage,
        }),
        [SET_PROMOTED_CURRENT_PAGE]: (state) => ({
            ...state,
            promotedCurrentPage: action.promotedCurrentPage,
        }),
        [SET_INACTIVE_CURRENT_PAGE]: (state) => ({
            ...state,
            inactiveCurrentPage: action.inactiveCurrentPage,
        }),
        [SET_LISTING_TABLEDATA]: (state) => ({
            ...state,
            listingTableData: action.listingTableData,
        }),
        [SET_RENTED_TABLEDATA]: (state) => ({
            ...state,
            rentedTableData: action.rentedTableData,
        }),
        [SET_PROMOTED_TABLEDATA]: (state) => ({
            ...state,
            promotedTableData: action.promotedTableData,
        }),
        [SET_INACTIVE_TABLEDATA]: (state) => ({
            ...state,
            inactiveTableData: action.inactiveTableData,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}