import React from 'react';
import "./style.scss"
import useWindowDimensions from "../../../App/hooks/WindowSize";

// logos
import logo_apollo from "../../../_assets/images/new_homepage/logos/apollo.svg"
import logo_borrowell from "../../../_assets/images/new_homepage/logos/borrowell.webp"
import logo_durham from "../../../_assets/images/new_homepage/logos/durham.jpeg"
import logo_iwinrealestate from "../../../_assets/images/new_homepage/logos/iwinrealestate.webp"
import logo_midterm from "../../../_assets/images/new_homepage/logos/midterm.png"
import logo_rockstar from "../../../_assets/images/new_homepage/logos/rockstarrealestate.png"
import logo_singlekey from "../../../_assets/images/new_homepage/logos/singlekey.png"
import logo_smarthomechoice from "../../../_assets/images/new_homepage/logos/smarthomechoice.jpg"
import logo_solo from "../../../_assets/images/new_homepage/logos/solo.png"
import logo_verifast from "../../../_assets/images/new_homepage/logos/verifast.png"
import logo_wealthgenius from "../../../_assets/images/new_homepage/logos/wealthgenius.png"
import logo_wealthhacker from "../../../_assets/images/new_homepage/logos/wealthhacker.webp"

const TrustedBySection = () => {
    const { height, width } = useWindowDimensions();
    const logoType = 'trustedByLogo';
    const logos = [
      logo_durham,
      logo_wealthgenius,
      logo_solo,
      logo_rockstar,
      logo_iwinrealestate,
      logo_smarthomechoice,
      logo_wealthhacker,
      logo_singlekey,
      logo_verifast,
      logo_apollo,
      logo_borrowell,
      logo_midterm,
    ]
  
    return (
          <div className="trustedBySection">
            <div className="trustedByHeader">trusted by the best in the business</div>
        
            <div className="trustedByLogoContainer">
              <ul className="trustedByLogos">
                {logos.map((data, i) =>
                  <li data-logo-id={i}>
                    <div className={`${logoType+(i + 1)}`}
                    style={{  
                        backgroundImage: "url(" + data + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                        }}
                    ></div>
                  </li>
                )}
              </ul>
            </div>
          </div>
    );
};

export default TrustedBySection;