import React, { useState } from "react";
import { postAPI } from "../../../../../Api";
import { logger } from "../../../../../Utils";
import { LargeButton, CancelButton, ModalBox, SidePanel } from "../../../../components";
import { Col, Row, Form, Image, Button } from "react-bootstrap";
import { AddingTenantSchema } from "./Schema/AddingTenantSchema";
import { validate } from "../../../../../Utils/Validation/validation";
import { withRouter } from "react-router-dom";

const log = logger("ReasonFour");
const ReasonFour = (props) => {
    const { isShow, handleClose, property_details_id, apiCallback, openReasonFour, openCreateLeaseOption, setOpenDeactivate, singleReasonData, setTenantList } = props;

    const [tenantAdded, setTenantAdded] = useState([
        {
            tenantFullName: "", tenantEmailId: ""
        }
    ]);
    const [skipModal, setSkipOpen] = useState(false);

    const OnChangeInput = (event, index) => {
        log.info(event.target.value);
        let items = [...tenantAdded];
        let item = { ...items[index] };
        const name = event.target.name ? event.target.name : event.target.getAttribute('name')
        item[name] = event.target.value;
        items[index] = item;
        setTenantAdded(items);
    }
    const addMore = () => {
        const data = { tenantFullName: "", tenantEmailId: "" }
        setTenantAdded(tenantAdded => [...tenantAdded, data])
    }
    const OnRemove = (index) => {
        let items = [...tenantAdded];
        items.splice(index, 1);
        setTenantAdded(items);
    }
    const addAndInvite = () => {
        if (validate(AddingTenantSchema)) {
            const url = "/admin/listing/lease/add-tenant";
            const data = [];
            tenantAdded.map((tenant) => {
                const newData = {
                    name: tenant.tenantFullName,
                    email: tenant.tenantEmailId
                }
                data.push(newData);
                return tenant;
            })
            const tenant = {
                "tenants": data
            }
            postAPI(url, tenant).then(res => {
                if (res.status === 200) {
                    setTenantList(res.data.data)
                    handleClose();
                    openCreateLeaseOption(true)
                }
            })
        }
    }
    const handleCloseConfirm = () => {
        handleClose();
        setOpenDeactivate(true);
    }
    const openSkipModal = () => {
        setSkipOpen(true)
        handleClose();
    }
    const onClickYes = () => {
        // openCreateLeaseOption(true)
        const url = "/admin/listing/deactive/" + property_details_id;
        const data = {
            "deactivate_reason": singleReasonData && singleReasonData.order,
            "deactivate_description": singleReasonData && singleReasonData.deactive_reason
        }
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                apiCallback();
                setSkipOpen(false);
            }
        })
    }
    const onClickNo = () => {
        openReasonFour(true)
        setSkipOpen(false)
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose("callBackApi")}
            extraClasses='reason-four'
            title="register tenants">
            <h4 className="edit-sub">Your landlord’s tenants will be submitted an invitation
to join Rent Panda</h4>
            <div className="deactive-reasons sidepanel-body">
                <Form id="Form">
                    {tenantAdded ?
                        tenantAdded.map((data, i) => {
                            return (
                                <div key={'tenantAdded' + i} className={"tenantAdded" + i}>
                                    <Row>
                                        <Form.Group as={Col} md="12">
                                            <div className="display-flex-inline-elm">
                                                <Form.Label className='reason-lable'>Tenant’s Full Name</Form.Label>
                                                {i !== 0 &&
                                                    <div className='sub-title d-flex justify-content-between align-items-center pt-0'>
                                                        <Image
                                                            className="icon cursorPointer"
                                                            width="20px"
                                                            height="20px"
                                                            title="Remove"
                                                            onClick={() => OnRemove(i)}
                                                            src={require("../../../../../_assets/images/delete-icon.svg")}
                                                        />
                                                    </div>}
                                            </div>
                                            <Form.Control
                                                type="text"
                                                placeholder="John Doe"
                                                name="tenantFullName"
                                                id={"tenantFullName" + i}
                                                value={data.tenantFullName}
                                                className='reason-placeholder'
                                                onChange={(e) => OnChangeInput(e, i)} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label className='reason-lable'>Tenant’s Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="name@mail.com"
                                                name="tenantEmailId"
                                                id={"tenantEmailId" + i}
                                                value={data.tenantEmailId}
                                                className='reason-placeholder'
                                                onChange={(e) => OnChangeInput(e, i)} />
                                        </Form.Group>
                                    </Row>
                                    <hr />
                                </div>)
                        }) : ''}
                </Form>
                <Row>
                    <Col md='12'>
                        <Button onClick={() => addMore()} type="button" className="pull-right add-more">+ Add More</Button>
                    </Col>
                </Row>

            </div>
            <div>
                <div className='fixed-bottom reason-four'>
                    <div className="skip-link mb-5">
                        <Button onClick={() => openSkipModal()} type="button" className="skip-btn">Skip</Button>
                    </div>
                    <div className="d-flex justify-content-between">
                        <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                        <LargeButton title='next' onClick={() => addAndInvite()} style={{ width: '200px' }} />
                    </div>
                </div>

            </div>

        </SidePanel>
        <ModalBox
            show={skipModal}
            closeButtonHeader={false}
            size="sm"
            type="condition"
            actionbuttontitle={"Yes"}
            buttonaction={() => onClickYes()}
            backbuttontitle={'Cancel'}
            backaction={() => onClickNo()}
        >
            <h4 className='modal-skip-heading'>are you sure you want to skip?</h4>
            <p className="order-one-modal">you won’t get access to the management tool where you can collect rent, serve notices, record repairs and more!</p>
        </ModalBox>
    </>);
}
export default withRouter(ReasonFour);