export const RentedHomeValidationSchema = {
    current_form_address_2: [
        { rule: "required", message: "Current address required" },
    ],
    current_form_start_date: [
        { rule: "required", message: "Start date required" },
    ],
  
    monthly_rent_current: [
        { rule: "required", message: "Monthly rent required" },
    ],
    landlord_name_current: [
        { rule: "required", message: "Landlord name required" },
    ],
}