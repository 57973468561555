import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster } from '../../../../Utils';
import { getAPI, postAPI } from '../../../../Api';
import { SingleOptionCheck, LargeButton, ModalBox } from '../../../components';
import { rentDeposite } from './LeaseConstants';
import history from '../../../../history';
// import { validate } from '../../../../Utils/Validation/validation';
// import { TenantDetailSchema } from './CreateLeaseSchema/TenantDetailSchema';
import LeaseTenantDetailLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/LeaseTenantDetailLoader/LeaseTenantDetailLoader';
import { Prompt } from 'react-router';


const CreateLeaseTenantDetails = (props) => {
	const { callAddTenant, apiCall } = props;
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState('');
	const [user_id, setUserId] = useState('');
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const [addedTenantsData, setAddedTenantData] = useState([]);
	const [reasondata] = useState(null);
	const [required_tenant_insurance, setTenantInssurance] = useState(0);
	const [saveLoading, setBtnDisable] = useState(false);
	const [leaseData, setCompleteLeaseData] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [saveClick, setSaveClick] = useState(false);
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

	const backToPrevPage = () => {
		props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/lease-details/${lease_id}` });
		history.go(0);
	}
	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'required_tenant_insurance') {
			setTenantInssurance(Value);
		}
		setShouldBlockNavigation(true);
	}
	const addtenant = () => {
		callAddTenant(true);
		setShouldBlockNavigation(true);
	}
	const OnRemove = (index, data) => {
		let items = [...addedTenantsData];
		items.splice(index, 1);
		setAddedTenantData(items);
		setShouldBlockNavigation(true);
	}
	const onSubmit = (event) => {
		event.preventDefault();
		// if (validate(TenantDetailSchema)) {
		if (addedTenantsData.length !== 0) {
			setBtnDisable(true)
			const tenant_data = [];
			addedTenantsData.map((tenants) => {
				let tenant = null;
				if (tenants.tenant_id) {
					tenant = { 'id': tenants.id, 'tenant_id': tenants.tenant_id }
				} else {
					tenant = { 'id': tenants.id, 'tenant_id': 0 }
				}
				tenant_data.push(tenant);
				return tenants;
			})
			const data = {
				'lease_id': lease_id,
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'required_tenant_insurance': required_tenant_insurance,
				'tenants': addedTenantsData,
			}
			const url = '/admin/listing/lease/tenant-details';
			postAPI(url, data).then(res => {
				setBtnDisable(false)
				if (res.status === 200) {
					setShouldBlockNavigation(true);
					toaster('success', 'Data Saved Successfully!');
					if (saveState === 'next') {
						props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/complete-lease-details/${lease_id}`, state: { singleReasonData: reasondata } });
						history.go(0);
					} else if (saveState === 'update') {
						setLease_updated(true);
					}
				} else if (res.response.status === 400) {
					toaster('error', res.response.data.message);
				} else if (res.response.status === 422) {
					toaster('error', res.response.data.message);
				}
			})
		} else {
			toaster('error', 'Please add tenant!')
		}

		// }
	}
	const redirectLast = () => {
		setLease_updated(false)
		props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/complete-lease-details/${lease_id}` });
	}

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setLeaseId(props.match.params.lease_id);
		setUserId(props.match.params.user_id);
	}, [
		props.match.params.property_id,
		props.match.params.property_details_id,
		props.match.params.lease_id,
		props.match.params.user_id,
	]);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const handleClickInside = (option) => {
		setIsPopoverOpen(option === 'yes' ? true : false);
	}
	useEffect(() => {
		if ((property_details_id && lease_id) || apiCall === true) {
			setLoading(true)
			const url = `/admin/listing/lease/get-lease-details/${property_details_id}/${lease_id}`
			getAPI(url).then(res => {
				setLoading(false)
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setCompleteLeaseData(fetchedData);
					if (fetchedData && fetchedData.tenant_details) {
						setTenantInssurance(fetchedData.tenant_details.required_tenant_insurance);
						setAddedTenantData(fetchedData.tenant_details.tenants.length !== 0 ? fetchedData.tenant_details.tenants : [])
					}
				}
			})
		}
	}, [property_details_id, lease_id, apiCall]);
	return (isLoading === true ? <LeaseTenantDetailLoader /> : <>
		<Prompt
			when={shouldBlockNavigation}
			message="your information will be lost do you want to leave this page?"
		/>
		<Form id='Form' onSubmit={onSubmit} className='higher-level-lease'>
			<div className='inner-wrapper'>
				<div className='body-container'>
					<div className='body-header'>
						<h3 className='text-left lease-heading'>tenant details</h3>
						{leaseData && leaseData.is_sent_to_tenant ?
							<button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => { setSaveState('update'); setSaveClick(true); }}>Save</button>
							: <button className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => { setSaveState(''); setSaveClick(true); }}>Save</button>}
					</div>
					<Row>

						<Form.Group as={Col} md='12'>
							<Form.Label>Add tenant(s)</Form.Label>
							<ul className='select-options'>
								{addedTenantsData.map((data, i) =>
									<li key={'tenant_' + data.id} value={data.id} name='tenants' className={'active'}>
										<div className="display-flex-inline-elm">
											{data.name}
											<div className='sub-title d-flex justify-content-between align-items-center pt-0'>
												<Image
													className="icon cursorPointer ml-3"
													width="14px"
													height="15px"
													title="Remove"
													onClick={() => OnRemove(i, data)}
													src={require("../../../../_assets/images/delete.svg")}
												/>
											</div>
										</div>
									</li>)
								}
								<li className="list-btn">
									<LargeButton isIcon={true} title='Add Tenant Info' variant='dark' onClick={() => addtenant()} style={{ display: 'block', width: "335px", padding: "0px", textTransform: 'lowercase', padding: '12px' }} className="mobilebtn" />
								</li>
							</ul>

						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='6'>
							<div className='d-flex'>
								<Form.Label className='m-0'>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
								<span className='warning-popover'>
									<img className='cursorPointer ml-2 mb-1'
										onMouseEnter={() => handleClickInside('yes')}
										onMouseLeave={() => handleClickInside('no')}
										src={require('../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
									{(isPopoverOpen === true) && <div className="custom-popover extra">
										<p className='popover-content'>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute</p>
									</div>}
								</span>
							</div>


							<SingleOptionCheck
								mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'required_tenant_insurance'} setParam={required_tenant_insurance}
							/>
						</Form.Group>
					</Row>
				</div>
			</div>

			<div className='container-footer'>
				<Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
				{leaseData && leaseData.is_sent_to_tenant ?
					<Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('update')}>Update {saveLoading === true && saveClick === false && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
					: <Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('next')}>Next {saveLoading === true && saveClick === false && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
				}
			</div>
		</Form>
		<ModalBox
			show={is_lease_updated}
			onHide={() => redirectLast()}
			size="sm"
			actionbuttontitle={"got it"}
			buttonaction={() => redirectLast()}
		>
			<h4>your lease has been updated</h4>
			<p>the tenant has been <br /> notified of the changes. </p>
		</ModalBox>
	</>);
}

export default withRouter(CreateLeaseTenantDetails);
