import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { phoneNumberSanitizer } from '../../../Utils/NumberSanitizer/NumberSanitizer';
import { ModalBox } from '../../../App/components';
import { validate } from '../../../Utils';
import { postAPI } from '../../../Api';
import { ForRealtorsSchema } from './ForRealtorsSchema';

const FormForRealtors = () => {

	const [first_name, setFirstName] = useState('');
	const [owner_first_name, setOwnerFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [owner_last_name, setOwnerLastName] = useState('');
    const [email, setEmail] = useState('');
    const [owner_email, setOwnerEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [owner_phone_number, setOwnerPhoneNumber] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
	const [formSubmitPop, setFormSubmitPop] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        if (validate(ForRealtorsSchema)) {
			const url = "/cms/save-realtor-partner";
            const data = {
                "first_name": first_name,
                "last_name": last_name,
                "phone_number": phone_number,
                "email": email,
                "owner_first_name": owner_first_name,
                "owner_last_name": owner_last_name,
                "owner_phone_number": owner_phone_number,
                "owner_email": owner_email,
            }
            setSaveLoading(true);
            postAPI(url, data).then(response => {
                setSaveLoading(false);
                setFormSubmitPop(true);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setOwnerFirstName('');
                setOwnerLastName('');
                setOwnerEmail('');
                setOwnerPhoneNumber('');
            })
        }
    }

    return (
        <>
            <Container className="form-container">
                <h2 className="subHead">send a referral with the form below and we will get in touch!</h2>
                <div className="col-md-12 col-lg-8 col-xl-7">
                    <Card>
                        <h2 className="formTitle">Your Info</h2>
                        <Form id="Form" autoComplete="off">
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="First Name"
                                        name={'first_name'}
                                        id={'first_name'}
                                        value={first_name}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="Last Name"
                                        name={'last_name'}
                                        id={'last_name'}
                                        value={last_name}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="Email"
                                        name='email'
                                        id='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Col>
                                <Col md={4} className="mb-3">
                                    <NumberFormat
                                        placeholder="Phone Number"
                                        format='###-###-####'
                                        mask='_'
                                        name='phone_number'
                                        id='phone_number'
                                        className={'form-control'}
                                        value={phone_number ? phone_number : ''}
                                        onChange={(e) => setPhoneNumber(phoneNumberSanitizer(e.target.value))}
                                    />
                                </Col>
                            </Row>

                            <h2 className="formTitle formTitle-two">Property Owner Info</h2>
                            
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="First Name"
                                        name={'owner_first_name'}
                                        id={'owner_first_name'}
                                        value={owner_first_name}
                                        onChange={(e) => setOwnerFirstName(e.target.value)}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="Last Name"
                                        name={'owner_last_name'}
                                        id={'owner_last_name'}
                                        value={owner_last_name}
                                        onChange={(e) => setOwnerLastName(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder="Email"
                                        name='owner_email'
                                        id='owner_email'
                                        value={owner_email}
                                        onChange={(e) => setOwnerEmail(e.target.value)}
                                    />
                                </Col>
                                <Col md={4} className="mb-3">
                                    <NumberFormat
                                        placeholder="Phone Number"
                                        format='###-###-####'
                                        mask='_'
                                        name='owner_phone_number'
                                        id='owner_phone_number'
                                        className={'form-control'}
                                        value={owner_phone_number ? owner_phone_number : ''}
                                        onChange={(e) => setOwnerPhoneNumber(phoneNumberSanitizer(e.target.value))}
                                    />
                                </Col>
                            </Row>

                            <em className="formTitle-declaration mb-0">We will not call or email your client until speaking with you first.</em>

                            <Button className='btnFormAction' type='submit'
                                onClick={(e) => onSubmit(e)} disabled={saveLoading === true ? true : false}>
                                submit referral
                                {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
                            </Button>
                        </Form>
                    </Card>
                </div>
            </Container>
            <ModalBox
                show={formSubmitPop}
                onHide={() => setFormSubmitPop(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => setFormSubmitPop(false)}
            >
                <h4>Your request has been submitted successfully!</h4>
            </ModalBox>
        </>
    )
}

export default withRouter(FormForRealtors);
