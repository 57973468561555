import React from "react";
import EditButton from "../../Buttons/EditButton";
import { Image } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style.scss';
import { useSelector } from "react-redux";

const UnitDetailsImageCarousel = (props) => {
  const { editDetails } = props;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const images = (unitDetails ? unitDetails.images : [])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  };

  return (
    <div className={(images && images.length !== 0) ? "caurousel-struct" : "inner-wrapper"} style={{ width: "100%", height: "298px" }}>
      <div className='button-wrapper'>
        <EditButton onClick={editDetails} />
      </div>
      {images && images.length === 0 && <h4 className="text-center">No images uploaded yet</h4>}
      <Carousel
        responsive={responsive}
        infinite={true}
        swipeable={true}
        showDots={false}
        draggable={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        dotListClass="landlord-resources-dot-list"
        arrows={true}
      >
        {images && images.slice(0, 5).map((image, i) => {
          return (
            <Image
              key={i}
              draggable={false}
              style={{ width: "100%", height: "298px" }}
              src={image}
              className="rounded"
            />
          );
        })}
      </Carousel>
    </div>
  );
}
export default UnitDetailsImageCarousel;