import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SidePanel, LargeButton } from "../../../../components";
import { logger, toaster } from "../../../../../Utils";
import {
    is_furnishedData,
    pet_policyData,
    // amenitiesData,
    is_parking_includedDataAdmin,
    // utilitiesData,
    laundryForHouseData,
    laundryForUnitData,
    getUtilitiesAndAmenities
} from "../../../../../Constants/propertyDetails";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { postAPI } from "../../../../../Api";
import { isEmptyArray } from "formik";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const log = logger("AdminUnitDetails");
const AdminUnitDetails = (props) => {
    const { isShow, handleClose, apiCallback } = props;
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [property_id, setProperty_id] = useState("");
    const [property_details_id, setProperty_details_id] = useState("");
    const [is_furnished, setIs_furnished] = useState(0);
    const [pet_policy, setPet_policy] = useState(0);
    const [amenities, setAmenities] = useState([]);
    const [is_parking_included, setIs_parking_included] = useState(0);
    const [parking_slot, setParking_slot] = useState(0);
    const [utilities, setUtilities] = useState([0]);
    const [laundry, setLaundry] = useState(0);
    const [disableBtn, setBtnDisable] = useState(false);
    const laundryData = (propertyDetails && propertyDetails.property_type === 1) ? laundryForHouseData : laundryForUnitData;
    const [amenitiesData, setAmenitiesData] = useState([]);
    const [utilitiesData, setUtilitiesData] = useState([]);

    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'is_furnished') {
            setIs_furnished(Value);
        } else if (name === 'pet_policy') {
            setPet_policy(Value);
        } else if (name === 'is_parking_included') {
            if (Value === 0) {
                setParking_slot(0)
            } else if (parking_slot === 0) {
                setParking_slot(1)
            }
            setIs_parking_included(Value)
        } else if (name === 'laundry') {
            setLaundry(Value)
        }
    }

    const OnMultipleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'amenities') {
            if (amenities.includes(Value)) {
                const index = amenities.indexOf(Value);
                amenities.splice(index, 1);
                setAmenities(amenities => [...amenities])
            } else {
                setAmenities(amenities => [...amenities, Value])
            }
        } else if (name === 'utilities') {
            if (Value === 0) {
                if (!utilities.includes(Value)) {
                    setUtilities([0])
                }
            } else {
                if (utilities.includes(0)) {
                    const selectIndex = utilities.indexOf(0)
                    utilities.splice(selectIndex, 1);
                    setUtilities(utilities => [...utilities])
                }
                if (utilities.includes(Value)) {
                    const index = utilities.indexOf(Value);
                    utilities.splice(index, 1);
                    if (utilities.length > 0) {
                        setUtilities(utilities => [...utilities])
                    } else {
                        setUtilities([0])
                    }
                } else {
                    setUtilities(utilities => [...utilities, Value])
                }
            }
        }
    }

    const OnChangeInput = (event) => {
        setParking_slot(event.target.value)
    }

    const onSubmit = (event, props) => {
        event.preventDefault();
        const url = '/admin/listing/update/step-3';
        const data = {
            property_id: property_id,
            property_details_id: property_details_id,
            property_type: propertyDetails && propertyDetails.property_type,
            is_furnished: is_furnished,
            pet_policy: pet_policy,
            amenities: amenities,
            is_parking_included: is_parking_included,
            parking_slot: parking_slot,
            utilities: utilities,
            laundry: laundry
        }
        setBtnDisable(true)
        postAPI(url, data).then(response => {
            setBtnDisable(false)
            if (response.status === 200) {
                toaster('success', 'Data Saved Successfully!')
                apiCallback();
                handleClose();
            } else if (response.response.status === 400) {
                log.info(response.response)
                toaster('error', response.response.data.message);
            } else if (response.response.status === 422) {
                toaster('error', response.response.data.message);
            }
        })
    }
    useEffect(() => {
        if (unitDetails) {
            setIs_furnished(unitDetails.is_furnished)
            setPet_policy(unitDetails.pet_policy)
            setAmenities(unitDetails.amenities.length !== 0 ? unitDetails.amenities : [1])
            setIs_parking_included(unitDetails.is_parking_included)
            setParking_slot(unitDetails.parking_slot);
            setUtilities(isEmptyArray(unitDetails.utilities) ? [0] : unitDetails.utilities);
            setLaundry(unitDetails.laundry);
        }
        (async() => {
            const {utilitiesData=[], amenitiesData=[]}=await getUtilitiesAndAmenities()
            setAmenitiesData(amenitiesData)
            setUtilitiesData(utilitiesData)
          })()
    }, [unitDetails]);
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id]);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Edit Information">
            <h4 className="edit-main mt-4">unit details</h4>
            <form id="Form" onSubmit={onSubmit} autoComplete="off">
                <div className="sidepanel-body filter-tenant mt-4">
                    <Row className="autoAddress">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Is This Unit Furnished?</Form.Label>
                            <ul className="select-options">
                                {is_furnishedData.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"is_furnished_" + data.value} value={data.value} name="is_furnished"
                                        className={(is_furnished === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>What Is The Pet Policy?</Form.Label>
                            <ul className="select-options">
                                {pet_policyData.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"pet_policy_" + data.value} value={data.value} name="pet_policy"
                                        className={(pet_policy === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>What amenities are included? (Select all that apply)</Form.Label>
                            <ul className="select-options">
                                {amenitiesData.map((data) =>
                                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"amenities_" + data.id} value={data.id} name="amenities"
                                        className={(amenities.includes(data.id)) ? "active" : ""}>{data.name}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>Is Parking Included?</Form.Label>
                            <ul className="select-options">
                                {is_parking_includedDataAdmin.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"is_parking_included_" + data.value} value={data.value} name="is_parking_included"
                                        className={(is_parking_included === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        {is_parking_included !== 0 &&
                            <Form.Group as={Col} md="12">
                                <Form.Label>How Many Parking Spots</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="1"
                                    name="parking_slot"
                                    min="1"
                                    value={parking_slot}
                                    onChange={(e) => OnChangeInput(e)} />
                            </Form.Group>}
                        <Form.Group as={Col} md="12">
                            <Form.Label>Utilities Included (Select All That Apply)</Form.Label>
                            <ul className="select-options">
                                {utilitiesData.map((data) =>
                                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"utilities_" + data.id} value={data.id} name="utilities"
                                        className={(utilities.includes(data.id)) ? "active" : ""}>{data.name}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>Laundry</Form.Label>
                            <ul className="select-options">
                                {laundryData.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"laundry_" + data.value} value={data.value} name="laundry"
                                        className={(laundry === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>

                    </Row>
                </div>
                <div className="fixed-bottom d-flex justify-content-end">
                    <LargeButton saveLoading={disableBtn} title='save changes' type="submit" style={{ width: '200px' }} />
                </div>
            </form>
        </SidePanel>
    </>);
}
export default withRouter(AdminUnitDetails);