import {
    SET_MESSAGE_DATA,
    SET_CONVERSATION_ID,
    SET_IS_BLOCKED,
    SET_IS_PROFILE_COMPLETED,
    SET_RECEIVER_DATA,
    SET_VIEWING_REQUEST_ID,
    SET_EXISTING_SHOWING_REQUEST_ID,
    SET_EXISTING_SHOWING_SENT_BY,
    SET_HEADER_CONTENT,
    SET_CALENDER_DATA,
    SET_TENANT_LIST_DATA,
    SET_TENANT_ID,
    SET_CREDIT_CHECK_REQUEST_ID,
    SET_CREDIT_CHECK_PAYMENT_ID,
    SET_IS_LEASE_EXIST,
    SET_TENANCY_APPLICATION_ID,
    SET_EXISTING_TENANCY_APPLICATION_ID,
    SET_IS_TENANCY_APPLICATION_RECEIVED,
    SET_IS_LEASE_FROM_TENANT_APPLICATION,
    SET_LEASE_DATA
} from "../actions/Action";

export const initialState = {
    messageData: null,
    conversationId: null,
    isBlocked: null,
    isProfileCompleted: null,
    receiverData: null,
    viewingRequestId: null,
    existingShowingRequestId: null,
    existingShowingSentBy: null,
    headerContent: null,
    calenderData: null,
    tenantListData: null,
    tenantId: null,
    creditCheckRequestId: null,
    creditCheckPaymentId: null,
    isLeaseExists: null,
    tenancyApplicationId: null,
    existingTenancyApplicationId: null,
    isTenancyApplicationReceived: null,
    isLeaseFromTenantApplication: false,
    leaseData: {}
};

export default function messageDetailsReducer(state = initialState, action) {
    const handlers = {
        ['CLEAR_ALL']: (state) => ({
            ...state,
            messageData:null
        }),
        [SET_MESSAGE_DATA]: (state) => ({
            ...state,
            messageData: action.messageData,
        }),
        [SET_LEASE_DATA]: (state) => ({
            ...state,
            leaseData: action.leaseData,
        }),
        [SET_CONVERSATION_ID]: (state) => ({
            ...state,
            conversationId: action.conversationId,
        }),
        [SET_IS_BLOCKED]: (state) => ({
            ...state,
            isBlocked: action.isBlocked,
        }),
        [SET_IS_PROFILE_COMPLETED]: (state) => ({
            ...state,
            isProfileCompleted: action.isProfileCompleted,
        }),
        [SET_RECEIVER_DATA]: (state) => ({
            ...state,
            receiverData: action.receiverData,
        }),
        [SET_VIEWING_REQUEST_ID]: (state) => ({
            ...state,
            viewingRequestId: action.viewingRequestId,
        }),
        [SET_EXISTING_SHOWING_REQUEST_ID]: (state) => ({
            ...state,
            existingShowingRequestId: action.existingShowingRequestId,
        }),
        [SET_EXISTING_SHOWING_SENT_BY]: (state) => ({
            ...state,
            existingShowingSentBy: action.existingShowingSentBy,
        }),
        [SET_HEADER_CONTENT]: (state) => ({
            ...state,
            headerContent: action.headerContent,
        }),
        [SET_CALENDER_DATA]: (state) => ({
            ...state,
            calenderData: action.calenderData,
        }),
        [SET_TENANT_LIST_DATA]: (state) => ({
            ...state,
            tenantListData: action.tenantListData,
        }),
        [SET_TENANT_ID]: (state) => ({
            ...state,
            tenantId: action.tenantId,
        }),
        [SET_CREDIT_CHECK_REQUEST_ID]: (state) => ({
            ...state,
            creditCheckRequestId: action.creditCheckRequestId,
        }),
        [SET_CREDIT_CHECK_PAYMENT_ID]: (state) => ({
            ...state,
            creditCheckPaymentId: action.creditCheckPaymentId,
        }),
        [SET_IS_LEASE_EXIST]: (state) => ({
            ...state,
            isLeaseExists: action.isLeaseExists,
        }),
        [SET_TENANCY_APPLICATION_ID]: (state) => ({
            ...state,
            tenancyApplicationId: action.tenancyApplicationId,
        }),
        [SET_EXISTING_TENANCY_APPLICATION_ID]: (state) => ({
            ...state,
            existingTenancyApplicationId: action.existingTenancyApplicationId,
        }),
        [SET_IS_TENANCY_APPLICATION_RECEIVED]: (state) => ({
            ...state,
            isTenancyApplicationReceived: action.isTenancyApplicationReceived,
        }),
        [SET_IS_LEASE_FROM_TENANT_APPLICATION]: (state) => ({
            ...state,
            isLeaseFromTenantApplication: action.isLeaseFromTenantApplication,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}