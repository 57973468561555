import { postAPI } from "../../../Api";
import { toaster } from "../../../Utils";

export const resetPassword = async (userId, password) => {
  try {
    const res = await postAPI("/admin/users/change-password", {
      password: password,
      user_id: userId,
    });
    toaster('success', 'Password updated successfully!');
    return res?.status === 200;
  } catch (err) {
    toaster('error', 'Something went wrong!');

    return false;
  }
};