import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { logger } from "../../../Utils";
import { SidePanel } from "../";
import { setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";

const log = logger("CreateLeaseOptions");
const CreateLeaseOptions = (props) => {
    const dispatch = useDispatch();
    const { isShow, handleClose, setUploadLease, title } = props;

    const [property_id, setProperty_id] = useState("");
    const [property_details_id, setProperty_details_id] = useState("");
    const unitSelector = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const headerContent = useSelector(
        (state) => state.messageDetailsReducer.headerContent
      );
    const clickOnCreateLease = () => {
        dispatch(setIsLeaseFromTenantApplication(true));
        props.history.push({
            pathname: '/create-lease/' + property_id + '/' + property_details_id,
            state: {
                lease_id: 0,
                is_tenancy_application: true
            }
        });
    }
    const clickOnUploadLease = () => {
        dispatch(setIsLeaseFromTenantApplication(true));
        handleClose();
        props.history.push({
            pathname: '/property-details/' + property_id + '/' + property_details_id + '/leases',
            state: {
                lease_id: 0,
                is_tenancy_application: true
            }
        });
        setUploadLease(true);
    }

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id]);

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title={title ? title : `Do you want to create a lease for the ${headerContent?.name || 'tenant'}?`}>
            <div className="deactive-reasons">
                <Row className="p-4 border-reason cursor-pointer" id="BTN-create-lease" data-description="Landlord began to create a lease" onClick={() => clickOnCreateLease()}>
                    <Col className='pl-0 pr-0 change-heading d-flex justify-content-between' id="BTN-create-lease" data-description="Landlord began to create a lease" md={12}> Create a new lease
                        <img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" />
                    </Col>
                </Row>
                <Row className="p-4 border-reason cursor-pointer" id="BTN-upload-lease" data-description="Landlord chose to upload a lease" onClick={() => clickOnUploadLease()}>
                    <Col className='pl-0 pr-0 change-heading d-flex justify-content-between' id="BTN-upload-lease" data-description="Landlord chose to upload a lease" md={12}>Upload lease
                        <img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" />
                    </Col>
                </Row>
            </div>

        </SidePanel>
    </>);
}
export default withRouter(CreateLeaseOptions);