import React from 'react';
import { DollorNumberDisplay, HighlightContent } from '..';
import './table.scss'
import moment from "moment";
import { colorStatus, colorStatusMaintain, colorStatusLease, colorStatusShowings, colorStatusInspection } from '../../../Constants/colorStatus';
import SearchInput from '../../Admin/ProfileDetail/ListUsableComponent/SearchInput';
import LeaseHeader from './LeaseHeader';
import ShowingHeader from './ShowingHeader';
import ListingHeader from './ListingHeader';
import MaintainanceHeader from './MaintainanceHeader';
import InspectionHeader from './InspectionHeader';
import NumberFormat from 'react-number-format';
import { Col, Image, Row } from 'react-bootstrap';
import NoticesHeader from './NoticesHeader';
import { dateFormat2 } from '../../../Utils/DateSanitizer/DateSanitizer';
import { useSelector } from 'react-redux';
import logger from '../../../Utils/logger';

const log = logger("TableLandlord");

const TableLandlord = (props) => {
	const {
		component, to, from, current_page, last_page, total, setSortByPrice, clickNext,
		clickPrev, columns, tableData, goToProfileDetail, searchLocation, setSearch, onSortTenant,
		sort_by_type, sortType, sort_by_status, sortStatus, sortShowing, onSortTime, onSortDate,
		durationFilter, filterRent, sortStatusLease, sort_by_status_lease, roleAdmin
	} = props;

	log.info("tableData", tableData);

	const noticeType = useSelector(state => state.propertyDetailsReducer.noticeType)
	const noticeTypeDisplay = (value) => {
		var title = "";
		noticeType.map((data) => {
			if (value === data.id) {
				title = (data.title)
			}
			return data;
		})
		return title;
	}
	return (
		<div className="table-component-wrapper table-responsive" style={{ overflow: 'visible' }}>
			<Row>
				<Col md={4} className='ml-4 mt-2 mb-m-10-20'><SearchInput placeHolder="Search Listing" searchLocation={searchLocation} setSearch={(data) => setSearch(data)} /></Col>
			</Row>

			<div className={tableData.length !== 0 ? 'custom-height' : ''}>
				<div className='table-responsive r-table-responsive'>
					<table className="table-component a">
						<thead>
							<tr className="margin-wrapper">
								{columns.map((data, i) => {
									return (<th key={'table_head_' + i} className={(data.header === "TYPE" || data.header === "STATUS") + " display-flex"} width={data.width}>
										<ListingHeader
											component={component}
											data={data}
											setSortByPrice={setSortByPrice}
											sort_by_type={sort_by_type}
											onSortTenant={onSortTenant}
											sortType={sortType}
											sortStatus={sortStatus}
											sort_by_status={sort_by_status}
											is_verified={props.is_verified}
											setIsVerified={props.setIsVerified}
										/>
										<ShowingHeader
											component={component}
											data={data}
											onSortDate={onSortDate}
											onSortTime={onSortTime}
											sortShowing={sortShowing}
										/>
										<NoticesHeader
											component={component}
											data={data}
											selectedNoticeType={props.selectedNoticeType}
											sortByNoticeType={props.sortByNoticeType}
											sortCreatedNotice={props.sortCreatedNotice}
											sortNoticeStatus={props.sortNoticeStatus}
											status={props.status}
										/>
										<LeaseHeader
											component={component}
											data={data}
											durationFilter={durationFilter}
											filterRent={filterRent}
											sortStatusLease={sortStatusLease}
											sort_by_status_lease={sort_by_status_lease}
										/>
										<MaintainanceHeader
											component={component}
											data={data}
											maintainanceType={props.maintainanceType}
											sortByMaintainanceType={props.sortByMaintainanceType}
											sortCreated={props.sortCreated}
											sortCreatedOn={props.sortCreatedOn}
											sortMaintainanceStatus={props.sortMaintainanceStatus}
										/>
										<InspectionHeader
											component={component}
											data={data}
											setType={props.setType}
											sortCreatedOn={props.sortCreatedOn}
											sortInspectionStatus={props.sortInspectionStatus}
										/>
									</th>)
								})}
							</tr>
						</thead>
						<tbody>
							{tableData && tableData.map((data, i) => {
								return (
									<tr key={'table_body_' + i} className="cursorPointer" onClick={() => goToProfileDetail(data)}>
										{component === "listing" && <React.Fragment>
											<td style={{ minWidth: '5px' }}>
												<Image src={data.is_verified === 0 ? require("../../../_assets/images/Unverified.png") : require("../../../_assets/images/verified.png")} width={20} height={20} alt={'verified'} />
											</td>
											<td>
												<HighlightContent searchWord={searchLocation} highlightWord={data.address} />
											</td>
											<td>
												<NumberFormat value={data.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'}
													renderText={value => <>
														<HighlightContent searchWord={searchLocation} highlightWord={value} />
													</>}
												/> / Month
                                        </td>
											<td>{data.tenant !== null ? <HighlightContent searchWord={searchLocation} highlightWord={data.tenant} /> : 'None'}</td>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.property_type} /></td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatus(data.status)}><HighlightContent searchWord={searchLocation} highlightWord={data.status} /></span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
										{component === "showings" && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.address} /></td>
											<td>
												{moment(data.request_date).format("ll")}
											</td>
											<td>
												{moment(data.start_time, ["H:mm"]).format("h:mm") + ' - ' + moment(data.end_time, ["H:mm"]).format("h:mm a")}
											</td>
											<td>{data.name}</td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={"showing-status-admin " + colorStatusShowings(data.status)}>{data.status}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
										{component === "lease" && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.address} /></td>
											<td>{data.lease_type}</td>
											<td>
												{roleAdmin === 'landlord' ?
													<>
														{(data.lease_tenants && data.lease_tenants.length !== 0) ?
															<>{(data && data.lease_tenants && data.lease_tenants.length !== 0) && (data.lease_tenants[0].name)} {(data && data.lease_tenants && data.lease_tenants.length > 1) && '+'} {(data && data.lease_tenants && data.lease_tenants.length > 1) && (Number(data.lease_tenants.length) - 1)} </>
															: 'None'}
													</>
													:
													<>{data.landlord_name ? data.landlord_name : 'None'}</>
												}
											</td>
											<td><DollorNumberDisplay price={data.rent} /></td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatusLease(data.status)}>{data.status}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
										{component === 'requestManagement' && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.address} /></td>
											<td>{data.role}</td>
											<td>{data.requestType}</td>
											<td>{data.city}</td>
											<td>{data.dateAndTime}</td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatusLease(data.status)}>{data.action}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}

										{component === 'maintainance' && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.address} /></td>
											<td>{data.type}</td>
											<td>{data.created}</td>
											<td>{data.completed_on !== null ? data.completed_on : '--'}</td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatusMaintain(data.status_name)} style={{ textTransform: 'uppercase', fontSize: '16px' }}>{data.status_name}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
										{component === 'inspection' && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.address} /></td>
											<td>{data.type_name} Inspection</td>
											<td>{data.tenant_name !== "" ? data.tenant_name : 'None'}</td>
											<td>{data.completed_on}</td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatusInspection(data.status_name)}>{data.status_name}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
										{component === 'notices' && <React.Fragment>
											<td><HighlightContent searchWord={searchLocation} highlightWord={data.property.address} /></td>
											<td>{noticeTypeDisplay(data.notice_type)}</td>
											<td>{dateFormat2(data.created_at)}</td>
											<td>{data.formatted_notice_type}</td>
											<td>
												<div className="d-flex justify-content-between align-items-center">
													<span className={colorStatusInspection(data.formatted_status)}>{data.formatted_status}</span>
													<span className='status'><img className="next-arrow" src={require('../../../_assets/images/next.svg')} alt="next" /></span>
												</div>
											</td>
										</React.Fragment>}
									</tr>)
							})}
						</tbody>
					</table>

					{tableData.length === 0 &&
						<div className="empty-table-data" style={{ minHeight: '650px' }}>
							There is no data found
					</div>
					}
					{tableData && tableData.length !== 0 &&
						<div className="table-footer">
							<div>
								{tableData.length === 0 ? <span>Data not found</span> : <span className="left">
									{tableData.length} of {total}
								</span>}
							</div>
							<div>
								{from && to &&
									<span className="right">
										{from} to {to}
										{current_page !== from ? <img className="previous-arrow" onClick={clickPrev} src={require('../../../_assets/images/next.svg')} alt="previous" /> : <img className="previous-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="previous" />}
										{current_page !== last_page ? <img className="next-arrow" onClick={clickNext} src={require('../../../_assets/images/next.svg')} alt="next" /> : <img className="next-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="next" />}
									</span>}
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default TableLandlord;