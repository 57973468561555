import React from 'react'
import { LargeButton, CancelButton } from '../../..';

const AddTenantFooter = props => {
    const {handleCloseConfirm, isDeactivation, addAndInvite} = props;
    return (
        <div className='fixed-bottom d-flex justify-content-between'>
            <CancelButton onClick={() => handleCloseConfirm()} title='back' />
            <LargeButton title={isDeactivation ? 'next' : 'add and invite'} onClick={() => addAndInvite()} style={{ width: '160px' }} />
        </div>
    )
}

export default AddTenantFooter
