import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./style.scss";
import CalenderListView from "./CalenderListView/CalenderListView";
import CalenderView from "./CalenderView/CalenderView";
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
import { createEventId } from "./event-utils";
import { useDispatch, useSelector } from 'react-redux';
import { setShowingCalendarData, setShowingListData } from "../../../Store/actions/ShowingDetailAction";
import { setExistingShowingRequestId, setExistingShowingSentBy } from "../../../Store/actions/MessageDetailAction";
import io from 'socket.io-client';
import { SOCKET_URL } from "../../../Constants/endPoints";

const log = logger("Calender Component");
const Calender = (props) => {
  const {
    user_type,
    setConfirmShowing,
    showingType
  } = props;
  const [toggleCalender, setToggleCalender] = useState(false);

  const dispatch = useDispatch();
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const calenderData = useSelector(state => state.showingDetailReducer.showingCalendarData);
  const calenderListViewData = useSelector(state => state.showingDetailReducer.showingListData);
  const [activeKey, setActiveKey] = useState('new_to_old');
  const messageData = useSelector(state => state.messageDetailsReducer.messageData); 
  const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);

  log.info('activeKey',activeKey);

  const toggleView = () => {
    setToggleCalender(!toggleCalender);
  };

  let socket = useState(null);
  useEffect(()=>{
    dispatch(setShowingListData([]));
    dispatch(setShowingCalendarData([]));
    dispatch(setExistingShowingRequestId(null));
    dispatch(setExistingShowingSentBy(null));
    getDetails()
  },[])

  useEffect(() => {  
    socket = io(SOCKET_URL);
    log.info('socket',socket)
    
    socket.on("connect", () => {
        log.info('socket connected',socket.connected); // true
    });        

    socket.on('reconnect', (attemptNumber) => {
        log.info("socket reconnected -",attemptNumber)
    });
    
    socket.on("disconnect", (reason) => {
        log.info('socket disconnected',reason,'===>',socket.disconnected); // false
        socket.open();
    });

    socket.on("conversation-"+ conversation_id +":MessageEvent", () => {
        log.info('socket conversation',getDetails())
    });
    
  }, [messageData]);

  const getDetails = () => {
    getCalenderDetails();
    getCalenderListDetails();
  }

  const getCalenderDetails = () => {
    let url = "";
    if(showingType === 'all'){
      url = "/landlord/calendar-showings/calendar-view";
    }else if  (property_details_id) {
      url = "/landlord/calendar-showings/calendar-view/" + property_details_id;
    }
    if (user_type === 'tenant') {
      url = "/tenant/showing-requests/calendar-view";
    }
    getAPI(url)
    .then((response) => {
    const res = response.data.data;
    log.info("Landlord Calender res", res);
    //log.info('sample data ',INITIAL_EVENTS);
    let resultArray = [];
    res.map((result, i) => {
        const date = result.start.replace(/\s/g, "");
        let backgroundColor = "#DEDEDE";
        let borderColor = "#DEDEDE";

        let status = result.data.status;
        //log.info('status',status,result)
        if (status && status.includes("Confirmed")) {
        backgroundColor = "#BFE7C3";
        borderColor = "#BFE7C3";
        } else if (status && status.includes("Attention")) {
        backgroundColor = "#F7BFBF";
        borderColor = "#F7BFBF";
        }

        resultArray.push({
        id: createEventId(),
        title: result.title,
        start: date,
        data: result.data,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        });

        return resultArray;
    });
    dispatch(setShowingCalendarData(resultArray));
    dispatch(setExistingShowingRequestId(null));
    dispatch(setExistingShowingSentBy(null));
    })
    .catch((error) => {});
  }

  const getCalenderListDetails = (sort) => { 
      let calendarUrl = '';
      
      let key = (sort === 'old_to_new') ? 'asc' : 'desc';
      if(showingType === 'all'){
        calendarUrl = `/landlord/calendar-showings/list-view/?sort=${key}`;
      }else if (property_details_id) {
        calendarUrl = `/landlord/calendar-showings/list-view/all/${property_details_id}?sort=${key}`;
      }
      if (user_type === 'tenant') {
        calendarUrl = `/tenant/showing-requests/list-view?sort=${key}`;
      }
      getAPI(calendarUrl)
        .then((response) => {
          const res = response.data.data;
          //log.info('list view res',res);
          dispatch(setShowingListData(res));
          dispatch(setExistingShowingRequestId(null));
          dispatch(setExistingShowingSentBy(null));
        })
        .catch((error) => {});
  }

  useEffect(() => {
    getCalenderDetails();
    getCalenderListDetails(activeKey);
  }, [property_details_id, user_type, activeKey]);

  return (
    <div className="profile-container">
      <div className="profile-container-box">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Button onClick={() => toggleView()} className="list-grid-btn">
                <Image
                  width="12px"
                  height="12px"
                  alt="list-grid-icon"
                  src={require(`../../../_assets/images/list-grid-icon.svg`)}
                />
                {toggleCalender ? "List View" : "Calendar View"}
              </Button>
              {toggleCalender
                ? calenderData && (
                    <CalenderView
                      setConfirmShowing={setConfirmShowing}
                      user_type={user_type}
                    />
                  )
                : calenderListViewData && (
                    <CalenderListView
                      setConfirmShowing={setConfirmShowing}
                      user_type={user_type}
                      activeKey={activeKey} 
                      setActiveKey={(data)=>setActiveKey(data)}
                    />
                  )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(Calender);
