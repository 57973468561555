import { postAPI } from "../../../../Api";
import { SERVICE_AREA_LATLNG } from "../../../../constants";


export const publishListing=async(propertyId,propertyDetailsId)=>{
try{
const res =await postAPI('/landlord/publish-listing',{property_id:propertyId,property_details_id:propertyDetailsId})
return res.status===200;
}catch(err)
{
  return false
}
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
 


export const isWithin20Km = (lat, lng) => {
  return Object.values(SERVICE_AREA_LATLNG).find((place) => {
    const latLng=place.latLng;
    const distance = calcDistance(latLng[0], latLng[1], lat, lng).toFixed(1)
    return parseInt(distance) <= place.radius
  })
}



export const calcDistance = (lat1, lon1, lat2, lon2) => {

  const kmConstant = 6371;
  const distanceLat = toRad(lat2 - lat1);
  const distanceLon = toRad(lon2 - lon1);
  const lat1Radian = toRad(lat1);
  const lat2Radian = toRad(lat2);

  const a = Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) + Math.sin(distanceLon / 2) * Math.sin(distanceLon / 2) * Math.cos(lat1Radian) * Math.cos(lat2Radian);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = kmConstant * c;
  return parseFloat(distance);
}


export const toRad = (Value) => {
  return Value * Math.PI / 180;
}

export const getLatLngAddress = (place, lat, lng) => {
  return place.geometry?.location?.lat === parseFloat(lat) && place.geometry?.location?.lng === parseFloat(lng);
};
