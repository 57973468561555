import React, { useState, useEffect } from "react";
 import { Row, Col,  } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { updateRawData } from "../../../Store/actions/RawDataAction";
import { setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";
import { useGlobalState } from "../../../StateContext";
import { getTenantsList } from "../../../Store/actions/AddedTenantAction";
import { ModalBox } from "../../components";
import { deleteLeaseAPI, getLeaseList } from "./helper";
import LeaseList from "./LeaseList";
import LeaseListHeader from "./LeaseListHeader";
import "./style.scss";


const Leases = (props) => {
  const { setUploadLease, setLeaseId } = props;
  const [propertyLists, setPropertyLists] = useState(null);
  const [activeKey, setActiveKey] = useState('new_to_old');
  const [leaseToDelete, setLeaseToDelete] = useState(null)
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const [globalState, setGlobalState] = useGlobalState()

  const dispatch = useDispatch();

  useEffect(() => {
    if (property_details_id) {
      getInitialData()
    }
  }, [property_details_id])

  const getInitialData = async () => {
    const leaseList = await getLeaseList(property_id, property_details_id)
    setPropertyLists(leaseList);
  }

  const handleDropdownSelect = async (key) => {
    const url = `/landlord/listing/lease/lease-list/${property_id}/${property_details_id}?sort_by=post_date&sort_order=${key}`;
    const leaseList = await getLeaseList(property_id, property_details_id, url)
    setActiveKey(key);
    setPropertyLists(leaseList);
  }

  const leaseAction = (data, lease_id, is_manual_uploaded, isView = false) => {
    dispatch(setIsLeaseFromTenantApplication(false));
    dispatch(getTenantsList([]))
    if (props.setIsDeactivation) {
      props.setIsDeactivation(false);
    }
    if (is_manual_uploaded !== 1) {
      if (data === 'create') {
        setGlobalState({ showReviewMessage: !!lease_id })
        props.history.push({
          pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
          state: {
            lease_id: lease_id,
            step: (lease_id === 0 ? 0 : 3),
            view: isView
          }
        });
      } else if (data === 'upload') {
        setUploadLease(true);
      }
    } else {
      setLeaseId(lease_id);
      setUploadLease(true)
    }
    updateRawData({ leaseCurrentStep: 0 })
  }
  const onLeaseDeleteHandler = (data) => setLeaseToDelete(data)

  const deleteLease = async () => {
    if (leaseToDelete) {
      const payload = { lease_id: leaseToDelete.lease_id, property_id, property_detail_id: property_details_id }
      const res = await deleteLeaseAPI(payload)
      if (res) {
        setLeaseToDelete(null)
        getInitialData()
      }
    }
  }

  return (
    <div className="profile-container">
      <div className="profile-container-box">
        <LeaseListHeader
          handleDropdownSelect={handleDropdownSelect}
          leaseAction={leaseAction}
          activeKey={activeKey}
        />
        <Row>
          <Col>
            <div className="table-component-wrapper list-view-calender lease-list">
              <LeaseList propertyLists={propertyLists} leaseAction={leaseAction} onLeaseDeleteHandler={onLeaseDeleteHandler} />
            </div>
          </Col>
        </Row>
        {(propertyLists && propertyLists.length === 0) &&
          <Row className="m-0">
            <Col className="empty-showing-request" md={12}>There is no lease available</Col>
          </Row>
        }
      </div>

      <ModalBox
        show={leaseToDelete}
        onHide={() => setLeaseToDelete(null)}
        backaction={() => setLeaseToDelete(null)}
        size='md'
        actionbuttontitle={'Yes'}
        buttonaction={deleteLease}
        backbuttontitle={'No'}
        type={true}
      >
        <h4>Are you sure you want to delete this lease?</h4>
        <p>When the lease is deleted the applicant will be notified and no longer be able to sign or move forward with this lease.</p>
      </ModalBox>
    </div>
  );
}

export default withRouter(Leases);
