export const GET_ACTIVE_USER_DATA = 'GET_ACTIVE_USER_DATA';
export const GET_INACTIVE_USER_DATA = 'GET_INACTIVE_USER_DATA';
export const SET_ACTIVE_USER_CURRENT_PAGE = 'SET_ACTIVE_USER_CURRENT_PAGE';
export const SET_INACTIVE_USER_CURRENT_PAGE = 'SET_INACTIVE_USER_CURRENT_PAGE';
export const SET_ACTIVE_USER_FILTERS = 'SET_ACTIVE_USER_FILTERS';
export const SET_INACTIVE_USER_FILTERS = 'SET_INACTIVE_USER_FILTERS';
export const SET_USER_STATUS = 'SET_USER_STATUS';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_TABLEDATA = 'GET_TABLEDATA';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export function setActiveUserTableData(data) {
    return function (dispatch) {
        dispatch({ type: GET_ACTIVE_USER_DATA, activeUserTableData: data });
    };
}

export function setInactiveUserTableData(data) {
    return function (dispatch) {
        dispatch({ type: GET_INACTIVE_USER_DATA, inactiveUserTableData: data });
    };
}

export function setActiveUserCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_ACTIVE_USER_CURRENT_PAGE, activeUserCurrentPage: data });
    };
}

export function setInactiveUserCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_USER_CURRENT_PAGE, inactiveUserCurrentPage: data });
    };
}

export function setActiveUserFilters(data) {
    return function (dispatch) {
        dispatch({ type: SET_ACTIVE_USER_FILTERS, activeUserFilters: data });
    };
}
export function setInactiveUserFilters(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_USER_FILTERS, inactiveUserFilters: data });
    };
}

export function setUserStatus(data) {
    return function (dispatch) {
        dispatch({ type: SET_USER_STATUS, userStatus: data });
    };
}

export function setFilters(data) {

    return function (dispatch, getState) {
        const state = getState();
        if (state.userReducer.userStatus === 'inactive') {
            dispatch(setInactiveUserFilters(data));
            dispatch(setCurrentPage(1));
        } else {
            dispatch(setActiveUserFilters(data));
            dispatch(setCurrentPage(1));
        }

        dispatch({ type: GET_FILTERS, filters: data });
    };
}

export function setTableData(data) {

    return function (dispatch, getState) {
        const state = getState();
        if (state.userReducer.userStatus === 'inactive') {
            dispatch(setInactiveUserTableData(data));
        } else {
            dispatch(setActiveUserTableData(data));
        }
        dispatch({ type: GET_TABLEDATA, tableData: data });
    };
}

export function setCurrentPage(data) {
    return function (dispatch, getState) {
        const state = getState();
        if (state.userReducer.userStatus === 'inactive') {
            dispatch(setInactiveUserCurrentPage(data));
        } else {
            dispatch(setActiveUserCurrentPage(data));
        }
        dispatch({ type: SET_CURRENT_PAGE, currentPage: data });
    };
}