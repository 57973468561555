import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Image } from 'react-bootstrap';
import { AppLayout } from "../../../App/components";
import { Container } from "react-bootstrap"; //, Row, Col
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import "react-quill/dist/quill.snow.css";
// import 'react-quill/dist/quill.bubble.css';
import "./style.scss";
import LandingPage from "../LandingPage";

const LandingPageRentalReportOctober2022 = (props) => {
    const previewData = {
       image_url: '../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/iconsBanner.png', /* path of parent dir */
       headline: 'Canadian monthly rental report - Oct 2022',
       excerpt: 'Check out our Rent Panda National Monthly Rent Report For October 2022 for Canada'
    }

    return (
        <LandingPage previewData={previewData}>
            <div className="page-header">
                <p>Data is reflective of Oct 2022 rental listings across Canada.</p>
            </div>
            
            <div className="page-header-banners">
                <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/iconsBanner.png")} alt="Banner" />
                <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rpXdoorInsight.png")} alt="Banner" />
            </div>
            
            <div className="page-content">
                <div className="page-content-section">
                    <h1>executive summary</h1>
                    
                    <p><strong>Year-over-year rents are up</strong>:&nbsp;It’s no surprise that year-over-year rents have risen 21.3% across Canada this October, with Alberta and Manitoba leading the country with 33% and 29% increases respectively.</p>
                    <p><strong>Whispers of rent stabilization can be heard</strong>:&nbsp;The good news for renters is that October saw month-over-month median rent start to stabilize in some Canadian markets. The largest decline since last month was seen in Nova Scotia, where overall rent prices came down by 13.6%. Other previously hot markets, such as Guelph, Waterloo, and St. Catharines have all shown signs of a slowdown, with rent dropping by single digit percentages. This may be a sign that rent is approaching an inflection point, or it might just be a momentary ease for renters as we approach the holidays. </p>
                    <p><strong>Affordability standards are changed forever</strong>:&nbsp;The age-old rule of a family not spending more than 30% of their household income on rent is a thing of the past. The average family in Canada will now spend over 36% of their income on rent, with that number rising upwards of 50-60% within hot rental markets in the country like Vancouver, Toronto, Waterloo, and Montreal. 
                    <br /><br />The more affordable cities for renters outside of Ontario include Trois-Rivières, Edmonton, and Regina, with the household income-to-rent ratio being under 30%. In Ontario, markets such as Thunder Bay, Sault St. Marie, and Sarnia hover just above the 30% range, while others like St Catherines, Hamilton, Brantford, and London are still on the affordable end for families, with rent being 45% or less of household income.</p>
                    <p><strong>What to expect for the rest of the year</strong>:&nbsp;With the Bank of Canada continuing its war on inflation, mainly with rate hikes, there is likely going to be more pain to be felt for renters across the country. Interest rate hikes, combined with inflation, puts upward pressure on rents, as landlords look to offset additional costs. According to MyPerch.io, Canada's most intuitive home ownership platform for mortgages, application volume in 2021 was 81% fixed, 19% variable, and so far 2022, the split has been 49% fixed, 51% variable; therefore, the impact of the interest rate hikes will trickle down to tenants for the foreseeable future. It remains to be seen over the coming months how individuals and families can support these increases, and where the balancing points will be across each market in the country.
                    <br /><br />In our Canada-wide rent report we look at recent market trends, overall rent rates, and affordability metrics to provide a near real-time analysis of the state of the Canadian rental market. </p>
                </div>
                
                <div className="page-content-section">
                    <h1>affordability</h1>
                    
                    <p>Rental rates across Canada for individuals as well as multi-income families have become increasingly less affordable throughout 2022. </p>
                    <p>Not surprisingly, Vancouver leads the pack of unaffordable cities with 123% of an average individual's income being spent on rent. Toronto (118%), Victoria (87%), and Kitchener/Waterloo/Cambridge (87%) are close behind in terms of unaffordability for individuals based on an individual’s average income. These factors are unsurprising as of late, and are driving the need for creative housing solutions such as obtaining roommates, multi-generational housing, or room subletting in order to make rent.</p>
                    <p>In addition, historically affordable markets have been rapidly becoming less affordable, with pandemic-driven migration trends exacerbating housing shortages. Average rent-to-income rates for Peterborough (81%), Brantford (80%), Niagara/St.Catharines (79%), and Halifax (77%) are pushing the limits for individual tenants, creating housing situations akin to large urban centers.</p>
                    <p>The good news is that there are still some affordable cities for individuals, including Trois-Rivières (39%), Regina (44%), Edmonton (47%), Saskatoon (51%), and Thunder Bay (51%).</p>
                    <p>When looking at housing affordability based on household income for larger families, we can see the same patterns in major markets. Vancouver once again has families spending upwards of 74% of their household income on rent, with Toronto (73%), Victoria (53%), Montreal (50%), Abbotsford (50%), Kelowna (50%), and Kitchener/Waterloo/Cambridge (52%) all fairly unaffordable. These major markets all exceed the newly accepted rule that only 30-50% of household income should be spent on rent, leaving tenants with less financial security —and landlords less confident in a tenant’s ability to pay rent. </p>
                    <p>Affordable cities for families include Trois-Rivières (28%), Edmonton (29%), Regina (29%), Thunder Bay (30%), Sault St. Marie (30%), and Saskatoon (31%), as well as major cities such as Winnipeg (35%), Ottawa (36%), and Calgary (39%).</p>
                    
                    <h2 className="text-left">affordability across the country</h2>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/affordabilityChart.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/affordabilityScale.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>average days-on-market</h1>
                    
                    <p>Across the country, not just in large urban areas, we are seeing high demand and low average days-on-market for rental properties. Windsor, Ontario led the country with the shortest average days on market at 8, followed closely with the prairie cities of Calgary, Saskatoon, and Edmonton at 9 and 10 days respectively. Out east, Halifax and Moncton saw units snapped up within 9 days. In Western Ontario, London and Brantford saw listings stay on the market for longer periods of time, with an average DOM of 14. In the Northwest Territories, Yellowknife saw listings stay on market for an average DOM of 18. With average DOM being less than 3 weeks, landlords and investors can expect a quick turnaround to fill their vacancies without losing monthly rent.</p>
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/daysOnMarket.png")} alt="Banner" />
                    
                    <div className="page-content-section-footnote">
                        <p>1.  The days-on-market (DOM) metric is calculated by tracking public listings for changes in status from an active to an expired or removed listing. This is calculated on a per city basis for the time period of October 1st to October 31st.</p>
                    </div>
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 3 bedroom (houses)</h1>
                    
                    <p>Over the last year, we saw the most drastic increases in median rent in Regina ($1,800) and Calgary ($2,400), as both had over 33% median rent hikes. Since last month, Calgary might be showing some stabilization with near-identical median rents since last month, while Regina continues its rise with a small 2.9% increase. In Ontario, Waterloo has experienced the highest year-over-year rise in 3 bedroom home rent amounts, with an over 33% increase, although  it is showing signs of stabilization since last month, with a slight decline of 3.1%. 
Vancouver (13.1%), Toronto (12.9%), Moncton (12.2%), and Montreal (11.1%) continue to see MoM rental increases with no signs of slowing down. Brantford, a market that investors have focused on for large returns these last two years, continues a steady increased MoM pace, (8.7%).</p>

                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/nationalRentSnapshotByCity-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/nationalRentSnapshotByCity-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 3 bedroom (all dwellings)</h1>
                    
                    <p>Quebec City led the Canadian market in YoY increases in all 3 bedroom dwellings, with an increase of 44.6%. The majority of the samples include 3 bedroom condo units, which experienced strong rent increases putting the median 3 bedroom rent for the city at $1,825/month.
                        <br /><br />
                        Top growing rental cities MoM include St. Catharines (14%) and Montreal (11.1%). Stabilizing markets include Burlington and Mississauga at 0% respectively. Declining markets include Waterloo (-9.1%) and Halifax (6.5%).
                    </p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates3BedroomAllDwellings-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates3BedroomAllDwellings-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 2 bedroom (condo/apartments)</h1>
                    
                    <p>Although London has experienced the greatest YoY gain of any market at +48%, they have seen the market correct itself MoM where rental prices have softened and median rent prices have fallen by 13.8% to $2,070, followed by Brampton (+38.1%), Mississauga (+31.8%), Toronto (+30.2%), and Calgary (+30%).
MoM, Mississauga has seen the highest surge in rent for investors now at $2,900/month (+15.2%), followed by Kitchener (12.5%), Guelph (12.4%), Oshawa (11.7%) and Montreal (8.2%).</p>

                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates2Bedroom-CondoApt-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates2Bedroom-CondoApt-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 2 bedroom (all dwellings)</h1>
                    
                    <p>Quite like the 2 bed condo/apartment numbers, London saw the most significant increase YoY of any market at +43.6%, although down MoM (-9.4%) with median rent being $2,082/month. This is followed by YoY increases in Surrey (+39%), Vancouver (+34.6%), Toronto (+27.4%), and Burlington (+27%).
                    <br /><br />MoM, the biggest mover in the 2 bedroom market appeared to be Kingston, where median rent prices are showing an increase of 16.3% overlast month, at $2,195/month, followed by Surrey (+12.5%), Guelph (+11.1%), Kanata (9.5%), and Kitchener (+9.3%).</p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates2BedRoom-allDwelling-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates2BedRoom-allDwelling-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 1 bedroom (condo/apartments)</h1>
                    
                    <p>There were large YoY increases in most Canadian markets, the highest being Mississauga (+33.3%) with rent now at $2,400/month, followed by Vancouver (+32.3%), Laval (+30.6%), Toronto (+29.5%), and North York (+27.8%).
                        <br /><br />
                        Some significant market correction occurred in markets in MOM rent in October, such as Windsor with an 8.3% decline in month-over-month rent to $1,100 and Kingston down 9.3% with rent at $1,572. The larger markets of Vancouver and Toronto might also be showing some signs of stabilization in 1 bedroom condo/apartment units, with a 8.3% decline and 0.9% increase respectively, and median rent rates of $2,750 and $2,421 respectively. Winnipeg ($1,121) and Saskatoon ($1,134) saw some of the largest gains from last month with 15% and 14.8% respectively as well.
                    </p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates-1bedroom-condoApt-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates-1bedroom-condoApt-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent rates - 1 bedroom (all dwellings)</h1>
                    
                    <p>Vancouver leads all YoY rental increases in the 1 bedroom category, with +31.1% rent now at $2,725, followed by North York (+30.6%), Laval (+30.6%), Peterborough (+30.2%), Welland (+29.5%), and Mississauga (+29.4%). Interestingly, Sudbury ($1,250) and Halifax ($1,425) were the only cities to exhibit a year-over-year decrease, with 13.7% and 6.6% respectively. 
                        <br /><br />MoM rental increases are lead by North York (+23.6%), with rent being $2,200/month, followed by Winnipeg (+20.7%), Scarborough (17.1%), Burlington (16.9%), Mississauga (15.9%), and Thunder Bay (11.7%).
                    </p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates-1bedroom-alldwelling-01.png")} alt="Banner" />
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentRates-1bedroom-alldwelling-02.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent by square foot (all dwelling types)</h1>
                    
                    <p>Vancouver leads the way in highest rent per square foot at $3.74/sq. ft, followed closely by Toronto at $3.60. Of the major cities with populations  over 1 million, Edmonton comes in with the lowest price per square foot at $1.40, helping to keep the reputation of Alberta as a highly affordable province to reside in as a renter. Kitchener, Ontario shows up high on the list as a surprise entry 
commanding $2.10/sq. ft, likely due to the ever-increasing housing shortage and unaffordability in neighbouring Waterloo.</p>

                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentBySqFoot.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent by square foot (condos-apartments)</h1>
                    
                    <p>The average rent per square foot for condos is by the far highest in the country in Vancouver at $4.36/sq. ft, followed by Toronto at $3.82/sq. ft. Outside of the GTA, Victoria and Surrey BC lead the way at $3.27/sq. ft and $3.05/sq. ft respectively. Out in Quebec, Sherbrooke and Trois-Rivieres come out as the most affordable average price per square foot, at just$1.00/sq. Ft.</p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentBySquareFoot-condoApt.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>rent by square foot (houses)</h1>
                    
                    <p>Rent per square foot comes down significantly when looking at house rents, as generally we see larger spaces for more affordable prices outside of core urban neighbourhoods. Vancouver leads the way at $2.91/sq. ft followed by Toronto at $2.88. Out east, St. John’s Newfoundland provides the largest space for least cost at $1.27/sq. Ft.</p>
                    
                    <Image src={require("../../../_assets/images/landing-pages/standalone-landing/rental-report-oct-2022/rentBySqFoot-houses.png")} alt="Banner" />
                </div>
                
                <div className="page-content-section">
                    <h1>Glossary</h1>
                    
                    <p><strong>Median rent vs. average</strong><br />
                    Median is used as a more accurate portrayal of market rent, as average rent can be skewed by extreme lows or highs in just a few properties within a market with a smaller data set of rentals.</p>

                    <p><strong>Affordability</strong><br />
                    An analysis and comparison of affordability in major cities across the country. Affordability is calculated using median household income numbers along with median rent rates and mapping to a scale.
                    </p>

                    <p><strong>Family household</strong><br />
                    A family household or couple family is a family where the reference person has a married spouse or common-law partner in the family, regardless of whether or not the reference person also has children.</p>

                    <p><strong>Individual household</strong><br />
                    An individual household is an individual who is not part of a census family, couple family, or lone-parent family. Persons not in census families may live with their married children or with their children who have children of their own. They may be living with a family to whom they are related or unrelated. They may also be living alone or with other non-family persons.</p>

                    <p><strong>Vacancy</strong><br />
                    An analysis of vacancy rates across the province of Ontario.</p>

                    <p><strong>Rent by square foot</strong><br />
                    Rent by square foot (sq. ft) is summarized by first calculating the rent per sq. ft cost of individual listings that contain square footage information, this is then averaged on a per-city basis.</p>

                    <p><strong>Days on market</strong><br />
                    The average # of days a rental unit has been listed on the market, factoring in multiple listing websites.</p>
                </div>
                
                <div className="page-content-section">
                    <h1>note on data sources</h1>
                    
                    <p>With unique insights from affordability, days on market, rent rates by housing type, and rent rates from both large and tertiary, smaller markets, we believe that our report is the most comprehensive and accurate snapshot of the rental market in Canada to date. </p>

                    <p>Our analysis in this document is based on advertised properties for rent across multiple listing sites in Canada. </p>

                    <p>Our data within the report is manually analyzed, then aggregated using four of the top ten rental listing sites used nationally by landlords and investors. “All dwelling types” includes the following types of homes: rental apartments, condominium apartments, basement apartments, duplexes, individual units, townhomes and single detached houses. The “house” type includes single detached houses, duplex and townhomes. The “condo” type includes condominium apartments and rental apartments.</p>

                    <p>Where noted, cities that have an asterisk (*) next to them are calculated using a smaller sample size.</p>
                    
                    <p><strong>Median rent</strong><br />
                    Calculated using the total number of # of rentals across top listing sites and finding the median rent amount per building type, unit size, to get an accurate representation of the rental norm amounts per market.</p>
                    
                    <p><strong>Affordability methodology</strong><br />
                    Affordability for family households is calculated by comparing the average family household monthly income to the average monthly rent in the major cities. A rating to rank affordability was developed based on this ratio. The Golden Rule of 30% of income to rent played a role in determining the affordability of a city.</p>
                </div>
                
                <div className="page-content-section">
                    <h1>our partnership</h1>
                
                    <p>Rent Panda, in collaboration with Door Insight, have created a market-leading report outlining an accurate portrayal of the national rental market in Canada with key indicators of the current state and future state of the rental industry.</p>
                    
                    <p>We strive to continually provide an up-to-date and accurate rental analysis for investors and landlords to inform the decision-making process for their investments.</p>

                    <h2>About Rent Panda</h2>
                    <p>Rent Panda is a tech-enabled Canadian real estate company. Our mission is to empower small landlords with innovative tools and services that make the landlording experience possible - helping small landlords effectively find tenants in Ontario. From a free listing platform for do-it-yourself landlords, to a white-glove leasing service for more passive landlords, Rent Panda offers the most comprehensive services in the industry, making it safer for all parties to rent. For more information visit www.rentpanda.ca or follow us on Instagram, Facebook and LinkedIn.</p>

                    <h2>About Door Insight</h2>
                    <p>Door Insight is a real estate intelligence platform that analyzes Canadian real estate data to make information accessible and digestible for consumers and professionals. Their mission is to arm Canadians with transparency and insights to make informed real estate decisions. Door Insight offers a leading Rent Estimator, insightful neighbourhood maps, and tools to aid in the due diligence process. For your Canadian real estate research needs visit doorinsight.com for more information.</p>
                </div>
            </div>
        </LandingPage>
    );
}

export default withRouter(LandingPageRentalReportOctober2022);