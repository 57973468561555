export const EditProfileValidationSchema = {
    email: [
        { rule: "required", message: "Email required" },
        { rule: "email", message: "Email is invalid" }
    ],
    firstName: [
        { rule: "required", message: "First name required" },
    ],
    lastName: [
        { rule: "required", message: "Last name required" },
    ],
    phone_number: [
        { rule: "required", message: "Phone number required" },
    ],
}