import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form, Collapse } from 'react-bootstrap';
import { getAPI, postAPI } from '../../../../../Api';
import { LargeButton, CancelButton, ModalBox, SidePanel } from '../../../../components';
import { validate } from '../../../../../Utils/Validation/validation';
import { withRouter } from 'react-router-dom';


const DeactivateListing = (props) => {
    const { isShow, handleClose, apiCallback, setOpenDeactivate, getReasonData } = props;

    const [property_details_id, setProperty_details_id] = useState('');
    const [reasonList, setReasonList] = useState([]);
    const [openForOrder1, setOpenForOrder1] = useState(false)
    const [selectedReason, setSelectedReason] = useState(null);
    const [openForOrder1Confirm, setOrder1Confirm] = useState(false);
    const [deactiveReasonSix, setDeactiveReasonSix] = useState('');
    const [accordianArrow, setAccorrdianArrow] = useState(false);

    const clickOnReason = (reasonData) => {
        setSelectedReason(reasonData)
        if ((reasonData.order === 1 && reasonData.id === 1) || (reasonData.order === 3 && reasonData.id === 3)) {
            setOpenForOrder1(true);
            setOpenDeactivate(false);
        } else if ((reasonData.order === 2 && reasonData.id === 2) || (reasonData.order === 4 && reasonData.id === 4) || (reasonData.order === 5 && reasonData.id === 5)) {
            getReasonData(reasonData);
            setOpenDeactivate(false);
        }
    }
    const closeOrder1Modal = () => {
        setOpenForOrder1(false)
        apiCallback();
    }
    const toggleConfirm = () => {
        setOrder1Confirm(!openForOrder1Confirm)
    }
    const handleCloseConfirm = () => {
        handleClose();
        apiCallback();
        setAccorrdianArrow(false);
        setSelectedReason(null);
    }
    const submitOrder1 = () => {
        const url = '/admin/listing/deactive/' + property_details_id;
        const data = {
            'deactivate_reason': selectedReason && selectedReason.order,
            'deactivate_description': selectedReason && selectedReason.deactive_reason,
            'is_active': 0
        }
        postAPI(url, data).then(res => {
            if (res.status === 200) {
                setOpenForOrder1(false);
                setOrder1Confirm(true);
                apiCallback();
            }
        })
    }
    const handleAccordian = (accordian, reason) => {
        setAccorrdianArrow(accordian);
        setSelectedReason(reason);
    }
    const ValidationSchema = {
        deactiveReasonSix: [
            { rule: 'required', message: 'Reason is required' },
        ]
    }
    const submitOrder6 = () => {
        if (validate(ValidationSchema)) {
            const url = '/admin/listing/deactive/' + property_details_id;
            const data = {
                'deactivate_reason': selectedReason && selectedReason.order,
                'deactivate_description': deactiveReasonSix,
                'is_active': 0
            }
            postAPI(url, data).then(res => {
                if (res.status === 200) {
                    setOpenDeactivate(false);
                    toggleConfirm();
                    apiCallback();
                    setDeactiveReasonSix('')
                    setAccorrdianArrow(false)
                }
            })
        }
    }
    useEffect(() => {
        let url = '/admin/listing/lease/get-deactive-reasons';
        getAPI(url).then(res => {
            if (res.status === 200) {
                setReasonList(res.data.data)
            }
        })
    }, [property_details_id])

    useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id]);


    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            extraClasses={'deactivate'}
            title='Deactivate Listing'>
            <h4 className='edit-sub'>Why is the landlord deactivating this property?</h4>
            <div className='deactive-reasons sidepanel-body'>
                {reasonList && reasonList.map((reason, i) => {
                    return <React.Fragment key={'reason' + i}>
                        {reason.order !== 6 ?
                            <Row className='p-4 border-reason cursorPointer' onClick={() => clickOnReason(reason)}>
                                <Col className='pl-0 pr-0 change-heading d-flex justify-content-between' md={12}>
                                    {reason.deactive_reason}
                                    <img className='next-arrow' src={require('../../../../../_assets/images/next.svg')} alt='next' />
                                </Col>
                            </Row> :
                            <Row className='p-4 border-reason'>
                                <Col md={12} className='pl-0 pr-0 change-heading'>
                                    <span>{reason.deactive_reason}</span>
                                    <img className='next-arrow pull-right' onClick={() => handleAccordian(!accordianArrow, reason)} src={accordianArrow === false ? require('../../../../../_assets/images/next.svg') : require('../../../../../_assets/images/nextDown.svg')} alt='next' />
                                    <Collapse in={accordianArrow}>
                                        <form id='Form'>
                                            <Row className='mt-4'>
                                                <Col md={12}>
                                                    <Form.Control
                                                        type='text'
                                                        as='textarea'
                                                        rows={6}
                                                        name='deactiveReasonSix'
                                                        id='deactiveReasonSix'
                                                        value={deactiveReasonSix}
                                                        placeholder='If other reason, please tell us more why the landlord wants to deactivate'
                                                        onChange={(e) => setDeactiveReasonSix(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </form>
                                    </Collapse>
                                </Col>
                            </Row>
                        }
                    </React.Fragment>

                })}
            </div>
            {accordianArrow === true &&
                <div className='fixed-bottom d-flex justify-content-between'>
                    <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                    <LargeButton title='deactivate' onClick={() => submitOrder6()} style={{ width: '200px' }} />
                </div>
            }

        </SidePanel>
        <ModalBox
            show={openForOrder1}
            closeButtonHeader={false}
            size='sm'
            type='condition'
            actionbuttontitle={'Yes'}
            buttonaction={() => submitOrder1()}
            backbuttontitle={'Cancel'}
            backaction={() => closeOrder1Modal()}
            extraClassUpper={'deactive3'}
        >
            <p className='order-one-modal pt-3'>
                {selectedReason && selectedReason.order === 1 && `Are you sure you want to deactivate this listing for the landlord?`}
                {selectedReason && selectedReason.order === 3 && `Are you sure you want to deactivate this listing?`}
            </p>
        </ModalBox>
        <ModalBox
            show={openForOrder1Confirm}
            onHide={() => toggleConfirm()}
            size='sm'
            actionbuttontitle={'got it'}
            buttonaction={() => toggleConfirm()}
            extraClassUpper={'deactive3Confirm'}
        >
            <p className='deactive-confirm'>This Listing has been deactivated </p>
        </ModalBox>
    </>);
}
export default withRouter(DeactivateListing);