export const PartnerValidationSchemal = {
    company_name: [
        { rule: "required", message: "company name required" }
    ],
    company_address: [
        { rule: "required", message: "company address required" },
    ],
    contact_number: [
        { rule: "required", message: "contact number required" },
        { rule: "phone", length: 10, message: "Phone number must be of 10 digits" },
    ],
    email_partner: [
        { rule: "required", message: "email required" },
        { rule: "email", message: "Email is invalid" }
    ],
    partner_desc: [
        { rule: "required", message: "description required" },
    ],
}