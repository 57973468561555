export const PetInfoValidationSchema = {
    pet_count: [
        { rule: "required", message: "Pet count required" },
    ],
    // pet_description: [
    //     { rule: "required", message: "Pet description required" }
    // ],
    pet_deposit_amount: [
        { rule: "required", message: "Pet deposit amount required" },
    ]
}