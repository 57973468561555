import React from 'react';
import { withRouter } from 'react-router-dom';
import { TableLandlord } from '../../components';
import { postAPI } from '../../../Api';
import { getCurrentPage } from '../../../Store/actions/Action';
import { updateRawData } from '../../../Store/actions/RawDataAction'
import { connect } from 'react-redux';
import { multiCheckbox } from '../../../Utils/MultiCheckbox/MultiCheckbox';
import UserManagementTable from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import { commaSanitizer } from '../../../Utils/NumberSanitizer/NumberSanitizer';

class LandlordTableComponent extends React.Component {
    state = {
        tableData: [],
        type: '',
        user_id: '',
        path: '',
        component: 'listing',
        user_type: '',
        searchLocation: '',
        sort_by_price: 'all',
        sort_by_tenant: '',
        sort_by_type: [],
        sort_by_status: [],
        currentPage: null,
        adminListingCurrentPage: null,
        previousPage: null,
        is_verified: false,
        isLoading: true,
        userColumns: [
            {
                header: 'isVerified',
                width: '5%'
            },
            {
                header: 'ADDRESS',
                width: '30%'
            },
            {
                header: 'PRICE',
                width: '15%'
            },
            {
                header: 'TENANT',
                width: '15%'
            },
            {
                header: 'TYPE',
                width: '15%'
            },
            {
                header: 'STATUS',
                width: '20%'
            }
        ]
    }
    componentDidMount = () => {
        console.log(this.props)
        this.setState({
            user_id: this.props.match.params.user_id,
            user_type: this.props.match.params.user_type,
            currentPage: this.props.currentPage,
            // currentPage: 1,
            adminListingCurrentPage: this.props.adminListingCurrentPage,
            previousPage: this.props.previousPage,
            path: this.props.location.state && this.props.location.state.path
        }, () => {
            if (this.state.adminListingCurrentPage !== null) {
                const urlNext = `/admin/users/landlord/listing/${this.state.user_id}?page=${this.state.adminListingCurrentPage}`;
                this.getData(urlNext);
            } else if (this.state.user_type === 'landlord') {
                this.getData(`/admin/users/landlord/listing/${this.state.user_id}`);
            }
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.user_id !== this.props.user_id) {
            this.setState({
                tableData: this.props.data,
                type: this.props.type,
                user_id: this.props.user_id,
                component: this.props.component,
                path: this.props.location.state.path
            }, () => {
                if (this.state.user_type === 'landlord') {
                    this.getData(`/admin/users/landlord/listing/${this.state.user_id}`);
                }
            }
            )
        }
    }

    insertInStore = (key, value) => updateRawData({ [key]: value });

    getData = (fetchedUrl) => {
        // let val = null;
        // let valDeactivate = null;
        // if (this.state.sort_by_status.length !== 0) {
        //     val = this.state.sort_by_status.includes('0');
        //     valDeactivate = this.state.sort_by_status.includes('5');
        // }

        const status = this.state.sort_by_status.includes('all') ? [] : this.state.sort_by_status;
        const property_type = this.state.sort_by_type.includes('all') ? [] : this.state.sort_by_type;
        const dataAssigned = {
            'filter': {
                'city': '',
                'address': commaSanitizer(this.state.searchLocation),
                'landlord': '',
                'tenant': this.state.sort_by_tenant,
                'property_type': property_type,
                'status': status,
                // 'is_verified': val === true ? 1 : null,
                // 'is_active': valDeactivate === true ? 0 : null,
            },
            'is_verified': this.state.is_verified === false ? 0 : 1,
            'sort_by_price': {
                'order': this.state.sort_by_price
            },
            'limit': 10
        }
        const url = fetchedUrl ? fetchedUrl : `/admin/users/landlord/listing/${this.state.user_id}`;
        if (this.state.user_id !== '') {
            postAPI(url, dataAssigned)
                .then(response => {
                    if (response.status === 200) {
                        const res = response.data.data.listing;
                        this.setState({ tableData: res, isLoading: false })
                    }
                })
        }
    }
    
    onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        this.props.dispatch(getCurrentPage(nextPage));
        this.insertInStore("adminListingCurrentPage", nextPage);
        const urlNext = `/admin/users/landlord/listing/${this.state.user_id}?page=${nextPage}`;
        this.getData(urlNext);
    }

    onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        this.props.dispatch(getCurrentPage(prevPage));
        this.insertInStore("adminListingCurrentPage", prevPage);
        const urlPrve = `/admin/users/landlord/listing/${this.state.user_id}?page=${prevPage}`;
        this.getData(urlPrve);
    }

    goToProfileDetail = (user) => {
        console.log(user.user_type.toLowerCase())
        this.props.history.push({
            pathname: '/profile-detail/listings/' + user.user_type.toLowerCase() + '/' + user.user_id + '/' + user.property_id + '/' + user.property_details_id,
            state: { path: this.state.path }
        });
    }
    sortType = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(this.state.sort_by_type, item, isChecked);
        this.setState({ sort_by_type: fetchedFilter }, () => {
            this.getData(`/admin/users/landlord/listing/${this.state.user_id}`);
        })
    }
    sortStatus = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(this.state.sort_by_status, item, isChecked);
        this.setState({ sort_by_status: fetchedFilter }, () => {
            this.getData(`/admin/users/landlord/listing/${this.state.user_id}`);
        })
    }
    setSortByPrice = (value) => {
        this.setState({
            sort_by_price: value
        }, () => {
            this.getData(`/admin/users/landlord/listing/${this.state.user_id}`)
        })
    }
    setSortByTenant = (value) => {
        this.setState({
            sort_by_tenant: value
        }, () => {
            this.getData(`/admin/users/landlord/listing/${this.state.user_id}`)
        })
    }
    setIsVerified = (value) => {
        this.setState({
            is_verified: !this.state.is_verified
        }, () => {
            this.getData(`/admin/users/landlord/listing/${this.state.user_id}`)
        })
    }
    setSearch = (e) => {
        this.setState({ searchLocation: e }, () => {
            // let val = null;
            // let valDeactivate = null;
            // if (this.state.sort_by_status.length !== 0) {
            //     val = this.state.sort_by_status.includes('0');
            //     valDeactivate = this.state.sort_by_status.includes('5');
            // }
            const dataAssigned = {
                'filter': {
                    'city': '',
                    'address': commaSanitizer(this.state.searchLocation),
                    'landlord': '',
                    'tenant': this.state.sort_by_tenant,
                    'property_type': this.state.sort_by_type,
                    'status': this.state.sort_by_status,
                    // 'is_verified': val === true ? 1 : null,
                    // 'is_active': valDeactivate === true ? 0 : null,
                },
                'sort_by_price': {
                    'order': this.state.sort_by_price
                },
                'limit': 10
            }
            if (this.state.searchLocation !== null) {
                const url = `/admin/users/landlord/listing/${this.state.user_id}`;
                if (this.state.user_id !== '') {
                    postAPI(url, dataAssigned)
                        .then(response => {
                            if (response.status === 200) {
                                const res = response.data.data.listing;
                                this.setState({ tableData: res })
                            }
                        })
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading === true ? <UserManagementTable columns={this.state.userColumns} component={'lease'} /> : <>
                    {this.state.tableData && this.state.tableData.data ?
                        <TableLandlord
                            columns={this.state.userColumns}
                            tableData={this.state.tableData.data}
                            to={this.state.tableData.to}
                            from={this.state.tableData.from}
                            current_page={this.state.tableData.current_page}
                            last_page={this.state.tableData.last_page}
                            per_page={this.state.tableData.per_page}
                            total={this.state.tableData.total}
                            goToProfileDetail={(data) => this.goToProfileDetail(data)}
                            clickNext={() => this.onClickNext(this.state.tableData.current_page)}
                            clickPrev={() => this.onClickPrev(this.state.tableData.current_page)}
                            setSearch={(data) => this.setSearch(data)}
                            searchLocation={this.state.searchLocation}
                            setSortByPrice={(data) => this.setSortByPrice(data)}
                            onSortTenant={(data) => this.setSortByTenant(data)}
                            sort_by_type={this.state.sort_by_type}
                            is_verified={this.state.is_verified}
                            setIsVerified={(data) => this.setIsVerified(data)}
                            sortType={(data) => this.sortType(data)}
                            sort_by_status={this.state.sort_by_status}
                            sortStatus={(data) => this.sortStatus(data)}
                            component={this.state.component}
                        />
                        : ''}
                </>}
            </React.Fragment>
        );
    }
}
const select = (state) => ({
    currentPage: state.propertyDetailsReducer.currentPage,
    adminListingCurrentPage: state.rawData.adminListingCurrentPage,
});
export default connect(select)(withRouter(LandlordTableComponent));