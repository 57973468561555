import { bathroomsData, bedroomsData, isSharedBathroomData, is_furnishedData, is_parking_includedData, pet_policyData } from "../../Constants/propertyDetails";

export const bedroomTitle = (value) => {
    var title = "";
    bedroomsData.map((data) => {
        if (Number(value) === data.value) {
            title = (data.title);
        }
        return data;
    })
    return title;
}

export const bathroomTitle = (value) => {
    var title = "";
    bathroomsData.map((data) => {
        if (Number(value) === data.value) {
            title = (data.title);
        }
        return data;
    })
    return title;
}

export const sharedBathroomTitle = (value) => {
    var title = "";
    isSharedBathroomData.map((data) => {
        if (Number(value) === data.value) {
            title = (data.title);
        }
        return data;
    })
    return title;
}


export const furnishedDataTitle = (value) => {
    var title = "";
    is_furnishedData.map((data) => {
        if (value === data.value) {
            title = (data.display)
        }
        return data;
    })
    return title;
}

export const petAndPolicyTitle = (value) => {
    var title = "";
    pet_policyData.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}

export const parkingIncludeTitle = (value, slot) => {
    var title = "";
    is_parking_includedData.map((data) => {
        if (value === data.value) {
            title = (slot !== 0 ? (slot) + ' ' + data.display : data.display)
        }
        return data;
    })
    return title;
}

export const laundryDataTitle = (laundryData, value) => {
    var title = "";
    laundryData.map((data) => {
        if (value === data.value) {
            title = ('Laundry ' + data.title)
        }
        return data;
    })
    return title;
}
