import React, { useState } from "react";
import { SidePanel, ModalBox, DatePickerComponent, LargeButton } from "../../../components"
import { Form, Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { postAPI } from "../../../../Api";
import { validate } from "../../../../Utils/Validation/validation";
import { ProfessionalPhotoshootSchema } from './ValidationSchema/ProfessionalPhotoshootSchema';
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage, getUnitDetail } from "../../../../Store/actions/PropertyDetailAction";
import { toaster } from "../../../../Utils";

const ProfessionalPhotoShoot = (props) => {
  // debugger
  const { isShow, handleClose, title, subTitle, type } = props
  const [date, setDate] = useState(new Date());
  const [appointment_time, setAppointment_time] = useState('select');
  const [phone_number, setPhone_number] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;

  const OnChangeInput = (event) => {
    if (event.target.name === 'appointment_time') {
      setAppointment_time(event.target.value)
    } else if (event.target.name === 'phone_number') {
      setPhone_number(event.target.value)
    }
    (validate(ProfessionalPhotoshootSchema))
  }

  const onSubmit = () => {
    if (validate(ProfessionalPhotoshootSchema)) {
      setLoader(true)
      const url = '/landlord/create-listing/step-5/professional-photoshoot';
      const phone = phone_number.replace(/\D+/g, '')
      const data = {
        property_id: property_id,
        property_details_id: property_details_id,
        appointment_date: moment(date).format('YYYY-MM-DD'),
        appointment_time: appointment_time,
        phone_number: phone,
        type: type
      }
      postAPI(url, data)
      .then(response => {
        if(response.status === 200){
          setLoader(false)
          handleClose()
          setModalShow(true)
        }else if(response.response){
          if(response.response.status === 422){
            setLoader(false)
            toaster('error', response.response.data.message)
          }else if(response.response.status === 400){
            setLoader(false)
            toaster('error', response.response.data.message)
          }
        }
      })
      .catch(error => {
        setLoader(false)
        toaster('error', 'Something went wrong')
      })
    }
    // setLoader(false)
  }
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={title}
      subTitle={subTitle}>
      <Form id="Form">
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What date can we get in touch with you</Form.Label>
            <DatePickerComponent
              name="date"
              id="date"
              value={date}
              handleDateChange={date => setDate(date)}
              displayText={"Select Date"}
              minDate={new Date()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>When would be best to chat?</Form.Label>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="appointment_time"
              custom
              name="appointment_time"
              value={appointment_time}
              onChange={(e) => OnChangeInput(e)}
            >
              <option value="">Select one</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              className="form-control"
              name="phone_number"
              id="phone_number"
              value={phone_number}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>
      </Form>
      <div data-description="Landlord submitted request for pro photography" id="BTN-listing-step-5-photo-pro-request" className="container-footer">
        <LargeButton saveLoading={loader} isDisable={loader}  variant="success" onClick={onSubmit} type='button' title='Submit' />
      </div>
    </SidePanel>

    { type === 1 ? <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => {
        setModalShow(false)
        dispatch(getCurrentPage(6))
      }}
    >
      <h4>thanks for letting us know when to reach you</h4>
      <p style={{ fontSize: '20px' }}>our team will be in touch within 24 hours to book a time for your photoshoot!</p>
    </ModalBox>:
    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => {
        setModalShow(false)
        dispatch(getCurrentPage(6))
      }}
    >
      <h4>thanks for letting us know when to reach you</h4>
      <p style={{ fontSize: '20px' }}>our team will be in touch within 24 hours to book a time for your videoshoot!</p>
    </ModalBox>}
  </>);
}

export default ProfessionalPhotoShoot;
