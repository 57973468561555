import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { validate } from '../../../../Utils/Validation/validation';
import { LargeButton } from '../../../components';
import { toaster } from '../../../../Utils';
import { emptyLeadingSpaceSanitizer } from '../../../../Utils/DateSanitizer/DateSanitizer';

const InternalNotes = (props) => {
    const { property_details_id, apiCallback } = props;
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);

    const [notes, setNotes] = useState('');
    const maxLength = 250;
    const [remainNotesChar, setRemainNotesChar] = useState(maxLength);
    const [disableBtn, setBtnDisable] = useState(false);
    const ValidationSchema = {
        notes: [
            { rule: 'required', message: 'notes is required' },
        ]
    }

    const handleChange = (e) => {
        const noteWithLimit = emptyLeadingSpaceSanitizer(e.target.value).substring(0, maxLength);
        setRemainNotesChar(maxLength - noteWithLimit.length);
        // setNotes(noteWithLimit);
        setNotes(emptyLeadingSpaceSanitizer(e.target.value));
    }
    const onSubmit = (event, props) => {
        event.preventDefault();
        if (validate(ValidationSchema)) {
            setBtnDisable(true)
            const url = '/admin/listing/update/notes/' + property_details_id;
            const data = {
                'notes': notes
            }
            postAPI(url, data).then(res => {
                if (res.status === 200) {
                    apiCallback();
                    setBtnDisable(false)
                    toaster('success', 'Notes saved successfully');
                }
            })
        }
    }
    useEffect(() => {
        if (unitDetails) {
            setNotes(unitDetails.notes)
        }
    }, [unitDetails])

    return (
        <>
            <div className='inner-wrapper'>
                <h6 className='mb-4'>Internal Notes</h6>
                <form id='Form' onSubmit={onSubmit}>
                    <Row>
                        <Col md={12}>
                            <Form.Control
                                type='text'
                                as='textarea'
                                rows={10}
                                name='notes'
                                id='notes'
                                value={notes}
                                placeholder='Write some text here...'
                                onChange={(e) => handleChange(e)}
                                // maxLength={maxLength}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="d-flex invisible">
                            <span className="charLimit">{`${remainNotesChar} / ${maxLength}`}</span>
                        </Col>
                        <Col xs={6}>
                            <LargeButton saveLoading={disableBtn} title='save notes' type='submit' style={{ width: '200px', float: 'right' }} />
                        </Col>
                    </Row>

                </form>
            </div>

        </>
    )
}

export default withRouter(InternalNotes);