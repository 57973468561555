import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from '../../../../Api';
import { getTenantsList } from '../../../../Store/actions/AddedTenantAction';
import { logger, toaster } from '../../../../Utils';
import { LargeButton, CancelButton, ModalBox, SidePanel } from '../../../components';
import SearchInput from '../ListUsableComponent/SearchInput';

const log = logger('CheckListTenant');
const CheckListTenant = (props) => {
	const { isShow, handleClose, apiCallback, callAddTenant, openCreateLeaseOption, from, openLeaseDuration, setTenantList, selectedTenants } = props;

	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [openForOrder1Confirm, setOrder1Confirm] = useState(false);
	const [searchLocation] = useState(null);
	const [serachData, setSearchData] = useState([]);
	const [selectedData, setSelectedData] = useState(selectedTenants);
	const addedTenantReducer = useSelector(state => state.addedTenantReducer.addedTenants);
	const dispatch = useDispatch();

	const [reducerData] = useState(addedTenantReducer !== null ? addedTenantReducer[0] : [])
	const [tenantList, setRentpandaTenant] = useState();

	const toggleConfirm = () => {
		setOrder1Confirm(!openForOrder1Confirm)
	}
	const handleCloseConfirm = () => {
		if (from === 'upload-lease') {
			handleClose();
			openLeaseDuration(true)
		} else {
			handleClose();
			apiCallback();
			dispatch(getTenantsList(null))
		}

	}
	const onValueChange = (e, data) => {
		const item = e.target.name;
		const isChecked = e.target.checked;
		log.info(item, isChecked);
		isChecked === true ? selectedData.push(data) : selectedData.splice(selectedData.indexOf(data), 1);
		setSelectedData(state => state)
	}
	const reasonTwoNext = () => {
		const url = '/admin/listing/lease/confirm-tenant';
		if (selectedData.length !== 0) {
			let data = [];
			selectedData.map((selected) => {
				log.info(selected)
				const renewed = {
					'property_id': property_id,
					'property_detail_id': property_details_id,
					'tenant_id': selected.tenant_id,
				}
				data.push(renewed);
				return selected;
			})
			setTenantList(selectedData)
			postAPI(url, data).then(res => {
				if (res.status === 200) {
					log.info('from', from)
					if (from === 'upload-lease') {
						log.info('from inside', from)
						handleClose();
						openLeaseDuration(true)
					} else {
						handleClose();
						openCreateLeaseOption(true);
						dispatch(getTenantsList(null))
					}

				}
			})
		} else {
			toaster('error', 'Need minimum one tenant to be selected!');
		}

	}
	const addtenant = () => {
		handleClose();
		callAddTenant(true);
	}
	const customSearch = (e) => {
		log.info("In execute", e)
		const excludeColumns = [
			"created_at",
			"email",
			"is_invited",
			"tenant_id",
			"updated_at",
			"user_type",
		];
		var updatedList = serachData;

		const lowercasedFilter = e.toLowerCase().trim();
		const filteredData = updatedList.filter((item) => {
			return Object.keys(item).some((key) =>
				excludeColumns.includes(key)
					? false
					: (key === "name" ? item[key].toString().toLowerCase().indexOf(lowercasedFilter) > -1 : item[key].toString().toLowerCase().indexOf(lowercasedFilter) === 0)
			);
		});
		log.info("filteredData", filteredData);
		setRentpandaTenant(filteredData)
	}

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		dispatch(getTenantsList(null))
	}, [props.match.params.property_id, props.match.params.property_details_id]);
	useEffect(() => {
		if (property_id && property_details_id) {
			let url = `/admin/users/get-tenants/${property_id}/${property_details_id}`;
			getAPI(url).then(res => {
				if (res.status === 200) {
					setRentpandaTenant(res.data.data.concat(reducerData))
					setSearchData(res.data.data.concat(reducerData));
				} else {
					setRentpandaTenant([])
				}
			})
		}
	}, [property_id, property_details_id, reducerData])


	return (<div className={'extraClassFor' + from}>

		<SidePanel
			isShow={isShow}
			isHide={() => handleCloseConfirm()}
			title={props.titleUpload === 'uploadTitle' ? 'Select your tenant' : 'create a lease'}>
			<h4 className='edit-sub'>Select a tenant from your contact list</h4>
			<div className={'deactive-reasons sidepanel-body checklist-tenant ' + from}>
				<SearchInput extraCalss={'mb-3'} searchLocation={searchLocation} placeHolder={from === 'upload-lease' ? 'Search user' : 'Type to search tenant'} setSearch={(data) => customSearch(data)} />
				{tenantList && tenantList.map((tenants, i) => {
					return <Row className='p-3 border-reason checkbox-left check-status' key={'tenant' + i}>
						<Col md={12} className='pl-0 pr-0'>
							<div className="form-group m-0">
								<div className="custom-control custom-checkbox large">
									<input type="checkbox" className="custom-control-input" id={"customCheck" + tenants.tenant_id}
										name={tenants.tenant_id}
										checked={selectedData.find(elm => elm.tenant_id === tenants.tenant_id)} onChange={(e) => onValueChange(e, tenants)}
									/>
									<label className="custom-control-label pl-4" htmlFor={"customCheck" + tenants.tenant_id}>{tenants.name} {tenants.is_invited === 1 ? <span className='invited'>invited</span> : ''}</label>
								</div>
							</div>
						</Col>
					</Row>
				})}

				{(tenantList && tenantList.length === 0) && <h4 className='edit-sub text-center'>There is no tenant found</h4>}

				<LargeButton isIcon={true} title='Add tenant outside of Rent Panda' variant='dark' onClick={() => addtenant()} style={{ textTransform: 'none' }} />
			</div>
			<div className='fixed-bottom d-flex justify-content-between'>
				<CancelButton onClick={() => handleCloseConfirm()} title='Back' />
				<LargeButton title={from === 'upload-lease' ? 'add' : 'next'} onClick={() => reasonTwoNext()} style={{ width: '150px' }} />
			</div>
		</SidePanel>
		<ModalBox
			show={openForOrder1Confirm}
			onHide={() => toggleConfirm()}
			size='sm'
			actionbuttontitle={'got it'}
			buttonaction={() => toggleConfirm()}
		>
			<p className='deactive-confirm'>This Listing has been deactivated </p>
		</ModalBox>
	</div>);
}
export default withRouter(CheckListTenant);