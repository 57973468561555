import React, { Component } from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import Offline from './App/components/Offline'
import store from "./configureStore";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import StateProvider from "./StateProvider";
import ErrorBoundry from "./ErrorBoundry";
import { Helmet } from "react-helmet";
import LinkedInTag from 'react-linkedin-insight';
class App extends Component {

  componentDidMount() {
    const linkedInPartnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
    const linkedInConversionId = process.env.REACT_APP_LINKEDIN_CONVERSION_ID;
    
    // Runs after the first render() lifecycle
    LinkedInTag.init(linkedInPartnerId, 'dc', false);
    // LinkedInTag.track(linkedInConversionId);
  }
  
  render() {
    let persistor = persistStore(store)
    return (
      <Provider store={store}>
        <Helmet meta={[
            {
              name: 'description',
              content: 'Rent Panda is the best place to find a rental home online. Browse high quality ads for free, with peace of mind knowing we have verified every unit',
            }
              ]} />
        <Offline>
          <div className="no-internet-connection">No internet connection!</div>
        </Offline>
        <PersistGate loading={null} persistor={persistor}>

        <ErrorBoundry>
          
          <StateProvider>
            <Routes />
          </StateProvider>
        
        </ErrorBoundry>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
