import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N11Schema } from "./ValidationSchema/N11Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";


const N11Form = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    getParalegal,
    triggerReqTenant,
    onBack, onNoticeDataUpdate
  } = props;
  const [terminationDate, setTerminationDateDate] = useState(null);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [globalState,setGlobalState]=useGlobalState()



  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
     onNoticeDataUpdate({
      tenant_move_out:terminationDate,
      signData,phoneError,
      notice_type:11
    })
    setGlobalState({ onSubmit: onSubmit })

  },[
    tenant_id.length,property_id,
    terminationDate,
    phoneError,
    signData,
   ])



  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N11Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 11,
        tenant_move_out: terminationDate,
        agree_terms_and_conditions: "1",
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 14,
      };
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    }
  };

  useEffect(() => {
    if (noticeData ) {
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
       setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Agreement To End Tenancy (N11 Form)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            type={"lastName"}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={11}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N11Form);
