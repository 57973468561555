import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { SidePanel } from "..";
import { postAPI } from "../../../Api";
import moment from 'moment'
import { useSelector } from "react-redux";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ComposedChart, Line, ResponsiveContainer, } from 'recharts';
import "./style.scss";

const ShowAnalytics = (props) => {
    
  const { isShow, handleClose } = props;
  const propertyId = useSelector(state => state.propertyDetailsReducer.propertyDetails.id)
  const propertyDetailsId = useSelector(state => state.propertyDetailsReducer.unitDetails.id)
  const [analyticsDataViewing, setAnalyticsDataViewing] = useState(null)
  const [analyticsDataMessage, setAnalyticsDataMessage] = useState(null)
  const [analyticsDataRequest, setAnalyticsDataRequest] = useState(null)
  const [date1, setDate1] = useState(moment())
  const [date2, setDate2] = useState(moment())
  const [date3, setDate3] = useState(moment())
  const [daysListed, setDaysListed] = useState(0)
  const [disableForward, setDisabledForward] = useState({first: true, second: true, third: true})
  const [barTooltip,setBarTooltip]=useState('')

  let viewData = null
  let viewingData = null
  let messageData = null

  const getAnalyticsData = useCallback((date, meta) => {
    if(propertyId && propertyDetailsId){
      const data = {
        'property_id': String(propertyId),
        'property_details_id': String(propertyDetailsId),
        'start_date': moment(date).subtract(6, 'days').format('YYYY-MM-DD'),
        'end_date': moment(date).format('YYYY-MM-DD')
      }
      postAPI(`/landlord/listing-analytics`, data).then(res => {
          if (res.status === 200) {
            const listedDate = moment(res.data.data.listed_date, 'DD-MM-YYYY')
            setDaysListed(moment(moment(),'DD-MM-YYYY').diff(listedDate, 'days'))
            if(meta === 'all' || meta === 'first') setAnalyticsDataViewing(res.data.data.view_data)
            if(meta === 'all' || meta === 'second') setAnalyticsDataMessage(res.data.data.message_data)
            if(meta === 'all' || meta === 'third') setAnalyticsDataRequest(res.data.data.viewing_request_data)
          }
      })
    }
  }, [propertyId, propertyDetailsId])

  useEffect(() => {
      getAnalyticsData(moment(), 'all')
  }, [getAnalyticsData])

  useEffect(() => {
      getAnalyticsData(date1, 'first')
  }, [date1, getAnalyticsData])

  useEffect(() => {
      getAnalyticsData(date2, 'second')
  }, [date2, getAnalyticsData])

  useEffect(() => {
      getAnalyticsData(date3, 'third')
  }, [date3, getAnalyticsData])

  const prevDateHandler = (date, setDate, meta) => {
    const date1 = moment(date).subtract(6, 'days')
    if(moment(date1).diff(moment(), 'days') <= 0){
      setDisabledForward(prevState =>  { return { ...prevState, [meta] : false }})
    }
    setDate(date1)
  }

  const nextDateHandler = (date, setDate, meta) => {
    const date1 = moment(date).add(6, 'days')
    if(moment(date1).diff(moment(), 'days') >= 0){
      setDisabledForward(prevState =>  { return { ...prevState, [meta] : true }})
      if(moment(date1).diff(moment(), 'days') !== 0)  return
    }
    setDate(date1)
  }
  if(analyticsDataMessage){
    messageData = analyticsDataMessage.map(data => ({...data, name: moment(data.date, 'DD-MM-YYYY').format('MMMM d')}))
  }
  
  if(analyticsDataRequest){
    viewingData = analyticsDataRequest.map(data => ({ ...data, day: moment(data.date, 'DD-MM-YYYY').format('dd') }))
  }
  
  if(analyticsDataViewing){  
    viewData = analyticsDataViewing.map(data => ({ ...data, day: moment(data.date, 'DD-MM-YYYY').format('dd') }))
  }

  const CustomTooltip = ({ active, payload, type }) => {
    if (!active || !barTooltip) 
    {
      return null
    }
    const selectedBar=payload.find((i)=>i.dataKey===barTooltip)

    return selectedBar ? <div className='tooltip-div'>
      {`${moment(selectedBar?.payload?.date, 'DD-MM-YYYY').format('MMM, D')}`} <br />
      {type === 'message' ? 'Messages: ' : type === 'views' ? 'Total Views: ' : 'Viewing Requests: '}
      {selectedBar.value}</div> :
      ''

  }

  return (<>
      <SidePanel
          isShow={isShow}
          isHide={() => handleClose()}
          title="Analytics" >
            
          <div style={{ top: '60px' }} className='sidepanel-body pt-4'>
            
            <div className="no-of-days">
              <h3 style={{ fontWeight: 'bold' }}>{ daysListed ? daysListed : '0' } { daysListed && daysListed > 1 ? 'Days' : 'Day'}</h3>
              <p style={{ margin: '0' }}>Number of days listed for rent</p>
            </div>


            <div className='totalView-wrapper'>
              <p>Total Views</p>
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  width={400}
                  height={300}
                  data={viewData}
                  margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                  }}
                >
                  <CartesianGrid />
                  <XAxis tick={{fill:'#8CC947'}} dataKey="day"  />
                  <Tooltip cursor={{fill: 'none'}} content={ <CustomTooltip type='views' /> } />
                  <Bar radius={[ 5, 5, 0, 0 ]} dataKey="views" barSize={30} fill='#8CC947'
                  onMouseOut={() => setBarTooltip('')}
                  onMouseOver={() => setBarTooltip('views')} />
                </BarChart>
              </ResponsiveContainer>
            </div>
            
            <div className="date-controller">
              <button className="prevDateButton" onClick={() => prevDateHandler(date1, setDate1, 'first')}>
                <svg width="8"  height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L2 5.5L7 1" stroke='black' strokeWidth="2"/>
                </svg>
              </button>
                  <p className='rp-date-text'>{ moment(date1).subtract(6, 'days').format('DD MMM') +' - '+ moment(date1).format('DD MMM') }</p>
              <button className="nextDateButton" onClick={() => nextDateHandler(date1, setDate1, 'first')} disabled = {disableForward.first}>
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 5.5L1 10" stroke={ disableForward.first ? 'gray' : 'black' } strokeWidth="2"/>
                </svg>
              </button>
            </div>

            <div className="messageView-wrapper">
              <p># Of Messages</p>
              <ResponsiveContainer width='100%' height={300}>
                <ComposedChart
                  width={400}
                  height={300}
                  data={messageData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid/>
                  <Tooltip content={ <CustomTooltip type='message' /> } />
                  <Line type="monotone" dataKey="messages" strokeWidth='2px' stroke="#8CC947" />
                  {/* <Area type="monotone" dataKey="messages" strokeWidth='2px' fill='none' stroke="#8CC947" /> */}
              <Bar dataKey="messages"
                barSize={30} fill="#8CC947"
                name='bar-chart' 
                onMouseOut={() => {
                  setBarTooltip('')
                }}
                onMouseOver={() => { setBarTooltip('messages') }}
                radius={[5, 5, 0, 0]} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>

            <div className="date-controller">
              <button className="prevDateButton" onClick={() => prevDateHandler(date2, setDate2, 'second')}>
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L2 5.5L7 1" stroke="black" strokeWidth="2"/>
                </svg>
              </button>
                <p className='rp-date-text'>{ moment(date2).subtract(6, 'days').format('DD MMM') +' - '+ moment(date2).format('DD MMM') }</p>
              <button className="nextDateButton" onClick={() => nextDateHandler(date2, setDate2, 'second')} disabled = {disableForward.second} >
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 5.5L1 10" stroke={ disableForward.first ? 'gray' : 'black' } strokeWidth="2"/>
                </svg>
              </button>
            </div>

            <div className='viewingRequest-wrapper'>
              <p>Number Of Viewing Requests</p>
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  width={400}
                  height={300}
                  data={viewingData}
                  margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                  }}
                >
                  <CartesianGrid />
                  <XAxis tick={{fill:'#8CC947'}} dataKey="day" />
                  <Tooltip cursor={{fill: 'none'}} content={ <CustomTooltip type='requests' /> } />
                  <Bar 
                   radius={[ 5, 5, 0, 0 ]}
                   dataKey="requests" 
                   barSize={30} fill='#8CC947'
                   strokeWidth='2px' onMouseOut={() => setBarTooltip('')}
                   onMouseOver ={() => setBarTooltip('requests')} />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="date-controller">
              <button className="prevDateButton" onClick={() => prevDateHandler(date3, setDate3, 'third')}>
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L2 5.5L7 1" stroke="black" strokeWidth="2"/>
                </svg>
              </button>
                <p className='rp-date-text'>{ moment(date3).subtract(6, 'days').format('DD MMM') +' - '+ moment(date3).format('DD MMM') }</p>
              <button className="nextDateButton" onClick={() => nextDateHandler(date3, setDate3, 'third')} disabled = {disableForward.third} >
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 5.5L1 10" stroke={ disableForward.first ? 'gray' : 'black' } strokeWidth="2"/>
                </svg>
              </button>
            </div>

          </div>
      </SidePanel>
  </>);
}
export default withRouter(ShowAnalytics);




