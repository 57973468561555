import React from 'react'
import { Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import moment from "moment";
import {salaryOptions} from '../../Sidebar/constant'

function EmploymentInfo(props) {
    const { employmentStatus, emp = {}, hideEmploymentStatus = false } = props
    const formatTime = (time) => {
        return time ? moment(time).format('ll') : '--';
    }
    let salary= salaryOptions.filter(single => single.value === emp.salary_range).map(filter => {
        return filter.display;
      })
    return (
        <>
            {!hideEmploymentStatus && <Col md={6}>
                <span className="card-labels">Employment Status</span>
                <p className="card-labels-desc">{employmentStatus}</p>

            </Col>}
            <Col md={6}>
                <span className="card-labels">Job Title</span>
                <p className="card-labels-desc">{emp.job_title}</p>
            </Col>
            <Col md={6}>
                <span className="card-labels">Period</span>
                <p className="card-labels-desc">{formatTime(emp.start_date)} - {!hideEmploymentStatus ? 'Present' : formatTime(emp.end_date)}</p>
            </Col>
            {(employmentStatus !== "Past Job" && !hideEmploymentStatus) && 
            <Col md={6}>
                <span className="card-labels">Annual income</span>
                <p className="card-labels-desc">
                    {salary[0]}
                    {/* <NumberFormat value={emp.min_salary} displayType={'text'} thousandSeparator={true} prefix={'$'} /> - <NumberFormat value={emp.max_salary} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                </p>
            </Col>}
            <Col md={6}>
                <span className="card-labels">Reference</span>
                <p className="card-labels-desc">{emp.employment_reference_name}</p>
            </Col>
            <Col md={6}>
                <span className="card-labels">Contact</span>
                <p className="card-labels-desc"> <NumberFormat value={emp.employment_reference_phone} format="###-###-####" displayType={'text'} allowEmptyFormatting mask="_" /></p>
            </Col>
        </>
    )
}

export default EmploymentInfo
