export const multiCheckbox = (sort, item, isChecked) => {
    let arraydata = sort;
    let returnVal = [];
    if (item === 'all') {
        let arr = []
        if (isChecked === true) {
            arr = ['all']
        } else {
            arr = []
        }
        returnVal = arr;
    } else if (item !== 'all') {
        isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
        if (arraydata.includes('all')) {
            const indexElm = arraydata.indexOf('all');
            arraydata.splice(indexElm, 1);
        }
        returnVal = arraydata;
    }
    return returnVal;
}
