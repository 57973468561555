import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Cookies from "js-cookie";

import { logger } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import { personNeedEntry } from '../../../../Constants/NoticeConstants';
import Moment from 'react-moment';

const log = logger("EntryDetails");
const EntryDetails = (props) => {
  const { noticeData, downloadPdf, requestData, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  log.info('requestData', requestData);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Enter The Premises</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              The Person Needing Entry
            </span>
            <span className="field-value-text">
              {noticeData.person_needing_entry && personNeedEntry.filter((data) => {
                let title = '';
                if (Number(noticeData.person_needing_entry) === (data.value)) {
                  title = data.title
                }
                return title;
              }).map(function (obj) {
                return obj.title;
              }).join(', ')}
            </span>
          </Col>

          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              Reason For Entry
            </span>
            <span className="field-value-text">
              {noticeData.reason_for_entry && requestData?.filter((data) => {
                let title = '';
                if (Number(noticeData.reason_for_entry) === (data.id)) {
                  if (data.id === 21) {
                    title = data.title + ' - ' + noticeData.reason_text;
                  } else {
                    title = data.title;
                  }
                }
                return title;
              }).map(function (obj) {
                return obj.title;
              }).join(', ')}
            </span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              Date Of Entry
            </span>
            <span className="field-value-text">{<Moment format="MMMM D, YYYY">{noticeData.date_of_entry}</Moment>}</span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              Earliest entry time
            </span>
            <span className="field-value-text">{<Moment format="hh:mm A">{noticeData.date_of_entry + ' ' + noticeData.time_of_entry}</Moment>}</span>
          </Col>
          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              Latest entry time
            </span>
            <span className="field-value-text">{<Moment format="hh:mm A">{noticeData.date_of_entry + ' ' + noticeData.end_time}</Moment>}</span>
          </Col>
        </Row>

        <div className="border-separator"></div>

        <div className="mt-4">
          <span className="form-title">Additional Notes</span>
        </div>

        <Row>
          <Col md={10} className="d-flex flex-column">
            <span className="field-value-text">
              {noticeData.notes}
            </span>
          </Col>
        </Row>

      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default EntryDetails;
