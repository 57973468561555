import React from "react";
import { withRouter } from "react-router-dom";
import LandlordTableComponent from "../LandlordTableComponent";
import "../style.scss";

const Listing = (props) => {
    console.log(props.profileDetails)
    return (
        <LandlordTableComponent
            data={props.profileDetails && props.profileDetails.listing}
            user_id={props.user_id}
            currentPage={null}
            component="listing"
            type={props.type} />
    );
}

export default withRouter(Listing);
