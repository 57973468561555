import "./style.scss"
import React, { useState, useEffect, useRef } from "react"
import greenCircle from "../../../_assets/images/homepage/greenCircle.svg";
import greenStar from "../../../_assets/images/homepage/greenStar.svg";
import rightBtn from "../../../_assets/images/homepage/carouselLeft.svg";
import leftBtn from "../../../_assets/images/homepage/carouselRight.svg";
import posLeftScroll from "../../../_assets/images/homepage/scrollBarPosLeft.svg";
import posRightScroll from "../../../_assets/images/homepage/scrollBarPosRight.svg";


const ReviewCard = ({name, location, reviewHeadline, reviewBody, rating}) => {

    return (
        <div className="reviewCard">
            <div className="reviewHeader">
                <div className="nameCont">
                            <span className="initialsCont">
                                <img className={"greenCircle"} src={greenCircle} alt=""/>
                                <p className={"initials"}>{name.charAt(0)}</p>
                            </span>
                    <div className="detailsCont">
                        <div className="nameActual">
                            {name}
                        </div>
                        <div className="locationActual">
                            {location}
                        </div>
                    </div>

                </div>
                <div className="reviewHeadline">
                    {reviewHeadline}
                </div>
                <div className="starCont">
                    {[...Array(rating)].map((e, i) => {
                        return <img key={i} src={greenStar} alt=""/>
                    })}
                </div>
            </div>

            <div className="reviewBody">
                <p>{reviewBody}</p>
            </div>
        </div>
    )
}

const Carousel = ({reviews}) => {

    const scrollRef = useRef(null)
    const [scrolledLeft, setScrolledLeft] = useState(false)

    const scroll = (scrollOffset) => {
        scrollRef.current.scrollLeft = scrollOffset;
        if (scrolledLeft && scrollOffset < 100) {
            setScrolledLeft(false)
        } else if (!scrolledLeft && scrollOffset > 100) {
            setScrolledLeft(true)
        }
    };

    return (
        <div className="carousel">
            <div className="backwardContainer">
                <img className={"backward"} src={rightBtn} alt=""
                     onClick={() => scroll(-1000)}
                />
            </div>

            <div className="carouselContainer"
                 ref={scrollRef}
            >
                {reviews.map((review, index) => {
                    return (
                        <ReviewCard name={review.name}
                                    location={review.location}
                                    reviewHeadline={review.reviewHeadline}
                                    reviewBody={review.reviewBody}
                                    rating={review.rating}
                                    key={index}
                        />
                    )
                })}
            </div>

            <div className="forwardContainer">
                <img className={"forward"} src={leftBtn}
                     alt=""
                     onClick={() => scroll(1200)}
                />
            </div>

            <div className="scrollBar">
                { scrolledLeft
                    ? (<img className={"posLeft"} src={posRightScroll} alt=""/>)
                    : (<img className={"posLeft"} src={posLeftScroll} alt=""/>)
                }
            </div>
        </div>
    )
}

export default Carousel