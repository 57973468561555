import React, { useRef, useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAPI } from "../../../Api";
import { AppLayout, PageTitleHeader, LargeButton, Loader } from "../../components";
import { logger, validate } from "../../../Utils";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./style.scss";
import Cookies from "js-cookie";
import { clearRawData, updateRawData } from '../../../Store/actions/RawDataAction'
import { Prompt } from "react-router";

import {
  N1Form,
  N2Form,
  N3Form,
  N4Form,
  N5Form,
  N6Form,
  N7Form,
  N8Form,
  N10Form,
  N11Form,
  N12Form,
  N13Form,
  EnteryForm,
  WarningForm,
  ParalegalForm,
  N9Form,
} from "../../components/Notices/NoticeForms";
import { useGlobalState } from "../../../StateContext";
import { NOTICE_TYPE_SCHEMA } from "./helper";

const log = logger("CreateNotice");
const CreateNotice = (props) => {
  const [globalState, setGlobalState] = useGlobalState()
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [tenantlist, setTenantlist] = useState([]);
  const [allChecked, setAllChecked] = useState(false)
  const [search, setSearch] = useState("");
  const [tenantReq, setTenantReq] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState(false);

  const [property_id, setProperty_id] = useState();
  const [property_detail_id, setProperty_detail_id] = useState();
  const [notice_id, setNotice_id] = useState(null);
  const [is_Paralegal, setParalegal] = useState(false);
  const role = Number(Cookies.get("role"));
  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );
  const noticeData = useSelector((state) => state.rawData)
  const landlordInfo = useSelector(state => state.maintenanceRepairReducer.landlord)
  const { tenants = [], selectedReason, requestData = [], reasonOption, showLoader = false, allCleared } = noticeData || {}
  const wrapperRef = useRef(null);



  useEffect(() => {
    setShouldBlockNavigation(!!(Object.keys(noticeData).length))
  }, [noticeData])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSelectDropdown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const address = useSelector(
    (state) => state.maintenanceRepairReducer.address
  );
  const setNoticeData = (data) => updateRawData({ noticeData: data })

  const back = () => {
    props.history.push(
      `/maintainance-repair/${property_id}/${property_detail_id}/notices`
    );
  };

  const onBack = () => {
    props.history.goBack();
  };

  const getParalegal = () => {
    setParalegal(true);
  };

  const triggerReqTenant = (data) => {
    // setTenantReq(data);
  };

  const getData = () => {
    const url = `/notice/types-and-further-options`;
    getAPI(url)
      .then((response) => {
        const res = response.data.data;
        log.info(res);
        // setRequestData(res);
        updateRawData({ requestData: res })
      })
      .catch((error) => { });
  };


  const getTenants = (p, pd) => {
    if (property_id) {
      const url = `/get-property-tenants/${property_id}/${property_detail_id}`;
      getAPI(url).then((res) => {
        let tenants = res?.data?.data || [];
        for (var tenant in tenants) {
          var obj = tenants[tenant];
          Object.assign(obj, {
            checked: false,
          });
        }
        setTenantlist(tenants);
      });
    }
  };

  const tenantHandleChange = (id, display, index, isChecked) => {
    const tenants = [...tenantlist];
    var obj = tenants[index];
    Object.assign(obj, {
      checked: !isChecked,
    });
    const tempId = [];
    const tempName = [];
    for (var tenant in tenantlist) {
      if (tenantlist[tenant].checked) {
        tempId.push(tenantlist[tenant].tenant_id);
        tempName.push(tenantlist[tenant].name);
      }
    }
    if (tempId.length !== 0) {
      setTenantReq(false);
    }
    setTenantlist(tenants);
    setAllChecked(tempId.length === tenants.length)
    updateRawData({ tenants: tempName })
  };

  const toggleSelect = () => {
    setSelectDropdown(!selectDropdown);
  };

  const requestHandleChange = (seletedOptions) => {
    updateRawData({ selectedReason: null, reasonOption: seletedOptions, agree: false });
  };

  const setFormHandleChange = (data) => {
    const tempRequestData = requestData;
    const tempReasonOption = reasonOption
    const tempTenants = tenants
    clearRawData()
    updateRawData({ tenants: tempTenants, selectedReason: data, agree: false, requestData: tempRequestData, reasonOption: tempReasonOption });
  };

  const getNoticeData = () => {
    let url = "";
    if (notice_id) {
      if (role === 2) {
        url = `/landlord/notice/details/${notice_id}`;
      } else if (role === 3) {
        url = `/tenant/notice/details/${notice_id}`;
      }
      getAPI(url)
        .then((response) => {
          const res = response.data.data;
          log.info(res);
          setNoticeData(res);
          updateRawData({ ...res })
        })
        .catch((error) => { });
    }
  };

  useEffect(() => {
    if (role === 2) {
      getTenants();
    }
    getData();
    getNoticeData();
    setProperty_id(props.match.params.property_id);
    setProperty_detail_id(props.match.params.property_details_id);
    if (props.match.params.notice_id) {
      setNotice_id(props.match.params.notice_id);
    }
  }, [props]);

  useEffect(() => {

    requestData &&
      requestData.map((data, i) => {
        if (noticeData.i_want_to === data.id) {
          updateRawData({ reasonOption: data });

          if (noticeData.i_want_to !== noticeData.notice_type) {

            const selectedReason = data.further_options.find((i) => i.id === noticeData.notice_type)
            updateRawData({ selectedReason: selectedReason })
          }
        }
      });
  }, []);


  const onNoticeDataUpdate = (data) => {
    updateRawData(data)
    if (data.notice_type && globalState.isSubmitClicked) {
      validate(NOTICE_TYPE_SCHEMA[data.notice_type], null, true)
    }
  }

  const onAllChecked = () => {
    setAllChecked(allChecked => {
      const tempName = [];
      if (!allChecked) {
        for (const tenant in tenantlist) {
          tempName.push(tenantlist[tenant].name);
        }
      }
      updateRawData({ tenants: tempName })
      return !allChecked
    })
  }

  const getFilteredTenants = React.useCallback((search) => {
    const tenentIds = []
    const checkedList = tenantlist.map((i) => {
      if (tenants.includes(i.name)) {
        i.checked = true
        tenentIds.push(i.tenant_id)
      }
      return i
    })
    return { tenant_id: tenentIds, filteredTenant: checkedList.filter((i) => i.name.match(new RegExp(search, 'i'))) }
  })

  const { tenant_id, filteredTenant } = getFilteredTenants(search)



  return (
    <>
      <Prompt
        when={shouldBlockNavigation && !allCleared}
        message="This will lose your changes. Are you sure want leave the page?"
      />
      <AppLayout>

        <PageTitleHeader
          title={"create new notices"}
          rightBtnText={"Review"}
          rightBtn={"true"}
          isArrow={true}
          rightBtnHandler={() => {
            if (globalState?.onSubmit)
              globalState.onSubmit()
          }}
          onClick={() => back()}
          extraClass='text-lowercase'
        />
        <div className="notice-form-card">
          <div id='notice_loader'>
            {/* {showLoader &&
              <div style={{ top: '50%', position: 'absolute', left: '44%', width: '10%' }}>
                <Loader />
              </div>
            } */}
          </div>
          <div className="d-flex justify-content-between">
            <span className="address-text">{address}</span>
          </div>
          {role === 2 && (
            <Form id="Form">
              <Row>
                <Form.Group as={Col} md="6" lg="3" className="request-select">
                  <Form.Label>Select Present Tenant(s)</Form.Label>
                  <div style={{ position: "relative" }} ref={wrapperRef}>
                    <div className="select-tenant" onClick={toggleSelect}>
                      <div className="d-flex justify-content-between">
                        <span>
                          {tenants.length === 0 && "Select"}
                          {tenants &&
                            tenants.map((tenant, index) => {
                              return (
                                <>
                                  <span style={{ color: '#212529' }} key={index}>
                                    {tenant}
                                  </span> {(tenants.length > 1 && tenants.length - 1 !== index) && ", "}</>
                              );
                            })}
                        </span>
                        <img alt=""
                          src={require("../../../_assets/images/select-icon.svg")}
                        />
                      </div>
                    </div>
                    {selectDropdown && (
                      <div className="select-dropdown-box">
                        <input
                          className="select-search-filter"
                          placeholder="Search Tenant Name"
                          onChange={(event) => setSearch(event.target.value)}
                          value={search}
                          name="search"
                        />

                        <div
                          className="select-checkbox-wrapper d-flex justify-content-start"

                        >
                          <input
                            type="checkbox"
                            className="checkit"
                            id={'all'}
                            name={'all'}
                            checked={allChecked}
                            onChange={onAllChecked}
                            hidden
                          />
                          <label
                            style={{ cursor: 'pointer' }}
                            htmlFor={'all'}
                            className="checkmark"
                          ></label>
                          <span style={{ cursor: 'pointer' }} onClick={onAllChecked} className="checkbox-title">
                            All
                          </span>
                        </div>

                        {filteredTenant.map((options, i) => {
                          return (
                            <div
                              className="select-checkbox-wrapper d-flex justify-content-start"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="checkit"
                                id={options.tenant_id}
                                name={options.tenant_id}
                                checked={options.checked}
                                onChange={() =>
                                  tenantHandleChange(
                                    options.tenant_id,
                                    options.name,
                                    i,
                                    options.checked
                                  )
                                }
                                hidden
                              />
                              <label
                                style={{ cursor: 'pointer' }}
                                htmlFor={options.tenant_id}
                                className="checkmark"
                              ></label>
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  tenantHandleChange(
                                    options.tenant_id,
                                    options.name,
                                    i,
                                    options.checked
                                  )
                                }
                                className="checkbox-title">
                                {options.name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {tenantReq && (
                      <div className="invalid-select">Tenant Required</div>
                    )}
                  </div>
                  {/* ---multiple select with search */}
                </Form.Group>
                <Form.Group as={Col} md="6" lg="3">
                  <Form.Label>I want to:</Form.Label>
                  <DropdownButton
                    // alignLeft
                    disabled={!tenant_id?.length}
                    title={reasonOption ? reasonOption.title : "Select"}
                    id="dropdown-select"
                    name="request_type"
                    style={{ width: "100%", color: '#212529' }}
                  >
                    {requestData &&
                      requestData.map((options, i) => {
                        return (
                          <Dropdown.Item
                            eventKey={options.id}
                            onClick={() => requestHandleChange(options)}
                            key={"request_option_" + i}
                          >
                            {options.title}
                          </Dropdown.Item>
                        );
                      })}
                  </DropdownButton>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Please select further information on why you're sending this
                    notice
                  </Form.Label>

                  <DropdownButton
                    // alignLeft
                    title={
                      selectedReason
                        ? selectedReason.title
                        : reasonOption &&
                          (reasonOption.id === 16 || reasonOption.id === 17)
                          ? "Not Applicable"
                          : "Select"
                    }
                    id="dropdown-select"
                    name="reason_type"
                    className={reasonOption ? "" : "disabled"}
                    disabled={!tenant_id?.length || !reasonOption || (reasonOption && (reasonOption.id === 16 || reasonOption.id === 17))}
                  >
                    {reasonOption &&
                      (reasonOption.id === 16 || reasonOption.id === 17) ? (
                        <></>
                      ) : (
                        reasonOption &&
                        reasonOption.further_options.map((options, i) => {
                          return (
                            <Dropdown.Item
                              eventKey={options.id}
                              onClick={() => setFormHandleChange(options)}
                              key={"reason_" + i}
                            >
                              {options.title}
                            </Dropdown.Item>
                          );
                        })
                      )}
                  </DropdownButton>
                </Form.Group>
              </Row>
            </Form>
          )}

          {role === 3 && (
            <Form id="Form">
              <Row>
                <Form.Group as={Col} md="3" className="request-select">
                  <Form.Label><span style={{ fontWeight: 600 }}>Landlord</span></Form.Label>
                  <p style={{ fontWeight: 700 }}>{landlordInfo && landlordInfo.name}</p>
                </Form.Group>
                <Form.Group as={Col} md="8">
                  <Form.Label> <span style={{ fontWeight: 600 }}>Notice</span></Form.Label>
                  <p style={{ fontWeight: 700 }}>Tenant’s notice to end the tenancy</p>
                </Form.Group>
              </Row>
            </Form>
          )}
        </div>

        {!(reasonOption || selectedReason || noticeData) && role === 2 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "350px" }}
          >
            role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{
                width: "100%",
                maxWidth: "450px",
                height: "48px",
                margin: "0",
              }}
              onClick={() => getParalegal()}
            />
          </div>
        )}

        {selectedReason && selectedReason.id === 1 && (
          <N1Form
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 2 && (
          <N2Form
            property_id={property_id}
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 3 && (
          <N3Form
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 4 && (
          <N4Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 5 && (
          <N5Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 6 && (
          <N6Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 7 && (
          <N7Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 8 && (
          <N8Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 10 && (
          <N10Form
            onNoticeDataUpdate={onNoticeDataUpdate}

            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 11 && (
          <N11Form
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 12 && (
          <N12Form
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {selectedReason && selectedReason.id === 13 && (
          <N13Form
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            onNoticeDataUpdate={onNoticeDataUpdate}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {reasonOption && reasonOption.id === 16 && (
          <EnteryForm
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            requestData={requestData && requestData[2].further_options}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}

        {reasonOption && reasonOption.id === 17 && (
          <WarningForm
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            requestData={requestData && requestData[3].further_options}
            noticeData={noticeData}
            triggerReqTenant={(data) => triggerReqTenant(data)}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
            onNoticeDataUpdate={onNoticeDataUpdate}
          />
        )}

        {role === 3 && (
          <N9Form
            onNoticeDataUpdate={onNoticeDataUpdate}
            property_id={property_id}
            property_detail_id={property_detail_id}
            tenant_id={tenant_id}
            noticeData={noticeData}
            getParalegal={() => getParalegal()}
            onBack={() => onBack()}
          />
        )}
      </AppLayout>

      <ParalegalForm
        isShow={is_Paralegal}
        handleClose={() => setParalegal(false)}
      />
    </>
  );
};
export default withRouter(CreateNotice)
