import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { getLeaseDetailPath } from '../../../../Store/actions/Action';
import { multiCheckbox } from '../../../../Utils/MultiCheckbox/MultiCheckbox';
import { TableLandlord } from '../../../components';
import UserManagementTable from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import '../style.scss';


const Leases = (props) => {
    const { user_id, role } = props;
    const [leaseList, setLeaseList] = useState(null);
    // const [path, setPath] = useState('');
    const [searchLocation, setSearch] = useState(null);
    const [filterRent, setFilterRent] = useState('all');
    const [leaseType, setLeaseType] = useState('all');
    const [sort_by_status_lease, setStatusLease] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    let userColumns = [];
    userColumns = [
        {
            header: 'ADDRESS',
            width: '30%'
        },
        {
            header: 'DURATION',
            width: '20%'
        },
        {
            header: role === 'landlord' ? 'TENANT' : 'LANDLORD',
            width: '15%'
        },
        {
            header: 'RENT ($)',
            width: '15%'
        },
        {
            header: 'STATUS',
            width: '20%'
        }
    ];
    const filterData = {
        'filter': {
            'address': searchLocation,
            'lease_type': leaseType === 'all' ? '' : leaseType,
            'status': sort_by_status_lease,
        },
        'sort_by_rent': {
            'order': filterRent === 'all' ? '' : filterRent
        },
        'limit': 10
    }
    let url = role === 'landlord' ? `/admin/users/landlord/lease/${user_id}` : `/admin/users/tenants/lease/${user_id}`;

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        let urlNext = '';
        if (role === 'landlord') {
            urlNext = `/admin/users/landlord/lease/${user_id}?page=${nextPage}`;
        } else {
            urlNext = `/admin/users/tenants/lease/${user_id}?page=${nextPage}`;
        }
        postAPI(urlNext, filterData)
            .then(response => {
                if (response.status === 200) {
                    setIsLoading(false);
                    const res = response.data.data.Leases;
                    setLeaseList(res)
                }
            })
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        let urlPrev = '';
        if (role === 'landlord') {
            urlPrev = `/admin/users/landlord/lease/${user_id}?page=${prevPage}`;
        } else {
            urlPrev = `/admin/users/tenants/lease/${user_id}?page=${prevPage}`;
        }
        postAPI(urlPrev, filterData)
            .then(response => {
                if (response.status === 200) {
                    setIsLoading(false);
                    const res = response.data.data.Leases;
                    setLeaseList(res)
                }

            })
    }
    const goToProfileDetail = (userData) => {
        dispatch(getLeaseDetailPath('list-lease'))
        props.history.push({ pathname: `/lease-detail/${role}/${userData.id}/${user_id}` })
    }

    const sortStatusLease = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(sort_by_status_lease, item, isChecked);
        setStatusLease(fetchedFilter)
        if (user_id !== '') {
            const filterDataInside = {
                'filter': {
                    'address': searchLocation,
                    'lease_type': leaseType === 'all' ? '' : leaseType,
                    'status': fetchedFilter,
                },
                'sort_by_rent': {
                    'order': filterRent === 'all' ? '' : filterRent
                },
                'limit': 10
            }
            postAPI(url, filterDataInside)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        const res = response.data.data.Leases;
                        setLeaseList(res)
                    }
                })
        }
    }
    useEffect(() => {
        if ((user_id !== '' || user_id !== undefined)) {
            let urlInside = '';
            role === 'landlord' ?
                urlInside = '/admin/users/landlord/lease/' + user_id :
                urlInside = '/admin/users/tenants/lease/' + user_id;
            const data = {
                'filter': {
                    'address': searchLocation,
                    'lease_type': leaseType === 'all' ? '' : leaseType,
                    'status': sort_by_status_lease,
                },
                'sort_by_rent': {
                    'order': filterRent === 'all' ? '' : filterRent
                },
                'limit': 10
            }
            postAPI(urlInside, data)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        const res = response.data.data.Leases;
                        setLeaseList(res)
                    }
                })
        }
        // setPath(props.location.state.path)
    }, [user_id, role, searchLocation, leaseType, filterRent, sort_by_status_lease])
    return (
        isLoading === true ? <UserManagementTable columns={userColumns} component={'lease'} /> :
            <>{
                leaseList && leaseList.data ?
                    <TableLandlord
                        columns={userColumns}
                        tableData={leaseList.data}
                        to={leaseList.to}
                        from={leaseList.from}
                        current_page={leaseList.current_page}
                        last_page={leaseList.last_page}
                        per_page={leaseList.per_page}
                        total={leaseList.total}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        clickNext={() => onClickNext(leaseList.current_page)}
                        clickPrev={() => onClickPrev(leaseList.current_page)}
                        setSearch={(data) => setSearch(data)}
                        searchLocation={searchLocation}
                        sort_by_status_lease={sort_by_status_lease}
                        sortStatusLease={(data) => sortStatusLease(data)}
                        durationFilter={(data) => setLeaseType(data)}
                        filterRent={(data) => setFilterRent(data)}
                        component='lease'
                        roleAdmin={role}
                    /> :
                    <div className="empty-table-data">
                        There is no data found
                </div>
            }
            </>
    );
}

export default withRouter(Leases);
