export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SET_PROFILE = "SET_PROFILE";
export const SET_FORGOT_PASSWORD_PIN = "SET_FORGOT_PASSWORD_PIN";

export function setLoggedIn(data) {
  return function (dispatch) {
    dispatch({ type: LOGIN_SUCCESS, token: data.token, loggedIn: true, role: data.role });
  };
}

export function signUp(email, userId) {
  return function (dispatch) {
    dispatch({ type: SIGNUP_SUCCESS, email, userId });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch({ type: LOGOUT });
  };
}

export function forgotPassword(email) {
  return function (dispatch) {
    dispatch({ type: FORGOT_PASSWORD, email });
  };
}

export function setProfile(data) {
  return function (dispatch) {
    dispatch({ type: SET_PROFILE, data });
  };
}

export function setForgotPasswordPin(pin) {
  return function (dispatch) {
    dispatch({ type: SET_FORGOT_PASSWORD_PIN, pin });
  };
}
