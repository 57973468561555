import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import { getResourceData, getcardNum } from "./helpers";
import ArticlesCardList from "./blogCardList";
import "./style.scss";
import { Helmet } from "react-helmet";

const LandlordBlogs = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [articlesList, setArticlesList] = useState([]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const data = { "category": "Landlord Forms" };
    setShowLoader(true);
    const articlesList = await getResourceData(`/cms/articles/list`, data);
    setShowLoader(false);
    setArticlesList(articlesList);
  };



  return (
    <>
    <Helmet>
      <title>Blog | Rent Panda</title>
      <meta name="description" content="We're experts at renting. Our blogs cover a wide range of 
      topics, for free, to make sure everyone has the best rental experience and follows the rules." />
    </Helmet>
    <div className="ResourcesPage">
      <Layout newHeader={true}>
        <div className="ResourcesPage-header">
          <Container>
            <div className="main-heading text-left text-md-center text-transform-lowercase">
              rent panda blog
            </div>
            <div className="sub-heading text-left text-md-center text-justify">
              Stay up to date on the rental market and get top tips and tricks from industry experts here.
            </div>
          </Container>
        </div>

        <div className="ResourcesPage-contentBox">
          <ArticlesCardList
            articlesList={articlesList}
            showLoader={showLoader}
          />
        </div>
      </Layout>

    </div>
    </>
  );
};

export default withRouter(LandlordBlogs);
