import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLeaseHeading, uploadManual } from '../../../../../Store/actions/Action';
import { getTenantsList } from '../../../../../Store/actions/AddedTenantAction';
import { SidePanel } from '../../../../components';

const CreateLeaseOptions = (props) => {
    const { isShow, handleClose, user_id, singleReasonData, openUploadLease } = props;

    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    // const unitSelector = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const dispatch = useDispatch();

    const clickOnCreateLease = () => {
        dispatch(getLeaseHeading(''))
        dispatch(getTenantsList(null))
        // let lease_id = (unitSelector && unitSelector.lease !== '') ? unitSelector.lease.id : 0;
        props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/contact-details/0`, state: { singleReasonData: singleReasonData } });
    }
    const clickOnUploadLease = () => {
        openUploadLease(true)
        handleClose();
        dispatch(uploadManual(null))
        dispatch(getTenantsList(null))
    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id]);

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title='Do you want to create a lease for the landlord?'
            extraClasses={'create-lease-option'}
        >
            <div className='deactive-reasons'>
                <Row className='p-4 border-reason cursorPointer' onClick={() => clickOnCreateLease()}>
                    <Col className='pl-0 pr-0 d-flex justify-content-between' md={12}><span className='create-lease-options'> Create a new lease</span>
                        <img className='next-arrow' src={require('../../../../../_assets/images/next.svg')} alt='next' />
                    </Col>
                </Row>
                <Row className='p-4 border-reason cursorPointer' onClick={() => clickOnUploadLease()}>
                    <Col className='pl-0 pr-0 d-flex justify-content-between' md={12}><span className='create-lease-options'>Upload lease</span>
                        <img className='next-arrow' src={require('../../../../../_assets/images/next.svg')} alt='next' />
                    </Col>
                </Row>
            </div>

        </SidePanel>
    </>);
}
export default withRouter(CreateLeaseOptions);