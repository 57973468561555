import React from "react";
import { sort_time, sort_date, sort_status_showing } from "../../../Constants/adminConstant";
import { logger } from "../../../Utils";
import ImageComponent from "../../Admin/ProfileDetail/ListUsableComponent/ImageComponent";
import ThreeDotsDropdown from "../ThreeDotsDropdown";

const log = logger("ShowingHeader");
const ShowingHeader = (props) => {
	const { data, component, onSortDate, onSortTime, sortShowing } = props;

	return (
		<>
			{component === "showings" &&
				<>
					{(() => {
						switch (data.header) {
							case 'ADDRESS':
								return (
									<>
										{data.header}
									</>
								)
							case 'DATE':
								return (
									<>
										{data.header}
										<ThreeDotsDropdown
											extraClass="display-inline"
											icon={<ImageComponent />}
											actionsButton={
												<>
													{sort_date.map((data, i) => {
														return <span className="dropdown-item sort-option cursorPointer" key={"sort_date" + i} onClick={() => onSortDate(data.value)}>{data.display}</span>
													})}
												</>
											}
										/>
									</>
								)
							case 'TIME':
								return (
									<>
										{data.header}
										<ThreeDotsDropdown
											extraClass="display-inline"
											icon={<ImageComponent />}
											actionsButton={
												<>
													{sort_time.map((data, i) => {
														return <span className="dropdown-item sort-option cursorPointer" key={"sort_time" + i} onClick={() => onSortTime(data.value)}>{data.display}</span>
													})}
												</>
											}
										/>
									</>
								)
							case 'TENANT':
								return (
									<>
										{data.header}
									</>
								)
							case 'LANDLORD':
								return (
									<>
										{data.header}
									</>
								)
							case 'STATUS':
								return (
									<>
										{data.header}
										<ThreeDotsDropdown
											extraClass="display-inline extra-width"
											icon={<ImageComponent />}
											actionsButton={
												<>
													{sort_status_showing.map((data, i) => {
														return <span className="dropdown-item sort-option cursorPointer" key={"sort_status_showing" + i} onClick={() => sortShowing(data.value)}>{data.display}</span>
													})}
												</>
											}
										/>
									</>
								)
							default:
								return null
						}
					})()} </>}
		</>
	);
}
export default ShowingHeader;