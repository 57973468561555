import React from 'react'
import { getAPI, postAPI } from "../../../Api";
import { setSearchFilter, setSearchLocation } from "../../../Store/actions/LandingPageAction";
import { toaster } from "../../../Utils";
import Bed from '../../../_assets/images/bed.svg';
import Bath from '../../../_assets/images/bath.svg';
import Home from '../../../_assets/images/home.svg';
import { Image } from 'react-bootstrap';

const PROPERTY_TYPES = {
    3: () => 'Room for Rent',
    2: (bedRoom) => `Unit • ${bedRoom}`,
    1: (bedRoom) => `'Full Property' • ${bedRoom}`
}

export const getMarkerData = async (data) => {
    try {
        const url = '/tenant/search-property-locations'
        const res = await postAPI(url, data)
        if (res.status === 200) {
            return res.data.data
        }
        return []

    } catch (error) {
        return []
    }
}

export const getPropertyDetails = async (id) => {
    try {
        const url = `/tenant/${id}/single-property-unit-details`;
        const res = await getAPI(url)
        if (res.status === 200) {
            return res.data.data
        }
        return {}

    } catch (error) {
        return {}
    }
}


export const isPropertyPresent = (propertList = [], propertyId) => {
    return !!propertList.find((property) => property.property_details_id === propertyId)
}


 

export const formattedDetails = (data) => {
    // const { bedrooms, property_type } = data;
    // const bedRoom = `${bedrooms} ${bedrooms > 1 ? 'Bedrooms' : 'Bedroom'}`
    // return PROPERTY_TYPES[property_type] && PROPERTY_TYPES[property_type](bedRoom);
    const {
        unit_no,
        bedrooms,
        price_per_month,
        property_type,
        is_shared_bathroom,
        bathroom_count
      } = data;
     
      const bedRoom =
        bedrooms &&
        (parseInt(bedrooms) > 1 ? bedrooms + " Bedrooms" : bedrooms + " Bedroom");
      const price = price_per_month && `$${price_per_month}/Month`;
      
      const propertyTypes= {
        1 : 'Full Property',
        2 : 'Unit',
        3 : 'Room for Rent'
      }
  
      // return ${bedrooms};
      return (
        <div style={{ display: "flex" , fontSize:14,fontWeight:500,color:'black'}}>
          {!! bedrooms &&<div style={{ display: "flex" }}>
            <Image src={Bed}/>
            &nbsp;
            <span >{bedrooms} Bed</span>
          </div>}
         {!!bathroom_count && <div style={{ display: "flex" ,marginLeft:15}}>
         <Image src={Bath}/>
            &nbsp;
            <span >{bathroom_count} Bath</span>
          </div>}
          <div style={{ display: "flex" ,marginLeft: !!bathroom_count ? 15 : 0}}>
          <Image src={Home}/>
            &nbsp;
            <span >{propertyTypes[property_type]}</span>
          </div>
        </div>
      );
      // let details = '';
      // if (property_type === 3) {
      //   if (is_shared_bathroom && is_shared_bathroom === 1) {
      //     details = `Room • Shared Bathroom`;
      //   } else {
      //     details = `Room • Private Bathroom`;
      //   }
      //   if (price) {
      //     details += ` • ${price}`;
      //   }
      // } else {
      //   if (property_type === 2) {
      //     details = unit_no ? (isNaN(unit_no) ? unit_no : `Unit ${unit_no}`) : 'Unit';
      //   } else if (property_type === 1) {
      //     details = `Full Property`;
      //   }
      //   if (bedRoom && price) {
      //     details += ` • ${bedRoom} • ${price}`;
      //   } else if (bedRoom) {
      //     details += ` • ${bedRoom}`;
      //   } else if (price) {
      //     details += ` • ${price}`;
      //   }
      // }
      // return details;
}

export const checkIsBottom = (e) => {
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop
    const clientHeight = e.target.clientHeight
    const conditionOne = parseInt((parseInt(scrollHeight) - parseInt(scrollTop))) === parseInt(clientHeight)
    const conditionTwo = parseInt((parseInt(scrollHeight) - parseInt(scrollTop)) + 1) === parseInt(clientHeight)
    const conditionThree = parseInt((parseInt(scrollHeight) - parseInt(scrollTop)) - 1) === parseInt(clientHeight)

    return (conditionOne || conditionTwo || conditionThree)
}

export const setPlaceChanged = (place, dispatch, getSearchFilter) => {
    const address = place.formatted_address;
    let latValue = null, lngValue = null;
    if (!address) {
          toaster('error', 'Please select valid address')
          return false
    }
    if (place.geometry) {
        latValue = place.geometry.location.lat();
        lngValue = place.geometry.location.lng();
    }
    dispatch(setSearchLocation(address))
    dispatch(setSearchFilter({
        ...getSearchFilter,
        city_address_or_location: address,
        search_term: address,
        latitude: latValue,
        longitude: lngValue
    }))
    return { lat: latValue, lng: lngValue }
}