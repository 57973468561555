export const AdminNav = [
  {
    name: "user-icon",
    link: ["/dashboard/all-user", "/dashboard/inactive-user"],
    title: "User Management",
  },
  {
    name: "list-icon",
    link: [
      "/listing-management/all-listing",
      "/listing-management/rented",
      "/listing-management/promoted",
      "/listing-management/inactive",
    ],
    title: "Listing Management",
  },
  {
    name: "warning-icon",
    link: ["/request-management"],
    title: "Request Management",
  },
  {
    name: "coupon-icon",
    link: ["/coupon-management/active", "/coupon-management/inactive"],
    title: "Coupon Management",
  },
  {
    name: "article-icon",
    link: [
      "/cms/content-management",
      "/cms/category-tags",
      "/cms/advertisement",
    ],
    title: "Content Management",
  },
];

export const LandlordNav = [
  {
    name: "listing-icon",
    link: ["/dashboard"],
    title: "For Rent",
  },
  {
    name: "key-icon",
    link: ["/rented-listing"],
    title: "Rented Listings",
  },
  {
    name: "time-iocn",
    link: ["/showings"],
    title: "Showings",
  },
  {
    name: "home-icon",
    link: ["/deactivated-listing"],
    title: "Deactivated Listings",
  },
  {
    name: "list-icon",
    link: ["/resources"],
    title: "Resources",
  },
];

export const TenantNav = [
  {
    name: "search-listing-icon",
    link: ["/search-listing"],
    title: "Search Listings",
  },
  {
    name: "star-icon",
    link: ["/favourites"],
    title: "Favourite Listings",
  },
  {
    name: "dashboard-icon",
    link: [
      "/dashboard/my-listing",
      "/dashboard/my-home",
      "/dashboard/showings",
    ],
    title: "Dashboard",
  },
  {
    name: "list-icon",
    link: ["/resources"],
    title: "Resources",
  },
];

export const salaryOptions = [
  { value: 1, display: "$0 - $25,000" },
  { value: 2, display: "$25,001 - $40,000" },
  { value: 3, display: "$40,001 - $55,000" },
  { value: 4, display: "$55,001 - $75,000" },
  { value: 5, display: "$75,001 - $100,000" },
  { value: 6, display: "$100,000+ " },
];
