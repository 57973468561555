import Cookies from "js-cookie";

export const cookieBasedRedirectPath = (fromPath) => {
	const role = Number(Cookies.get("role"));
	let path = '';
	if (role) {
		if (fromPath) {
		path = fromPath?.pathname;
		} else {
		if (role === 1) {
			path = "/dashboard/all-user";
		} else if (role === 3) {
			path = "/search-listing";
		} else if (role === 2) {
			path = "/dashboard";
		}
		}
	} else {
		path = "/login";
	}
	return path;
}
