import "./ReportSection.scss"
import React from "react"


const ReportSection = ({data, type = "normal", name}) => {

  const formatParagraph = (text) => {
    let paragraphs = []
    let paragraph = ""
    
    text = text.replace(/\[url=([^\s\]]+)\s*\](.*(?=\[\/url\]))\[\/url\]/g, '<a href="$1">$2</a>');
    text = text.replace(/\[img=(.*?)\](.*?)\[\/img\]/g, '<img alt="$1" src="$2" />');
    
    let textArray = text.split(" ")

    textArray.forEach((word) => {
      if (word.includes("\n")) {
        let splitWords = word.split("\n")
        paragraph += splitWords[0]
        paragraphs.push(paragraph)
        paragraph = ""
        paragraphs.push(splitWords[1])
      } else {
        paragraph += word + " "
      }
    })

    paragraphs.push(paragraph)
    return paragraphs
  }

  return (
    <div className={"reportSection"} id={name}>
      <h3 className={"chapterHeader"}>{data.title.toLowerCase()}</h3>
      <div className={"sectionContent"}>
        {/*<p>{data.data.textContent}</p>*/}
        { data.data.contentType === "text" && formatParagraph(data.data.textContent).map((paragraph, index) => {
          return (
            <p key={index} dangerouslySetInnerHTML={{__html: paragraph}}></p>
          )
        })
        }
      </div>
    </div>
  )
}

export default ReportSection