import { postAPI } from '../../../../Api';
import store from '../../../../configureStore';

export const updateProfile = (url, data) => {
  const { conversation_id,forCertn,creditCheckRequestId, } = store.getState().rawData || {};

  return postAPI(
    url.includes('admin')
    ? url
    : (
      conversation_id
      ? `${url}/${conversation_id}/${forCertn ? 1 : 0}/${forCertn && creditCheckRequestId ? creditCheckRequestId : ''}`
      : `${url}/0/${forCertn ? 1 : 0}`
    ),
    data
  );
};

export const validatePastHomes = (pastHomes = []) => {
  const errors = {};
  pastHomes.forEach((i, index) => {
    if (i.is_owned === 0) {
      const error = {};
      if (!validateEmail(i.landlord_email) && !validPhone(i.landlord_phone)) {
        error['landlord_email'] = !i.landlord_email?.trim()?.length
          ? 'Landlord email is required'
          : 'Landlord email is invalid';
        
        }
        
      if ( !validateEmail(i.landlord_email) && !validPhone(i.landlord_phone)) {
        error['landlord_phone'] = !i.landlord_phone?.toString()?.trim()?.length
          ? 'Landlord phone is required'
          : 'Landlord phone is invalid';
      }
      if (Object.keys(error).length) {
        errors[index] = error;
      }
    }
  });
  return { isValid: !Object.keys(errors).length, errors };
};

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validPhone = (phone) => {
  return phone?.toString()?.length === 10;
};
