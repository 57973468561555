import React, { useState, useEffect } from "react";
import { logger } from "../../../../Utils";
import { withRouter, NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import RequestTable from "./RequestTable";
import { postAPI, getAPI } from "../../../../Api";
import { useSelector } from "react-redux";
import { ModalBox } from "../../../components";
const log = logger("Repairs");
const Repairs = (props) => {
  const [activeKey, setActiveKey] = useState("new_to_old");  
  const [maintenance, setMaintenance] = useState([]);  
  const [cancelSuccessModalShow, setCancelSuccessModalShow]=useState(false);  
  const id = useSelector((state) => state.auth.id);
  const role = Number(Cookies.get("role"));
  

  useEffect(() => {
    if (id !== undefined) {
      getMaintenanceList();
    }
  }, [id]);

  const getMaintenanceList=(key)=>{
    const url = "/maintenance-request/list";
    let data
    let sort
      if(key==="new_to_old"){
        sort="desc"
      }else if(key==="old_to_new"){
        sort="asc"
      }else{
        sort="desc"
      }
    if(role===2){      
       data = {
        property_id: Number(props.match.params.property_id),
        property_detail_id: Number(props.match.params.property_details_id),
        landlord_id: Number(id),
        sort_by: sort,
      };
    }else if(role===3){
       data = {
        property_id: Number(props.match.params.property_id),
        property_detail_id: Number(props.match.params.property_details_id),
        tenant_id: Number(id),
        sort_by: sort,
      };
    }
    
    postAPI(url, data).then((response) => {     
      if (response.data.status_code === 200) {         
        setMaintenance(response.data.data);
      }
    });
  }
  const handleDropdownSelect = (key) => {
    setActiveKey(key);
    getMaintenanceList(key)
  };

  const createListing = () => {
    props.history.push(`/repair-request/${props.match.params.property_id}/${props.match.params.property_details_id}`);
  };

  const deleteItemHandler =(deleteId)=>{
    const url = `/maintenance-request/delete/${deleteId}`
    getAPI(url).then((response) => {
      getMaintenanceList();
      setCancelSuccessModalShow(true);     
   });
}

  return (
    <>
      <div className="profile-container">
        <div className="profile-container-box">
          <div className="ln-create-btn-area"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: "20",
              marginBottom: "16px",
            }}
          >
            <Navbar className="filter-nav">
              <Navbar.Collapse className="d-flex justify-content-between">
                <Navbar.Text>posted date:</Navbar.Text>
                <NavDropdown
                  title={activeKey.split("_").join(" ")}
                  id="nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={(e) => handleDropdownSelect("new_to_old")}
                  >
                    posted date: new to old
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={(e) => handleDropdownSelect("old_to_new")}
                  >
                    posted date: old to new
                  </NavDropdown.Item>                 
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>
            <button className="create-listing-black-button">
              <span
                className="d-inline-block"
                onClick={() => createListing()}
                id="BTN-create-repair-request"
                data-description="Tenant chose to create repair request"
              >
                Create New
              </span>
              {/* <span className="d-md-none d-lg-none d-xl-none">add Listing</span> */}
            </button>
          </div>

          <RequestTable role={role}  deleteItem={deleteItemHandler} maintenanceList={maintenance}/>
          <ModalBox
          show={cancelSuccessModalShow}
          onHide={() => {
            setCancelSuccessModalShow(false);
          }}
          size="sm"
          actionbuttontitle={"got it"}
          buttonaction={() =>{
            setCancelSuccessModalShow(false);
          }}
        >
          <h5 className="created-success-msg">
           you have deleted this request
          </h5>
        </ModalBox>
        </div>
      </div>
    </>
  );
};

export default withRouter(Repairs);
