export const BasicInfoValidationSchema = {
    firstName: [
        { rule: "required", message: "First name required" },
    ],
    lastName: [
        { rule: "required", message: "Last name required" },
    ],
    email: [
        { rule: "required", message: "Email address required" },
        { rule: "email", message: "Email is invalid" }
    ],
    new_email: [
        { rule: "required", message: "Email address required" },
        { rule: "email", message: "Email is invalid" }
    ],
    password: [
        { rule: "required", message: "Password is required" }
    ],
    phoneNumber: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number is not valid" }
    ]
}