import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./style.scss";
import Cookies from "js-cookie";
import { Button, Image } from "react-bootstrap";
import { ThreeDotsDropdown } from "..";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch } from "react-redux";
import { getCurrentPage } from "../../../Store/actions/PropertyDetailAction";
import { withRouter } from 'react-router-dom';

const PageTitleHeader = (props) => {
  const {
    isMobile,
    title,
    extraClass,
    subTitle,
    mobileTitle,
    isArrow,
    onClick,
    progress,
    rightBtnHandler,
    rightBtn,
    rightBtnText,
    rightBtnStyle,
    leftBtnHandler,
    leftBtn,
    leftBtnText,
    threeDotNav,
    setDeactivateReasonOptions,
    relistingProperty,
    setSharedListing,
    setSharedAnalytics,
    saveLoading,
    status,
    propertyId,
    propertyDetailsId,
    showRelist,
    isInProgress,
    history
    
  } = props;
  
  const role = Number(Cookies.get("role"));

  const onDeactivateListing = () => {
    setDeactivateReasonOptions(true);
  }

  const onShareListing = () => {
    setSharedListing(true);
  }

  const onSharedAnalytics = () => {
    setSharedAnalytics(true);
  }
  
  const dispatch = useDispatch();


  const editDetails = (step) => {
    let url = `/edit-listing/${propertyId}/${propertyDetailsId}`;

    dispatch(getCurrentPage(step));
    history.push({
      pathname: url
    }); 
  }

  return (
    <React.Fragment>
      <div className={`${role !== 1 ? "profile-header" : extraClass === "leaseInfoTitle" ? 'profile-header changes leaseInfoTitleContainer' : "profile-header changes"}`}>
        <div className="d-flex justify-content-start align-items-center">
          {isArrow && (
            <div
              className={
                role !== 1
                  ? "profile-header-image"
                  : `profile-header-image mr-2 ${extraClass}`
              }
              onClick={onClick}
            >
              <img
                alt="arrowVector"
                src={require("../../../_assets/images/arrowVector.png")}
                width={role !== 1 || extraClass ? "24" : "18"}
                height={role !== 1 || extraClass ? "21" : "13"}
                className={
                  role !== 1 || extraClass
                    ? `${!isMobile && 'd-inline-block'} align-top ${!isMobile ? 'arrowwidth':''}`
                    : "d-inline-block align-top mt-2"
                }
                style={isMobile?{width:18,height:13,alignItems:'center',display:'flex'}:{}}
              />
            </div>
          )}
          <div className={role !== 1 ? "profile-name" : "profile-name changes"}>
            <span className={"img-dt-title navbar-brand-name " + extraClass}>
              {title}
            </span>
            {status && <div className={`header-status ${status}`}>{status}</div>}

            <span className="img-mb-title navbar-brand-name">{mobileTitle}</span>
            {subTitle && (
              <div>
                <span className="img-dt-subtitle" style={{ display: "block" }}>
                  {subTitle}
                </span>
              </div>
            )}
          </div>
        </div>
        {leftBtn === "true" && (
          <Button onClick={leftBtnHandler} className="left-Btn page-header-left-button">
            {leftBtnText}{saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
          </Button>
        )}
        {rightBtn === "true" && (
          <Button onClick={rightBtnHandler} className="right-Btn page-header-right-button" style={{ ...rightBtnStyle }} variant="success">
            <div>
              {rightBtnText}
            </div>

          </Button>
        )}
        {(threeDotNav === 'property-details' && !isInProgress) &&
          <
          >
          <Container className="threeBtn">
            <Row className="threeBtns">
         <Col sm={4} className="btnCol"> <Button className="threeBtn1" onClick={() => editDetails(6)}>promote</Button></Col><Col sm={4} className="btnCol"><Button className="threeBtn1" onClick={() => onShareListing()}>share</Button></Col><Col sm={4}className="btnCol"><Button className="deactivate-btn" onClick={() => onDeactivateListing()}>deactivate</Button></Col>
          </Row>
          </Container>
          </>
         
          }

        {(threeDotNav === 'property-relist') &&
          <ThreeDotsDropdown
            icon={<Image
              width="6px"
              height="24px"
              alt="three-dots-vertical"
              src={require(`../../../_assets/images/three-dots-vertical.svg`)} />}
            actionsButton={
              <>
                {showRelist && <div className="dropdown-item cursor-pointer" onClick={relistingProperty}>
                  Relist Listing
                </div>}
                <div className="dropdown-item cursor-pointer">
                  <Link style={{ textDecoration: 'none', color: '#212529' }} to={`/create-notice/${propertyId}/${propertyDetailsId}`}>End The Tenancy</Link>
                </div>
              </>
            }
          />}

        {props.extraElm && <>{props.extraElm}</>}
      </div>

      <div className="progress-bar-outer">
        {progress && <ProgressBar now={progress} />}
      </div>
    </React.Fragment>
  );
};

export default PageTitleHeader;
