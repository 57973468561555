import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { getAPI } from '../../../../Api';
import history from '../../../../history';
import { DollorNumberDisplay, EditButton, LargeButton, PhoneNumberFormat } from '../../../components';
import { leaseTypeDisplay, aminitiesDisplay, aminitiesGrid, rentPaidDisplay, parkingTypeDisplay } from './LeaseConstants';
import { dateFormat2, dateFormat3 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { useDispatch, useSelector } from 'react-redux';
import { getDeactiveReasonSelected, getLeaseData } from '../../../../Store/actions/Action';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import CompleteLeaseLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/CompleteLeaseLoader/CompleteLeaseLoader';

const log = logger('CompleteLeaseDetails');
const CompleteLeaseDetails = (props) => {
	const { component, openSignLease, openFinalCreateLease, backClicked } = props;
	log.info('component', component)
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState('');
	const [user_id, setUserId] = useState('');
	const [leaseDetailsData, setLeaseDetails] = useState(null);
	const [propertyAddress, setPropertyAddress] = useState(null);
	const [contactDetails, setContactDetails] = useState(null);
	const [landlordMailingDetails, setLandlordMailingDetails] = useState(null);
	const [otherDetails, setOtherDetails] = useState(null);
	const [tenantDetails, setTenantDetails] = useState(null);
	const [reasondata] = useState(null);
	const [leaseData, setCompleteLeaseData] = useState('');
	const [other_service_utilities, setOther_service_utilities] = useState([]);
	const dispatch = useDispatch();
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const [notices_and_documents, setNotices_and_documents] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [amenitiesData, setAmenitiesData] = useState([]);

	const edit = (pathLocate) => {
		if (pathLocate === 'contact') {
			props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/contact-details/${lease_id}` })
			history.go(0);
		} else if (pathLocate === 'leaseDetail') {
			props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/lease-details/${lease_id}` })
			history.go(0);
		} else if (pathLocate === 'tenantDetail') {
			props.history.push({ pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/tenant-details/${lease_id}` })
			history.go(0);
		}
	}
	const viewAppendix = () => {
		log.info('viewAppendix action here...')
		props.history.push({
			pathname: `/view-appendix/${property_id}/${property_details_id}/${lease_id}/${user_id}`,
			state: {
				// singleReasonData: reasondata,
				// path: path,
				pdfUrl: leaseDetailsData && leaseDetailsData.lease_appendix,
				// user_id: user_id
			}
		})
	}
	const onSubmit = () => {
		dispatch(getDeactiveReasonSelected(reasondata))
		if (leaseData && leaseData.tenant_details &&
			leaseData.tenant_details.tenants && leaseData.tenant_details.tenants.length > 0) {
			if (leaseData && leaseData.is_signed) {
				openFinalCreateLease(true);
			} else {
				openSignLease(true, reasondata);
			}
		} else {
			toaster('error', 'Please add tenant!');
		}

	}
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const handleClickInside = (option) => {
		setIsPopoverOpen(option === 'yes' ? true : false);
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setLeaseId(props.match.params.lease_id);
		setUserId(props.match.params.user_id);
		(async () => {
			const { amenitiesData = [] } = await getUtilitiesAndAmenities()
			setAmenitiesData(amenitiesData)
		})()
	}, [
		props.match.params.property_id,
		props.match.params.property_details_id,
		props.match.params.lease_id,
		props.match.params.user_id
	]);
	useEffect(() => {
		if (property_details_id && lease_id) {
			setLoading(true)
			const url = `/admin/listing/lease/get-lease-details/${property_details_id}/${lease_id}`
			getAPI(url).then(res => {
				console.log(res)
				setLoading(false)
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setCompleteLeaseData(fetchedData)
					if (fetchedData) {
						setContactDetails(fetchedData.contact_details)
						setNotices_and_documents(fetchedData.notices_and_documents)
						setPropertyAddress(fetchedData.property_address)
						setLeaseDetails(fetchedData.lease_deatils)
						setLandlordMailingDetails(fetchedData.landlord_mailing_address)
						setOtherDetails(fetchedData.other_details)
						setTenantDetails(fetchedData.tenant_details)
					}
					dispatch(getLeaseData(fetchedData))
				}
			})
		}
	}, [property_details_id, lease_id, dispatch]);
	useEffect(() => {
		if (backClicked === true) {
			if (property_details_id && lease_id) {
				setLoading(true)
				const url = `/admin/listing/lease/get-lease-details/${property_details_id}/${lease_id}`
				getAPI(url).then(res => {
					setLoading(false)
					if (res.status === 200) {
						const fetchedData = res.data.data;
						setCompleteLeaseData(fetchedData)
						if (fetchedData) {
							setContactDetails(fetchedData.contact_details)
							setNotices_and_documents(fetchedData.notices_and_documents)
							setPropertyAddress(fetchedData.property_address)
							setLeaseDetails(fetchedData.lease_deatils)
							setLandlordMailingDetails(fetchedData.landlord_mailing_address)
							setOtherDetails(fetchedData.other_details)
							setTenantDetails(fetchedData.tenant_details)
						}
						dispatch(getLeaseData(fetchedData))
					}
				})
			}
		}
	}, [property_details_id, lease_id, dispatch, backClicked]);


	useEffect(() => {
		unitDetails && amenitiesData.filter(data => data.value !== 1).map(filter => {
			if (unitDetails.amenities.includes(filter.value)) {
				setOther_service_utilities(amenities => [...amenities, filter.title])
			}
			return filter
		})
		log.info(unitDetails)
	}, [unitDetails])
	const maxLength = 25;
	return (isLoading === true ? <CompleteLeaseLoader /> : <>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>landlord contact information</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('contact')} />
					</div>
				</div>
				<hr />
				<Row>
					{(contactDetails !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Full Name</Form.Label>
							<span>{(contactDetails.full_name !== null && contactDetails.full_name !== "") ? contactDetails.full_name : '--'}</span>
						</Form.Group>}
					{(contactDetails !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Email Address</Form.Label>
							<span>{(contactDetails.email !== null && contactDetails.email !== "") ? contactDetails.email : '--'}</span>
						</Form.Group>
					}
					{(contactDetails !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Phone number</Form.Label>
							<span><PhoneNumberFormat phoneNumber={(contactDetails.phone_number !== null && contactDetails.phone_number !== "") ? contactDetails.phone_number : '--'} /></span>
						</Form.Group>
					}
				</Row>
				<Row>
					{(notices_and_documents) &&
						<>
							<Form.Group as={Col} md='12'>
								<Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
								<span>{notices_and_documents.agree_to_received_notice === 1 ? <> Yes - email: {notices_and_documents.agree_to_received_notices_email}</> : 'No'}</span>
							</Form.Group>

							<Form.Group as={Col} md='12'>
								<Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
								<span>{notices_and_documents.day_to_day_communication ?
									<>
										Yes  - email: {notices_and_documents.day_to_day_communication_email}, phone number:
                                        <PhoneNumberFormat phoneNumber={notices_and_documents.day_to_day_communication_phone_number} />
									</>
									: 'No'}</span>
							</Form.Group>
						</>}
				</Row>
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>landlord mailing address</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('contact')} />
					</div>
				</div>
				<hr />
				<Row>
					{(landlordMailingDetails) &&
						<>

							{(landlordMailingDetails !== null) &&
								<Form.Group as={Col} md='4'>
									<Form.Label>Address</Form.Label>
									<span>{(landlordMailingDetails.mailing_address !== null && landlordMailingDetails.mailing_address !== "") ? landlordMailingDetails.mailing_address : '--'}</span>
								</Form.Group>
							}
							{(landlordMailingDetails !== null) &&
								<Form.Group as={Col} md='2'>
									<Form.Label>Unit</Form.Label>
									<span>{(landlordMailingDetails.mailing_unit_no !== null && landlordMailingDetails.mailing_unit_no !== "") ? landlordMailingDetails.mailing_unit_no : '--'}</span>
								</Form.Group>
							}
							{(landlordMailingDetails !== null) &&
								<Form.Group as={Col} md='2'>
									<Form.Label>City</Form.Label>
									<span>{(landlordMailingDetails.mailing_city !== null && landlordMailingDetails.mailing_city !== '') ? landlordMailingDetails.mailing_city : '--'}</span>
								</Form.Group>
							}
							{(landlordMailingDetails !== null) &&
								<Form.Group as={Col} md='2'>
									<Form.Label>Province</Form.Label>
									<span>{(landlordMailingDetails.mailing_province !== null && landlordMailingDetails.mailing_province !== '') ? landlordMailingDetails.mailing_province : '--'}</span>
								</Form.Group>
							}
							{(landlordMailingDetails !== null) &&
								<Form.Group as={Col} md='2'>
									<Form.Label>Postal Code</Form.Label>
									<span>{(landlordMailingDetails.mailing_postal_code !== null && landlordMailingDetails.mailing_postal_code !== '') ? landlordMailingDetails.mailing_postal_code : '--'}</span>
								</Form.Group>
							}
						</>}
				</Row>
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>rental property address</h4>
					{/* {component === 'lease-detail' ? */}
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('contact')} />
					</div>
					{/* : ''} */}
				</div>
				<hr />
				<Row>
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='4'>
							<Form.Label>Address</Form.Label>
							<span>{(propertyAddress.address !== null && propertyAddress.address !== "") ? propertyAddress.address : '--'}</span>
						</Form.Group>
					}
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Unit</Form.Label>
							<span>{(propertyAddress.unit_no !== null && propertyAddress.unit_no !== "") ? propertyAddress.unit_no : '--'}</span>
						</Form.Group>
					}
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>City</Form.Label>
							<span>{(propertyAddress.city !== null && propertyAddress.city !== "") ? propertyAddress.city : '--'}</span>
						</Form.Group>
					}
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Province</Form.Label>
							<span>{(propertyAddress.province !== null && propertyAddress.province !== "") ? propertyAddress.province : '--'}</span>
						</Form.Group>
					}
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Postal Code</Form.Label>
							<span>{(propertyAddress.postal_code !== null && propertyAddress.postal_code !== "") ? propertyAddress.postal_code : '--'}</span>
						</Form.Group>}
					{(propertyAddress !== null) &&
						<Form.Group as={Col} md='2'>
							<Form.Label>Is this rental unit in a condo?</Form.Label>
							<span>{propertyAddress.is_condo === 0 ? 'No' : 'Yes'}</span>
						</Form.Group>
					}
				</Row>
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>lease details</h4>
					{/* {component === 'lease-detail' ? */}
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('leaseDetail')} />
					</div>
					{/* : ''} */}
				</div>
				<hr />
				{leaseDetailsData !== null &&
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Lease Type</Form.Label>
							<span>{(leaseDetailsData.lease_type !== null && leaseDetailsData.lease_type !== "") ? leaseTypeDisplay(leaseDetailsData.lease_type) : '--'}</span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Start  Date</Form.Label>
							<span>{leaseDetailsData.lease_start_date ? dateFormat2(leaseDetailsData.lease_start_date) : '--'}</span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>End Date</Form.Label>
							<span>{leaseDetailsData.lease_end_date ? dateFormat2(leaseDetailsData.lease_end_date) : '--'}</span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Additional Terms</Form.Label>
							<span>{(leaseDetailsData.tenancy_agreement === 1) ? 'Yes' : 'No'}</span>
						</Form.Group>
						{(leaseDetailsData.tenancy_agreement === 1) &&
							<Form.Group as={Col} md='4'>
								<Form.Label>Attached Terms</Form.Label>
								<span className='cursorPointer pdf-title' onClick={() => viewAppendix()}>
									<Image className="icon" width='25' src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" style={{ marginRight: '10px' }} />
									{leaseDetailsData.lease_appendix && <>
										{leaseDetailsData.lease_appendix.split('/').pop().length > maxLength ? leaseDetailsData.lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseDetailsData.lease_appendix.split('/').pop()}
									</>}
								</span>
							</Form.Group>}
					</Row>
				}
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>more details about the rent</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('leaseDetail')} />
					</div>
				</div>
				<hr />
				{(leaseDetailsData !== null) &&
					<>
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Rent is to be paid on:</Form.Label>
								<span>{(leaseDetailsData.rent_paid_on !== null || leaseDetailsData.rent_paid_on !== '') ?
									<>{leaseDetailsData.rent_paid_on === '1st' ? '1st day of each month' : leaseDetailsData.rent_paid_on}</>
									: '--'}</span>
							</Form.Group>
							<Form.Group as={Col} md='2' className='pl-0'>
								<Form.Label>Base rent for the rental property</Form.Label>
								<span>{(leaseDetailsData.rent !== null || leaseDetailsData.rent !== '') ?
									<DollorNumberDisplay price={leaseDetailsData.rent} />
									: '--'}</span>
							</Form.Group>
							<Form.Group as={Col} md='2'>
								<Form.Label>Is parking available?</Form.Label>
								<span>{leaseDetailsData.is_parking_available === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{leaseDetailsData.is_parking_available === 1 &&
								<>
									<Form.Group as={Col} md='1' className='pr-0 pl-0'>
										<Form.Label>Parking Type</Form.Label>
										<span>{parkingTypeDisplay(leaseDetailsData.parking_type)}</span>
									</Form.Group>
									<Form.Group as={Col} md='1'>
										<Form.Label># of spots</Form.Label>
										<span>{(leaseDetailsData.parking_spot !== null) ? leaseDetailsData.parking_spot : '--'}</span>
									</Form.Group>
									<Form.Group as={Col} md='2'>
										<Form.Label>Price for parkings</Form.Label>
										<span>{(leaseDetailsData.parking_price !== null) ?
											<DollorNumberDisplay price={leaseDetailsData.parking_price} />
											: '--'}</span>
									</Form.Group>
								</>
							}
							<Form.Group as={Col} md='2'>
								<Form.Label>Rent is payable to:</Form.Label>
								<span>{(leaseDetailsData.rent_payable_to !== null || leaseDetailsData.rent_payable_to !== '') ? leaseDetailsData.rent_payable_to : '--'}</span>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Payment Method</Form.Label>
								<span>{rentPaidDisplay(leaseDetailsData.rent_pay_method)}</span>
							</Form.Group>
							<Form.Group as={Col} md='3'>
								<Form.Label>Is the first rental period a partial period?</Form.Label>
								<span>{leaseDetailsData.first_rental_partial_period === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{leaseDetailsData.first_rental_partial_period === 1 &&
								<>
									<Form.Group as={Col} md='3'>
										<Form.Label>Partial Period</Form.Label>
										<span>{dateFormat3(leaseDetailsData.partial_cover_start_date)} to {dateFormat3(leaseDetailsData.partial_cover_end_date)}</span>
									</Form.Group>
									<Form.Group as={Col} md='3'>
										<Form.Label>Partial Period Payment Amount</Form.Label>
										<span><DollorNumberDisplay price={leaseDetailsData.partial_cover_amount} />&nbsp;{'on'} {dateFormat3(leaseDetailsData.partial_payment_date)}</span>
									</Form.Group>
								</>
							}
						</Row>
						<Row>
							<Form.Group as={Col} md='4'>
								<Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
								<span>{leaseDetailsData.administrative_charge !== null ? <DollorNumberDisplay price={leaseDetailsData.administrative_charge} /> : '--'}</span>
							</Form.Group>
							{otherDetails &&
								<Form.Group as={Col} md='2'>
									<Form.Label>Is smoking allowed?</Form.Label>
									<span>{otherDetails.is_smoking_allowed === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>}
						</Row>
					</>
				}
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>deposits and discounts</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('leaseDetail')} />
					</div>
				</div>
				<hr />
				{leaseDetailsData &&
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is there a rent deposit?</Form.Label>
							<span>{leaseDetailsData.is_rent_deposit === 1 ? 'Yes' : 'No'}</span>
						</Form.Group>
						{leaseDetailsData.is_rent_deposit === 1 &&
							<Form.Group as={Col} md='4'>
								<Form.Label>Rent Deposit Amount</Form.Label>
								<DollorNumberDisplay price={leaseDetailsData.is_rent_deposit_amount} />
							</Form.Group>
						}
					</Row>}
				{otherDetails &&
					<>
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Is there a key deposit?</Form.Label>
								<span>{otherDetails.is_key_deposite === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{otherDetails.is_key_deposite === 1 &&
								<Form.Group as={Col} md='4'>
									<Form.Label>key Deposit amount (can only be amount to replace key)</Form.Label>
									<DollorNumberDisplay price={otherDetails.key_deposite_amount} />
								</Form.Group>
							}
						</Row>
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Is there a rent discount</Form.Label>
								<span>{otherDetails.is_rent_discounted === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{otherDetails.is_rent_discounted === 1 &&
								<>
									<Form.Group as={Col} md='2'>
										<Form.Label>Amount</Form.Label>
										<DollorNumberDisplay price={otherDetails.rent_discount_amount} />
									</Form.Group>
									<Form.Group as={Col} md='8'>
										<Form.Label>Rent Discount Description</Form.Label>
										<span>{otherDetails.rent_discount_description}</span>
									</Form.Group>
								</>
							}
						</Row>
					</>
				}
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>who pays for the utilities</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('leaseDetail')} />
					</div>
				</div>
				<hr />
				<Row>
					<Form.Group as={Col} md='2'>
						<Form.Label>Electricity</Form.Label>
						<span>{leaseDetailsData !== null && (leaseDetailsData.utility_electricity_pay_by === 1 ? "Landlord" : "Tenant")}</span>
					</Form.Group>

					<Form.Group as={Col} md='2'>
						<Form.Label>Heat</Form.Label>
						<span>{leaseDetailsData !== null && (leaseDetailsData.utility_heat_pay_by === 1 ? "Landlord" : "Tenant")}</span>
					</Form.Group>

					<Form.Group as={Col} md='2'>
						<Form.Label>Water</Form.Label>
						<span>{leaseDetailsData !== null && (leaseDetailsData.utility_water_pay_by === 1 ? "Landlord" : "Tenant")}</span>
					</Form.Group>
				</Row>
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>amenities</h4>
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('leaseDetail')} />
					</div>
				</div>
				<hr />
				{leaseDetailsData !== null &&
					<Row>
						{leaseDetailsData.amenities && leaseDetailsData.amenities.map((data, i) => {
							return <Form.Group as={Col} md={aminitiesGrid(data.id)} key={i}>
								<Form.Label>{aminitiesDisplay(data.id)}</Form.Label>
								<span>{data.allow === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
						})}
						<Form.Group as={Col} md={'6'}>
							<Form.Label>Others</Form.Label>
							{other_service_utilities &&
								<ul className='amenities'>
									{other_service_utilities.map((data, i) => {
										return <li key={'amenities' + i}>{data}</li>
									})}
									{leaseDetailsData.other_service_utilities && leaseDetailsData.other_service_utilities[0] !== '' && <li>{leaseDetailsData.other_service_utilities[0]}</li>}
								</ul>}
						</Form.Group>
					</Row>
				}
			</div>
		</div>
		<div className='inner-wrapper mb-4 complete-lease-details'>
			<div className='body-container'>
				<div className='d-flex justify-content-between'>
					<h4>tenant details</h4>
					{/* {component === 'lease-detail' ? */}
					<div className='button-wrapper pt-0'>
						<EditButton onClick={() => edit('tenantDetail')} />
					</div>
					{/* : ''} */}
				</div>
				<hr />
				<Row>
					{(tenantDetails !== null && tenantDetails.tenants.length !== 0) &&
						<Form.Group as={Col} md='5'>
							<Row>
								<Form.Group as={Col} md='4' className='utilities-list'>
									<Form.Label>Tenant Name</Form.Label>
								</Form.Group>
								<Form.Group as={Col} md='8' className='utilities-list'>
									<Form.Label>Email</Form.Label>
								</Form.Group>
							</Row>
							{tenantDetails.tenants.map((tenant, i) => {
								return <Row key={i}>
									<Form.Group as={Col} md='4' className='utilities-list'>
										<span>{tenant.name}</span>
									</Form.Group>
									<Form.Group as={Col} md='8' className='utilities-list'>
										<span className='utilities'>{tenant.email}</span>
									</Form.Group>
								</Row>
							})}

						</Form.Group>
					}
					<Form.Group as={Col} md='6'>
						<div className='d-flex'>
							<Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
							<span className='warning-popover'>
								<img className='cursorPointer ml-2 mb-1'
									onMouseEnter={() => handleClickInside('yes')}
									onMouseLeave={() => handleClickInside('no')}
									src={require('../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
								{(isPopoverOpen === true) && <div className="custom-popover extra">
									<p className='popover-content'>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute</p>
								</div>}
							</span>
						</div>
						<span className='utilities'>{tenantDetails !== null && (tenantDetails.required_tenant_insurance === 1 ? 'Yes' : 'No')}</span>
					</Form.Group>
				</Row>
			</div>
		</div>
		<div className='d-flex justify-content-between'>

			<Form.Group controlId="formBasicCheckbox" className=''>
				{(leaseDetailsData && leaseDetailsData.tenancy_agreement === 1) &&
					<LargeButton title='view appendix' variant='dark' onClick={() => viewAppendix()} style={{ display: 'block', width: "300px", height: "40px", padding: "0px" }} />
				}
			</Form.Group>
			{component === 'lease-detail' ?
				<div className='container-footer'>
					<Button variant='success' type='submit' onClick={() => onSubmit()}>Next</Button>
				</div> : ''}
		</div>
	</>);
}

export default withRouter(CompleteLeaseDetails);
