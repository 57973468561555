import React from 'react';
import "../style.scss"
import downVector from "../../../_assets/images/homepage/downVector.svg";

const HomeHero = () => {
    return (
        <div className={"homeHero container"}>
          <div className="row">
            <div className="heroTextCont col-md">
                <h2 className={"heroHeading"}>
                    you've found<br />
                    <span>the rental experts</span>
                </h2>
                <p>
                  We are investors and landlords ourselves, looking to make
                  landlording possible by providing the best tools, services and
                  becoming part of your power team. We live and breathe rentals
                  and work every day to make landlords more successful.
                </p>
            </div>
            <div className={"heroOverlay col-md mobile-only"}>
                <div className="heroImg"></div>
            </div>
          </div>
        </div>
    )
};

export default HomeHero;

