export const N1Schema = {
    rentDate: [
        { rule: "required", message: "Date required" },
    ],
    rent: [
        { rule: "required", message: "rent required" },
    ],
    newRent: [
        { rule: "required", message: "new rent required" },
    ],   
    signingName: [
        { rule: "required", message: "signing name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}