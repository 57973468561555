import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import CheckBox from "../../Checkbox/Checkbox";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N12Schema } from "./ValidationSchema/N12Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const N12Form = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    getParalegal,
    triggerReqTenant,
    onBack, 
    onNoticeDataUpdate
  } = props;
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const [reasons, setReasons] = useState("reason1");
  const [reason1, setReason1] = useState([]);
  const [reason1Error, setReason1Error]= useState(false);
  const [reason2Error, setReason2Error]= useState(false);
  const [reason2, setReason2] = useState([]);
  const [globalState,setGlobalState]=useGlobalState()



  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
    onNoticeDataUpdate({
      tenant_move_out: terminationDate,
      end_tenancy_reason_1_move_into_rental_unit: reason1,
      end_tenancy_reason_1: reasons === "reason1" ? 1 : 0,
      end_tenancy_reason_2: reasons === "reason2" ? 1 : 0,
      end_tenancy_reason_2_purchase_and_sale_of_rental_unit: reason2,
      signData,
      notice_type:12
    })
    setGlobalState({ onSubmit: onSubmit })

  },[
    tenant_id.length,property_id,
    terminationDate,
    phoneError,
    signData,
    reasons,
    reason1,
    reason2,
    reason1Error,
    reason2Error
  ])

  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (reasons === "reason1" && reason1?.length === 0) {
      setReason1Error(true);
    }
    if (reasons === "reason2" && reason2?.length === 0) {
      setReason2Error(true);
    }
    if (
      validate(N12Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    )  {
      const url = "/landlord/notice/store-notice";
      const data = {
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 12,
        tenant_move_out: terminationDate,
        agree_terms_and_conditions: "1",
        end_tenancy_reason_1_move_into_rental_unit:  reason1?.length ? typeof reason1!=='string'?reason1:JSON.parse(reason1):[] ,
        end_tenancy_reason_1: reasons === "reason1" ? 1 : 0,
        end_tenancy_reason_2: reasons === "reason2" ? 1 : 0,
        
        end_tenancy_reason_2_purchase_and_sale_of_rental_unit: reason2?.length ? typeof reason2!=='string'?reason2: JSON.parse(reason2):[],
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 14,
      };
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    }
  };

  const checkboxHandler = (id, type) => {
    let tempArray;
    if (type === "reason1") {
      tempArray = [...(reason1||[])];
    } else if (type === "reason2") {
      tempArray = [...(reason2||[])];
    }

    if (tempArray.includes(id)) {
      const index = tempArray.indexOf(id);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
    } else {
      tempArray.push(id);
    }
    if (type === "reason1") {
      setReason1(tempArray);
    } else if (type === "reason2") {
      setReason2(tempArray);
    }
  };

  useEffect(() => {
    if (noticeData) {
       if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());      
      setReasons(noticeData.end_tenancy_reason_1=="1" ? "reason1" : noticeData.end_tenancy_reason_2=="1" && "reason2" )    
      setReason1(noticeData.end_tenancy_reason_1_move_into_rental_unit || [])
      setReason2(noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit || [])
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Notice to End Tenancy
            <br />
            Landlord, a purchaser or a family member requires the rental unit
            (N12)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>

        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">Reason(s) for Ending Tenancy</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                Please select one to indicate your reason for ending the tenancy
              </Form.Label>
              <div className={"select-radio-wrapper " + (reason1Error && "error-bg")}>
                <Form.Check
                  key={"reason1"}
                  type="radio"
                  id={`reason1`}
                  name="reasons"
                  value={"reason1"}
                  checked={reasons === "reason1" ? true : false}
                  onChange={() => {
                    setReasons("reason1");
                    setReason2([]);
                    setReason2Error(false)
                  }}
                  label={
                    "Reason 1: The following person intends to move into the rental unit and occupy it for at least one year:"
                  }
                />
                {reasons === "reason1" && (
                  <div className="internal-checkboxes">
                    <div className="check-title">
                      <span>Select all that apply</span>{reason1Error && <span className="invalid-select ml-3">required</span>}
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"Me"}
                          name={"1"}
                          checked={reason1?.includes("1")}
                          onChange={() => checkboxHandler("1", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Spouse"}
                          name={"2"}
                          checked={reason1?.includes("2")}
                          onChange={() => checkboxHandler("2", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Child"}
                          name={"3"}
                          checked={reason1?.includes("3")}
                          onChange={() => checkboxHandler("3", "reason1")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Parent(s)"}
                          name={"4"}
                          checked={reason1?.includes("4")}
                          onChange={() => checkboxHandler("4", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's child"}
                          name={"5"}
                          checked={reason1?.includes("5")}
                          onChange={() => checkboxHandler("5", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's parent"}
                          name={"6"}
                          checked={reason1?.includes("6")}
                          onChange={() => checkboxHandler("6", "reason1")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          label={
                            "A person who provides or will provide care services to:"
                          }
                          name={"7"}
                          checked={reason1?.includes("7")}
                          onChange={() => checkboxHandler("7", "reason1")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"Me"}
                          name={"8"}
                          checked={reason1?.includes("8")}
                          onChange={() => checkboxHandler("8", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Spouse"}
                          name={"9"}
                          checked={reason1?.includes("9")}
                          onChange={() => checkboxHandler("9", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Child"}
                          name={"10"}
                          checked={reason1?.includes("10")}
                          onChange={() => checkboxHandler("10", "reason1")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Parent(s)"}
                          name={"11"}
                          checked={reason1?.includes("11")}
                          onChange={() => checkboxHandler("11", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's child"}
                          name={"12"}
                          checked={reason1?.includes("12")}
                          onChange={() => checkboxHandler("12", "reason1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's parent"}
                          name={"13"}
                          checked={reason1?.includes("13")}
                          onChange={() => checkboxHandler("13", "reason1")}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">              
            <div className={"select-radio-wrapper " + (reason2Error && "error-bg")}>
                <Form.Check
                  key={"reason2"}
                  type="radio"
                  id={`reason2`}
                  name="reasons"
                  value={"reason2"}
                  checked={reasons === "reason2" ? true : false}
                  onChange={() => {
                    setReasons("reason2");
                    setReason1([]);
                    setReason1Error(false)
                  }}
                  label={
                    "Reason 2: I have signed an Agreement of Purchase and Sale of the rental unit and the following person intends to move into the rental unit:"
                  }
                />
                {reasons === "reason2" && (
                  <div className="internal-checkboxes">
                    <div className="check-title">
                    <span>Select all that apply</span>{reason2Error && <span className="invalid-select ml-3">required</span>}
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"Me"}
                          name={"1"}
                          checked={reason2?.includes("1")}
                          onChange={() => checkboxHandler("1", "reason2")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Spouse"}
                          name={"2"}
                          checked={reason2?.includes("2")}
                          onChange={() => checkboxHandler("2", "reason2")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Child"}
                          name={"3"}
                          checked={reason2?.includes("3")}
                          onChange={() => checkboxHandler("3", "reason2")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Parent(s)"}
                          name={"4"}
                          checked={reason2?.includes("4")}
                          onChange={() => checkboxHandler("4", "reason2")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's child"}
                          name={"5"}
                          checked={reason2?.includes("5")}
                          onChange={() => checkboxHandler("5", "reason2")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's parent"}
                          name={"6"}
                          checked={reason2?.includes("6")}
                          onChange={() => checkboxHandler("6", "reason2")}
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          label={
                            "A person who provides or will provide care services to:"
                          }
                          name={"7"}
                          checked={reason2?.includes("7")}
                          onChange={() => checkboxHandler("7", "reason2")}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            disableError={(data) => disableError(data)}
            type={"lastName"}
            setSignData={(data) => setSignData(data)}
            notice_type={12}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N12Form);
