export const TenantDetailSchema = {
    possession_date: [
        { rule: "required", message: "Date required" },
    ],
    rent_discount_amount: [
        { rule: "required", message: "Discount amount required" },
    ],
    rent_discount_description: [
        { rule: "required", message: "Description required" },
    ],
    key_deposite_amount: [
        { rule: "required", message: "Deposite amount required" },
    ],
    current_address_landlord: [
        { rule: "required", message: "Address required" },
    ],
    city_landlord: [
        { rule: "required", message: "City required" },
    ],
    province_landlord: [
        { rule: "required", message: "Province required" },
    ],
    unit_no_landlord: [
        { rule: "required", message: "Unit number required" },
    ],
    postal_code_landlord: [
        { rule: "required", message: "Postal code required" },
    ],
}