import React, { useState } from 'react';
import { DropDown, SearchInput } from '../../../components';
import '../style.scss';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { sort_date, sort_content_type, sort_cms_status } from "../../../../Constants/adminConstant";

const CMSTableHeader = (props) => {
    const { sort_by_date, sort_by_type, sort_by_status, sortStatus, componentLoad, columns } = props;

    const [status_filter, setStatusFilter] = useState(false);
    const showStatusFilter = () => {
        setStatusFilter(!status_filter);
    }
    const [dateFilter, setDateFilter] = useState(false);
    const showDateFilter = () => {
        setDateFilter(!dateFilter);
    }
    const [typeFilter, setTypeFilter] = useState(false);
    const showTypeFilter = () => {
        setTypeFilter(!typeFilter);
    }

    return (
        columns.map((data, i) => {
            return (
                <th key={'table_head_' + i} width={data.width} className={data.header !== '' ? 'pr-0' : ''}>
                    {data.header}
                    {(() => {
                        switch (data.header) {
                            case 'Created Date':
                                return (
                                    <DropdownButton
                                        // alignLeft
                                        title={props.sortDateState}
                                        id='dropdown-menu-align-left'
                                        style={{ width: '100%' }}
                                    >
                                        {sort_date.map((data, i) => {
                                            return <Dropdown.Item eventKey={data.value} data-display={data.display} data-value={data.value}
                                            onClick={props.sortDate}
                                            key={i}>{data.display}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                )

                            case 'Content Type':
                                return (
                                    componentLoad === 'category-tags' || componentLoad === 'advertisement' ?
                                        <DropdownButton
                                            // alignLeft
                                            title='View all'
                                            id='dropdown-menu-align-left'
                                            className='all-listing-module'
                                            disabled={true}
                                        >
                                            <Dropdown.Item></Dropdown.Item>
                                        </DropdownButton>
                                        :
                                        <span className="request-type">
                                            <DropDown close={() => setTypeFilter(false)} open={typeFilter} position={"right"} enableTopArrow={false} style={{ width: "241px", top: "47px" }}
                                                content={
                                                    <div className="row checkbox-left check-status">
                                                        {sort_content_type.map((data, i) => {
                                                            return <div className="col-12" key={i}>
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-checkbox large">
                                                                        <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                            name={data.value}
                                                                            checked={sort_by_type.includes(data.value)} onChange={props.sortContentType}
                                                                        />
                                                                        <label className="custom-control-label pl-3 pt-1" style={{ textTransform: 'capitalize' }} htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            >
                                                <Dropdown onClick={showTypeFilter} className="multiselect-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {sort_content_type.filter((data) => {
                                                            let status = '';
                                                            if (sort_by_type.includes(data.value)) {
                                                                status = data;
                                                            }
                                                            return status;
                                                        }).map(function (obj) {
                                                            return obj.display;
                                                        }).join(", ")}
                                                    </Dropdown.Toggle>
                                                </Dropdown>
                                            </DropDown>
                                        </span>
                                )
                            case 'Status':
                                return (
                                    componentLoad === 'category-tags' || componentLoad === 'advertisement' ?
                                        <DropdownButton
                                            // alignLeft
                                            title='View all'
                                            id='dropdown-menu-align-left'
                                            className='all-listing-module'
                                            disabled={true}
                                        >
                                            <Dropdown.Item></Dropdown.Item>
                                        </DropdownButton>
                                        :
                                        <span className="request-type">
                                            <DropDown close={() => setStatusFilter(false)} open={status_filter} position={"right"} enableTopArrow={false} style={{ width: "241px", top: "47px" }}
                                                content={
                                                    <div className="row checkbox-left check-status">
                                                        {sort_cms_status.map((data, i) => {
                                                            return <div className="col-12" key={i}>
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-checkbox large">
                                                                        <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                            name={data.value}
                                                                            checked={sort_by_status.includes(data.value)} onChange={sortStatus}
                                                                        />
                                                                        <label className="custom-control-label pl-3 pt-1" style={{ textTransform: 'capitalize' }} htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            >
                                                <Dropdown onClick={showStatusFilter} className="multiselect-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {sort_cms_status.filter((data) => {
                                                            let status = '';
                                                            if (sort_by_status.includes(data.value)) {
                                                                status = data;
                                                            }
                                                            return status;
                                                        }).map(function (obj) {
                                                            return obj.display;
                                                        }).join(", ")}
                                                    </Dropdown.Toggle>
                                                </Dropdown>
                                            </DropDown>
                                        </span>
                                )
                            case '':
                                return (
                                    <span></span>
                                )
                            case 'Name':
                                return (
                                    <SearchInput searchValue={props.searchName} setSearchValue={props.setSearchName} placeHolder="Search" extraCalss="table-search-filter" />
                                )
                            case 'Category':
                                return (
                                    <SearchInput searchValue={props.searchCategory} setSearchValue={props.setSeachCategory} placeHolder="Search" extraCalss="table-search-filter" />
                                )
                            case 'Creator':
                                return (
                                    <SearchInput searchValue={props.searchCreator} setSearchValue={props.setSearchCreator} placeHolder="Search" extraCalss="table-search-filter" />
                                )
                            default:
                                return null
                        }
                    })()}
                </th>
            )
        })
    );
}

export default withRouter(CMSTableHeader);