import React from "react";
import "./style.scss";
import { DebounceInput } from 'react-debounce-input';

const SearchInput = (props) => {
    const { searchValue, setSearchValue, placeHolder, extraCalss, min_length } = props;
    return (
        <DebounceInput
            type="text" placeholder={placeHolder ? placeHolder : "Type to search location"} id="searchValue" name="searchValue"
            minLength={min_length ? min_length : 2}
            debounceTimeout={1000}
            className={"form-control searchBar mt-2 " + extraCalss}
            value={searchValue === null ? "" : searchValue}
            onChange={event => setSearchValue(event.target.value)}
        />
    );
}

export default SearchInput;
