import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import ListManagementTable from '../ListManagemantTable';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { getBackPath } from '../../../../Store/actions/Action';
import { setPromotedTableData, setPromotedCurrentPage } from '../../../../Store/actions/ListingAction';
import TableContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader';
import { dateFormat } from '../../../../Utils/DateSanitizer/DateSanitizer';

const log = logger('Promoted');
const Promoted = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const filters = useSelector(state => state.listingReducer.filters);
    const pagination = useSelector(state => state.listingReducer.promotedCurrentPage);
    const currentComponent = useSelector(state => state.listingReducer.currentComponent);
    const [oldFilter, setOldFilter] = useState({...filters})

    const getData = (url) => {
        let customFilter = {
            'city': filters.searchCity || '',
            'address': filters.searchAddress || '',
            'landlord': filters.searchLandlord || '',
            'rentpanda_id': filters.searchRentpandaId || '',
            'expiry_date': dateFormat(filters.filterByExpiryDate) === "Invalid date" ? '' : dateFormat(filters.filterByExpiryDate)
        }
        const data = {
            'filter': customFilter,
            'search_date': (filters.filterByUpdatedDate&&moment(filters.filterByUpdatedDate).format('YYYY-MM-DD') )|| '',
            'sort_by_price': {
                'order': filters.sort_by_price_order === '' ? 'all' : filters.sort_by_price_order
            },
            'sort_by_status': filters.sort_by_status.includes('all') ? [] : filters.sort_by_status || [],
            'limit': 10
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setIsLoading(false)
                dispatch(setPromotedTableData(response.data.data));
            }
        })
    }

    const goToProfileDetail = (data) => {
        log.info(data)
        props.history.push({
            pathname: `/profile-detail/listings/${data.user_type}/${data.user_id}/${data.property_id}/${data.property_details_id}`,
            state: { path: currentComponent }
        });
        dispatch(getBackPath(currentComponent))
    }

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        dispatch(setPromotedCurrentPage(nextPage));
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        dispatch(setPromotedCurrentPage(prevPage));
    }

    useEffect(() => {
        let setUrl = ''
        if(JSON.stringify(oldFilter) !== JSON.stringify(filters)){
            setUrl = '/admin/listing/promoted'
            setOldFilter({...filters})
        }else{
            if (pagination !== null && pagination > 1 ) {
                setUrl = `/admin/listing/promoted?page=${pagination}`
            }else{
                setUrl = '/admin/listing/promoted'
            }
        }
        getData(setUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, filters]);

    let userColumns = [];

    userColumns = [
        {
            header: 'City',
            name: 'city',
            width: '15%'
        },
        {
            header: 'Address',
            name: 'address',
            width: '20%'
        },
        {
            header: 'Landlord',
            name: 'landlord',
            width: '20%'
        },
        {
            header: 'Expiry Date',
            name: 'expiry_date',
            width: '15%'
        },
        {
            header: 'Price',
            name: 'price',
            width: '15%'
        },
        {
            header: 'Last Updated Date',
            name: 'search_date',
            width: '10%'
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'listingManagement'} /> : <>
                <ListManagementTable
                    columns={userColumns}
                    goToProfileDetail={(data) => goToProfileDetail(data)}
                    clickNext={(data) => onClickNext(data)}
                    clickPrev={(data) => onClickPrev(data)}
                />
            </>}
        </React.Fragment>
    )
};

export default withRouter(Promoted);