import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logger } from '../../../../Utils';
import { dateFormat2 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { DollorNumberDisplay, TenantDetailBlock } from '../../../components';
import '../style.scss';

const log = logger('TenantDetailSection')
const TenantDetailSection = (props) => {
    const { user_type, apiCallback } = props;
    const [user_id, setUserId] = useState(false);
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const leaseData = unitDetails && unitDetails.lease;

    log.info("unitDetails", unitDetails);
    useEffect(() => {
        setUserId(props.match.params.user_id);
    }, [props.match.params.user_id]);

    const viewLeaseDetails = () => {
        leaseData ?
            (props.history.push({
                pathname: `/review-lease-detail/${user_type}/${leaseData.id}/${user_id}/${leaseData.property_id}/${leaseData.property_detail_id}`,
                state: {
                    leaseData: leaseData.property_address,
                    path: 'tenant-details-section',
                    showLeaseInfoPage: true,
                }
            }))
            : log.error("No leaseData found", leaseData)
    }

    return (
        <>
            <Row className="container-detail admin">
                {unitDetails && (unitDetails.formatted_status === "rented" && unitDetails.lease !== null) ?
                    <TenantDetailBlock extraClass={'user admin-section'} leaseData={unitDetails && unitDetails.lease} heading={'Tenant'} apiCallback={apiCallback} />
                    : <Col md="12" className="card-detail">
                        <h4 className="not-rented">This property has not been rented yet</h4>
                    </Col>}
            </Row>
            {unitDetails && (unitDetails.formatted_status === "rented" && unitDetails.lease !== null) &&
                <Row className="container-detail admin">
                    <Col md="6" className='pl-0' >
                        <div className='card-detail'>
                            {(leaseData && !leaseData.is_manual_uploaded) &&
                                <div className='arrow-class cursorPointer' onClick={() => viewLeaseDetails()}>
                                    <img className='next-arrow' src={require('../../../../_assets/images/next.svg')} alt='next' />
                                </div>
                            }
                            <h4 className='user-name text-left'>PROPERTY LEASE</h4>
                            <div className='text-left'>
                                <span className='green-text'>{dateFormat2(unitDetails && unitDetails.lease.lease_start_date)} to {dateFormat2(unitDetails && unitDetails.lease.lease_end_date)}</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" className='pr-0'>
                        <div className='card-detail'>
                            <h4 className='user-name text-left'>PROPERTY RENT</h4>
                            <span className='green-text'>
                                <DollorNumberDisplay price={unitDetails && unitDetails.lease && unitDetails.lease.rent} />
                            </span>
                        </div>

                    </Col>
                </Row>
            }
        </>
    )
}

export default withRouter(TenantDetailSection);