import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N12Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={
            "Notice to end tenancy because the landlord, a purchaser or a family member requires the rental unit (N12 Form)"
          }
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
              The termination date the landlord sets out in this notice must be
              at least 60 days after the landlord gives you this notice. Also,
              the termination date must be the last day of the rental period.
              For example, if you pay rent on the first of each month, the
              termination date must be the last day of a month. Finally, if the
              tenancy is for a fixed term the termination date cannot be earlier
              than the last day of the fixed term. For example, if you signed a
              one-year lease, the termination date cannot be earlier than the
              last day of the one-year period set out in the lease.
            </span>
          </div>

          <div className="notice-info-title">
            <span>Tenant is entitled to compensation or another unit</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord
              has put in this notice. However, the landlord can apply to the
              Board to evict you. The Board will schedule a hearing where you
              can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
            If you move out of the rental unit by the termination date, your tenancy ends on that date.
            </span>
          </div>          
          <div className="notice-info-title">
            <span>A tenant can give 10 days' notice to end the tenancy</span>
          </div>
          <div className="notice-info-text">
            <span>
            You can terminate the tenancy sooner than the date set out in this notice as long as you give the landlord at least 10 days' notice that you intend to move out of the rental unit. You must use the Landlord and Tenant Board's Form N9 Tenant's Notice to End the Tenancy to give your written notice to the landlord.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord has put in this notice. However, the landlord can apply to the LTB to evict you. The LTB will schedule a hearing where you can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the LTB?</span>
          </div>
          <div className="notice-info-text">
            <span>
              The landlord can apply to the LTB to evict you immediately after giving you this notice. If the landlord applies to the LTB to evict you, the LTB will schedule a hearing and send you a copy of the application and the Notice of Hearing. The Notice of Hearing sets out the date, time and location of the hearing. At the hearing, the landlord will have to prove the claims they made in this Notice to End your Tenancy and in the application and you can respond to the claims your landlord makes. If the LTB issues an order ending your tenancy and evicting you, the order will not require you to move out any earlier than the termination date included in this notice.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
            For more information about this notice or your rights, you can contact the Landlord and Tenant Board. You can reach the Board by phone at <strong>416-645-8080 or 1-888-332-3234</strong>. You can visit the Board's website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a>.
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N12Info);
