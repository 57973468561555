import React from "react";



const CheckBox = (props) => {
  const { onChange, name, checked, label,extraClass } = props;
  return (
    <div className={extraClass + " d-flex justify-content-start"}>
      <input
        type="checkbox"
        className="checkit"
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        hidden
      />
      <label htmlFor={name} className="checkmark"></label>
      <span className="checkbox-title">{label}</span>
    </div>
  );
};
export default CheckBox;
