import React from "react";
import '../style.scss';
import { Col, Image, Row } from "react-bootstrap";
import { SidePanel } from "../../../components";
import SidePanelAddress from "./SidePanelAddress";
import moment from "moment";

const AdminShowings = (props) => {
    const { isShow, handleClose, showingDetails, requestDetails } = props;
    let title = "";
    let displayStatus = "";
    if (showingDetails.status === "Confirmed") {
        title = "Showing details";
        displayStatus = "Confirmed";
    } else if (showingDetails.status === "Pending") {
        title = "Confirmed Viewing";
        displayStatus = "Pending";
    }
    return (<>
        <SidePanel isShow={isShow} isHide={handleClose} title={title}>
            <div className="sidepanel-body request-showing">
                <Row>
                    <Col md="4" className="pr-0">
                        <Image className="img-responsive" rounded width="108px" height="99px" src={requestDetails && requestDetails.image_url ? requestDetails.image_url : require("../../../../_assets/images/placeholder.jpg")} />
                    </Col>
                    <Col md="7" className="pl-0">
                        <SidePanelAddress details={requestDetails} />
                        <span className={"showing_status_admin " + displayStatus}>{displayStatus}</span>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <div className="admin-sidepanel-card">
                            <h4 className="title-card">Date and Time Options</h4>
                            {requestDetails && requestDetails.viewingRequestDates && requestDetails.viewingRequestDates.map((request, i) => {
                                return <p className="date-time">{displayStatus === 'Pending' && '-'} {moment(request.date).format("LL")} at {moment(request.time, ["HH:mm"]).format("hh:mm a")} - {moment(request.end_time, ["HH:mm"]).format("hh:mm a")}</p>
                            })
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <div className="admin-sidepanel-card">
                            <h4 className="title-card">Landlord</h4>
                            <p className="name-landlord">{requestDetails && requestDetails.landlord && requestDetails.landlord.name}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <div className="admin-sidepanel-card">
                            <h4 className="title-card">Tenant</h4>
                            <p className="name-landlord">{requestDetails && requestDetails.tenant && requestDetails.tenant.name}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </SidePanel>
    </>);
}

export default AdminShowings;