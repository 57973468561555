import React from "react";
import { Formik, Form, Field } from "formik";
import AuthLayout from "../Authentication";
import {
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { postAPI } from '../../../Api';
import { logger, toaster } from "../../../Utils"

const log = logger("ResetPassword");
const ResetPassword = (props) => {
  const history = useHistory();

  const renderInput = ({ type, id, placeholder, invalidAuth }) => {
    return (
      <FormGroup>
        <Field name={id}>
          {({
            field: { name, value, onChange, onBlur },
            form: { touched, errors },
            meta,
          }) => (
              <div style={{ position: 'relative' }}>
                <Input
                  type={type}
                  name={name}
                  id={id}
                  invalid={!!invalidAuth || (!!meta.touched && !!meta.error)}
                  placeholder={placeholder}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />

                {invalidAuth && <FormFeedback>{invalidAuth}</FormFeedback>}
                {meta.touched && meta.error && (
                  <FormFeedback>{meta.error}</FormFeedback>
                )}
              </div>
            )}
        </Field>
      </FormGroup>
    );
  };

  return (
    <AuthLayout style={{ marginTop: '15px' }} title="Change Password" image="change.png" history={history}>
      <p className="authentication-page-form-links">You can now change your old password</p>

      <Formik
        initialValues={{
          input_value: ""
        }}
        onSubmit={async (values) => {
          try {
            const location_state = props.location.state;
            const type = location_state.type === 'email' ? 2 : 1;
            const data = {
              pin: location_state.pin,
              type: type,
              new_password: values.new_password,
              repeat_password: values.repeat_password,
            };

            if (type === 2) {
              data.email = location_state.data
            } else {
              data.phone_number = location_state.data
            }

            await postAPI("/reset-password", data).then(response => {
              if (response.status === 200) {
                toaster('success', response.data.message);
                history.push("/login");
              } else if (response.response) {
                if (response.response.status === 422) {
                  if (response.response.data.errors.new_password) {
                    toaster('error', response.response.data.errors.new_password[0]);
                  } else if (response.response.data.errors.repeat_password) {
                    toaster('error', response.response.data.errors.repeat_password[0]);
                  }
                } else if (response.response.status === 400) {
                  toaster('error', response.response.data.message);
                }
              } else {
                log.info('response error', response)
              }
            })
          } catch (err) {
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              modalText: err.message,
            });
          }
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
        }) => (<>
          <Form onSubmit={handleSubmit} disabled={isSubmitting}>
            <div className='form-wrapper'>
              <Label style={{ display: 'block', textAlign: 'left' }}>New Password</Label>
              {renderInput({
                type: "password",
                id: 'new_password',
                placeholder: "Input Here"
              })}

              <Label style={{ display: 'block', textAlign: 'left' }}>Repeat Password</Label>
              {renderInput({
                type: "password",
                id: 'repeat_password',
                placeholder: "Input Here"
              })}
            </div>

            <Button
              style={{ width: "100%", margin: '40px 0 0' }}
              disabled={isSubmitting}
              type={"submit"}
              className="btn btn-primary text-bold"
            >
              Confirm
              </Button>
            <Button
              style={{ width: "100%" }}
              className="btn text-bold btn-default"
              onClick={() => props.history.push("/forgotpassword")}>Cancel</Button>
          </Form>
        </>)}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPassword;
