import { postAPI } from "../../Api";

export const getResourceData =  async(url, payload) => {
  try {
    const response = await postAPI(url, payload);
    return response.data.data;
  } catch {}
};

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1500 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 991 },
    items: 3,
    partialVisibilityGutter: 26,
  },
  tablet2: {
    breakpoint: { max: 991, min: 579 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 579, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

export const getcardNum=()=>{
    let width = window.screen.width;
        let cardNum = 3;
        let isDesktop = '';
        if (width <= 579) {
            cardNum = 1;
            isDesktop = false;
        } else if (width > 579 && width <= 991) {
            cardNum = 2;
            isDesktop = false;
        } else if (width > 991 && width <= 1200) {
            cardNum = 3;
            isDesktop = false;
        } else if (width > 1200 && width <= 1500) {
            cardNum = 3;
            isDesktop = true;
        } else if (width > 1500 && width <= 4000) {
            cardNum = 3;
            isDesktop = true;
        }
        return{cardNum,isDesktop}
}

export const getQueryParam = (queryString, param) => {
  const parameters = new URLSearchParams(queryString);
  const value = parameters.get(param);
  return value;
}