import { postAPI } from '../../../../Api';

export const requestShowingFromTenant = async ({
  propertyId,
  propertyDetailsId,
  landlordId,
  selectedTime,
  selectedDay,
}) => {
   try {
    const res = await postAPI(
      '/tenant/process-showing-request',

      {
        property_id: propertyId,
        property_details_id: propertyDetailsId,
        landlord_id: landlordId,
        time_of_day:  selectedTime,
        time_of_week:  selectedDay,
      }
    );
     return res.status !== 200 ?  {msg:res.response.data.message} : true
   } catch (err) {
     return 'Something went wrong'
  }
};
