import React, { useEffect, useState } from 'react';
import { SidePanel, FileBrowse, LargeButton, CancelButton } from '../../../components'
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { IMAGE_API_URL } from '../../../../Constants/endPoints'
import Cookies, { set } from 'js-cookie';
// import { ImageUploadAPI } from '../../../../Utils'
import { toaster } from '../../../../Utils'
import { validate } from '../../../../Utils/Validation/validation';
import { BasicInfoValidationSchema } from './ValidationSchema/BasicInfoValidationSchema';
import { postAPI } from '../../../../Api';
import NumberFormat from 'react-number-format';
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import './style.scss';
import { updateProfile } from './helper';

const BasicInfo = (props) => {
  const { basic_info,current_home, isShow, handleClose, setCurrentHomeInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhone_number] = useState('');
  const [front_image, setFront_image] = useState({ file: '', percent: 0 });
  const [back_image, setBack_image] = useState({ file: '', percent: 0 });
  const [isSubmitClicked,setIsSubmitClicked]=useState(false)
  const [updateEmail, setUpdateEmail] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [imageLoader,setImageLoader]=useState({frontImageLoader:false,backImageLoader:false})

  useEffect(() => {
    if (basic_info) {
      console.log("basic_info",current_home);
      setFirstName(basic_info.first_name)
      setLastName(basic_info.last_name)
      setEmail(basic_info.email)
      setPhone_number(basic_info.phone)
      setFront_image({ file: basic_info.front_image })
      setBack_image({ file: basic_info.back_image })
      setPassword('')
      setUpdateEmail(false)
      setNewEmail('')
    }
    setIsSubmitClicked(false)
  }, [basic_info])

  // useEffect(() => {
  //   if (isSubmitClicked) {
  //     validate(BasicInfoValidationSchema)
  //   }
  // }, [firstName, lastName, email, phoneNumber, front_image, back_image])

  const OnChangeInput = (event, setInputState) => {
    if(event.target.name === 'password'){
      setPasswordError(false)
    }
    setInputState(event.target.value)
    setTimeout(() => {
      isSubmitClicked && validate(BasicInfoValidationSchema)
    }, 300)
  }

  const handleDrop = (file, setImage,imgType='') => {
    const url = IMAGE_API_URL;
    const token = `Bearer ${Cookies.get('access_token')}`
    const headers = {
      'Authorization': token,
      'Content-Type': 'multipart/form-data'
    }
    setImageLoader({[imgType]:true})


    file.map((dataFile) => {
      let formdata = new FormData()
      formdata.append('image', dataFile)

      axios.post(
        url, formdata, {
        headers: headers,
        onUploadProgress: progressEvent => {
          var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
          if (percent >= 100) {
            setImage({ percent: percent });
            return file;
          } else {
            setImage({ percent: percent });
            return null;
          }
        }
      })
        .then(response => {
          setImage({ file: response.data.data.url })
          setImageLoader({[imgType]:false})
    
        })
        .catch(error => {
          setImageLoader({[imgType]:false})
        });
      return dataFile;
    })
  }

  const removeImage = (index, setImage) => {
    setImage('')
  }

  const onSubmit = () => {
    setIsSubmitClicked(true)
    if (validate(BasicInfoValidationSchema)) {
      let url = '';
      if (user_id) {
        url = '/admin/users/tenant/basic-info/' + user_id;
      } else {
        url = '/tenant/profile/basic-info';
      }
      const data = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumberSanitizer(phoneNumber),
        front_image: front_image.file,
        back_image: back_image.file,
        email: updateEmail ? newEmail : email,
        password: password,
      }
      // !isSubmitClicked && postData(url, data)
      postData(url, data)
    }
  }

  const postData = (url, data) => {
    updateProfile(url, data)
    .then(response => {
      console.log(response.status)
      if (response === undefined || (response.response && response.response.status === 422)) {
        let errorList = []
        let resErrorData = response.response.data.errors
        for(let error in resErrorData){
          errorList.push(resErrorData[error])
        }
        response.response ? errorList.map(error => error.map(err => toaster('error', err)))  : toaster('error', 'Something went wrong!');
      }else if (response.status === 200) {
        handleClose()
        if (role !== 1) {
          if(!current_home?.id){
            setCurrentHomeInfo(true)
          }
        }
        toaster('success', response.data.message);
      }else if (response.response.status === 400) {
        setPasswordError(true)
      }
    })
    .catch(error => {
      console.log(error)
    })
  }
  const {frontImageLoader,backImageLoader}=imageLoader
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Let’s Get To Know You A Bit' : 'Basic Information'}>

      <div className='sidepanel-body'>
        <Form id='Form'>
          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Your First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='FIrst name'
                name='firstName'
                id='firstName'
                value={firstName}
                onChange={(e) => OnChangeInput(e, setFirstName)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Your Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Last name'
                name='lastName'
                id='lastName'
                value={lastName}
                onChange={(e) => OnChangeInput(e, setLastName)}
              />
            </Form.Group>
          </Row>
          { !updateEmail && <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Email</Form.Label>
              <Form.Control
                type='email'
                placeholder={'name@mail.com'}
                name={'email'}
                id='email'
                value={email}
                onChange={(e) => OnChangeInput(e, setEmail)}
                disabled
              />
              {!updateEmail && <p className='update-email-text' onClick={() => setUpdateEmail(true)} >{role === 1 ? 'Update email' : 'Update my email'}</p> }
            </Form.Group>
          </Row>}
          { updateEmail && <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Email</Form.Label>
              <Form.Control
                type='email'
                placeholder={role === 1 ? 'Enter new email' : 'Enter your new email'}
                name={'new_email'}
                id='new_email'
                value={newEmail}
                onChange={(e) => OnChangeInput(e, setNewEmail)}
              />
              {/* {!updateEmail && <p className='update-email-text' onClick={() => setUpdateEmail(true)} >Update my email</p> } */}
              {updateEmail && role ===1 && <p className='update-email-text' onClick={() => setUpdateEmail(false)}>Cancel</p>}
            </Form.Group>
          </Row>}
          { updateEmail && role !== 1 &&
            <Row>
              <Form.Group as={Col} md='12'>
                <Form.Label className="custom-lable">Enter Your Password To Update Your Email</Form.Label>
                <Form.Control
                  style={{ background: passwordError && '#FBDFDF' }}
                  type='password'
                  placeholder={'Enter your password'}
                  name={'password'}
                  id='password'
                  onChange={(e) => OnChangeInput(e, setPassword)}
                  disabled={!updateEmail}
                  autoComplete={false}
                />
                <div className='d-flex flex-row justify-content-between'>
                  <p className='update-email-error' style={{ visibility: passwordError ? 'visible' : 'hidden' }}>Wrong password, try again</p>
                  <p className='update-email-text' onClick={() => setUpdateEmail(false)}>Cancel</p>
                </div>
              </Form.Group>
            </Row>
          }
          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Phone</Form.Label>
              <NumberFormat
                format='###-###-####'
                mask='_'
                placeholder='123-123-1234'
                name='phoneNumber'
                id={'phoneNumber'}
                value={phoneNumber}
                className='form-control'
                onChange={(e) => OnChangeInput(e, setPhone_number)}
              />
            </Form.Group>
          </Row>

          <Row style={{ borderTop: '1px solid #EEEEEF', marginTop: '10px' }}>
            <Col md='12'>
              <header>
                <h3>Proof of Identification</h3>
              </header>
              <Form.Label className='pt-2 custom-lable'>Front of ID (Driver's License, Passport Etc.)</Form.Label>
              <FileBrowse
                handleDrop={(e) => handleDrop(e, setFront_image,'frontImageLoader')}
                size={'sm'}
                title={'Drag to upload'}
                percent={front_image.percent}
                multiple={false}
                fileType={'.jpg,.png,.jpeg'}
                disable={false}
                showImageLoading={frontImageLoader}
                imageData={front_image.file && front_image.file}
                removeImage={(e) => removeImage(e, setFront_image)}
                lableClass={'custom-lable'}
              />
            </Col>
            <Col md='12'>
              <Form.Label className='pt-4 custom-lable'>Back of ID (Driver's License, Passport Etc.)</Form.Label>
              <FileBrowse
                handleDrop={(e) => handleDrop(e, setBack_image,'backImageLoader')}
                size={'sm'}
                title={'Drag to upload'}
                percent={back_image.percent}
                multiple={false}
                fileType={'.jpg,.png,.jpeg'}
                disable={false}
                showImageLoading={backImageLoader}
                imageData={back_image.file && back_image.file}
                removeImage={(e) => removeImage(e, setBack_image)}
                lableClass={'custom-lable'}
              />
            </Col>
          </Row>
        </Form>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default BasicInfo;