import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LargeButton, CancelButton, SidePanel, FileBrowse } from '../../../components';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../../../Utils';
import { fileUploadApi, leaseSubmit } from '../CreateLeaseSteps/helper';

const UploadLeaseDocument = (props) => {
    const { isShow, handleClose, setOpenLeaseDuration, setLeaseId } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [lease_pdf, setLease_pdf] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [showImageLoading,setShowImageLoading]=useState(false)

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props]);

    const handleDrop = async (file, setImage) => {
        
        const dataFile = file[0];
        setShowImageLoading(true)
        setUploadData(dataFile);
        let formdata = new FormData();
        formdata.append('file', dataFile);

        const {response, error} = await fileUploadApi(formdata, {setImage}, 'completeLease')
        
        if(error){
            setShowImageLoading(false)
        }else{
            setImage({ file: response })
            setErrorClass(false)
            setShowImageLoading(false)
        }
    }

    const removeImage = (index, setImage) => {
        setImage('')
    }

    const submit = async () => {
        if (lease_pdf.file === '') {
            setErrorClass(true)
            return;
        }

        const data = {
            'lease_id': 0,
            'property_id': property_id,
            'property_detail_id': property_details_id,
            "lease_pdf": lease_pdf.file
        }

        const res = await leaseSubmit(data, 'upload');
        
        if (res.status === 200) {
            handleClose();
            const leaseId = res.data.id;
            setLeaseId(leaseId);
            setOpenLeaseDuration(true);
            toaster('success', 'lease uploaded successfully')
        } else if (res.status === 422) {
            toaster('error', 'The lease pdf field is required.');
            setErrorClass(true)
        }else{
            toaster('error', res.message);
            setErrorClass(true)
        }
        
    }
        
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title='UPLOAD LEASE'
            subTitle='please provide a few key details'
        >
            <div className='sidepanel-body mt-5'>
                <Row>
                    <Col md="12">
                        <FileBrowse
                            handleDrop={(e) => handleDrop(e, setLease_pdf)}
                            size={'sm'}
                            title={'Upload Documents'}
                            percent={lease_pdf.percent}
                            multiple={false}
                            fileType={'.jpg,.png,.jpeg,.pdf'}
                            disable={false}
                            showImageLoading={showImageLoading}
                            imageData={lease_pdf.file && lease_pdf.file}
                            removeImage={(e) => removeImage(e, setLease_pdf)}
                            lableClass={'custom-lable'}
                            section={'upload-lease'}
                            uploadData={uploadData && uploadData}
                            errorClass={errorClass === true ? "error-upload-box" : ""}
                        />
                    </Col>
                </Row>
            </div>
            <div className='fixed-bottom d-flex justify-content-between'>
                <CancelButton onClick={() => handleClose('callBackApi')} title='Back' />
                <LargeButton title='next' onClick={() => submit()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(UploadLeaseDocument);