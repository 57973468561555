import React from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap';
import { SingleOptionCheck, LargeButton } from '../../../';
import { rentDeposite } from '../../../../../Constants/LeaseConstants';

const AddTenantDetails = props => {
    const {leaseData, isSaveEnable, setSaveState, selectedTenantData, OnRemove, addtenant, handleClickInside, isPopoverOpen, OnSingleOptionCheck, required_tenant_insurance} = props;

    return (
        <div className='inner-wrapper'>
            <div className='body-container'>
                <div className='body-header'>
                    <h3 className='text-left lease-heading'>tenant details</h3>
                    {leaseData && leaseData.is_sent_to_tenant ?
                        <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
                        : <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
                </div>

                <Row>
                    <Form.Group as={Col} md='12'>
                        <Form.Label>Your tenant(s)</Form.Label>
                        <ul className='select-options'>
                            {selectedTenantData && selectedTenantData.map((tenant, i) => {
                                return (<li className="active">{tenant.name}
                                    <Image
                                        className="icon cursorPointer ml-3"
                                        width="14px"
                                        height="15px"
                                        title="Remove"
                                        onClick={() => OnRemove(i, tenant.tenant_id)}
                                        src={require("../../../../../_assets/images/delete.svg")}
                                    />
                                </li>)
                            })}
                        </ul>
                        <LargeButton isIcon={true} title='Add Tenant Info' variant='dark' onClick={() => addtenant()} style={{ display: 'block', maxWidth: "300px", height: "48px", padding: "0px" }} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md='7'>
                        <Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
                        <span className='warning-popover'>
                            <img className='cursorPointer ml-2 mb-1'
                                onMouseEnter={() => handleClickInside('yes')}
                                onMouseLeave={() => handleClickInside('no')}
                                src={require('../../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
                            {(isPopoverOpen === true) && <div className="custom-popover extra">
                                <p className='popover-content' style={{ height: 'auto', minHeight: 'auto' }}>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute.</p>
                            </div>}
                        </span>
                        <SingleOptionCheck
                            mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'required_tenant_insurance'} setParam={required_tenant_insurance}
                        />
                    </Form.Group>
                </Row>
            </div>
        </div>
    )
}

export default AddTenantDetails

