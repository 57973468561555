import React from "react";
import { useState } from 'react';
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import AppLayout from "../AppLayout";
import "./style.scss";
import { useHistory } from 'react-router-dom';
import PageTitleHeader from "../PageTitleHeader";
import { Button } from "react-bootstrap";
import { ModalBox } from "../../components";




const Profile = (props) => {
  const history = useHistory();
 
  const [show, setShow] = useState(false);
  const deleteClose = () => setShow(false);
  const deleteShow = () => setShow(true);


  const backToDashboard = () => {
    history.push('/dashboard');
  }
  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title='my profile'

            isArrow={true}
            onClick={() => backToDashboard()}
          />

          <div className="profile-container">
            <div className="profile-container-box">
              <div className="inner-wrapper">
                <div className="profile-container-header">
                  <Row>
                    <Col sm={6} style={{display: "flex"}}>
                      <div
                        className="profile-container-header-name">
                          {props.editProfileList.data &&
                          Object.values(props.editProfileList.data.name).join("").toString()} {""}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div>
                        <Button onClick={deleteShow} className="btn-primary" id="delete-btn" data-description="delete button" gtmdetails="Tab 1"> delete user </Button> 
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="profile-container-header-email">
                          Landlord - {" "}
                          {props.editProfileList.data &&
                          Object.values(props.editProfileList.data.email).join("").toString()} {"-"} {props.editProfileList.data &&
                          Object(props.editProfileList.data.phone).toString()}
                      </div>
                    </Col>
                  </Row>
                </div>
              
                <Row className='profile-option-container'>
                  <Col className="profile-option-box" style={{ minHeight: '170px' }} onClick={() => history.push('/editprofile')}>
                    <img
                      width="25.5"
                      height="25.5"
                      left="12.5%"
                      right="12.5%"
                      top="12.5%"
                      bottom="12.5%"
                      alt="Group"
                      src={require("../../../_assets/images/check-with-box-active-icon.svg")}
                      className="d-inline-block align-top"
                    />
                    <span
                      className="profile-option-box-title-typography"
                    >
                      Edit Profile
                      </span>
                    <span
                      className="et-path-arrow-icon"
                    >
                      <img
                        width="11"
                        height="18"
                        alt="Path"
                        src={require("../../../_assets/images/path.svg")}
                        className="d-inline-block align-top"
                      />
                    </span>
                  </Col>

                  <Col className="profile-option-box" style={{ minHeight: '170px' }} onClick={() => history.push('/changepassword')}>
                    <img
                      width="25.5"
                      height="25.5"
                      left="12.5%"
                      right="12.5%"
                      top="12.5%"
                      bottom="12.5%"
                      alt="reset"
                      src={require("../../../_assets/images/setting-active-icon.svg")}
                      className="d-inline-block align-top"
                    />
                    <span
                      className="profile-option-box-title-typography"
                    >
                      Reset Password
                      </span>
                    <span
                      className="et-path-arrow-icon"
                    >
                      <img
                        width="11"
                        height="18"
                        alt="Path"
                        src={require("../../../_assets/images/path.svg")}
                        className="d-inline-block align-top"
                      />
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
        show={show}
        onHide={deleteClose}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
        setShow(false)
        }}
        >
                        <p>If you’d like your account and information deleted, please send an email to <strong>hello@rentpanda.ca</strong> with the subject line <i>“Delete My Account”</i> and your account will be deleted within 48 hours.</p>
        </ModalBox> 

      </AppLayout>
    </>
  );
}

const select = (state) => ({
  editProfileList: state.editProfile.editProfileList,

});
export default connect(select)(Profile);
