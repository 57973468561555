export const ForRealtorsSchema = {
    first_name: [
        { rule: "required", message: "First name required" }
    ],
    last_name: [
        { rule: "required", message: "Last name required" },
    ],
    email: [
        { rule: "required", message: "Email required" },
        { rule: "email", message: "The email must be a valid email address." },
    ],
    phone_number: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number is not valid" }
    ],

    // for owner
    owner_first_name: [
        { rule: "required", message: "First name required" }
    ],
    owner_last_name: [
        { rule: "required", message: "Last name required" },
    ],
    owner_email: [
        { rule: "required", message: "Email required" },
        { rule: "email", message: "The email must be a valid email address." },
    ],
    owner_phone_number: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number is not valid" }
    ],
}
