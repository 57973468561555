export const ProfessionalPhotoshootSchema = {
    date: [
        { rule: "required", message: "Date required" },
    ],
    appointment_time: [
        { rule: "required", message: "Appointment time required" },
    ],
    phone_number: [
        { rule: "required", message: "Phone number time required" },
    ],
}