import React from "react";
import ContentLoader from 'react-content-loader';

const MessageTenantListLoader = (props) => {
    const { grid, type, loop = 4 } = props;
    return (
        <>
            {(() => {
                const options = [];
                for (let i = 0; i < loop; i++) {
                    options.push(<React.Fragment key={i}><RowList grid={grid} type={type} /></React.Fragment>);
                }
                return options;
            })()}
        </>
    )
}
export default MessageTenantListLoader;


const RowList = (props) => {
    return (
        <div className="message-list-card">
            <div className={"verified status"}>
                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="230" y="0" rx="0" ry="0" width="20%" height="15" />
                </ContentLoader>
            </div>
            <h4 className="tenant-name">
                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="50%" height="15" />
                </ContentLoader>
            </h4>
            <p className="tenant-contact-date">
                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="70%" height="15" />
                </ContentLoader>
            </p>
            <div className="list-inline">
                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="80%" height="15" />
                </ContentLoader>
            </div>
        </div>
    )
}