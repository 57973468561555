

import React from "react";
import logger from '../../../Utils/logger';

const log = logger("SingleOptionCheck");

const SingleOptionCheck = (props) => {
    const { mapData, OnSingleOptionCheck, name, setParam, extra, extraClass, isInvalid = false, errMessage = '' } = props;
    log.info("setParam", mapData, setParam);
    return (
        <>
            <ul className={extraClass ? "select-options " + extraClass : (isInvalid ? "select-options is-invalid" : "select-options")}>
                {mapData.map((data) =>
                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={name + data.value} value={data.value} name={name} id={name}
                        className={(setParam === data.value) ? "active" : ""}>{data.title}</li>)}
                {extra && extra}
            </ul>
            {isInvalid && <div id={"error_" + name} className="invalid-feedback">{errMessage}</div>}
        </>
    );
}
export default SingleOptionCheck;