export const Numbering = (i) => {
    switch (i){
        case 1:
          return i+'st';
        case 2:
          return i+'nd';
        case 3:
          return i+'rd';
        default:
          return i+'th'
      }
}