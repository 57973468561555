import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { Nav } from "react-bootstrap";
import moment from "moment";
import { AppLayout, ModalBox } from "../../components";
import "./style.scss";
import ActiveCoupon from "./ActiveCoupon";
import { logger } from "../../../Utils";
import CreateCoupon from "./CreateCoupon";
import ViewCoupon from "./ViewCoupon";
import { getAPI, postAPI } from "../../../Api";
import InActiveCoupon from "./InActiveCoupon";
import { setCouponComponent, setCouponFilters } from "../../../Store/actions/Action";

const log = logger("CouponManagement");
const CouponManagement = (props) => {
  const [couponType, setcouponType] = useState("active");
  const [showCreateCoupon, setShowCreateCoupon] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [deletedModalShow, setDeletedModalShow] = useState(false);
  const [deactivatedModalShow, setDeactivatedModalShow] = useState(false);
  const [activatedModalShow, setActivatedModalShow] = useState(false);
  const [expiredModalShow, setExpiredModalShow] = useState(false);
  const [showViewCoupon, setShowViewCoupon] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [coupon_code, setCoupon_code] = useState("");
  const [reload, setReload] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [modalType, setModalType] = useState("");
  const [editCoupon, setEditCoupon] = useState(null);
  const slug = props.match.params.slug;
  const dispatch = useDispatch()
  const currentComponent = useSelector(state => state.CouponReducer.currentComponent)
  const filters = useSelector(state => state.CouponReducer.filters)

  useEffect(() => {
    if (slug) {
      setcouponType(slug);
      dispatch(setCouponComponent(slug))
      if(currentComponent !== slug){
        dispatch(setCouponFilters({
          coupon_name: '',
          created_date: 'desc',
          no_of_usage: '',
          total_no_of_usage: '',
          start_date: '',
          expiry_date: '',
          coupon_code: '',
          coupon_value: '',
        }))
      }
    }
  }, [slug, dispatch, currentComponent]);

  const openCreateCoupon = () => {
    setShowCreateCoupon(true);
    setEditCoupon(null);
    getAPI("/admin/coupons/get-unique-coupon").then((response) => {
      setCoupon_code(response.data.data);
    });
  };

  const viewCoupon = (data) => {
    setCouponData(data);
    setShowViewCoupon(true);
  };

  const deleteCoupon = (id, confirm) => {
    setShowViewCoupon(false);
    setDeleteId(id);
    setDeletePrompt(true);
    setReload(false);
    setModalType("delete");
    if (confirm === "yes") {
      const url = "/admin/coupons/delete";
      const data = {
        id: id,
      };
      postAPI(url, data).then((res) => {
        setReload(true);
        setDeletePrompt(false);
        setDeletedModalShow(true);
      });
    }
  };

  const deactivateCoupon = (id, confirm) => {
    setShowViewCoupon(false);
    setDeleteId(id);
    setDeletePrompt(true);
    setReload(false);
    setModalType("deactivate");
    if (confirm === "yes") {
      const url = "/admin/coupons/change-status";
      const data = {
        id: id,
        status: 0,
      };

      postAPI(url, data).then((res) => {
        setReload(true);
        setDeletePrompt(false);
        setDeactivatedModalShow(true);
      });
    }
  };

  const activateCoupon = (cData, confirm) => {
    log.info("coupon expired? ", moment().toDate() > moment(cData.expiry_date).toDate())
    setShowViewCoupon(false);
    if (moment().toDate() > moment(cData.expiry_date).toDate()) {
      setExpiredModalShow(true);
    } else {
      setDeleteId(cData.id);
      setDeletePrompt(true);
      setModalType("activate");
    }
    setReload(false);
    if (confirm === "yes") {
      const url = "/admin/coupons/change-status";
      const data = {
        id: cData,
        status: 1,
      };

      postAPI(url, data).then((res) => {
        if (res.data && res.data.status_code === 200) {
          setReload(true);
          setDeletePrompt(false);
          setActivatedModalShow(true);
        }        
        if (res.response && res.response.status === 400) {
          setExpiredModalShow(true)          
        }
      });
    }else{
      setEditCoupon(cData);
    }
  };

  const editCouponHandler = (data) => {
    setEditCoupon(data);
    setShowViewCoupon(false);
    setShowCreateCoupon(true);    
  };

  return (
    <>
      <AppLayout>
        <div className="couponManagement">
          <Nav
            fill
            variant="tabs"
            className="user-tab-views tab-space-hover pt-2"
          >
            <NavLink
              to={{ pathname: "/coupon-management/active" }}
              className={"nav-link"}
              activeclassname={"active"}
            >
              active
            </NavLink>
            <NavLink
              to={{ pathname: "/coupon-management/inactive" }}
              className={"nav-link"}
              activeclassname={"active"}
            >
              inactive
            </NavLink>
          </Nav>

          <div className="table-container">
            <div className="d-md-flex justify-content-between mb-4 mb-md-0">
              <h2>COUPON MANAGEMENT</h2>
              <button onClick={openCreateCoupon} className="create-coupon-btn">
                <img src={require("../../../_assets/images/plus-white.svg")} alt="" />
                Create New Coupon
              </button>
            </div>
            {couponType === "active" && (
              <ActiveCoupon
                type={couponType}
                viewCoupon={(data) => viewCoupon(data)}
                reload={reload}
                deleteCoupon={(id) => deleteCoupon(id)}
              />
            )}
            {couponType === "inactive" && (
              <InActiveCoupon
                type={couponType}
                viewCoupon={(data) => viewCoupon(data)}
                reload={reload}
                deleteCoupon={(id) => deleteCoupon(id)}
              />
            )}
          </div>
        </div>
      </AppLayout>
      <CreateCoupon
        isShow={showCreateCoupon}
        handleClose={() => {
          setShowCreateCoupon(false);
          setEditCoupon(null);
        }}
        type={"edit"}
        setSuccessModalShow={() => setSuccessModalShow(true)}
        setUpdateModalShow={() => setUpdateModalShow(true)}
        setReload={(data) => setReload(data)}
        coupon_code={coupon_code}
        editCoupon={editCoupon}
      />
      <ViewCoupon
        isShow={showViewCoupon}
        handleClose={() => setShowViewCoupon(false)}
        couponData={couponData}
        deleteCoupon={(id) => deleteCoupon(id)}
        deactivateCoupon={(id) => deactivateCoupon(id)}
        activateCoupon={(data) => activateCoupon(data)}
        editCoupon={(data) => editCouponHandler(data)}
        couponType={couponType}
      />
      <ModalBox
        show={successModalShow}
        onHide={() => {
          setSuccessModalShow(false);
        }}
        size="sm"
        actionbuttontitle={"got it"}
        buttonaction={() => setSuccessModalShow(false)}
      >
        <p className="coupon-success-text">
          coupon created
          <br /> successfully
        </p>
      </ModalBox>
      <ModalBox
        show={updateModalShow}
        onHide={() => {
          setUpdateModalShow(false);
        }}
        size="sm"
        actionbuttontitle={"got it"}
        buttonaction={() => setUpdateModalShow(false)}
      >
        <p className="coupon-success-text">Coupon updated successfully</p>
      </ModalBox>
      <ModalBox
        show={deletedModalShow}
        onHide={() => {
          setDeletedModalShow(false);
        }}
        size="sm"
        actionbuttontitle={"got it"}
        buttonaction={() => setDeletedModalShow(false)}
      >
        <p className="coupon-success-text">
          coupon deleted
          <br /> successfully
        </p>
      </ModalBox>
      <ModalBox
        show={deactivatedModalShow}
        onHide={() => {
          setDeactivatedModalShow(false);
        }}
        size="sm"
        actionbuttontitle={"got it"}
        buttonaction={() => setDeactivatedModalShow(false)}
      >
        <p className="coupon-success-text">
          coupon is deactivated
          <br /> successfully
        </p>
      </ModalBox>
      <ModalBox
        show={activatedModalShow}
        onHide={() => {
          setActivatedModalShow(false);
        }}
        size="sm"
        actionbuttontitle={"got it"}
        buttonaction={() => setActivatedModalShow(false)}
      >
        <p className="coupon-success-text">
          coupon activated
          <br /> successfully
        </p>
      </ModalBox>
      <ModalBox
        show={deletePrompt}
        onHide={() => setDeletePrompt(false)}
        size="sm"
        type="condition"
        actionbuttontitle={"Yes"}
        buttonaction={() => {
          setDeletePrompt(false);
          if (modalType === "delete") {
            deleteCoupon(deleteId, "yes");
          } else if (modalType === "deactivate") {
            deactivateCoupon(deleteId, "yes");
          } else if (modalType === "activate") {
            activateCoupon(deleteId, "yes");
          }
        }}
        backbuttontitle={"Not now"}
        backaction={() => {
          setDeletePrompt(false);
        }}
      >
        <p className="modal-confirmation-text-coupon">
          Are you sure you want to <br />
          {modalType} this coupon?
        </p>
      </ModalBox>
      <ModalBox
        show={expiredModalShow}
        onHide={() => setExpiredModalShow(false)}
        size="sm"
        type="condition"
        actionbuttontitle={"Yes"}
        buttonaction={() => {
          setExpiredModalShow(false);
          setShowViewCoupon(false);
          setShowCreateCoupon(true); 
          
        }}
        backbuttontitle={"Not now"}
        backaction={() => {
          setExpiredModalShow(false);
        }}
      >
        <p className="modal-confirmation-text-coupon">
          Coupon is expired, you have to edit the coupon details to activate.
        </p>
      </ModalBox>
    </>
  );
};

export default withRouter(CouponManagement);
