import React from "react"
import "./RentRates.scss"
import "../../ReportSections/ReportSection.scss"


const RentRates = ({data, chartData, name}) => {
  // This loops through the first 10 because if say the first 3 object are all missing a given property but
  // the 4th has it, and we only loop through the first col, the table won't get the correct column headers.
  const getTableKeys = () => {
    const tableKeys = []
    for (let i = 0; i < 10; i++) {
      for (const key in chartData[i]) {
        if (!tableKeys.includes(key)) {
          tableKeys.push(key)
        }
      }
    }
    return tableKeys
  }
  const myKeys = getTableKeys()

  const PercentageBar = ({value, altColor = false}) => {
    const cleanNumbers = (num) => {
      if (!num || num === "0%" || num === "0.0%" || num === 0 || num === "%") {
        return 0
      } else {
        return parseFloat(num.replace(/[^0-9.-]/g, '')) || null
      }
    }

    const myCleanedData = chartData.map((item) => (cleanNumbers(item[myKeys[4]])))
    const maxWidth = Math.max(...myCleanedData)
    const maxPercent = Math.ceil(maxWidth / 10) * 10 + 10

    const onlyInt = cleanNumbers(value)
    const myWidth = ((onlyInt / maxPercent) * 100).toPrecision(2)

    const determineBlockSide = (value) => {
      if (value > 0) {
        return {marginLeft: "50%"}
      } else if (value < 0) {
        return {marginRight: "50%"}
      } else return null
    }

    const color1 = !altColor ? "#D1E9B5" : "#FFA674"

    const style = {
      // width: `${Math.abs(myWidth) * 1.2}%`,
      width: "100%",
      backgroundColor: onlyInt > 0 ? color1 : "#ED7474",
      // ...determineBlockSide(onlyInt)
    }

    const getPosNegClass = (value) => {
      if (value > 0) {
        return "pos"
      } else if (value < 0) {
        return "neg"
      } else return null
    }

    return (
      <div className="percentage-bar">
        {!onlyInt && ( <span className={"centerText"}>
               { onlyInt === 0 ? "0.0%" : "N/A"}
              </span>
        )}
        <div className={`percentage-bar__bar ${getPosNegClass(onlyInt)}`} style={style}>
          { onlyInt !== 0 && onlyInt !== null && (
              <span>
                {onlyInt}%
              </span>
            )
          }
        </div>
      </div>
    )
  }

  const RateTable = () => {

    return (
      <div className="tableContainer">
        <table className={"rentRateTable"}>
          <thead>
          <tr>
            { myKeys.map((key) => {
              return <th key={key}>{key}</th>
            })
            }
          </tr>
          </thead>

          <tbody>
          { chartData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.City}</td>
                <td>{item[myKeys[1]]}</td>
                <td>{item[myKeys[2]]}</td>
                <td>{item[myKeys[3]]}</td>
                {item[myKeys[4]] ? ( <td><PercentageBar value={item[myKeys[4]]} /></td>) : <td>N/A</td>}
                {item[myKeys[5]] ? ( <td><PercentageBar value={item[myKeys[5]]} /></td>) : <td>N/A</td>}
              </tr>
            )})
          }
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div className={"reportSection"} id={name} key={name}>
      <h3 className={"chapterHeader"}>{name}</h3>
      <div className={"sectionContent"}>
        <RateTable />
        { data.data.appendixText && (
          <p className={"appendixText"}>{data.data.appendixText}</p>
        )}

      </div>
    </div>
  )
}

export default RentRates