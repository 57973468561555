import "./ReportStyle.scss"
import React, { useState, useEffect } from "react";
import chevron_left from "../../../_assets/images/reportImages/chevron-left.svg"
import greenHouse from "../../../_assets/images/reportImages/greenHouse.png"
import blueHouse from "../../../_assets/images/reportImages/blueHouse.png"
import purpleHouse from "../../../_assets/images/reportImages/purpleHouse.png"
import orangeHouse from "../../../_assets/images/reportImages/orangeHouse.png"
import { generateReport } from "../GenerateReportUtil"
import ReportSection from "../ReportSections/ReportSection"
import ExecutiveSummary from "../ReportSections/ExecutiveSummary/ExecutiveSummary"
import AffordabilityTable from "../Charts/AffordabilityTable/AffordabilityTable"
import PredictionComparison from "../Charts/PredictionComparison/PredictionComparison"
import {DOMSChart} from "../Charts/DOMSChart/DOMSChart"
import {Link, useParams} from "react-router-dom"
import rpLogo from "../../../_assets/images/reportImages/rpLogo.png"
import x from "../../../_assets/images/reportImages/x.png"
import DILogo from "../../../_assets/images/reportImages/doorInsightLogo.png"
import fbLogo from "../../../_assets/images/reportImages/socialIcons/fbSocial.png"
import instagramLogo from "../../../_assets/images/reportImages/socialIcons/igLogo.png"
import LinkedInLogo from "../../../_assets/images/reportImages/socialIcons/linkedinlogo.png"
import emailIcon from "../../../_assets/images/reportImages/socialIcons/emailLogo.png"
import copyLinkIcon from "../../../_assets/images/reportImages/socialIcons/copyLinkLogo.png"
import RentRates from "../Charts/RentRates/RentRates"
import RentSqFt from "../Charts/RentSqFt/RentSqFt"
import RChart from "../Charts/RChart/RChart"
import {Helmet} from "react-helmet";
import {HeaderLandingPage} from "../../components";
import { fetchGoogleSheetsData } from 'google-sheets-mapper';
import axios from "axios";



const Report = () => {
  const [newReportData, setNewReportData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState(undefined)
  const [reportUrl, setReportUrl] = useState(undefined)
  const [reportMonth, setReportMonth] = useState(undefined)
  const [reportYear, setReportYear] = useState(undefined)
  
  const { slug } = useParams();

  const monthName = (month) => {
    switch (month) {
      case 1:
        return "January"
      case 2:
        return "February"
      case 3:
        return "March"
      case 4:
        return "April"
      case 5:
        return "May"
      case 6:
        return "June"
      case 7:
        return "July"
      case 8:
        return "August"
      case 9:
        return "September"
      case 10:
        return "October"
      case 11:
        return "November"
      case 12:
        return "December"
      default:
        return "Invalid month"
    }
  }

  const getSheet = async (sheetId) => {
    try {
      return await fetchGoogleSheetsData({
        apiKey: process.env.REACT_APP_GOOGLE_SHEETS_API_KEY,
        sheetId: sheetId,
      });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    const getReports = async () => {
      const reportData = await axios.get(`https://rentpanda.ca/api/cms/rental-report?slug=${slug}`)
          .then((res) => {
            const sheet = res.data.rental_report.excel_url
            const report_month = res.data.rental_report.report_month
            const report_year = res.data.rental_report.report_year
            setReportMonth(report_month)
            setReportYear(report_year)

            getSheet(sheet).then((data) => {
              setChartData(data)
              if (data.length > 0) {
                const generatedReport = generateReport(data[0].data[0])
                setNewReportData(generatedReport)
                setIsLoading(false)
              }
            })
          })
    }
    getReports()
  }, [])


  const locateCorrespondingData = (id) => {
    return chartData.find((item) => {
      if (item.id === id) {
        return item
      } else return null
    })
  }

  const handleRenderChartType = (chartType, data, section, key) => {
    switch (chartType) {
      default:
        return null
      case "domsChart":
        return <DOMSChart data={section} chartData={locateCorrespondingData(section.data.dataLocation).data} key={key} name={section.title} />
      case "rentRates":
        return <RentRates data={section} chartData={locateCorrespondingData(section.data.dataLocation).data} key={key} name={section.title} />
      case "rentSqFt":
        return <RentSqFt data={section} chartData={locateCorrespondingData(section.data.dataLocation).data} key={key} name={section.title} />
      case "robsChart":
        return <RChart data={section} chartData={locateCorrespondingData(section.data.dataLocation).data} key={key} name={section.title} />
      case "yearRecap":
        return <RChart isYearlyRecap={true} data={section} chartData={locateCorrespondingData(section.data.dataLocation).data} key={key} name={section.title} />
    }
  }

  const handleSectionTypes = (section, key) => {
    console.log('section type', section.data.contentType);
  
    switch (section.data.contentType) {
      default:
        return <ReportSection data={section} key={key} name={section.title} />
      case "executiveSummary":
        return <ExecutiveSummary data={section} key={key} name={section.title} />
      case "predictionComparison":
          const predictionLocation = locateCorrespondingData(section.data.dataLocation)
        return <PredictionComparison data={section} chartData={predictionLocation} key={key} name={section.title} />
      case "tableHeatMap":
          const heatLocation = locateCorrespondingData(section.data.dataLocation)
        return <AffordabilityTable data={section} chartData={heatLocation} key={key} name={section.title} />
      case "chart":
        return handleRenderChartType(section.data.chartType, section.data, section, key)
      case "mixed":
        return <h2>hello</h2>
    }
  }

  const handleDoorInsight = () => {
    window.open("https://doorinsight.com/")
  }
  const handleRentPanda = () => {
    window.open("https://rentpanda.ca/")
  }
  const handleFB = () => {
    window.open("https://www.facebook.com/rentpanda/")
  }

  const handleIG = () => {
    window.open("https://www.instagram.com/rentpanda/")
  }

  const handleLinkedIn = () => {
    window.open("https://www.linkedin.com/company/rent-panda/")
  }

  const handleEmail = () => {
    window.open("mailto:hello@rentpanda.ca")
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  const handlePrintPage = () => {
    window.print()
  }

  const handleChapterNav = (chapter) => {
    const element = document.getElementById(chapter)
    const headerOffset = 55
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
  }

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // TODO: LINE:235 Matt: The "headline" property is set to Jan rental report, but this is actually the december rental report.
  // if you could update it in the prod. db that would be great, thank you!
  // TODO: Title for this page throws error if set to newReportData.headline, because it is undefined on first render.
  // unclear of solution

  return (
    <div className={"reportPage"}>
      <React.Fragment>
        <Helmet>
          <title>Rental Report</title>
          <meta name="description"
                content="Discover our Rent Panda x Door Insight rent report for November 2022 outlining the national rental market in Canada."
          />
        </Helmet>
      </React.Fragment>
      <HeaderLandingPage />
      { !isLoading && newReportData && chartData && (
        <div className={"reportContainer"}>

          <div className="reportHeader">
            <Link to={"/rental-reports"}>
              <div className={"goBackCont"}>
                <img src={chevron_left} alt={""} />
                <h2>back to rental report overview</h2>
              </div>
            </Link>

            <div className="heroContainer">
              <div className={"rpxCont"}>
                <img src={rpLogo} alt="rent panda x door insight" className={"rpXdi"}
                     onClick={handleRentPanda}
                />
                <img src={x} alt="rent panda x door insight" className={"xLogo"}/>
                <img src={DILogo} alt="rent panda x door insight" className={"rpXdi"}
                     onClick={handleDoorInsight}
                />
              </div>
              <div className="heroHeader">
                <h1>{newReportData.headline}</h1>
                <p className="heroSubText">
                  {/* TODO: add field in for sub header*/}
                  Data is reflective of {monthName(reportMonth)} {reportYear} rental listings across Canada.
                </p>
              </div>

              <div className="houseBannerContainer">
                <div className="houseBanner">
                  <img src={greenHouse} alt="house outline artwork" className={"houseBannerImg"} />
                  <img src={blueHouse} alt="house outline artwork" className={"houseBannerImg"} />
                  <img src={purpleHouse} alt="house outline artwork" className={"houseBannerImg"} />
                  <img src={orangeHouse} alt="house outline artwork" className={"houseBannerImg"} />
                </div>
              </div>

              <div className="bottomHero">
                <div className="socialContainer">
                  <img src={fbLogo} alt="facebook"
                       onClick={handleFB}
                  />
                  <img src={instagramLogo} alt="instagram"
                       onClick={handleIG}
                  />
                  <img src={LinkedInLogo} alt="LinkedIn"
                       onClick={handleLinkedIn}
                  />
                  <img src={emailIcon} alt="email us"
                       onClick={handleEmail}
                  />
                  <img src={copyLinkIcon} alt="copy link"
                       onClick={handleCopyLink}
                  />
                  <button onClick={handlePrintPage} className={"printButton"}>
                    print
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="reportBodyContainer">
            <div className="reportNav">
              <h3>chapters</h3>
              <select name="chapters" id="chapters"
                      onChange={(e) => {
                        handleChapterNav(e.target.value)
                      }}
              >
                {newReportData.reportSections.map((chapter, index) => (
                  <option value={chapter.title} key={index}>
                    {chapter.title}
                  </option>
                ))}

              </select>

            </div>
            <div className="backToTopBtnCont"
                 onClick={handleBackToTop}
            >
              <div className="circle">
                <img src={chevron_left} alt="back to top" className={"backToTopBtn"} />
              </div>
              <p className="backToTopText">
                back to top
              </p>
            </div>

            {newReportData.reportSections.map((section, index) => {
              return handleSectionTypes(section, index)
            })}

            <div className="mediaQuery">
              <h4>media inquiries</h4>
              <p className="subMedia">For media inquiries email hello@rentpanda.ca</p>
              <button className={"contactNowBtn"}
                      onClick={handleEmail}
              >
                contact us
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Report