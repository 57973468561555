import { setUpdateEditProfileAPI } from "../api/updateEditProfile-api";

export const POST_UPDATE_EDIT_PROFILE_INIT = "POST_UPDATE_EDIT_PROFILE_INIT";
export const POST_UPDATE_EDIT_PROFILE_SUCCESS =
  "POST_UPDATE_EDIT_PROFILE_SUCCESS";
export const POST_UPDATE_EDIT_PROFILE_FAILED =
  "POST_UPDATE_EDIT_PROFILE_FAILED";

export function setUpdateEditProfile(body) {
  return function (dispatch) {
    dispatch({ type: POST_UPDATE_EDIT_PROFILE_INIT });
    setUpdateEditProfileAPI(body)
      .then((response) => {
        return dispatch({ type: POST_UPDATE_EDIT_PROFILE_SUCCESS, response });
      })

      .catch((error) =>
        dispatch({ type: POST_UPDATE_EDIT_PROFILE_FAILED, error })
      );
  };
}
