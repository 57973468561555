import {
  POST_UPDATE_EDIT_PROFILE_INIT,
  POST_UPDATE_EDIT_PROFILE_SUCCESS,
  POST_UPDATE_EDIT_PROFILE_FAILED,
} from "../actions/Action";

export const initialState = {
  updatedEditProfileList: [],
};

export default function updatedEditProfileReducer(
  state = initialState,
  action
) {
  const handlers = {
    [POST_UPDATE_EDIT_PROFILE_INIT]: (state) => ({
      ...state,
    }),
    [POST_UPDATE_EDIT_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      updatedEditProfileList: action.response,
    }),
    [POST_UPDATE_EDIT_PROFILE_FAILED]: (state) => ({
      ...state,
      updatedEditProfileList: [],
    }),
  };
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}
