import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N8Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={"Notice to end tenancy at the end of the term (N8 Form)"}
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
              For most types of tenancies (including monthly tenancies) the termination date must be at least 60 days after the landlord gives you this notice. Also, the termination date must be the last day of a rental period. For example, if you pay rent on the first of each month, the termination date must be the last day of the month. If the tenancy is for a fixed term (for example, a lease for one year), the termination date cannot be earlier than the last date of the fixed term. Exception: The termination date must be at least 28 days after the landlord gives you this notice if your tenancy is daily or weekly (you pay rent daily or weekly). Also, the termination date must be the last day of the rental period. For example, if you pay rent weekly each Monday, the termination date must be a Sunday. If the tenancy is for a fixed term, the termination date cannot be earlier than the last date of the fixed term.
            </span>
          </div>          
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord has put in this notice. However, the landlord can apply to the Board to evict you. The Board will schedule a hearing where you can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If you move out of the rental unit by the termination date, your tenancy ends on that date.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the board?</span>
          </div>
          <div className="notice-info-text">
            <span>
              The landlord can apply to the Board to evict you immediately after giving you this notice. If the landlord applies to the Board to evict you, the Board will schedule a hearing and send you a copy of the application and the Notice of Hearing. The Notice of Hearing sets out the date, time and location of the hearing. At the hearing, the landlord will have to prove the claims they made in this Notice to End your Tenancy and in the application and you can respond to the claims your landlord makes. If the Board issues an order ending your tenancy and evicting you, the order will not require you to move out any earlier than the termination date included in this notice.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
              For more information about this notice or your rights, you can
              contact the Landlord and Tenant Board. You can reach the Board by
              phone at <b>416-645-8080</b> or <b>1-888-332-3234</b>. You can visit the Board's
              website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a>.
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N8Info);
