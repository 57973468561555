import React from "react"


const ExecutiveSummary = ({data, name}) => {
  const parseText = (text) => {
    let textArray = []
    let textFormatter = {
    }
    const boldPat = /([\w-\s]*:\s)/g
    // const boldPat = /((?<!\n)[\w-\s]*:\s)/g
    const matches = text.matchAll(boldPat)
    let lastIndex = 0

    for (const match of matches) {
      textFormatter = {
        text: text.substring(lastIndex, match.index),
        bold: false
      }

      if (textFormatter.text !== "") {
        textArray.push(textFormatter)
      }

      textFormatter = {
        text: match[0],
        bold: true
      }
      textArray.push(textFormatter)
      lastIndex = match.index + match[1].length
    }
    return textArray
  }

  const addNewline = (text) => {
    // match a newline at the begging of "text"
    const newLinePat = /^\n/
    if (newLinePat.test(text)) {
      return `<br/>${text}`
    } else return text
  }

  const zipText = (textArray) => {
    let zippedText = []
    for (let i = 0; i < textArray.length; i++) {
      if (i === 0 || i % 2 !== 0) {
        zippedText.push([textArray[i], textArray[i+1]])
      }
    }
    return zippedText
  }

  const text = Object.values(data.data)
  console.log("TEXT", text)
  return (
    <div className={"reportSection"} id={name}>
      <h3 className={"chapterHeader"}>{data.title}</h3>
      <div className={"sectionContent"}>

        {text.map((item, index) => {
          if  (item !== "executiveSummary" ) {
            if (item.length === 2) {
              return (
                  <p key={index}><b>{item[0]}</b><br/>{item[1]}</p>
              )
            } else {
              return (
                  <p key={index}>{item[0]}</p>
              )
            }
          }
        })}


  {/*       old text */}
  {/*      { zippedText.map((text, index) => {
          if (index === 0) {
            return (
              <p key={index}><b>{addNewline(text[0].text)}</b>{addNewline(text[1].text)}</p>            )
          }
          if (text.length === 1) {
            return (
              <p key={index}>{addNewline(text[0].text)}</p>
            )
          }
          return (
            <p key={index}><b>{addNewline(text[1].text)}</b>{addNewline(text[0].text)}</p>
          )
        })*/}
      </div>
    </div>
  )
}

export default ExecutiveSummary