import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { EditButton } from '../../../../components';

const AmenitiesTenantCompleteDetails = props => {
    const {setCurrentStep, leaseDetailsData, amenitiesData, other_service_utilities, tenantDetails, handleClickInside, isPopoverOpen} = props;

    return (
        <>
            <div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>amenities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{leaseDetailsData !== null &&
						<Row>
							{leaseDetailsData.amenities && leaseDetailsData.amenities.map((data, i) => {
								return <Form.Group as={Col} md={2} key={i}>
									<Form.Label>{amenitiesData && amenitiesData.length > 0 && amenitiesData[i].name}</Form.Label>
									<span>{data.allow === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
							})}
							<Form.Group as={Col} md={'6'}>
								<Form.Label>Others</Form.Label>
								{leaseDetailsData.other_service_utilities &&
									<ul className='amenities'>
										{other_service_utilities.map((data, i) => {
											return data !== '' && <li key={'amenities' + i}>{data}</li>
										})}

									</ul>}
							</Form.Group>
						</Row>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>tenant details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(2)} />
						</div>
					</div>
					<hr />
					<Row>
						{(tenantDetails !== null && tenantDetails.tenants.length !== 0) &&
							<Form.Group as={Col} md='5'>
								<Row>
									<Form.Group as={Col} md='5' className="utilities-list">
										<Form.Label>Tenant Name</Form.Label>
									</Form.Group>
									<Form.Group as={Col} md='7' className="utilities-list">
										<Form.Label>Email</Form.Label>
									</Form.Group>
								</Row>
								{tenantDetails.tenants.map((tenant, i) => {
									return (<Row key={"tenant_list_" + i}>
										<Form.Group as={Col} md='5' className="utilities-list">
											<span>{tenant.name}</span>
										</Form.Group>
										<Form.Group as={Col} md='7' className="utilities-list">
											<span className='utilities'>{tenant.email}</span>
										</Form.Group>
									</Row>)
								})}
							</Form.Group>
						}
						<Form.Group as={Col} md='7'>
							<div className="utilities-list">
								<Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
								<span className='warning-popover d-inline'>
									<img className='cursorPointer ml-2 mb-1'
										onMouseEnter={() => handleClickInside('yes')}
										onMouseLeave={() => handleClickInside('no')}
										src={require('../../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
									{(isPopoverOpen === true) && <div className="custom-popover extra">
										<p className='popover-content' style={{ height: 'auto', minHeight: 'auto' }}>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute.</p>
									</div>}
								</span>
							</div>
							<span className='utilities'>{tenantDetails && (tenantDetails.required_tenant_insurance === 1 ? "Yes" : "No")}</span>
						</Form.Group>
					</Row>
				</div>
			</div>
        </>
    )
}

export default AmenitiesTenantCompleteDetails
