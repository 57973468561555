import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { numberFormatSanitizerInt, toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import CostNumberFormat from "../../CostNumberFormat";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N5Schema } from "./ValidationSchema/N5Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";
import TimePicker from "../../TimePicker";


const N5Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack,onNoticeDataUpdate
  } = props;
  const [errors,setErrors]=useState({})
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [globalState,setGlobalState]=useGlobalState()
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [primaryTime, setPrimaryTime] = useState(null);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [reason1, setReason1] = useState(false);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);
  const [reasonRadio1, setReasonRadio1] = useState("radio1reason1");
  const [reasonRadio2, setReasonRadio2] = useState("radio1reason2");
  const [reasonRadio3, setReasonRadio3] = useState("radio1reason3");
  const [reason_2_eviction_amount_1, setReason_2_eviction_amount_1] = useState(
    ""
  );
  const [reason_2_eviction_amount_2, setReason_2_eviction_amount_2] = useState(
    ""
  );
  const [noticeReasons, setNoticeReasons] = useState([
    {
      date: null,
      time: null,
      details: "",
    },
    {
      date: null,
      time: null,
      details: "",
    },
    {
      date: null,
      time: null,
      details: "",
    },
  ]);


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
    onNoticeDataUpdate({    
      tenant_move_out: terminationDate,
      end_tenancy_reason_1: reason1 ? "1" : "0",
      reason_1_tenant_eviction:
        reasonRadio1 === "radio1reason1"
          ? "1"
          : reasonRadio1 === "radio2reason1" && "2",
      end_tenancy_reason_2: reason2 ? "1" : "0",
      reason_2_tenant_eviction:
        reasonRadio2 === "radio1reason2"
          ? "1"
          : reasonRadio2 === "radio2reason2" && "2",
      reason_2_eviction_amount_1: reason_2_eviction_amount_1,
      reason_2_eviction_amount_2: reason_2_eviction_amount_2,
      end_tenancy_reason_3: reason3 ? "1" : "0",
      reason_3_tenant_eviction:
        reasonRadio3 === "radio1reason3"
          ? "1"
          : reasonRadio3 === "radio2reason3" && "2",
      notice_reasons: noticeReasons,
      signData
    })
    setGlobalState({ onSubmit: onSubmit })
    if (reason1 || reason2 || reason3) {
      setErrors({ selectReasonError: false })
     }

    if(isSubmitClicked)
    validate(N5Schema,null,true)

  },[tenant_id.length,property_id, reason_2_eviction_amount_1,reason_2_eviction_amount_2,terminationDate,phoneError,signData,noticeReasons,reason1,reason2,reason3,reasonRadio1,reasonRadio2,reasonRadio3])

  const onSubmit = () => {
    setIsSubmitClicked(true)
    const selectedResons=noticeReasons.filter((i)=>i.details && i.date && i.time).map((reasonItem)=>{
      const newDate=moment(reasonItem.date).format('YYYY-MM-DD')
     return {...reasonItem,date:newDate}
   })
    
    if (!reason1 && !reason2 && !reason3) {
      setErrors({ selectReasonError: true })
      document.getElementById('reasons').scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-", '').replace("_", '').length !== 10
    ) {
      setPhoneError(true);
      return
    }
    if (
      validate(N5Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        notice_id: noticeData && noticeData.id,
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 5,
        tenant_move_out: terminationDate,
        end_tenancy_reason_1: reason1 ? "1" : "0",
        reason_1_tenant_eviction:
          reasonRadio1 === "radio1reason1"
            ? "1"
            : reasonRadio1 === "radio2reason1" && "2",
        end_tenancy_reason_2: reason2 ? "1" : "0",
        reason_2_tenant_eviction:
          reasonRadio2 === "radio1reason2"
            ? "1"
            : reasonRadio2 === "radio2reason2" && "2",
        reason_2_eviction_amount_1: reason_2_eviction_amount_1,
        reason_2_eviction_amount_2: reason_2_eviction_amount_2,
        end_tenancy_reason_3: reason3 ? "1" : "0",
        reason_3_tenant_eviction:
          reasonRadio3 === "radio1reason3"
            ? "1"
            : reasonRadio3 === "radio2reason3" && "2",
        notice_reasons: selectedResons,
        agree_terms_and_conditions: "1",
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 14,
      };
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };

  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    } else if (type === "event1") {
      let tempArray = [...noticeReasons];
      tempArray[0].date = dateValid;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      let tempArray = [...noticeReasons];
      tempArray[1].date = dateValid;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      let tempArray = [...noticeReasons];
      tempArray[2].date = dateValid;
      setNoticeReasons(tempArray);
    }
  };

  const handleChangeTime = (time, type) => {
    const theDate = new Date(Date.parse(time));
    const timeFormated = theDate.toLocaleTimeString();
    if (type === "event1") {
      let tempArray = [...noticeReasons];
      tempArray[0].time = timeFormated;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      let tempArray = [...noticeReasons];
      tempArray[1].time = timeFormated;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      let tempArray = [...noticeReasons];
      tempArray[2].time = timeFormated;
      setNoticeReasons(tempArray);
    }
  };

  const onChangeDescription = (value, type) => {
    if (type === "event1") {
      let tempArray = [...noticeReasons];
      // validate(N5Schema)
      tempArray[0].details = value;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      // validate(N5Schema)
      let tempArray = [...noticeReasons];
      tempArray[1].details = value;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      // validate(N5Schema)
      let tempArray = [...noticeReasons];
      tempArray[2].details = value;
      setNoticeReasons(tempArray);
    }
  };

  
  useEffect(() => {
    if (noticeData ) {
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());
      setReason1(noticeData.end_tenancy_reason_1 == "0" ? false : noticeData.end_tenancy_reason_1 == "1" && true)
      setReason2(noticeData.end_tenancy_reason_2 == "0" ? false : noticeData.end_tenancy_reason_2 == "1" && true)
      setReason3(noticeData.end_tenancy_reason_3 == "0" ? false : noticeData.end_tenancy_reason_3 == "1" && true)
      setReasonRadio1(noticeData.reason_1_tenant_eviction== 1 ? "radio1reason1" : noticeData.reason_1_tenant_eviction== 2 && "radio2reason1" )
      setReasonRadio2(noticeData.reason_2_tenant_eviction== 1 ? "radio1reason2" : noticeData.reason_2_tenant_eviction== 2 && "radio2reason2" )
      setReasonRadio3(noticeData.reason_3_tenant_eviction== 1 ? "radio1reason3" : noticeData.reason_3_tenant_eviction== 2 && "radio2reason3")
      setReason_2_eviction_amount_1(noticeData.reason_2_eviction_amount_1)
      setReason_2_eviction_amount_2(noticeData.reason_2_eviction_amount_2)
      const tempArray = [{
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      }];
      (noticeData.notice_reasons || []).forEach((section,i) => {
          if (section.date && section.date !== 'Invalid date' && section.date !== '0000:00:00' && section.time!=='00:00:00') {
            section.date = moment(section.date).toDate()
            tempArray[i]=section
          }
      })
      setNoticeReasons(tempArray.length ? tempArray:[{
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      },])
    }
  }, [noticeData.notice_type]);
 
 
  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Notice to End your Tenancy <br />
            For Interfering with Others, Damage or Overcrowding (N5 Form)
            
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-3">
          <Row >
            <Form.Group as={Col} md="5" >
              <Form.Label  >
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title"  >Reason(s) for Ending Tenancy</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                <div id='reasons' style={{margin:'10px 0px 10px 0px',color:errors.selectReasonError?'red':''}}> 
                Please indicate your reasons by checking the boxes on the
                options below
                </div>
              </Form.Label>
              <div className="select-checkbox-wrapper">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    className="checkit"
                    id={"reason1"}
                    name={"reason1"}
                    checked={reason1}
                    onChange={() => {
                      setReason1(!reason1);
                    }}
                    hidden
                  />
                  <label htmlFor={"reason1"} className="checkmark"></label>
                  <span className="checkbox-title">
                    Reason 1: Tenant(s) behaviour or the behaviour of someone
                    visiting or living with the current tenant(s) has
                    substantially interfered with another tenant's or my
                    reasonable enjoyment of the residential complex and lawful
                    rights, privileges or interests
                  </span>
                </div>
                {reason1 && (
                  <>
                    <Form.Label className="nested-checkbox-title">
                      Please indicate the following below:
                    </Form.Label>

                    <Form.Check
                      key={"radio1reason1"}
                      type="radio"
                      id={`radio1reason1`}
                      name="reasonRadio1"
                      value={"radio1reason1"}
                      checked={reasonRadio1 === "radio1reason1" ? true : false}
                      onChange={() => {
                        setReasonRadio1("radio1reason1");
                      }}
                      label={
                        "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                      }
                    />
                    <Form.Check
                      key={"radio2reason1"}
                      type="radio"
                      id={`radio2reason1`}
                      name="reasonRadio1"
                      value={"radio2reason1"}
                      checked={reasonRadio1 === "radio2reason1" ? true : false}
                      onChange={() => {
                        setReasonRadio1("radio2reason1");
                      }}
                      label={
                        "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                      }
                    />
                  </>
                )}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    className="checkit"
                    id={"reason2"}
                    name={"reason2"}
                    checked={reason2}
                    onChange={() => {
                      setReason2(!reason2);
                    }}
                    hidden
                  />
                  <label htmlFor={"reason2"} className="checkmark"></label>
                  <span className="checkbox-title">
                    Reason 2: Tenant(s) or someone visiting or living with the
                    current tenant has wilfully or negligently damaged the
                    rental unit or the residential complex.
                  </span>
                </div>
                {reason2 && (
                  <>
                    <Form.Label className="nested-checkbox-title">
                      The Tenant(s) can correct their behavior by:
                    </Form.Label>

                    <Form.Check
                      key={"radio1reason2"}
                      type="radio"
                      id={`radio1reason2`}
                      name="reasonRadio2"
                      value={"radio1reason2"}
                      checked={reasonRadio2 === "radio1reason2" ? true : false}
                      onChange={() => {
                        setReasonRadio2("radio1reason2");
                      }}
                      label={
                        "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                      }
                    />
                    {reasonRadio2 === "radio1reason2" && (
                      <>
                        <Form.Label className="nested-checkbox-radio-title">
                          The Tenant(s) can correct their behavior by:
                        </Form.Label>
                        <div className="nested-checkbox-radio">
                          <ul>
                            <li>
                              <span>Repairing the damaged property</span>
                            </li>
                            or...
                            <li>
                              <div className="d-flex justify-content-start">
                                <span className="mr-2">
                                  Pay you with the estimated amount it will cost
                                  to repair the damaged property
                                </span>
                                <span className="mr-1"></span>
                                <div> 
                                <CostNumberFormat
                                  name={"reason_2_eviction_amount_1"}
                                  id={"reason_2_eviction_amount_1"}
                                  classname="eviction-field"
                                  value={
                                    reason_2_eviction_amount_1
                                      ? reason_2_eviction_amount_1
                                      : ""
                                  }
                                  updateField={(e) =>
                                    setReason_2_eviction_amount_1(
                                       e.target.value
                                    )
                                  }
                                  pre="$"
                                  placeholder={"Please indicate $ amount"}
                                />
                                </div>
                              </div>
                            </li>
                            or...
                            <li>
                              <span>
                                Replacing the damaged property, if it is not
                                reasonable to repair it.
                              </span>
                            </li>
                            or...
                            <li>
                              <div className="d-flex justify-content-start">
                                <span>
                                  Pay you with the estimated amount it will cost
                                  to replace the damaged property
                                </span>
                                <div> 
                                <CostNumberFormat
                                  name={"reason_2_eviction_amount_2"}
                                  id={"reason_2_eviction_amount_2"}
                                  classname="eviction-field"
                                  value={
                                    reason_2_eviction_amount_2
                                      ? reason_2_eviction_amount_2
                                      : ""
                                  }
                                  updateField={(e) =>
                                    setReason_2_eviction_amount_2(
                                      e.target.value
                                    )
                                  }
                                  pre="$"
                                  placeholder={"Please indicate $ amount"}
                                />
                                </div>
                              </div>
                            </li>
                            or...
                            <li>
                              <span>
                                making arrangements acceptable to you by
                                repairing or replacing damaged property or
                                paying you to replace or repair
                              </span>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                    <Form.Check
                      key={"radio2reason2"}
                      type="radio"
                      id={`radio2reason2`}
                      name="reasonRadio2"
                      value={"radio2reason2"}
                      checked={reasonRadio2 === "radio2reason2" ? true : false}
                      onChange={() => {
                        setReasonRadio2("radio2reason2");
                      }}
                      label={
                        "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                      }
                    />
                  </>
                )}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    className="checkit"
                    id={"reason3"}
                    name={"reason3"}
                    checked={reason3}
                    onChange={() => {
                      setReason3(!reason3);
                    }}
                    hidden
                  />
                  <label htmlFor={"reason3"} className="checkmark"></label>
                  <span className="checkbox-title">
                    Reason 3: There are more people living in your rental unit
                    than is permitted by health, safety or housing standards.
                  </span>
                </div>
                {reason3 && (
                  <>
                    <Form.Label className="nested-checkbox-title">
                      Please indicate the following below:
                    </Form.Label>

                    <Form.Check
                      key={"radio1reason3"}
                      type="radio"
                      id={`radio1reason3`}
                      name="reasonRadio3"
                      value={"radio1reason3"}
                      checked={reasonRadio3 === "radio1reason3" ? true : false}
                      onChange={() => {
                        setReasonRadio3("radio1reason3");
                      }}
                      label={
                        "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                      }
                    />
                    <Form.Check
                      key={"radio2reason3"}
                      type="radio"
                      id={`radio2reason3`}
                      name="reasonRadio3"
                      value={"radio2reason3"}
                      checked={reasonRadio3 === "radio2reason3" ? true : false}
                      onChange={() => {
                        setReasonRadio3("radio2reason3");
                      }}
                      label={
                        "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                      }
                    />
                  </>
                )}
              </div>
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">
              Details about the reasons for notice
            </span>
          </div>
          <Form.Label>
            <div style={{margin:'10px 0px 10px 0px'}}>          
            List up to 3 events below that have led you to give this notice,
            including the dates, times and specific detail
            </div>
          </Form.Label>

          <div className="event-details">
            <div className="event-title">Event 1 Details</div>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <DatePickerComponent
                  id="date1"
                  name="date1"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons[0]?.date || ''}
                  handleDateChange={(date) => handleDateChange(date, "event1")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <TimePicker
                  name={"event1"}
                  id={"event1"}
                  viewDateParam={noticeReasons[0]?.time}
                  handleChangeTime={(e) => handleChangeTime(e, "event1")}
                  placeholder={"Type or select"}
                  hideTimeLimit={true}
                />
                {/* <TimePickerComponent
                  name={"event1"}
                  id={"event1"}
                  viewDateParam={noticeReasons[0]?.time}
                  handleChangeTime={(e) => handleChangeTime(e, "event1")}
                  placeholder={"Type or select"}
                /> */}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details1"
                  id="details1"
                  value={noticeReasons[0]?.details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event1")
                  }
                />
              </Form.Group>
            </Row>
          </div>
          <div className="event-details">
            <div className="event-title">Event 2 Details</div>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <DatePickerComponent
                  id="date2"
                  name="date2"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons[1]?.date||''}
                  handleDateChange={(date) => handleDateChange(date, "event2")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <TimePickerComponent
                  name={"event2"}
                  id={"event2"}
                  viewDateParam={noticeReasons[1]?.time}
                  handleChangeTime={(e) => handleChangeTime(e, "event2")}
                  placeholder={"Type or select"}
                  hideTimeLimit={true}

                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details2"
                  id="details2"
                  value={noticeReasons[1]?.details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event2")
                  }
                />
              </Form.Group>
            </Row>
          </div>
          <div className="event-details">
            <div className="event-title">Event 3 Details</div>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <DatePickerComponent
                  id="date3"
                  name="date3"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons[2]?.date || ''}
                  handleDateChange={(date) => handleDateChange(date, "event3")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <TimePickerComponent
                  name={"event3"}
                  id={"event3"}
                  viewDateParam={noticeReasons[2]?.time}
                  handleChangeTime={(e) => handleChangeTime(e, "event3")}
                  placeholder={"Type or select"}
                  hideTimeLimit={true}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details3"
                  id="details3"
                  value={noticeReasons[2]?.details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event3")
                  }
                />
              </Form.Group>
            </Row>
          </div>
          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            type={"lastName"}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={5}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N5Form);
