import React from 'react';
import { AppLayout, PageTitleHeader, LargeButton } from '../..';
import '../style.scss';
import { withRouter } from "react-router-dom";

const N3Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (<>
   <AppLayout>
    <PageTitleHeader
      isArrow={true}
      onClick={() => back()}
      title={'Important Information About the Law'}
      subTitle={'Rent including charges for care services and meals (N3 Form)'}/>

      <div className="notice-info-card">
        <ol>
          <li>The landlord must give the tenant a notice to increase the rent and/or the charges for care services and meals at least 90 days before the date of the increase. If the landlord is only increasing the rent, this notice does not have to be given if the landlord and tenant have signed an Agreement to Increase the Rent Above the Guideline (Form N10)</li>
          <li>A landlord may increase the rent if at least 12 months have passed since the last rent increase or since a new tenant moved into the rental unit. This restriction does not apply to increases in the charges for care services and meals.</li>
          <li>If the rent increase needs approval by an order under the Residential Tenancies Act, 2006, the tenant is not required to pay more than the guideline increase until the order is issued. If the tenant pays the guideline increase, the tenant may owe the landlord once the order is issued.</li>
          <li>When a tenant moves into a care home, the landlord must give the tenant a care home information package. Until the tenant receives this package, the landlord cannot give the tenant a notice to increase the rent and/or charges for care services and meals.</li>
          <li>A tenant of a care home must give the landlord at least 30 days notice on Form N9 (Tenant's Notice to End the Tenancy) before terminating the tenancy. Once the tenant gives this notice, the tenant can give the landlord at least 10 days written notice to stop providing care services and meals.</li>
          <li>If you have any questions about the law related to rent increases and how it applies to this notice, you may contact the Landlord and Tenant Board at <strong>416-645-8080</strong> or toll-free at <strong>1-888-332-3234</strong>. Or, you may visit the Board's website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a> for further information.</li>
        </ol>
      </div>
      <div className="d-flex justify-content-end button-extra-margin">
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

        />
      </div>
  </AppLayout>
  </>);
}

export default withRouter(N3Info);