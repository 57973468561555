import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N7Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={"Causing serious problems in the rental unit or complex (N7 Form)"}
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
            The termination date the landlord sets out in this notice must be at least 10 days after
the landlord gives you this notice.
            </span>
          </div>          
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord
              has put in this notice. However, the landlord can apply to the
              Board for an order to evict you. The Board will schedule a hearing
              where you can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
            If you move out of the rental unit by the termination date, your tenancy ends on that
            date. However, if your landlord gave you this notice because you damaged the rental
            unit or the residential complex, you may still owe the landlord money for the damage.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the board?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If the landlord applies to the Board to evict you, the Board will
              schedule a hearing and send you a copy of the application and the
              Notice of Hearing. The Notice of Hearing sets out the date, time
              and location of the hearing. At the hearing, the landlord will
              have to prove the claims they made in this Notice to End your
              Tenancy and in the application and you can respond to the claims
              your landlord makes.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
              For more information about this notice or your rights, you can
              contact the Landlord and Tenant Board. You can reach the Board by
              phone at <b>416-645-8080</b> or <b>1-888-332-3234</b>. You can visit the Board's
              website at <a href='sjto.ca/LTB'>sjto.ca/LTB</a>.
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N7Info);
