import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const getListStyle = isDraggingOver => ({
    display: 'flex',
    padding: 8,
    overflow: 'auto',
});
const DNDContainer = (props) => {

    const {setDragging} = props;

    const dragStartHandler = () => setDragging(true);

    const dragEndHandler = (result) => {
        setDragging(false);
        props.onDragEnd(result);
    }
    return (
        <DragDropContext onDragStart={dragStartHandler} onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}

                    >
                        {props.items?.map((item, index) => (
                            <Draggable key={props.itemKey ? item[props.itemKey] : item} draggableId={props.itemKey ? item[props.itemKey] : item} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        onClick={event => event.stopPropagation()}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {props.renderItem(item, index)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

}


export default DNDContainer