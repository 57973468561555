import React, { useEffect, useState } from "react";
import { addMonths } from "date-fns";
import {
  SidePanel,
  LargeButton,
  DatePickerComponent,
} from "../../components";
import { Form, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { logger, toaster } from "../../../Utils";
import { validate } from "../../../Utils/Validation/validation";

import { getAPI, postAPI } from "../../../Api";
import { dateSanitizer } from "../../../Utils/DateSanitizer/DateSanitizer";
import "./style.scss";
import {
  whoCanUseCoupon,
  limitCouponBy,
} from "../../../Constants/adminConstant";
import { CreateCouponValidationSchemaWithExpiry } from "./ValidationSchema";
import { withRouter } from "react-router-dom";

const log = logger("CreateCoupon");
const CreateCoupon = (props) => {
  const {
    isShow,
    handleClose,
    setSuccessModalShow,
    setUpdateModalShow,
    setReload,
    editCoupon,
  } = props;
  const [coupon_name, setCoupon_Name] = useState("");
  const [start_date, setStart_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [who_can_use, setWho_can_use] = useState(1);
  const [limit_by, setLimit_by] = useState(1);
  const [total_usage_limit, setTotal_usage_limit] = useState(null)
  const [usage_limit, setUsage_limit] = useState(null);
  const [coupon_code, setCoupon_code] = useState("");
  const [couponCodeErr, setCouponCodeErr] = useState(false);
  const [couponCodeErrMsg, setCouponCodeErrMsg] = useState("");
  const [coupon_value, setCoupon_value] = useState(null);
  const [noExpiry, setNoExpiry] = useState(false);
  const [dateExpiryFlag, setDateExpiryFlag] = useState(false)

  const OnChangeInput = (event, setInputState) => {
    setInputState(event.target.value);
    setCouponCodeErr(false);
  };
  const handleDateChange = (date, setStart_date) => {
    const dateValid = dateSanitizer(date);
    setStart_date(dateValid);
  };
  
  
  useEffect(() => {
    if(noExpiry){
      setDateExpiryFlag(false)
    }
  },[noExpiry]);

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    if (name === "who_can_use") {
      setWho_can_use(Value);
    } else if (name === "limit_by") {
      setLimit_by(Value);
    }
  };

  useEffect(() => {
    if(editCoupon === null) {
      getAPI("/admin/coupons/get-unique-coupon").then((response) => {
        setCoupon_code(response.data?.data);
      });
    }
  }, [editCoupon]); 

  useEffect(() => {
    setDateExpiryFlag(false)
    setNoExpiry(false)
  }, [isShow])

  useEffect(() => {
    log.info("editCoupon", editCoupon)
    if (editCoupon !== null) {
      setCoupon_Name(editCoupon.coupon_name);
      setStart_date(new Date(editCoupon.start_date));
      if(editCoupon.expiry_date === null){
        setEnd_date(null);
        setNoExpiry(true)
      }else{
        setEnd_date(new Date(editCoupon.expiry_date));
        setNoExpiry(false)
      }      
      setWho_can_use(editCoupon.use_by);
      setLimit_by(editCoupon.limit_coupon_by);
      setUsage_limit(editCoupon.usage_limit);
      setTotal_usage_limit(editCoupon.total_usage_limit)
      setCoupon_code(editCoupon.coupon_code);
      setCoupon_value(editCoupon.coupon_value);
    } else if (editCoupon === null) {
      setCoupon_Name("");
      setStart_date(null);
      setEnd_date(null);
      setWho_can_use(1);
      setLimit_by(1);
      setTotal_usage_limit(null)
      setUsage_limit("");
      setCoupon_code(coupon_code);
      setCoupon_value("");
      setDateExpiryFlag(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCoupon]);

  const onSubmit = () => {
    let validation 
    let expiryDate
    let dateExpiryVar
  
    validation = CreateCouponValidationSchemaWithExpiry
    if(!noExpiry && !end_date){
      dateExpiryVar = true
      setDateExpiryFlag(dateExpiryVar)
    }else if(!noExpiry && end_date){
      dateExpiryVar = false
      // validation = CreateCouponValidationSchemaWithoutExpiry
      setDateExpiryFlag(dateExpiryVar)
      expiryDate= moment(end_date).format("YYYY-MM-DD")
    }else{
      dateExpiryVar = false
      setDateExpiryFlag(dateExpiryVar) 
      expiryDate= ""
    }

    if (validate(validation) && !dateExpiryVar) {
      let url;
      let data;
      let type;
      if (editCoupon === null) {
        url = "/admin/coupons/create";
        type = "create";
        data = {
          coupon_name: coupon_name,
          use_by: who_can_use,
          limit_coupon_by: limit_by,
          total_usage_limit: total_usage_limit,
          usage_limit: usage_limit,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          expiry_date: expiryDate,
          coupon_code: coupon_code,
          coupon_value: coupon_value,
        };
      } else {
        url = "/admin/coupons/update";
        type = "update";
        data = {
          id: editCoupon.id,
          coupon_name: coupon_name,
          use_by: who_can_use,
          limit_coupon_by: limit_by,
          usage_limit: usage_limit,
          total_usage_limit: total_usage_limit,
          start_date: moment(start_date).format("YYYY-MM-DD"),
          expiry_date: expiryDate,
          coupon_code: coupon_code,
          coupon_value: coupon_value,
        };
      }

      setReload(false);
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          handleClose();
          if (type === "create") {
            setSuccessModalShow(true);
          } else if (type === "update") {
            setUpdateModalShow(true);
          }
          setReload(true);
          setCoupon_Name("");
          setStart_date(null);
          setEnd_date(null);
          setWho_can_use(1);
          setLimit_by(1);
          setUsage_limit("");
          setTotal_usage_limit(null)
          setCoupon_code(coupon_code);
          setCoupon_value("");
          setDateExpiryFlag(false)
          if(props.history.location.pathname.split("/")[2]==="inactive"){
            props.history.push('/coupon-management/active')
          }
        } else if (response.response) {
          if (response.response.status === 422) {
            if (response.response.data.errors.coupon_code) {
              setCouponCodeErr(true);
              setCouponCodeErrMsg(response.response.data.errors.coupon_code[0]);
              // toaster("error", response.response.data.errors.coupon_code[0]);
            } else {
              toaster("error", response.response.data.message);
            }
          }
        }
      });
    }
  };

  return (
    <>
      {isShow &&
        <SidePanel isShow={isShow} isHide={handleClose} title={`${editCoupon === null ? 'Create Coupon' : 'Edit Coupon'}`}>
          <div className="sidepanel-body create-coupon mt-2">
            <Form id="Form">
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Coupon Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Here"
                      name="coupon_name"
                      id="coupon_name"
                      value={coupon_name ? coupon_name : ""}
                      onChange={(e) => OnChangeInput(e, setCoupon_Name)}
                      maxLength={100}
                    />
                  </Form.Group>
                </Row>
                
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Who Can Use This Coupon</Form.Label>
                    <ul className="select-options">
                      {whoCanUseCoupon.map((data) => (
                        <li
                          onClick={(e) => OnSingleOptionCheck(e, this)}
                          key={"who_can_use" + data.value}
                          value={data.value}
                          name="who_can_use"
                          className={who_can_use === data.value ? "active" : ""}
                        >
                          {data.title}
                        </li>
                      ))}
                    </ul>
                  </Form.Group>
                </Row>
                {/* <Row>
                  <Form.Group as={Col} md="12" className="mb-0">
                    <Form.Label>Limit Coupon By</Form.Label>
                    <ul className="select-options">
                      {limitCouponBy.map((data) => (
                        <li
                          onClick={(e) => OnSingleOptionCheck(e, this)}
                          key={"limit_by" + data.value}
                          value={data.value}
                          name="limit_by"
                          className={limit_by === data.value ? "active" : ""}
                        >
                          {data.title}
                        </li>
                      ))}
                    </ul>
                  </Form.Group>
                </Row> */}
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Total Usage Limit</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Specify a limit (e.g. 5)"
                      name="usage_limit"
                      id="usage_limit"
                      value={(usage_limit && usage_limit > 0) ? usage_limit : ""}
                      onChange={(e) => OnChangeInput(e, setUsage_limit)}
                      maxLength={8}
                    />
                    
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Limit Use Per User</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Specify a limit (e.g. 5)"
                      name="total_usage_limit"
                      id="total_usage_limit"
                      value={(total_usage_limit && total_usage_limit > 0) ? total_usage_limit : ""}
                      onChange={(e) => OnChangeInput(e, setTotal_usage_limit)}
                      maxLength={8}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Row>
                      <Col className="pr-0">
                        <Form.Label>Start Date</Form.Label>

                        <DatePickerComponent
                          id="current_start_date"
                          name="current_start_date"
                          format={"dd/MM/yyyy"}
                          value={start_date && start_date}
                          handleDateChange={(date) =>
                            handleDateChange(date, setStart_date)
                          }
                          minDate={new Date()}
                          displayText={"Select Date"}
                          popperPlacement="bottom-start"
                          openToDate={new Date()}
                          highlightDates={[new Date()]}
                        />
                      </Col>

                      <Col className="expiry-xmargin">
                        <div className="d-flex justify-content-between">
                          <Form.Label>Expiry Date</Form.Label>
                          <div className="expiry-checkbox d-flex justify-content-start">
                            <input
                              type="checkbox"
                              className="checkit"
                              id={"expiry"}
                              name={"expiry"}
                              checked={noExpiry}
                              onChange={() => {
                                setEnd_date(null);
                                setNoExpiry(!noExpiry)}}
                              hidden
                            />
                            <label htmlFor={"expiry"} className="checkmark"></label>
                            <span className="checkbox-title">No Expiry</span>
                          </div>
                        </div>

                        <DatePickerComponent
                          extraClass= { dateExpiryFlag && !noExpiry ? 'is-invalid' : '' }
                          name="current_end_date"
                          id="current_end_date"
                          format={"dd/MM/yyyy"}
                          minDate={new Date() > start_date ? new Date() : start_date}
                          maxDate={addMonths(new Date(), 3)}
                          value={end_date && end_date}
                          handleDateChange={(date) =>
                              handleDateChange(date, setEnd_date)
                          }
                          displayText={noExpiry ? "No Expiry" : "Select Date"}
                          popperPlacement="bottom-end"
                          disableValue={noExpiry ? true : false}
                          openToDate={new Date()}
                          highlightDates={[new Date()]}
                        />
                        <div id="error_current_end_date" style={{ display: dateExpiryFlag && !noExpiry ? 'block' : 'none' }} className="invalid-feedback">End Date required</div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="8JASDFA"
                      name="coupon_code"
                      id="coupon_code"
                      value={coupon_code}
                      onChange={(e) => OnChangeInput(e, setCoupon_code)}
                      className={couponCodeErr ? 'is-invalid2' : ''}
                      maxLength={8}
                      // readOnly
                    />
                    {couponCodeErr ? <div class="invalid-feedback2">{couponCodeErrMsg}</div> : ''}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Coupon Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="coupon_value"
                      id="coupon_value"
                      value={(coupon_value && coupon_value > 0) ? coupon_value : ""}
                      onChange={(e) => OnChangeInput(e, setCoupon_value)}
                      maxLength={13}
                    />
                  </Form.Group>
                </Row>
              </Form>
            </div>

            <div className={"fixed-bottom"}>
              <Row>
                <Col md={12}>
                  <LargeButton
                    onClick={() => onSubmit()}
                    title={`${editCoupon === null ? 'create coupon' : 'update coupon'}`}
                    style={{ width: "200px", float: "right" }}
                  />
                </Col>
              </Row>
            </div>
        </SidePanel>
      }
    </>
  );
};

export default withRouter(CreateCoupon);
