import React from 'react'
import { Button } from 'react-bootstrap';

const FormFooter = props => {
    const {backToPrevPage, leaseData, saveLoading, setSaveState, id, dataDescription} = props;
    return (
        <div className='container-footer cu-step-form-footer'>
            <Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
            {leaseData && leaseData.is_sent_to_tenant ?
                <Button disabled={saveLoading} variant='success' type='submit' onClick={() => setSaveState('update')}>Update {saveLoading && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                : <Button disabled={saveLoading} variant='success' type='submit' onClick={() => setSaveState('next')} id={id} data-description={dataDescription}>Next {saveLoading && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
            }
        </div>
    )
}

export default FormFooter;
