export const BlogValidationSchema = {
    cms_category_id: [
        { rule: "required", message: "category ID is required" }
    ],
    headline: [
        { rule: "required", message: "headline is required" }
    ],
    author: [
        { rule: "required", message: "author is required" },
    ],
    status: [
        { rule: "required", message: "status is required" },
    ],
    excerpt: [
        { rule: "required", message: "excerpt is required" },
    ],
}
