import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppLayout, PageTitleHeader, Loader, PDFViewerComponent } from '..';
// import { getAPI } from '../../../Api';
import { logger } from '../../../Utils';

const log = logger('ViewAppendix');
const ViewAppendix = (props) => {
    // const [reasondata, setReasonDeactivateData] = useState(null);
    // const [path, setPath] = useState('');
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [lease_id, setLeaseId] = useState('');
    const [user_id, setUserId] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);

    const back = () => {
        props.history.push({
            pathname: `/create-lease/${user_id}/${property_id}/${property_details_id}/complete-lease-details/${lease_id}`,
            // state: { singleReasonData: reasondata, path: path }
        });
    }

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setLeaseId(props.match.params.lease_id);
        setUserId(props.match.params.user_id);
        // setPath(props.location.state.path)
        // setReasonDeactivateData(props.location.state.singleReasonData)
        setPdfUrl(props.location.state.pdfUrl);
    }, [
        props.match.params.property_id,
        props.match.params.property_details_id,
        props.match.params.lease_id,
        //     props.location.state.singleReasonData,
        // props.location.state.path
    ]);


    return (
        <AppLayout>
            <div >
                <PageTitleHeader
                    isArrow={true}
                    onClick={() => back()}
                    title={'lease appendix'}
                />
            </div>

            {pdfUrl ?
                <PDFViewerComponent PDFUrl={pdfUrl} />
                : <Loader />
            }
        </AppLayout>
    );
}
export default withRouter(ViewAppendix);