import React from 'react'
import { Col, Image, Navbar, NavDropdown } from 'react-bootstrap';

function MessageSortBar({handleDropdownSelect,activeKey}) {
    return (
        <Navbar className="filter-nav mb-3">
        <Navbar.Collapse className="d-flex justify-content-between">
          <Navbar.Text>posted date:</Navbar.Text>
          <NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
            <NavDropdown.Item
              onClick={(e) => handleDropdownSelect('new_to_old')}
            >
              posted date: new to old
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={(e) => handleDropdownSelect('old_to_new')}
            >
              posted date: old to new
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    )
}

export default MessageSortBar
