import React, { useEffect } from 'react';
 import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import { logger } from '../../../../Utils';
import '../style.scss';
import { useDispatch, useSelector } from "react-redux";
import { setReceiverData, setViewingRequestId, getCalenderData, setConversationId } from "../../../../Store/actions/MessageDetailAction";
import { useHistory } from 'react-router';

const log = logger("Calender");
const CalenderView = (props) => {
    const { user_type, setConfirmShowing } = props;
    let eventGuid = 0;
    
    const dispatch = useDispatch();
    const history=useHistory()
    const calenderData = useSelector(state => state.showingDetailReducer.showingCalendarData);

    useEffect(() => {
        const eventObj = history.location.state
        if (eventObj && Object.keys(eventObj).length) {
            dispatch(getCalenderData(eventObj));
            //dispatch(setReceiverData(null));
                dispatch(setViewingRequestId(eventObj.viewingRequestId))
                dispatch(setConversationId(eventObj.conversation_id));
                setConfirmShowing(true);
             
        }
        return () => {
            dispatch(setConversationId(null));
        }
    }, [])

    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: String(eventGuid++),
                title,                
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    const renderEventContent = (eventInfo) => {
        
        return (
            <div className={(eventInfo.event.extendedProps.data && eventInfo.event.extendedProps.data.status) + ' calender-event'}>
                {/* <p className="mb-0 mt-0 address-text">
                    {eventInfo.event.extendedProps?.data?.address}
                </p> */}
                <p className="mb-0 mt-0 time-text">{eventInfo.event.extendedProps.data && eventInfo.timeText}  - {user_type==='landlord' ? eventInfo.event.extendedProps.data?.tenant?.split(' ')[0] : user_type==='tenant'? eventInfo.event.extendedProps.data?.landlord?.split(' ')[0]:''}</p>
                {/* <p className="mb-0 mt-0 landlord-text">
                    {user_type === 'tenant' ?
                        <span><span className="user-title">Landlord:</span>{" "+(eventInfo.event.extendedProps.data && eventInfo.event.extendedProps.data.landlord)}</span>
                        : <span><span className="user-title">Tenant:</span>{" " + (eventInfo.event.extendedProps.data && eventInfo.event.extendedProps.data.tenant)}</span>
        }
                </p> */}
                {/* <p className="mb-0 mt-0 status-text">{user_type + eventInfo.event.extendedProps.data && eventInfo.event.extendedProps.data.status}</p> */}
            </div>
        )
    }
    return (
        <FullCalendar
            class="full-calendar"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, momentPlugin]}
            // height="parent"
            headerToolbar={{
                left: 'prev',
                center: 'title',
                right: 'next'
            }}
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: true
            }}
            lazyFetching={false}
            initialView='timeGridWeek'
            contentHeight={'80vh'}
            handleWindowResize={true}
            editable={false}
            selectable={false}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            slotDuration='00:30:00'
            titleFormat='MMMM D, YYYY'
            // slotMinTime="08:00:00"
            // slotMaxTime="22:30:00"
            //initialEvents={calenderData}
            events={calenderData}
            select={() => handleDateSelect()}
            eventContent={renderEventContent} // custom render function   
            eventClick={
                function (info) {
                    var eventObj = info.event.extendedProps.data;
                    log.info(eventObj);
                    dispatch(getCalenderData(eventObj));
                    dispatch(setReceiverData(null));
                    dispatch(setViewingRequestId(eventObj.viewingRequestId))
                    dispatch(setConversationId(eventObj.conversation_id));
                    setConfirmShowing(true);
                }
            }
        />
    )
}



export default CalenderView;
