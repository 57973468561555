import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Col } from 'react-bootstrap';
import TenantListForMessage from "../../components/Message/TenantListForMessage";
import ChatBox from "../../components/Message/ChatBox";
import { Row } from "react-bootstrap";
import { Loader } from "../../components";
import io from 'socket.io-client';
import { SOCKET_URL } from "../../../Constants/endPoints";
import useWindowDimensions from "../../hooks/WindowSize";
import { sendMessageAPI, getTenantListApi, removeFromContact, getMessagesAPI, blockUserAPI, getQueyParam,getSelectedTenantProfile, sortTenant } from './helper'
import { useDispatch, useSelector } from "react-redux";
import { setConversationId, setHeaderContent, setTenantId } from "../../../Store/actions/MessageDetailAction";
import { withRouter } from "react-router";
import EmptyState from "./EmptyState";
import "./style.scss";
import { updateRedux } from "./messagesHelper";
 import { updateRawData } from "../../../Store/actions/RawDataAction";

let socket = null
let timeoutFlag;
const LandlordMessages = (props) => {

    const {
        setShowingRequest,
        setConfirmShowing,
        callTenantprofile,
        setInvite_apply,
        setLeaseOptionsScreen,
        setCertnPurchaseReport,
        setLeasePdfViewScreen,
        setReSchedule,
        setShowTenantList,
        is_reSchedule } = props;

    const role = Number(Cookies.get("role"));
    const { width } = useWindowDimensions();
    const property_id = props && Number(props.match.params.property_id);
    const property_details_id = props && Number(props.match.params.property_details_id);
    const user_id = useSelector(state => state.auth.id);
    const [isMobile] = useState(width < 750)
    const dispatch = useDispatch()
    const existingTenancyApplicationId=useSelector(state=>state.messageDetailsReducer.existingTenancyApplicationId)
    const [currentTenant,setCurrentTenant]=useState(null)
    const nextTenantUrl=useSelector(state=>state.rawData.nextTenantUrl)
    // states depends on socket
    const [message, setMessage] = useState('');
    const [messagesArr, setMessagesArr] = useState([])
    const [tenants, setTenants] = useState([])
    const conversationIdFromQuery = getQueyParam(window.location.search, 'conversation_id')
    const [conversation_id, setConverSationId] = useState(null)
    const [headerContent, setHeaderContentState] = useState({})
    // states depends on socket


    const [commonState, setCommonState] = useState({
        wrappedText: [],
        messageLoader: false,
        activeKey: 'new_to_old',
        showTenantList: true,
        rowCount: 1,
        isMessageEmpty: false,
        is_fetching_data: false,
        isLoading: false,
        tenant_id: null,
        is_blocked: false
    })
    const [showBottomLoader,setShowBottomLoader]=useState(false)
    
    const {
        wrappedText,
        messageLoader,
        activeKey,
        showTenantList,
        rowCount,
        isMessageEmpty,
        isLoading,
        tenant_id,
        is_blocked
    } = commonState

    // effects
    useEffect(() => {
        socket = io(SOCKET_URL);
        getTenantList(null,true)
        return () => {
            socket.removeAllListeners()

            //update redux
            dispatch(setTenantId(null))
            dispatch(setConversationId(null));
            //update redux
        }
    }, [])

    useEffect(() => {

        const selectedTenant = tenants?.find((i) => i.conversation_id === parseInt(conversationIdFromQuery))
        setCurrentTenant(selectedTenant);
        if (selectedTenant) {
            tenantListClick(selectedTenant)
        }

    }, [conversationIdFromQuery, tenants?.length])

    useEffect(() => {
        if (activeKey) {
            // getTenantList(activeKey);
             const tenantList=sortTenant(tenants,activeKey==='new_to_old'?'desc':'asc');
              setTenants([...tenantList])
        }

    }, [activeKey,tenants?.length])


    useEffect(() => {
        const { conversation_id } = headerContent || {}
        const userEventString = "message-" + user_id + ":ChatEvent"
        const conversationEventString = "conversation-" + conversation_id + ":MessageEvent"
        if (socket) {
            socket.removeAllListeners()
            socket.on("disconnect", (reason) => {
                socket.open();
            });
            socket.on(userEventString, (d) => {
                console.log({'LANDLORD USER DATA':d});
            
                onUserListen()
            });
            socket.on(conversationEventString, (data) => {
                console.log({'LANDLORD DATA':data});
                onConversationIdListen(headerContent)

            });
        }

    }, [headerContent?.conversation_id])


    useEffect(() => {
        if (conversation_id) {
            getMessages(conversation_id)
        }
    }, [conversation_id])


    useEffect(() => {
        if (!headerContent?.conversation_id && tenants?.length && !conversationIdFromQuery) {
            if(!isMobile)tenantListClick(tenants[0])
            else setState({showTenantList:true})

        }
    }, [tenants.length])
    // effects


    const setState = (obj) => {
        const newState = Object.assign({}, commonState, obj)
        setCommonState(newState)
        setShowTenantList(obj.showTenantList);
    }


    // socket handlers
    const onConversationIdListen = async (headerContent) => {
        if (headerContent.conversation_id === conversation_id) {
            const url = "/landlord/get-messages/" + conversation_id;
            const messagesRes = await getMessagesAPI(url)
            const tenantProfile=await getSelectedTenantProfile(currentTenant.tenant_id,conversation_id);
            setState({
                messageLoader: false,
                is_fetching_data: false
            })
            updateRawData({tenantProfileInfo:tenantProfile})
            setMessagesArr(messagesRes.messageData)
            updateRedux(dispatch, messagesRes)
        }
    }

    const onUserListen = async () => {
        const key = (activeKey === 'new_to_old') ? 'desc' : 'asc';
        const tenantsList = await getTenantListApi(key, property_id, property_details_id,true)
        const newTenantList=sortTenant(tenantsList,activeKey==='new_to_old'?'desc':'asc');

        setTenants(newTenantList)
        
    }

    // socket handlers


    const getTenantList = async (sort,showLoader=false) => {
        
        const sortKey=sort || activeKey
        const key = (sortKey === 'new_to_old') ? 'desc' : 'asc';
        if(showLoader){
            updateRawData({showLoader:true})
        }
        const tenantsList = await getTenantListApi(key, property_id, property_details_id)
        updateRawData({showLoader:false})
      
        setTenants( [...tenants,...tenantsList])
        return true
        
    }

    // message handlers

    const getMessages = async (conversationId) => {
        if (conversationId) {
            setState({
                messageLoader: true,
                is_fetching_data: true
            })
            const url = "/landlord/get-messages/" + conversationId;
            const messagesRes = await getMessagesAPI(url)
            const tenantProfile=await getSelectedTenantProfile(currentTenant.tenant_id,conversation_id);
            setState({
                messageLoader: false,
                is_fetching_data: false
            })
            setMessagesArr(messagesRes.messageData)
            updateRawData({tenantProfileInfo:tenantProfile})
            updateRedux(dispatch, messagesRes)
        }


    }

    const sendMessage = async (event, props) => {
        event.preventDefault();
        setMessage('')
        if (message.trim().length) {
            const url = "/landlord/send-message"
            const data = {
                "conversation_id": conversation_id,
                "message": message.trim()
            }
            await sendMessageAPI(url, data)
        }
    }

    // message handlers


    // event handlers

    const handleChange = (event) => {
        const textArea = event.target;
        if (event.type === "paste") {
            setTimeout(() => {
                setMessage(textArea.value);
            }, 100);
        } else {
            setMessage(event.target.value);
        }
    }


    const tenantListClick = async (data,newTenantList) => {
        const updatedTenants = (newTenantList || tenants).map((tenant) => {
            if (tenant.tenant_id === data.tenant_id) {
                if (tenant.new_message) {
                    tenant.new_message = false
                }
            }
            return tenant
        })
        if (data.tenant_id !== tenant_id) {
            setState({ is_blocked: data.is_blocked, showTenantList: !isMobile })
            setHeaderContentState(data)
            setConverSationId(data.conversation_id)
            setTenants(updatedTenants)
            setCurrentTenant(data)
            //update redux
            dispatch(setTenantId(data.tenant_id))
            dispatch(setConversationId(data.conversation_id));
            dispatch(setHeaderContent(data))
            //update redux
        }

    }

    const viewProfile = () => {
        callTenantprofile(true);
    }

    const blockUser = async (status) => {
        const url = status === 'unblock' ? '/landlord/unblock-tenant' : '/landlord/block-tenant'
        const res = await blockUserAPI(url, conversation_id)
        if (res) {
            await getTenantList(activeKey);
            setState({ is_blocked: status !== 'unblock' })
        }
    }


    const removeFromContactHandler = async () => {
        const newTenantList = await removeFromContact(conversation_id, tenants)
        setTenants(newTenantList)
        tenantListClick(newTenantList[0],newTenantList)
    }
    
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (event.shiftKey) {
                return
            } else if (event.target.value.trim() !== '') {
                sendMessage(event);
            }
        }
    }

    // event handlers


    return (
        <>
            <div className="profile-container">
                <div className="profile-container-box property-details">
                    {tenants ?
                        tenants.length ?
                            <Row className={conversation_id !== null && "tenant-list-outer"}>
                                {
                                showTenantList &&
                                <Col md="3" className="message-list-scroll pl-0 pr-0">
                                    <TenantListForMessage
                                        tenantListClick={(data) => tenantListClick(data)}
                                        isLoading={isLoading}
                                        activeKey={activeKey}
                                        setActiveKey={(data) => setState({ activeKey: data })}
                                        listData={tenants}
                                        headerContent={headerContent}
                                        onScrollDown={async ()=>{
                                           
                                            if(!showBottomLoader)
                                            {
                                                setShowBottomLoader(true)
                                                await getTenantList()
                                                setShowBottomLoader(false)
                                            }
                                        }}
                                        showBottomLoader={showBottomLoader}
                                    />
                                     </Col>
                                    }

                                {(!isMobile || !showTenantList) &&
                                    (conversation_id ?
                                        (<ChatBox
                                            listData={tenants}
                                            showTenantList={showTenantList}
                                            setShowTenantList={() => setState({ showTenantList: true })}
                                            messageData={messagesArr}
                                            headerContent={headerContent}
                                            conversation_id={conversation_id}
                                            setReSchedule={(flag) => setReSchedule(flag)}
                                            is_reSchedule={is_reSchedule}
                                            grid={9}
                                            role={role}
                                            message={message}
                                            is_blocked={is_blocked}
                                            sendMessage={(data) => sendMessage(data)}
                                            handleChange={(data) => handleChange(data)}
                                            setShowingRequest={setShowingRequest}
                                            setConfirmShowing={setConfirmShowing}
                                            viewProfile={viewProfile}
                                            set_tenancy={setInvite_apply}
                                            messageLoader={messageLoader}
                                            blockUser={(data) => blockUser(data)}
                                            removeFromContact={(data) => removeFromContactHandler(data)}
                                            setLeaseOptionsScreen={setLeaseOptionsScreen}
                                            setCertnPurchaseReport={setCertnPurchaseReport}
                                            setLeasePdfViewScreen={setLeasePdfViewScreen}
                                            rowCount={rowCount}
                                            isMessageEmpty={isMessageEmpty}
                                            handleKeyPress={handleKeyPress}
                                            handleTextWrap={wrappedText}
                                        />)
                                        : <Loader />
                                    )
                                }
                            </Row>
                            :
                            <EmptyState />
                        : <Loader />}

                </div>
            </div>
        </>
    );
}
export default withRouter(LandlordMessages);