import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster } from '../../../../Utils';

import { useSelector } from 'react-redux';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import Loader from '../../Loader';
import { fileUploadApi, leaseSubmit } from './helper';
import ContactCompleteDetails from './CompleteLeaseDetailsComponents/ContactCompleteDetails';
import LeaseInfoCompleteDetails from './CompleteLeaseDetailsComponents/LeaseInfoCompleteDetails';
import AmenitiesTenantCompleteDetails from './CompleteLeaseDetailsComponents/AmenitiesTenantCompleteDetails';
import UploadLeaseCompleteDetails from './CompleteLeaseDetailsComponents/UploadLeaseCompleteDetails';
import CompleteLeaseDetailsFooter from './CompleteLeaseDetailsComponents/CompleteLeaseDetailsFooter';

const CompleteLeaseDetails = (props) => {
	const { setCurrentStep, property_id, property_details_id, leaseData, lease_id, setOpenSignLease, setLeasePdfViewScreen, inProgress, leaseCreationHandler } = props;

	const [leaseDetailsData, setLeaseDetails] = useState(null);
	const [propertyAddress, setPropertyAddress] = useState(null);
	const [contactDetails, setContactDetails] = useState(null);
	const [notices_and_documents, setNotices_and_documents] = useState(null);
	const [landlordMailingDetails, setLandlordMailingDetails] = useState(null);
	const [otherDetails, setOtherDetails] = useState(null);
	const [tenantDetails, setTenantDetails] = useState(null);
	const [other_service_utilities, setOther_service_utilities] = useState([]);
	const [errorClass, setErrorClass] = useState(false);
	const [showImageLoading, setShowImageLoading] = useState(false);
	const [uploadData, setUploadData] = useState(null);
	const [loading, setLoading] = useState(false);
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const [amenitiesData, setAmenitiesData] = useState([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const maxLength = 25;

	useEffect(() => {
		(async () => {
			const { amenitiesData = [] } = await getUtilitiesAndAmenities()
			setAmenitiesData(amenitiesData)
		})()
	}, [])

	useEffect(() => {
		const fetchedData = leaseData;
		if (fetchedData && fetchedData.contact_details) {
			setContactDetails(fetchedData.contact_details)
			setNotices_and_documents(fetchedData.notices_and_documents)
			setPropertyAddress(fetchedData.property_address)
			setLeaseDetails(fetchedData.lease_deatils)
			setLandlordMailingDetails(fetchedData.landlord_mailing_address)
			setOtherDetails(fetchedData.other_details)
			setTenantDetails(fetchedData.tenant_details)
		}
	}, [leaseData]);

	useEffect(() => {
		leaseData && leaseData.lease_deatils && setOther_service_utilities(leaseData.lease_deatils.other_service_utilities)
		unitDetails && amenitiesData.filter(data => data.value !== 1).map(filter => {
			if (unitDetails?.amenities?.includes(filter.value)) {
				setOther_service_utilities(amenities => [filter.title, ...amenities])
			}
			return filter
		})
	}, [unitDetails, leaseData, amenitiesData])

	const viewAppendix = () => {
		props.history.push({
			pathname: `/view-appendix/${property_id}/${property_details_id}/${lease_id}`,
			state: {
				pdfUrl: leaseDetailsData && leaseDetailsData.tenancy_agreement === 1 ? leaseDetailsData?.lease_appendix : leaseDetailsData?.rent_panda_lease_appendix
			}
		})
	}

	const onSubmit = () => {
		if (leaseData?.tenant_details?.tenants?.length > 0) {
			if (leaseData?.is_signed) {
				setLeasePdfViewScreen(true);
			} else {
				setOpenSignLease(true);
			}
		} else {
			toaster('error', 'Please add tenant!');
		}
	}

	const handleSubmitSignedLease = async () => {
		setLoading(true);
		const data = { lease_id: lease_id, lease_pdf: uploadData.file };
		const res = await leaseSubmit(data, 'upload-signed-lease')
		if (res.status === 200) {
			setLoading(false);
			toaster('success', res.data.message)
			document.getElementById('profileTop').scrollIntoView();
			props.history.push({
				pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/overview',
				state: {
					lease_id: lease_id,
					step: 3
				}
			});
		} else {
			toaster('error', res.message);
		}
	}

	const handleDrop = async (file, setImage) => {
		setShowImageLoading(true)
		const dataFile = file[0];
		setUploadData(dataFile);
		let formdata = new FormData();
		formdata.append('file', dataFile);

		const {response, error} = await fileUploadApi(formdata, {setImage}, 'completeLease' );
	
		if(error){
			setShowImageLoading(false)
		}else{
			setImage({ file: response })
			setErrorClass(false)
			setShowImageLoading(false)
		}
	}

	const removeFile = (index, setFile) => setFile('')
	
	const handleClickInside = (option) => {
		setIsPopoverOpen(option === 'yes' ? true : false);
	}

	return (<>
		{!leaseData ? <Loader /> : <>
			{/* {leaseData && leaseData.is_signed === false && leaseData.is_sent_to_tenant === true && leaseData.is_signed_offline === true &&
				<UploadLeaseCompleteDetails 
					uploadData={uploadData}
					maxLength={maxLength}
					removeFile={removeFile}
					setUploadData={setUploadData}
					handleDrop={handleDrop}
					errorClass={errorClass}
					showImageLoading={showImageLoading}
				/>
			} */}
			<ContactCompleteDetails
				setCurrentStep={setCurrentStep}
				contactDetails={contactDetails}
				notices_and_documents={notices_and_documents}
				landlordMailingDetails={landlordMailingDetails}
				propertyAddress={propertyAddress}
			/>
			<LeaseInfoCompleteDetails
				setCurrentStep={setCurrentStep}
				leaseDetailsData={leaseDetailsData}
				viewAppendix={viewAppendix}
				maxLength={maxLength}
				otherDetails={otherDetails}
			/>
			<AmenitiesTenantCompleteDetails
				setCurrentStep={setCurrentStep}
				leaseDetailsData={leaseDetailsData}
				amenitiesData={amenitiesData}
				other_service_utilities={other_service_utilities}
				tenantDetails={tenantDetails}
				handleClickInside={handleClickInside}
				isPopoverOpen={isPopoverOpen}
			/>
			<CompleteLeaseDetailsFooter
				leaseDetailsData={leaseDetailsData}
				viewAppendix={viewAppendix}
				inProgress={inProgress}
				leaseData={leaseData}
				leaseCreationHandler={leaseCreationHandler}
				uploadData={uploadData}
				loading={loading}
				handleSubmitSignedLease={handleSubmitSignedLease}s
				onSubmit={onSubmit}
			/>
		</>}
	</>);
}

export default withRouter(CompleteLeaseDetails);