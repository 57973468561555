import "../ChartsStyle.scss"
import React from "react"


const AffordabilityTable = ({data, name, chartData}) => {
  const camelCase = (str) => {
    const words = str.split(" ")
    const camelCasedWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
    })
    return camelCasedWords.join("")
  }

  const normalizeObjProperties = (obj) => {
    // make all keys lowercase
    const normalizedObj = {}
    for (const key in obj) {
        normalizedObj[camelCase(key)] = obj[key]
    }
    return normalizedObj
  }

  const cleanString = (str) => {
    if (str) {
      const regex = /[^a-zA-Z0-9]/g
      return str.replace(regex, "")
    }

  }

  // #B4202C - threshold > 60
  // #D55F67 - threshold 50-60
  // #EA863C - threshold 40-50
  // #FDE194 - threshold 30-40
  // #78A569 - threshold 20-30
  // #4C7E3C - threshold < 20
  const getColor = (number) => {
    number = parseInt(cleanString(number))
    if (number > 60) {
      return "#B4202C"
    } else if (number > 50) {
      return "#D55F67"
    } else if (number > 40) {
      return "#EA863C"
    } else if (number > 30) {
      return "#FDE194"
    } else if (number > 20) {
      return "#78A569"
    } else {
      return "#4C7E3C"
    }
  }

  const invertTextColor = (number) => {
     if (parseInt(cleanString(number)) <= 40) {
      return "#000"
     } else {
      return "#FFF"
     }
  }

  const renderRow = (row, index) => {
    const normalizedRow = normalizeObjProperties(row)
    if (normalizedRow.city) {
      return (
          <tr key={index}>
            <td className={"cityRow"}>{normalizedRow.city}</td>
            <td className={"percentRow"} style={{ background: `${getColor(normalizedRow.individualAffordability)}`, color: `${invertTextColor(normalizedRow.individualAffordability)}`}}>
              {normalizedRow.individualAffordability}
            </td>
            <td className={"ratioRow"}>{normalizedRow.affordabilityRatioInd}</td>
            <td className={"houseHoldAffordability"}  style={{ background: `${getColor(normalizedRow.householdAffordability)}`,
              color: `${invertTextColor(normalizedRow.householdAffordability)}`}}
            >
              {normalizedRow.householdAffordability}
            </td>
            <td className={"ratioRow"}>{normalizedRow.affordabilityRatio}</td>
          </tr>
      )
    } else return null
  }


  return (
    <div className={"reportSection"} id={name}>
      <h3 className={"chapterHeader"}>{data.title}</h3>
      <div className={"sectionContent"}>

        <div className="tableLegendCont">
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              60%+ of <br/>
              income to rent
            </div>
          </div>
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              50-60% of <br/>
              income to rent
            </div>
          </div>
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              40-50% of <br/>
              income to rent
            </div>
          </div>
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              30-40% of <br/>
              income to rent
            </div>
          </div>
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              20-30% of <br/>
              income to rent
            </div>
          </div>
          <div className={"colorBlockCont"}>
            <div className="colorBlock"></div>
            <div className={"colorBlockText"}>
              {"<"}20% of <br/>
              income to rent
            </div>
          </div>
        </div>

        <div className="mobileScrollMsg">
          scroll right / left to see full table
        </div>

        <div className="tableContainer">
          <table id={"affordabilityTable"}>
            <thead>
            <tr>
              <th>City</th>
              <th>Individual Affordability</th>
              <th>Affordability Ratio</th>
              <th>Household Affordability</th>
              <th>Affordability Ratio</th>
            </tr>
            </thead>
            <tbody>
            {chartData.data.map((row, index) => renderRow(row, index))}
            </tbody>
          </table>
        </div>

        <div className="appendix">
          <p className="appendixText">
            {data.data.appendixText}
          </p>
        </div>

      </div>
    </div>

  )
}

export default AffordabilityTable