import {
    SET_SHOWING_CALENDAR_DATA,
    SET_SHOWING_LIST_DATA,
} from "../actions/Action";

export const initialState = {
    showingCalendarData: null,
    showingListData: null,
};

export default function showingDetailReducer(state = initialState, action) {
    const handlers = {
        [SET_SHOWING_CALENDAR_DATA]: (state) => ({
            ...state,
            showingCalendarData: action.showingCalendarData,
        }),
        [SET_SHOWING_LIST_DATA]: (state) => ({
            ...state,
            showingListData: action.showingListData,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}