import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { setToggleMenu } from "../../../Store/actions/Action";
import "./style.scss";
import RpImage from "../Image";
import Drawer from "../Drawer";
import Cookie from "js-cookie";

class Header extends Component {
  componentDidMount() {
    if (this.props.staticHeader) {
      let element = document.getElementById("header-navbar");
      let elementWithoutNavbar = document.getElementById("without-navbar");
      if (element && elementWithoutNavbar) {
        element.style.display = "flex";
        elementWithoutNavbar.style.display = "none";
      }
    } else {
      window.addEventListener("scroll", this.handleScroll);
      this.handleScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let element = document.getElementById("header-navbar");
    let elementWithoutNavbar = document.getElementById("without-navbar");
    if (element && elementWithoutNavbar) {
      if (
        !(
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        )
      ) {
        element.style.display = "none";
        elementWithoutNavbar.style.display = "flex";
        if (this.props.showActionButton) {
          this.props.showActionButton(false);
        }
      } else {
        element.style.display = "flex";
        elementWithoutNavbar.style.display = "none";
        if (this.props.showActionButton) {
          this.props.showActionButton(true);
        }
      }
    }
  };

  onOpenDrawer = () => {
    const element = document.getElementById("side-drawer");
    const overlayScreen = document.getElementById("overlay-screen");
    this.props.dispatch(setToggleMenu(true))

    if (element) {
      element.style.width = "100%";
    }
    if (overlayScreen) {
      overlayScreen.style.height = "200%";
      document.body.style.overflow = "hidden";
    }
  };

  renderDrawerIcon() {
    return (
      <div className="d-flex align-items-center">
        <div className="navbar-drawer">
          <div
            className="navbar-dark navbar-toggler-icon"
            onClick={this.onOpenDrawer}
          />
        </div>
      </div>
    );
  }

  onCloseButton = () => {
    const element = document.getElementById("side-drawer");
    const overlayScreen = document.getElementById("overlay-screen");
    if (element) {
      element.style.width = "0";
    }
    if (overlayScreen) {
      overlayScreen.style.height = "0";
      document.body.style.overflow = "initial";
    }
  };

  getRole = () =>{
    return parseInt(Cookie.get('role') || 0)
  }

  render() {
    const role = this.getRole();

    return (
      <React.Fragment>
        {/* <div id="overlay-screen" onClick={this.onCloseButton}/> */}
        <Navbar
          className="d-flex justify-content-between"
          id="header-navbar"
          expand="lg"
          sticky="top"
          collapseOnSelect
        >
          <Nav className="nav d-lg-none d-xl-none d-md-none p-3">
            {this.renderDrawerIcon()}
          </Nav>
          <Navbar.Brand href={(role && role === 2) ? "/landlord" : "/"}>
            <RpImage
              alt="Rent Panda"
              src={require("../../../_assets/images/logo-color.svg")}
              style={{ maxHeight: "50px" }}
              className="d-inline-block align-top py-2"
            />
          </Navbar.Brand>
          <div className="d-flex align-items-center">
            {this.props.type === "website" ? (
              <Nav.Link
                className="d-lg-none d-xl-none d-md-none"
                onClick={this.onLogout}
              >
                log in
              </Nav.Link>
            ) : (''
                // <RpImage
                //   width="20"
                //   height="20"
                //   alt="Rent Panda"
                //   src={require("../../../_assets/images/user.png")}
                //   className="d-inline-block align-top"
                // />
              )}

            <Dropdown className="d-none d-md-block">
              <Dropdown.Toggle className="cu-dropdown-toggle" variant="success" id="dropdown-basic">
                how it works
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/how-it-works/landlord" onClick={this.onLogout}>for landlords</Dropdown.Item>
                <Dropdown.Item href="/how-it-works/tenant" onClick={this.onLogout}>for tenants</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-none d-md-block">
              <Dropdown.Toggle className="cu-dropdown-toggle" variant="success" id="dropdown-basic">
                resources
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/resources/landlord" onClick={this.onLogout}>for landlords</Dropdown.Item>
                <Dropdown.Item href="/resources/tenant" onClick={this.onLogout}>for tenants</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <Nav.Link
              href="/resources"
              className="navbar-link d-none d-md-block"
              onClick={this.onLogout}
            >
              resources
            </Nav.Link> */}
            {/* <Nav.Link
              href="/signup/tenant"
              className="navbar-link d-none d-md-block"
              onClick={this.onLogout}
            >
              sign up
            </Nav.Link> */}
            
            <Dropdown className="d-none d-md-block">
              <Dropdown.Toggle className="cu-dropdown-toggle" variant="success" id="dropdown-basic">
                sign up
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/signup/tenant" onClick={this.onLogout}>for tenants</Dropdown.Item>
                <Dropdown.Item href="/signup/landlord" onClick={this.onLogout}>for landlords</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
            <Nav.Link
              href="/login"
              className="navbar-link d-md-none f-secondary-r"
              onClick={this.onLogout}
            >
              log in
            </Nav.Link>
            <Nav.Link
              href="/login"
              className="navbar-link d-none d-md-flex nav-link-active f-secondary-r"
              onClick={this.onLogout}
            >
              log in
            </Nav.Link>
          </div>
        </Navbar>
        <Drawer onLogout={this.props.onLogout} userData={this.props.userData} />
      </React.Fragment>
    );
  }
}

const select = (state) => ({
  toggleMenu: state.headerReducer.toggleMenu,
  auth: state.auth,
});

export default connect(select) (withRouter(Header));
