 import React, { useEffect, useState } from "react";
 import Cookies from "js-cookie";
import { Row, Col, Button } from "react-bootstrap";
import { logger } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";

const log = logger("WarningDetails");
const WarningDetails = (props) => {
  const { noticeData, downloadPdf, requestData, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  const [description, setDescription] = useState(''); 
  useEffect(() => {
    if (noticeData) {
      const str = noticeData?.notes?.replace(/<[^>]*>?/gm, '')
      setDescription(str?.replaceAll(`&nbsp;`,' '))
    }
  }, [noticeData]);

  log.info('requestData', requestData);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice of Warning</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="mt-4">
          <span className="form-title">Landlord Info</span>
        </div>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              Full Name
            </span>
            <span className="field-value-text">{noticeData.landlord && noticeData.landlord.name}</span>
          </Col>

          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              Phone Number
            </span>
            <span className="field-value-text">{noticeData.landlord && noticeData.landlord.phone_number}</span>
          </Col>
        </Row>

        <div className="border-separator"></div>

        <div className="mt-4">
          <span className="form-title">Reasons for warning</span>
        </div>

        <Row>
          <Col md={10} className="d-flex flex-column">
            <span className="field-title-text">
              Reasons
            </span>
            <ul className="field-value-text">
              {console.log("noticeData.reasons_for_warning", noticeData.reasons_for_warning)}
              {noticeData.reasons_for_warning && requestData.filter((data) => {
                let title = '';
                if (noticeData.reasons_for_warning.includes(data.id)) {
                  if (data.id === 29) {
                    title = data.title + ' - ' + noticeData.reason_text;
                  } else {
                    title = data.title;
                  }
                }
                return title;
              }).map(function (obj, i) {
                return <li key={'reasons_for_warning' + i}>{obj.title}</li>;
              })}
            </ul>
          </Col>
        </Row>

        <div className="border-separator"></div>

        <div className="mt-4">
          <span className="form-title">Additional Notes</span>
        </div>

        <Row>
          <Col md={10} className="d-flex flex-column">
            <span className="field-value-text">
              {description}
            </span>
          </Col>
        </Row>

      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default WarningDetails;
