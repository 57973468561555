import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N5Info = (props) => {
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={
            "Notice to end your tenancy for interfering with others, damage or overcrowding (N5 Form)"
          }
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The termination date</span>
          </div>
          <div className="notice-info-text">
            <span>
              If this is your first N5 Notice to End your Tenancy in the past 6
              months, the termination date on page 1 must be at least 20 days
              after the landlord gave you this notice. If this is your second 
              <span className={'link'} onClick={back}>  N5 Notice to End your Tenancy </span> in the past 6 months, the termination
              date on opage 1 must be at least 14 days after the landlord gave
              you this notice.
              <br />
              <br />
              Note: A landlord cannot give you a second N5 Notice to End your
              Tenancy unless at least 7 days have passed since the first N5
              notice was given.
            </span>
          </div>
          <div className="notice-info-text">
            <span>For reasons 2 and 3</span>
            <ul>
              <li>
                <span>
                  If this is your first Notice to End your Tenancy in the past 6
                  months, the termination
                  <br /> date must be at least 20 days after the landlord gave
                  you this notice.
                </span>
              </li>
              <li>
                <span>
                  If this is your second Notice to End your Tenancy in the past
                  6 months and the first
                  <br /> notice had a 7 day correction period, the termination
                  date must be at least 14 days after the landlord gives you
                  this notice.
                </span>
              </li>
            </ul>
          </div>
          <div className="notice-info-title">
            <span>What if you agree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If you agree with what the landlord has put in this notice, and
              this is your first Notice to End your Tenancy in the past 6
              months, you should correct the problem(s) described on page 2
              within 7 days after receiving this notice. If you do, the landlord
              cannot apply to the Board to evict you based on this notice.
              <br />
              <br />
              Note: A landlord cannot give you a second N5 Notice to End your
              Tenancy unless at least 7 days have passed since the first N5
              notice was given.
            </span>
          </div>
          <div className="notice-info-text">
            <span>The landlord can apply to the Board to evict you if:</span>
            <ul>
              <li>
                <span>you do not correct the problem(s) within 7 days, or</span>
              </li>
              <li>
                <span>
                  this is your second Notice to End your Tenancy in the past 6
                  months.
                </span>
              </li>
            </ul>
            <br />           
            <span>
              If the landlord applies to evict you, you do not have to move out.
              The Board will schedule a hearing which you can attend. However,
              if the landlord applies to the Board to evict you and the Board
              orders eviction, you will likely have to pay the landlord's filing
              fee.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you disagree with the notice?</span>
          </div>
          <div className="notice-info-text">
            <span>
              You do not have to move out if you disagree with what the landlord
              has put in this notice. However, the landlord can apply to the
              Board for an order to evict you. The Board will schedule a hearing
              where you can explain why you disagree.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if you move out?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If you move out of the rental unit by the termination date, your
              tenancy ends on that date. However, if your landlord gave you this
              notice because you damaged the rental unit or the residential
              complex, you may still owe the landlord money for the damage.
            </span>
          </div>
          <div className="notice-info-title">
            <span>What if the landlord applies to the board?</span>
          </div>
          <div className="notice-info-text">
            <span>
              If the landlord applies to the Board to evict you, the Board will
              schedule a hearing and send you a copy of the application and the
              Notice of Hearing. The Notice of Hearing sets out the date, time
              and location of the hearing. At the hearing, the landlord will
              have to prove the claims they made in this notice and in the
              application and you can respond to the claims your landlord makes.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
              For more information about this notice or your rights, you can
              contact the Landlord and Tenant Board. You can reach the Board by
              phone at <b>  416-645-8080 or 1-888-332-3234.</b> You can visit the Board's
              website at <a href="http://sjto.ca/LTB" target="_blank"> sjto.ca/LTB.</a>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N5Info);
