import React from 'react'

const CustomRadio = (props) => {
    const { onChange, name, checked, label, extraClass } = props;
    return (
      <div className={extraClass + " d-flex justify-content-start"}>
        <input
          type="radio"
          className="checkit"
          name={name}
          id={label}
          checked={checked}
          onChange={onChange}
          value={label}
          hidden
        />
        <label htmlFor={label} className="checkmark"></label>
        <span className="checkbox-title">{label}</span>
      </div>
    );
};

export default CustomRadio;
