import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProfileCard, PhoneNumberFormat, DollorNumberDisplay } from '../../../components';
import moment from 'moment'
import Cookies from 'js-cookie';

const PastHomeInfoDisplay = (props) => {
  const { profileDetails, editaction, addaction } = props
  const role = Number(Cookies.get('role'));

  return (
    <ProfileCard
      title={role !== 1 ? 'Past Address' : 'Past Home Info'}
      editaction={editaction}
      addaction={addaction}>

      <Container fluid>
        {profileDetails.past_home ?
          profileDetails.past_home.map((data, i) => {
            return (<div key={'past_home_' + i}>
              {i > 0 && <hr />}
              <Row>
                <Col md='2'>
                  <label>Full Address</label>
                  <h3>{data.full_address}</h3>
                </Col>
                {role !== 1 &&
                  <Col md='1'>
                    <label>Unit Number</label>
                    <h3>{data.unit_no ? data.unit_no : '--'}</h3>
                  </Col>
                }
                <Col md='1'>
                  <label>Status</label>
                  <h3>{data.is_owned === 1 ? 'Owned' : 'Rented'}</h3>
                </Col>
                <Col md='2'>
                  <label>Period</label>
                  {/* <h3><Moment format='MMM D, YYYY'>{data.start_date}</Moment> { data.start_date ? moment(data.start_date).format(MMM D, YYYY)  } - <Moment format='MMM D, YYYY'>{data.end_date ? data.end_date : '--' }</Moment></h3> */}
                  <h3>{data.start_date ? moment(data.start_date).format('MMM D, YYYY') : moment(new Date()).format('MMM D, YYYY')} - {data.end_date ? moment(data.end_date).format('MMM D, YYYY') : moment(new Date()).format('MMM D, YYYY')}</h3>
                </Col>
                <Col md='2'>
                  <label>Monthly Rent</label>
                  {data.is_owned ?
                    <h3>--</h3>
                    :
                    <h3><DollorNumberDisplay price={data.monthly_rent} /></h3>
                  }
                </Col>
                <Col md='4'>
                  <label>Owner's Contact Info</label>
                  {data.is_owned ?
                    <h3>--</h3> :
                    <h3>{data.landlord_name}
                      <span className='ml-4 highlight'>{data.landlord_email}</span>
                      <span className='ml-4 highlight'><PhoneNumberFormat phoneNumber={data.landlord_phone} /></span></h3>
                  }
                </Col>
              </Row>
            </div>)
          }) :
          <Row>
            <Col md='12' className='d-flex justify-content-center'>
              <label>No Information Provided</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default PastHomeInfoDisplay;