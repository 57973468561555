import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Google_Map_API } from "../../../Constants//endPoints"
import Loader from "../Loader";

const MapContainer = (props) => {
  const { latitude, longitude, height } = props
  const [lat, setLat] = useState(34.059849);
  const [lng, setLng] = useState(-118.455551);

  useEffect(() => {
    if (latitude && longitude) {
      setLat(latitude)
      setLng(longitude)
    }
  }, [latitude, longitude])

  return (
    <Map
      google={props.google}
      center={{
        lat: lat,
        lng: lng
      }}
      zoom={15}
      centerAroundCurrentLocation={false}
      containerStyle={{
        height: height ? height : '60vh',
        position: 'relative',
        width: '100%'
      }}>

      <Marker
        title={props.address && props.address.formatted_address}
        position={{
          lat: lat,
          lng: lng
        }} />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: (Google_Map_API),
  LoadingContainer: Loader
})(MapContainer)