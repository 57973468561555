import {
  GET_EDIT_PROFILE_INIT,
  GET_EDIT_PROFILE_SUCCESS,
  GET_EDIT_PROFILE_FAILED,

  // POST_EDIT_PROFILE_INIT,
  // POST_EDIT_PROFILE_SUCCESS,
  // POST_EDIT_PROFILE_FAILED,
} from "../actions/Action";

export const initialState = {
  editProfileList: [],
  //updatedEditProfileList: []
};
export default function editProfileReducer(state = initialState, action) {
  const handlers = {
    [GET_EDIT_PROFILE_INIT]: (state) => ({
      ...state,
    }),
    [GET_EDIT_PROFILE_SUCCESS]: (state, action) => ({
      ...state,
      editProfileList: action.response,
    }),
    [GET_EDIT_PROFILE_FAILED]: (state) => ({
      ...state,
      editProfileList: [],
    }),

    //  [POST_EDIT_PROFILE_INIT]: (state) => ({
    //     ...state
    // }),
    // [POST_EDIT_PROFILE_SUCCESS]: (state, action) => ({
    //     ...state,
    //     updatedEditProfileList: action.response
    // }),
    // [POST_EDIT_PROFILE_FAILED]: (state) => ({
    //     ...state,
    //     updatedEditProfileList: [],
    // })
  };
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}

//  export default function updatedEditProfileReducer(state=initialState, action){
//     const handlers = {
//         [POST_EDIT_PROFILE_INIT]: (state) => ({
//             ...state
//         }),
//         [POST_EDIT_PROFILE_SUCCESS]: (state, action) => ({
//             ...state,
//             updatedEditProfileList: action.response
//         }),
//         [POST_EDIT_PROFILE_FAILED]: (state) => ({
//             ...state,
//             updatedEditProfileList: [],
//         })
//     };
//     const handler = handlers[action.type];
//     if(!handler) return state;
//     return {...state, ...handler(state, action)};
// }
