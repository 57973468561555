import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import { LargeButton, CancelButton, SidePanel } from "../../../components";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { toaster } from "../../../../Utils";

const SignLease = (props) => {
    const { isShow, handleClose, openFinalCreateLease, setSkip } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [lease_id, setLeaseId] = useState('');
    const [full_name_sign_Lease, setFullName] = useState('');
    const [full_name_sign_LeaseUrl, setFullNameUrl] = useState(null);
    const [disableBtn, setBtnDisable] = useState(false);

    const handleCloseConfirm = () => {
        handleClose();
    }

    const createSignImage = (value) => {
        const textToImage = require('text-to-image');
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        }).then(function (dataUri) {
            setFullNameUrl(dataUri);
        });
    }

    const onChangeInput = (e) => {
        setFullName(e.target.value)
        createSignImage(e.target.value)
    }

    const openSkip = () => {
        setSkip(true)
        handleClose();
        openFinalCreateLease(true);
    }
    const finalCreate = () => {
        setBtnDisable(true)
        const url = "/admin/listing/lease/sign";
        const data = {
            "lease_id": lease_id,
            "property_id": property_id,
            "property_detail_id": property_details_id,
            "landlord_lease_signature": full_name_sign_LeaseUrl
        }
        postAPI(url, data).then(res => {
            setBtnDisable(false)
            if (res.status === 200) {
                // const url = "/admin/listing/deactive/" + property_details_id;
                // const data = {
                //     "deactivate_reason": reasonDeactivate && reasonDeactivate.order,
                //     "deactivate_description": reasonDeactivate && reasonDeactivate.deactive_reason,
                //     'is_active': 1
                // }
                // postAPI(url, data).then(res => {
                //     if (res.status === 200) {
                //         handleClose();
                //         openConfirmation(true)
                //     }
                // })
                handleClose();
                openFinalCreateLease(true);
            } else if (res.response.status === 400) {
                toaster('error', res.response.data.message);
            } else if (res.response.status === 422) {
                toaster('error', res.response.data.message);
            }
        })

    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setLeaseId(Number(props.match.params.lease_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);
    return (<div className='sign-lease-comp'>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Create A Lease"
        // subTitle={'Sign your lease'}
        >
            <div className="sidepanel-body" style={{ top: '90px' }}>
                <Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label>Type Landlord's Fullname</Form.Label>
                        <Form.Control
                            type="text"
                            name="full_name_sign_Lease"
                            id="full_name_sign_Lease"
                            value={full_name_sign_Lease}
                            placeholder="John Doe"
                            maxLength="30"
                            onChange={(e) => onChangeInput(e)}
                        />
                    </Form.Group>
                    <Col md={'12'}>
                        <span className='disclaimer'>Disclaimer: this is a simple electronic signature and may not constitute a legally binding agreement between tenant and landlord. Rent Panda recommends that all leases be signed in person, with each party to the agreement obtaining a copy of the fully executed and legally binding lease agreement as per section 17 of the Residential Tenancy Agreement.</span>
                    </Col>
                    <Col md={'12'} className='text-center'>
                        <Button className='skip-offline' onClick={() => openSkip()}>Skip (Sign Offline)</Button>
                    </Col>
                    <Col md={'12'}>
                        {full_name_sign_LeaseUrl &&
                            <Form.Group className="mt-3 signature">
                                <Form.Label>Esignature</Form.Label>
                                <Image src={full_name_sign_LeaseUrl} alt="Sign Image" />
                            </Form.Group>}
                    </Col>
                </Row>
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='back' style={{ fontWeight: 'bold' }} />
                <LargeButton title='create lease' saveLoading={disableBtn} onClick={() => finalCreate()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </div>);
}
export default withRouter(SignLease);