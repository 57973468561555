import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ModalBox } from "../../../components";
import '../style.scss';
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
const RequestTable = (props) => {
    const role = Number(Cookies.get('role')); 
    const [modalShow, setModalShow]=useState(false);
    const [deleteItemId, setDeleteItemId]=useState(null);
    const { calenderListViewData, maintenanceList, deleteItem } = props;  
    const id = useSelector((state) => state.auth.id);

    const viewRequest=(id)=>{
        props.history.push(`/view-repair-request/${id}`);
        }
    return (
        <div>
            <div className="table-component-wrapper repair-list">
                <table className="table-component">
                    <thead>
                        <tr className="list-view-header repair-list-head">
                            <th>{role===2 ? "Repair Request" : role===3 && "Request Type"}</th>
                            <th >{role===2 ? "Date" : role===3 && "Date Created"}</th>
                            <th>Time</th>
                            <th>{role===2 ? "Tenant" : role===3 && "Landlord"}</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                       {maintenanceList && maintenanceList.map((list, index)=>{
                           return <tr className={list.status_info.status_name==='Delivered' ? 'item-delivered' : list.status_info.status_name==='Completed' ? 'item-completed' : 'item-needAttention'} style={{lineHeight:'45px'}} key={index}>
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)} width='30%'>{list.request_type_info.status_name}</td>
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)}>{list.date}</td>
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)}>{list.time}</td>
                           {role===2 && 
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)}>{list.property_tenants && list.property_tenants.map((tenant, i)=>{
                               return (
                                <span
                                    key={i}
                                >
                                   {tenant.name}
                                   {i !== (list.property_tenants.length - 1) && ","}&nbsp; 
                                </span>)
                           })}</td> }
                           {role===3 && 
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)}>{list.landlord.name}</td> }
                           <td className="cursor-pointer" onClick={()=>viewRequest(list.id)}><span className="status">{list.status_info.status_name}</span></td>
                           <td>{id===list.creator && list.status_info.status_name!=='Completed' && <img className="cursor-pointer" onClick={()=>{
                               setDeleteItemId(list.id);
                               setModalShow(true);
                        }} src={require('../../../../_assets/images/delete.svg')} alt="Delete" />}</td>
                       </tr>
                       })}              
                    </tbody>                   
                </table>
                {maintenanceList.length===0 && <div className="d-flex justify-content-center pt-4"><span>No repair history</span></div>}      
            </div>
            {(calenderListViewData && calenderListViewData.length === 0) &&
                <Row className="m-0">
                    <Col className="empty-showing-request" md={12}>There are no showing requests or scheduled showings to display.</Col>
                </Row>
            }
            
        <ModalBox
          show={modalShow}
          onHide={() => setModalShow(false)}
          closeButtonHeader={false}
          size="md"
          type="condition"
          actionbuttontitle={"Yes"}
          buttonaction={() => {
            setModalShow(false);
              deleteItem(deleteItemId);}}
          backbuttontitle={"No"}
          backaction={() => {
            setModalShow(false);
          }}
        >         
             <p className="modal-confirmation-text">
                are you sure you want to <br /> cancel this {role===3 && "repair"} request?
              </p>
              {/* {role===3 ? <p className="success-text-secoundary" style={{ marginTop: '20px' }} >it cannot be undone</p> : <p className="modal-text">it will be deleted permanently</p> } */}
        </ModalBox>
        </div>
    );
}

export default withRouter(RequestTable);