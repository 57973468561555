import { postAPI, getAPI } from '../../../Api';
import store from '../../../configureStore';
import { setUserProfile } from '../../../Store/actions/UserProfileAction';
import { toaster } from '../../../Utils';

export const getTenantProfile = async () => {
  if (store.getState().auth?.role) {
    const url = '/tenant/profile';
    const response = await getAPI(url);
    const res = response.data.data;
    store.dispatch(setUserProfile(res));
  }
};

export const askToCompleteProfile = async (conversationId, tenantId) => {
  try {
    const url = '/landlord/ask-tenant-complete-profile';
    const data = {
      conversation_id: conversationId,
      tenant_id: tenantId,
    };
    const res = await postAPI(url, data);
    if (res.status !== 200) {
      toaster('error', res.response.data.message);
      return false;
    }
    return true;
  } catch (err) {
    toaster('error', err.message);
    return false;
  }
};

export const cancelScreening = async (
  credit_check_request_id,
  conversation_id
) => {
  try {
    const url = '/landlord/cancel-screening';
    const data = {
      credit_check_request_id: credit_check_request_id,
      conversation_id: conversation_id,
    };
    const res = await postAPI(url, data);
    if (res.status !== 200) {
      toaster('error', res.response.data.message);
      return false;
    }
    return true;
  } catch (err) {
    toaster('error', err.message);
    return false;
  }
};

export const getShowingData = async (viewingRequestId) => {
  try {
    const url = '/landlord/showing-request-details/' + viewingRequestId;
    const res = await getAPI(url);
    if (res.status !== 200) {
      toaster('error', res.response.data.message);
      return false;
    }
    return res.data.data;
  } catch (err) {
    toaster('error', err.message);
    return false;
  }
};

export const cancelShowingFromTenant = async (
  conversation_id,
  viewingRequestId,
  reject_reason
) => {
  const data = {
    conversation_id: conversation_id,
    viewing_request_id: viewingRequestId,
    reason: reject_reason,
  };
  try {
    let url = '/tenant/reject-showing-invitation';
    const res = await postAPI(url, data);
    if (res.status !== 200) {
      toaster('error', res.response.data.message);
      return false;
    }
    toaster('success', 'Viewing request rejected.');
    return res.data;
  } catch (err) {
    toaster('error', err.message);
    return false;
  }
};
