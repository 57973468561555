export const CreateCouponValidationSchemaWithExpiry = {
    coupon_name: [
        { rule: "required", message: "Coupon Name required" },
        { rule: "maxLength", maxLength: 100, message: "Coupon Name can be 30 characters long" },
    ],
    usage_limit: [
        { rule: "required", message: "Usage Limit required" },
        { rule: "maxLength", maxLength: 8, message: "Max Usage Limit can be 99999999" }
    ],
    coupon_value: [
        { rule: "required", message: "Coupon Value required" },
        { rule: "maxLength", maxLength: 11, message: "Max Coupon Value can be 10000000.00" },
        { rule: "decValue", maxLength: 8, message: "Coupon Value cannot have more than 8 numbers before decimal" }
    ],
    current_start_date: [
        { rule: "required", message: "Start Date required" },
    ],
    total_usage_limit: [
        { rule: "required", message: "Use Per User Required" },
    ],
    // current_end_date: [
    //     { rule: "required", message: "End Date required" },
    // ],
    
}

export const CreateCouponValidationSchemaWithoutExpiry = {
    coupon_name: [
        { rule: "required", message: "Coupon Name required" },
        { rule: "maxLength", maxLength: 100, message: "Coupon Name can be 30 characters long" }
    ],
    usage_limit: [
        { rule: "required", message: "Usage Limit required" },
        { rule: "maxLength", maxLength: 8, message: "Max Usage Limit can be 99999999" }
    ],
    coupon_value: [
        { rule: "required", message: "Coupon Value required" },
        { rule: "maxLength", maxLength: 13, message: "Max Coupon Value can be 1000000000.00" }
    ],
    current_start_date: [
        { rule: "required", message: "Start Date required" },
    ],
    total_usage_limit: [
        { rule: "required", message: "Use Per User Required" },
    ],
   
    
}