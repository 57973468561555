import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchFilter, setSearchLocation } from '../../../Store/actions/LandingPageAction';
import { Loader, AutoCompleteAddress } from '../../../App/components';
import { logger } from '../../../Utils';

const log = logger("HeroSection");
const HeroSection = (props) => {
    const searchLocation = useSelector(state => state.landingPageReducer.getSearchLocation) || '';
    const getSearchFilter = useSelector(state => state.landingPageReducer?.getSearchFilter) || null;
    const locations = useSelector(state => state.landingPageReducer.getLocationList) || [];
    const [location, setLocation] = useState('')
    // const [address, setAddress] = useState('');
    const dispatch = useDispatch();
    
    useEffect(() => {
        setLocation('')
    },[])
    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     // if (searchLocation !== '') { }
    //     log.info("searchLocation", searchLocation);
    //     props.history.push('/search-result');
    // }
    const handleCapsuleClick = (location) => {
        dispatch(setSearchLocation(location.city_details));
        setLocation(location.city_details)
        const latValue = null, lngValue = null;
		dispatch(setSearchFilter({...getSearchFilter, city_address_or_location: location.city_details, search_term: location.city_details, latitude: latValue, longitude: lngValue}))
        props.history.push('/search-result');
    }
    
    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        log.info("address", address);
        // setAddress(address);
        dispatch(setSearchLocation(address));
        const latValue = null, lngValue = null;
		dispatch(setSearchFilter({...getSearchFilter, city_address_or_location: address, search_term: address, latitude: latValue, longitude: lngValue}))

        setLocation(address)
        props.history.push('/search-result');
    }
    const handleChange = event => {
        event.target.setAttribute('autocomplete', 'off');
        // dispatch(setSearchLocation(event.target.value))
        setLocation(event.target.value)
        // setAddress(event.target.value);
    }

    return (
        <div className="hero-section">
            <Container>
            <div className="headings-container">
                <div className="main-head">
                    <span className="main-head-line-one">change the way </span>
                    <span className="main-head-line-two mobile">you rent.</span>
                    <span className="desktop">you rent in</span>
                    <span className="main-header-line-three-static desktop">Ontario</span>
                    {/*
                    <ul className="cities main-head-line-two">
                        <li className="city-item">thunder bay</li>
                        <li className="city-item">kitchener</li>
                        <li className="city-item">guelph</li>
                        <li className="city-item">brampton</li>
                        <li className="city-item">barrie</li>
	    		        <li className="city-item">ottawa</li>
	    		        <li className="city-item">toronto</li>
	    		        <li className="city-item">kingston</li>
                    </ul>
                    */}
                </div>
                <h2 className="sub-head"><span>create a profile.</span> <span>message landlords. book showings.</span></h2>
            </div>
                {/* <Form onSubmit={e => onSubmit(e)} className="searchbox-container col-md-5">
                    <SearchInput searchValue={searchLocation} setSearchValue={(e) => dispatch(setSearchLocation(e))} placeHolder="where do you want to live?" extraCalss="search-filter mb-4" />
                </Form> */}
                <Col md={7} className="searchbox-container">
                    <AutoCompleteAddress
                        className="searchBar"
                        placeholder="Type to search location"
                        id="searchLocation"
                        state={location}
                        selectPlaceOnEnter
                        updateField={(data) => handleChange(data)}
                        handlePlaceChanged={(data) => handlePlaceChanged(data)}
                    />
                </Col>
                <Row className="landlord-link-container">
                <Link className="link-to-landlord" to='/landlord' id="BTN-im-a-landlord" data-description="Visitor chose I'm a landlord from homepage">i'm a landlord</Link>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(HeroSection);
