import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SidePanel, LargeButton } from "../../../../components";
import { logger, toaster } from "../../../../../Utils";
import {
	pet_policyData,
	// amenitiesData,
	laundryForHouseData,
	laundryForUnitData,
	isSharedBathroomData,
	bathroomsData,
	bedroomsData,
	getUtilitiesAndAmenities
} from "../../../../../Constants/propertyDetails";
import moment from 'moment';
import { validate } from "../../../../../Utils/Validation/validation";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { postAPI } from "../../../../../Api";
import { DescriptionSchema } from "./Schema/DescriptionSchema";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { emptyLeadingSpaceSanitizer } from "../../../../../Utils/DateSanitizer/DateSanitizer";

const log = logger('AdminDescription');
const AdminDescription = (props) => {
	const { isShow, handleClose, apiCallback } = props;
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const [property_id, setProperty_id] = useState("");
	const [property_details_id, setProperty_details_id] = useState("");
	const [descriptionAdmin, setDescription] = useState('');
	const [isPrePopulate, setIsPrePopulate] = useState(false);
	const [disableBtn, setBtnDisable] = useState(false);
	const [amenitiesData, setAmenitiesData] = useState([]);

	useEffect(() => {
		log.info(unitDetails)
		if (unitDetails) {
			setDescription(unitDetails.description)
		}
		(async() => {
            const {amenitiesData=[]}=await getUtilitiesAndAmenities()
            setAmenitiesData(amenitiesData)
          })()
	}, [unitDetails])

	const OnChangeInput = (event) => {
		setDescription(emptyLeadingSpaceSanitizer(event.target.value))
	}

	const PrePopulateData = () => {
		const bedrooms = bedroomsData.map((data) => {
			let title = '';
			if (unitDetails.bedrooms === data.value) {
				title = data.title;
			}
			return title;
		}).join("") + ' bedroom'

		const bathrooms = bathroomsData.map((data) => {
			let title = '';
			if (unitDetails.bathrooms === data.value) {
				title = data.title;
			}
			return title;
		}).join("") + ' bathroom'

		const is_shared_bathroom = isSharedBathroomData.map((data) => {
			let title = '';
			if (unitDetails.is_shared_bathroom === data.value) {
				title = data.title;
			}
			return title;
		}).join("") + ' bathroom'

		const shortAddress = propertyDetails.address_line_1
		const parking_slot = unitDetails.parking_slot
		const available_date = moment(unitDetails.available_date).format("MMMM D, YYYY")
		const price_per_month = unitDetails.price_per_month
		const amenity = amenitiesData.filter((data) => {
			let title = '';
			if (unitDetails.amenities.includes(data.value)) {
				title = data.title;
			}
			return title;
		}).map(function (obj) {
			return obj.title;
		}).join(", ")

		const laundryData = (propertyDetails.property_type === 1) ? laundryForHouseData : laundryForUnitData;
		const laundry = laundryData.map((data) => {
			let title = '';
			if (unitDetails.laundry === data.value) {
				title = 'Laundry ' + data.title;
			}
			return title;
		}).join("")

		const pet_policy = pet_policyData.map((data) => {
			let title = '';
			if (unitDetails.pet_policy === data.value) {
				title = data.title;
			}
			return title;
		}).join("")

		const preDescription = "This beautiful "
			+ ((propertyDetails.property_type === 3) ? is_shared_bathroom : bedrooms + ", " + bathrooms) +
			" place could be your perfect place to call home! \n\n" +
			"Located on " + shortAddress + " , this home is in a great neighbourhood that is close to schools, grocery stores, transit and tons of amenities.\n\n" +
			"The home features lots of space, great natural light, " + (parking_slot !== 0 ? parking_slot : 'No') + " parking spaces, " +
			(amenity ? "Amenities( " + amenity + " ), " : "") + laundry + ", " + pet_policy + ", and could be yours as of " + available_date + " for $" + price_per_month + "/month.\n\n" +
			"If you are interested, please make sure your profile is complete and reach out to schedule a showing. We look forward to hearing from you!\n"
		setIsPrePopulate(true)
		setDescription(preDescription)
	}

	const onSubmit = (event, props) => {
		event.preventDefault();
		if (validate(DescriptionSchema)) {
			setBtnDisable(true)
			const url = '/admin/listing/update/step-4';
			const data = {
				property_id: property_id,
				property_details_id: property_details_id,
				description: descriptionAdmin
			}
			postAPI(url, data).then(response => {
				setBtnDisable(false)
				if (response.status === 200) {
					toaster('success', 'Data Saved Successfully!')
					apiCallback();
					handleClose();
				} else if (response.response.status === 400) {
					log.info(response.response)
					toaster('error', response.response.data.message);
				} else if (response.response.status === 422) {
					toaster('error', response.response.data.message);
				}
			})
		}
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
	}, [props.match.params.property_id, props.match.params.property_details_id]);
	return (<>
		<SidePanel
			isShow={isShow}
			isHide={handleClose}
			title="Edit Description" >
			<form id="Form" onSubmit={onSubmit} autoComplete="off">
				<div className="sidepanel-body filter-tenant">
					<Row>
						<Form.Group as={Col} md="12">
							<Form.Control
								as="textarea"
								rows="8"
								placeholder="This room is perfect for....."
								name="descriptionAdmin"
								id="descriptionAdmin"
								value={descriptionAdmin}
								onChange={(e) => OnChangeInput(e)} />
							<Row>
								{isPrePopulate ?
									<Col md={12}>
										<LargeButton
											title="delete pre-populated description"
											variant="secondary"
											onClick={() => {
												setDescription('')
												setIsPrePopulate(false)
											}}
										/>
									</Col> :
									<Col md={12}>
										<LargeButton
											title="pre-populate"
											variant="dark"
											onClick={() => PrePopulateData()}
											style={{ marginTop: "25px" }} />
									</Col>}
							</Row>
						</Form.Group>

					</Row>
				</div>
				<div className="fixed-bottom d-flex justify-content-end">
					<LargeButton saveLoading={disableBtn} title='save changes' type="submit" style={{ width: '200px', float: "right" }} />
				</div>
			</form>
		</SidePanel>
	</>);
}
export default withRouter(AdminDescription);