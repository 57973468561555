import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import AuthLayout from "../Authentication";
import {
	FormGroup,
	Input,
	Button,
	FormFeedback
} from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../../Constants/endPoints"
import { logger, toaster } from "../../../Utils";

const log = logger("ForgetPasswordFlow");
const ForgetPasswordFlow = () => {
	const history = useHistory();
	const [inputScreen, setInputScreen] = useState(false);
	const [inputType, setInputType] = useState('');

	const RedirectTo = (type) => {
		setInputScreen(true)
		setInputType(type)
	}

	const renderInput = ({ type, id, placeholder, invalidAuth }) => {
		return (
			<FormGroup>
				<Field name={id}>
					{({
						field: { name, value, onChange, onBlur },
						form: { touched, errors },
						meta,
					}) => (
							<div style={{ position: 'relative' }}>
								<Input
									type={type}
									name={name}
									id={id}
									invalid={!!invalidAuth || (!!meta.touched && !!meta.error)}
									placeholder={placeholder}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
								/>


								{invalidAuth && <FormFeedback>{invalidAuth}</FormFeedback>}
								{meta.touched && meta.error && (
									<FormFeedback>{meta.error}</FormFeedback>
								)}
							</div>
						)}
				</Field>
			</FormGroup>
		);
	};


	return (
		<AuthLayout style={{ marginTop: '0px' }} title="Forgot Password?" image="password.svg" history={history}>

			{inputScreen ?
				<>
					<p className="authentication-page-form-links" style={{ marginBottom: "80px", fontWeight: "normal", fontSize: "20px", color: "#454555" }}>
						{`Please provide your ${inputType === 'email' ? 'email address' : 'phone number'} below and we will send you a reset code.`}
					</p>
					<Formik
						initialValues={{
							input_value: ""
						}}
						onSubmit={async (values) => {
							try {
								const data = {
									type: inputType === 'email' ? 2 : 1,
								};

								if (inputType === 'email') {
									data.email = values.input_value
								} else {
									data.phone_number = values.input_value
								}

								const res = await axios.post(
									API_URL + "/forgot-password",
									data
								);
								log.info("res.status", res.status);
								if(res.status === 200){
									history.push({
										pathname: "/verification",
										state: {
											type: inputType,
											data: values.input_value,
											flow: 'forgotPassword'
										}
									});
									toaster('success', res.data.message)
								} 

							} catch (err) {
								if (err.response && err.response.status === 422) {
									if (err.response.data.errors.email) {
										toaster('error', err.response.data.errors.email[0]);
									} else if (err.response.data.errors.phone_number[0]){
										toaster('error', err.response.data.errors.phone_number[0]);
									} else if (err.response.data.status_code === 400) {
										toaster('error', err.response.data.message);
									}
								} else {
									toaster('error', err.response.data.message);
								}
								
							}
						}}
					>
						{({
							values,
							handleSubmit,
							isSubmitting,
						}) => (<>
							<Form onSubmit={handleSubmit} disabled={isSubmitting}>
								<div className='form-wrapper'>
									{renderInput({
										type: inputType === "email" ? "email" : "text",
										id: 'input_value',
										placeholder: "Enter your registered " + inputType
									})}
								</div>

								<Button
									style={{ width: "100%", margin: '40px 0 0' }}
									disabled={isSubmitting}
									type={"submit"}
									className="btn btn-primary text-bold"
								>
									Get Authentication Code
								</Button>
								<Button
									style={{ width: "100%" }}
									className="btn text-bold btn-default"
									onClick={() => setInputScreen(false)}>Cancel</Button>
							</Form>
						</>)}
					</Formik>
				</>
				: <>
					<p className="authentication-page-form-links" style={{ marginBottom: "80px", fontWeight: "normal", fontSize: "20px", color: "#454555" }}>
						How would you like us to send your password reset
          			</p>
					<div className="profile-form">
						<div className="profile-option-box" onClick={() => RedirectTo('phone')}>
							<div className="icon-wrapper">
								<img
									width="51.38px"
									height="55.23px"
									alt="smartphone"
									src={require("../../../_assets/images/smartphone.svg")}
									className="d-inline-block align-top"
								/>
							</div>
							<div>
								<div
									className="option-container-heading"
									id="sms"
									muted
									style={{ textAlign: 'left' }}
								>
									Via SMS
                				</div>
								{/* <div id="number">
									<span className="option-container-subheading">xxx xxx 891</span>
								</div> */}
							</div>
						</div>

						<div className="profile-option-box" onClick={() => RedirectTo('email')} style={{ marginTop: '40px' }}>
							<div className="icon-wrapper">
								<img
									width="51.38px"
									height="55.23px"
									alt="smartphone"
									src={require("../../../_assets/images/mailbox.svg")}
									className="d-inline-block align-top"
								/>
							</div>
							<div>
								<div
									className="option-container-heading"
									id="sms"
									muted
									style={{ textAlign: 'left' }}
								>
									Via email
                				</div>
								{/* <div id="number">
									<span className="option-container-subheading">xxxxxxxlk@gmail.com</span>
								</div> */}
							</div>
						</div>
					</div>
				</>
			}

		</AuthLayout>
	);
};

export default ForgetPasswordFlow;
