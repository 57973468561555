import React, { useState, useEffect } from "react";
import { logger, toaster } from "../../../../Utils";
import { withRouter } from "react-router-dom";
import {
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import InspectionTable from "./InspectionTable";
import { postAPI } from "../../../../Api";
import { useSelector } from "react-redux";
import { ModalBox } from "../../../components";
import Cookies from 'js-cookie';

const log = logger("Inspection");
const Inspection = (props) => {
  const [activeKey, setActiveKey] = useState("new_to_old");
  const [maintenance, setMaintenance] = useState([]);
  const [cancelSuccessModalShow, setCancelSuccessModalShow] = useState(false);
  const role = Number(Cookies.get('role'));
  const id = useSelector((state) => state.auth.id);

  useEffect(() => {
    if (id !== undefined) {
      getMaintenanceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getMaintenanceList = (key) => {
    let url;
    if (role === 2) {
      url = "/landlord/inspections/list";
    } else if (role === 3) {
      url = "/tenant/inspections/list";
    }

    let data;
    let sort;
    if (key === "new_to_old") {
      sort = "desc";
    } else if (key === "old_to_new") {
      sort = "asc";
    } else {
      sort = "desc";
    }
    data = {
      property_id: Number(props.match.params.property_id),
      property_detail_id: Number(props.match.params.property_details_id),
      sort_by: sort,
      // tenant_id:[129]
    };

    postAPI(url, data).then((response) => {
      if (response.data !== undefined) {
        if (response.data.status_code === 200) {
          setMaintenance(response.data.data);
        }
      }
    });
  };
  const handleDropdownSelect = (key) => {
    setActiveKey(key);
    getMaintenanceList(key);
  };

  const handleMoveOut = (req) => {
    let isMoveIn = { viewing_request: "Move In", status_string: "In Progress" };
    return req.some(e => (e.viewing_request === isMoveIn.viewing_request && e.status_string === isMoveIn.status_string));
  }

  const redirectTo = (path) => {
    if (path === "movein") {
      props.history.push(
        `/move-in-inspection/${props.match.params.property_id}/${props.match.params.property_details_id}`
      );
    } else if (path === "moveout") {
      log.info(handleMoveOut(maintenance));
      if (handleMoveOut(maintenance)) {
        toaster('error', 'Please first complete the move in inspection.');
      } else {
        props.history.push(
          `/move-out-inspection/${props.match.params.property_id}/${props.match.params.property_details_id}`
        );
      }
    }
  };

  const deleteItemHandler = (deleteId) => {
    const url = `/landlord/inspections/delete`;
    const data = {
      id: deleteId,
    };
    postAPI(url, data).then((response) => {
      getMaintenanceList();
      setCancelSuccessModalShow(true);
    });
  };

  return (
    <>
      <div className="profile-container">
        <div className="profile-container-box">
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: "20",
              marginBottom: "16px",
            }}
          >
            <Navbar className={"filter-nav " + (role === 3 && maintenance.length === 0 && "d-none")}>
              <Navbar.Collapse className="d-flex justify-content-between">
                <Navbar.Text>posted date:</Navbar.Text>
                <NavDropdown
                  title={activeKey.split("_").join(" ")}
                  id="nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={(e) => handleDropdownSelect("new_to_old")}
                  >
                    posted date: new to old
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={(e) => handleDropdownSelect("old_to_new")}
                  >
                    posted date: old to new
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>
            {/* <button className="create-listing-black-button">
              <span
                className="d-none d-sm-inline-block"
                onClick={() => createListing()}
              >
                create new
              </span>
              <span className="d-md-none d-lg-none d-xl-none">add Listing</span>
            </button> */}
            {role === 2 &&
              <Navbar className="create-lease" >
                <Navbar.Collapse className="d-flex justify-content-center">
                  <NavDropdown title={"Create New"} id="nav-dropdown">
                    <NavDropdown.Item onClick={() => redirectTo("movein")}>
                      Move In Inspection
                  </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => redirectTo("moveout")}>
                      Move Out Inspection
                  </NavDropdown.Item>
                  </NavDropdown>
                </Navbar.Collapse>
              </Navbar>
            }
          </div>

          <InspectionTable
            role={role}
            deleteItem={deleteItemHandler}
            maintenanceList={maintenance}
          />
          <ModalBox
            show={cancelSuccessModalShow}
            onHide={() => {
              setCancelSuccessModalShow(false);
            }}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => {
              setCancelSuccessModalShow(false);
            }}
          >
            <h5 className="created-success-msg">
              you have deleted this request.
            </h5>
          </ModalBox>
        </div>
      </div>
    </>
  );
};

export default withRouter(Inspection);
