import React, { useState, useEffect } from 'react';
import { SidePanel, LargeButton } from '../..';
import { Form, Row, Col, Image, Alert } from 'react-bootstrap';
import { getAPI, postAPI } from '../../../../Api';
import { logger, toaster } from '../../../../Utils';
import '../style.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCreditCheckPaymentId,
  setCreditCheckRequestId,
  setExistingTenancyApplicationId,
  setTenancyApplicationId,
} from '../../../../Store/actions/MessageDetailAction';
import { getCertnCheck, getCertnReport, getCreditRequestIdApi } from './helper';

const log = logger('PurchaseReport');
const PurchaseReport = (props) => {
  const {
    isShow,
    handleClose,
    setCertnPaymentMethod,
    setIs_proceed_for_payment,
    setCertnPurchaseModel,
    conversation_id,
  } = props;
  const [discount, setDiscount] = useState(0);
  const [isPromoCode, setIsPromoCode] = useState(false);
  // const [promoCodeList, setPromoCodeList] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState(null);
  const [coupon_code, setCoupon_code] = useState('');
  const [couponErrorMessage, setCouponErrorMessage] = useState('');

  const dispatch = useDispatch();
  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(
    (state) => state.propertyDetailsReducer.unitDetails
  );
  const property_details_id = unitDetails && unitDetails.id;
  const headerContent = useSelector(
    (state) => state.messageDetailsReducer.headerContent
  );
  const tenant_id = headerContent && headerContent.tenant_id;
  const creditCheckRequestId = useSelector(
    (state) => state.messageDetailsReducer.creditCheckRequestId
  );
  const tenancy_application_id = useSelector(
    (state) => state.messageDetailsReducer.existingTenancyApplicationId
  );

  useEffect(() => {
    getDiscount();
    setCouponErrorMessage('');
    if (!creditCheckRequestId || !tenancy_application_id) {
      getCreditRequestId();
    }
  }, [isShow]);

  const getCreditRequestId = async () => {
    const { requestId, tenancy_application_id } = await getCreditRequestIdApi(
      tenant_id,
      property_details_id,
      property_id
    );
    if (requestId) {
      dispatch(setCreditCheckRequestId(requestId));
    }
    if (tenancy_application_id) {
      dispatch(setExistingTenancyApplicationId(tenancy_application_id));
    }
  };

  const OnChangeInput = (event, state) => {
    // if(event.target.name === 'discount') {
    setCouponErrorMessage('');
    setDiscount(Number(event.target.value));
    state(event.target.value);
    // }
  };

  const getDiscount = (type) => {
    let url = `/landlord/get-payment-summary`;
    if (coupon_code && type !== 'remove') {
      url = `/landlord/get-payment-summary/${coupon_code}`;
    }
    getAPI(url).then((response) => {
      if (response.status !== 200) {
        const res = response.response.data;
        setCouponErrorMessage(res.message);
      } else if (response.status === 200) {
        const res = response.data;
        setPaymentSummary(res.data);
        if (coupon_code && type !== 'remove')
          toaster('success', 'Promo Applied Successfully!');
      }
    });
  };

  const onSubmit = async () => {
    const url = '/landlord/purchase-certn-report';
    const data = {
      property_details_id: property_details_id,
      property_id: property_id,
      tenant_id: tenant_id,
      credit_check_request_id: creditCheckRequestId,
      discount_id: paymentSummary?.discount_id,
    };

    postAPI(url, data).then(async (response) => {
      if (response.status !== 200) {
        const res = response.response.data;
        toaster('error', res.message);
      } else if (response.status === 200) {
        const res = response.data.data;
        log.info('purchase-certn-report response', res);
        dispatch(setCreditCheckPaymentId(res.credit_check_payment_id));
        handleClose();
        setCertnPaymentMethod(true);
        setIs_proceed_for_payment(res.is_proceed_for_payment);

        if (!res.is_proceed_for_payment) {
         await getCertnCheck({
            property_id,
            property_details_id,
            tenancy_application_id: tenancy_application_id,
            conversation_id: headerContent.conversation_id,
            tenant_id,
            credit_check_request_id: creditCheckRequestId,
          });

          setCertnPurchaseModel(true);
        }
      }
    });
  };

  // useEffect(() => {
  //   if (isPromoCode) {
  //     const promoCodeUrl = '/landlord/coupon/promo-code-list';
  //     const data = {
  //       "status": 1, //0: Inactive List, 1: Active List
  //     }
  //     getAPI(promoCodeUrl, data).then(response => {
  //       if (response.status !== 200) {
  //         toaster('error', 'Something went wrong!');
  //       } else if (response.status === 200) {
  //         const res = response.data.data;
  //         setPromoCodeList(res);
  //       }
  //     })
  //   }
  // }, [isPromoCode])

  return (
    <>
      <SidePanel
        isShow={isShow}
        isHide={handleClose}
        title="get a credit & background check"
      >
        <div
          className="sidepanel-body purchase-report mt-2"
          style={{ top: 65 }}
        >
          <Row>
            <Col md="12">
              {/* <Image src={require("../../../../_assets/images/certn.jpeg")} alt='certn logo' className='certn-logo'/> */}
              <p>
                Screening a tenant can be the best way to make sure you are
                renting to a great tenant.
              </p>
              <p>
                By purchasing a report you will get access to a full{' '}
                <b>credit check</b> and a <b>comprehensive background check</b>,
                so you will have the confidence in your choice of tenant.
              </p>
              <p>
                The tenant must consent to the screening, but once they do the
                report is generated instantly! Your credit card will only be
                charged once the tenant agrees and the report is generated.
              </p>
              {/* <h3 className='summary-title'>What You Will Get:</h3>
            <ul className="facility-list">
              <li>- Background &amp; Criminal History Check</li>
              <li>- Full Credit Check </li>
            </ul> */}

              <h2 style={{ marginTop: 50 }}>Payment Summary</h2>
              {/* <p>Review the detailed price</p> */}
              <ul className="list-display">
                <li>
                  <span>Screening Report</span>
                  <span>
                    $
                    {paymentSummary &&
                      paymentSummary.background_and_credit_check}
                  </span>
                </li>
              </ul>
              <ul className="list-display">
                {parseFloat(paymentSummary?.discount_amount) ? (
                  <li>
                    <span>Discount</span>
                    <span>
                      ${paymentSummary && paymentSummary.discount_amount}
                    </span>
                  </li>
                ) : (
                  ''
                )}
                <li>
                  <span>Sub Total</span>
                  <span>${paymentSummary && paymentSummary.subtotal}</span>
                </li>
                <li>
                  <span>
                    HST (+{paymentSummary && paymentSummary.hst_fee}%)
                  </span>
                  <span>${paymentSummary && paymentSummary.hst}</span>
                </li>
              </ul>
              <h3 className="price-display">
                <span style={{ flex: 1 }}>Total</span>$
                {paymentSummary && paymentSummary.grand_total}
              </h3>
              {!isPromoCode ? (
                <span
                  className="promo-code-display"
                  onClick={() => setIsPromoCode(true)}
                >
                  + Apply Promo Code
                </span>
              ) : (
                <>
                  <Row>
                    <Form.Group as={Col} md="12">
                      <Row
                        className="d-flex flex-row"
                        style={{
                          justifyContent: 'space-between',
                          padding: '0 13px',
                        }}
                      >
                        <Form.Label>Enter Coupon Code</Form.Label>
                        <span
                          className="promo-code-display"
                          onClick={() => {
                            setIsPromoCode(false);
                            setCoupon_code('');
                            setCouponErrorMessage('');
                            getDiscount('remove');
                          }}
                        >
                          Cancel
                        </span>
                      </Row>
                      <Form.Control
                        className={couponErrorMessage && 'is-invalid'}
                        type="text"
                        placeholder="RP20"
                        name="coupon_code"
                        id="coupon_code"
                        value={coupon_code}
                        onChange={(e) => OnChangeInput(e, setCoupon_code)}
                        // maxLength={8}
                      />
                      <div
                        style={{ display: 'block' }}
                        class="invalid-feedback"
                      >
                        {couponErrorMessage}
                      </div>
                    </Form.Group>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      paddingRight: '25px',
                    }}
                  >
                    <LargeButton
                      style={{ width: '50%' }}
                      title="Apply Coupon"
                      id="BTN-screen-apply-coupon"
                      dataDescription="Landlord applied coupon for screening"
                      onClick={() => getDiscount()}
                    />
                  </Row>
                </>
              )}
              <div className="message-profile-card mt-2  ">
                <h4 className="message-card-heading">
                  your credit card will not be charged yet
                </h4>
                <hr />
                <p className="info">
                  when purchasing the report you will see a pre-authorized
                  payment on your card, but the payment will not be processed
                  until the tenant has completed all the necessary information
                  for a credit report.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="fixed-bottom">
          <LargeButton
            title="purchase the report now"
            id="BTN-screen-purchase-report-now"
            dataDescription="Landlord clicked through to purchase screening"
            onClick={() => onSubmit()}
          />

          <LargeButton
            variant="secondary"
            title="cancel"
            onClick={handleClose}
          />
        </div>
      </SidePanel>
    </>
  );
};

export default PurchaseReport;
