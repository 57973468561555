import React from "react";
import ContentLoader from 'react-content-loader';

const UnitDescriptionContentLoader = (props) => {
    const loop = 4;
    return (
        <div className="inner-wrapper">
            <h6>
                <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="10%" height="10" />
                </ContentLoader>
            </h6>
            {(() => {
                const options = [];
                for (let i = 0; i < loop; i++) {
                    options.push(<React.Fragment key={i}><RowList /></React.Fragment>);
                }
                return options;
            })()}
        </div>
    )
}
export default UnitDescriptionContentLoader;

const RowList = (props) => {
    return (
        <p style={{ whiteSpace: 'pre-line' }} className="description-property">
            <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
            </ContentLoader>
            <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                <rect x="0" y="0" rx="0" ry="0" width="70%" height="10" />
            </ContentLoader>
        </p>
    )
}
