import React from 'react';
import "../style.scss"
import arrowForward from "../../../../_assets/images/arrow-forward.svg";
import useWindowDimensions from "../../../hooks/WindowSize";
import {Nav} from "react-bootstrap";


const PostCard = ({id, headline, excerpt, placeholder, index, cardType, cardUrl}) => {
    const {width} = useWindowDimensions();
    const limitExcerptLength = (excerpt) => {
        if (excerpt != null && excerpt.length > 70) {
            return excerpt.slice(0, 70) + "..."
        }
        return excerpt
    }

    return (
        <div className={`${cardType+(index + 1)} ${cardType}Card`}>
            <div className={"cardImage"}>
                {cardUrl !== null ? (
                  <a href={cardUrl}>
                    <img src={placeholder} alt="" className={"blogPostImg"}/>
                  </a>
                ) : (
                  <img src={placeholder} alt="" className={"blogPostImg"}/>
                )}
            </div>

            <div className={"postContentCont"}>
                <div className="postTitle">
                    {cardUrl !== null ? (
                      <a href={cardUrl}>{headline}</a>
                    ) : (
                      <>
                       {headline}
                      </>
                    )}
                </div>
                <div className="postBody">
                    {limitExcerptLength(excerpt)}
                </div>
                {cardUrl === null ? (
                  <p className={"viewMoreText"}
                  >
                      <Nav.Link
                          className="viewMoreAnchor"
                          href={`/resources/blog/preview/${id}`}
                      >
                          read more
                          &nbsp;
                          <img src={arrowForward} alt="read more"/>
                      </Nav.Link>
                  </p>
                ) : ( <></> )}
            </div>

        </div>
    );
};

export default PostCard;