export const RentedHomePastValidation = {
    full_address: [
        { rule: "required", message: "Address required" },
    ],
    past_start_date: [
        { rule: "required", message: "Start date required" },
    ],
    past_end_date: [
        { rule: "required", message: "End Date required" },
    ],
    // landlord_email: [
    //     { rule: "required", message: "Landlord email required" },
    //     { rule: "email", message: "Landlord email is invalid" }
    // ],
    // landlord_phone: [
    //     { rule: "required", message: "Landlord phone number required" },
    //     { rule: "phone", length: 10, message: "Phone number must be of 10 digits" },
    // ],
    monthly_rent: [
        { rule: "required", message: "Monthly rent required" },
    ],
    landlord_name: [
        { rule: "required", message: "Landlord name required" },
    ],
}