import {
    GET_ACTIVE_USER_DATA,
    GET_INACTIVE_USER_DATA,
    SET_ACTIVE_USER_CURRENT_PAGE,
    SET_INACTIVE_USER_CURRENT_PAGE,
    SET_CURRENT_PAGE,
    SET_ACTIVE_USER_FILTERS,
    SET_INACTIVE_USER_FILTERS,
    SET_USER_STATUS,
    GET_FILTERS,
    GET_TABLEDATA
} from '../actions/Action';

export const initialState = {
    activeUserTableData: [],
    inactiveUserTableData: [],
    tableData: { data: [] },
    activeUserCurrentPage: 1,
    inactiveUserCurrentPage: 1,
    currentPage: 1,
    activeUserFilters: {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
    },
    inactiveUserFilters: {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
    },
    userStatus: 'active',
    filters: {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
    }

}

export default function UserReducer(state = initialState, action) {
    const handlers = {
        [GET_ACTIVE_USER_DATA]: (state) => ({
            ...state,
            activeUserTableData: action.activeUserTableData,
        }),
        [GET_INACTIVE_USER_DATA]: (state) => ({
            ...state,
            inactiveUserTableData: action.inactiveUserTableData,
        }),
        [SET_ACTIVE_USER_CURRENT_PAGE]: (state) => ({
            ...state,
            activeUserCurrentPage: action.activeUserCurrentPage,
        }),
        [SET_INACTIVE_USER_CURRENT_PAGE]: (state) => ({
            ...state,
            inactiveUserCurrentPage: action.inactiveUserCurrentPage,
        }),
        [SET_ACTIVE_USER_FILTERS]: (state) => ({
            ...state,
            activeUserFilters: action.activeUserFilters,
        }),
        [SET_INACTIVE_USER_FILTERS]: (state) => ({
            ...state,
            inactiveUserFilters: action.inactiveUserFilters,
        }),

        [SET_USER_STATUS]: (state) => ({
            ...state,
            userStatus: action.userStatus,
        }),
        [GET_FILTERS]: (state) => ({
            ...state,
            filters: state.userStatus === 'inactive' ? state.inactiveUserFilters : state.activeUserFilters,
        }),
        [GET_TABLEDATA]: (state) => ({
            ...state,
            tableData: state.userStatus === 'inactive' ? state.inactiveUserTableData : state.activeUserTableData,
        }),
        [SET_CURRENT_PAGE]: (state) => ({
            ...state,
            currentPage: state.userStatus === 'inactive' ? state.inactiveUserCurrentPage : state.activeUserCurrentPage,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}