import React from "react";
import { Card, Col } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const ListBlockContentLoader = (props) => {
    const { grid, type, loop = 4 } = props;
    return (
        <>
            {(() => {
                const options = [];
                for (let i = 0; i < loop; i++) {
                    options.push(<React.Fragment key={i}><RowList grid={grid} type={type} /></React.Fragment>);
                }
                return options;
            })()}
        </>
    )
}
export default ListBlockContentLoader;


const RowList = (props) => {
    const { grid, type } = props;
    return (
        <Col lg={grid ? grid : 3} md={4} sm={6} xs={12} style={{ padding: type !== 'list' ? '5px' : '5px 0', }}>
            <Card className={type === 'list' ? "display-card list" : "display-card"}>
                <div className="card-header-wrapper">
                    <Card.Img variant="top" src={require("../../../../../../../_assets/images/placeholder.jpg")} />
                    <div className="top-details-wrapper">

                        {type !== 'list' &&
                            <div>
                                <ContentLoader height={35} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="35" />
                                </ContentLoader>
                            </div>
                        }
                        {type !== 'list' &&
                            <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                <rect x="150" y="0" rx="0" ry="0" width="100%" height="15" />
                            </ContentLoader>
                        }
                    </div>
                </div>

                <Card.Body>
                    {type !== 'list' ?
                        <div className="status-wrapper">
                            <span className='unverified'>
                                <ContentLoader height={10} style={{ width: '60%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                    <rect x="0" y="0" rx="0" ry="0" width="60%" height="10" />
                                </ContentLoader>
                            </span>
                        </div> : ''}
                    <h2 className="property-title">
                        <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
                        </ContentLoader>
                    </h2>
                    <div className="details-wrapper">
                        <div style={{ width: '100%' }}>
                            <span className="specification">
                                <ContentLoader height={10} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
                                </ContentLoader>
                            </span><br />
                            {type === 'list' &&
                                <span className="verified">
                                    <ContentLoader height={10} style={{ width: '60%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="60%" height="10" />
                                    </ContentLoader>
                                </span>
                            }
                        </div>
                        {type === 'list' &&
                            <div className="icon-wrapper">
                                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                    <rect x="150" y="0" rx="0" ry="0" width="100%" height="15" />
                                </ContentLoader>
                            </div>
                        }

                        {type === 'list' &&
                            <div className="icon-wrapper">
                                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                                    <rect x="150" y="0" rx="0" ry="0" width="100%" height="15" />
                                </ContentLoader>
                            </div>}

                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}