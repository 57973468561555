import React, { Component } from "react";
import AppLayout from "../../AppLayout";
import "./style.scss";
import "../../Profile/style.scss";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import PageTitleHeader from "../../PageTitleHeader";
import ModalBox from "../../ModalBox";
import LargeButton from "../../Buttons/LargeButton";
import { Link, withRouter } from "react-router-dom";
import { validate } from "../../../../Utils/Validation/validation";
import { ChangePasswordValidationSchema } from "./ChangePasswordValidationSchema";
import { changePasswordAPI } from "../../../../Store/api/changePassword-api";
import { logger, toaster } from "../../../../Utils/";

const log = logger("ChangePassword");
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalText: "",
      old_password: "",
      new_password: "",
      repeat_password: "",
      redirectBack: false,
      hiddenOld: true,
      hiddenNew: true,
      hiddenRepeat: true,
    };
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  onFormChange = async (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  // clearFormData = () => {
  //   document.getElementById('old_password').value = ''
  //   document.getElementById('new_password').value = ''
  //   document.getElementById('repeat_password').value = ''
  // }

  onSubmitClick = async (event) => {

    event.preventDefault();

    // let passArr = []

    // const formData = new FormData(document.querySelector('form'))
    // for (let pair of formData.entries()) {
    //   passArr.push(pair[1])
    // }
    // let old_password = passArr[0]
    // let new_password = passArr[1]
    // let repeat_password = passArr[2]
    // console.log(passArr)
    if (validate(ChangePasswordValidationSchema)) {
      const { old_password, repeat_password, new_password } = this.state;
      if (old_password && repeat_password === new_password) {
        const body = {
          old_password,
          new_password,
          repeat_password,
        };
        changePasswordAPI(body, this.props)
        this.setState({
          old_password: '',
          new_password: '',
          repeat_password: ''
        })
        // this.clearFormData()
      } else if (repeat_password !== new_password) {
        toaster('error', "Repeat password does not match.");
      }
    }
  };

  toggleModal = async () => {
    if (this.state.redirectBack) {
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      });
      this.props.history.push("/profile");
    } else {
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      });
    }
  };

  togglePassword = (type) => {
    if (type === 'old') {
      this.setState({
        hiddenOld: !this.state.hiddenOld,
      });
    } else if (type === 'new') {
      this.setState({
        hiddenNew: !this.state.hiddenNew,
      });
    }
    else if (type === 'repeat') {
      this.setState({
        hiddenRepeat: !this.state.hiddenRepeat,
      });
    }
  };

  redirectToForgotPassword = () => {
    this.props.history.push('/forgot-password')
  }

  render() {
    // const {isDesktop} = this.state;

    return (
      <>
        <AppLayout>
          <div className="profile">
            <PageTitleHeader
              title="edit profile"

              isArrow={true}
              onClick={() => this.props.history.goBack()}
            />

            <div className="profile-container">
              <div className="profile-container-box">
                <div className="inner-wrapper">
                  <div className="profile-item">
                    <div className="image-wrapper">
                      <img
                        width="67.08"
                        height="69.99"
                        alt="change"
                        src={require("../../../../_assets/images/change.png")}
                        className="d-inline-block align-top"
                      />
                      <span className="bl-img-title navbar-brand-name">
                        Change Password
                      </span>
                    </div>

                    <Form
                      className="profile-form"
                      id="Form"
                      onSubmit={this.onSubmitClick}
                    >
                      <Form.Group></Form.Group>
                      <Form.Group style={{ position: "relative" }}>
                        <Form.Label>
                          Old Password
                          <span onClick={this.redirectToForgotPassword} className="fp-link">
                            <u>Forgot Your Password</u>
                          </span>
                        </Form.Label>

                        <Form.Control
                          className=" bg-pf-ip profile-input "
                          type={this.state.hiddenOld ? "password" : "text"}
                          id="old_password"
                          name="old_password"
                          placeholder="Input Here"
                          onChange={this.onFormChange}
                          value={this.state.old_password}
                        />
                        <p className="password-eye">
                          {this.state.old_password && (
                            <img
                              width="20"
                              onClick={() => this.togglePassword("old")}
                              height="20"
                              src={require("../../../../_assets/images/" +
                                (this.state.hiddenOld ? "show.svg" : "hide.svg"))}
                              alt="eyeIcon"
                            />
                          )}
                        </p>
                      </Form.Group>

                      <Form.Group style={{ position: "relative" }}>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          className="bg-pf-ip profile-input"
                          type={this.state.hiddenNew ? "password" : "text"}
                          id="new_password"
                          name="new_password"
                          placeholder="Input Here"
                          onChange={this.onFormChange}
                          value={this.state.new_password}
                        />
                        <p className="password-eye">
                          {this.state.new_password && (
                            <img
                              width="20"
                              onClick={() => this.togglePassword("new")}
                              height="20"
                              src={require("../../../../_assets/images/" +
                                (this.state.hiddenNew ? "show.svg" : "hide.svg"))}
                              alt="eyeIcon"
                            />
                          )}
                        </p>
                      </Form.Group>
                      <Form.Group style={{ position: "relative" }}>
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control
                          className="bg-pf-ip profile-input"
                          type={this.state.hiddenRepeat ? "password" : "text"}
                          id="repeat_password"
                          name="repeat_password"
                          placeholder="Input Here"
                          onChange={this.onFormChange}
                          value={this.state.repeat_password}
                        />
                        <p className="password-eye">
                          {this.state.repeat_password && (
                            <img
                              width="20"
                              onClick={() => this.togglePassword("repeat")}
                              height="20"
                              src={require("../../../../_assets/images/" +
                                (this.state.hiddenRepeat ? "show.svg" : "hide.svg"))}
                              alt="eyeIcon"
                            />
                          )}
                        </p>
                      </Form.Group>

                      <div className="button-wrapper">
                        <LargeButton title="Save changes" type="submit" />

                        <LargeButton
                          variant="secondary"
                          title="Cancel"
                          type="button"
                          onClick={() => this.props.history.goBack()}
                        />
                      </div>
                      <Link to="/forgetchangepassword" className="fp-mb-link">
                        <u>Forgot Your Password</u>
                      </Link>
                    </Form>
                  </div>
                  <ModalBox
                    show={this.state.isModalOpen}
                    onHide={this.toggleModal}
                    size="sm"
                    actionbuttontitle={"got it"}
                    buttonaction={this.toggleModal}
                  >
                    <p>{this.state.modalText}</p>
                  </ModalBox>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </AppLayout>
      </>
    );
  }
}

const select = (state) => ({
  updatedChangePasswordList: state.changePassword.updatedChangePasswordList,
});
export default connect(select)(withRouter(ChangePassword));
