import React from "react";
import "../style.scss";
import { DebounceInput } from 'react-debounce-input';

const SearchInputTwo = (props) => {
    const { searchLocation, setSearch, placeHolder, extraCalss, idOrName } = props;
    return (
        <DebounceInput
            type="text" placeholder={placeHolder ? placeHolder : "Type to search location"} id={idOrName} name={idOrName}
            minLength={3}
            debounceTimeout={1000}
            className={"table-search-filter " + extraCalss}
            value={searchLocation === null ? "" : searchLocation}
            onChange={event => setSearch(event.target.value)} />
    );
}

export default SearchInputTwo;
