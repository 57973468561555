import React, {useState, useEffect} from "react";
import { CancelButton, LargeButton, SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";
import { validate } from "../../../../Utils";
import { ValidationSchemaLandlord, ValidationSchemaTenant } from "../Repairs/ValidationSchema";
import { Form, Image } from "react-bootstrap";

const ProvideSignature = (props) => {
    const { isShow, handleClose, sign, setLandlordSign, landlordSign, setTenantSign, tenantSign, setSign, hideBackButton, handleSubmit } = props;
    const [tenant_full_name_sign_LeaseUrl, setTenantFullNameUrl] = useState(null);
    const [landlord_full_name_sign_LeaseUrl, setLandlordFullNameUrl] = useState(null);

    console.log(tenantSign)

    const createLandlordSignImage = (value) => {
        const textToImage = require('text-to-image');
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        }).then(function (dataUri) {
            setLandlordFullNameUrl(dataUri)
        });
    }
    const createTenantSignImage = (value) => {
        const textToImage = require('text-to-image');
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        }).then(function (dataUri) {
            setTenantFullNameUrl(dataUri)
        });
    }

    const landlordSignHandler = (event) => {
        const re = /^[a-zA-Z ]*$/
        let value = event.target.value
        if(re.test(value)){
            setLandlordSign(event.target.value)
            createLandlordSignImage(event.target.value)
        }
    }
    
    const tenantSignHandler = (event) => {
        const re = /^[a-zA-Z ]*$/
        let value = event.target.value
        if(re.test(value)){
            setTenantSign(event.target.value)
            createTenantSignImage(event.target.value)
        }
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose("addSign")}
            title={
                sign === "landlord"
                    ? "Landlord Signature"
                    : sign === "tenant" && "Tenant Signature"
            }
        >
            <form id="Form">
                <div className="customize-title">
                    <span>Type your name to digitally sign the inspection</span>
                </div>
                <div className="sidepanel-body" style={{ top: "125px" }}>
                    <div className={"d-flex flex-column"}>
                        <div className={"d-flex justify-content-between"}>
                            <span>
                                {sign === "landlord"
                                    ? "Landlord's"
                                    : sign === "tenant" && "Tenant's"}{" "}
                  Full Name
                </span>
                    </div>
                        {sign === "landlord" ? (
                            <input
                                key='landlordSign'
                                type="text"
                                className="add-section-input"
                                id="landlordSign"
                                name="landlordSign"
                                placeholder="John Doe"
                                value={landlordSign}
                                onChange={(e) => landlordSignHandler(e)}
                            />
                        ) : (
                                // sign === "tenant" && (
                                    <input
                                        key='tenantSign'
                                        type="text"
                                        className="add-section-input"
                                        id="tenantSign"
                                        name="tenantSign"
                                        placeholder="John Doe"
                                        value={tenantSign}
                                        onChange={(e) => tenantSignHandler(e)}
                                    />
                                // )
                            )
                        }
                        
                        {sign === 'landlord' && landlord_full_name_sign_LeaseUrl &&
                            <Form.Group className="mt-3 signature">
                                <Form.Label style={{color: "black"}}>Esignature</Form.Label>
                                <Image src={landlord_full_name_sign_LeaseUrl} alt="Sign Image" />
                            </Form.Group>
                        }
                        {sign === 'tenant' && tenant_full_name_sign_LeaseUrl &&
                            <Form.Group className="mt-3 signature">
                                <Form.Label style={{color: "black"}}>Esignature</Form.Label>
                                <Image src={tenant_full_name_sign_LeaseUrl} alt="Sign Image" />
                            </Form.Group>
                        }
                        
                    </div>
                </div>
                <div className="fixed-bottom d-flex justify-content-between">
                    <CancelButton
                        onClick={() => {
                            if (sign === "landlord") {
                                handleClose("addSign");
                            } else if (sign === "tenant") {
                                setSign("landlord");
                            }
                        }}
                        title="back"
                        style={{
                            fontWeight: "bold",
                            visibility: hideBackButton && "hidden",
                        }}
                    />
                    <LargeButton
                        title={"save"}
                        onClick={() => {
                            if (sign === "tenant") {
                                if (validate(ValidationSchemaTenant)) {
                                    handleSubmit();
                                }
                            } else if (sign === "landlord") {
                                if (validate(ValidationSchemaLandlord)) {
                                    setSign("tenant");
                                }
                            }
                        }}
                        style={{
                            width: "150px",
                            height: "36px",
                            padding: "0",
                        }}
                    />
                </div>
            </form>
        </SidePanel>
    </>);
}
export default withRouter(ProvideSignature);