import React from "react";
import { CancelButton, LargeButton, SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";

const CustomizedSection = (props) => {
    const { isShow, handleClose, sectionName, setSectionName, addNewSection } = props;


    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose("customizeSection")}
            title={"Customize Section"}
        >
            <div className="customize-title mb-3">
                <span>Give Name To Your Customized Section</span>
            </div>
            <div className="sidepanel-body" style={{ top: "125px" }}>
                <div className="d-flex flex-column">
                    <span>Name Your Section</span>
                    <input
                        className="add-section-input"
                        type="text"
                        placeholder="Eg. Kitchen"
                        name="sectionName"
                        onChange={(e) => setSectionName(e.target.value)}
                        value={sectionName}
                    />
                </div>
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton
                    onClick={() => handleClose("customizeSection")}
                    title="back"
                    style={{ fontWeight: "bold" }}
                />
                <LargeButton
                    title={"add"}
                    onClick={addNewSection}
                    style={{
                        width: "150px",
                        height: "36px",
                        padding: "0",
                    }}
                />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(CustomizedSection);