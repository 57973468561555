import React, { useState, useEffect } from 'react';
import AppLayout from '../AppLayout/AppLayout';
import { Image, Col } from 'react-bootstrap';

const PageNotFound = (props) => {
    const [pageContent, setPageContent] = useState('Page Not Found');
    
    useEffect(() => {
        if (props.location?.state?.message) {
            setPageContent(props.location?.state?.message);
        }
    }, [props.location]);
    
    return (
        <AppLayout>
            <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                <Image src={require("../../../_assets/images/no-data.svg")} />
                <p style={{ fontSize: '46px', color: '#979797' }}>{pageContent}</p>
            </Col>
        </AppLayout>
    )
}

export default PageNotFound;