import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container} from "react-bootstrap";
import { logger } from "../../Utils";
import { ModalBox, PDFViewerComponent } from "../../App/components";
import Layout from "../components/Layout";
import ArticlesList from "./articlesList";
import PartnersList from "./partnersList";
import FormsList from "./formsList";
import { getResourceData, responsive, getcardNum } from "./helpers";
import "./style.scss";
import { Helmet } from "react-helmet";

const log = logger("TenentResource");
const TenentResource = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [articlesList, setArticlesList] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const [partnersList, setPartners] = useState([]);
  const [numberOfCards, setNumberOfCards] = useState(3);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const data = {"category":"Tenant Forms"};
    setShowLoader(true);
    const articalsList = await getResourceData(`/cms/articles/list`, data);
    const formsList = await getResourceData(`/cms/forms/list`, data);
    const partnersList = await getResourceData(`/cms/partners/list`, data);
    setShowLoader(false);
    setArticlesList(articalsList);
    setFormsList(formsList);
    setPartners(partnersList);
  };

  const resize = () => {
    const { cardNum, isDesktop } = getcardNum;
    setNumberOfCards(cardNum);
    setIsDesktop(isDesktop);
  };

  return (
    <>
    <Helmet>
      <title>Tenant Resources | Rent Panda</title>
      <meta name="description" content="Rent Panda provides free rental resources for tenants. 
      Browse through our blogs, advice, and forms to find what you're looking for." />
    </Helmet>
    <div className="ResourcesPage">
      <Layout newHeader={true}>
        <div className="ResourcesPage-header">
          <Container>
            <div className="main-heading text-left text-md-center text-transform-lowercase">
              tenant resource center
            </div>
            <div className="sub-heading text-left text-md-center">
              Tips, expert advice, key forms, preferred partners and answers to
              all your questions.
            </div>
          </Container>
        </div>

        <div className="ResourcesPage-contentBox">
          <ArticlesList
            articlesList={articlesList}
            colCount={12}
            showLoader={showLoader}
            responsive={responsive}
            isDesktop={isDesktop}
            numberOfCards={numberOfCards}
          />
          <FormsList
            formsList={formsList}
            colCount={12}
            showLoader={showLoader}
            responsive={responsive}
            isDesktop={isDesktop}
            numberOfCards={numberOfCards}
            setPdfModal={(val) => setPdfModal(val)}
            setPdfUrl={(pdf) => setPdfUrl(pdf)}
            userType={'tenant'}
          />

          <PartnersList
            partnersList={partnersList}
            colCount={12}
            showLoader={showLoader}
            responsive={responsive}
            isDesktop={isDesktop}
            numberOfCards={numberOfCards}
          />
        </div>
      </Layout>

      <ModalBox
        show={pdfModal}
        onHide={() => setPdfModal(false)}
        size="xl"
        className="cu-full-modal"
      >
        <PDFViewerComponent PDFUrl={pdfUrl && pdfUrl} />
      </ModalBox>
    </div>
    </>
  );
};

export default withRouter(TenentResource);
