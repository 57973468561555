export const GET_ACTIVE_CMS_FILTER = 'GET_ACTIVE_CMS_FILTER';
export const GET_CMS_CLICKED_DATA = 'GET_CMS_CLICKED_DATA';
export const GET_FORMS_PDF_URL = 'GET_FORMS_PDF_URL';

export function activeCMSFilter(data) {
    return function (dispatch) {
        dispatch({ type: GET_ACTIVE_CMS_FILTER, activeCMSFilterStatus: data });
    };
}

export function cmsClickedData(data) {
    return function (dispatch) {
        dispatch({ type: GET_CMS_CLICKED_DATA, clickedData: data });
    };
}

export function formsPdfUrlAction(data) {
    return function (dispatch) {
        dispatch({ type: GET_FORMS_PDF_URL, formsPdfUrl: data });
    };
}