import React from "react";
import {
  SidePanel,
  LargeButton
} from "../../components";
import { Form, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { logger } from "../../../Utils";
import "./style.scss";

const log = logger("ViewCoupon");
const ViewCoupon = (props) => {
  const {
    isShow,
    handleClose,
    couponData,
    deleteCoupon,
    deactivateCoupon,
    editCoupon,
    couponType,
    activateCoupon
  } = props;

  console.log(props)

  return (
    <>
      <SidePanel isShow={isShow} isHide={handleClose} title={"Coupon Details"}>
        {console.log("couponData", couponData)}
        <div className="coupon-sidepanel-body mt-2">
          <Row className="row-spacing">
            <Form.Group as={Col} md="12">
              <Form.Label>coupon name</Form.Label>
              <span>{couponData && couponData.coupon_name}</span>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>who can use this coupon</Form.Label>
              <span>{couponData && couponData.use_by_info.status_name}</span>
            </Form.Group>
          </Row>
          <Row className="row-spacing">
            <Form.Group as={Col} md="6">
              <Form.Label>Total number of uses</Form.Label>
              <span>
                {couponData && couponData.usage_limit}
              </span>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>use per user</Form.Label>
              <span>{couponData && couponData.total_usage_limit}</span>
            </Form.Group>
          </Row>
          <Row className="row-spacing">
            <Form.Group as={Col} md="6">
              <Form.Label>start date</Form.Label>
              <span>
                {couponData && moment(couponData.start_date).format("D/M/YYYY")}
              </span>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>expiry date</Form.Label>
              <span>
                {couponData &&
                  couponData.expiry_date === null  ? "---" : couponData && (moment(couponData.expiry_date).format("D/M/YYYY"))}
              </span>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>coupon code</Form.Label>
              <span>{couponData && couponData.coupon_code}</span>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>coupon value</Form.Label>
              <span>{couponData && couponData.coupon_value}</span>
            </Form.Group>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <LargeButton
                variant={"dark"}
                onClick={() => deleteCoupon(couponData.id)}
                title={"delete coupon"}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </div>

        <div className={"fixed-bottom"}>
          <Row>
            <Col md={6}>
              {couponType === "active" && (
                <LargeButton
                  onClick={() => deactivateCoupon(couponData.id)}
                  title={"deactivate coupon"}
                  style={{
                    width: "200px",
                    float: "right",
                    background: "#979797",
                    fontSize: "15px",
                  }}
                />
              )}
            </Col>
            {couponType === "active" && (
              <Col md={6}>
                <LargeButton
                  onClick={() => editCoupon(couponData)}
                  title={"edit coupon"}
                  style={{ width: "200px", float: "right", fontSize: "15px" }}
                />
              </Col>
            )}
            {couponType === "inactive" && (
              <Col md={6}>
                <LargeButton
                  onClick={() => activateCoupon(couponData)}
                  title={"activate coupon"}
                  style={{ width: "200px", float: "right", fontSize: "15px" }}
                />
              </Col>
            )}
          </Row>
        </div>
      </SidePanel>
    </>
  );
};

export default ViewCoupon;
