import { setExistingShowingRequestId, setExistingShowingSentBy, setExistingTenancyApplicationId, setIsLeaseExists, setIsTenancyApplicationReceived, setLeaseData, setViewingRequestId } from "../../../Store/actions/MessageDetailAction";

export const updateRedux = (dispatch, messagesRes) => {
    dispatch(setLeaseData(messagesRes.leaseDetail));
    dispatch(setExistingShowingRequestId(messagesRes.existingShowingRequestId));
    dispatch(setExistingShowingSentBy(messagesRes.existingShowingSentBy));
    dispatch(setExistingTenancyApplicationId(messagesRes.existingTenancyApplicationId));
    dispatch(setIsTenancyApplicationReceived(messagesRes.isTenancyApplicationReceived));
    dispatch(setIsLeaseExists(messagesRes.isLeaseExists));
    dispatch(setViewingRequestId(messagesRes.viewingRequestId));
}