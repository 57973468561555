import React, {useEffect, useState} from "react";
import './style.scss';
import {Helmet} from "react-helmet";
import HeaderNew from "../components/HeaderLandingPage/HeaderNew";
import HomeHero from "./HomeHero/HomeHero";
import CardDisplay from "../../App/components/CardDisplay/CardDisplay";
import DiyRentTools from "./DIYRentTools/DIYRentTools";
import LLReviews from "./LLReviews/LLReviews";
import ServicesSection from "./ServicesSection/ServicesSection";
import FooterLandingPage from "../components/FooterLandingPage";
import FreeRentAssessment from "../../App/Landlord/CreateListing/Steps/FreeRentAssessment";
import {getResourceData} from "../Resources/helpers";
import { Link } from "react-router-dom";
import SideHowItWorks from "./SideHowItWorks/SideHowItWorks";
import YouAreCoveredSection from "./YouAreCoveredSection/YouAreCoveredSection";
import TrustedBySection from "./TrustedBySection/TrustedBySection";


const Homepage = () => {
    const [blogPosts, setBlogPosts] = useState([])
    const [resources, setResources] = useState([])
    const [showSideHowItWorks, setShowSideHowItWorks] = useState(false)
    const [showFreeRentAssessment, setShowFreeRentAssessment] = useState(false)
    const [showFreeRentAssessmentTP, setShowFreeRentAssessmentTP] = useState(false)
    const [showFreeRentAssessmentPM, setShowFreeRentAssessmentPM] = useState(false)
    const [free_rent_assessment_id, setFree_rent_assessment_id] = useState(false)

    const getInitialData = async () => {
        // Note from Jack: I did not write this. I don't know why it is structured this way.
        const data = {"category": "Landlord Forms"}
        const blogData = await getResourceData(`/cms/articles/list`, data)
        const formsList = await getResourceData(`/cms/featured/list`, data)

        // May want to not return 43 results on the homepage, for first contentful paint
        setBlogPosts([blogData[0], blogData[1], blogData[2]])
        setResources([formsList[0], formsList[1], formsList[2]])
    }
    
    const freeTP = () => {
        setShowFreeRentAssessmentTP(true)
        setShowFreeRentAssessment(true)
    }
    const freePM = () => {
        setShowFreeRentAssessmentPM(true)
        setShowFreeRentAssessment(true)
    }
    const handleFreeClose = () => {
        setShowFreeRentAssessmentPM(false)
        setShowFreeRentAssessmentTP(false)
        setShowFreeRentAssessment(false)
    }

    useEffect(() => {
        getInitialData()
    }, [])

    const gridInfoBlog = {
        classTag: "blogArea",
        sectionTitle: "recent blogs",
        header: "blogHeader",
        cardType: "blogPost"
    }

    const gridInfoResource = {
        classTag: "resourceArea",
        sectionTitle: "featured on",
        header: "resourceHeader",
        cardType: "resource",
        extraClass: "featuredOnExtra",
    }

    const leasingCardActions = {
        showSide: () => setShowSideHowItWorks(true),
        closeSide: () => setShowSideHowItWorks(false)
    }

    const screeningLink = "https://screening.rentpanda.ca"
    const propertyManagementLink = "https://groverentals.ca/"
    const listRentalLink = "/signup/landlord"


    return (
        <>
            <Helmet>
                <title>
                    Home | Rent Panda
                </title>
                <meta name="description" content="Rent Panda is a free property rental
                platform for tenants and landlords. Find houses, rooms, condos, or apartments
                for rent with verified listings." />
            </Helmet>

            <SideHowItWorks
                show={showSideHowItWorks}
                handleClose={() => setShowSideHowItWorks(false)}
                openRentForm={() => setShowFreeRentAssessment(true)}
            />

            <FreeRentAssessment
                isShow={showFreeRentAssessment}
                handleClose={handleFreeClose}
                isTP={showFreeRentAssessmentTP}
                isPM={showFreeRentAssessmentPM}
                setFree_rent_assessment_id={setFree_rent_assessment_id}
                landlordLanding={true}
                tabsSection={true}
            />

            <HeaderNew />

            <div className="landingPage">
                <HomeHero />
                <ServicesSection 
                  openRentFormPM={freePM}
                  openRentFormTP={freeTP}
                  />
                <YouAreCoveredSection />
                <TrustedBySection />
        
                <LLReviews />
        
                <CardDisplay gridData={gridInfoResource} cardItems={resources} className="featured-on-posts" />
        
                <CardDisplay gridData={gridInfoBlog} cardItems={blogPosts} />
        
                <div className="tenantQuestion">
                        <div className="tQuestionCont">
                            <h3 className={"h3-Homepage"}>are you a tenant?</h3>
                            <p>It's always free to look at properties posted by verified landlords. Go to our listings section here.</p>
                            <button className="startSearching">
                                <Link to={"/search-result"}>
                                    start searching
                                </Link>
                            </button>
                        </div>
                </div>

                <div className="footerCont">
                    <FooterLandingPage />
                </div>
            </div>
        </>
    )
};

export default Homepage;