import React, { useState } from "react";
import { logger } from "../../../Utils";
import LargeButton from "../Buttons/LargeButton";
import ModalBox from "../ModalBox";
import PhoneNumberFormat from "../PhoneNumberFormat";
import { getAPI } from '../../../Api';

const log = logger('TenantDetailBlock')
const TenantDetailBlock = (props) => {
	const { leaseData, heading, extraClass, apiCallback } = props;

	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [action, setAction] = useState("");
	const [buttonTitle, setButtonTitle] = useState("");
	const [profile, setProfile] = useState(null);

	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === 'switch') {
			onClickSwitch(data)
			setButtonTitle('yes, switch profile');
		} else if (action === 'suspend' || action === 'active') {
			suspendUser(data);
			if (action === 'suspend') {
				setButtonTitle('yes, suspend');
			} else if (action === 'active') {
				setButtonTitle('yes, activate');
			}
		}
	}
	const onClickSwitch = (data) => {
		setProfile(data);
	}
	const suspendUser = (userData) => {
		setProfile(userData);
	}
	const goBack = () => {
		let url = "";
		if (action === "suspend" || action === "active") {
			if (action === "suspend") {
				url = '/admin/users/suspend/' + profile.id;
			} else if (action === "active") {
				url = '/admin/users/activate/' + profile.id;
			}
			getAPI(url).then(res => {
				setOpenSwitchModal(false);
				apiCallback()
			})
		}
	}

	return (
		<div className='col-md-12 card-detail'>
			{(leaseData && leaseData.LeaseTenants) && leaseData.LeaseTenants.length !== 0 && <h4 className={'user-name text-left ' + extraClass}>{heading}</h4>}
			{(leaseData && leaseData.LeaseTenants) &&
				(leaseData.LeaseTenants.length !== 0 ?
					<>
						{
							leaseData.LeaseTenants.filter(data => Number(data.is_primary) === 1).map((filteredData, i) => {
								return <React.Fragment key={i}>
									<div className='detail-left'>
										<span className='tenant-name' style={{ display: 'block' }}>{filteredData.name}</span>
										<div className='contact-details'>
											<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/mobile-icon.svg')} alt='mobile-icon' />
                                        Phone: <span className='title-text'><PhoneNumberFormat phoneNumber={filteredData.phone_number} /> </span>
											</span>
											<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/message-icon.svg')} alt='message-icon' />
                                        Email: <span className='title-text'>{filteredData.email}</span>
											</span>
											<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/id-icon.svg')} alt='message-icon' />
                                        ID: <span className='title-text'>{filteredData.id ? filteredData.id : 'None'}</span>
											</span>
										</div>
									</div>
									<div className='detail-right'>
										{(filteredData && filteredData.is_active !== 'Suspended') &&
											<LargeButton onClick={() => toggleModal(filteredData, 'suspend')} className='suspend-btn' variant={'dark'} title='suspend user' style={{ width: '177px', float: 'right' }} />}
										{(filteredData && filteredData.is_active !== 'Active') &&
											<LargeButton onClick={() => toggleModal(filteredData, 'active')} className='suspend-btn' variant={'dark'} title='activate' style={{ width: '177px', float: 'right' }} />}
									</div>
								</React.Fragment>
							})
						}
						{leaseData.LeaseTenants.filter(data => Number(data.is_primary) === 1).length === 0 && <h4 className='text-left'>There is no primary tenant found in list</h4>}
					</>
					: <h4 className='text-left'>There is no tenant added</h4>)}

			{(leaseData && leaseData.lease_tenants) && leaseData.lease_tenants.length !== 0 && <h4 className={'user-name text-left ' + extraClass}>{heading}</h4>}
			{(leaseData && leaseData.lease_tenants) &&
				(leaseData.lease_tenants.length !== 0 ?
					<>
						{
							leaseData.lease_tenants.filter(data => Number(data.is_primary) === 1).map((filteredData, i) => {
								return <React.Fragment key={i}>
									<span className='tenant-name' style={{ display: 'block' }}>{filteredData.name}</span>
									<div className='contact-details'>
										<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/mobile-icon.svg')} alt='mobile-icon' />
                                        Phone: <span className='title-text'><PhoneNumberFormat phoneNumber={filteredData.phone_number} /> </span>
										</span>
										<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/message-icon.svg')} alt='message-icon' />
                                        Email: <span className='title-text'>{filteredData.email}</span>
										</span>
										<span className='user-contact-title'><img className='user-icon' src={require('../../../_assets/images/id-icon.svg')} alt='id-icon' />
                                        ID: <span className='title-text'>{filteredData.id ? filteredData.id : 'None'}</span>
										</span>
									</div>
								</React.Fragment>
							})
						}
						{leaseData.lease_tenants.filter(data => Number(data.is_primary) === 1).length === 0 && <h4 className='text-left'>There is no primary tenant found in list</h4>}
					</>
					: <h4 className='text-left'>There is no tenant added</h4>)}
			<ModalBox
				show={openSwitchModal}
				onHide={toggleModal}
				size="sm"
				actionbuttontitle={buttonTitle}
				buttonaction={goBack}
				extraBtnStyle='extraBtnStyle'
			>
				{action === "suspend" &&
					<p className="switching-details">Are you sure to suspend <span className="profile-name">{profile && profile.name}’s?</span> <br />
            They will be unable to apply to any properties within Rent Panda
            </p>}
				{action === "active" &&
					<p className="switching-details">Are you sure to activate <span className="profile-name">{profile && profile.name}’s?</span> <br />
            Their rented property will remain within Rent Panda but other listing will be activated automatically
            </p>}
			</ModalBox>
		</div>
	)

}
export default TenantDetailBlock;