import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { LargeButton, DatePickerComponent, TimePickerComponent } from "../..";
import { personNeedEntry } from '../../../../Constants/NoticeConstants';
import "../style.scss";
import { logger, toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { EntryFormSchema } from "./ValidationSchema/EntryFormSchema";
import { useGlobalState } from "../../../../StateContext";

const log = logger("EnteryForm");
const EnteryForm = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    requestData,
    getParalegal,
    triggerReqTenant,
    onBack,
  } = props;
  const [person, setPerson] = useState();
  const [reason, setReason] = useState();
  const [other, setOther] = useState('');
  const [errors, setErrors] = useState({})
  const [date, setDate] = useState("");
  const [earlyTime, setEarlyTime] = useState("");
  const [latestTime, setLatestTime] = useState("");
  const [description, setDescription] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [globalState, setGlobalState] = useGlobalState()

  log.info("noticeData", noticeData);

  useEffect(() => {
    setGlobalState({ onSubmit: onSubmit })
    return () => {
      setGlobalState({ onSubmit: null })
    }
  }, [])

  const onSubmit = () => {
    setGlobalState({ isSubmitClicked: true })
    setIsSubmitted(true)
    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    setErrors({ reason: !reason, person: !person })
    if (validate(EntryFormSchema) && tenant_id.length !== 0 && reason && person) {
      const url = '/landlord/notice/store-notice';
      // console.log(time)
      const data = {
        "notice_id": noticeData && noticeData.id,
        "property_id": property_id,
        "property_detail_id": property_detail_id,
        "notice_type": 16,
        "person_needing_entry": person,
        "reason_for_entry": reason,
        "other_reason_text": other,
        "date_of_entry": date,
        "time_of_entry": earlyTime,
        'end_time': latestTime,
        "notes": description,
        "tenants": tenant_id,
        "i_want_to": 16
      }

      postAPI(url, data).then(response => {
        if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster('success', 'Data Saved Successfully!');
          viewNotice(notice_id)
        }
      })
    }
  }

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`
    });
  }

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'reason') {
      setReason(Value);
      setErrors({ reason: !Value, person: !person })
    } else if (name === 'person') {
      setPerson(Value);
      setErrors({ reason: !reason, person: !Value })
    }
  }

  const handleDateChange = (date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const handleChangeTime = (time, state) => {
    console.log(time)
    const theDate = new Date(Date.parse(time));
    const timeFormated = theDate.toLocaleTimeString();
    console.log(timeFormated)
    state(timeFormated);
  };

  useEffect(() => {
    setGlobalState({ onSubmit: onSubmit })
    if (isSubmitted)
      validate(EntryFormSchema)
  }, [date, description])

  useEffect(() => {
    if (noticeData && noticeData.notice_type === 16) {
      setPerson(Number(noticeData.person_needing_entry));
      setReason(Number(noticeData.reason_for_entry));
      setOther(noticeData.other_reason_text);
      setDate(moment(noticeData.date_of_entry).toDate());
      setEarlyTime(noticeData.time_of_entry);
      setLatestTime(noticeData.end_time)
      // setTime('9:19:00 PM');
      setDescription(noticeData.notes);
    }
  }, [noticeData]);

  return (<>
    <div className="N-form-card form-pad">
      <div className="d-flex justify-content-between">
        <span className="form-title">Enter The Premises</span>
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          extraClass={'get-paralegal-help-btn'}
          onClick={getParalegal}
        />
      </div>
      <Form id="Form" className="mt-2">
        <Row>
          <Form.Group as={Col} md='10'>
            <Form.Label style={{ color: errors.person ? 'red' : '' }}>Please indicate the person needing entry</Form.Label>
            <ul className="select-options">
              {personNeedEntry.map((data) =>
                <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"person_" + data.value} value={data.value} name="person"
                  className={person === data.value ? "active" : ""}>{data.title}</li>)}
            </ul>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md='10'>
            <Form.Label style={{ color: errors.reason && isSubmitted ? 'red' : '' }}>Reason for entry</Form.Label>
            <ul className="select-options">
              {requestData && requestData.map((data) =>
                <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"reasonOption_" + data.id} value={data.id} name="reason"
                  className={reason === data.id ? "active" : ""}>{data.title}</li>)}

              {(reason === 21) &&
                <li className="other-fild">
                  <Form.Control
                    type='text'
                    name='other'
                    id='other'
                    value={other}
                    placeholder='Clarify "Other"'
                    onChange={(e) => setOther(e.target.value)}
                  />
                </li>}
            </ul>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="6" lg="4">
            <Form.Label>Date Of Entry</Form.Label>
            <DatePickerComponent
              id="date2"
              name="date2"
              format={"dd/MM/yyyy"}
              value={date}
              handleDateChange={(date) => handleDateChange(date)}
              displayText={"Select or type"}
              popperPlacement="bottom-end"
              minDate={new Date()}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" lg="4">
            <Form.Label>Earliest entry time</Form.Label>
            <TimePickerComponent
              name={"timeofentry"}
              id={"timeofentry"}
              hideTimeLimit={true}
              viewDateParam={earlyTime || "08:00:00"}
              handleChangeTime={(e) => handleChangeTime(e, setEarlyTime)}
              placeholder={"Select or type"}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" lg="4">
            <Form.Label>Latest entry time</Form.Label>
            <TimePickerComponent
              name={"timeofentry"}
              id={"timeofentry"}
              hideTimeLimit={true}
              viewDateParam={latestTime || "08:00:00"}
              handleChangeTime={(e) => handleChangeTime(e, setLatestTime)}
              placeholder={"Select or type"}
            />
          </Form.Group>
        </Row>


        <div className="border-separator"></div>
        <div className="mt-2 mb-4">
          <span className="form-title">Additional Notes</span>
        </div>
        <Row>
          <Form.Group as={Col} md='10'>
            {/* <Form.Label>Write Any Additional Notes</Form.Label> */}
            <Form.Control
              as="textarea"
              rows="8"
              placeholder="This is where you can provide all the details you need to about the notice you are sending."
              name="description"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)} />
          </Form.Group>
        </Row>
      </Form>
    </div>
    <div className="container-footer button-extra-margin">
      <Button variant="secondary" onClick={() => onBack()}>
        Back
      </Button>
      <Button variant="success" onClick={() => onSubmit()}>
        Review
      </Button>
    </div>
  </>);
};

export default withRouter(EnteryForm);
