/*global fbq*/
/* The above comment is required in order for the application to compile through LINT */

import toaster from "./toaster";
import ImageUploadAPI from "./ImageUploadAPI";
import logger from "./Logger/Logger";
import { bathroomTitle, bedroomTitle, sharedBathroomTitle } from "./PropertyDetailsData/propertyDetailsReturn";
import { dateFormat, timeLeft } from './DateSanitizer/DateSanitizer';
import { validate } from './Validation/validation';
import { numberFormatSanitizerInt } from './NumberSanitizer/NumberSanitizer';
import LinkedInTag from 'react-linkedin-insight';

const captureEvent = (title, cat, label) => {
    if (process.env.NODE_ENV === 'production') {
      if((typeof window !== 'undefined' &&  window.gtag) )
      {
        window.gtag("event", title, {
            event_category: cat,
            event_label: label
        });
      }

      if(typeof fbq != 'undefined') {
        fbq('trackCustom', label);
      }

      if(label === 'Landlord Sign Up' && typeof process.env.REACT_APP_LINKEDIN_LANDLORD_SIGNUP_CONVERSION_ID != 'undefined' && process.env.REACT_APP_LINKEDIN_LANDLORD_SIGNUP_CONVERSION_ID != null) {
        LinkedInTag.track(process.env.REACT_APP_LINKEDIN_LANDLORD_SIGNUP_CONVERSION_ID);
      }
      if(label === 'Tenant Sign Up' && typeof process.env.REACT_APP_LINKEDIN_TENANT_SIGNUP_CONVERSION_ID != 'undefined' && process.env.REACT_APP_LINKEDIN_TENANT_SIGNUP_CONVERSION_ID != null) {
        LinkedInTag.track(process.env.REACT_APP_LINKEDIN_TENANT_SIGNUP_CONVERSION_ID)
      }
}

}


export const diffDate = (itemOne, itemTwo, direction) => {
  return direction === 'asc'
    ? new Date(itemOne ).getTime() -
        new Date(itemTwo).getTime()
    : new Date(itemTwo).getTime() -
        new Date(itemOne).getTime();
};


export {captureEvent, toaster, ImageUploadAPI, logger, bathroomTitle, bedroomTitle, sharedBathroomTitle, dateFormat, timeLeft, validate, numberFormatSanitizerInt };
