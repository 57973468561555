import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, Card, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { logger } from '../../../../Utils';
import { AppLayout, PageTitleHeader } from '../../../components';
import { getAPI } from '../../../../Api';
import { dateFormat2 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import './style.scss';

const log = logger('InspectionDetails');
const InspectionDetails = (props) => {
	const [user_id, setUserId] = useState('');
	// const [path] = useState('');
	const [inspection_id, setInspectionId] = useState('');
	const [user_type, setUserType] = useState('');
	const [inspectionData, setInspectionData] = useState(null);
	const [accordianId, setAccordionId] = useState(3);
	const back = () => {
		// props.history.push({ pathname: `/profile-detail/${user_type}/${user_id}/inspection`, state: { path: path } });
		props.history.goBack();
	}

	const clickExterior = (id) => {
		const check = id === accordianId ? null : id
		setAccordionId(check);
	}
	useEffect(() => {
		setUserId(props.match.params.user_id);
		setInspectionId(props.match.params.inspection_id);
		setUserType(props.match.params.user_type)
		// setPath(props.location.state.path)
	}, [props.match.params.user_id, props.match.params.inspection_id, props.match.params.user_type]);

	useEffect(() => {
		log.info(user_id, inspection_id)
		if (user_id && inspection_id) {
			if (user_id !== undefined && inspection_id !== undefined) {
				let urlInside = '';
				if (user_type === 'landlord') {
					urlInside = `/admin/users/landlord/inspections/view/${user_id}/${inspection_id}`
				} else {
					urlInside = `/admin/users/tenants/inspections/view/${user_id}/${inspection_id}`
				}
				getAPI(urlInside).then(res => {
					if (res.status === 200) {
						setInspectionData(res.data.data);
					}
				})
			}
		}

	}, [user_id, inspection_id, user_type]);
	return (<>
		<AppLayout>
			<div >
				{inspectionData && inspectionData.type_info &&
					<PageTitleHeader
						isArrow={true}
						onClick={() => back()}
						title={inspectionData.type_info.status_name + ' INSPECTION'}
					/>
				}
			</div>
			<div className='main-lease-container'>
				<Row className='container-detail'>
					<Col md='12'>
						<div className='card-detail'>
							<div className='d-flex justify-content-between'>
								{inspectionData && inspectionData.property &&
									<div className='request-title-right'>
										<h4>{inspectionData.property.address}</h4>
									</div>
								}
								{inspectionData &&
									<div className='request-title-left'>
										<span>Date Completed: {dateFormat2(inspectionData.inspection_date)}</span>
									</div>}
							</div>
							<Row className='maintainanace-form-group mt-4'>
								<Col md={4} className='d-flex'>
									{inspectionData && inspectionData.landlord &&
										<Form.Group className='mr-5'>
											<Form.Label>Landlord Name</Form.Label>
											<ul className={"select-options"}>
												<li className={"active"}>{inspectionData.landlord.name}</li>
											</ul>
										</Form.Group>
									}
									{inspectionData && inspectionData.tenant &&
										<Form.Group>
											<Form.Label>Tenant Name</Form.Label>
											<ul className={"select-options"}>
												<li className={"active"}>{inspectionData.tenant.name}</li>
											</ul>
										</Form.Group>
									}
								</Col>
								<Col md={8}>
									{inspectionData &&
										<Form.Group>
											<Form.Label>General Comments</Form.Label>
											<h5 className='comment'>{inspectionData.comment}</h5>
										</Form.Group>
									}
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				{inspectionData && inspectionData.sections &&
					<Row className='container-detail accordion-row inspection'>
						<Col md='12'>
							<Accordion defaultActiveKey="Exterior">
								{inspectionData.sections.length !== 0 ?
									<>
										{inspectionData.sections.map((section, i) => {
											return <Card className='mb-3' key={'section_' + i}>
												<Card.Header className='d-flex justify-content-between'>
													<span className='accordian-heading'>{section.section_name}</span>
													<Accordion.Toggle as={Button} className='inspection-btn' variant="link" eventKey={section.section_name} onClick={() => clickExterior(section.id)}>
														<img className="next-arrow"
															src={accordianId === section.id ? require('../../../../_assets/images/nextUp.svg') : require('../../../../_assets/images/nextDown.svg')} alt="next" />
													</Accordion.Toggle>
												</Card.Header>
												<Accordion.Collapse eventKey={section.section_name}>
													{section.items && section.items.length !== 0 &&
														<Card.Body className='section-card'>
															<Row>
																{section.items.map((item, idx) => {
																	return < Col md={4} className='mb-3' key={'items_' + idx}>
																		<div className='main-card'>
																			<h4 className='card-main-heading'>{item.item_name}</h4>
																			{item.status_info &&
																				<div className={'status-card ' + item.status_info.status_name}>
																					{item.status_info.status_name === 'Good' && <img alt='good' width={19} height={29} src={require('../../../../_assets/images/star.svg')} />}
																					{item.status_info.status_name === 'Issue' && <img alt='issue' width={19} height={29} src={require('../../../../_assets/images/issue.svg')} />}
																					{item.status_info.status_name === 'Repair' && <img alt='repair' width={19} height={29} src={require('../../../../_assets/images/repair.svg')} />}
																					<h5>{item.status_info.status_name}</h5>
																				</div>
																			}
																			<h5 className='card-notes-data'>Note: {item.notes !== null ? item.notes : 'N/A'}</h5>
																			{item.images && item.images.length !== 0 ?
																				<ul className='attached_img'>
																					{item.images.map((imgData, i) => {
																						return <li key={i}>
																							<Image
																								src={imgData.image}
																								width={84}
																								height={56}
																								className='rounded'
																								alt={'attached_img_' + i}
																							/>
																						</li>
																					})}
																				</ul> : <div className='no-img-data'>No images</div>}
																		</div>
																	</Col>
																})}
															</Row>
														</Card.Body>
													}
												</Accordion.Collapse>
											</Card>
										})}
									</> : ""}
							</Accordion>
						</Col>
					</Row>
				}
			</div>
		</AppLayout>
	</>);
}

export default withRouter(InspectionDetails);
