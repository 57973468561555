import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { formsPdfUrlAction } from '../../../Store/actions/Action';
import { Nav, Button } from "react-bootstrap";
import { AppLayout, CreateContent, ModalBox, PDFViewerComponent } from "../../components";
import './style.scss';

import CMS from './CMS';
import PreviewForms from './CMS/Forms/PreviewForms';
import CategoryTags from './CategoryTags';
import Advertisement from './Advertisement';
import PhotographyTips from './PhotograhyTips/PhotographyTips';
import { setFilters } from '../../../Store/actions/ListingAction';
import { setCouponFilters } from '../../../Store/actions/CouponAction';


const PageHead = ({ currComp }) => {

	const dispatch = useDispatch()

    const pathName = window.location.pathname
    useEffect(() => {
        if(!pathName.includes('listing-management')){
            dispatch(setFilters({
                searchCity: '',
                searchAddress: '',
                searchLandlord: '',
                searchTenant: '',
                searchRentpandaId: '',
                is_verified: false,
                sort_by_price_order: '',
                sort_by_status: ['all'],
                filterByExpiryDate: ''
            }));
        }
        if (!pathName.includes('coupon-management')) {
            dispatch(setCouponFilters({
                coupon_name: '',
                created_date: 'desc',
                no_of_usage: '',
                total_no_of_usage: '',
                start_date: '',
                expiry_date: '',
                coupon_code: '',
                coupon_value: '',
            }))
        }
    },[pathName, dispatch])

	let headText;
	switch (currComp) {
		case "content-management": {
			headText = "Content Management"
			break
		}
		case "category-tags": {
			headText = "Category and Tag Management"
			break
		}
		case "advertisement": {
			headText = "Advertisement Management"
			break
		}
		default: {
			headText = currComp.replace(/-/g, " ");
		}
	}
	return (
		<h2 style={{ textTransform: 'uppercase' }}>{headText}</h2>
	)
}
const ContentManagement = (props) => {
	const getFormsPdfUrl = useSelector(state => state.CMSReducer.formsPdfUrl);
	const [componentLoad, setComponentLoad] = useState('content-management');
	const [openCreateContent, setCreateContent] = useState(false);
	const [previewForm, setPreviewForm] = useState(false);
	const [pdfModal, setPdfModal] = useState(false);
	const [pdfUrl, setPdfUrl] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		if (getFormsPdfUrl !== null) {
			getFormsPdfUrl && showPdfModal(getFormsPdfUrl)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getFormsPdfUrl]);

	const showPdfModal = (url) => {
		setPdfUrl(url);
		setPdfModal(true);
		componentLoad === "content-management" && setPreviewForm(false);
	}
	const hidePdfModal = () => {
		dispatch(formsPdfUrlAction(null));
		setPdfModal(false);
		componentLoad === "content-management" && setPreviewForm(true);
	}

	useEffect(() => {
		const slug = props.match.params.slug;
		setComponentLoad(slug)
	}, [props]);
	const onClickCreateContent = () => {
		setCreateContent(true)
	}
	const toggleCreateContent = () => {
		setCreateContent(!openCreateContent)
	}
	return (
		<>
			<AppLayout>
				<div className="contentManagement">
					<Nav fill variant="tabs" className="user-tab-views list-management pt-2 tab-space-hover">
						<NavLink to={{ pathname: "/cms/content-management" }} activeclassname={'active'} className={componentLoad === "content-management" || componentLoad === undefined ? "nav-link active" : "nav-link"}>content management</NavLink>
						<NavLink to={{ pathname: "/cms/category-tags" }} activeclassname={'active'} className={"nav-link"} >category &amp; tags</NavLink>
						<NavLink to={{ pathname: "/cms/advertisement" }} activeclassname={'active'} className={"nav-link"} >advertisement</NavLink>
						<NavLink to={{ pathname: "/cms/photography-tips" }} activeclassname={'active'} className={"nav-link"} >photography tips</NavLink>
					</Nav>

					<div className="table-container">
						<div className='d-flex justify-content-between'>
							{componentLoad !== "photography-tips" && <PageHead currComp={componentLoad} />}
							{componentLoad === "content-management" &&
								<Button onClick={() => onClickCreateContent()} className='btn-create-content'>
									<img src={require("../../../_assets/images/plus-white.svg")} alt="" />
                                    Create Content
                                </Button>
							}
						</div>
						{componentLoad === "content-management" || componentLoad === undefined ? <CMS componentLoad={componentLoad} setPreviewForm={setPreviewForm} /> : ""}
						{componentLoad === "category-tags" ? <CategoryTags componentLoad={componentLoad} /> : ""}
						{componentLoad === "advertisement" ? <Advertisement componentLoad={componentLoad} /> : ""}
						{componentLoad === "photography-tips" ? <PhotographyTips componentLoad={componentLoad} /> : ""}
					</div>
				</div>
			</AppLayout>
			{openCreateContent === true &&
				<CreateContent
					isShow={openCreateContent} handleClose={(data) => toggleCreateContent(data)}
				/>
			}

			<PreviewForms previewForm={previewForm} setPreviewForm={setPreviewForm} />
			<ModalBox
				show={pdfModal}
				onHide={() => hidePdfModal()}
				size="xl"
				className="cu-full-modal"
			>
				<PDFViewerComponent PDFUrl={pdfUrl && pdfUrl} />
			</ModalBox>
		</>
	)

}

export default withRouter(ContentManagement);