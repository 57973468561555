import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import moment from 'moment'
import SignatureView from "../Signature/SignatureView";
import Cookies from 'js-cookie';

const N7DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice to End Tenancy For Causing Serious Problems in the Rental Unit or Residential Complex (N7 Form)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            When do you want your tenant to move out? (Date of Termination)
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Reason(s) for Ending Tenancy</span>
        </div>
        {noticeData.end_tenancy_reason_1 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: Tenant(s) behaviour or the behaviour of someone visiting or living with your tenant has seriously impaired the safety of another person and this behaviour occurred in the residential complex.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_2 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={noticeData.end_tenancy_reason_2 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: Tenant(s) or someone visiting or living with your tenant has wilfully damaged the rental unit or the residential complex.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_3 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={noticeData.end_tenancy_reason_3 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You or someone visiting or living with you has used the rental unit or the residential complex in a way that is inconsistent with its use as residential premises and this has caused or can be expected to cause serious damage.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_4 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason4"}
                  name={"reason4"}
                  checked={noticeData.end_tenancy_reason_4 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason4"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 4: Tenant and I live in the same building that has 3 or fewer residential units. Your behaviour or the behaviour of someone visiting or living with you has substantially interfered with:
                <ul>
                    <li>my reasonable enjoyment of the residential complex, and/or </li>
                    <li>another one of my lawful rights, privileges, or interests</li>
                  </ul>
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">
            Details about the reasons for notice
          </span>
        </div>
        {noticeData.notice_reasons.map((event, index) => {
          return <div className="event-details mb-3" key={index}>
            <span className="event-title">Event {index + 1} Details</span>
            <div className="d-flex flex-column">
              <span className="field-title-text">Date And Time</span>
              <span className="field-value-text">{moment(event.date).format('LL')},&nbsp;
            {new Date('1970-01-01T' + event.time + 'Z')
                  .toLocaleTimeString({},
                    { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                  ).toString().toUpperCase()}
              </span>
              <span className="field-title-text">Details Of Event</span>
              <span className="field-value-text">
                {event.details}
              </span>
            </div>
          </div>
        })}

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N7DetailsView);
