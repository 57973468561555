import React from 'react'

const CheckSignedLease = props => {
    const {currentStep, leaseData, view} = props;
    return (
        <>
            {(currentStep === 3) &&
				<>
					{leaseData && leaseData.is_signed === true ?
						<div className="top-status delivered">
							<p>Lease Has Been Signed</p>
						</div>
						: (leaseData && leaseData.is_sent_to_tenant ?
							<div className="top-status delivered">
								<p>Lease Has Not Been Signed Yet{leaseData.is_signed_offline === true && ', Please Upload Your Lease'}</p>
							</div>

							: (view && props.location && props.location.state && props.location.state.step ? <div className="top-status in-progress">
								<p>Please Review The Information Below Before Signing The Lease</p>
							</div> : null))}
				</>}
        </>
    )
}

export default CheckSignedLease
