export const SET_MESSAGE_DATA = 'SET_MESSAGE_DATA';
export const SET_CONVERSATION_ID = 'SET_CONVERSATION_ID';
export const SET_IS_BLOCKED = 'SET_IS_BLOCKED';
export const SET_IS_PROFILE_COMPLETED = 'SET_IS_PROFILE_COMPLETED';
export const SET_RECEIVER_DATA = 'SET_RECEIVER_DATA';
export const SET_VIEWING_REQUEST_ID = 'SET_VIEWING_REQUEST_ID';
export const SET_EXISTING_SHOWING_REQUEST_ID = 'SET_EXISTING_SHOWING_REQUEST_ID';
export const SET_EXISTING_SHOWING_SENT_BY = 'SET_EXISTING_SHOWING_SENT_BY';
export const SET_HEADER_CONTENT = 'SET_HEADER_CONTENT';
export const SET_CALENDER_DATA = 'SET_CALENDER_DATA';
export const SET_TENANT_LIST_DATA = 'SET_TENANT_LIST_DATA';
export const SET_TENANT_ID = 'SET_TENANT_ID';
export const SET_CREDIT_CHECK_REQUEST_ID = 'SET_CREDIT_CHECK_REQUEST_ID';
export const SET_CREDIT_CHECK_PAYMENT_ID = 'SET_CREDIT_CHECK_PAYMENT_ID';
export const SET_IS_LEASE_EXIST = 'SET_IS_LEASE_EXIST';
export const SET_EXISTING_TENANCY_APPLICATION_ID = 'SET_EXISTING_TENANCY_APPLICATION_ID';
export const SET_TENANCY_APPLICATION_ID = 'SET_TENANCY_APPLICATION_ID';
export const SET_IS_LEASE_FROM_TENANT_APPLICATION = 'SET_IS_LEASE_FROM_TENANT_APPLICATION';
export const SET_IS_TENANCY_APPLICATION_RECEIVED = 'SET_IS_TENANCY_APPLICATION_RECEIVED';
export const SET_LEASE_DATA = 'SET_LEASE_DATA';

export function clearMessageData(){
    return function (dispatch){
        dispatch({type:'CLEAR_ALL'})
    }
}

export function setMessageData(data) {
    return function (dispatch) {
        dispatch({ type: SET_MESSAGE_DATA, messageData: data });
    };
}

export function setLeaseData(data) {
    return function (dispatch) {
        dispatch({ type: SET_LEASE_DATA, leaseData: data });
    };
}

export function setConversationId(data) {
    return function (dispatch) {
        dispatch({ type: SET_CONVERSATION_ID, conversationId: data });
    };
}

export function setIsBlocked(data) {
    return function (dispatch) {
        dispatch({ type: SET_IS_BLOCKED, isBlocked: data });
    };
}

export function setIsProfileCompleted(data) {
    return function (dispatch) {
        dispatch({ type: SET_IS_PROFILE_COMPLETED, isProfileCompleted: data });
    };
}

export function setReceiverData(data) {
    return function (dispatch) {
        dispatch({ type: SET_RECEIVER_DATA, receiverData: data });
    };
}

export function setViewingRequestId(data) {
    return function (dispatch) {
        dispatch({ type: SET_VIEWING_REQUEST_ID, viewingRequestId: data });
    };
}

export function setExistingShowingRequestId(data) {
    return function (dispatch) {
        dispatch({ type: SET_EXISTING_SHOWING_REQUEST_ID, existingShowingRequestId: data });
    };
}

export function setExistingShowingSentBy(data) {
    return function (dispatch) {
        dispatch({ type: SET_EXISTING_SHOWING_SENT_BY, existingShowingSentBy: data });
    };
}

export function setHeaderContent(data) {
    return function (dispatch) {
        dispatch({ type: SET_HEADER_CONTENT, headerContent: data });
    };
}

export function getCalenderData(data) {
    return function (dispatch) {
        dispatch({ type: SET_CALENDER_DATA, calenderData: data });
    };
}

export function setTenantListData(data) {
    return function (dispatch) {
        dispatch({ type: SET_TENANT_LIST_DATA, tenantListData: data });
    };
}

export function setTenantId(data) {
    return function (dispatch) {
        dispatch({ type: SET_TENANT_ID, tenantId: data });
    };
}

export function setCreditCheckRequestId(data) {
    return function (dispatch) {
        dispatch({ type: SET_CREDIT_CHECK_REQUEST_ID, creditCheckRequestId: data });
    };
}

export function setCreditCheckPaymentId(data) {
    return function (dispatch) {
        dispatch({ type: SET_CREDIT_CHECK_PAYMENT_ID, creditCheckPaymentId: data });
    };
}

export function setIsLeaseExists(data) {
    return function (dispatch) {
        dispatch({ type: SET_IS_LEASE_EXIST, isLeaseExists: data });
    };
}

export function setTenancyApplicationId(data) {
    return function (dispatch) {
        dispatch({ type: SET_TENANCY_APPLICATION_ID, tenancyApplicationId: data });
    };
}

export function setExistingTenancyApplicationId(data) {
    return function (dispatch) {
        dispatch({ type: SET_EXISTING_TENANCY_APPLICATION_ID, existingTenancyApplicationId: data });
    };
}

export function setIsTenancyApplicationReceived(data) {
    return function (dispatch) {
        dispatch({ type: SET_IS_TENANCY_APPLICATION_RECEIVED, isTenancyApplicationReceived: data });
    };
}
export function setIsLeaseFromTenantApplication(data) {
    return function (dispatch) {
        dispatch({ type: SET_IS_LEASE_FROM_TENANT_APPLICATION, isLeaseFromTenantApplication: data });
    };
}