import { N9Form } from "../../components/Notices/NoticeForms"
import { EntryFormSchema } from "../../components/Notices/NoticeForms/ValidationSchema/EntryFormSchema"
import { N10Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N10Schema"
import { N11Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N11Schema"
import { N12Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N12Schema"
import { N13Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N13Schema"
import { N1Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N1Schema"
import { N2Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N2Schema"
import { N3Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N3Schema"
import { N4Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N4Schema"
import { N5Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N5Schema"
import { N6Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N6Schema"
import { N7Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N7Schema"
import { N8Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N8Schema"
import { N9Schema } from "../../components/Notices/NoticeForms/ValidationSchema/N9Schema"
import { WarningFormSchema } from "../../components/Notices/NoticeForms/ValidationSchema/WarningFormSchema"



// export const VIEW_ONLY_NOTICE={17:'Viewed',16:'Viewed'}
export const VIEW_ONLY_NOTICE = {}

export const TERMS_TEXT = {
    10: 'Important Information About this Agreement and the Law',
    1: 'Important Information About this Agreement',
    2: 'Important Information About this Agreement',
    3: 'Important Information About this Agreement',
    5: 'Important Information from the Landlord and Tenant Board (LTB)',
    6: 'Important Information from the Landlord and Tenant Board (LTB)',
    7: 'Important Information from the Landlord and Tenant Board (LTB)',
    8: 'Important Information from the Landlord and Tenant Board (LTB)',
    11: 'Important Information from the Landlord and Tenant Board (LTB)',
    12: 'Important Information from the Landlord and Tenant Board (LTB)',
    13: 'Important Information from the Landlord and Tenant Board (LTB)'
}

export const NOTICE_TYPE_SCHEMA = {
    1: N1Schema,
    2: N2Schema,
    3: N3Schema,
    4: N4Schema,
    5: N5Schema,
    6: N6Schema,
    7: N7Schema,
    8: N8Schema,
    9: N9Schema,
    10: N10Schema,
    11: N11Schema,
    12: N12Schema,
    13: N13Schema,
    16: EntryFormSchema


}