import React, { useState, useRef, useEffect } from "react";
import { logger } from "../../../Utils";
import "./style.scss";

const log = logger("DropDown");

const DropDown = (props) => {

    const { children, style, open, position = 'left', enableTopArrow, content, close } = props;

    const element = document.getElementById("dd-label");


    const labelHeight = enableTopArrow === true ? (element ? Number(element.offsetHeight) + 9 : 50) : (element ? Number(element.offsetHeight) + 2 : 40);

    const [clickedOutside, setClickedOutside] = useState(false);
    const [arrowPos, setArrowPos] = useState(10)
    const [innerStyle, setInnerStyle] = useState({});
    const myRef = useRef();
    const [openDropDown, setOpenDropDown] = useState(open);

    const handleClickInside = () => { setClickedOutside(false); setOpenDropDown(true); }
    useEffect(() => {
        const handleClickOutside = e => {
            if (!myRef.current.contains(e.target)) {
                setClickedOutside(true);
                setOpenDropDown(false)
                if (close) {
                    close();
                }
            }
        };
        const setPosition = () => {
            const dropdown = document.getElementById("dd-body");
            const contentWidth = (dropdown === null) ? parseInt(style.width, 10) : dropdown.offsetWidth;
            log.info("contentWidth", contentWidth);
            if (position === 'left') {
                //setLeft('10')
                setArrowPos(20);
                setInnerStyle({
                    top: labelHeight + 'px',
                    left: '12px',
                })
            }
            if (position === 'center') {
                //setLeft('0')
                if (element !== null) {
                    setArrowPos(element.offsetWidth / 2)
                }
                setInnerStyle({
                    top: labelHeight + 'px',
                    left: '0px',
                })
            }
            if (position === 'right') {
                contentWidth ? setArrowPos(contentWidth - 49) : setArrowPos(20);
                setInnerStyle({
                    top: labelHeight + 'px',
                    right: '12px',
                })
            }
        }
        if (open) {
            setClickedOutside(false)
        }
        log.info("open", openDropDown, "clickedOutside", clickedOutside);
        setPosition();
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, 
    [open, clickedOutside, close, openDropDown, element, labelHeight, position, style.width]);

 
    return (
        <div className={"dropdown-wrapper"} ref={myRef} onClick={handleClickInside}>
            <div id="dd-label">{children}</div>
            {open === true && clickedOutside === false &&
                <div className="row dropdown-body" id="dd-body" style={{ ...innerStyle, ...style }}>
                    {enableTopArrow === true && <div className='arrow-up' style={{ left: arrowPos + 'px' }}></div>}
                    {content}
                </div>
            }
        </div >
    )

}
export default DropDown;