import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import moment from "moment";
import SignatureView from "../Signature/SignatureView";
import Cookies from 'js-cookie';

const N5DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Notice to End your Tenancy <br />
            For Interfering with Others, Damage or Overcrowding (N5 Form)
          </span>
          {
          noticeData && noticeData.is_notice_received  
          ?role!== 3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px",  margin: "0" }}
              onClick={getParalegal}
            />
          :
          <LargeButton
              title={
                <div> 
                  <i className='fa fa-print mr-2'/>
                  Download PDF
                </div>
              }
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />
            }
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            Deadline (Date of termination if tenant fails)
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Reason(s) for Ending Tenancy</span>
        </div>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>
              Please indicate your reasons by checking the boxes on the options
              below
            </Form.Label>
            {noticeData.end_tenancy_reason_1 === 1  &&
            <div className="select-checkbox-wrapper">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: Tenant(s) behaviour or the behaviour of someone
                  visiting or living with the current tenant(s) has
                  substantially interfered with another tenant's or my
                  reasonable enjoyment of the residential complex and lawful
                  rights, privileges or interests
                </span>
              </div>
              {noticeData.end_tenancy_reason_1 === 1 && (
                <>
                  <Form.Label className="nested-checkbox-title">
                    Please indicate the following below:
                  </Form.Label>

                  <Form.Check
                    key={"radio1reason1"}
                    type="radio"
                    id={`radio1reason1`}
                    name="reasonRadio1"
                    value={"radio1reason1"}
                    checked={noticeData.reason_1_tenant_eviction === 1 && true}
                    disabled
                    label={
                      "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                    }
                  />
                  <Form.Check
                    key={"radio2reason1"}
                    type="radio"
                    id={`radio2reason1`}
                    name="reasonRadio1"
                    value={"radio2reason1"}
                    checked={noticeData.reason_1_tenant_eviction === 2 && true}
                    disabled
                    label={
                      "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                    }
                  />
                </>
              )}
            </div>}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            {noticeData.end_tenancy_reason_2 === 1 &&
            <div className="select-checkbox-wrapper">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={noticeData.end_tenancy_reason_2 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: Tenant(s) or someone visiting or living with the
                  current tenant has wilfully or negligently damaged the rental
                  unit or the residential complex.
                </span>
              </div>
              {noticeData.end_tenancy_reason_2 === 1 && (
                <>
                  <Form.Label className="nested-checkbox-title">
                    The Tenant(s) can correct their behavior by:
                  </Form.Label>

                  <Form.Check
                    key={"radio1reason2"}
                    type="radio"
                    id={`radio1reason2`}
                    name="reasonRadio2"
                    value={"radio1reason2"}
                    checked={noticeData.reason_2_tenant_eviction === 1 && true}
                    disabled
                    label={
                      "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                    }
                  />
                  {noticeData.reason_2_tenant_eviction === 1 && (
                    <>
                      <Form.Label className="nested-checkbox-radio-title">
                        The Tenant(s) can correct their behavior by:
                      </Form.Label>
                      <div className="nested-checkbox-radio">
                        <ul>
                          <li>
                            <span>Repairing the damaged property</span>
                          </li>
                          or...
                          <li>
                            <div className="d-flex justify-content-start">
                              <span className="mr-2">
                                Pay you with the estimated amount it will cost
                                to repair the damaged property
                              </span>
                              <div className="view-eviction-field">
                                <span>
                                  {Number(
                                    noticeData.reason_2_eviction_amount_1
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </li>
                          or...
                          <li>
                            <span>
                              Replacing the damaged property, if it is not
                              reasonable to repair it.
                            </span>
                          </li>
                          or...
                          <li>
                            <div className="d-flex justify-content-start">
                              <span>
                                Pay you with the estimated amount it will cost
                                to replace the damaged property
                              </span>
                              <div className="view-eviction-field">
                                <span>
                                  {Number(
                                    noticeData.reason_2_eviction_amount_2
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </li>
                          or...
                          <li>
                            <span>
                              making arrangements acceptable to you by repairing
                              or replacing damaged property or paying you to
                              replace or repair
                            </span>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                  <Form.Check
                    key={"radio2reason2"}
                    type="radio"
                    id={`radio2reason2`}
                    name="reasonRadio2"
                    value={"radio2reason2"}
                    checked={noticeData.reason_2_tenant_eviction === 2 && true}
                    disabled
                    label={
                      "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                    }
                  />
                </>
              )}
            </div>}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            {noticeData.end_tenancy_reason_3 === 1 &&
            <div className="select-checkbox-wrapper">
              <div className="d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={noticeData.end_tenancy_reason_3 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: There are more people living in your rental unit
                  than is permitted by health, safety or housing standards.
                </span>
              </div>
              {noticeData.end_tenancy_reason_3 === 1 && (
                <>
                  <Form.Label className="nested-checkbox-title">
                    Please indicate the following below:
                  </Form.Label>

                  <Form.Check
                    key={"radio1reason3"}
                    type="radio"
                    id={`radio1reason3`}
                    name="reasonRadio3"
                    value={"radio1reason3"}
                    checked={noticeData.reason_3_tenant_eviction === 1 && true}
                    disabled
                    label={
                      "Your tenant has 7 days to stop the activities or correct the behaviour to avoid eviction"
                    }
                  />
                  <Form.Check
                    key={"radio2reason3"}
                    type="radio"
                    id={`radio2reason3`}
                    name="reasonRadio3"
                    value={"radio2reason3"}
                    checked={noticeData.reason_3_tenant_eviction === 2 && true}
                    disabled
                    label={
                      "If this is the 2nd notice sent to your tenant, apply to the board immediately in order to evict your current tenant(s)"
                    }
                  />
                </>
              )}
            </div>}
          </Form.Group>
        </Row>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">
            Details about the reasons for notice
          </span>
        </div>
        {noticeData.notice_reasons.map((event, index)=>{
          return<>{!!event.details && <div className="event-details mb-3" key={index}>
          <span className="event-title">Event {index+1} Details</span>
          <div className="d-flex flex-column">
            <span className="field-title-text">Date and time</span>
            <span className="field-value-text">{moment(event.date).format('LL')},{event.time}</span>
            <span className="field-title-text">Details of event</span>
            <span className="field-value-text">
              {event.details}
            </span>
          </div>
        </div>}</>
        })}
        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />
          
        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 && 
        <Button variant="success" onClick={() => onServeNotice()}>
          Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) && 
        <Button variant="dark" onClick={() => cancelNotice()}>
          Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N5DetailsView);
