import React from 'react'
import { LargeButton, CancelButton } from "../../../../components";

const SignLeaseFooter = props => {
    const {handleCloseConfirm, submitLoader, createSignLease} = props;

    return (
        <div className="fixed-bottom d-flex justify-content-between">
            <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
            <LargeButton saveLoading={submitLoader} title='create lease' id='BTN-create-lease-sign' dataDescription='Landlord digitally signed lease' onClick={() => createSignLease()} style={{ width: '200px' }} />
        </div>
    )
}

export default SignLeaseFooter
