import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Card, Image, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Cookies from 'js-cookie';
import { ModalBox } from '../../components';
import "./style.scss";
import { setMyFavourite } from "./helper";

const HorizontalPropertyCard = (props) => {
  const {
    onFavouriteChange = () => { },
    onVerifyAction,
    id,
    type,
    isVerifed,
    image_url,
    title,
    // hide_exact_address,
    details,
    price,
    isLoveIcon,
    onClick,
    utilities
  } = props;
  
  const role = useSelector(state => state.auth.role) || parseInt(Cookies.get('role'));
  const [isFavorite, setIsFavorite] = useState(isLoveIcon)
  const tokenCookie = Cookies.get('access_token');
  const isLoggedIn = tokenCookie;
  const history = useHistory();
  const [confirmAction, setConfirmAction] = useState(false);
  // let titiletoArr = title.split(',');
  // titiletoArr.splice(-2, 2)
  // let formatedTitled = hide_exact_address === 0 && role !== 1 ? titiletoArr.join(',').substr(0, 125) : title;


  const toggleFavourite = async (id) => {
    if (role === 2 || !isLoggedIn) {
      setConfirmAction(true);
      return
    }

    try {
      await setMyFavourite(id)
      setIsFavorite(!isFavorite)
      onFavouriteChange(!isFavorite, id)
    } catch (err) {

    }
  }

  const utilitiesType = {
    1: 'Heat',
    2: 'Hydro',
    3: 'Water',
    4 : 'None'
  }

  let finalUtilities = ''
  if(utilities?.length){
    utilities.forEach((util)=>{
      finalUtilities += utilitiesType[util] + ', '
    })
  }else{
    finalUtilities = 'None'
  }
  if(finalUtilities[finalUtilities.length - 2] === ','){
    finalUtilities = finalUtilities.slice(0, finalUtilities.length - 2)
  }

  const getSignedUp = () => {
    setConfirmAction(false);
    history.push('/signup/tenant');
  }
  return (
    <Col xs={12}  >

      <Card
        className={'horizontal-card-item-container'}
      >
        <div className={'horizontal-card-header'}>
          <Card.Img
            variant="top"
            src={
              image_url
                ? image_url
                : require("../../../_assets/images/placeholder.jpg")
            }
            onClick={onClick}
            style={{ borderTopLeftRadius: 10,borderBottomLeftRadius:10, cursor: onClick && "pointer", height: 150, width: 124 }}
          />
        </div>
        <Card.Body>
          <div className='top-section'>
            {price && type !== "list" && (
              <div className="price-wrapper">
                  <h2>
                      <NumberFormat
                        value={price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />{" "}
                      <span style={{fontSize: '14px'}}>
                        / month
                      </span>
                  </h2>
                  <div className="details-wrapper">
                    {details && details?.length ? 
                    <div style={{ width: "100%" }}>
                      <span className="specification">{details}</span>
                    </div>:null}
                    <div className={'horizontal-card-footer  space_bt'}>
                      <div className="status-wrapper" >
                        {
                          isVerifed === 'unverified' ?
                            <span
                              className='cursor-pointer'
                              style={{ display: 'flex' }}
                              onClick={onVerifyAction}
                            >
                              <Image
                                className="icon"
                                width="20px"
                                height="20px"
                                src={require("../../../_assets/images/Unverified.png")}
                              />
                              
                            </span>
                            : isVerifed === 'verification pending' ?
                              <span
                                style={{ cursor: 'default' }}
                              >
                                <Image
                                  className="icon"
                                  width="20px"
                                  height="20px"
                                  src={require("../../../_assets/images/Unverified.png")}
                                />
                                
                              </span>
                              :
                              <span
                                style={{ cursor: 'default', display: 'flex' }}
                                className="success"
                              >
                                <Image
                                  className="icon"
                                  width="20px"
                                  height="20px"
                                  src={require("../../../_assets/images/verified.png")}
                                />
                                
                              </span>
                        }
                      </div>    
                    </div>
                  </div>
              </div>
            )}

            <div className="details-wrapper">
              {details && 
                (<div style={{ width: "100%" }}>
                  <span className="specification">{details}</span>
                </div>)}
            </div>
            

            <h2 className="property-title"
              onClick={onClick}
              style={{ cursor: onClick && "pointer" }}
            >{title}</h2>

              {/* <div className={'horizontal-card-footer  space_bt'}>
                <div className="status-wrapper" >
                  {
                    isVerifed === 'unverified' ?
                      <span
                        className='cursor-pointer'
                        style={{ display: 'flex' }}
                        onClick={onVerifyAction}
                      >
                        <Image
                          className="icon"
                          width="20px"
                          height="20px"
                          src={require("../../../_assets/images/Unverified.png")}
                        />
                        {isVerifed}
                      </span>
                      : isVerifed === 'verification pending' ?
                        <span
                          style={{ cursor: 'default' }}
                        >
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                          {isVerifed}
                        </span>
                        :
                        <span
                          style={{ cursor: 'default', display: 'flex' }}
                          className="success"
                        >
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/verified.png")}
                          />
                          {isVerifed}
                        </span>
                  }



                </div>    
              </div> */}
              <div className="d-flex justify-content-between">
                <span className="utilities">
                  <span>Utilites Included:</span>
                    &nbsp;
                  <b>{finalUtilities}</b>
                </span>
                  <Image
                    className="icon"
                    width="22px"
                    height="20px"
                    src={
                      isFavorite
                        ? require("../../../_assets/images/love-icon.svg")
                        : require("../../../_assets/images/love-white-border.svg")
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleFavourite(id)}
                  />
              </div>
          </div>


        </Card.Body>
      </Card>

      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'get started'}
        buttonaction={() => getSignedUp()}
      >
        {role === 2 ? <>
          <h4>Looks like you're a landlord</h4>
          <p>Please sign up as a tenant with another<br />email address to engage with a listing</p>
        </> : <>
          <h4>Sign up for free</h4>
          <p>create an account and get all the tools<br />you need to find the perfect home</p>
        </>}
      </ModalBox>
    </Col>
  );
};
export default HorizontalPropertyCard;
