import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { makeTimeValid, isValidTime, convertTime12to24, convertTime24to12, onTimeChange, onIncrementDecrement } from './helper'
import './style.scss'


const TimePicker = (props) => {
    const { placeholder, id, name, viewDateParam = '', handleChangeTime, iconStyles = {}, hideTimeLimit = false } = props
    const [timeValue, setTimeValue] = useState({ hr: null, min: null, ampm: 'AM' })

    useEffect(() => {
        if (viewDateParam) {
            const timeValue = convertTime24to12(viewDateParam)
            const newValue = makeTimeValid(timeValue)
            if (newValue.min?.toString().length === 3)
                newValue.min = newValue.min.substring(1)
            if (newValue.hr?.length === 1)
                newValue.hr = '0' + newValue.hr
            setTimeValue({ ...newValue })
        }
        else {
            if (timeValue['hr'] == null) {
                const realTime = moment(new Date()).format('HH:mm:ss')
                const timeValue = convertTime24to12(realTime)
                setTimeValue({ ...timeValue })
            }

        }
    }, [])

    useEffect(() => {
        const { hr, min, ampm } = timeValue
        const timeString = `${hr || '00'}:${min || '00'} ${ampm}`
        const { hours, minutes } = convertTime12to24(timeString)
        const timeConv = new Date(new Date().setHours(hours, minutes, 0))
        handleChangeTime(timeConv)
    }, [timeValue])


    const onTimeChangeHandler = (e) => onTimeChange(e, timeValue, setTimeValue)


    const onIncrementDecrementHandler = (timeParam, offset) => onIncrementDecrement(timeParam, timeValue, setTimeValue, offset)


    const toggleAmPm = () => setTimeValue({ ...timeValue, ampm: timeValue.ampm === 'AM' ? 'PM' : 'AM' })

    const { hr, min, ampm } = timeValue

    const timeParams = [{
        name: 'hr',
    },
    {
        name: 'min'
    }
    ]

    return <div className={'wrapper'}>
        <OverlayTrigger
            onToggle={(isShow) => {
                if (!isShow) {
                    if (parseInt((timeValue?.hr || 0)) === 0) {
                        setTimeValue({ ...timeValue, hr: 12 })
                    }
                }
            }}
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
                <Tooltip className="time-dropdown">
                    <div style={{display:'flex'}}> 
                        {timeParams.map((i, index) => {
                            return(
                            <>
                                <div className={'time_item'} >
                                        <div className={'arr'} onClick={() => onIncrementDecrementHandler(i.name, 1)}>
                                            <img alt={''} src={require('../../../_assets/images/up.svg')} />
                                        </div>
                                        <input name={i.name} className={'time_item_input'} onChange={onTimeChangeHandler} type='text' value={timeValue[i.name] === null ? '00' : timeValue[i.name]} />
                                        <div className={'arr'} onClick={() => onIncrementDecrementHandler(i.name, -1)}>
                                            <img alt={''} src={require('../../../_assets/images/down.svg')} />
                                        </div>
                                </div>
                                <div className={'timeseparator'}>
                                    {/* {index === 0 ? ':' : '-'} */}
                                    :
                                </div>
                            </>)
                        })}
                        <div className={'time_item'}  >
                            <div className={'arr'} onClick={toggleAmPm}>
                                <img src={require('../../../_assets/images/up.svg')} />
                            </div>
                            <span>
                                {ampm}
                            </span>
                            <div className={'arr'} onClick={toggleAmPm}>
                                <img src={require('../../../_assets/images/down.svg')} />
                            </div>
                        </div>
                    </div>
                </Tooltip>

            }
        >

            <div style={{ display: 'flex', backgroundColor: '#f2f3f3', borderRadius: 5, cursor: 'pointer' }} id='timepickerwrapper' >
                <input className={'timepicker-container'} style={{ width: 593 }} plaeholder={placeholder} id={id} name={name} value={hr ? `${hr}:${min || '00'} ${ampm}` : placeholder} disabled={true} />
                <img style={{ marginRight: 10, ...iconStyles }} src={require('../../../_assets/images/time-picker-icon.svg')} />
            </div>
        </OverlayTrigger>
        {
            !isValidTime(timeValue) && !hideTimeLimit ?
                <div style={{ color: 'red' }}>Please select time between 8 am to 10 pm</div> : ''
        }
    </div>
}

export default TimePicker