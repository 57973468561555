import React from 'react';
import { AppLayout, PageTitleHeader, LargeButton } from '../../';
import '../style.scss';
import { withRouter } from "react-router-dom";

const N1Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (<>
   <AppLayout>
    <PageTitleHeader
      isArrow={true}
      onClick={() => back()}
      title={'Important Information About the Law'}
      subTitle={'N1 Form'}/>

      <div className="notice-info-card">
        <ol>
          <li>The landlord must give the tenant this notice at least 90 days before the date of the rent increase. A landlord may increase the rent if at least 12 months have passed since the last rent increase or since a new tenant moved into the rental unit. No Notice of Rent Increase is required where the landlord and tenant have signed an agreement to Increase the Rent Above the Guideline (Form N10).</li>
          <li>A tenant does not have to sign a new lease when a fixed term tenancy ends. If the tenant decides not to sign a new lease, the tenant does not have to move, but the tenancy becomes "month-to-month".</li>
          <li>If a tenant plans to move, the tenant must notify the landlord on Form N9 (Tenant's Notice to End the Tenancy) at least 60 days before the lease expires if the tenant has a fixed term of tenancy or 60 days before the end of a monthly or yearly rental period. The tenant must notify the landlord on Form N9 at least 28 days before the end of a weekly rental period.</li>
          <li>If the rent increase needs approval by an order under the Residential Tenancies Act, 2006 the tenant is not required to pay more than the guideline increase until the order is issued. If the tenant only pays the guideline increase, the tenant may owe the landlord once the order is issued.</li>
          <li>If you have any questions about the law related to rent increases and how it applies to this notice, you can contact the Landlord and Tenant Board at 416-645-8080 or toll-free at 1-888-332-3234. Or, you may also visit the Board's website at sjto.ca/LTB for further information.</li>
        </ol>
      </div>
      <div className="d-flex justify-content-end button-extra-margin">
        <LargeButton
          title={"get help from our paralegal"}
          style={{ width: "310px", margin: "0" }}
          onClick={getParalegal} 
          extraClass={'get-paralegal-help-btn'}
        />
      </div>
  </AppLayout>
  </>);
}

export default withRouter(N1Info);