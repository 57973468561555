import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image, Button, Tab, Row, Nav, Container,  } from 'react-bootstrap';
import "./style.scss";

class HowItWorksSection extends Component {
    constructor(props) {
        super(props);
        this.LandlordHomeComponent = props.LandlordLeasingComponent;
      }

    render() {
        return (
            <>
             <div className="home-tabbing-wrap global-tabbing-wrap listing-how-it-works-section">                
                    <div className="position-relative">
                        <Container>                    
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                          <div className="col-lg-12 mb-12">
                                            <h2 className="listing-title">check out how it works</h2>
                                          </div>
                                        </Row>
                                        <Row>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-landlord-1.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">property&nbsp;&amp;&nbsp;rent assessment</h5>
                                                        <div className="h-list-box-disc">Even before you sign on the dotted line, get an inspection and rent assessment, so you know what to charge and who will be the perfect tenant for your rental.  </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-leasing-2.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">professional marketing</h5>
                                                        <div className="h-list-box-disc">High quality ads are crafted with pro photos and 360 videos, and then listed on all the best sites to attract the right tenants. </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-landlord-7.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">manage showings</h5>
                                                        <div className="h-list-box-disc">We manage all of the showings, with pre-qualified tenants, so you don&#39;t have to worry.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-landlord-6.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">best-in-class screening</h5>
                                                        <div className="h-list-box-disc">Credit, background and reference checks are done, before top tenants are presented. </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-landlord-2.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">lease creation</h5>
                                                        <div className="h-list-box-disc">We craft the lease with a unique lease appendix for your property, to make sure your investment is secure. </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="h-list-box">
                                                    <div className="h-list-box-left">                                                        
                                                        <Image src={require("../../../../_assets/images/how-it-works/how-it-works-leasing-1.svg")} alt="Banner" /> 
                                                    </div>
                                                    <div className="h-list-box-right">
                                                        <h5 className="h-list-box-title">tenant handoff</h5>
                                                        <div className="h-list-box-disc">Once the lease is signed, a handoff is done to make sure everyone knows next steps, and you get your rent right away. </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <Button onClick={() => this.LandlordHomeComponent.setState({ freeRentAssessment: 13, inquiry_type: 'tenant_acquisition_campaign' })} className="tabbing-lastBtn" id="BTN-landlord-leasing-get-started-llp" data-description="Get started from landlord leasing page" gtmdetails="Tab 1">
                                              connect with us
                                            </Button>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>                            
                        </Container>
                    </div>                    
                </div>
            </>
        );
    }
}

export default withRouter(HowItWorksSection);