import { logger } from "../../Utils";
import { GET_TENANT_LIST } from "../actions/AddedTenantAction";

const log = logger('propertyReducer');
export const initialState = {
    addedTenants: null,
};

export default function addedTenantReducer(state = initialState, action) {
    const handlers = {
        [GET_TENANT_LIST]: (state) => ({
            ...state,
            addedTenants: action.addedTenants,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}