import React, { useState, useEffect } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { logger } from "../../../../Utils";
import {
	AdminUserDetails,
	AppLayout,
	PageTitleHeader,
	LargeButton,
	ModalBox,
	PDFViewerComponent,
} from "../../../components";
import { getAPI } from "../../../../Api";
import { dateFormat2 } from "../../../../Utils/DateSanitizer/DateSanitizer";
import './style.scss';

const log = logger("NoticesDetails");
const NoticesDetails = (props) => {
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [user_id, setUserId] = useState("");
	const [notice_id, setNoticeId] = useState("");
	const [user_type, setUserType] = useState("");
	const [userData, setUserData] = useState(null);
	const [noticesData, setNoticeData] = useState(null);
	const [profile, setProfile] = useState(null);
	const [action, setAction] = useState("");
	const [buttonTitle, setButtonTitle] = useState("");
	const [path] = useState('');
	const [openPDFView, setOpenPDFView] = useState(false);

	const back = () => {
		// props.history.push({ pathname: `/profile-detail/${user_type}/${user_id}/notices`, state: { path: path } });
		props.history.goBack();
	};

	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === "switch") {
			onClickSwitch(data);
			setButtonTitle("yes, switch profile");
		} else if (action === "suspend" || action === "active") {
			suspendUser(data);
			if (action === "suspend") {
				setButtonTitle("yes, suspend");
			} else if (action === "active") {
				setButtonTitle("yes, activate");
			}
		}
	};
	const onClickSwitch = (data) => {
		setProfile(data);
	};
	const suspendUser = (userData) => {
		setProfile(userData);
	};
	const goBack = () => {
		let url = "";
		if (action === "suspend" || action === "active") {
			if (action === "suspend") {
				url = "/admin/users/suspend/" + user_id;
			} else if (action === "active") {
				url = "/admin/users/activate/" + user_id;
			}
			getAPI(url).then((res) => {
				getAPI(`/admin/notice/details/${user_id}/${notice_id}`).then((res) => {
					if (res.status === 200) {
						setUserData(res.data.data.user);
						setNoticeData(res.data.data.notice);
					}
				});
				setOpenSwitchModal(false);
			});
		}
	};

	useEffect(() => {
		setUserId(props.match.params.user_id);
		setNoticeId(props.match.params.notice_id);
		setUserType(props.match.params.user_type);
		// setPath(props.location.state.path)
	}, [
		props.match.params.user_id,
		props.match.params.notice_id,
		props.match.params.user_type,
	]);
	useEffect(() => {
		log.info(user_id, notice_id);
		if (user_id && notice_id) {
			if (user_id !== undefined && notice_id !== undefined) {
				let urlInside = `/admin/notice/details/${user_id}/${notice_id}`;
				getAPI(urlInside).then((res) => {
					if (res.status === 200) {
						log.info(res.data.data);
						setUserData(res.data.data.user);
						setNoticeData(res.data.data.notice);
					}
				});
			}
		}
	}, [user_id, notice_id, user_type]);
	let maxLength = 30;
	return (
		<>
			<AppLayout>
				<div>
					<PageTitleHeader
						isArrow={true}
						onClick={() => back()}
						title={"NOTICE DETAILS"}
					/>
				</div>
				<div className="main-lease-container">
					<Row className="container-detail">
						<Col md="12">
							<div className="card-detail d-flex justify-container-between">
								<div className="detail-left">
									{userData && (
										<AdminUserDetails
											userDetails={userData}
											user_type={userData && userData.user_type}
										/>
									)}
								</div>
								<div className="detail-right">
									{userData && userData.is_active !== "Suspended" && (
										<LargeButton
											onClick={() => toggleModal(userData, "suspend")}
											className="suspend-btn"
											variant={"dark"}
											title="suspend user"
											style={{ width: "177px", float: "right" }}
										/>
									)}
									{userData && userData.is_active !== "Active" && (
										<LargeButton
											onClick={() => toggleModal(userData, "active")}
											className="suspend-btn"
											variant={"dark"}
											title="activate"
											style={{ width: "177px", float: "right" }}
										/>
									)}
								</div>
							</div>
						</Col>
					</Row>
					<Row className="container-detail">
						<Col md="12">
							<div className="card-detail">
								<div className="d-flex justify-content-between">
									<div className="request-title-right">
										{noticesData && <h4>
											notice: <span className="ml-2">{noticesData.formatted_i_want_to}</span>
										</h4>}
									</div>
									<div className="request-title-left">
										<span>
											Date Created:{" "}
											{dateFormat2(
												noticesData && noticesData.created_at
											)}
										</span>
									</div>
								</div>

								<Row className="maintainanace-form-group mt-3">
									{noticesData && noticesData.property && (
										<Col md={3}>
											<Form.Group>
												<Form.Label>Property</Form.Label>
												<h5 className="address">
													{noticesData.property.address}
												</h5>
											</Form.Group>
										</Col>
									)}
									{noticesData && (
										<Col md={2}>
											{user_type === 'landlord' ?
												<Form.Group>
													<Form.Label>Landlord Name</Form.Label>
													<h5 className="name">{noticesData.landlord.name}</h5>
												</Form.Group> :
												<>
													{noticesData.tenant_details &&
														<Form.Group>
															<Form.Label>Tenant Name</Form.Label>
															<h5 className="name">{noticesData.tenant_details[0].name}</h5>
														</Form.Group>
													}
												</>
											}
										</Col>
									)}
									{noticesData &&
										<Col md={3}>
											<Form.Group>
												<Form.Label>Notice Type</Form.Label>
												<h5 className="notes">{noticesData.formatted_notice_type}</h5>
											</Form.Group>
										</Col>
									}
									{(noticesData && noticesData.notes !== "") && (
										<Col md={4}>
											<Form.Group>
												<Form.Label>Notes</Form.Label>
												<h5 className="notes">{noticesData.notes}</h5>
											</Form.Group>
										</Col>)}
								</Row>

							</div>
						</Col>
						{(noticesData && (noticesData.pdf_url !== null || noticesData.pdf_url !== '')) &&
							<Col md="12" className='mt-3'>
								<div className="card-detail p-0">
									<h4 className='doc-heading'>Documents</h4>
									<hr />
									<Row className='doc-row'>
										<Col md={6} className='doc-col'>
											<div className='d-flex justify-content-between'>
												<div>
													<Image
														src={require(`../../../../_assets/images/pdf-icon.svg`)}
														width={40}
														height={48}
														alt={'pdf-icon'}
													/>
													<span className='pdf-lable' >{noticesData.pdf_url.split('/').pop().length > maxLength ? noticesData.pdf_url.split('/').pop().substring(0, maxLength) + '...' : noticesData.pdf_url.split('/').pop()}</span>
												</div>
												<LargeButton title="view" style={{ width: '117px', height: '48px' }} onClick={() => setOpenPDFView(true)} />
											</div>
										</Col>
									</Row>
								</div>
							</Col>}
					</Row>
				</div>
			</AppLayout>
			<ModalBox
				show={openSwitchModal}
				onHide={toggleModal}
				size="sm"
				actionbuttontitle={buttonTitle}
				buttonaction={goBack}
				extraBtnStyle="extraBtnStyle"
			>
				{action === "suspend" && (
					<p className="switching-details">
						Are you sure to suspend{" "}
						<span className="profile-name">{profile && profile.name}’s?</span>{" "}
						<br />
            They will be unable to apply to any properties within Rent Panda
					</p>
				)}
				{action === "active" && (
					<p className="switching-details">
						Are you sure to activate{" "}
						<span className="profile-name">{profile && profile.name}’s?</span>{" "}
						<br />
            Their rented property will remain within Rent Panda but other
            listing will be activated automatically
					</p>
				)}
			</ModalBox>
			<ModalBox
				show={openPDFView}
				onHide={() => setOpenPDFView(false)}
				size="xl"
				className="cu-full-modal"
			>
				<PDFViewerComponent PDFUrl={noticesData && noticesData.pdf_url} />
			</ModalBox>
		</>
	);
};

export default withRouter(NoticesDetails);
