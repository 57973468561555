export const PastHomeInfoValidationSchema = {
    full_address: [
        { rule: "required", message: "Address required" },
    ],
    past_start_date: [
        { rule: "required", message: "Start date required" },
    ],
    past_end_date: [
        { rule: "required", message: "End Date required" },
    ]
}