import React from "react";
import { inspectionType, createdCompletedSort, inspectionStatus } from "../../../Constants/adminConstant";
import ImageComponent from "../../Admin/ProfileDetail/ListUsableComponent/ImageComponent";
import ThreeDotsDropdown from "../ThreeDotsDropdown";

const InspectionHeader = (props) => {
    const { data, component } = props;
    return (
        <>
            {component === "inspection" &&
                <>
                    {(() => {
                        switch (data.header) {
                            case 'ADDRESS':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'TYPE':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {inspectionType.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"completed" + i} onClick={() => props.setType(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />

                                    </>
                                )
                            case 'TENANT':
                                return (
                                    <>
                                        {data.header}
                                    </>
                                )
                            case 'COMPLETED ON':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {createdCompletedSort.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"completed" + i} onClick={() => props.sortCreatedOn(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            case 'STATUS':
                                return (
                                    <>
                                        {data.header}
                                        <ThreeDotsDropdown
                                            extraClass="display-inline"
                                            icon={<ImageComponent />}
                                            actionsButton={
                                                <>
                                                    {inspectionStatus.map((data, i) => {
                                                        return <span className="dropdown-item sort-option cursorPointer" key={"main-status" + i} onClick={() => props.sortInspectionStatus(data.value)}>{data.display}</span>
                                                    })}
                                                </>
                                            }
                                        />
                                    </>
                                )
                            default:
                                return null
                        }
                    })()} </>}
        </>
    );
}
export default InspectionHeader;