export const MainDetailSchema = {
    addressAdmin: [
        { rule: "required", message: "Address required" },
    ],
    unit_numberAdmin: [
        { rule: "required", message: "Unit name or number required" },
    ],
    dateAdmin: [
        { rule: "required", message: "Available date required" },
    ],
    lease_durationAdmin: [
        { rule: "required", message: "Lease duration required" },
    ],
    price_per_monthAdmin: [
        { rule: "required", message: "Price per month required" },
    ]
}