import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Loader } from '../../App/components';
import "./style.scss";
import { Image } from "react-bootstrap";
import {
  JombotronSection,
  HowItWorksSection
} from "./components";
import { HeaderLandingPage, FooterLandingPage } from "../components";
import { FreeRentAssessment } from "../../App/Landlord/CreateListing/Steps";
import { Helmet } from 'react-helmet';

class LandlordLeasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freeRentAssessment: false,
      free_rent_assessment_id: '',
      inquiry_type: '',
      bannerImage: null
    }
  }

  handleClose = () => {
    this.setState({ freeRentAssessment: false })
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Leasing | Rent Panda</title>
          <meta name="description" content="Rent Panda is a free rental platform for landlords in 
          Ontario with the tools you'll need to find and manage the best tenants." />
        </Helmet>
        <div className="leasing-page-main">
          <HeaderLandingPage />
          <JombotronSection />
          <HowItWorksSection LandlordLeasingComponent={this} />
          <FooterLandingPage />

          {/* Free Rent Assessment Form */}
          {<FreeRentAssessment
            isShow={this.state.freeRentAssessment}
            requestType={this.state.freeRentAssessment}
            handleClose={this.handleClose}
            landlordLanding={true}
            tabsSection={true}
            inquiryType={this.state.inquiry_type}
            setFree_rent_assessment_id={(data) => this.setState({ free_rent_assessment_id: data })} />}
        </div>          
      </React.Fragment>
    );
  }
}

export default withRouter(LandlordLeasing);
