import React from 'react'
import { Col } from "react-bootstrap";
import DollorNumberDisplay from '../../DollorNumberDisplay';

function SocialAssistanceInfo(props) {
    const { employmentStatus, emp = {} } = props

    return (
        <>
            <Col md={6}>
                <span className="card-labels">Employment Status</span>
                <p className="card-labels-desc">{employmentStatus}</p>

            </Col>
            <Col md={6}>
                <span className="card-labels">Monthly income</span>
                <p className="card-labels-desc"> <DollorNumberDisplay price={emp.min_salary} /></p>

            </Col>

        </>
    )
}

export default SocialAssistanceInfo
