import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ModalBox, TableTenant } from '../../components';
import { getAPI, postAPI } from '../../../Api';
import { getBackPath, getPreviousPage, inActiveUserFilter, setUserStatus, setFilters, setTableData } from '../../../Store/actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import TableContentLoader from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader';
import { phoneNumberSanitizer } from '../../../Utils/NumberSanitizer/NumberSanitizer';
import logger from '../../../Utils/logger';

const log = logger("InactiveUser");

const InactiveUser = (props) => {

    const [openSwitchModal, setOpenSwitchModal] = useState(false);
    const [profile, setProfile] = useState(null);
    const dispatch = useDispatch();
    const inactiveUserFilters = useSelector(state => state.userReducer.inactiveUserFilters)
    const tableData = useSelector(state => state.userReducer.inactiveUserTableData);
    const inactiveUserCurrentPage = useSelector(state => state.userReducer.inactiveUserCurrentPage);
    const filters = useSelector(state => state.userReducer.filters);
    const [isLoading, setIsLoading] = useState(true);

    const goToProfileDetail = (user) => {
        let dataFilter = {
            'filter': {
                'name': filters.name || "",
                'email': filters.email | "",
                'phone_number': phoneNumberSanitizer(filters.phone_number),
                'user_type': filters.user_type || "",
            },
            'sort': {
                'by': 'is_active',
                'order': 'desc'
            },
            'limit': 15
        };
        user.user_type.toLowerCase() === 'landlord' &&
            props.history.push({
                pathname: '/profile-detail/landlord/' + user.id + '/listings', state: { path: 'inactive' }
            });
        user.user_type.toLowerCase() === 'tenant' && props.history.push({
            pathname: '/profile-detail/tenant/' + user.id + '/profile', state: { path: 'inactive' }
        });
        dispatch(inActiveUserFilter(dataFilter))
        dispatch(getBackPath('inactive'));
        dispatch(getPreviousPage(inactiveUserCurrentPage));
    }

    const onClickSwitch = (data) => {
        setOpenSwitchModal(true);
        setProfile(data);
    }

    const toggleModal = () => {
        setOpenSwitchModal(!openSwitchModal);
    }

    const goBack = () => {
        let url = '';
        if (profile.user_type === 'Landlord') {
            url = '/admin/users/switch-to-tenant/' + profile.id;
        } else if (profile.user_type === 'Tenant') {
            url = '/admin/users/switch-to-landloard/' + profile.id;
        }
        getAPI(url).then(response => {
            toggleModal();
            profile.user_type === 'Landlord' ?
                props.history.push('/profile-detail/tenant/' + profile.id + '/profile') :
                props.history.push('/profile-detail/landlord/' + profile.id + '/listings')
        })
    }
    useEffect(() => {
        dispatch(setUserStatus('inactive'));
        dispatch(setFilters(inactiveUserFilters));
    }, [])

    useEffect(() => {
        let data = {
            'filter': {
                'name': filters.name || "",
                'email': filters.email || "",
                'phone_number': phoneNumberSanitizer(filters.phone_number),
                'user_type': filters.user_type || "",
            },
            'sort': {
                'by': 'is_active',
                'order': 'desc'
            },
            'limit': 15
        };
        log.info("data", data, filters);
        let inSideUrl = '';
        if (inactiveUserCurrentPage !== null) {
            inSideUrl = `/admin/users/suspended?page=${inactiveUserCurrentPage}`
        } else {
            inSideUrl = `/admin/users/suspended`
        }
        postAPI(inSideUrl, data).then(response => {
            setIsLoading(false)
            dispatch(setTableData(response.data));
        })
    }, [filters, inactiveUserCurrentPage, dispatch]);

    let userColumns = [];
    userColumns = [
        {
            header: 'Name of User',
            name: 'name',
            width: '25%'
        },
        {
            header: 'Email',
            name: 'email',
            width: '25%'
        },
        {
            header: 'Phone Number',
            name: 'phone_number',
            width: '25%'
        },
        {
            header: 'Role',
            name: 'user_type',
            width: '25%'
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'userManagement'} /> : <>
                {tableData && tableData.data ?
                    <TableTenant
                        columns={userColumns}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        onClickSwitch={(data) => onClickSwitch(data)}
                        component={'userManagement'}
                    /> : ''}
            </>}
            <ModalBox
                show={openSwitchModal}
                onHide={toggleModal}
                size='sm'
                actionbuttontitle={'yes, switch profile'}
                buttonaction={goBack}
                extraBtnStyle='extraBtnStyle'
            >
                <p className='switching-details list'>You are switching <span className='profile-name'>{profile && profile.name}’s</span> Profile
                from a {profile && profile.user_type.toLowerCase()} to a {profile && profile.user_type === 'Landlord' ? 'tenant' : 'landlord'} Continue?</p>
            </ModalBox>
        </React.Fragment>
    )
};

export default withRouter(InactiveUser);