import {
    SET_FILTERS,
    SET_TABLEDATA,
    SET_CURRENT_PAGE
} from '../actions/Action';

export const initialState = {
    tableData: { data: [] },
    currentPage: 1,
    filters: {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
    }

}

export default function UserReducer(state = initialState, action) {
    const handlers = {
        [SET_FILTERS]: (state) => ({
            ...state,
            filters: action.filters,
        }),
        [SET_TABLEDATA]: (state) => ({
            ...state,
            tableData: action.tableData,
        }),
        [SET_CURRENT_PAGE]: (state) => ({
            ...state,
            currentPage: action.currentPage,
        })
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}