import React from "react";
import { Card, Button } from "react-bootstrap";
import "./style.scss";
const PackageCard = (props) => {
  const { title, price, items, onClick, is_active } = props;

  return (
    <Card className={is_active ? "package-card active" : "package-card"}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Card.Title>{price}</Card.Title>
        <ul>
        {items &&
            items.map((data, i)=>{
              return(<li key={'list_'+i}>{data.description}</li>)
            })
        }
        </ul>

        <div className='button-outer'>
          <Button data-description="Landlord selected a promotional package" data-gtmdetails={title} id="BTN-listing-step-6-package" onClick={onClick}>Select Package</Button>
        </div>
      </Card.Body>
    </Card>
  );
}
export default PackageCard;
