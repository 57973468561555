import "../ChartsStyle.scss"
import "../../ReportSections/ReportSection.scss"
import "./RentSqFt.scss"
import React from "react"

const RentSqFt = ({data, chartData, name}) => {
  // console.log("data", data)
  // console.log("chartData", chartData)

  // split the chart data list in half


  const RentSqFtTable = ({chartData}) => {
    const myKeys = ["City", "Sqft_Mean"]
    const myHeaders = ["City", "Price per Sqft"]

    const highLimit = Math.max(...chartData.map((item) => (item[myKeys[1]])))


    const BarCell = ({value}) => {
      // return a single div, with a percentage of the high limit colored in purple
      const myWidth = ((value / highLimit) * 100).toPrecision(2)
      const style = {
        width: `${myWidth}%`,
        backgroundColor: "#8E7DC0"
      }

      return (
        <td className="whitePadding">
          <div className="bar-cell">
            <div className="bar-cell__bar" style={style}>
            <span>
              ${value}
            </span>
            </div>
          </div>
        </td>
      )
    }

    return (
        <table className={"sqFtTable"}>
          <thead>
          <tr>
            { myHeaders.map((key) => {
              return <th key={key}>{key}</th>
            })
            }
          </tr>
          </thead>

          <tbody>
          { chartData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.City}</td>
                <BarCell value={item[myKeys[1]]} />
              </tr>
            )
          })
          }
          </tbody>

        </table>
    )
  }
  const half = Math.ceil(chartData.length / 2)
  const firstHalf = chartData.slice(0, half)
  const secondHalf = chartData.slice(half, chartData.length)
  return (
    <div className={"reportSection"} id={name}>
      <h3 className={"chapterHeader"}>{name}</h3>
      <div className={"sectionContent"}>

        <div className="sqFtTableContainer">
          <RentSqFtTable chartData={firstHalf}/>
          <RentSqFtTable chartData={secondHalf}/>
        </div>


        { data.data.appendixText && (
          <p className={"appendixText"}>{data.data.appendixText}</p>
        )}
      </div>
    </div>
  )
}

export default RentSqFt