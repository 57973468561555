import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { toaster } from '../../../../Utils';
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import { ModalBox } from '../../';
import { ContactSchema } from '../CreateLeaseSteps/CreateLeaseSchema/ContactSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { updateRawData } from '../../../../Store/actions/RawDataAction';
import { leaseSubmit, getAddressFromPlace, getContactInfoFromApi, getContactInfo } from './helper'
import MailingAddress from './ContactInfoComponents/MailingAddress'
import PropertyAddress from './ContactInfoComponents/PropertyAddress'
import LandlordContactInfo from './ContactInfoComponents/LandlordContactInfo';
import { useSelector } from 'react-redux';
import FormFooter from "./FormFooter/FormFooter";

const CreateLeaseContactInfo = (props) => {
	const { setCurrentStep, property_id, property_details_id, leaseData, lease_id, setLeaseId } = props;

	const landlordPhone = useSelector(state => state?.auth?.phone)
	const landlordEmail = useSelector(state => state?.auth?.email)
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const profileDetails = useSelector(state => state.editProfile.editProfileList);
	const isLeaseFromTenantApplication = useSelector(state => state.messageDetailsReducer.isLeaseFromTenantApplication);

	const [contactInfo, setContactInfo] = useState({
		full_name: profileDetails.data.name,
		email: profileDetails.data.email,
		phone_number: profileDetails.data.phone,
		agree_to_received_notice: 1,
		agree_to_received_notices_email: landlordEmail,
		day_to_day_communication: 1,
		day_to_day_communication_email: landlordEmail,
		day_to_day_communication_phone_number: landlordPhone,
		address: propertyDetails.address_line_1,
		city: propertyDetails.city,
		province: propertyDetails.province,
		country: '',
		postal_code: propertyDetails.postal_code,
		latitude: propertyDetails.latitude || 34.059849,
		longitude: propertyDetails.longitude || -118.455551,
		unit_no: propertyDetails.unit_no,
		is_condo: propertyDetails.is_condo ? 1 : 0,
		mailing_address: '',
		mailing_city: '',
		mailing_province: '',
		mailing_country: '',
		mailing_postal_code: '',
		mailing_latitude: 34.059849,
		mailing_longitude: -118.455551,
		mailing_unit_no: ''
	})
	const [phoneError, setPhoneError] = useState(false);
	const [communicationPhoneError, setCommunicationPhoneError] = useState(false);
	const [saveLoading, setBtnDisable] = useState(false);
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const [isSaveEnable, setSaveEnable] = useState(false);

	useEffect(() => {
		const updatedContactInfo = getContactInfoFromApi(leaseData);
		setContactInfo(prevContactInfo => ({ ...prevContactInfo, ...updatedContactInfo }));
	}, [leaseData]);

	useEffect(() => {
		if (contactInfo?.mailing_address === '') {
			setContactInfo(prevContact => ({ ...prevContact, mailing_city: '', mailing_province: '', mailing_postal_code: '' }));
		}
		if (contactInfo?.address === '') {
			setContactInfo(prevContact => ({ ...prevContact, city: '', province: '', postal_code: '' }));
		}
	}, [contactInfo.mailing_address, contactInfo.address])

	const OnChangeInput = (event) => {
		const change = phoneNumberSanitizer(event.target.value);
		const phoneBool = event.target.name === 'phone_number_contact'
		const phone = phoneBool ? 'phone_number' : 'day_to_day_communication_phone_number'
		setContactInfo(prevContact => ({ ...prevContact, [phone]: change }))

		const checkNAN = isNaN(Number(change))
		phoneBool ? setPhoneError(checkNAN) : setCommunicationPhoneError(checkNAN)
		setSaveEnable(true);
	}

	const backToPrevPage = () => {
		props.history.push({
			pathname: `/property-details/${property_id}/${property_details_id}/leases`
		})
	}

	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		setSaveEnable(true);
		setContactInfo({ ...contactInfo, [name]: Value });
	}

	const handlePlaceChanged = (place, type) => {
		const address = getAddressFromPlace(place);
		getPlaceDetails(place, address, type === 'property' ? 'rental' : 'mailing');
		setSaveEnable(true);
	}

	const updateState = (event, state) => {
		const value = event.target.value;
		const updatedObj = {};
		if(state === 'address' || state === 'mailing_address'){
			updatedObj[state === 'address' ? 'city' : 'mailing_city'] = '';
			updatedObj[state === 'address' ? 'province' : 'mailing_province'] = '';
			updatedObj[state === 'address' ? 'postal_code' : 'mailing_postal_code'] = '';
		}
		setContactInfo(prevContact => ({ ...prevContact, [state]: value, ...updatedObj}))
		setSaveEnable(true);
	}

	const getPlaceDetails = (place, address, state) => {
		const upadtedConctactInfo = getContactInfo(place, address, state, contactInfo)
		setContactInfo(upadtedConctactInfo);
		validate(ContactSchema)
		setSaveEnable(true);
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		if (validate(ContactSchema)) {
			setBtnDisable(true)
			try {
				const data = {
					...contactInfo,
					'lease_id': lease_id,
					'mailing_unit' : contactInfo.mailing_unit_no,
					'property_id': property_id,
					'property_detail_id': property_details_id,
					'is_tenancy_application': isLeaseFromTenantApplication,
					'action': saveState
				}
				const res = await leaseSubmit(data, 'contact-details')
				setBtnDisable(false)
				if (res.status === 200) {
					const leaseId = res.data.lease_id;
					updateRawData({ leaseId })
					setLeaseId(leaseId);
					if (saveState === 'update') {
						setLease_updated(true);
					} else {
						let step = 0;
						if (saveState === 'next') {
							step = 1;
							setCurrentStep(1);
						}
						props.history.push({
							pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + leaseId,
							state: {
								lease_id: leaseId,
								step: step
							}
						});

					}
					if (saveState !== 'update' && isSaveEnable) {
						toaster('success', res.message);
					}
				} else {
					toaster('error', res.message);
				}
			} catch (error) {
				setBtnDisable(false)
				toaster('error', 'Something went wrong!');
			}
		}
	}

	return (
		<>
			<Form id='Form' onSubmit={onSubmit}>
				<div className='inner-wrapper cu-step-form-body'>
					<div className='body-container'>
						<div className='body-header'>
							<h3 className='text-left'>landlord contact information</h3>
							{leaseData && leaseData.is_sent_to_tenant ?
								<button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
								: <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
						</div>
						<LandlordContactInfo
							contactInfo={contactInfo}
							updateState={updateState}
							OnSingleOptionCheck={OnSingleOptionCheck}
							phoneError={phoneError}
							communicationPhoneError={communicationPhoneError}
							OnChangeInput={OnChangeInput}
						/>
						<hr />
						<MailingAddress
							contactInfo={contactInfo}
							updateState={updateState}
							handlePlaceChanged={handlePlaceChanged}
							setSaveEnable={setSaveEnable}
						/>
						<hr />
						<PropertyAddress
							contactInfo={contactInfo}
							handlePlaceChanged={handlePlaceChanged}
							setSaveEnable={setSaveEnable}
							updateState={updateState}
							OnSingleOptionCheck={OnSingleOptionCheck}
						/>
					</div>
				</div>
				<FormFooter
					backToPrevPage={backToPrevPage}
					leaseData={leaseData}
					saveLoading={saveLoading}
					setSaveState={setSaveState}
					id='BTN-create-lease-land-contact-info'
					dataDescription='BTN-create-lease-land-contact-info'
				/>
			</Form>

			<ModalBox
				show={is_lease_updated}
				onHide={() => setLease_updated(false)}
				size="sm"
				actionbuttontitle={"got it"}
				buttonaction={() => {
					setLease_updated(false);
					setCurrentStep(3);
				}}
			>
				<h4>your lease has been updated</h4>
				<p>the tenant has been <br /> notified of the changes. </p>
			</ModalBox>
		</>
	);
}

export default withRouter(CreateLeaseContactInfo);