import React from "react";
import { CancelButton, LargeButton, SidePanel } from "../../../components";
import { withRouter } from "react-router-dom";

const InsertItem = (props) => {
	const { isShow, handleClose, items, handleRemove, handleChange, handleAdd, addItemsToSection, newItemText, hideBackButton } = props;


	return (<>
		<SidePanel
			isShow={isShow}
			isHide={() => handleClose("addSectionItem")}
			title={`Add New ${newItemText} Item(s)`}
		>
			<div className="customize-title">
				<span>What Items You Want To Insert</span>
			</div>
			<div className="sidepanel-body" style={{ top: "125px" }}>
				{items.map((item, idx) => {
					return (
						<div
							className={
								idx !== 0
									? "d-flex flex-column border-margin mt-4 pt-3"
									: "d-flex flex-column"
							}
							key={`${item}-${idx}`}
						>
							<div className={"d-flex justify-content-between"}>
								<span>Name Of Item{idx !== 0 ? ` (${idx + 1})` : ""}</span>
								{idx !== 0 && (
									<img
										className="cursor-pointer"
										onClick={() => handleRemove(item.inspection_section_item_id)}
										src={require("../../../../_assets/images/delete.svg")}
									/>
								)}
							</div>

							<input
								type="text"
								className="add-section-input"
								name="item_name"
								id={"item_name" + idx}
								placeholder="Eg. Kitchen"
								value={item.item_name}
								onChange={(e) => handleChange(e, idx)}
							/>
						</div>
					);
				})}
				<LargeButton
					variant={"dark"}
					isIcon={true}
					onClick={() => handleAdd()}
					title={"Add another item"}
					style={{
						marginTop: "25px",
						height: "48px",
					}}
				/>
			</div>
			<div className="fixed-bottom d-flex justify-content-between">
				<CancelButton
					onClick={() => handleClose("addSectionItem")}
					title="back"
					style={{
						fontWeight: "bold",
						visibility: hideBackButton && "hidden",
					}}
				/>
				<LargeButton
					title={"add"}
					onClick={addItemsToSection}
					style={{
						width: "150px",
						height: "36px",
						padding: "0",
					}}
				/>
			</div>
		</SidePanel>
	</>);
}
export default withRouter(InsertItem);