import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import {
  CostNumberFormat,
  DatePickerComponent,
  LargeButton,
  SingleOptionCheck,
} from "../../";
import { numberFormatSanitizerInt } from "../../../../Utils/NumberSanitizer/NumberSanitizer";
import { frequencyType } from "../../../../Constants/NoticeConstants";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { logger, toaster, dateFormat, validate } from "../../../../Utils";
import { postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { N2Schema } from "./ValidationSchema/N2Schema";
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const log = logger("N2Form");
const N2Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack,
    onNoticeDataUpdate
  } = props;
  const [date, setDate] = useState(null);
  const [rent, setRent] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [other, setOther] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)

  //Signature Data
  const [signData, setSignData] = useState(null);
  const [globalState,setGlobalState]=useGlobalState()


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
    onNoticeDataUpdate({
      when_rent_increase:dateFormat(date),
      new_rent_rate:rent,
      frequency:frequency,
      frequency_text:other,
      signData
    })
    setGlobalState({ onSubmit: onSubmit })
    if(isSubmitClicked)
    validate(N2Schema,null,true)

  },[date,rent,frequency,other,phoneError,signData,tenant_id.length,property_id])


  const onSubmit = () => {
    setIsSubmitClicked(true)

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N2Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        notice_id: noticeData && noticeData.id,
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 2,
        when_rent_increase: dateFormat(date),
        new_rent_rate: rent,
        frequency: frequency,
        frequency_text: other,
        full_name: signData && signData?.signingName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 15,
      };

      postAPI(url, data).then((response) => {
        if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, Date) => {
    const dateValid = dateSanitizer(date);
    setDate(dateValid);
  };

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute("name");
    if (name === "frequency") {
      setFrequency(Value);
    }
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  useEffect(() => {
    if (noticeData) {
      if(noticeData.when_rent_increase && noticeData.when_rent_increase!=='Invalid date' && noticeData.when_rent_increase.length)
      setDate(moment(noticeData.when_rent_increase).toDate());
      setRent(noticeData.new_rent_rate);
      setFrequency(noticeData.frequency || 1);
      setOther(noticeData.frequency_text);
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Unit Partially Exempt (N2)</span>
          <LargeButton
            title={"get help from our paralegal"}
            extraClass={'get-paralegal-help-btn'}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>When Is The Rent increasing?</Form.Label>
              <DatePickerComponent
                id="date"
                name="date"
                format={"dd/MM/yyyy"}
                value={date && date}
                handleDateChange={(date) => handleDateChange(date, setDate)}
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-end"
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>New Rent Rate ($)</Form.Label>
              <CostNumberFormat
                name={"rent"}
                id={"rent"}
                classname="form-control"
                value={rent ? rent : ""}
                updateField={(e) =>
                  setRent(e.target.value)
                }
                pre="$"
                placeholder={"Please indicate $ amount"}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="8">
              <Form.Label>Frequency</Form.Label>
              <SingleOptionCheck
                mapData={frequencyType}
                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                name={"frequency"}
                setParam={frequency}
                extra={
                  frequency === 3 && (
                    <li className="other-fild">
                      <Form.Control
                        type="text"
                        name="other"
                        id="other"
                        value={other}
                        placeholder='Clarify "Other"'
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </li>
                  )
                }
              />
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={2}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N2Form);
