

export const reorderEmployemntInfo = (employmentInfo = []) => {
    let currentJob = {};
    const otherJobs = []
    employmentInfo.forEach((emp) => {
        if (emp.is_current) {
            currentJob = emp
        }
        else {
            otherJobs.push(emp)
        }
    })
    return [currentJob, ...otherJobs]
}