export const N6Schema = {
    terminationDate: [
        { rule: "required", message: "Date required" },
    ],
    rent: [
        { rule: "required", message: "rent required" },
    ],
    date1: [
        { rule: "required", message: "start date required" },
    ], 
    date2: [
        { rule: "required", message: "start date required" },
    ], 
    date3: [
        { rule: "required", message: "start date required" },
    ],
    event1:[
        { rule: "required", message: "time required" },
    ], 
    event2:[
        { rule: "required", message: "time required" },
    ], 
    event3:[
        { rule: "required", message: "time required" },
    ], 
    details1:[
        { rule: "required", message: "required" },
    ],
    details2:[
        { rule: "required", message: "required" },
    ], 
    details3:[
        { rule: "required", message: "required" },
    ],    
    firstName: [
        { rule: "required", message: "first name required" },
    ], 
    lastName: [
        { rule: "required", message: "last name required" },
    ], 
    signingDate: [
        { rule: "required", message: "signing date required" },
    ], 
}