import React, { useState, useEffect } from "react";
import { SidePanel, ModalBox, PDFViewerComponent } from "../";
import { Col, Image } from "react-bootstrap";
import moment from 'moment';
import { getAPI } from "../../../Api";
import { withRouter } from "react-router-dom";
import { logger, timeLeft } from "../../../Utils";
import Cookies from "js-cookie";
import Loader from "../Loader";

const log = logger("LeaseOverviewPdfView");
const LeaseOverviewPdfView = (props) => {
    const { isShow, handleClose, address, rentDetails } = props;
    const [property_details_id, setProperty_details_id] = useState('');
    const [pdfData, setPdfData] = useState(null);
    const [openPDFView, setOpenPDFView] = useState(false);
    const role = Number(Cookies.get("role"));
    const daysLeft = rentDetails && moment(rentDetails.lease_end_date).diff(moment(new Date()).format('YYYY-MM-DD'), 'days') + 1

    const onDownload = () => {
        // handleClose();
        setOpenPDFView(true);
    }

    useEffect(() => {
        if (property_details_id && rentDetails.lease_id) {
            let url = '';
            if (role === 2) {
                url = `/landlord/listing/lease/view-appendix/${property_details_id}/${rentDetails.lease_id}`;
            } else {
                url = `/tenant/listing/lease/view-appendix/${property_details_id}/${rentDetails.lease_id}`;
            }

            getAPI(url).then(res => {
                if (res.status === 200) {
                    const fetchedData = res.data.data;
                    setPdfData(fetchedData);
                    log.info('resp', fetchedData);
                }
            })
        }
    }, [rentDetails]);

    useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Lease for"
            subTitle={address}>
            <div className="sidepanel-body lease-pdf-view">
                {rentDetails &&
                    <div className="lease-details-card mt-4">
                        <div className="d-flex justify-content-between">
                            <span className="rent-card-title bold">Lease</span>
                            <span className="rent-card-title">{timeLeft(daysLeft)}</span>
                        </div>
                        <div className="rent-amount">
                            <span>{moment(rentDetails.lease_start_date).format('MMMM DD, YYYY')} to {moment(rentDetails.lease_end_date).format('MMMM DD, YYYY')}</span>
                        </div>
                    </div>}

                {pdfData ?
                    <Col className='confirm-section mt-3 d-flex justify-content-start'>
                        <Image className="icon" src={require("../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                        <div className='file-details'>
                            {pdfData.lease_pdf_url.split('/').pop()}<br />
                            <Image className="icon" src={require("../../../_assets/images/download.svg")} alt="Download" />
                            <span className='file-details-link' onClick={() => onDownload()}>Download</span>
                        </div>
                    </Col> : <Loader />}
            </div>
        </SidePanel>

        <ModalBox
            show={openPDFView}
            onHide={() => setOpenPDFView(false)}
            size="xl"
            className="cu-full-modal"
        >
            <PDFViewerComponent PDFUrl={pdfData && pdfData.lease_pdf_url} />
        </ModalBox>
    </>);
}
export default withRouter(LeaseOverviewPdfView);