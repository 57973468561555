import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N13Schema } from "./ValidationSchema/N13Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const N13Form = (props) => {
  const { 
    property_id, 
    property_detail_id, 
    tenant_id, 
    noticeData, 
    getParalegal,
    triggerReqTenant,
    onBack, 
    onNoticeDataUpdate
  } = props;
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);

  const [reason1, setReason1] = useState(true);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);
  const [details, setDetails] = useState("");
  const [workPlanned, setWorkPlanned] = useState("");
  const [permits, setPermits] = useState("permit1");
  const [globalState,setGlobalState]=useGlobalState()

  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])



  useEffect(() => {
    onNoticeDataUpdate({
      tenant_move_out:terminationDate,
      end_tenancy_reason_1: reason1 ? 1 : 0,
      end_tenancy_reason_2: reason2 ? 1 : 0, 
      end_tenancy_reason_3: reason3 ? 1 : 0,
      necessary_permits: permits === "permit1"
      ? 1
      : permits === "permit2"
      ? 2
      : permits === "permit3" && 3, 
      phoneError, 
      work_planned:workPlanned, 
      signData, 
      details_of_the_work:details, 
      agree_terms_and_conditions: 1,
      notice_type:13
    })
    setGlobalState({ onSubmit: onSubmit })

  }, [ tenant_id.length,property_id, terminationDate, reason1, reason2, reason3, details, phoneError, workPlanned, signData, permits])
 


  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N13Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
    const url = "/landlord/notice/store-notice";
    const data = {
      property_id: property_id,
      property_detail_id: property_detail_id,
      notice_type: 13,
      landlord_id: 1,
      tenant_move_out: terminationDate,
      end_tenancy_reason_1: reason1 ? 1 : 0,
      end_tenancy_reason_2: reason2 ? 1 : 0,
      end_tenancy_reason_3: reason3 ? 1 : 0,
      necessary_permits:
        permits === "permit1"
          ? 1
          : permits === "permit2"
          ? 2
          : permits === "permit3" && 3,
      work_planned: workPlanned,
      details_of_the_work: details,
      agree_terms_and_conditions: 1,
      first_name: signData && signData?.firstName,
      last_name: signData && signData?.lastName,
      phone_number: signData && signData?.phoneNumber,
      date: signData && signData?.signDate,
      landlord_signature: signData && signData?.full_name_sign,
      tenants: tenant_id,
      i_want_to: 14,
    };
    postAPI(url, data).then((response) => {
      if (response === undefined) {
        toaster("error", "Something went wrong!");
      } else if (response.status === 200) {
        const notice_id = response.data.data.notice_id;
        toaster("success", "Data Saved Successfully!");
        viewNotice(notice_id);
      }
    });
  }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    }
  };

  useEffect(() => {
    if (noticeData) {
      const obj={1:'permit1',2:'permit2',3:'permit3'}
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());
      setReason1(noticeData.end_tenancy_reason_1 == "0" ? false : noticeData.end_tenancy_reason_1 == "1" && true)
      setReason2(noticeData.end_tenancy_reason_2 == "0" ? false : noticeData.end_tenancy_reason_2 == "1" && true)
      setReason3(noticeData.end_tenancy_reason_3 == "0" ? false : noticeData.end_tenancy_reason_3 == "1" && true)
      setDetails(noticeData.details_of_the_work)
      setPermits(obj[noticeData.necessary_permits])
      setWorkPlanned(noticeData.work_planned)
    }
  }, [noticeData.notice_type]);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">
            Notice to End your Tenancy Because the Landlord Wants to Demolish
            the Rental Unit, <br />Repair it or Convert it to Another Use (N13 Form)
          </span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: 0 }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">Reason(s) for Ending Tenancy</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label style={{margin:'10px 0px 15px 0px'}}>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={reason1}
                  onChange={() => {
                    setReason1(!reason1);
                  }}
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: I intend to demolish the rental unit or the
                  residential complex.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={reason2}
                  onChange={() => {
                    setReason2(!reason2);
                  }}
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: I require the rental unit to be vacant in order to
                  do repairs or renovations so extensive that I am required to
                  get a building permit and the rental unit must be vacant to do
                  the work.
                  <br />
                  <br /> Note: You have the right to move back into the rental
                  unit once I have completed the repairs or renovations. If you
                  want to move back in once the work is done, you must give me
                  written notice telling me you want to move back in. Also, you
                  must keep me informed in writing any time your address
                  changes.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={reason3}
                  onChange={() => {
                    setReason3(!reason3);
                  }}
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: I intend to convert the rental unit or the
                  residential complex to a non-residential use.
                </span>
              </div>
            </Form.Group>
          </Row>

          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">
              Details about the reasons for notice
            </span>
          </div>
          <Form.Label  style={{margin:'10px 0px 15px 0px'}}>
            Described below the work I plan to do for the reason shown above,
            including specific details about how I will carry out the work.
          </Form.Label>
          <div className="event-details">
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Work Planned</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Write here"
                  name="workPlanned"
                  id="workPlanned"
                  value={workPlanned}
                  onChange={(e) => setWorkPlanned(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of the work</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details1"
                  id="details1"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Form.Group>
            </Row>
          </div>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">Necessary Permits</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
              Shaded the circle to indicate whether you have obtained any necessary building permits
              </Form.Label>
              <div className="select-radio-wrapper">
                <Form.Check
                  key={"permit1"}
                  type="radio"
                  id={`permit1`}
                  name="permits"
                  value={"permit1"}
                  checked={permits === "permit1" ? true : false}
                  onChange={() => {
                    setPermits("permit1");
                  }}
                  label={
                    "I have obtained the necessary building permits or other authorization to convert, demolish or repair the rental unit."
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-radio-wrapper">
                <Form.Check
                  key={"permit2"}
                  type="radio"
                  id={`permit2`}
                  name="permits"
                  value={"permit2"}
                  checked={permits === "permit2" ? true : false}
                  onChange={() => {
                    setPermits("permit2");
                  }}
                  label={
                    "I will obtain the necessary building permits or other authorization to convert demolish or repair the rental unit."
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-radio-wrapper">
                <Form.Check
                  key={"permit3"}
                  type="radio"
                  id={`permit3`}
                  name="permits"
                  value={"permit3"}
                  checked={permits === "permit3" ? true : false}
                  onChange={() => {
                    setPermits("permit3");
                  }}
                  label={
                    "No permits or other authorization are necessary in this case to convert the rental unit or demolish it."
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4 mb-4">
            <span className="form-title">Signature</span>
          </div>
          <SignatureForm
          phoneError={phoneError}
          noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
          disableError={(data) => disableError(data)}
          type={"lastName"}
            setSignData={(data) => setSignData(data)}
            notice_type={13}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
      <Button variant="secondary" onClick={() => onBack()}>
        Back
      </Button>
      <Button variant="success" onClick={() => onSubmit()}>
        Review
      </Button>
      </div>
    </>
  );
};

export default withRouter(N13Form);
