export const ChangePasswordValidationSchema = {
    old_password: [
        { rule: "required", message: "Old password required" },
    ],
    new_password: [
        { rule: "required", message: "New password required" },
    ],
    repeat_password: [
        { rule: "required", message: "Repeat required" },
    ],
}