import React, { useState, useEffect } from 'react';
import { Col, Row, Image, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAPI } from '../../../../Api';
import { getTenantsList } from '../../../../Store/actions/AddedTenantAction';
import { logger, toaster } from '../../../../Utils';
import { LargeButton, CancelButton, ModalBox, SidePanel, SearchInput } from '../../../components';
import './style.scss';

const log = logger('CheckListTenant');
const CheckListTenant = (props) => {
	const { isShow, setSkipOpen, setReturnPath, handleClose, apiCallback, setAddTenant, newTenantList, setSelectedTenant, selectedTenantList, setUploadLease } = props;

	const [openForOrder1Confirm, setOrder1Confirm] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [selectedData, setSelectedData] = useState(selectedTenantList || []);
	const addedTenantReducer = useSelector(state => state.addedTenantReducer.addedTenants);
	const [reducerData, setReducerData] = useState(addedTenantReducer !== null ? (addedTenantReducer[0] || []) : [])
	const [filteredReducerData, setFilteredReducerData] = useState([]);
	const [tenantList, setRentpandaTenant] = useState([]);
	const [filteredTenantList, setFilteredTenantList] = useState([]);
	const [isUpdate, setUpdate] = useState(false);
	const dispatch = useDispatch();
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const property_id = propertyDetails && propertyDetails.id;
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const property_details_id = unitDetails && unitDetails.id;
	useEffect(() => {
		if (property_id && property_details_id) {
			const url = `/landlord/users/get-tenants/${property_id}/${property_details_id}?search=${searchValue}`
			getAPI(url).then(res => {
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setRentpandaTenant(fetchedData);
				} else {
					setRentpandaTenant([])
				}
			})
		}
	}, [property_id, property_details_id, searchValue])


	useEffect(() => {
		setFilteredTenantList(tenantList)
	}, [tenantList]);

	useEffect(() => {
		setFilteredReducerData(reducerData);
	}, [reducerData])

	useEffect(() => {
		if (tenantList && newTenantList) {
 				setRentpandaTenant(state => [...state, 	...newTenantList[0]]);
				 setSelectedData(state => [...state, 	...newTenantList[0]]) 
 		}
	}, [newTenantList?.length])



	useEffect(() => {
		if (property_id && property_details_id) {
			const updatedTenants = tenantList;
			const updatedFilteredTenants = [];
			updatedTenants.forEach(tenant => {
				if (searchValue === "") {
					updatedFilteredTenants.push(tenant);
				} else {
					if (tenant.name.toLowerCase().includes(searchValue.toLowerCase())) {
						updatedFilteredTenants.push(tenant);
					}
				}
			})
			setFilteredTenantList(updatedFilteredTenants)

			const updatedReducerData = reducerData;
			const updatedFilteredReducerData = [];
			updatedReducerData.forEach(tenant => {
				if (searchValue === "") {
					updatedFilteredReducerData.push(tenant);
				} else {
					if (tenant.name.includes(searchValue)) {
						updatedFilteredReducerData.push(tenant);
					}
				}
			})
			setFilteredReducerData(updatedFilteredReducerData)

		}
	}, [property_id, property_details_id, searchValue, tenantList, reducerData]);

	useEffect(() => {
		setUpdate(false);
	}, [isUpdate]);

	useEffect(() => {
		selectedTenantList !== null && setSelectedData(selectedTenantList);
	}, [selectedTenantList]);

	const toggleConfirm = () => {
		setOrder1Confirm(!openForOrder1Confirm)
	}
	const handleCloseConfirm = () => {
		handleClose();
		if (props.isDeactivation) {
			if (props.leaseBackStep === 1) {
				props.setOpenDeactivate(true);
			} else if (props.leaseBackStep === 4) {
				setUploadLease(true);
			}
		} else if (apiCallback) {
			apiCallback();
		}
	}
	const onValueChange = (e, data) => {
		const isChecked = e.target.checked;
		const updatedSelectedData = [...selectedData];
		const currentData = updatedSelectedData.findIndex(updatedData => updatedData.tenant_id === data.tenant_id);
		isChecked === true ? updatedSelectedData.push(data) : updatedSelectedData.splice(currentData, 1);
		setSelectedData(updatedSelectedData);
		setUpdate(true);
	}

	const onSubmit = () => {
		log.info("selectedData ===> ", selectedData);
		setSelectedTenant(selectedData);
		handleClose();
		if (props.isDeactivation) {

			if (selectedData !== []) {
				if (props.clickType === 'add') { // check back step - if UPLOAD LEASE AND DETAILS
					setUploadLease(true);
				} else {
					props.setLeaseOptionsScreenFromDeactive(true)
				}
			} else {
				toaster('error', 'please select a tenant first')
			}
		} else if (setUploadLease) {
			setUploadLease(true);
		}
	}
	const addtenant = () => {
		handleClose();
		setAddTenant(true);
	}
	const OnRemove = (index) => {
		let items = [...reducerData];
		let data = [];
		log.info("items b", items);
		selectedData.splice(selectedData.indexOf(items[index]), 1);
		setSelectedData((state) => state)
		items.splice(index, 1);
		data.push(items);
		dispatch(getTenantsList(items.length !== 0 ? data : null));
		log.info("items a", data);
		setReducerData(data[0]);
		setUpdate(true);
	}
	const openSkipModal = returnPath => {
		setReturnPath(returnPath);
		setSkipOpen(true)
		handleClose();
	}

	return (<>

		<SidePanel
			isShow={isShow}
			isHide={handleClose}
			title={`${props.isDeactivation ? "Found a tenant on rent panda" : "SELECT YOUR TENANT"}`}>
			<h4 className='edit-sub'>Select tenant from your contact list</h4>
			<div className='deactive-reasons sidepanel-body checklist-tenant'>
				<SearchInput
					extraCalss={"mb-3"} searchValue={searchValue} placeHolder={`${props.isDeactivation ? "Search user" : "Type to search tenant"}`}
					setSearchValue={(data) => { setSearchValue(data) }}
				/>
				<div className={'scroller'}>
					{(isUpdate === false && filteredTenantList) && filteredTenantList.map((tenants, i) => {
						return tenants !== null && tenants !== undefined && <Row className='p-3 border-reason checkbox-left check-status' key={'tenant' + i}>
							<Col md={12} className='pl-0 pr-0'>
								<div className="form-group mb-0">
									<div className="custom-control custom-checkbox large">
										<input type="checkbox" className="custom-control-input" id={"customCheck" + tenants.tenant_id}
											name={tenants.tenant_id}
											checked={selectedData.find(elm => elm.tenant_id === tenants.tenant_id)} onChange={(e) => onValueChange(e, tenants)}
										/>
										<label className="custom-control-label pl-4" htmlFor={"customCheck" + tenants.tenant_id}>{tenants.name} {tenants.is_invited === 1 ? <span className='invited'>invited</span> : ''}</label>
									</div>
								</div>
							</Col>
						</Row>
					})}
				</div>
				{(isUpdate === false && filteredReducerData) && filteredReducerData.map((tenants, i) => {
					return tenants !== null && tenants !== undefined && <Row className='p-3 border-reason checkbox-left check-status' key={'tenant' + i}>
						<Col md={12} className='pl-0 pr-0'>
							<div className="form-group mb-0">
								<div className="custom-control custom-checkbox large">
									<div className='display-flex-inline-elm'>
										<input type="checkbox" className="custom-control-input" id={"customCheck" + tenants.tenant_id}
											name={tenants.id}
											checked={selectedData.find(elm => elm.tenant_id === tenants.tenant_id)} onChange={(e) => onValueChange(e, tenants)}
										/>
										<label className="custom-control-label pl-4" htmlFor={"customCheck" + tenants.tenant_id}>{tenants.name}{tenants.is_invited === 1 ? <span className='invited'>invited</span> : ''}</label>
										{/*tenants.is_invited === 1 &&
											<div className='sub-title d-flex justify-content-between align-items-center pt-0'>
												<Image
													className='icon cursorPointer'
													width='20px'
													height='20px'
													title='Remove'
													onClick={() => OnRemove(i)}
													src={require('../../../../_assets/images/delete.svg')}
												/>
											</div>*/}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				})}
				{(filteredTenantList && filteredTenantList.length === 0 && filteredReducerData && filteredReducerData.length === 0) && <h4 className='edit-sub text-center'>There is no tenant found</h4>}

				<LargeButton isIcon={true} title='Add tenant outside of Rent Panda' variant='dark' onClick={() => addtenant()} />
				{props.isDeactivation &&
					<Row className="skip-link">
						<Col md='12'>
							<Button variant="link" onClick={() => openSkipModal('checklistTenant')} className="skip-btn mb-4">Skip</Button>
						</Col>
					</Row>}
			</div>
			<div className='fixed-bottom d-flex justify-content-between'>
				<CancelButton onClick={() => handleCloseConfirm()} title='back' />
				<LargeButton title={`${props.isDeactivation ? "next" : "add"}`} onClick={() => onSubmit()} style={{ width: '160px' }} />
			</div>
		</SidePanel>
		<ModalBox
			show={openForOrder1Confirm}
			onHide={() => toggleConfirm()}
			size='sm'
			actionbuttontitle={'got it'}
			buttonaction={() => toggleConfirm()}
		>
			{/* {props.isDeactivation ? */}
			<>
				<h4>Congrats on renting your place!</h4>
				<p>You have uploaded a lease and sent it to the tenant for their records. make sure that you and the tenant have copies of the signed lease for your records.</p>
			</>
			{/* : <p className='deactive-confirm'>your lease has been uploaded and your property is now rented</p>
			} */}
		</ModalBox>
	</>);
}
export default withRouter(CheckListTenant);