import React from 'react';
import { withRouter } from "react-router-dom";
import { Image, Col } from 'react-bootstrap';

const Testimonials = ({userImage, testimonial, name, userType}) => {
    return (
        <Col md={4} className="testimonial mb-4 text-center">
            <Image className="testimonial-userImage" src={userImage} />
            <div className="testimonial-content">{testimonial}</div>
            <div className="testimonial-name">{name}</div>
            <div className="testimonial-userType">{userType}</div>
        </Col>
    )
};

export default withRouter(Testimonials);
