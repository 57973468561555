import React from "react";
import { AppLayout, PageTitleHeader, LargeButton } from "../..";
import "../style.scss";
import { withRouter } from "react-router-dom";

const N11Info = (props) => {
  const { getParalegal } = props;
  const back = () => {
    props.history.goBack();
  };

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          isArrow={true}
          onClick={() => back()}
          title={"Important Information About the Landlord and Tenant Board"}
          subTitle={"Agreement to End the Tenancy (N11 Form) "}
        />
        <div className="notice-info-card">
          <div className="notice-info-title">
            <span>The landlord can apply to evict the tenant</span>
          </div>
          <div className="notice-info-text">
            <span>
              After the landlord and tenant sign this agreement, the landlord
              can apply to the Board for an order evicting the tenant. The
              earliest eviction date the Board can include in its order is the
              termination date set out above.
            </span>
          </div>

          <div className="notice-info-title">
            <span>The tenant must move out</span>
          </div>
          <div className="notice-info-text">
            <span>
              The tenant must move out and remove all their personal possessions
              from the rental unit by the termination date set out above. If the
              tenant moves out by the termination date set out above, but leaves
              behind personal possessions, the tenant will no longer have any
              rights to those possessions and the landlord will be allowed to
              dispose of them.
            </span>
          </div>
          <div className="notice-info-title">
            <span>The tenant can't be required to sign this agreement</span>
          </div>
          <div className="notice-info-text">
            <span>
              A landlord cannot require the tenant to sign an N11 Agreement to
              End the Tenancy as a condition of agreeing to rent a unit.A tenant
              does not have to move out based on this agreement if the landlord
              required the tenant to sign it when the tenant agreed to rent the
              unit.
            </span>
          </div>
          <div className="notice-info-text">
            <span>
              <b>Exceptions</b>: A landlord can require a tenant to sign an N11
              Agreement to End the Tenancy as a condition of agreeing to rent a
              rental unit in the following two situations:
            </span>
            <ul>
              <li>
                <span>
                  The tenant is a student living in accommodation provided by a
                  post-secondary institution or by a landlord who has an
                  agreement with the post-secondary school to provide the
                  accommodation.
                </span>
              </li>
              <li>
                <span>
                  The tenant is occupying a rental unit in a care home for the
                  purposes of receiving rehabilitative or therapeutic services,
                  and
                </span>
              </li>
              <li>
                <span>
                  the tenant agreed to occupy the rental unit for not more than
                  4 years,
                </span>
              </li>
              <li>
                <span>
                  the tenancy agreement set out that the tenant can be evicted
                  when the objectives of providing the care services have been
                  met or will not be met, and
                </span>
              </li>
              <li>
                <span>
                  the rental unit is provided to the tenant under an agreement
                  between the landlord and a service manager under the Housing
                  Services Act, 2011.
                </span>
              </li>
            </ul>
          </div>
          <div className="notice-info-title">
            <span>Keep a copy of this agreement</span>
          </div>
          <div className="notice-info-text">
            <span>
            Both the landlord and the tenant should keep a copy of this agreement for their records.
            </span>
          </div>
          <div className="notice-info-title">
            <span>How to get more information</span>
          </div>
          <div className="notice-info-text">
            <span>
            For more information about this notice or your rights, you can contact the Landlord and Tenant Board. You can reach the Board by phone at <b> 416-645-8080  or  1-888-332-3234.</b> You can visit the Board's website at <a href='tribunalsontario.ca/ltb'>tribunalsontario.ca/ltb.</a>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end button-extra-margin">
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
          extraClass={'get-paralegal-help-btn'}

          />
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(N11Info);
