import React, { Component } from "react";
import Header from "../Header";
import { HeaderLandingPage, FooterLandingPage } from "../";
import Cookie from "js-cookie";
import { connect } from "react-redux";
import { logout } from "../../../Store/actions/AuthAction";

class Layout extends Component {
  onLogout = () => {
    Cookie.remove("access_token");
    Cookie.remove("role");
    this.props.dispatch(logout());
  };

  render() {
    const { children, newHeader } = this.props;
    return (
      <div className="landing-page-layout">
        {newHeader ? <HeaderLandingPage /> :
        <Header staticHeader={false} userData="name" onLogout={this.onLogout} />}
        {children}
        <FooterLandingPage />
      </div>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
});

export default connect(select)(Layout);
