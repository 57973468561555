import React from 'react'
import Moment from 'react-moment';
import DeleteIcon from '../../../_assets/images/red-delete-icon.svg'

function LeaseList(props) {
    const { propertyLists = [], leaseAction = () => { }, onLeaseDeleteHandler = () => { } } = props
    return (
        < >
            <table className="table-component">
                <thead>
                    <tr className="list-view-header">
                        <th>Tenant(s)</th>
                        <th>Created Date</th>
                        <th>Lease Period</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {propertyLists?.map((data) => {
                        return (
                            <tr
                                onClick={(e) => data.status !== 5 && leaseAction('create', data.lease_id, data.is_manual_uploaded, true)}
                                key={'table_body_' + data.lease_id} className={'row-wrapper ' + data.formatted_status}>
                                <td>{data.tenants && data.tenants.map((data) => {
                                    return data
                                }).join(', ')}</td>
                                <td><Moment format="MMM D, YYYY">{data.created_date}</Moment></td>
                                <td>{data.lease_start_date ?
                                    <>
                                        <Moment format="MMM D, YYYY">{data.lease_start_date}</Moment>
                                        {' - '}
                                        <Moment format="MMM D, YYYY">{data.lease_end_date}</Moment>
                                    </> : '-'}</td>
                                <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span className={'lease-status'}>{data.formatted_status}</span>

                                    {
                                        (data.status === 0 || data.status === 1) &&
                                        <span onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            onLeaseDeleteHandler(data)
                                        }}>
                                            <img src={DeleteIcon} alt="Delete Lease" />
                                        </span>
                                    }
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </ >
    )
}

export default LeaseList
