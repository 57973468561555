import React from 'react';
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap';
import { ProfileCard } from '../../../components';
import Cookies from 'js-cookie';

const VehicleInfoDisplay = (props) => {
  const { editaction } = props;
  const role = Number(Cookies.get('role'));
  const vehicleInfo = useSelector(state => state.UserProfileReducer.userProfile.vehicle_info) || props?.profileDetails?.vehicle_info
 
  return (
    <ProfileCard
      isComplete={vehicleInfo?.vehicle_status !==null}
      title={role !== 1 ? 'Vehicle Info' : 'Vehicle'}
      editaction={editaction}>

      <Container fluid>
        {vehicleInfo && vehicleInfo.vehicle_status === null ?
          <Row>
            <Col md='12' className='d-flex justify-content-center'>
              <label>No Information Provided</label>
            </Col>
          </Row>
          :
          vehicleInfo && vehicleInfo['vehicle_info'][0] && vehicleInfo.vehicle_status === 1 ?
            <>
              {role !== 1 &&
                <Row>
                  <Col md='3'>
                    <label>Make and Model</label>
                    <h3>{vehicleInfo['vehicle_info'][0].vehicle_make + ' ' + vehicleInfo['vehicle_info'][0].vehicle_model + ''}</h3>
                  </Col>
                </Row>
              }
              {role === 1 &&
                <Row>
                  <Col md='2'>
                    <label>Vehicle Make</label>
                    <h3>{vehicleInfo['vehicle_info'][0].vehicle_make}</h3>
                  </Col>
                  <Col md='2'>
                    <label>Vehicle Model</label>
                    <h3>{vehicleInfo['vehicle_info'][0].vehicle_model}</h3>
                  </Col>
                </Row>}
            </>
            :
            <Row>
              <Col md='12' className='d-flex justify-content-center'>
                <label>{role === 3 ? 'You have' : 'Tenant has'} no vehicle</label>
              </Col>
            </Row>}
      </Container>
    </ProfileCard>
  );
}

export default VehicleInfoDisplay;