import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { SidePanel, ModalBox, PDFViewerComponent } from "../..";
import { withRouter } from "react-router-dom";
import { toaster, validate } from "../../../../Utils";
import { LeasePdfViewSchema } from "./CreateLeaseSchema/LeasePdfViewSchema";
import { getPdfApi, leaseSubmit, textToImage } from "./helper";
import LeasePdf from "./LeasePDFViewComponents/LeasePdf";
import LeasePdfFooter from "./LeasePDFViewComponents/LeasePdfFooter";

const LeasePdfView = (props) => {
    const { isShow, handleClose, lease_id, role, is_signOffline, handleBack, is_sent_to_tenant, isUpdated } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [pdfData, setPdfData] = useState(null);
    const [openSucessModal, setOpenSucessModal] = useState(false);
    const [full_name_sign_Lease, setFullName] = useState('');
    const [full_name_sign_LeaseUrl, setFullNameUrl] = useState(null);
    const [openTenantSucessModal, setOpenTenantSucessModal] = useState(false);
    const [openPDFView, setOpenPDFView] = useState(false);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [loading, setLoading] = useState(false);
    const { selectedPropertyForMessage = {} } = useSelector(
        (state) => state.rawData
      );
useEffect(()=>{
    if(!isShow)
    {
        setPdfData(null)
    }

},[isShow])

    useEffect(() => {
        if (property_details_id && (lease_id && lease_id !== 0 && isShow)) {
            (async () => {
                const { response, error } = await getPdfApi(role, property_details_id, lease_id);

                if (error) {
                    return;
                }
                if (response.status === 200) {
                    setPdfData(response.data.data);
                } else if (response.response?.status === 404 && !selectedPropertyForMessage?.property_id) {
                    props.history.push({
                        pathname: '/404',
                        state: { message: 'This lease you are trying to view no longer exists.' }
                    });
                }
                else {
                    setPdfData({ lease_pdf_url: 'NOT_FOUND' })
                }
            })()
        }
    }, [lease_id, property_details_id, role, isShow, props.history]);

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id || selectedPropertyForMessage?.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id   || selectedPropertyForMessage?.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id,selectedPropertyForMessage?.property_id]);

    const createSignImage = async (value) => {
        try {
            const dataUri = await textToImage(value);
            setFullNameUrl(dataUri);
        } catch (error) { }
    }

    const onChangeInput = (e) => {
        validate(LeasePdfViewSchema)
        setFullName(e.target.value)
        createSignImage(e.target.value)
    }

    const handleCloseConfirm = (action = 'close') => {
        if (action === 'back') {
            handleClose();
            handleBack && handleBack();
        } else {
            createSignImage('');
            setFullName('');
            handleClose();
        }
    }

    const onButtonAction = () => {
        setOpenSucessModal(false);
        if (is_signOffline) {
            props.history.push({
                pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`
            })
        } else {
            backToLease();
        }
    }

    const onDownload = () => {
        setOpenPDFView(true);
    }

    const handleOpenTenantSucessModal = (data) => {
        setOpenTenantSucessModal(data);
        props.history.push({
            pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`
        })
    }

    const submit = async () => {
        setLoading(true);
        if (role === 2) {
            if (!is_sent_to_tenant || isUpdated === true) {
                const data = {
                    "lease_id": lease_id,
                    "property_id": property_id,
                    "property_detail_id": property_details_id
                }
                const res = await leaseSubmit(data, 'create-lease');
                if (res.status === 200) {
                    handleClose();
                    setOpenSucessModal(true);
                } else {
                    toaster('error', res.response.data.message);
                }
            }
            setLoading(false);

        } else if (validate(LeasePdfViewSchema)) {
            const data = {
                "lease_id": lease_id,
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "tenant_lease_signature": full_name_sign_LeaseUrl
            }
            const res = await leaseSubmit(data, 'signed-tenant-lease', 'tenant')
            if (res.status === 200) {
                handleClose();
                if(res?.data?.redirect_to_overview_page){
                    setOpenTenantSucessModal(true);
                }
            } else {
                toaster('error', res.message);
            }
            setLoading(false);
        }
    }

    const backToLease = () => {
        props.history.push({
            pathname: `/property-details/${property_id}/${property_details_id}/leases`
        })
    }

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleCloseConfirm}
            title={propertyDetails && role !== 2 ? "Lease for " + propertyDetails.address_line_1 : "CREATE A LEASE"}
            subTitle={role === 2 ? "Download The Lease" : ""}>
            <LeasePdf
                pdfData={pdfData}
                onDownload={onDownload}
                role={role}
                is_signOffline={is_signOffline}
                onChangeInput={onChangeInput}
                full_name_sign_Lease={full_name_sign_Lease}
                full_name_sign_LeaseUrl={full_name_sign_LeaseUrl}
            />
            <LeasePdfFooter
                role={role}
                handleCloseConfirm={handleCloseConfirm}
                loading={loading}
                submit={submit}
                is_signOffline={is_signOffline}
                is_sent_to_tenant={is_sent_to_tenant}
                isUpdated={isUpdated}
            />
        </SidePanel>

        {is_signOffline ?
            <ModalBox
                show={openSucessModal}
                onHide={() => setOpenSucessModal(false)}
                size="sm"
                buttonaction={() => onButtonAction()}
                // backaction={() => setOpenSucessModal(false)}
                // type={true}
                actionbuttontitle={"ok"}
                // backbuttontitle={'back'}
            >
                <>
                    <h4>Congrats on renting this home! </h4>
                    <p>This listing will be moved to the rented section of your dashboard so you can communicate with your tenant and manage the home with more tools.</p>
                </>
            </ModalBox>
            :
            <ModalBox
                show={openSucessModal}
                onHide={() => setOpenSucessModal(false)}
                size="sm"
                actionbuttontitle={'got it'}
                buttonaction={() => onButtonAction()}
            >
                <>
                    <h4>you have sent the lease to the tenant(s)</h4>
                    <p>once signed, the listing will be moved to the "rented" section of your dashboard.<br /> <br />if you've chosen to sign the lease offline, make sure to upload the final version to the listing.
                </p>
                </>
            </ModalBox>
        }
        <ModalBox
            show={openTenantSucessModal}
            onHide={() => setOpenTenantSucessModal(false)}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => handleOpenTenantSucessModal(false)}
        >
            <h4>Congrats on signing the lease and finding the perfect place to call home.</h4>
            <p>the listing has now been moved to the “my home” section of your dashboard.<br /><br />
            from here you can view the details of your lease, message the landlord, notify them of repairs and give your notice when you decide to leave the home.</p>
        </ModalBox>

        <ModalBox
            show={openPDFView}
            onHide={() => setOpenPDFView(false)}
            size="xl"
            className="cu-full-modal"
        >
            <PDFViewerComponent PDFUrl={pdfData && pdfData.lease_pdf_url} />
        </ModalBox>
    </>);
}
export default withRouter(LeasePdfView);