import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { getAPI } from '../../../../Api';
import PropertyCard from '../../PropertyCard';
import './style.scss';

const SimilarPropertyList = () => {
    const [propertyDetails, setPropertyDetails] = useState([])
    const propertyDetailId = useSelector(state => state.propertyDetailsReducer.propertyDetails.property_details_id);

    useEffect(() => {
        if (propertyDetailId && Cookies.get('access_token')) {
            const url = '/tenant/similar-listing/' + propertyDetailId;
            getAPI(url).then(response => {
                const res = response.data.data;
                setPropertyDetails(res.slice(0,2));
            })
            .catch(error => {
                // console.log(error)
            });
        }
    }, [propertyDetailId])

    const formattedDetails = (data) => {
		const {unit_no, bedrooms} = data;
        const unitNo = isNaN(unit_no) ? unit_no : `Unit ${unit_no}`;
        const bedRoom = bedrooms && (bedrooms > 1) ? (bedrooms + ' Bedrooms') : (bedrooms + ' Bedroom');
		
        let details = '';
        if (unit_no && bedrooms) {
            details = `${unitNo} • ${bedRoom}`;
        } else if (unit_no) {
            details = unitNo;
        } else if (bedrooms) {
			details = bedRoom;
		}
        return details;
    }

    const similarList =  propertyDetails.length > 0 ? 
    propertyDetails.map((data, i) =>{
        return(
            <React.Fragment key={"property" + i}>
                <PropertyCard
                    type={'list'}
                    image_url={data.image_url}
                    price={'$'+data.price_per_month+'/month'}
                    isVerifed={data.is_verified}
                    title={data.address}
                    details={formattedDetails(data)}
                    isLoveIcon={data.is_favorite}
                    grid={12}
                />
            </React.Fragment>
        )
    }) : 'No similar listing available'
    
    return (
        <div className="inner-wrapper mt-5 no-shadow" style={{ backgroundColor: 'transparent', padding: '0' }}>
            <Row className="mb-2">
                <Col md={12}>
                    <h4 className="similar-listing-title">Similar Listings</h4>
                </Col>
            </Row>
            {similarList}
        </div>
    );
}
export default SimilarPropertyList;