import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { setToggleMenu } from "../../../Store/actions/Action";
import { AdminNav, LandlordNav, TenantNav } from './constant';
import './style.scss';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { inactivePagination, listManagemantPagination, promotedPagination, rentedPagination, requestManagementPagination } from '../../../Store/actions/Action';

const Sidebar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toggleMenu = useSelector(state => state.headerReducer.toggleMenu);
  const goToLink = (link) => {
    if (link === '/request-management') {
      dispatch(requestManagementPagination(null))
    }
    if (link === '/listing-management/all-listing') {
      dispatch(listManagemantPagination(null))
    } else if (link === '/listing-management/inactive') {
      dispatch(inactivePagination(null));
    } else if (link === '/listing-management/promoted') {
      dispatch(promotedPagination(null));
    } else if (link === '/listing-management/rented') {
      dispatch(rentedPagination(null));
    }
    dispatch(setToggleMenu(!toggleMenu));
    history.push(link);
  }

  useEffect(() => {
    dispatch(setToggleMenu(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const role = Number(Cookies.get('role'));
  const Navigation = role === 1 ? AdminNav :
    role === 2 ? LandlordNav :
      role === 3 && TenantNav;

  const renderIcons = () => {
    return Navigation && Navigation.map((icon, i) => {
      return (
        <div className='sidebar-icon-holder' key={'sidebar_' + i} onClick={() => goToLink(icon.link[0])}>
          <div className={`sidebar-icon-${icon.link.includes(props.location.pathname) ? 'active' : 'inactive'}`}></div>
          <div className='d-flex sidebar-icon-position'>
            <Image
              width='20px'
              height='18px'
              alt='Vector'
              src={require(`../../../_assets/images/nav/${icon.name}-${icon.link.includes(props.location.pathname) ? 'active' : 'inactive'}.svg`)}
              className='d-inline-block align-top'
            />
            <span className={icon.link.includes(props.location.pathname) ? 'active' : 'inactive'}> {icon.title}</span>
          </div>
        </div>
      );
    });
  };

  return <div className={`sidebar-wrapper ${toggleMenu ? 'active' : ''}`}>{renderIcons()}</div>;

}

export default withRouter(Sidebar);
