import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { isCondoOption } from '../../../../../Constants/LeaseConstants';
import AutoCompleteAddress from '../../../AutoCompleteAddress';
import SingleOptionCheck from '../../../SingleOptionCheck';


function PropertyAddress(props) {
    const { contactInfo, handlePlaceChanged, setSaveEnable, updateState, OnSingleOptionCheck } = props
    return (
        <div>
            <h3 className='text-left mt-4'>rental property address</h3>
            <Row>
                <Form.Group as={Col} md='6'>
                    <AutoCompleteAddress
                        md='12'
                        as={Col}
                        id='current_address'
                        name='current_address'
                        state={contactInfo?.address}
                        updateField={(data) => updateState(data, 'address')}
                        handlePlaceChanged={(data) => handlePlaceChanged(data, 'property')}
                        addressTitle={'Address'}
                    />
                </Form.Group>
                <Form.Group as={Col} md='2'>
                    <Form.Label>Unit</Form.Label>
                    <Form.Control
                        type='text'
                        name='unit_no'
                        id='unit_no'
                        value={contactInfo?.unit_no}
                        placeholder='Type here'
                        onChange={(e) => { setSaveEnable(true); updateState(e, 'unit_no')}}
                        maxLength={15}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md='4'>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type='text'
                        name='city'
                        id='city'
                        value={contactInfo?.city}
                        placeholder='Type here'
                        onChange={(e) => updateState(e, 'city')}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md='2'>
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                        type='text'
                        name='province'
                        id='province'
                        value={contactInfo?.province}
                        placeholder='Type here'
                        onChange={(e) => updateState(e, 'province')}
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} md='2'>
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                        type='text'
                        name='postal_code'
                        id='postal_code'
                        value={contactInfo?.postal_code}
                        placeholder='Type here'
                        onChange={(e) => updateState(e, 'postal_code')}
                        readOnly
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md='6'>
                    <Form.Label>Is this rental unit a condo?</Form.Label>
                    <SingleOptionCheck
                        mapData={isCondoOption}
                        OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                        name={'is_condo'}
                        setParam={contactInfo?.is_condo}
                    />
                </Form.Group>
            </Row>
        </div>
    )
}

export default PropertyAddress
