import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { SidePanel } from "../../../components";
import "../style.scss";

const SuggestedVendors = (props) => {
  const { openSidePanel, vendors } = props;

  const redirectTo = () =>{
    props.history.push('/resources/partners')
  }
  
  return (
    <>
      <div className="request-repair-form mb-0">
        <div className="d-flex justify-content-between mb-3">
          <span className="address-text">Suggested vendors</span>
          {vendors.length!==0 && 
         
          <Link className="date-text" target={"_blank"} to='/resources/partners'>View More 
            <img
              className="pl-2 next"
              src={require("../../../../../src/_assets/images/next.svg")}
              alt='panda-partners'
            />
          </Link>
}
        </div>
        <Row>
          {vendors.map((vendor, index) => {
            return (
              <Col
                md="3"
                key={index}
                className="cursor-pointer"
                onClick={() => openSidePanel(vendor)}
              >
                <div className="d-flex align-items-start flex-column">
                  <img className="vendor-image" src={vendor?.image_url ? vendor?.image_url : require("../../../../_assets/images/placeholder.jpg")} alt="vender" />
                  <span className="image-text">{vendor.company_name}</span>
                </div>
              </Col>
            );
          })}
           <Col>{vendors.length===0 && <span className="attched-img pb-2">No Suggestions</span>}</Col>
          
        </Row>
      </div>
    </>
  );
};

export default withRouter(SuggestedVendors);
