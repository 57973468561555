import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { logger } from '../../../../Utils';
import { AdminUserDetails, AppLayout, PageTitleHeader, LargeButton, ModalBox, UnitDetailsImageCarousel } from '../../../components';
import { getAPI } from '../../../../Api';
import { dateFormat2 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import arrowForward from '../../../../_assets/images/arrow-forward.svg'
import './style.scss';

const log = logger('MaintainanceDetails');
const MaintainanceDetails = (props) => {
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [user_id, setUserId] = useState('');
	// const [path] = useState('');
	const [maintainance_id, setMaintainanceId] = useState('');
	const [user_type, setUserType] = useState('');
	const [userData, setUserData] = useState(null);
	const [maintainanceData, setMaintainanceData] = useState(null);
	const [profile, setProfile] = useState(null);
	const [action, setAction] = useState('');
	const [buttonTitle, setButtonTitle] = useState('');
	const [scrollRight, setScrollRight] = useState(200)
	const [scrollLeft, setScrollLeft] = useState(-200)

	const back = () => {
		// props.history.push({ pathname: `/profile-detail/${user_type}/${user_id}/maintainance`, state: { path: path } });
		props.history.goBack();
	}

	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === 'switch') {
			onClickSwitch(data)
			setButtonTitle('yes, switch profile');
		} else if (action === 'suspend' || action === 'active') {
			suspendUser(data);
			if (action === 'suspend') {
				setButtonTitle('yes, suspend');
			} else if (action === 'active') {
				setButtonTitle('yes, activate');
			}
		}
	}
	const onClickSwitch = (data) => {
		setProfile(data);
	}
	const suspendUser = (userData) => {
		setProfile(userData);
	}
	const goBack = () => {
		let url = '';
		if (action === 'suspend' || action === 'active') {
			if (action === 'suspend') {
				url = '/admin/users/suspend/' + user_id;
			} else if (action === 'active') {
				url = '/admin/users/activate/' + user_id;
			}
			getAPI(url).then(res => {
				let url = '';
				if (user_type === 'landlord') {
					url = `/admin/users/landlord/maintenances/view/${user_id}/${maintainance_id}`
				} else {
					url = `/admin/users/tenants/maintenances/view/${user_id}/${maintainance_id}`
				}

				getAPI(url).then(res => {
					if (res.status === 200) {
						setUserData(res.data.data.user);
						setMaintainanceData(res.data.data.maintenance);
					}
				})
				setOpenSwitchModal(false);
			})
		}
	}
	const onCompleted = () => {
		let urlComleted = '';
		if (user_type === 'landlord') {
			urlComleted = `/admin/users/landlord/maintenances/complete/${user_id}/${maintainance_id}`
		} else {
			urlComleted = `/admin/users/tenants/maintenances/complete/${user_id}/${maintainance_id}`
		}
		getAPI(urlComleted).then(res => {
			if (res.status === 200) {
				let urlInside = '';
				if (user_type === 'landlord') {
					urlInside = `/admin/users/landlord/maintenances/view/${user_id}/${maintainance_id}`
				} else {
					urlInside = `/admin/users/tenants/maintenances/view/${user_id}/${maintainance_id}`
				}

				getAPI(urlInside).then(res => {
					if (res.status === 200) {
						setUserData(res.data.data.user);
						setMaintainanceData(res.data.data.maintenance);
					}
				})
			}
		})
	}
	useEffect(() => {
		setUserId(props.match.params.user_id);
		setMaintainanceId(props.match.params.maintainance_id);
		setUserType(props.match.params.user_type)
		// setPath(props.location.state.path)
	}, [props.match.params.user_id, props.match.params.maintainance_id, props.match.params.user_type]);
	useEffect(() => {
		log.info(user_id, maintainance_id)
		if (user_id && maintainance_id) {
			if (user_id !== undefined && maintainance_id !== undefined) {
				let urlInside = '';
				if (user_type === 'landlord') {
					urlInside = `/admin/users/landlord/maintenances/view/${user_id}/${maintainance_id}`
				} else {
					urlInside = `/admin/users/tenants/maintenances/view/${user_id}/${maintainance_id}`
				}
				getAPI(urlInside).then(res => {
					if (res.status === 200) {
						setUserData(res.data.data.user);
						setMaintainanceData(res.data.data.maintenance);
					}
				})
			}
		}

	}, [user_id, maintainance_id, user_type]);

	const scrollCarouselLeft = () => {
		console.log(scrollLeft)
		scroll(scrollLeft)
		setScrollLeft(scrollLeft - 200)
		setScrollRight(scrollLeft)
	}
	const scrollCarouselRight = () => {
		console.log(document.getElementById('attched_img_carousel').scrollLeft)
		scroll(scrollRight)
		setScrollRight(scrollRight + 200)
		setScrollLeft(scrollRight)
	}

	const scroll = (amount) => {
		const slideContent = document.getElementById('attched_img_carousel')
		slideContent.scroll({ top: 0, left: amount, behavior: 'smooth' })
	}

	return (<>
		<AppLayout>
			<div >
				<PageTitleHeader
					isArrow={true}
					onClick={() => back()}
					title={'MAINTENANCE DETAILS'}
				/>
			</div>
			<div className='main-lease-container'>
				<Row className='container-detail'>
					<Col md='12'>
						<div className='card-detail d-flex justify-container-between'>
							<div className='detail-left'>
								{userData && <AdminUserDetails userDetails={userData} user_type={userData && userData.user_type} />}
							</div>
							<div className='detail-right'>
								{(userData && userData.is_active !== 'Suspended') &&
									<LargeButton onClick={() => toggleModal(userData, 'suspend')} className='suspend-btn' variant={'dark'} title='suspend user' style={{ width: '177px', float: 'right' }} />}
								{(userData && userData.is_active !== 'Active') &&
									<LargeButton onClick={() => toggleModal(userData, 'active')} className='suspend-btn' variant={'dark'} title='activate' style={{ width: '177px', float: 'right' }} />}
							</div>
						</div>
					</Col>
				</Row>
				<Row className='container-detail'>
					<Col md='12'>
						<div className='card-detail'>
							<div className='d-flex justify-content-between'>
								<div className='request-title-right'>
									<h4>Maintenance request: <span className='ml-2'>{(maintainanceData && maintainanceData.request_type_info) && maintainanceData.request_type_info.status_name}</span></h4>
								</div>
								<div className='request-title-left'>
									<span>Created on: {dateFormat2(maintainanceData && maintainanceData.created_at)}</span>
								</div>
							</div>

							<Row className='maintainanace-form-group mt-3'>
								<Col md={3}>
									{maintainanceData && maintainanceData.property &&
										<Form.Group>
											<Form.Label>Property</Form.Label>
											<h5 className='address'>{maintainanceData.property.address}</h5>
										</Form.Group>
									}
								</Col>
								<Col md={2}>
									{(maintainanceData && maintainanceData.property_tenants) &&
										<Form.Group>
											<Form.Label>Tenant Name</Form.Label>
											{maintainanceData.property_tenants.length !== 0 ?
												<h5 className='name'>
													{maintainanceData.property_tenants.map(function (obj) {
														return obj.name;
													}).join(', ')}
												</h5> : <h5 className='name'>None</h5>}
										</Form.Group>
									}
								</Col>
								<Col md={7}>
									{maintainanceData &&
										<Form.Group>
											<Form.Label>Notes</Form.Label>
											<h5 className='notes'>{maintainanceData.notes}</h5>
										</Form.Group>
									}
								</Col>
							</Row>
							{maintainanceData && maintainanceData.images &&
								<>
									<h5 className='attach-heading'>Attached Image</h5>
									<Row style={{ position: 'relative' }}>
										{maintainanceData.images.length !== 0 ?
											<ul id='attched_img_carousel' className='attached_img'>
												{maintainanceData.images.map((imgData, i) => {
													return <li key={i}>
														<Image
															src={imgData.image}
															width={138}
															height={115}
															className='rounded'
															alt={'attached_img_' + i}
														/>
													</li>
												})}
											</ul> : <div className='no-img-data' style={{ width: '100%' }}>There is no attached images</div>}
										{/* Carousel controller */}
										<div className="carousel-button-wrapper">
											<div style={{ visibility: scrollLeft >= 0 ? 'visible' : 'hidden' }} className="prev carousel-button" onClick={scrollCarouselLeft} >
												<img src={arrowForward} style={{ transform: 'rotate(180deg)' }} alt="" />
											</div>
											<div className="next carousel-button" onClick={scrollCarouselRight}>
												<img src={arrowForward} alt="" />
											</div>
										</div>
									</Row>
								</>
							}
						</div>
					</Col>
				</Row>

				{maintainanceData && maintainanceData.completed_at === null &&
					<div className='container-detail'>
						<LargeButton onClick={() => onCompleted()} title='complete request' className='suspend-btn' style={{ width: '220px', height: '48px', float: 'right', borderRadius: '100px' }} />
					</div>
				}
			</div>
		</AppLayout>
		<ModalBox
			show={openSwitchModal}
			onHide={toggleModal}
			size='sm'
			actionbuttontitle={buttonTitle}
			buttonaction={goBack}
			extraBtnStyle='extraBtnStyle'
		>
			{action === 'suspend' &&
				<p className='switching-details'>Are you sure to suspend <span className='profile-name'>{profile && profile.name}’s?</span> <br />
           They will be unable to apply to any properties within Rent Panda
            </p>}
			{action === 'active' &&
				<p className='switching-details'>Are you sure to activate <span className='profile-name'>{profile && profile.name}’s?</span> <br />
            Their rented property will remain within Rent Panda but other listing will be activated automatically
            </p>}
		</ModalBox>
	</>);
}

export default withRouter(MaintainanceDetails);
