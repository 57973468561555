import React from "react";
import { Col, Row, Form, Image } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import Cookies from "js-cookie";


const SignatureView = (props) => {
  const { noticeData, type } = props;
  const role = Number(Cookies.get('role'));

  return (<>
    <div className="mt-4">
      <span className="form-title">{type === 'tenant' ? 'Tenant Signature' : 'Signature'}</span>
    </div>
    {type === 'tenant' ?
      <>
        <Row>

          {noticeData.tenant_first_name &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                First Name
          </span>
              <span className="field-value-text">{noticeData.tenant_first_name}</span>
            </Col>}

          {noticeData.tenant_last_name &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                Last Name
          </span>
              <span className="field-value-text">{noticeData.tenant_last_name}</span>
            </Col>}

          {noticeData.tenant_phone_number &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                Phone Number
          </span>
              <NumberFormat
                format="###-###-####"
                mask="_"
                name="phoneNumber"
                id="phoneNumber"
                displayType={'text'}
                className={'field-value-text'}

                value={noticeData?.tenant_phone_number || ""}

              />
            </Col>}


          {noticeData.tenant_sign_date &&
            <Col md={3} className="d-flex flex-column">
              <span className="field-title-text">
                Date
          </span>
              <span className="field-value-text">{<Moment format="MMMM D, YYYY">{noticeData.tenant_sign_date}</Moment>}</span>
            </Col>}
        </Row>

        {noticeData.tenant_signature &&
          <Row>
            <Form.Group as={Col} md="5" className="signature mt-3">
              <Form.Label>eSignature</Form.Label>
              <br />
              <Image src={noticeData.tenant_signature} alt="Sign Image" />
            </Form.Group>
          </Row>}
      </>

      : <>
        <Row>
          {noticeData.full_name &&
            <Col md={3} className="d-flex flex-column">
              <span className="field-title-text">
                {role === 3 ? 'Landloard Signature' : 'Name Of Person Signing'}
              </span>
              <span className="field-value-text">{noticeData.full_name}</span>
            </Col>}

          {noticeData.first_name &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                First Name
          </span>
              <span className="field-value-text">{noticeData.first_name}</span>
            </Col>}

          {noticeData.last_name &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                Last Name
          </span>
              <span className="field-value-text">{noticeData.last_name}</span>
            </Col>}

          {noticeData.phone_number &&
            <Col md={2} className="d-flex flex-column">
              <span className="field-title-text">
                Phone Number
          </span>
              <NumberFormat
                format="###-###-####"
                mask="_"
                name="phoneNumber"
                id="phoneNumber"
                displayType={'text'}
                className={'field-value-text'}
                value={noticeData.phone_number || ""}
              />
            </Col>}


          {noticeData.date &&
            <Col md={3} className="d-flex flex-column">
              <span className="field-title-text">
                Date
          </span>
              <span className="field-value-text">{<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}</span>
            </Col>}
        </Row>

        {noticeData.landlord_signature &&
          <Row>
            <Form.Group as={Col} md="5" className="tenantsignature mt-3">
              <Form.Label>eSignature</Form.Label>
              <br />
              <Image style={{ width: '70%', maxWidth: 'none' }} src={noticeData.landlord_signature} alt="Sign Image" />
            </Form.Group>
          </Row>}
      </>}
  </>);
};
export default SignatureView;
