import React, { Component } from "react";
import AppLayout from "../../AppLayout";
import "./style.scss";
import axios from "axios";
import { connect } from "react-redux";
import { forgotPassword } from "../../../../Store/actions/AuthAction";
import { Redirect } from "react-router-dom";
import { getEditProfile } from "../../../../Store/actions/EditProfileAction";
import PageTitleHeader from "../../PageTitleHeader"
import { API_URL } from "../../../../Constants/endPoints";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { logger, toaster } from "../../../../Utils";
import Cookies from "js-cookie";

const log = logger("ForgetPassword");
class ForgetPassword extends Component {
  constructor(props) {
    super(props);
  }


  componentDidMount() {
    this.props.dispatch(getEditProfile());
  }

  resetPasswordViaEmail = async () => {
    const email =
      this.props.editProfileList &&
      this.props.editProfileList.data &&
      this.props.editProfileList.data.email;
    try {
      const res = await axios.post(
        API_URL + "/resend-activation-code",
        {
          email,
        }
      );
      this.props.dispatch(forgotPassword(email));
    } catch (err) { }
  };

  RedirectTo = (type) => {
    log.info("type", type);
    const role = Number(Cookies.get('role'));
    let userRole = '';
    if (role === 2) {
      userRole = 'landlord'
    } else if (role === 3) {
      userRole = 'tenant'
    }
    const url = `/${userRole}/forgot-password`;
    const data = {
      "type": type
    }
    postAPI(url, data).then(res => {
      if (res.status === 200) {
        Cookies.set("type", type, {
          expires: 1,
        });
        this.props.history.push({
          pathname: '/verify',
          state: {
            type: type
          }
        })
        toaster('success', res.data.message)
      }
    })
  };


  render() {
    if (this.props.auth.forgotPasswordEmail) {
      return <Redirect to={"/verification"} />;
    }

    return (
      <>
        <AppLayout>
          <div className="profile">
            <PageTitleHeader
              title='forgot password'

              isArrow={true}
              onClick={() => this.props.history.goBack()}
            />

            <div className="profile-container">
              <div className="profile-container-box">
                <div className="inner-wrapper">
                  <div className="profile-item">
                    <div className="image-wrapper">
                      <img
                        width="67.08"
                        height="69.99"
                        alt="change"
                        src={require("../../../../_assets/images/password.svg")}
                        className="d-inline-block align-top"
                      />
                      <span className="bl-img-title navbar-brand-name">
                        Forgot Password?
                      </span>
                      <span style={{ marginTop: '20px', fontSize: '20px' }}>How would you like us to send your password reset</span>
                    </div>

                    <div className="profile-form">
                      <div
                        className="profile-option-box"
                        onClick={() => this.RedirectTo(1)}
                      >
                        <div className="icon-wrapper">
                          <img
                            width="51.38px"
                            height="55.23px"
                            alt="smartphone"
                            src={require("../../../../_assets/images/smartphone.svg")}
                            className="d-inline-block align-top"
                          />
                        </div>
                        <div>
                          <div
                            className="option-container-heading"
                            id="sms"
                            muted
                            style={{ textAlign: "left" }}
                          >
                            Via SMS:
                              </div>
                          <div id="number">
                            <span className="option-container-subheading">
                              {this.props.auth.phone}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="profile-option-box"
                        onClick={() => this.RedirectTo(2)}
                        style={{ marginTop: "40px" }}
                      >
                        <div className="icon-wrapper">
                          <img
                            width="51.38px"
                            height="55.23px"
                            alt="smartphone"
                            src={require("../../../../_assets/images/mailbox.svg")}
                            className="d-inline-block align-top"
                          />
                        </div>
                        <div>
                          <div
                            className="option-container-heading"
                            id="sms"
                            muted
                            style={{ textAlign: "left" }}
                          >
                            Via email:
                              </div>
                          <div id="number">
                            <span className="option-container-subheading">
                              {this.props.auth.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppLayout>
      </>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
  editProfileList: state.editProfile.editProfileList,
});

export default connect(select)(withRouter(ForgetPassword));
