import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Card, Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { phoneNumberSanitizer } from '../../../Utils/NumberSanitizer/NumberSanitizer';
import { validate } from '../../../Utils/Validation/validation';
import { logger } from '../../../Utils';
import { postAPI } from '../../../Api';
import { PortalsAndAuxillarySchema } from './PortalsAndAuxillarySchema';

const log = logger("MainBanner");
const MainBanner = ({formFor, formTitle, setFormSubmitPop}) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);

    const getApiUrl = () => {
        switch(formFor) {
            case "property-managers": return '/cms/save-property-managers';
            case "advertisers": return '/cms/save-advertisement';
            case "partners": return '/cms/save-panda-partner';
            default: return '/cms/save-realtor-partner';
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validate(PortalsAndAuxillarySchema)) {
            const data = {
                "first_name": first_name,
                "last_name": last_name,
                "phone_number": phone_number,
                "email": email,
            }
            setSaveLoading(true);
            postAPI(getApiUrl(), data).then(response => {
                setSaveLoading(false);
                setFormSubmitPop(true);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
            })
        }
    }
    return (
        <div className={`banner-bg ${formFor}`}>
            <Card>
                <h2 className="formTitle">{formTitle}</h2>
                <Form id="Form" autoComplete="off">
                    <Form.Label className='title-inside'>First name:</Form.Label>
                    <Form.Control
                        type='text'
                        name={'first_name'}
                        id={'first_name'}
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    <Form.Label className='title-inside'>Last name:</Form.Label>
                    <Form.Control
                        type='text'
                        name={'last_name'}
                        id={'last_name'}
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                    />

                    <Form.Label className='title-inside'>Email:</Form.Label>
                    <Form.Control
                        type='text'
                        name='email'
                        id='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Form.Label className='title-inside'>Enter your phone:</Form.Label>
                    <NumberFormat
                        format='###-###-####'
                        mask='_'
                        name='phone_number'
                        id='phone_number'
                        className={'form-control'}
                        value={phone_number ? phone_number : ''}
                        onChange={(e) => setPhoneNumber(phoneNumberSanitizer(e.target.value))}
                    />

                    <Button className='btnFormAction' type='submit'
                        onClick={(e) => onSubmit(e)} disabled={saveLoading === true ? true : false}>
                        Let's talk
                        {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}
                    </Button>
                </Form>
            </Card>
        </div>
    )
}

export default withRouter(MainBanner);
