import "./ReportDashboard.scss"
import { Link } from "react-router-dom"
import Report from "../Report/Report"
import HouseOutline from "../../../_assets/images/reportImages/houseOutline.svg"
import readMoreArrow from "../../../_assets/images/reportImages/readMoreArrow.svg"
import blackReadMore from "../../../_assets/images/reportImages/BlackreadMoreArrow.svg"
import noResultsPanda from "../../../_assets/images/reportImages/noResults.png"
import React, { useState, useEffect } from "react"
import {Helmet} from "react-helmet";
import {HeaderLandingPage} from "../../components";
import axios from "axios"

const ReportDashboard = () => {
  const [reports, setReports] = useState([])
  const [filteredReports, setFilteredReports] = useState([])
  const [filterApplied, setFilterApplied] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [noResults, setNoResults] = useState(false)
  const [month, setMonth] = useState("any")
  const [year, setYear] = useState("any")
  const [doneLoading, setDoneLoading] = useState(false)


  useEffect(() => {
    const getReports = async () => {
      const reports = await axios.get(`https://rentpanda.ca/api/cms/rental-reports`)
          .then((res) => {
            setReports([...res.data.rental_reports])
            setFilteredReports([...res.data.rental_reports])
            setDoneLoading(true)
          })

    }
    getReports()

    const filterReports = () => {
      if (searchTerm !== "" || month !== "any" || year !== "any") {
        setFilterApplied(false)
      }
      if (filteredReports.length === 0) {
        setNoResults(true)
      }
    }
  }, [month, year])
    const monthName = (month) => {
        switch (month) {
            case 1:
            return "January"
            case 2:
            return "February"
            case 3:
            return "March"
            case 4:
            return "April"
            case 5:
            return "May"
            case 6:
            return "June"
            case 7:
            return "July"
            case 8:
            return "August"
            case 9:
            return "September"
            case 10:
            return "October"
            case 11:
            return "November"
            case 12:
            return "December"
            default:
            return "Invalid month"
        }
    }

  const renderReports = (report, index, type) => {
      let showCard = false;
      let reportCardClassName = false;
      if(type == 'header') {
        if(index == 0 || index == 1 || index == 2) showCard = true;
        
        if(index == 0) reportCardClassName = 'reportCard firstCard';
        if(index == 1) reportCardClassName = 'reportCard secondCard';
        if(index == 2) reportCardClassName = 'reportCard thirdCard';
      } else {
        showCard = true;
        reportCardClassName = 'reportCardNormal';
      }
      return (
        <>
          <div className={reportCardClassName}>
            <p className={"reportCardTitle"}>
              {monthName(report.report_month)}
              {report.report_year}
              <br/>
              canadian monthly
              rental report
            </p>
            <p className="reportCardDate">
              {report.report_month}.
              {report.report_day}.
              {report.report_year}
            </p>

            <div className="readMoreText">
              <Link to={`/rental-reports/${report.slug}`}>
                read more
                <img src={blackReadMore} alt="" />
              </Link>
            </div>
          </div>
        </>
      )
  }


  return (
    <div className={"reportPage"}>
      <React.Fragment>
        <Helmet>
          <title>Canadian Rent Report - Report Dashboard - RentPanda</title>
          <meta name="description"
                content="Discover our Rent Panda x Door Insight rent reports outlining the national rental market in Canada."
          />
        </Helmet>
      </React.Fragment>
      <HeaderLandingPage />
      <div className={"reportDashContainer"}>

        <h1>monthly rental report</h1>
        <br/>
        <br/>
        <h2>latest reports</h2>
        <div className="latestCont">
            {doneLoading && (reports.map((report, index) => renderReports(report, index, 'header')))}
        </div>

        <div className="allReportsCont">
          <div className="allReportsHeader">
            <h2>all reports</h2>
          </div>
          {noResults && (
            <div className="noResultsCont">
              <p className="noResults">sorry! no results found</p>
              <img src={noResultsPanda} alt="" />
            </div>
          )}

          <div className="allReportsOptions">

            <div className="selectCont">
              <select name="months"
                      onChange={(e) => setMonth(e.target.value)}
              >
                <option value={"november"} >
                  november
                </option>
                <option value={"december"} >
                  december
                </option>
              </select>

              <select name="year"
                      onChange={(e) => setYear(e.target.value)}
              >
                <option value={2022} >
                  2022
                </option>
                <option value={2021} >
                  2021
                </option>
              </select>

            </div>

            <input  type="text"
                    placeholder="search"
                    className={"searchInput"}
                    onChange={(e) => setSearchTerm(e.target.value)}
            />

          </div>

          <div className="reportsCont">
              {doneLoading && (reports.map((report, index) => renderReports(report, index)))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportDashboard