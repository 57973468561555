import React from "react";
import NumberFormat from "react-number-format";

const PhoneNumberFormat = (props) => {
    const { customClass, phoneNumber, renderText } = props;
    return (
        <NumberFormat
            format="###-###-####"
            mask="_"
            displayType={'text'}
            className={customClass ? customClass : ""}
            value={phoneNumber}
            renderText={renderText}
        />
    );
}
export default PhoneNumberFormat;


