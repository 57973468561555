import React from 'react';
import DatePicker from 'react-datepicker';
import './style.scss';

const DatePickerComponent = (props) => {
    const { popperPlacement, onSelect=()=>{}, disableType=false, handleDateChange, clear, extraClassDatePicker, extraClass, value, name, id, minDate, maxDate, displayText, required, format, disableValue, openToDate, highlightDates, showMonthDropdown = false, showYearDropdown = false } = props;
    return (
        <div className={'rdt reactdatepicker ' + extraClassDatePicker}>
            <DatePicker
                selected={value}
                onChange={(e) => {
                    if(!disableType)
                    {
                        handleDateChange(e)
                        return
                    }    
                    if(value)
                    {
                        onSelect(e)
                    }
                    // (!disableType) && handleDateChange(e)}
                }
                }
                onKeyDown={(e) => disableType && e.preventDefault()}
                onSelect={onSelect}
                name={name}
                id={id}
                className={'form-control cursor-pointer ' + extraClass}
                maxDate={maxDate}
                placeholderText={displayText}
                minDate={minDate}
                required={required}
                dateFormat={(format) ? format : 'yyyy-MM-dd'}
                popperPlacement={popperPlacement ? popperPlacement : 'bottom-end'}
                autoComplete='off'
                popperModifiers={{
                    flip: {
                        behavior: ['bottom']
                    },
                    preventOverflow: {
                        enabled: false
                    },
                    hide: {
                        enabled: false
                    }
                }}
                disabled={disableValue}
                isClearable={clear}
                openToDate={openToDate}
                highlightDates={highlightDates}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode="select"
            />
        </div>
    );
}
export default DatePickerComponent;