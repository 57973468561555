import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NumberFormat from "react-number-format";
import { Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import Geocode from 'react-geocode';
import { Google_Map_API } from '../../../Constants/endPoints';
import Layout from '../../components/Layout';
import AutoCompleteAddress from '../../../App/components/AutoCompleteAddress';
import { postAPI } from '../../../Api';
import { logger, toaster } from '../../../Utils';
import { validate } from '../../../Utils/Validation/validation';
import { InquiryValidationSchema } from './InquiryValidationSchema';
import CustomRadio from './CustomRadio';

import '../style.scss';

const log = logger('Property Mangament - Inquiry Form');
const Inquiry = (props) => {
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [place, setPlace] = useState('');
    const [status, setStatus] = useState('');
    const [day, setDay] = useState('');
    const [time, setTime] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [responseData, setResponseData] = useState([]);
    
    const back = () => props.history.goBack();
    
    useEffect(() => {
        if (propertyDetails && propertyDetails.id) {
            getAddress(propertyDetails.latitude, propertyDetails.longitude)
        }
    }, [propertyDetails]);

    const getAddress = (latitude, longitude) => {
        Geocode.setApiKey(Google_Map_API);
        Geocode.fromLatLng(latitude, longitude).then(
            response => {
                setPlace(response.results[0])
            },
            error => {
                console.error(error);
            }
        );
    }
    const inquiryForm = useFormik({
        initialValues: {
            contact_full_name: '',
            contact_number: '',
            contact_email: '',
            full_property_address: '',
            longitude: '',
            latitude: '',
            property_status: '',
            contact_day: '',
            contact_time: ''
        },
        onSubmit: values => {
            handleFormSubmit(values);
        },
    });

    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        const latValue = place.geometry.location.lat();
        const lngValue = place.geometry.location.lng();
        inquiryForm.values.full_property_address = address;
        inquiryForm.values.longitude = latValue;
        inquiryForm.values.latitude = lngValue;
        setAddress(address);
    }
    const handleChange = event => {
        setAddress(event.target.value);
    }

    useEffect(() => {
        inquiryForm.values.contact_number = plainNumber(number);
        inquiryForm.values.contact_full_name = name;
        inquiryForm.values.contact_email = email;
        inquiryForm.values.contact_day = day;
        inquiryForm.values.contact_time = time;
        initStatus(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, email, time, day, status, number]);
    const plainNumber = (number) => number && Number(number.match(/\d/g).join(''));
    const initStatus = (status) => {
        let radios = document.getElementsByName("property_status");
        if (status === '') {
            radios.forEach(radio => radio.checked = (radio.value === 'Vacant') )
            inquiryForm.values.property_status = 'Vacant';
        } else {
            inquiryForm.values.property_status = status;
        }
    }
    
    const handleFormSubmit = (data) => {
        if (validate(InquiryValidationSchema)) {
            const createUrl = '/cms/property-management-inquiry';
            postAPI(createUrl, data).then((response) => {
                if (response.status === 200) {
                    const res = response.data.data;
                    toaster("success", 'inquiry sent');
                    setResponseData(res);
                    setSuccessMessage(true);
                    setName('');
                    setNumber('');
                    setEmail('');
                    setAddress('');
                    setStatus('');
                    setDay('');
                    setTime('');
                } else if (response.response.status === 422) {
                    const errorMsg = response.response.data.message;
                    log.info("error message: ", errorMsg);
                    toaster('error', errorMsg);
                    const errorEntries = Object.entries(response.response.data.errors);
                    for (const [errorId, message] of errorEntries) {
                        const errorBox = document.getElementById(`error_${errorId}`);
                        errorBox.innerText = message;
                        errorBox.style.display = "block";
                    }
                }
            });
        }
    }

    return (
        <Layout newHeader={true}>
            <div className="inquiry-form-page py-5">
                <Container className="inquiry-form-container py-5">
                    <Row className="inquiry-form-page-title">
                        {successMessage 
                        ?   <Col sm={10} md={8} lg={6} className="success-head">
                                <h1>We’ll get in touch soon!</h1>
                                <p>Thanks for submitting your request for more info on<br />property management services.</p>
                            </Col>
                        :   <Col md={11} lg={7}>
                                <h1>finding the best tenants and managing your property is what we do!</h1>
                                <p>Fill out the info below and our team will give you a<br />call to discuss your property management needs.</p>
                            </Col>
                        }
                    </Row>
                    {successMessage 
                    ?   <div className='success-content'>
                            <Row>
                                <Col sm={10} md={8} lg={6}>
                                    <Card>
                                        <h2>Your Contact Details</h2>
                                        {responseData &&
                                            <div className="card-data">
                                                <div className="name">
                                                    <img src={require('../../../_assets/images/user.png')} alt="user" />
                                                    {responseData.contact_full_name}
                                                </div>
                                                <div className="number">
                                                    <img src={require('../../../_assets/images/phone-icon.svg')} alt="phone" />
                                                    <NumberFormat
                                                        format="###-###-####"
                                                        type="phone"
                                                        value={responseData.contact_number}
                                                        displayType="text"
                                                    />
                                                </div>
                                                <div className="email">
                                                    <img src={require('../../../_assets/images/mail-icon.svg')} alt="email" />
                                                    {responseData.contact_email}</div>
                                                <div className="address">
                                                    <img src={require('../../../_assets/images/map-icon.svg')} alt="address" />
                                                    {responseData.full_property_address}
                                                    <span>({responseData.property_status})</span>
                                                </div>
                                            </div>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={10} md={8} lg={6}>
                                    <Button type="submit" onClick={back}>Back To Dashboard</Button>
                                </Col>
                            </Row>
                        </div>
                    :   <Row className="inquiry-form">
                            <Col sm={10} md={8} lg={6}>
                                <Form id="Form" onSubmit={inquiryForm.handleSubmit}>
                                    <Form.Group md={12}>
                                        <Form.Label>Your Full Name</Form.Label>
                                        <Form.Control type="text" placeholder="Jane Doe"
                                            id="contact_full_name"
                                            name="contact_full_name"
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Form.Group>

                                    <Form.Group md={12}>
                                        <Form.Label>Phone Number</Form.Label>
                                        <NumberFormat
                                            format="###-###-####"
                                            mask="_"
                                            placeholder="123-123-1234"
                                            name="contact_number"
                                            id="contact_number"
                                            className="form-control bg-pf-ip profile-input"
                                            type="phone"
                                            value={number}
                                            onChange={e => setNumber(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group md={12}>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="name@mail.com"
                                            id="contact_email"
                                            name="contact_email"
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </Form.Group>

                                    <Form.Group md={12}>
                                        <AutoCompleteAddress
                                            id={'full_property_address'}
                                            name={'full_property_address'}
                                            state={address}
                                            updateField={(data) => handleChange(data)}
                                            handlePlaceChanged={(data) => handlePlaceChanged(data)}
                                            placeholder='Google Address'
                                            addressTitle={'Property Address'}
                                        />
                                    </Form.Group>

                                    <Form.Group md={12}>
                                        <Form.Label>Select the status of this property</Form.Label>
                                        <div className="custom-radio-container">
                                            <CustomRadio
                                                extraClass="custom-radio"
                                                label={"Vacant"}
                                                name={"property_status"}
                                                onChange={e => setStatus(e.target.checked && e.target.value)}
                                                />
                                            <CustomRadio
                                                extraClass="custom-radio"
                                                label={"Tenanted"}
                                                name={"property_status"}
                                                onChange={e => setStatus(e.target.checked && e.target.value)}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group md={12}>
                                        <Form.Label>When would it be best to contact you</Form.Label>
                                        <Row>
                                            <Col md={6} className="ddlParent">
                                            <select
                                                name="contact_day"
                                                id="contact_day"
                                                value={day}
                                                onChange={(e) => setDay(e.target.value)}
                                                className="selectdropdown"
                                            >
                                                <option value="">Select</option>
                                                <option value="Anytime">Anytime</option>
                                                <option value="Weekday">Weekday</option>
                                                <option value="Weekend">Weekend</option>
                                            </select>
                                            </Col>
                                            <Col md={6} className="ddlParent">
                                                <select
                                                    name="contact_time"
                                                    id="contact_time"
                                                    value={time}
                                                    onChange={(e) => setTime(e.target.value)}
                                                    className="selectdropdown"
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Anytime">Anytime</option>
                                                    <option value="Morning">Morning</option>
                                                    <option value="Afternoon">Afternoon</option>
                                                    <option value="Evening">Evening</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Row>
                                        <Col md={12}>
                                            <Button type="submit">Submit Inquiry</Button>
                                        </Col>
                                        <Col md={12}>
                                            <Button className="back-btn" onClick={back}>Back</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </Layout>
    )
}

export default withRouter(Inquiry);
