import { getAPI, postAPI } from '../../../Api';
import store from '../../../configureStore';
import { updateRawData } from '../../../Store/actions/RawDataAction';
import { diffDate, toaster } from '../../../Utils';

export const getTenancyApplicatioinDetails = async (tenancyApplicationId) => {
  try {
    const url = `/landlord/tenant-application-details/${tenancyApplicationId}`;
    const res = await getAPI(url);
    return res.status === 200 ? res.data.data : null;
  } catch (err) {
    return null;
  }
};

export const removeFromContact = async (conversation_id, listData) => {
  try {
    const url = '/landlord/remove-from-contact-list';
    const data = {
      conversation_id: conversation_id,
    };
    const res = await postAPI(url, data);
    if (res.status === 200) {
      const newListData = listData.filter((data) => {
        return data.conversation_id !== conversation_id;
      });
      return newListData;
    }
    return listData;
  } catch (error) {
    return listData;
  }
};

export const getQueyParam = (queryString, param) => {
  const parameters = new URLSearchParams(queryString);
  const value = parameters.get(param);
  return value;
};
export const getTenantListApi = async (
  key,
  property_id,
  property_details_id,
  getUpto = false
) => {
  try {
    const { current_page = 0, last_page } = store.getState().rawData || {};
    const url = `/landlord/get-tenants/${property_id}/${property_details_id}?${
      getUpto ? 'get_upto' : 'page'
    }=${getUpto ? current_page||1: current_page +1} `;
    console.log({url})
    if (current_page !== last_page || getUpto) {
      const response = await getAPI(url);
      if (!getUpto) {
        updateRawData({
          current_page: current_page + 1,
          last_page: response.data?.data?.last_page,
        });
      }
      return response.data.data.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const sendMessageAPI = async (url, data) => {
  try {
    const response = await postAPI(url, data);
    return response.status === 200
      ? true
      : toaster('error', response.response.data.message);
  } catch (err) {
    return false;
  }
};

export const deleteLeaseAPI = async (payload) => {
  try {
    const res = await postAPI(`/landlord/listing/lease/retract`, payload);
    return res;
  } catch (err) {
    return false;
  }
};

export const getMessagesAPI = async (url) => {
  try {
    const res = await getAPI(url);
    updateRawData({ consentStatus: res?.data?.data?.consentStatus });
    return res.data.data;
  } catch (err) {
    return [];
  }
};

export const getLeaseList = async (
  property_id,
  property_details_id,
  apiUrl
) => {
  try {
    const url =
      apiUrl ||
      `/landlord/listing/lease/lease-list/${property_id}/${property_details_id}`;
    const res = await getAPI(url);
    return res.status === 200 ? res.data.data : [];
  } catch (err) {
    return [];
  }
};

export const blockUserAPI = async (url, conversation_id) => {
  try {
    const data = {
      conversation_id: conversation_id,
    };
    const res = await postAPI(url, data);

    return res.status === 200;
  } catch (err) {
    return false;
  }
};

export const getSelectedTenantProfile = async (tenantId, conversation_id) => {
  try {
    const response = await getAPI(
      `/landlord/tenant-profile/${tenantId}?conversation_id=${conversation_id}`
    );
    const res = response.data.data;
    return res;
  } catch (err) {
    return null;
  }
};

export const isProfileCompletedForSelectedTenant = (profileInfo = {}) => {
  const { profile_statuses } = profileInfo;
  profile_statuses.past_address = true;
  return Object.values(profile_statuses).every((i) => i);
};

export const sortTenant = (list = [], direction) => {
  return list.sort((itemOne, itemTwo) => {
    return diffDate(itemOne?.latest_massage_date_time, itemTwo?.latest_massage_date_time, direction);
  });
};
