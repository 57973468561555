import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import AdminLandlordProfile from "./AdminLandlordProfile";
import AdminTenantProfile from "./AdminTenantProfile";

const ProfileDetail = (props) => {
    const [user_type, setUser_type] = useState('');
    useEffect(() => {
        if (props.match.params.user_id) {
            const userType = props.match.params.user_type;
            setUser_type(userType);
        }
    }, [props])

    return (<>
        {user_type === "landlord" && <AdminLandlordProfile />}
        {user_type === "tenant" && <AdminTenantProfile />}

    </>);
}

export default withRouter(ProfileDetail);
