export const SchoolDataValidation = {
    school_attend: [
        { rule: "required", message: "School name is required" },
    ],
    program_taking: [
        { rule: "required", message: "Program name is required" },
    ],
    start_date: [
        { rule: "required", message: "Start Date is required" },
    ],
    end_date: [
        { rule: "required", message: "End Date is required" },
    ]
}
