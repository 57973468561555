import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const UnitAddressContentLoader = (props) => {
    const loop = 4;
    return (
        <div className="inner-wrapper">
            <Row>
                <Col md="10">
                    <h2>
                        <ContentLoader height={25} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="25" />
                        </ContentLoader>
                    </h2>
                </Col>
            </Row>
            <h4>
                <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                    <rect x="0" y="0" rx="0" ry="0" width="30%" height="15" />
                </ContentLoader>
            </h4>
            <ul className='features'>
                {(() => {
                    const options = [];
                    for (let i = 0; i < loop; i++) {
                        options.push(<React.Fragment key={i}><RowList /></React.Fragment>);
                    }
                    return options;
                })()}
            </ul>
        </div>
    )
}
export default UnitAddressContentLoader;

const RowList = (props) => {
    return (
        <li>
            <ContentLoader height={30} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
                <rect x="0" y="0" rx="5" ry="5" width="20%" height="30" />
                <rect x="80" y="5" rx="0" ry="0" width="40%" height="15" />
            </ContentLoader>
        </li>
    )
}

