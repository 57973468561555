import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getAPI } from '../../../../../Api';
import { logger } from '../../../../../Utils';
import { LargeButton, SidePanel } from '../../../../components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const log = logger('PublishDND');
const PublishDND = (props) => {
    const { isShow, handleClose, viewData } = props;
    const [faqList, setFaqList] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    log.info(faqList)
    const publishSubmit = () => {
        const sortData = []
        faqList.length !== 0 && faqList.map((data, idx) => {
            const obj = {
                "id": data.id,
                "sort_order": idx + 1
            }
            sortData.push(obj);
        })
        log.info('sortData', sortData)
        const data = {
            "data": sortData
        }
        setSaveLoading(true);
        props.openPublishModal(true, data)
        handleClose();
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(faqList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFaqList(items);
    }
    useEffect(() => {
        if (isShow === true) {
            let url = `/admin/cms/faq/list`;
            getAPI(url).then(response => {
                log.info(response.data.data)
                setFaqList(response.data.data);
            })
        }
    }, [isShow])
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title='Manage orders'
        >
            <div className='deactive-reasons sidepanel-body'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="faqList">
                        {(provided) => (
                            <ul className="faqList" {...provided.droppableProps} ref={provided.innerRef}>
                                {faqList.map(({ id, title }, index) => {
                                    return (
                                        <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <Image className="icon" width='20' height={'24'} src={require("../../../../../_assets/images/dnd-icon.svg")} alt="dnd-icon" />
                                                            <p className='ml-3'>{title}</p>
                                                        </div>
                                                        {clickedData === null && <>
                                                            {(viewData && viewData.id === id) && <span>new</span>}
                                                        </>}

                                                    </div>

                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className="fixed-bottom">
                <div className="pull-right">
                    <LargeButton title='publish' saveLoading={saveLoading} onClick={() => publishSubmit()} style={{ width: '200px' }} />
                </div>

            </div>
        </SidePanel>
    </>);
}
export default withRouter(PublishDND);