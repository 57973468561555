import {
    SET_TOGGLE_MENU,
} from '../actions/Action';

export const initialState = {
    toggleMenu: false,
}

export default function ListingReducer(state = initialState, action) {
    const handlers = {
        [SET_TOGGLE_MENU]: (state) => ({
            ...state,
            toggleMenu: action.toggleMenu,
        }),
    };
    const handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action) };
}