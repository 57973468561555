import React, { useEffect, useState } from 'react';
import { updateRawData } from '../../../../Store/actions/RawDataAction';
import SingleOptionCheck from '../../SingleOptionCheck';

const MAP = {
  1: 'morning',
  2: 'afternoon',
  3: 'evening',
  4: 'weekdays',
  5: 'weekends',
};

const TIMING = [
  { title: 'Morning', value: 1 },
  { title: 'Afternoon', value: 2 },
  { title: 'Evening', value: 3 },
];
const DAYS = [
  { title: 'Weekdays', value: 4 },
  { title: 'Weekend', value: 5 },
];
function TenantRequestShowing() {
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  useEffect(() => {
    updateRawData({
      selectedTime: MAP[selectedTime],
      selectedDay: MAP[selectedDay],
    });
  }, [selectedTime, selectedDay]);

  return (
    <div className={'tenant_showing'}>
    What Time Of Day Works Best For You?
      <SingleOptionCheck
        mapData={TIMING}
        OnSingleOptionCheck={(event) => {
          setSelectedTime(event.target.value);
        }}
        name={'timing'}
        setParam={selectedTime}
      />
      <div style={{ marginTop: 20 }}>
        What Time Of The Week Works Best For You?
        <SingleOptionCheck
          mapData={DAYS}
          OnSingleOptionCheck={(event) => {
            setSelectedDay(event.target.value);
          }}
          name={'days'}
          setParam={selectedDay}
        />
      </div>
    </div>
  );
}

export default TenantRequestShowing;
