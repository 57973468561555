export const sort_price = [
    { value: 'all', display: 'View All' },
    { value: 'high_to_low', display: 'Highest to lowest' },
    { value: 'low_to_high', display: 'Lowest to highest' }
];
export const sort_tenant = [
    { value: 'all', display: 'View All' },
    { value: 'not_tenanted', display: 'Not Tenanted' },
    { value: 'tenanted_only', display: 'Tenanted Only' }
];
export const sort_status = [
    { value: 'all', display: 'View All', isChecked: false },
    { value: '3', display: 'Per Room', isChecked: false },
    { value: '2', display: 'Per Unit', isChecked: false },
    { value: '1', display: 'Full Property', isChecked: false }
];
export const sort_status_all = [
    // { value: '0', display: 'Verified', isChecked: false },
    { value: '3', display: 'Needs Approval', isChecked: false },
    { value: '1', display: 'For Rent', isChecked: false },
    { value: '4', display: 'Rented', isChecked: false },
    { value: '2', display: 'In Progress', isChecked: false },
    { value: '5', display: 'Deactivated', isChecked: false },
    { value: 'all', display: 'View All', isChecked: false }
];
export const sort_time = [
    { value: 'all', display: 'View All' },
    { value: 'latest_to_earliest', display: 'Latest to Earliest' },
    { value: 'earliest_to_latest', display: 'Earliest to Latest' }
];
export const sort_date = [
    { value: 'all', display: 'View All' },
    { value: 'earliest_to_latest', display: 'Oldest to Newest' },
    { value: 'latest_to_earliest', display: 'Newest to oldest' }
];
export const sort_status_showing = [
    { value: 'all', display: 'View All', isChecked: false },
    { value: 'confirmed', display: 'Confirmed', isChecked: false },
    { value: 'pending', display: 'Pending', isChecked: false }
];

export const leaseDuration = [
    { value: 'all', display: 'View All' },
    { value: 1, display: '1 year lease' },
    { value: 2, display: 'Month to month' },
    { value: 0, display: 'Other' }
];

export const sort_status_lease = [
    { value: 'Pending', display: 'Pending', isChecked: false },
    { value: 'Signed', display: 'Signed', isChecked: false },
    { value: 'In progress', display: 'In Progress', isChecked: false },
    { value: 'all', display: 'View All', isChecked: false },
];

export const rentSorting = [
    { value: 'all', display: 'View All' },
    { value: 'highest_to_lowest', display: 'Highest to Lowest' },
    { value: 'lowest_to_highest', display: 'Lowest to Highest' }
];

export const roleSorting = [
    { value: 'all', display: 'View All' },
    { value: 'landlord', display: 'Landlords Only' },
    { value: 'tenant', display: 'Tenants Only' }
];
export const requesttypeSorting = [
    { value: '1', display: 'Realtor Partner' },
    { value: '2', display: 'Upload Portfolio - Property Manager' },
    { value: '3', display: 'Advertising' },
    { value: '4', display: 'Panda Partner' },
    { value: '5', display: 'Professional Photoshoot' },
    { value: '9', display: 'Professional Video Shoot' },
    { value: '6', display: 'Paralegal Consulting' },
    { value: '7', display: 'Full Service Inquiry' },
    { value: '8', display: 'Listing Promotion' },
    { value: '10', display: 'Free Rent Assessment' },
    { value: '11', display: 'Grove Property Request' },
    { value: 'all', display: 'View All' },
];
export const dateTimeSorting = [
    { value: 'daytime', display: 'Daytime' },
    { value: 'morning', display: 'Morning' },
    { value: 'afternoon', display: 'Afternoon' },
    { value: 'evening', display: 'Evening' },
    { value: 'all', display: 'View All' },
];
export const requestStatusSorting = [
    { value: '0', display: 'Needs Attention' },
    { value: '1', display: 'Completed' },
    { value: '2', display: 'Assigned to me' },
    { value: 'all', display: 'View All' },
];

export const maintainanceTypeSorting = [
    { value: '1', display: 'Plumbing' },
    { value: '2', display: 'Appliance' },
    { value: '3', display: 'Lawn Care' },
    { value: '4', display: 'Snow removal' },
    { value: '5', display: 'Lighting' },
    { value: '6', display: 'Locks' },
    { value: '7', display: 'Doors / Windows' },
    { value: '8', display: 'Smoke / CO2 Alarm' },
    { value: '9', display: 'General Maintenance' },
    { value: '10', display: 'Other' },
    { value: 'all', display: 'View all' },
];

export const createdCompletedSort = [
    { value: '', display: 'View All' },
    { value: 'asc', display: 'Oldest to Newest' },
    { value: 'desc', display: 'Newest to oldest' }
];

export const maintainanceStatus = [
    { value: '', display: 'View All' },
    { value: '0', display: 'Pending' },
    { value: '3', display: 'Completed' }
];
export const noticeStatusConst = [
    { value: 2, display: 'Pending' },
    { value: 3, display: 'Completed' },
    { value: 'all', display: 'View All' }
];
export const inspectionType = [
    { value: '1', display: 'Move In Inspection' },
    { value: '2', display: 'Move Out Inspection' },
    { value: '', display: 'View All' }
];
export const inspectionStatus = [
    { value: '1', display: 'In Progress' },
    { value: '2', display: 'Completed' },
    { value: '', display: 'View All' }
];

export const sort_notifiication = [
    { value: 'lease', display: 'Lease', isChecked: false },
    { value: 'notice', display: 'Notices', isChecked: false },
    { value: 'maintenance', display: 'Maintenance', isChecked: false },
    { value: 'request', display: 'Request', isChecked: false },
    { value: 'inspection', display: 'Inspection', isChecked: false },
    { value: 'all', display: 'View All', isChecked: false }
];

export const whoCanUseCoupon = [
    { value: 1, title: 'All User' },
    { value: 2, title: 'Landlord Only' },
    { value: 3, title: 'Tenant Only' }
]

export const limitCouponBy = [
    { value: 1, title: 'Total Uses' },
    { value: 2, title: 'Use Per User' },

]

export const sort_content_type = [
    { value: 'Article', display: 'Article', isChecked: false },
    { value: 'Form', display: 'Form', isChecked: false },
    { value: 'FAQ', display: 'FAQ', isChecked: false },
    { value: 'Partner', display: 'Partner', isChecked: false },
    { value: 'all', display: 'View All', isChecked: false }
]
export const sort_cms_status = [
    { value: '0', display: 'Draft', isChecked: false },
    { value: '2', display: 'Unpublished', isChecked: false },
    { value: '1', display: 'Published', isChecked: false },
    { value: 'all', display: 'View All', isChecked: false }
]