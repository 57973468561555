import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import moment from 'moment'
import SignatureView from "../Signature/SignatureView";
import Cookies from 'js-cookie';

const N6DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice to End your Tenancy</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <span className="form-title">
          For Illegal Acts or Misrepresenting Income in a Rent-Geared-to-Income Rental Unit
        </span>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            Deadline (Date of termination if tenant fails)
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Reason(s) for Ending Tenancy</span>
        </div>
        {noticeData.end_tenancy_reason_1 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: I believe that you or someone living with you has
                  committed an illegal act or is carrying on an illegal business
                  at the residential complex involving:
                  <ul>
                    <li>the production of an illegal drug,</li>
                    <li>trafficking in an illegal drug,</li>
                    <li>
                      possession of an illegal drug for the purposes of
                      trafficking.
                    </li>
                  </ul>
                  or
                  <br />I believe that you or someone living with you has
                  permitted someone else to carry out one or more of the above
                  illegal activities involving drugs in the residential complex.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_2 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={noticeData.end_tenancy_reason_2 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: I believe that you or someone living with you has
                  committed an illegal act or is carrying on an illegal business
                  at the residential complex (other than an illegal act or
                  business described in Reason 1),
                  <br />
                  or
                  <br />I believe that you or someone living with you has
                  permitted someone else to commit an illegal act or carry on an
                  illegal business at the residential complex (other than an
                  illegal act or business described in Reason 1).
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_3 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={noticeData.end_tenancy_reason_3 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You live in a rent-geared-to-income rental unit and
                  I believe that you have misrepresented your income or the
                  income of family members who live in the rental unit.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">
            Details about the reasons for notice
          </span>
        </div>
        {noticeData.notice_reasons.map((event, index) => {
          return <div className="event-details mb-3" key={index}>
            <span className="event-title">Event {index + 1} Details</span>
            <div className="d-flex flex-column">
              <span className="field-title-text">Date And Time</span>
              <span className="field-value-text">{moment(event.date).format('LL')}, {event.time}</span>
              <span className="field-title-text">Details Of Event</span>
              <span className="field-value-text">
                {event.details}
              </span>
            </div>
          </div>
        })}

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N6DetailsView);
