import moment from 'moment';
import { getAPI, postAPI } from '../../../Api';

export const getRentOverURL = ({
  leaseId,
  role,
  property_id,
  property_details_id,
}) => {
  if (role === 2) {
    return `/landlord/listing/lease/my-panda/${property_id}/${property_details_id}`;
  } else {
    if (leaseId) {
      return `/tenant/listing/lease/overview/${property_id}/${property_details_id}/${leaseId}`;
    } else {
      return `/tenant/listing/lease/overview/${property_id}/${property_details_id}`;
    }
  }
  return '';
};

export const setRelistingProperty = async (
  property_id,
  property_details_id,
  nextAvailableDate
) => {
  const url = `/landlord/relist-property`;
  const data = {
    property_id: property_id,
    property_details_id: property_details_id,
    available_date: moment(nextAvailableDate).format('YYYY-MM-DD'),
  };
  try {
    const response = await postAPI(url, data);
    if (response.status === undefined || response.status !== 200) {
      return { status: 'error', message: response.response.data.errors };
    } else {
      // return response.data.message
      return { status: 'success', message: response.data.message };
    }
  } catch (error) {
    return 'Something went wrong!';
  }
};

export const getLeaseData = async (url) => {
  try {
    const res = await getAPI(url);
    return res.data.data;
  } catch (err) {
    return {};
  }
};
export const relistProperty = async (property_id, property_detail_id) => {
  try {
    const res = await postAPI('/landlord/relist-listing', {
      property_id,
      property_detail_id,
    });

    return res?.data?.data;
  } catch (err) {
    return false;
  }
};
