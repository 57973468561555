import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import './style.scss';
import {
  UnitAddress,
  UnitDescription,
  UnitDetails,
  UnitImageDisplay,
  SimilarPropertyList,
  Loader,
  ModalBox,
} from '../../components';
import { logger, toaster } from '../../../Utils';
import { getAPI, postAPI } from '../../../Api';
import ReactPlayer from 'react-player/lazy';
import StaticMap from '../../components/StaticMap';
import { getGalaryItems } from './helper';
import { Col, Row } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPropertyDetail,
  getUnitDetail,
} from '../../../Store/actions/PropertyDetailAction';

const log = logger('TenantPropertyDetails');
const TenantPropertyDetails = (props) => {
  const { callbackRequestShowing, mapHeight = '70vh', isFromRedux ,isDashboard} = props;
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
  const [componentLoad, setProperty_component_load] = useState('');
  const [is_showingRequest, setShowingRequest] = useState(false);
  // const [email, setEmail] = useState(false);
  const [imagesGallary, setImagesGallary] = useState(null);
  const [is_showGallery, setShowGallery] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showVideo, setshowVideo] = useState({});

  const dispatch = useDispatch();
  const role = Number(Cookies.get('role'));

  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );
  // log.info('propertyDetails', propertyDetails);

  useEffect(() => {
    if (isFromRedux) {
      if(propertyDetails != null && propertyDetails?.images != null && propertyDetails?.videos != null) {
        if(propertyDetails?.images.length > 0 || propertyDetails?.videos.length > 0) {
          const galaryItems = getGalaryItems(
            propertyDetails?.images,
            propertyDetails?.videos,
            renderVideo
          );
          setImagesGallary(galaryItems);
        }
      }
    }
  }, [isFromRedux,propertyDetails]);

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
    setProperty_component_load(props.match.params.slug);
  }, [props]);

  useEffect(() => {
    if (property_details_id) {
      getPropertyData();
      getAnalyticsData();
    }
  }, [property_details_id]);

  const getPropertyData = () => {
    setLoader(true);
    const url = '/tenant/' + property_details_id + '/property-unit-details';
    getAPI(url)
      .then((response) => {
        if (response.status !== 200) {
          if (!Cookies.get('access_token')) {
            toaster('error', response.response.data.message);
            if (response?.response?.status === 400) {
              setTimeout(() => {
                if (!Cookies.get('access_token')) {
                  props.history.push('/');
                }
              }, 1000);
            }
          }
        } else if (response.status === 200) {
          const res = response.data.data;
          dispatch(setPropertyDetail(res));
          dispatch(getUnitDetail(null));
          
          console.log('property-details', res);

          const galaryItems = getGalaryItems(
            res?.images,
            res?.videos,
            renderVideo
          );
          setImagesGallary(galaryItems);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const renderVideo = (item) => {
    return (
      <div>
        {showVideo && showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <ReactPlayer
              url={item.embedUrl}
              id="ytplayer"
              title="test"
              width="100%"
            />
          </div>
        ) : (
          <a onClick={() => toggleShowVideo(item.embedUrl)}>
            <div className="video-thumbnail">
              <img className="image-gallery-image" src={item.original} />
            </div>
          </a>
        )}
      </div>
    );
  };

  const toggleShowVideo = (url) => {
    showVideo[url] = !Boolean(showVideo[url]);
    setshowVideo(showVideo);
  };

  const getAnalyticsData = () => {
    // For Analytics
    if (Cookies.get('access_token') && role === 3) {
      const url = '/tenant/create-listing-analytics';
      const data = {
        property_id: property_id,
        property_detail_id: property_details_id,
      };
      postAPI(url, data).then((response) => {
        log.info('Analytics call', response);
      });
    }
  };

  const requestShowing = () => {
    setShowingRequest(!is_showingRequest);
    callbackRequestShowing(!is_showingRequest);
  };
  const messageLandloard = () => {
    props.history.push({
      pathname:
        '/property-details/' +
        property_id +
        '/' +
        property_details_id +
        '/messages',
    });
  };

  const viewGallery = () => {
    setShowGallery(true);
  };

  const myFavourite = () => {
    const url = '/tenant/add-to-favorite-listing';
    const data = {
      property_details_id: property_details_id,
    };
    postAPI(url, data)
      .then((response) => {
        getPropertyData();
      })
      .catch((error) => {});
  };
  const _onSlide = (item) => {
    setshowVideo({});
  };
  console.log({ imagesGallary });

  return (
    <>
      <div className={`profile`}>
        <div className="profile-container">
          <div className="profile-container-box property-details">
            {loader ? (
              ''
            ) : (
              <Row className={`${props.classes}`}>
                <Col md="12" className="d-flex flex-wrap">
                  {propertyDetails?.images && (
                    <UnitImageDisplay
                      imagesList={propertyDetails?.images}
                      videosList={propertyDetails?.videos}
                      actionDetails={() => viewGallery()}
                    />
                  )}
                </Col>

                <Col md={`${props.colSize || '7'}`}>
                  {!props.hideAddress && (
                    <UnitAddress
                      requestShowing={() => requestShowing()}
                      messageLandloard={() => messageLandloard()}
                      myFavourite={(data) => myFavourite(data)}
                      is_favourite={propertyDetails?.is_favorite}
                    />
                  )}
                  <UnitDetails />
                  <UnitDescription />
                </Col>

                <Col md={`${props.colSize} || 5`}  >
                  <div className="inner-wrapper">
                  <Row className="mt-4 mb-2" >
                    <Col md={12}>
                   {isDashboard?   <h6   style={{color:'#888892',fontSize:14}}>
                        Check out the neighbourhood
                      </h6>
                    :
                    <h4 className="similar-listing-title text-left">
                    Check Out The Neighbourhood
                  </h4>  
                    }
                    </Col>
                  </Row>
                  {propertyDetails?.latitude && propertyDetails?.longitude && (
                    <div
                       
                      style={{
                        height: mapHeight,
                        padding: '0',
                        overflow: 'hidden',
                        border: '2px solid #ccc',
                        marginTop:isDashboard?5:0
                      }}
                    >
                      <StaticMap
                        height={mapHeight}
                        latitude={propertyDetails?.latitude}
                        longitude={propertyDetails?.longitude}
                      />
                    </div>
                  )}
                  {Cookies.get('role') === 3 && <SimilarPropertyList />}
                  {/* <Row className='filter-tenant'>
                                        <Form.Group as={Col} md='12' className='email-elm' controlId='formBasicCheckbox'>
                                            <div className='form-group'>
                                                <div className='custom-control custom-checkbox large'>
                                                    <input type='checkbox' className='custom-control-input' id={'email'}
                                                        name='email'
                                                        checked={email} onChange={(e) => OnCheckInput(e)}
                                                    />
                                                    <label className='custom-control-label pl-3 pt-1 form-check-label' htmlFor={'email'}>Email me similar new listings</label>
                                                </div>
                                            </div>
                                        </Form.Group>

                                    </Row> */}
               </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
      <ModalBox
        show={is_showGallery}
        onHide={() => setShowGallery(false)}
        size="lg"
      >
        {imagesGallary && (
          <ImageGallery
            items={imagesGallary}
            lazyLoad={false}
            onSlide={() => setshowVideo({})}
          />
        )}
      </ModalBox>
    </>
  );
};

export default withRouter(TenantPropertyDetails);
