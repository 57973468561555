import React, { useEffect, useState } from "react";
import './style.scss';
import { logger, toaster } from "../../../Utils";
import { LargeButton, SidePanel, TenantProfileDisplay, ModalBox, Loader } from "../../components";
import { getAPI, postAPI } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { setIsProfileCompleted, setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";
import { getTenancyApplicatioinDetails } from "./helper";
import { askToCompleteProfile } from "../../components/Message/helper";

const log = logger("ViewTenantProfile");
const ViewTenantProfile = (props) => {
	const {property_details_id,property_id, isShow, handleClose, title, setInvite_apply, setLeaseOptionsScreen ,setCertnPurchaseReport} = props;
	const [profile, setProfile] = useState(null);
	const [modalShow, setModalShow] = useState(false);
	const [disable, setDisable] = useState(false)
	const [showFullProfile, setShowFullProfile] = useState(false)
	const [loader, setLoader] = useState(false)
	const [tenancyApplicationDetails,setTenancyApplicationDetails]=useState(null);
	const dispatch = useDispatch();
	const {conversationId, existingTenancyApplicationId, tenantId}  = useSelector(state => state.messageDetailsReducer);
	const [profileModalShow, setPropfileModalShow] = useState(false);
	const consentStatus=useSelector(state=>parseInt(state.rawData.consentStatus || 0))
console.log({consentStatus});

	useEffect(() => {
		if (tenantId) {
			setLoader(true)
			const url=`/landlord/tenant-profile/${tenantId}?conversation_id=${conversationId}`
			getAPI(url).then(response => {
				log.info("profile data", response.data.data);
				const res = response.data.data;
				setProfile(res);
				dispatch(setIsProfileCompleted(response.data.data.basic_info.is_profile_completed))
				setLoader(false)
			})
			.catch(error => {
				setLoader(false)
			});
		}
		(async () => {
			if (existingTenancyApplicationId && isShow) {
			  const tenancyApplicationDetails = await getTenancyApplicatioinDetails(
				existingTenancyApplicationId
			  );
			  if (tenancyApplicationDetails) {
				setTenancyApplicationDetails({...tenancyApplicationDetails.tenancyApplicationData});
			  }
			}
		  })();
	}, [isShow]);

	const onSubmit = () => {
		handleClose();
		setInvite_apply(true);
	}

	const leaseAction = () => {
		handleClose();
		dispatch(setIsLeaseFromTenantApplication(true));
		setLeaseOptionsScreen(true);
	}

	const onCompleteProfile = () => {
		setDisable(true)
		const url = '/landlord/ask-tenant-complete-profile';
		const data = {
			"conversation_id": conversationId,
			"tenant_id": tenantId
		}

		postAPI(url, data).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message)
			} else if (response.status === 200) {
				log.info('data', response.data);
				setDisable(false)
				// setModalShow(false)
			}
		})
		.catch(error => {
			setDisable(false)
		});
	}
	// const classes = showFullProfile ? 'sidepanel-bg fullpage' : 'sidepanel-bg'
	return (<>
		<SidePanel  extraClasses={showFullProfile ? 'fullpage sidepanel-bg' : 'sidepanel-bg'} isShow={isShow} isHide={handleClose} title={title}>
			{ loader? <Loader /> :
				<>
					<div className="sidepanel-body" style={{top:'80px'}}>
						<TenantProfileDisplay
						property_details_id={property_details_id}
						property_id={property_id}
						tenancyApplicationData={tenancyApplicationDetails}
						showFullProfile={showFullProfile} 
						setShowFullProfile={setShowFullProfile}
						setCertnPurchaseReport={setCertnPurchaseReport}
						 profileData={profile}
						 handleClose={handleClose}
						 onModalShow={(key)=>setModalShow(key)}
						 setModalShow={setPropfileModalShow}
						 />
		<ModalBox
			show={profileModalShow}
			onHide={() => setPropfileModalShow(false)}
			size="sm"
			extraBtnStyle={'extraBtnStyle'}
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setPropfileModalShow(false)

			}}
		>
			<p className="modal-confirmation-text">request has been sent</p>
			<p>we will let you know when the tenant has completed their profile.</p>
		</ModalBox>
					</div>
					<div className="fixed-bottom d-flex justify-content-between dark-footer">
						{(profile && !!profile?.basic_info?.is_profile_completed && (
							( true) ?
							<div style={{width:'100%'}}>
								{!consentStatus  && <LargeButton onClick={() => {
								setPropfileModalShow(false)
								setCertnPurchaseReport(true)
							
							}} title={'screen tenant'} />
							}
							<LargeButton onClick={() => leaseAction('create',0)} title={'create a lease'} />
							</div>

							:(profile.basic_info.is_profile_completed !== 0) ?
								(existingTenancyApplicationId ? 
									<>
						 
						 <div style={{width:'100%'}}>
								{!consentStatus  && <LargeButton onClick={() => {
								setPropfileModalShow(false)
								setCertnPurchaseReport(true)
							
							}} title={'screen tenant'} />
							}
							<LargeButton onClick={() => leaseAction('create',0)} title={'create a lease'} />
							</div>
									</>
									:'')

							: (profile.basic_info.request_for_profile_completion) ?
								(existingTenancyApplicationId ? 
								 <>
								 
								 <div style={{width:'100%'}}>
								{!consentStatus  && <LargeButton onClick={() => {
								setPropfileModalShow(false)
								setCertnPurchaseReport(true)
							
							}} title={'screen tenant'} />
							}
							<LargeButton onClick={() => leaseAction('create',0)} title={'create a lease'} />
							</div>
								</>
									:'')
								// <div className='buttonLikeText grayButton' style={{width: '100%'}}>
								// 	<p>requested for profile completion</p>
								// </div>
							:<LargeButton onClick={() => {
								onCompleteProfile()
								handleClose()
								setModalShow(true)
							}} title='ask tenant to complete profile' />
						))}
					</div>
				</>
			}
		</SidePanel>
		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size="sm"
			// extraBtnStyle={'extraBtnStyle'}
			actionbuttontitle={'got it'}
			buttonaction={() => {
				askToCompleteProfile(conversationId,tenantId)
				setModalShow(false)
				handleClose();
			}}
		>
			<p className="modal-confirmation-text">request has been sent</p>
			<p>we will let you know when the tenant has completed their profile.</p>
		</ModalBox>
		
		{/* <ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size="md"
			// type="condition"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setModalShow(false)
			}}
			disable={disable}
		>
			<p className="modal-confirmation-text">You’ve requested that {profile?.basic_info?.name} complete their profile.</p>
			<p>It may take a bit of time for them to complete, so stay tuned!</p>
		</ModalBox> */}
	
	</>);
}

export default  ViewTenantProfile;