import { postAPI } from "../../../Api";

export const setMyFavourite = async (property_details_id) => {
    try {
        const url = '/tenant/add-to-favorite-listing';
        const data = {
            'property_details_id': property_details_id
        }
        const res = await postAPI(url, data)
        if(res.status === 200){
            return {status: res.status, message: res.data.message};
        }
        return {status: res.response.status, message: res.response.data.message}
    } catch (error) {
        return {status: 400, message: 'Something went wrong!'}
    }
}