import React, { useState, useEffect } from "react";
import "./style.scss";
import { Row, Col, Navbar, NavDropdown } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { logger, toaster } from "../../../Utils";
import { getAPI, postAPI } from "../../../Api";
import Cookies from "js-cookie";
import Moment from 'react-moment';
import { ModalBox } from "../../components";
import { clearRawData } from "../../../Store/actions/RawDataAction";
import { VIEW_ONLY_NOTICE } from './helper'
// ************* 'notice_status' ************* //

// 0 => 'Draft',
// 1 => 'Need Attention',
// 2 => 'Delivered',
// 3 => 'Signed'

// ***************************************** //


const log = logger("Notices");
const Notices = (props) => {
  const { property_details_id, property_id, role } = props;
  const [noticeLists, setNoticeLists] = useState(null);
  const [activeKey, setActiveKey] = useState('new_to_old');
  const accessToken = Cookies.get("access_token") ? Cookies.get("access_token") : null;
  const [modalShow, setModalShow] = useState(false);
  const [noticeId, setNoticeId] = useState(null);

  useEffect(() => {
    getData();
  }, [props, accessToken, activeKey])

  const getData = () => {
    let url = '';
    if (role === 2) {
      url = `/landlord/notice/list/`;
    } else if (role === 3) {
      url = `/tenant/notice/list/`;
    }
    const data = {
      "property_id": property_id,
      "property_detail_id": property_details_id,
      "sort": (activeKey === 'new_to_old') ? "desc" : "asc" //asc, desc          
    }
    postAPI(url, data).then(res => {
      if (res.status === 200) {
        const fetchedData = res.data.data;
        setNoticeLists(fetchedData);
        log.info('fetchedData', fetchedData);
      }
    })
  }

  const handleDropdownSelect = (key) => {
    setActiveKey(key);
  }

  const noticeAction = (action, notice_id) => {
    if (action === 'view' && notice_id) {
      props.history.push({
        pathname: `/view-notice/${property_id}/${property_details_id}/${notice_id}`
      });
    } else {
      clearRawData()
      props.history.push({
        pathname: `/create-notice/${property_id}/${property_details_id}`
      });
    }
  }

  const deleteAction = () => {
    let url = '';
    if (role === 2) {
      url = `/landlord/notice/delete/${noticeId}`;
    } else if (role === 3) {
      url = `/tenant/notice/delete/${noticeId}`;
    }

    getAPI(url)
      .then((response) => {
        const res = response.data.data;
        log.info(res);
        getData();
        toaster("success", "Notice Deleted Successfully!");
        setModalShow(false);
        setNoticeId(null);
      })
  }


  console.log({ noticeLists })
  return (
    <>
      <div className="profile-container">
        <div className="profile-container-box">
          <div className="dekstopflex"
            style={{
              position: "relative",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: "20",
              marginBottom: '16px'
            }}
          >
            <Navbar className="filter-nav">
              <Navbar.Collapse className="d-flex justify-content-between">
                <Navbar.Text>posted date:</Navbar.Text>
                <NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
                  <NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
                  <NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>

            <button className="create-listing-black-button" onClick={(e) => noticeAction('create', 0)}>
              <span className="d-sm-inline-block"
              id={role === 2 ? null : 'BTN-move-out-notice'}
              data-description={role === 2 ? null : 'Tenant chose send move out notice'}
              >{role === 2 ? 'Create New' : 'Send Move Out Notice'}</span>
            </button>
          </div>

          <Row>
            <Col>
              <div className="table-component-wrapper list-view-calender lease-list notice-list">
                <table className="table-component">
                  <thead>
                    <tr className="list-view-header notice-view-header">
                      <th>Notice Details</th>
                      <th>Date Created</th>
                      <th>{(role === 2) ? 'Tenant(s)' : 'Landlord'}</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {noticeLists && noticeLists.map((data, i) => {
                      return (
                        <tr onClick={(e) => noticeAction('view', data.id)}  key={'table_body_' + i} className={'row-wrapper ' + data.status_string}>
                          <td >{data.notice_details}</td>
                          <td >{data.date_created ?
                            <Moment format="MMM D, YYYY">{data.date_created}</Moment>
                            : '-'}</td>

                          {(role === 3) ?
                            <td >{data.landlord}</td>
                            : <td>{data.tenants && data.tenants.map((tenant) => {
                              return tenant
                            }).join(', ')}</td>}

                          <td>
                            <span className={`lease-status`} style={{ color: VIEW_ONLY_NOTICE[data.notice_type] || data.status===4 ? '#399a40' : '' }} >{VIEW_ONLY_NOTICE[data.notice_type] || data.status_string}</span>
                            {(data.status === 2) &&
                              <img className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setModalShow(true);
                                  setNoticeId(data.id);
                                }}
                                alt="Delete" src={require('../../../_assets/images/delete.svg')}
                              />}
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </table>
                {noticeLists?.length === 0 && <div className="d-flex justify-content-center pt-4"><span>There is no notice available</span></div>}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        type="condition"
        actionbuttontitle={"No"}
        buttonaction={() => {
          setModalShow(false);
        }}
        backbuttontitle={"Yes"}
        backaction={() => {
          setModalShow(false);
          deleteAction();

        }}
      >
        <h4>are you sure you want to delete this notice?</h4>
        {/* <p>It cannot be undone</p> */}
      </ModalBox>
    </>);
}

export default withRouter(Notices);
