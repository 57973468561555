import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import Cookies from 'js-cookie';

const N13DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title" style={{width:'70%'}}>Notice to End your Tenancy Because the Landlord Wants to Demolish the Rental Unit, Repair it or Convert it to Another Use (N13 Form)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            Deadline (Date of termination if tenant fails)
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Reason(s) for Ending Tenancy</span>
        </div>
        {noticeData.end_tenancy_reason_1 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: Tenant(s) have persistently paid your rent late.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_2 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={noticeData.end_tenancy_reason_2 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: Tenant(s) no longer qualify to live in public or
                  subsidized housing.
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        {noticeData.end_tenancy_reason_3 === 1 && (
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={noticeData.end_tenancy_reason_3 === 1 && true}
                  disabled
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You have made the unit available to your current
                  tenant(s) as a condition of their employment and their
                  employment has already ended
                </span>
              </div>
            </Form.Group>
          </Row>
        )}
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">
            Details about the reasons for notice
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">Work Planned </span>
          <span className="field-value-text">
            {noticeData.work_planned}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">Details </span>
          <span className="field-value-text">
            {noticeData.details_of_the_work}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Necessary Permits</span>
        </div>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>
              Shaded the circle to indicate whether you have obtained any
              necessary building permits
              </Form.Label>
              {noticeData.necessary_permits === 1  &&
            <div className="select-radio-wrapper">
              <Form.Check
                key={"permit1"}
                type="radio"
                id={`permit1`}
                name="permits"
                value={"permit1"}
                checked={noticeData.necessary_permits === 1 ? true : false}
                disabled
                label={
                  "I have obtained the necessary building permits or other authorization to convert, demolish or repair the rental unit."
                }
              />
            </div>}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            {noticeData.necessary_permits === 2 &&
            <div className="select-radio-wrapper">
              <Form.Check
                key={"permit2"}
                type="radio"
                id={`permit2`}
                name="permits"
                value={"permit2"}
                checked={noticeData.necessary_permits === 2 ? true : false}
                disabled
                label={
                  "I will obtain the necessary building permits or other authorization to convert demolish or repair the rental unit."
                }
              />
            </div>}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            {noticeData.necessary_permits === 3 &&
            <div className="select-radio-wrapper">
              <Form.Check
                key={"permit3"}
                type="radio"
                id={`permit3`}
                name="permits"
                value={"permit3"}
                checked={noticeData.necessary_permits === 3 ? true : false}
                disabled
                label={
                  "No permits or other authorization are necessary in this case to convert the rental unit or demolish it."
                }
              />
            </div>}
          </Form.Group>
        </Row>
        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N13DetailsView);
