import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContentLoader from 'react-content-loader';
import { EditButton } from "../../../../..";

const CompleteLeaseLoader = (props) => {
	return (
		<>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord contact information</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Full Name</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Email Address</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Phone number</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
					<Row>

						<Form.Group as={Col} md='12'>
							<Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
							<span><RowList /></span>
						</Form.Group>

						<Form.Group as={Col} md='12'>
							<Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord mailing address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>

						<Form.Group as={Col} md='4'>
							<Form.Label>Address</Form.Label>
							<span><RowList /></span>
						</Form.Group> <Form.Group as={Col} md='2'>
							<Form.Label>Unit</Form.Label>
							<span><RowList /></span></Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>City</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Province</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Postal Code</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>rental property address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='3'>
							<Form.Label>Address</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1'>
							<Form.Label>Unit</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1'>
							<Form.Label>City</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1'>
							<Form.Label>Province</Form.Label><span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1'>
							<Form.Label>Postal Code</Form.Label>
							<span><RowList /></span>

						</Form.Group>
						<Form.Group as={Col} md='3'>
							<Form.Label>Is this rental unit in a condo?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>lease details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Lease Type</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Start  Date</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>End Date</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Additional Terms</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='4'>
							<Form.Label>Attached Terms</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>more details about the rent</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Rent is to be paid on:</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2' className='pl-0'>
							<Form.Label>Base rent for the rental property</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is parking available?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1' className='pr-0 pl-0'>
							<Form.Label>Parking Type</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='1'>
							<Form.Label># of spots</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Price for parkings</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Rent is payable to:</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Payment Method</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='3'>
							<Form.Label>Is the first rental period a partial period?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='3'>
							<Form.Label>Partial Period</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='3'>
							<Form.Label>Partial Period Payment Amount</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is smoking allowed?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>deposits and discounts</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is there a rent deposit?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='4'>
							<Form.Label>Rent Deposit Amount</Form.Label>
							<span><RowList /></span>
						</Form.Group>

					</Row>
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is there a key deposit?</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='4'>
							<Form.Label>key Deposit amount (can only be amount to replace key)</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Is there a rent discount</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Amount</Form.Label>
							<span><RowList /></span>
						</Form.Group>
						<Form.Group as={Col} md='8'>
							<Form.Label>Rent Discount Description</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>who pays for the utilities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Electricity</Form.Label>
							<span><RowList /></span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Heat</Form.Label>
							<span><RowList /></span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Water</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>amenities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md={'6'}>
							<Form.Label>Others</Form.Label>
							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>tenant details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='5'>
							<Row>
								<Form.Group as={Col} md='4' className='utilities-list'>
									<Form.Label>Tenant Name</Form.Label>
								</Form.Group>
								<Form.Group as={Col} md='8' className='utilities-list'>
									<Form.Label>Email</Form.Label>
								</Form.Group>
							</Row>
							<Row  >
								<Form.Group as={Col} md='4' className='utilities-list'>
									<span><RowList /></span>
								</Form.Group>
								<Form.Group as={Col} md='8' className='utilities-list'>
									<span><RowList /></span>
								</Form.Group>
							</Row>

						</Form.Group>
						<Form.Group as={Col} md='6'>
							<Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>

							<span><RowList /></span>
						</Form.Group>
					</Row>
				</div>
			</div>
		</>
	)
}
export default CompleteLeaseLoader;


const RowList = (props) => {
	return (
		<ContentLoader height={20} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
			<rect x="0" y="0" rx="0" ry="0" width="100%" height="20" />
		</ContentLoader>
	)
}