import React from 'react'
import { Navbar, NavDropdown } from 'react-bootstrap';

function LeaseListHeader(props) {
    const { handleDropdownSelect, activeKey, leaseAction } = props;
    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: "20",
                padding: "0 5px",
                marginBottom: '16px'
            }}
        >
            <Navbar className="filter-nav">
                <Navbar.Collapse className="d-flex justify-content-between">
                    <Navbar.Text>posted date:</Navbar.Text>
                    <NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
                        <NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
                        <NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>

            <Navbar className="create-lease">
                <Navbar.Collapse className="d-flex justify-content-center">
                    <NavDropdown title={'Create New'} id="nav-dropdown">
                        <NavDropdown.Item onClick={(e) => leaseAction('create', 0)}>Create Lease</NavDropdown.Item>
                        <NavDropdown.Item onClick={(e) => leaseAction('upload')}> Upload Your Own</NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default LeaseListHeader
