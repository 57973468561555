import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SidePanel } from '..';
import TenantPropertyDetails from '../../Tenant/TenantPropertyDetails/TenantPropertyDetails';
import './styles.scss';

const PropertyDrawer = (props) => {
  const { isShow, handleClose = () => {}, finalAddress } = props;
  const propertyDetails = useSelector(
    (state) => state.propertyDetailsReducer.propertyDetails
  );

  return (
    <>
      <SidePanel
        isShow={isShow}
        isHide={() => handleClose()}
        title={finalAddress}
      >
        <TenantPropertyDetails
          mapHeight={283}
          // classes={'property-drawer'}
          colSize={'12'}
          hideAddress={true}
          isFromRedux={true}
          isDashboard={true}
        />
      </SidePanel>
    </>
  );
};

export default withRouter(PropertyDrawer);
