import React, { useState, useEffect } from "react";
import { SidePanel, ModalBox, DatePickerComponent } from "../.."
import { Form, Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { postAPI } from "../../../../Api";
import { validate } from "../../../../Utils/Validation/validation";
import { ParalegalFormSchema } from './ValidationSchema/ParalegalFormSchema';
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const ParalegalForm = (props) => {
  const { isShow, handleClose } = props
  const role = Number(Cookies.get("role"));
  const [date, setDate] = useState(null);
  const [appointment_time, setAppointment_time] = useState('select');
  const [question, setQuestion] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [propertyId, setPropertyId] =useState(null)
  const [propertyIdDetails, setPropertyDetailsId] =useState(null)
  const userName = useSelector(state => state.auth.name)
  const [fullName, setFullName] = useState(userName);


  const OnChangeInput = (event) => {
    if (event.target.name === 'appointment_time') {
      setAppointment_time(event.target.value)
    }
  }

  const onSubmit = () => {
    if (validate(ParalegalFormSchema)) {
      let url = '';
      if(role === 2){
       url = '/landlord/notice/process-notice-help';
      }else if(role === 3){
        url = '/tenant/notice/process-notice-help';
      }

      const data = {
        property_id: propertyId,
        property_detail_id: propertyIdDetails, 
        question: question,
        full_name: fullName,
        contact_day: moment(date).format('YYYY-MM-DD'),
        contact_time: appointment_time,
      }
      postAPI(url, data).then(response => {
        handleClose()
        setModalShow(true)
      })
    }
  }

  useEffect(() => {
    setPropertyId(props.match.params.property_id)
    setPropertyDetailsId(props.match.params.property_details_id)
  }, [
    props.match.params.property_id,
    props.match.params.property_details_id
  ])

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title="Get help from our paralegal"
      subTitle="Fill out the information below and we will get back to you right away!">
      <Form id="Form">
      <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What’s Your Question?</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type here"
              name="question"
              id="question"
              value={question}
              // maxLength="30"
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Your Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              name="fullName"
              id="fullName"
              value={fullName}
              maxLength="30"
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What Date Can We Get In Touch With You?</Form.Label>
            <DatePickerComponent
              name="dateContact"
              id="dateContact"
              value={date}
              handleDateChange={date => setDate(date)}
              displayText={"Select or type"}
              minDate={new Date()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>When Would Be Best To Chat?</Form.Label>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="appointment_time"
              custom
              name="appointment_time"
              value={appointment_time}
              onChange={(e) => OnChangeInput(e)}
              style={{ border: 'none', height: '48px' }}
              placeholder='Select one'
            >
              <option value="">Select one</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </Row>        
      </Form>
      <div className="container-footer">
        <Button variant="success" onClick={onSubmit}>Submit</Button>
      </div>
    </SidePanel>

    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => {
        setModalShow(false)
      }}
    >
      <h4>your help request has been sent</h4>
      <p>we will be in touch with you shortly</p>
    </ModalBox>
  </>);
}

export default withRouter(ParalegalForm);