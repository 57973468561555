import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  CustomInput,
  Button,
  FormFeedback,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  Row,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Link, useHistory, withRouter } from "react-router-dom";
import AuthLayout from "../Authentication";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setLoggedIn, logout } from "../../../Store/actions/AuthAction";
import { API_URL } from "../../../Constants/endPoints";
import { logger, toaster } from "../../../Utils";
import { Alert } from 'react-bootstrap';
import { cookieBasedRedirectPath } from './helper';
import { Helmet } from "react-helmet";

const log = logger("login");
const schema = yup.object().shape({
  email: yup.string()
    // .required()
    .email(),
  password: yup
    .string()
  // .required()
  // .min(8)
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // )
  ,
  stay_logged_in: yup.boolean(),
});

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [showPass, setShowPass] = useState(false);


  useEffect(() => {
    log.info("props", history);
    if (props.location.search && props.location.search === '?unauthorized') {
      dispatch(logout());
    } else {
      if (Cookies.get("role") && Cookies.get("access_token")) {
        const path = cookieBasedRedirectPath(props.location.state?.from);

        const goToPath = {
          pathname: path,
          history: { history },
          state: { from: props.location.state?.from?.pathname || '' },
        }
        history.push(goToPath);
      }
    }

  }, [])

  const togglePassword = () => {
    setShowPass(!showPass)
  }

  const resendCode = (details) => {
    const url = API_URL + '/resend-activation-code';
    const token = `Bearer ${Cookies.get("access_token")}`
    const headers = {
      'Authorization': token
    }

    const data = {
      email: details.email,
    }

    axios.post(url, data, {
      headers: headers
    })
      .then(function (response) {
        toaster("success", response.data.message);
      })
      .catch(function (error) {
        toaster('error', 'Something went wrong!')
      });
  }

  const renderInput = ({ type, id, placeholder, invalidAuth }) => {
    return (
      <FormGroup>
        <Field name={id}>
          {({
            field: { name, value, onChange, onBlur },
            form: { touched, errors },
            meta,
          }) => (
            <div style={{ position: 'relative' }}>
              <Input
                type={type === 'password' ? (showPass ? 'text' : 'password') : type}
                name={name}
                id={id}
                invalid={!!invalidAuth || (!!meta.touched && !!meta.error)}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
              {type === 'password' ?
                <p style={{
                  position: 'absolute',
                  top: '17px',
                  right: '11px',
                  fontSize: '11px',
                  fontWeight: '600',
                  opacity: '0.5',
                  cursor: 'pointer'
                }}
                  onClick={() => togglePassword()}>{value && <img width="20" height="20" src={require("../../../_assets/images/" + (showPass ? 'hide.svg' : 'show.svg'))} alt="hideAndShowIcon" />}</p>
                : ''}

              {invalidAuth && <FormFeedback>{invalidAuth}</FormFeedback>}
              {meta.touched && meta.error && (
                <FormFeedback>{meta.error}</FormFeedback>
              )}
            </div>
          )}
        </Field>
      </FormGroup>
    );
  };

  const [invalidAuthEmail, setInvalidAuthEmail] = React.useState(null);
  const [invalidAuthPassword, setInvalidAuthpassword] = React.useState(null);
  const [coockieError, setCoockieError] = React.useState(false);

  return (
    <>
    <Helmet>
      <meta name="description" content="Log into your Rent Panda account. The free property rental platform for tenants and landlords." />
    </Helmet>
    <AuthLayout className='login-page' title="log in">
      <Formik
        initialValues={{
          email: "",
          password: "",
          stay_logged_in: false,
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (!navigator.cookieEnabled) {
            setCoockieError("To continue using Rent Panda, please enable cookies on your browser.")
            return false;
          }
          try {
            const data = {
              email: values.email,
              password: values.password,
            };
            const res = await axios.post(
              API_URL + "/login",
              data
            );
            let expirationDays = 1;
            if (values.stay_logged_in) {
              expirationDays = 30;
            }
            Cookies.set("access_token", res.data.data.token, {
              expires: expirationDays,
            });
            Cookies.set("role", res.data.data.role, {
              expires: expirationDays,
            });
            dispatch(setLoggedIn(res.data.data));

            if (Cookies.get("role") && Cookies.get("access_token")) {
              const path = cookieBasedRedirectPath(props.location.state?.from);
                    
              const goToPath = {
                pathname: path,
                history: { history },
                state: { from: props.location.state?.from?.pathname || '' },
              }
              history.push(goToPath);
            }

          } catch (err) {
            log.info(err.response.data.status_code)
            if (err.response.data.errors) {
              if (err.response.data.errors.email) {
                log.info(err.response.data.errors.email[0])
                setInvalidAuthEmail(err.response.data.errors.email[0]);
              }
              if (err.response.data.errors.password) {
                setInvalidAuthpassword(err.response.data.errors.password[0]);
              }
            }
            if (err.response.data.status_code === 401) {
              toaster("error", err.response.data.message)
            } else if (err.response.data.data.is_active === false) {
              resendCode(values)
              history.push({
                pathname: "/verification",
                state: {
                  id: err.response.data.data.user_id,
                  data: values.email,
                  flow: 'authentication'
                }
              });
            }
            if (err.response.data.status_code === 400) {
              toaster("error", err.response.data.message)
            }

          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (<>
          {coockieError && <Alert variant={'danger'}>
            {coockieError}
          </Alert>}
          <Form onSubmit={handleSubmit} disabled={isSubmitting}>
            <div className='form-wrapper'>
              {renderInput({
                type: "email",
                id: "email",
                placeholder: "Email",
                invalidAuth: invalidAuthEmail,
              })}
              {renderInput({
                type: "password",
                id: "password",
                placeholder: "Password",
                invalidAuth: invalidAuthPassword,
              })}
              <div className="d-flex justify-content-between">
                <CustomInput
                  className="d-flex"
                  type="checkbox"
                  id="stay_logged_in"
                  onChange={handleChange}
                  label="Keep me logged in"
                />
                <Link
                  style={{ color: "#000", textDecoration: "underline", fontWeight: "bold", fontSize: "14px" }}
                  to="/forgotpassword">Forgot your password?</Link>
              </div>
            </div>

            <Button
              style={{ width: "100%", fontWeight: "bold" }}
              disabled={isSubmitting}
              type={"submit"}
              className="btn btn-primary text-bold mb-4 mt-4 text-transform-none"
            >
              Log In
            </Button>
            <p className="authentication-page-form-links">
              Don't have an account? &nbsp;
              <Link
                style={{ color: "#000", textDecoration: "underline" }}
                to="/signup/tenant">Sign up here</Link>
            </p>
          </Form>

          <Modal
            isOpen={isActive}
            toggle={() => setIsActive(false)}
          >
            <ModalHeader>
              notification
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col className="text-center">
                    A great profile helps you stand out from the crowd and increases
                    your chances of getting your perfect home.
                  </Col>
                </Row>
                <Row style={{ marginTop: '40px' }}>
                  <Button
                    className="w-100 m-auto btn btn-primary text-bold mt-5"
                    onClick={() => setIsActive(false)}>
                    fill in profile
                  </Button>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </>)}
      </Formik>
    </AuthLayout>
    </>
  );
};

export default withRouter(Login);
