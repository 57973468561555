import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { FormFeedback, Input, Label } from 'reactstrap';
import { ModalBox } from '../../components';
import * as Yup from 'yup';

const validateConfirmPassword = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'Password must contain character,number,special character'
    ),

  repeat_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

const ResetPassword = ({ modalShow, setModalShow, onSubmit }) => {
  const onSubmitHandler = (values) => {
    onSubmit(values.password);
    setModalShow(false);
  };

  const formik = useFormik({
    initialValues: { password: '', repeat_password: '' },
    onSubmit: onSubmitHandler,
    validationSchema: validateConfirmPassword,
  });
  
   const renderInput = ({ type, id, placeholder }) => {
    return (
      <div style={{ position: 'relative' }}>
        <Input
          type={type}
          name={id}
          id={id}
          autoComplete="new-password"

          // invalid={(!!meta.touched && !!meta.error)}
          placeholder={placeholder}
          onChange={(e) => {
            formik.setFieldValue(id, e.target.value);
          }}
        />

        {formik.errors[id] && formik.touched[id] && (
          <p style={{ fontSize: 16, color: 'red' }}>{formik.errors[id]}</p>
        )}
      </div>
    );
  };
  return (
    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      type="condition"
      actionbuttontitle={'No'}
      backbuttontitle={'Yes'}
      buttonaction={() => {
        setModalShow(false);
      }}
      backaction={() => {
        formik.submitForm();
      }}
    >
      <>
        <div className="form-wrapper">
          <Label style={{ display: 'block', textAlign: 'left' }}>
            New Password
          </Label>
          {renderInput({
            type: 'password',
            id: 'password',
            placeholder: 'Password',
          })}
      
          <Label style={{ display: 'block', textAlign: 'left' }}>
          Confirm Password
          </Label>
          {renderInput({
            type: 'password',
            id: 'repeat_password',
            placeholder: 'Confirm Password',
          })}
        </div>
      </>
    </ModalBox>
  );
};
export default ResetPassword;
