import React, { useState } from 'react'
import { Image, Dropdown, DropdownButton } from 'react-bootstrap'
import { HighlightContent, SearchInput } from '..';
import moment from "moment";
import './table.scss';
import { useDispatch, useSelector } from 'react-redux'
import { setCouponFilters } from '../../../Store/actions/Action';

const TableCoupon = (props) => {
    const dispatch = useDispatch()
    const filters = useSelector(state => state.CouponReducer.filters)

    const { component, from, to, current_page, last_page, total, clickNext, clickPrev, columns, tableData, viewCoupon,deleteCoupon } = props
    const createdDateFilter = [
        { display: 'View All', value: '' }, { display: 'Oldest to Newest', value: 'asc' }, { display: 'Newest to Oldest', value: 'desc' }
    ]
    const noOfUses = [
        { display: 'View All', value: '' },{ display: 'low to High', value: 'asc' }, { display: 'High to Low', value: 'desc' }
    ]
    const totalNoOfUses = [
        { display: 'View All', value: '' },{ display: 'low to High', value: 'asc' }, { display: 'High to Low', value: 'desc' }
    ]
    const noOfRedeemed = [
        { display: 'View All', value: '' },{ display: 'low to High', value: 'asc' }, { display: 'High to Low', value: 'desc' }
    ]
    const startDate = [
        { display: 'View All', value: '' },{ display: 'Oldest to Newest', value: 'asc' }, { display: 'Newest to Oldest', value: 'desc' }
    ]
    const expiryDate = [
        { display: 'View All', value: '' },{ display: 'Oldest to Newest', value: 'asc' }, { display: 'Newest to Oldest', value: 'desc' }
    ]
    const couponValue = [
        { display: 'View All', value: '' },{ display: 'low to High', value: 'asc' }, { display: 'High to Low', value: 'desc' }
    ]
    
    const [createdDateDisplay, setCreatedDateDisplay] = useState('Sort');
    const [noOfUsesDisplay, setNoOfUsesDisplay] = useState('Sort');
    const [totalNoOfUsesDisplay, setTotalNoOfUsesDisplay] = useState('Sort');
    const [startDateDisplay, setStartDateDisplay] = useState('Sort');
    const [expiryDateDisplay, setExpiryDateDisplay] = useState('Sort');
    const [couponValueDisplay, setCouponValueDisplay] = useState('Sort');
    const [noOfRedeemedDisplay, setNoOfRedeemedDisplay] = useState('Sort');

    const onClickFilter = (value, display, type) => {
        if(type==="createdDate"){
            setCreatedDateDisplay(display);
            props.setCreatedDate(value);
            dispatch(setCouponFilters({...filters, created_date: value}))
        }
        if(type==="noOfUses"){
            setNoOfUsesDisplay(display);
            props.setNoOfUses(value);
            dispatch(setCouponFilters({...filters, no_of_usage: value}))
        }
        if(type==="totalNoOfUses"){
            setTotalNoOfUsesDisplay(display);
            props.setNoOfUses(value);
            dispatch(setCouponFilters({...filters, total_no_of_usage: value}))
        }
        if(type==="startDate"){
            setStartDateDisplay(display);
            props.setStartDate(value);
            dispatch(setCouponFilters({...filters, start_date: value}))
        }
        if(type==="expiryDate"){
            setExpiryDateDisplay(display);
            props.setExpiryDate(value);
            dispatch(setCouponFilters({...filters, expiry_date: value}))
        }
        if(type==="couponValue"){
            setCouponValueDisplay(display);
            props.setCouponValue(value);
            dispatch(setCouponFilters({...filters, coupon_value: value}))
        }
        if(type==="noOfRedeemed"){
            setNoOfRedeemedDisplay(display);
            props.setNoOfRedeemed(value);
            // dispatch(setCouponFilters({...filters, couponCode: value}))
        }
        
       
      
    }
 
    return (
        <div className='table-component-wrapper-coupon' style={{ overflow: 'visible', minHeight: '600px' }}>
            <div className='table-responsive fixing-height'>
                <table className='table-component'>
                    <thead>
                        <tr>
                            {columns.map((data, i) => {
                                return (
                                    <th key={'table_head_' + i} width={data.width}>{data.header}
                                        {component === 'activeCoupons' &&
                                            <>
                                                {(() => {
                                                    switch (data.header) {
                                                        case 'Name':
                                                            return (
                                                                // <input
                                                                //     className='table-search-filter'
                                                                //     placeholder='Search'
                                                                //     onChange={(event) => props.setName(event.target.value)}
                                                                //     value={props.name}
                                                                //     name={data.name}
                                                                // />
                                                                <SearchInput 
                                                                    min_length={1}
                                                                    extraCalss={'table-search-filter'}
                                                                    searchValue={filters && filters.coupon_name}
                                                                    setSearchValue={(value) => dispatch(setCouponFilters({...filters, coupon_name: value}))}
                                                                    placeHolder={'Search'}
                                                                />
                                                            )                                                       
                                                        case 'Created Date':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={createdDateDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {createdDateFilter.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "createdDate")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )
                                                        case 'Use per user':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={noOfUsesDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {noOfUses.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value}  onClick={() => onClickFilter(options.value, options.display, "noOfUses")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )
                                                        case 'Total uses':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={totalNoOfUsesDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {totalNoOfUses.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "totalNoOfUses")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )
                                                        case 'start date':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={startDateDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {startDate.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "startDate")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )                                                            
                                                         case 'expire date':
                                                                    return (
                                                                        <DropdownButton
                                                                            // alignLeft
                                                                            title={expiryDateDisplay}
                                                                            id='dropdown-menu-align-left'
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            {expiryDate.map((options, i) => {
                                                                                return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display,"expiryDate")} key={i}>{options.display}</Dropdown.Item>
                                                                            })}
                                                                        </DropdownButton>
                                                                    )
                                                                    case 'coupon value':
                                                                        return (
                                                                            <DropdownButton
                                                                                // alignLeft
                                                                                title={couponValueDisplay}
                                                                                id='dropdown-menu-align-left'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                {couponValue.map((options, i) => {
                                                                                    return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "couponValue")} key={i}>{options.display}</Dropdown.Item>
                                                                                })}
                                                                            </DropdownButton>
                                                                        )
                                                                    case 'coupon code':
                                                            return (
                                                                // <input
                                                                //     className='table-search-filter'
                                                                //     placeholder='Search'
                                                                //     onChange={(event) => props.setCouponCode(event.target.value)}
                                                                //     value={props.couponCode}
                                                                //     name={data.name}
                                                                // />
                                                                <SearchInput 
                                                                    min_length={1}
                                                                    extraCalss={'table-search-filter'}
                                                                    searchValue={filters && filters.coupon_code}
                                                                    setSearchValue={(value) => dispatch(setCouponFilters({...filters, coupon_code: value}))}
                                                                    placeHolder={'Search'}
                                                                />
                                                            )  
                                                        default:
                                                            return null
                                                    }
                                                })()}
                                                
                                            </>
                                        }
                                        {component === 'InActiveCoupons' &&
                                            <>
                                                {(() => {
                                                    switch (data.header) {
                                                        case 'Name':
                                                            return (
                                                                // <input
                                                                //     className='table-search-filter'
                                                                //     placeholder='Search'
                                                                //     onChange={(event) => props.setName(event.target.value)}
                                                                //     value={props.name}
                                                                //     name={data.name}
                                                                // />
                                                                <SearchInput 
                                                                    min_length={1}
                                                                    extraCalss={'table-search-filter'}
                                                                    searchValue={filters && filters.coupon_name}
                                                                    setSearchValue={(value) => dispatch(setCouponFilters({...filters, coupon_name: value}))}
                                                                    placeHolder={'Search'}
                                                                />
                                                            )                                                       
                                                        case '# of coupon':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={noOfUsesDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {noOfUses.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "noOfUses")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )
                                                        case '# of redeemed':
                                                            return (
                                                                <DropdownButton
                                                                    // alignLeft
                                                                    title={noOfRedeemedDisplay}
                                                                    id='dropdown-menu-align-left'
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {noOfRedeemed.map((options, i) => {
                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display,"noOfRedeemed")} key={i}>{options.display}</Dropdown.Item>
                                                                    })}
                                                                </DropdownButton>
                                                            )
                                                            case 'start date':
                                                                return (
                                                                    <DropdownButton
                                                                        // alignLeft
                                                                        title={startDateDisplay}
                                                                        id='dropdown-menu-align-left'
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        {startDate.map((options, i) => {
                                                                            return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "startDate")} key={i}>{options.display}</Dropdown.Item>
                                                                        })}
                                                                    </DropdownButton>
                                                                )                                                            
                                                             case 'expire date':
                                                                        return (
                                                                            <DropdownButton
                                                                                // alignLeft
                                                                                title={expiryDateDisplay}
                                                                                id='dropdown-menu-align-left'
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                {expiryDate.map((options, i) => {
                                                                                    return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display,"expiryDate")} key={i}>{options.display}</Dropdown.Item>
                                                                                })}
                                                                            </DropdownButton>
                                                                        )
                                                                        case 'coupon value':
                                                                            return (
                                                                                <DropdownButton
                                                                                    // alignLeft
                                                                                    title={couponValueDisplay}
                                                                                    id='dropdown-menu-align-left'
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    {couponValue.map((options, i) => {
                                                                                        return <Dropdown.Item eventKey={options.value} onClick={() => onClickFilter(options.value, options.display, "couponValue")} key={i}>{options.display}</Dropdown.Item>
                                                                                    })}
                                                                                </DropdownButton>
                                                                            )
                                                                        case 'coupon code':
                                                                return (
                                                                    // <input
                                                                    //     className='table-search-filter'
                                                                    //     placeholder='Search'
                                                                    //     onChange={(event) => props.setCouponCode(event.target.value)}
                                                                    //     value={props.couponCode}
                                                                    //     name={data.name}
                                                                    // />
                                                                    <SearchInput 
                                                                        min_length={1}
                                                                        extraCalss={'table-search-filter'}
                                                                        searchValue={filters && filters.coupon_code}
                                                                        setSearchValue={(value) => dispatch(setCouponFilters({...filters, coupon_code: value}))}
                                                                        placeHolder={'Search'}
                                                                    />
                                                                )  
                                                        default:
                                                            return null
                                                    }
                                                })()}
                                                
                                            </>
                                        }                                       
                                    </th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data, i) => {
                            return (<React.Fragment key={'table_body_' + i}>
                                {component === 'activeCoupons' && <tr className='cursorPointer'>
                                    <td onClick={() => viewCoupon(data)}>                                       
                                       <HighlightContent searchWord={filters && filters.coupon_name} highlightWord={data.coupon_name} />
                                       </td>                                      
                                    <td onClick={() => viewCoupon(data)}>
                                    {data.created_at === null ? "---" : moment(data.created_at).format("MM/DD/YY")}  
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        {(data.usage_limit)}                                      
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        {(data.total_usage_limit)}                                      
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        {moment(data.start_date).format("MM/DD/YY")}                                      
                                    </td>                                    
                                    <td onClick={() => viewCoupon(data)}>
                                    {data.expiry_date === null ? "---" : moment(data.expiry_date).format("MM/DD/YY")}                                         
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        ${(data.coupon_value)}                                      
                                    </td>
                                    <td className='d-flex justify-content-between align-items-center'>                                   
                                        <span>
                                            <span onClick={() => viewCoupon(data)}> <HighlightContent searchWord={filters && filters.coupon_code} highlightWord={data.coupon_code} /></span>
                                            {
                                                (data.expiry_date !== null &&
                                                    (moment().add(1, 'days').format("MM/DD/YY") >= moment(data.expiry_date).format("MM/DD/YY"))) &&
                                                    <span className="alert-info" data-title="Coupon redemption is almost over.">
                                                        <Image className="alert-info-icon" src={require('../../../_assets/images/red-info-icon.svg')} alt="" />
                                                    </span>
                                            }
                                        </span>
                                        <img onClick={() => deleteCoupon(data.id)} src={require('../../../_assets/images/red-delete-icon.svg')} alt="" />
                                    </td>                                   
                                       
                                </tr>}  
                                {component === 'InActiveCoupons' && <tr className='cursorPointer'>
                                    <td onClick={() => viewCoupon(data)}>
                                    <HighlightContent searchWord={filters && filters.coupon_name} highlightWord={data.coupon_name} /></td>
                                    <td onClick={() => viewCoupon(data)}>
                                    {data.usage_limit}  
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                    {data.redeemed_coupons === null ? "---" : data.redeemed_coupons}                                     
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        {moment(data.start_date).format("MM/DD/YY")}                                      
                                    </td>                                    
                                    <td onClick={() => viewCoupon(data)}>
                                    {data.expiry_date === null ? "---" : moment(data.expiry_date).format("MM/DD/YY")}                                          
                                    </td>
                                    <td onClick={() => viewCoupon(data)}>
                                        ${(data.coupon_value)}                                      
                                    </td>
                                    <td className='d-flex justify-content-between align-items-center'>
                                        <span>
                                            <span onClick={() => viewCoupon(data)}> <HighlightContent searchWord={filters && filters.coupon_code} highlightWord={data.coupon_code} /></span>
                                            {
                                                (data.expiry_date !== null &&
                                                    (moment().add(1, 'days').format("MM/DD/YY") >= moment(data.expiry_date).format("MM/DD/YY"))) &&
                                                    <span className="alert-info" data-title="Coupon redemption is almost over.">
                                                        <Image className="alert-info-icon" src={require('../../../_assets/images/red-info-icon.svg')} alt="" />
                                                    </span>
                                            }
                                        </span>
                                        <img onClick={() => deleteCoupon(data.id)} src={require('../../../_assets/images/red-delete-icon.svg')} alt="" />
                                    </td>
                                        
                                       
                                </tr>}                              
                            </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
                {tableData.length === 0 &&
                    <div className='empty-table-data'>
                        There is no data found
            </div>
                }
            </div>

            {tableData && tableData.length !== 0 &&
                <div className='table-footer'>
                    <div>
                        <span className='left'>
                            {tableData.length === 0 ? <span>Data not found</span> : <span className='left'>
                                {tableData.length} of {total}
                            </span>}
                            {/* {per_page > total ? total : per_page} of {total} */}
                        </span>
                    </div>
                    <div>
                        <span className='right'>
                            {from} of {to}
                            {current_page !== from ? <img className='previous-arrow' onClick={clickPrev} src={require('../../../_assets/images/next.svg')} alt='previous' /> : <img className='previous-arrow' src={require('../../../_assets/images/next-inactive.svg')} alt='previous' />}
                            {current_page !== last_page ? <img className='next-arrow' onClick={clickNext} src={require('../../../_assets/images/next.svg')} alt='next' /> : <img className='next-arrow' src={require('../../../_assets/images/next-inactive.svg')} alt='next' />}
                        </span>
                    </div>
                </div>
            }

        </div>
    )
}

export default TableCoupon;