import { logger, toaster } from "../../Utils"
import { postAPI } from '../../Api';

const log = logger("changePasswordAPI")
export async function changePasswordAPI(body, props) {
  let url= '/change-password';
  await postAPI(url, body).then(response => {
    if (response.status === 200) {
      toaster('success', response.data.message)
      props.history.push("/profile");
    } else if (response.response) {
      if (response.response.status === 422) {
        if (response.response.data.errors.new_password) {
          toaster('error', response.response.data.errors.new_password[0]);
        }
      } else if (response.response.status === 400) {
        toaster('error', response.response.data.message);
      }
    } else {
      log.info('response error', response)
    }
  })
}
