import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const LeaseContactDetailsLoader = (props) => {
    return (
        <>
            <Form className='higher-level-lease'>
                <div className='inner-wrapper'>
                    <div className='body-container'>
                        <div className='body-header'>
                            <h3 className='text-left'>landlord contact information</h3>

                        </div>

                        <Row>
                            <Form.Group as={Col} md='4'>
                                <Form.Label className='transform-capitalize'>Landlord Full Name</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='4'>
                                <Form.Label className='transform-capitalize'>Email Address</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='4'>
                                <Form.Label className='transform-capitalize'>Phone Number</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='12'>
                                <Form.Label className='transform-none'>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
                                <div className='d-flex justify-content-flex-start align-items-center'>
                                    <RowList />
                                    <Form.Group as={Col} md='4' className='mb-0'>
                                        <RowList />
                                    </Form.Group>
                                </div>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='12'>
                                <Form.Label className='transform-none'>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
                                <div className='d-flex justify-content-flex-start align-items-center'>
                                    <RowList />

                                    <>
                                        <Form.Group as={Col} md='4' className='mb-0'>
                                            <RowList />
                                        </Form.Group>
                                        <Form.Group as={Col} md='4' className='mb-0'>
                                            <RowList />
                                        </Form.Group>
                                    </>
                                </div>
                            </Form.Group>
                        </Row>
                        <hr />
                        <h3 className='text-left mt-4'>landlord mailling address</h3>
                        <Row>
                            <Form.Group as={Col} md='6'>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Unit </Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='4'>
                                <Form.Label>City</Form.Label>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Province</Form.Label>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Postal Code</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <hr />
                        <h3 className='text-left mt-5'>rental property address</h3>
                        <Row>
                            <Form.Group as={Col} md='4'>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Unit</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='4'>
                                <Form.Label>City</Form.Label>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Province</Form.Label>
                                <RowList />
                            </Form.Group>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Postal Code</Form.Label>
                                <RowList />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md='6'>
                                <Form.Label>Is this rental unit a condo?</Form.Label>
                                <ul className='select-options'>
                                    <li><RowList /></li>)
                            </ul>
                            </Form.Group>
                        </Row>
                    </div>
                </div>
            </Form>
        </>
    )
}
export default LeaseContactDetailsLoader;


const RowList = (props) => {
    return (
        <ContentLoader height={40} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="40" />
        </ContentLoader>
    )
}