import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Card, Button } from 'react-bootstrap';

const ForLeasingAgent = () => {
    return (
        <section className="for-leasing-agent">
            <Container>
                <h2 className="subHead">are you a leasing agent looking to find great tenants for your clients?</h2>
                <Card>
                    <ul className="item-list">
                        <li>post unlimited listings for free.</li>
                        <li>one click gets your properties on Facebook, Kijiji and to the top of the pack.</li>
                        <li>review tenant profiles before booking showings to save you time.</li>
                        <li>schedule and track all the showings on your calendar.</li>
                        <li>get instant credit &amp; background checks at an affordable price.</li>
                        <li>manage applications and approve tenants with a single click.</li>
                        <li>build leases online, with a fulsome lease appendix and store it on your client’s account.</li>
                        <li>easily hand over the listing to your client so they can manage their tenant with online rent collection (coming soon),inspection tools, repair tracking and notice builders for free.</li>
                    </ul>
                    <Button href="/signup/landlord" className='btnAction'>post a listing now</Button>
                </Card>
            </Container>
        </section>
    )
}

export default withRouter(ForLeasingAgent);
