import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import {EditButton, PhoneNumberFormat} from '../../../../components'

const ContactCompleteDetails = props => {
    const {setCurrentStep, contactDetails, notices_and_documents, landlordMailingDetails, propertyAddress} = props;

    return (
        <>
            <div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord contact information</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(contactDetails) &&
                        <>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Full Name</Form.Label>
                                <span>{contactDetails.full_name}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                                <Form.Label>Email Address</Form.Label>
                                <span>{contactDetails.email}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                                <Form.Label>Phone number</Form.Label>
                                <span><PhoneNumberFormat phoneNumber={contactDetails.phone_number} /></span>
                            </Form.Group>
                        </>}
					</Row>
					<Row>
						{(notices_and_documents) &&
                        <>
                            <Form.Group as={Col} md='12'>
                                <Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
                                <span>{notices_and_documents.agree_to_received_notice ?
                                    <>
                                        Yes - email: {notices_and_documents.agree_to_received_notices_email}
                                    </>
                                    : 'No'}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='12'>
                                <Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
                                <span>{notices_and_documents.day_to_day_communication ?
                                    <>
                                        Yes  - email: {notices_and_documents.day_to_day_communication_email}, phone number: &nbsp;
                                    <PhoneNumberFormat phoneNumber={notices_and_documents.day_to_day_communication_phone_number} />
                                    </>
                                    : 'No'}</span>
                            </Form.Group>
                        </>}
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord mailing address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(landlordMailingDetails) &&
                        <>
                            <Form.Group as={Col} md='4'>
                                <Form.Label>Address</Form.Label>
                                <span>{landlordMailingDetails.mailing_address}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='1'>
                                <Form.Label>Unit</Form.Label>
                                <span>{landlordMailingDetails.mailing_unit_no || ' -- '}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>City</Form.Label>
                                <span>{landlordMailingDetails.mailing_city}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>Province</Form.Label>
                                <span>{landlordMailingDetails.mailing_province}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>Postal Code</Form.Label>
                                <span>{landlordMailingDetails.mailing_postal_code}</span>
                            </Form.Group>
                        </>}
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>rental property address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(propertyAddress) &&
                        <>
                            <Form.Group as={Col} md='4'>
                                <Form.Label>Address</Form.Label>
                                <span>{propertyAddress.address}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='1'>
                                <Form.Label>Unit</Form.Label>
                                <span>{propertyAddress.unit_no || ' -- '}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>City</Form.Label>
                                <span>{propertyAddress.city}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>Province</Form.Label>
                                <span>{propertyAddress.province}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='2'>
                                <Form.Label>Postal Code</Form.Label>
                                <span>{propertyAddress.postal_code}</span>
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                                <Form.Label>Is this rental unit in a condo?</Form.Label>
                                <span>{(propertyAddress.is_condo === 0 ? "No" : "Yes")}</span>
                            </Form.Group>
                        </>}
					</Row>
				</div>
			</div>
        </>
    )
}

export default ContactCompleteDetails
