import AuthAxios from "../../AuthAxios";
import { API_URL } from "../../Constants/endPoints"

export async function editProfileAPI() {
  try {
    const res = await AuthAxios.get(
      API_URL + "/edit-profile"
    );
    return res.data;
  } catch (err) {
    if (err.response.status === 400) {
      throw new Error(err.response.data.message);
    } else if (err.response.status !== 200) {
      throw new Error("failed");
    }
  }
}