import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ModalBox, TableTenant } from '../../components';
import { getAPI, postAPI } from '../../../Api';
import { getBackPath, getRole, setTableData, setUserStatus, setFilters } from '../../../Store/actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import TableContentLoader from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader';
import { phoneNumberSanitizer } from '../../../Utils/NumberSanitizer/NumberSanitizer';
import logger from '../../../Utils/logger';

const log = logger("TenantTableComponent");

const TenantTableComponent = (props) => {

    const [openSwitchModal, setOpenSwitchModal] = useState(false);
    const [profile, setProfile] = useState(null);
    const dispatch = useDispatch();
    const activeUserCurrentPage = useSelector(state => state.userReducer.activeUserCurrentPage);
    const filters = useSelector(state => state.userReducer.filters);
    const activeUserFilters = useSelector(state => state.userReducer.activeUserFilters);
    const tableData = useSelector(state => state.userReducer.activeUserTableData);
    const [isLoading, setIsLoading] = useState(true);

    const goToProfileDetail = (user) => {
        let dataFilter = {
            'filter': {
                'name': filters.name || "",
                'email': filters.email | "",
                'phone_number': phoneNumberSanitizer(filters.phone_number),
                'user_type': filters.user_type || "",
            },
            'sort': {
                'by': 'is_active',
                'order': 'desc'
            },
            'limit': 15
        };
        user.user_type.toLowerCase() === 'landlord' &&
            props.history.push({
                pathname: '/profile-detail/landlord/' + user.id + '/listings', state: { path: 'active' }
            });
        user.user_type.toLowerCase() === 'tenant' && props.history.push({
            pathname: '/profile-detail/tenant/' + user.id + '/profile', state: { path: 'active' }
        });
        dispatch(getRole(dataFilter))
        dispatch(getBackPath('active'))
    }

    const onClickSwitch = (data) => {
        setOpenSwitchModal(true);
        setProfile(data);
    }
    const toggleModal = () => {
        setOpenSwitchModal(!openSwitchModal);
    }
    const goBack = () => {
        let url = '';
        if (profile.user_type === 'Landlord') {
            url = '/admin/users/switch-to-tenant/' + profile.id;
        } else if (profile.user_type === 'Tenant') {
            url = '/admin/users/switch-to-landloard/' + profile.id;
        }
        getAPI(url).then(response => {
            toggleModal();
            profile.user_type === 'Landlord' ?
                props.history.push('/profile-detail/tenant/' + profile.id + '/profile') :
                props.history.push('/profile-detail/landlord/' + profile.id + '/listings')
        })
    }
    useEffect(() => {
        dispatch(setUserStatus('active'));
        dispatch(setFilters(activeUserFilters));
    }, [])

    useEffect(() => {
        // setIsLoading(true)
        let insideurl = '';
        if (activeUserCurrentPage !== null) {
            insideurl = `/admin/users?page=${activeUserCurrentPage}`;
        } else {
            insideurl = `/admin/users`;
        }
        let data = {
            'filter': {
                'name': filters.name || "",
                'email': filters.email || "",
                'phone_number': phoneNumberSanitizer(filters.phone_number) || "",
                'user_type': filters.user_type || "",
            },
            'sort': {
                'by': 'id',
                'order': 'desc'
            },
            'limit': 15,
        };
        log.info("data", data);
        try {
            postAPI(insideurl, data).then(response => {
                setIsLoading(false)
                dispatch(setTableData(response.data));
            })
        } catch (err) {
            log.error(err)
        }

    }, [filters, activeUserCurrentPage, dispatch]);

    let userColumns = [];
    userColumns = [
        {
            header: 'Name of User',
            name: 'name',
            width: '25%'
        },
        {
            header: 'Email',
            name: 'email',
            width: '25%'
        },
        {
            header: 'Phone Number',
            name: 'phone_number',
            width: '25%'
        },
        {
            header: 'Role',
            name: 'user_type',
            width: '25%'
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'userManagement'} /> : <>
                {tableData && tableData.data ?
                    <TableTenant
                        columns={userColumns}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        onClickSwitch={(data) => onClickSwitch(data)}
                        component={'userManagement'}
                    /> : ''}
            </>}
            <ModalBox
                show={openSwitchModal}
                onHide={toggleModal}
                size='sm'
                actionbuttontitle={'yes, switch profile'}
                buttonaction={goBack}
                extraBtnStyle='extraBtnStyle'
            >
                <p className='switching-details list'>You are switching <span className='profile-name'>{profile && profile.name}’s</span> Profile
                from a {profile && profile.user_type.toLowerCase()} to a {profile && profile.user_type === 'Landlord' ? 'tenant' : 'landlord'} Continue?</p>
            </ModalBox>
        </React.Fragment>
    )
};

export default withRouter(TenantTableComponent);