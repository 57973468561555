import { getAPI, postAPI } from '../../../../Api';

export const getCertnReport = async (tenant_id, conversationId,from='landlord') => {
  try {
    const url = `/${from}/get-certn-report/${tenant_id}/${conversationId}`;
    const res = getAPI(url);
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    return error;
  }
};

export const getCreditRequestIdApi = async (
  tenant_id,
  property_details_id,
  property_id
) => {
  const res = await postAPI('/landlord/invite-to-apply', {
    tenant_id,
    property_details_id,
    property_id,
  });
  const creditCheckId = res.data?.data?.credit_check_request_id;
  const tenancy_application_id = res?.data?.data?.tenancy_application_id
  return {requestId:creditCheckId,tenancy_application_id};
};

export const getCertnCheck = async ({
  property_id,
  property_details_id,
  tenancy_application_id,
  conversation_id,
  tenant_id,
  credit_check_request_id
}) => {
  try {
    const url = '/landlord/get-consent-check';
    const data = {
      property_id: property_id,
      property_details_id: property_details_id,
      tenancy_application_id: tenancy_application_id,
      conversation_id: conversation_id,
      tenant_id: tenant_id,
      credit_check_request_id:credit_check_request_id
    };
    const res = await postAPI(url,data);
    return res;
  } catch (err) {
    return false;
  }
};
