import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from '../../../../Api';
import { logger, toaster } from '../../../../Utils';
import { AdminUserDetails, AppLayout, LargeButton, ModalBox, PageTitleHeader, UnitAddress, UnitDescription, UnitDetails, UnitDetailsImageCarousel } from '../../../components';
import AdminNotification from './AdminNotification';
import ApprovalStatus from './ApprovalStatus';
import MainDetails from './EditAdminListing/MainDetails';
import InternalNotes from './InternalNotes';
import ListingStatus from './ListingStatus';
import TenantDetailSection from './TenantDetailSection';
import AdminUnitDetails from './EditAdminListing/AdminUnitDetails';
import AdminDescription from './EditAdminListing/AdminDescription';
import AdminImageEdit from './EditAdminListing/AdminImageEdit';
import DeactivateListing from './EditAdminListing/DeactivateListing';
import ReasonTwo from './EditAdminListing/ReasonTwo';
import AddingTenants from './EditAdminListing/AddingTenants';
import CreateLeaseOptions from './EditAdminListing/CreateLeaseOptions';
import UserDetailContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UserDetailContentLoader/UserDetailContentLoader';
import CauraselContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/CauraselContentLoader/CauraselContentLoader';
import UnitAddressContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UnitAddressContentLoader/UnitAddressContentLoader';
import UnitListContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UnitListContentLoader/UnitListContentLoader';
import UnitDescriptionContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UnitDescriptionContentLoader/UnitDescriptionContentLoader';
import { getUnitDetail, setPropertyDetail } from '../../../../Store/actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import ReasonFour from './EditAdminListing/ReasonFour';
import CheckListTenant from './CheckListTenant';
import UploadLeaseDocument from '../UploadManualLease/UploadLeaseDocument';
import LeaseDuration from '../UploadManualLease/LeaseDuration';
import PromoteListing from './PromoteListing';
import { getPromotionPlan } from '../LeaseDetails/LeaseConstants';
import MarkAsCompleted from '../../RequestManagement/MarkAsCompleted';
import AssignedRequestPanel from '../../RequestManagement/AssignedRequestPanel';
import TenantRoomatesBlock from '../../../components/TenantRoomatesBlock/TenantRoomatesBlock';

const log = logger('AdminListingDetails');
const AdminListingDetails = (props) => {
	const backPath = useSelector(state => state.propertyDetailsReducer.backPath);

	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	// const [user_type, setUserType] = useState('');
	const [user_id, setUserId] = useState('');
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [action, setAction] = useState('');
	const [profile, setProfile] = useState(null);
	const [buttonTitle, setButtonTitle] = useState('');
	const [openMainDetails, setMainDetails] = useState(false);
	const [fetchedDeactive, setFetchedDeactive] = useState(null);
	const [singleReasonData, setReasonData] = useState(null);
	const [openReasonTwo, setOpenReasonTwo] = useState(false);
	const [openReasonFour, setOpenReasonFour] = useState(false);
	const [openAddtenant, setAddTenant] = useState(false);
	const [createLeaseOption, setCreateListOption] = useState(false);
	const [checkListTenants, setCheckListTenants] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const propertyDetailsSelector = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const [propertyDetails, setPropertyDetails] = useState(propertyDetailsSelector);
	const [lease_id, setLeaseId] = useState('');
	const unitSelector = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const [unitDetails, setUnitDetails] = useState(unitSelector);
	const [skipModal, setSkipOpen] = useState(false);
	const [openManualLeaseUpload, setOpenUploadLease] = useState(false);
	const [leaseDuration, setOpenLeaseDuration] = useState(false);
	const [confirmUploadLease, setConfirmUploadLease] = useState(false);
	const [confirmUploadLeaseFinal, setConfirmUploadLeaseFinal] = useState(false);
	const [promoteSidePanel, setSidePromote] = useState(false);
	const [promotionModal, setPromotionModal] = useState(false);
	const [promotionData, setPromotionData] = useState('');
	const [path] = useState('');
	// const [requestNotification, openNotificationRequest] = useState(false)
	const [tenantList, setTenantList] = useState([]);
	const [tenantDetailFrom, setAddtenantDetail] = useState('');
	const [teantOutside, setTeantsOutside] = useState([]);

	const [clickedData, setClickedData] = useState(null);
	const [markData, setMarkData] = useState(null);
	const [openMarkCompleted, setMarkCompleted] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [requestAssignedPanel, openAssignedRequest] = useState(false);

	const [imageSubmitModal, openImageSubmitModal] = useState(false);
	const [isConfirmed, clickedConfirm] = useState(false);
	const [titleUpload, setTitleUpload] = useState(false);
	// const [clear, setClear] = useState(false)

	const onClickedData = (data) => {
		setClickedData(data)
		setLeaseId(data.id)
	}

	const callChecklistTenant = (data) => {
		setCheckListTenants(data);
		setAddtenantDetail('upload-lease');
		localStorage.setItem('tenantDetailFrom', 'upload-lease')
	}
	const toggleMainDetails = () => {
		setMainDetails(!openMainDetails)
	}
	const [openUnitDetails, setUnitOpenDetails] = useState(false);
	const toggleUnitDetails = () => {
		setUnitOpenDetails(!openUnitDetails)
	}
	const [openDescriptionDetails, setDescriptionDetails] = useState(false);
	const toggleDescriptionDetails = () => {
		setDescriptionDetails(!openDescriptionDetails)
	}
	const [openImageDetails, setImageDetails] = useState(false);
	const toggleImageDetails = () => {
		setImageDetails(!openImageDetails)
	}
	const [openDeactivate, setOpenDeactivate] = useState(false);
	const toggleDeactivate = () => {
		setOpenDeactivate(!openDeactivate)
		apiCallback();
	}

	const backToDashboard = () => {
		if (backPath) {
			if (backPath === 'active' || backPath === 'inactive') {
				props.history.push({
					pathname: '/profile-detail/landlord/' + user_id + '/listings', state: { path: backPath },
				});
			} else if (backPath === 'all-listing' || backPath === 'rented' || backPath === 'promoted' || backPath === 'inactiveListing') {
				props.history.push({
					pathname: `/listing-management/${backPath === 'inactiveListing' ? 'inactive' : backPath}`,
				});
			} else if (backPath === 'request-management') {
				props.history.push('/request-management');
			} else if (backPath === 'root-lease-details') {
				if (unitDetails && propertyDetails.user) {
					log.info(propertyDetails.user)
					props.history.push(`/lease-detail/${propertyDetails.user.user_type.toLowerCase()}/${unitDetails.lease.id}/${propertyDetails.user.id}`)
				}
			} else if (backPath === 'request') {
				props.history.push('/request-management')
			}
		}
	}
	const editDetailsImage = (step) => {
		setImageDetails(true)
	}
	const editDetailsAddress = () => {
		setMainDetails(true)
	}
	const editDetailsUnit = () => {
		setUnitOpenDetails(true)
	}
	const editDetailsDescription = () => {
		setDescriptionDetails(true)
	}
	const getReasonData = (reasonData) => {
		setReasonData(reasonData)
		if (reasonData && reasonData.order === 2) {
			// setCheckListTenants(true);
			setCreateListOption(true)
		} else if (reasonData && (reasonData.order === 4 || reasonData.order === 5)) {
			// setOpenReasonFour(true);
			setCreateListOption(true)
		}
	}

	const apiCallback = () => {
		setIsLoading(true);
		const url = '/admin/listing/listing-details/' + property_id + '/' + property_details_id;
		getAPI(url).then(response => {
			setIsLoading(false);
			if (response.status === 200) {
				const res = response.data.data;
				setPropertyDetails(res);
				dispatch(setPropertyDetail(res));
				res.details.length > 0 &&
					res.details.map((data) => {
						if (data.id === property_details_id) {
							setUnitDetails(data)
							dispatch(getUnitDetail(data));
						}
						return data;
					})
			}
		})
	}
	const toggleReasonTwo = (data) => {
		setOpenReasonTwo(!openReasonTwo);
		if (data === 'callBackApi') {
			apiCallback();
		}
	}
	const toggleReasonFour = (data) => {
		setOpenReasonFour(!openReasonFour);
		if (data === 'callBackApi') {
			apiCallback();
		}
	}
	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === 'switch') {
			onClickSwitch(data)
			setButtonTitle('yes, switch profile');
		} else if (action === 'suspend' || action === 'active') {
			suspendUser(data);
			if (action === 'suspend') {
				setButtonTitle('suspend');
			} else if (action === 'active') {
				setButtonTitle('yes, activate');
			}
		}
	}
	const onClickSwitch = (data) => {
		setProfile(data);
	}
	const suspendUser = (userData) => {
		setProfile(userData);
	}
	const goBack = () => {
		let url = '';
		if (action === 'suspend') {
			url = '/admin/users/suspend/' + user_id;
		} else if (action === 'active') {
			url = '/admin/users/activate/' + user_id;
		}
		getAPI(url).then(res => {
			setOpenSwitchModal(false);
			apiCallback();
		})
	}
	const openDeactivateList = (value) => {
		setLeaseId('')
		if (value === false) {
			setOpenDeactivate(true)
			setFetchedDeactive(value)
		} else {
			setOpenDeactivate(false)
			setFetchedDeactive(value)
		}
	}
	const callAddTenant = (data, from) => {
		log.info('bsdkjfjn', data)
		setAddTenant(data)
		setAddtenantDetail(from)
	}
	const toggleAddTenant = (data) => {
		setAddTenant(!openAddtenant)
		if (data === 'callBackApi') {
			apiCallback();
		}
	}
	const openCreateLeaseOption = (data) => {
		log.info(data)
		setCreateListOption(data)
	}
	const toggleCreateLeaseOption = (data) => {
		setCreateListOption(!createLeaseOption)
		if (data === 'callBackApi') {
			apiCallback();
		}
	}
	const openLeaseDuration = (data) => {
		setAddtenantDetail('upload-lease')
		setOpenLeaseDuration(data)
		localStorage.setItem('tenantDetailFrom', 'upload-lease')
	}
	const toggleLeaseDuration = (data) => {
		setOpenLeaseDuration(!leaseDuration)
		if (data === 'callBackApi') {
			apiCallback();
			clickedConfirm(true);
		}
		clickedConfirm(false);
	}
	const sendBackRes = (data) => {
		setCheckListTenants(data)
		log.info("tenantDetailFrom", tenantDetailFrom)
	}
	const toggleCheckListTenant = () => {
		setCheckListTenants(!checkListTenants)
	}
	const openSkipModal = (data) => {
		setSkipOpen(data)
	}
	const onClickYes = () => {
		const url = '/admin/listing/deactive/' + property_details_id;
		const data = {
			'deactivate_reason': singleReasonData && singleReasonData.order,
			'deactivate_description': singleReasonData && singleReasonData.deactive_reason,
			'is_active': 0
		}
		postAPI(url, data).then(res => {
			if (res.status === 200) {
				apiCallback();
				setSkipOpen(false);
			}
		})

	}
	const onClickNo = () => {
		callAddTenant(true);
		setSkipOpen(false)
	}

	const openUploadLease = (data) => {
		setOpenUploadLease(data)

	}
	const toggleUploadLease = (data) => {
		setOpenUploadLease(!openManualLeaseUpload)
		if (data === 'callBackApi') {
			apiCallback();
		}
	}
	const openConfirmationUploadLease = (data) => {
		setConfirmUploadLease(data)
	}
	const getConfirmed = () => {
		setConfirmUploadLease(false)
		// setConfirmUploadLeaseFinal(true)
		apiCallback()
	}
	const openPromoteListing = () => {
		setSidePromote(true)
	}
	const togglePromote = (promotionPlan) => {
		log.info(promotionPlan)
		setSidePromote(!promoteSidePanel)
		if (promotionPlan) {
			setPromotionData(promotionPlan)
		}
	}

	const markeData = (data) => {
		setMarkData(data)
	}
	const closeModal = () => {
		setModalShow(false);
	}
	const onConfirm = () => {
		let url = `/admin/requests/mark-complete`;
		postAPI(url, markData).then(res => {
			if (res.status === 200) {
				setModalShow(false)
				toaster('success', 'Data Saved Successfully!');
				clickedConfirm(true);
			}
		})
		clickedConfirm(false);
	}
	const toggelRequestPanel = () => {
		openAssignedRequest(!requestAssignedPanel)
	}

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		// setUserType(props.match.params.user_type);
		setUserId(props.match.params.user_id);
		// dispatch(getBackPath(props.location.state.path))
		// setComponent(props.location.state === undefined ? bacKPath : props.location.state.path);
		// log.info('path', bacKPath)
	}, [
		props.match.params.property_id,
		props.match.params.property_details_id,
		props.match.params.user_type,
		props.match.params.user_id,
		// props.location.state
	]);
	useEffect(() => {
		if (property_id) {

			const url = '/admin/listing/listing-details/' + property_id + '/' + property_details_id;
			getAPI(url).then(response => {
				setIsLoading(false)
				if (response.status === 200) {
					const res = response.data.data;
					setPropertyDetails(res);
					dispatch(setPropertyDetail(res));
					if (res.details.length > 0) {
						res.details.map((data) => {
							if (data.id === property_details_id) {
								setUnitDetails(data)
								dispatch(getUnitDetail(data));
							}
							return data;
						})
					} else {
						setUnitDetails(null)
						dispatch(getUnitDetail(null));
					}
				} else if (response.response && response.response.status === 400) {
					backToDashboard()
					toaster('error', 'Invalid Property')
				}
			})
		}
	}, [property_id, property_details_id, dispatch])

	const publishNow = () => {
		let url = '/admin/publish-property';
		const data = {
			property_id: props.match.params.property_id,
			property_details_id: props.match.params.property_details_id
		}
		postAPI(url, data).then(res => {
			if (res.status === 200) {
				toaster('success', res.data?.message);
				apiCallback();
			} else if (res.response?.status === 400) {
				toaster('error', res.response?.data?.message);
			}
		})
	}

	return (
		<>
			<AppLayout>
				<div className='profile'>
					<PageTitleHeader
						title='property details'
						// mobileTitle='property details'
						isArrow={true}
						onClick={() => backToDashboard()}
					/>
				</div>
				<ListingStatus unitDetails={unitDetails && unitDetails} publishNow={publishNow} />
				{isLoading === true ? <UserDetailContentLoader /> :
					<div className='container-detail admin'>
						<Row>
							<Col className='card-detail'>
								<div className='detail-left'>

									{propertyDetails && <AdminUserDetails userDetails={propertyDetails.user} user_type={propertyDetails.user && propertyDetails.user.user_type} />}
								</div>
								{propertyDetails && propertyDetails.user &&
									<div className='detail-right'>
										{(propertyDetails.user.is_active !== 'Suspended') &&
											<LargeButton onClick={() => toggleModal(propertyDetails.user, 'suspend')} className='suspend-btn' variant={'dark'} title='suspend user' style={{ width: '177px', float: 'right' }} />}
										{(propertyDetails.user.is_active !== 'Active') &&
											<LargeButton onClick={() => toggleModal(propertyDetails.user, 'active')} className='suspend-btn' variant={'dark'} title='activate' style={{ width: '177px', float: 'right' }} />}
									</div>
								}
							</Col>
						</Row>
					</div>}

				{isLoading === true ? <UserDetailContentLoader /> : <TenantDetailSection user_type={(propertyDetails && propertyDetails.user) && propertyDetails.user.user_type} apiCallback={apiCallback} />}
				<div className='profile-container'>
					<div className='profile-container-box property-details'>
						<Row style={{ padding: '0 8px' }}>
							<Col md='6'>
								{isLoading === true ? <CauraselContentLoader /> :
									<>
										{propertyDetails && <UnitDetailsImageCarousel
											editDetails={() => editDetailsImage(1)}
										/>
										}
									</>}
								{isLoading === true ? <UnitAddressContentLoader /> :
									<>
										{propertyDetails && <UnitAddress
											editDetails={() => editDetailsAddress(1)}
										/>
										}
									</>}
								{isLoading === true ? <UnitListContentLoader /> :
									<>
										{unitDetails &&
											<UnitDetails
												editDetails={() => editDetailsUnit(2)}
											/>
										}
									</>}
								{isLoading === true ? <UnitDescriptionContentLoader /> :
									<>
										{unitDetails &&
											<UnitDescription
												editDetails={() => editDetailsDescription(3)}
											/>
										}
									</>}
							</Col>
							<Col md='6'>
								<ApprovalStatus
									isLoading={isLoading}
									property_details_id={property_details_id}
									apiCallback={() => apiCallback()}
									openDeactivateList={(data) => openDeactivateList(data)}
								/>
								<div>{unitDetails && unitDetails.formatted_status === 'rented' && <TenantRoomatesBlock leaseData={unitDetails.lease} title={"ROOMMATES"} />}</div>
								<AdminNotification style={{ color: "#118AAF" }}
									userDetails={propertyDetails && propertyDetails.user}
									openNotificationRequest={(data) => setMarkCompleted(data)}
									setClickedData={(data) => onClickedData(data)}
									isConfirmed={isConfirmed}
									path={path}
									openLeaseDuration={(data) => openLeaseDuration(data)}
								/>
								<InternalNotes
									property_details_id={property_details_id}
									apiCallback={() => apiCallback()}
								/>
								{
									(unitDetails && (unitDetails.formatted_status === 'approved' && (unitDetails.is_active && unitDetails.is_active === 1)))
										? <LargeButton saveLoading={false} isDisable={propertyDetails.user && propertyDetails.user.is_active === 'Suspended' ? true : false} title='promote listing' style={{ height: '72px' }} type='button' onClick={() => openPromoteListing()} />
										: ''
								}
							</Col>
						</Row>
					</div>
				</div>
			</AppLayout>
			{openMainDetails === true &&
				<MainDetails
					isShow={openMainDetails}
					handleClose={() => toggleMainDetails()}
					apiCallback={(data) => apiCallback(data)}
				/>}
			{openUnitDetails === true &&
				<AdminUnitDetails
					isShow={openUnitDetails}
					handleClose={() => toggleUnitDetails()}
					apiCallback={(data) => apiCallback(data)}
				/>}
			{openDescriptionDetails === true &&
				<AdminDescription
					isShow={openDescriptionDetails}
					handleClose={() => toggleDescriptionDetails()}
					apiCallback={(data) => apiCallback(data)}
				/>}
			{openImageDetails === true &&
				<AdminImageEdit
					isShow={openImageDetails}
					handleClose={() => toggleImageDetails()}
					apiCallback={(data) => apiCallback(data)}
					path={path}
					openImageSubmitModal={(data) => openImageSubmitModal(data)}
				/>}
			{fetchedDeactive === false ?
				<DeactivateListing
					isShow={openDeactivate}
					handleClose={() => toggleDeactivate()}
					apiCallback={(data) => apiCallback(data)}
					setOpenDeactivate={(data) => setOpenDeactivate(data)}
					getReasonData={(data) => getReasonData(data)}
				/> : ''}
			{(singleReasonData && singleReasonData.order === 2) ?
				<ReasonTwo
					isShow={openReasonTwo}
					handleClose={(data) => toggleReasonTwo(data)}
					apiCallback={(data) => apiCallback(data)}
					property_details_id={property_details_id}
					setOpenDeactivate={(data) => setOpenDeactivate(data)}
					singleReasonData={singleReasonData}
					callAddTenant={(data) => callAddTenant(data)}
					property_id={property_id}
					user_id={user_id}
					openCreateLeaseOption={(data) => openCreateLeaseOption(data)}
				/> : ''
			}
			{(singleReasonData && (singleReasonData.order === 4 || singleReasonData.order === 5)) ?
				<ReasonFour
					isShow={openReasonFour}
					handleClose={(data) => toggleReasonFour(data)}
					apiCallback={(data) => apiCallback(data)}
					property_details_id={property_details_id}
					setOpenDeactivate={(data) => setOpenDeactivate(data)}
					singleReasonData={singleReasonData}
					property_id={property_id}
					user_id={user_id}
					setTenantList={(data) => setTeantsOutside(data)}
					openCreateLeaseOption={(data) => openCreateLeaseOption(data)}
					openReasonFour={() => toggleReasonFour()}
				/> : ''
			}
			{openAddtenant === true &&
				<AddingTenants
					isShow={openAddtenant}
					handleClose={(data) => toggleAddTenant(data)}
					apiCallback={(data) => apiCallback(data)}
					property_details_id={property_details_id}
					setOpenDeactivate={(data) => setOpenDeactivate(data)}
					sendBackRes={(data) => sendBackRes(data)}
					from={localStorage.getItem('tenantDetailFrom') ? localStorage.getItem('tenantDetailFrom') : 'listing-details'}
					singleReasonData={singleReasonData}
					setSkipOpen={(data) => openSkipModal(data)}
					openLeaseDuration={(data) => openLeaseDuration(data)}
					titleUpload={titleUpload === true}
				/>
			}
			{checkListTenants === true &&
				<CheckListTenant
					isShow={checkListTenants}
					handleClose={() => toggleCheckListTenant()}
					apiCallback={(data) => apiCallback(data)}
					sendBackRes={(data) => sendBackRes(data)}
					callAddTenant={(data) => callAddTenant(data)}
					selectedTenants={tenantList}
					setTenantList={(data) => setTenantList(data)}
					openCreateLeaseOption={(data) => openCreateLeaseOption(data)}
					from={localStorage.getItem('tenantDetailFrom')}
					openLeaseDuration={(data) => openLeaseDuration(data)}
					titleUpload={titleUpload === true && 'uploadTitle'}
				/>
			}
			{createLeaseOption === true &&
				<CreateLeaseOptions
					isShow={createLeaseOption}
					handleClose={(data) => toggleCreateLeaseOption(data)}
					apiCallback={(data) => apiCallback(data)}
					openUploadLease={(data) => openLeaseDuration(data)}
					user_id={user_id}
					singleReasonData={singleReasonData}
					path={path}
				/>
			}
			{openManualLeaseUpload === true &&
				<UploadLeaseDocument
					isShow={openManualLeaseUpload}
					handleClose={(data) => toggleUploadLease(data)}
					apiCallback={(data) => apiCallback(data)}
					openLeaseDuration={(data) => openLeaseDuration(data)}
					setLeaseId={(data) => setLeaseId(data)}
				/>
			}
			{leaseDuration === true &&
				<LeaseDuration
					isShow={leaseDuration}
					handleClose={(data) => toggleLeaseDuration(data)}
					openUploadLease={(data) => openUploadLease(data)}
					openConfirmationUploadLease={(data) => openConfirmationUploadLease(data)}
					callAddTenant={(data) => callChecklistTenant(data)}
					selectedTenant={tenantList}
					setSelectedTenant={(data) => setTenantList(data)}
					teantOutside={teantOutside}
					lease_id={lease_id ? lease_id : 0}
					setTitleUpload={(data) => setTitleUpload(data)}
				/>
			}
			{promoteSidePanel === true &&
				<PromoteListing
					isShow={promoteSidePanel}
					handleClose={(data) => togglePromote(data)}
					apiCallback={(data) => apiCallback(data)}
					setPromotionModal={(data) => setPromotionModal(data)}
				/>
			}
			{openMarkCompleted === true &&
				<MarkAsCompleted
					isShow={openMarkCompleted}
					handleClose={() => setMarkCompleted(false)}
					setOpenModal={(data) => setModalShow(data)}
					openAssignedRequest={(data) => openAssignedRequest(data)}
					clickedData={clickedData}
					markeData={(data) => markeData(data)}
					path={'listing'}
				/>
			}
			{requestAssignedPanel === true &&
				<AssignedRequestPanel
					isShow={requestAssignedPanel}
					handleClose={(data) => toggelRequestPanel(data)}
					setMarkCompleted={(data) => setMarkCompleted(data)}
					clickedData={clickedData}
				/>
			}
			<ModalBox
				show={modalShow}
				onHide={() => closeModal(false)}
				size="sm"
				type="condition"
				actionbuttontitle={'Yes'}
				buttonaction={() => onConfirm()}
				backbuttontitle={'Cancel'}
				backaction={() => closeModal(false)}
			>
				<p className="switching-details">Are you sure you want to mark request as completed?</p>
			</ModalBox>
			<ModalBox
				show={openSwitchModal}
				onHide={toggleModal}
				size='sm'
				actionbuttontitle={buttonTitle}
				buttonaction={goBack}
			>
				{action === 'switch' &&
					<p classNmae='switching-details'>You are switching <span className='profile-name'>{profile && profile.name}’s</span> Profile
                    from a {profile && profile.user_type.toLowerCase()} to a {profile && profile.user_type === 'Landlord' ? 'tenant' : 'landlord'} Continue?</p>}
				{action === 'suspend' &&
					<p classNmae='switching-details'>Are you sure to suspend <span className='profile-name'>{profile && profile.name}’s</span> <br />
                    They will be unable to apply to any properties within Rent Panda
                </p>}
				{action === 'active' &&
					<p classNmae='switching-details'>Are you sure to activate <span className='profile-name'>{profile && profile.name}’s</span> <br />
                    Their rented property will remain within Rent Panda but other listing will be activated automatically
            </p>}
			</ModalBox>
			<ModalBox
				show={skipModal}
				closeButtonHeader={false}
				size='sm'
				type='condition'
				actionbuttontitle={'Yes'}
				buttonaction={() => onClickYes()}
				backbuttontitle={'Cancel'}
				backaction={() => onClickNo()}
			>
				<h4>are you sure you want to skip?</h4>
				<p className='order-one-modal'>you won’t get access to the management tool where you can collect rent, serve notices, record repairs and more!</p>
			</ModalBox>
			<ModalBox
				show={confirmUploadLease}
				onHide={getConfirmed}
				size='sm'
				actionbuttontitle={'got it'}
				buttonaction={getConfirmed}
				extraClassUpper='upload-lease-confirm'
			>
				<p className='order-one-modal p-0 m-0'>your lease has been uploaded and your property is now rented</p>
			</ModalBox>
			<ModalBox
				show={confirmUploadLeaseFinal}
				onHide={() => setConfirmUploadLeaseFinal(false)}
				size='sm'
				actionbuttontitle={'got it'}
				buttonaction={() => setConfirmUploadLeaseFinal(false)}
			>
				<h4>the lease has been signed and the property will be de-activated only if the lease has been signed</h4>
			</ModalBox>
			<ModalBox
				show={promotionModal}
				onHide={() => setPromotionModal(false)}
				size='sm'
				actionbuttontitle={'got it'}
				buttonaction={() => setPromotionModal(false)}
				extraClassUpper={'promotion-modal'}
			>
				<p className='promotion-modal-data'>This property is now being promoted for</p>
				<p className='promotion-modal-data mb-0'>{getPromotionPlan(promotionData)} starting today</p>
			</ModalBox>
			<ModalBox
				show={imageSubmitModal}
				onHide={() => openImageSubmitModal(false)}
				size='sm'
				actionbuttontitle={'got it'}
				buttonaction={() => openImageSubmitModal(false)}
				extraClassUpper={'promotion-modal'}
			>
				<p className='promotion-modal-data'>photos has been saved and uploaded</p>
			</ModalBox>
		</>
	)
}

export default withRouter(AdminListingDetails);