import React from 'react'
import UploadIcon from '../../../_assets/images/upload-icon.svg'
import ViewIcon from '../../../_assets/images/green-eye-icon.svg'


function LeaseFooter(props) {
    const { rentDetails, viewDownloadPdf, role, uploadPDF } = props
    return (
        <div className="rent-card-footer" style={{ justifyContent: !rentDetails?.is_offline_lease_uploaded ? 'flex-end' : 'center' }}>
            {!rentDetails?.is_offline_lease_uploaded && rentDetails?.rp_lease_type !== 1 && role === 2 && rentDetails?.is_landlord_sign_offline &&
                <span
                    onClick={uploadPDF}
                    className='cursor-pointer'
                    style={{ marginRight: 30 }}>
                    <img alt=""
                        src={UploadIcon}
                    />
                      Upload lease
                   </span>}
            <span onClick={() => {
                viewDownloadPdf();
            }} className='cursor-pointer'>
                <img alt=""
                    src={ViewIcon}
                />
                  View and Download
                </span>
        </div>
    )
}

export default LeaseFooter
