import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { AppLayout, ModalBox, TableTenant } from "../../components";
import { logger, toaster } from "../../../Utils";
import MarkAsCompleted from "./MarkAsCompleted";
import moment from 'moment'
import AssignedRequestPanel from "./AssignedRequestPanel";
import { getAPI, postAPI } from "../../../Api";
import TableContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader";
import { useDispatch, useSelector } from "react-redux";
import { requestManagementPagination } from "../../../Store/actions/Action";
import { setTableData } from "../../../Store/actions/RequestAction";
import { setFilters } from '../../../Store/actions/ListingAction';
import { setCouponFilters } from '../../../Store/actions/CouponAction';
import { getQueryParams, fetchTableData } from "./helper";
import {userColumns} from "./constants";

const log = logger("RequestManagementTable");
const RequestManagementTable = (props) => {

	const [searchName, setSearchName] = useState('');
	const [searchCity, setSearchCity] = useState('');
	const [searchRole, setSeachRole] = useState('');
	const [searchRequestType, setRequestType] = useState(['all']);
	const [searchdateTime, setSearchDateTime] = useState(['all']);
	const [searchRequestStatus, setRequeststatus] = useState(['all']);
	const [openMarkCompleted, setMarkCompleted] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [requestAssignedPanel, openAssignedRequest] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [searchDate,setSearchDate]= useState('');
	const [tableData, setTabledata] = useState([]);
	const [clickedData, setClickedData] = useState(null);
	const [markData, setMarkData] = useState(null);
	const [loginId, setLoginId] = useState(null);
	const [paginationVal, setPaginationVal] = useState(null);
	const dispatch = useDispatch();
	const pagination = useSelector(state => state.propertyDetailsReducer.requestPage);
	const currentPage = useSelector(state => state.requestReducer.currentPage);

	const pathName = window.location.pathname
	useEffect(() => {
		if (!pathName.includes('listing-management')) {
			console.log('Inside use effect or reset state')
			dispatch(setFilters({
				searchCity: '',
				searchAddress: '',
				searchLandlord: '',
				searchTenant: '',
				searchRentpandaId: '',
				is_verified: false,
				sort_by_price_order: '',
				sort_by_status: ['all'],
				filterByExpiryDate: ''
			}));
		}
		if (!pathName.includes('coupon-management')) {
			dispatch(setCouponFilters({
				coupon_name: '',
				created_date: 'desc',
				no_of_usage: '',
				total_no_of_usage: '',
				start_date: '',
				expiry_date: '',
				coupon_code: '',
				coupon_value: '',
			}))
		}
	}, [pathName, dispatch])


	let filterData = {
		"name_of_user": searchName,
		"role": searchRole === 'all' ? '' : searchRole, //all, landlord, tenant
		"request_type": searchRequestType.includes('all') ? [] : searchRequestType, //1: Realtor Partner, 2: Property Management, 3: Advertising, 4: Panda Partner, 5: Professional Photoshoot, 6: Paralegal Consulting, 7: Full Service Inquiry, 8: Listing Promotion
		"city": searchCity,
		"date_time": searchdateTime.includes('all') ? [] : searchdateTime, //daytime, afternoon, evening 
		"actions": searchRequestStatus.includes('all') ? [] : searchRequestStatus, // 0: Needs Approval, 1: Completed, 2: Assigned to me
		"search_date": (searchDate&&moment(searchDate).format('YYYY-MM-DD') )|| '' 

	}

	const sortType = (event) => {
		const item = event.target.name;
		const isChecked = event.target.checked;
		log.info('item', item, '==== isChecked', isChecked)
		let arraydata = [...searchRequestType]
		let returnVal = [];
		if (item === 'all') {
			let arr = []
			if (isChecked === true) {
				arr = ['all']
			} else {
				arr = []
			}
			returnVal = arr;
		} else if (item !== 'all') {
			isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
			if (arraydata.includes('all')) {
				const indexElm = arraydata.indexOf('all');
				arraydata.splice(indexElm, 1);
			}
			returnVal = arraydata;
		}
		setRequestType(returnVal)
	}
	const dateTime = (event) => {
		const item = event.target.name;
		const isChecked = event.target.checked;
		let arraydata = [...searchdateTime]
		let returnVal = [];
		if (item === 'all') {
			let arr = []
			if (isChecked === true) {
				arr = ['all']
			} else {
				arr = []
			}
			returnVal = arr;
		} else if (item !== 'all') {
			isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
			if (arraydata.includes('all')) {
				const indexElm = arraydata.indexOf('all');
				arraydata.splice(indexElm, 1);
			}
			returnVal = arraydata;
		}
		setSearchDateTime(returnVal)
	}
	const requestStatus = (event) => {
		const item = event.target.name;
		const isChecked = event.target.checked;
		let arraydata = [...searchRequestStatus]
		let returnVal = [];
		if (item === 'all') {
			let arr = []
			if (isChecked === true) {
				arr = ['all']
			} else {
				arr = []
			}
			returnVal = arr;
		} else if (item !== 'all') {
			isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
			if (arraydata.includes('all')) {
				const indexElm = arraydata.indexOf('all');
				arraydata.splice(indexElm, 1);
			}
			returnVal = arraydata;
		}
		setRequeststatus(returnVal)
	}

	const goToProfileDetail = (data) => {
		setMarkCompleted(!openMarkCompleted)
		setClickedData(data)
		dispatch(requestManagementPagination(paginationVal))
	}

	useEffect(() => {
		const params = getQueryParams(window.location.search, ['request_type_id', 'id']).split(",");
		const data = {'request_type_id': parseInt(params[0]), 'id': parseInt(params[1])};

		if (data.request_type_id && data.id) {
			setMarkCompleted(true);
			setClickedData(data);
		}
    }, [props.location.search]);

	const markeData = (data) => {
		setMarkData(data)
	}
	const closeModal = () => {
		setModalShow(false);
	}
	const onConfirm = () => {
		let url = `/admin/requests/mark-complete`;
		postAPI(url, markData).then(res => {
			if (res.status === 200) {
				setModalShow(false)
				toaster('success', 'Data Saved Successfully!')
				let url = '';
				if (currentPage === null) {
					url = `/admin/requests/list`;
				} else {
					url = `/admin/requests/list?page=${currentPage}`;
				}
				postAPI(url, filterData).then(response => {
					log.info('response', response)
					if (response.status === 200) {
						setIsLoading(false)
						setTabledata(response.data.data);
						dispatch(setTableData(response.data.data))
					}
				})
			}
		})
	}

	const onSaveInternalNotes = async () => {
		const response = await fetchTableData(pagination, currentPage, filterData);
			if (response.status === 200) {
				setIsLoading(false)
				setTabledata(response.data.data);
				dispatch(setTableData(response.data.data))
			}
	}

	const toggelRequestPanel = () => {
		openAssignedRequest(!requestAssignedPanel)
	}
	const getData = (fetchedUrl) => {
		// setIsLoading(true)
		postAPI(fetchedUrl, filterData).then(response => {
			if (response.status === 200) {
				// setIsLoading(false);
				const res = response.data.data;
				setTabledata(res)
				dispatch(setTableData(response.data.data))
			}
		})
	}
	const onClickNext = (currentPage) => {
		const nextPage = currentPage + 1;
		let urlPrev = `/admin/requests/list?page=${nextPage}`;
		getData(urlPrev);
		setPaginationVal(nextPage)
	}
	const onClickPrev = (currentPage) => {
		const prevPage = currentPage - 1;
		let urlPrev = `/admin/requests/list?page=${prevPage}`;
		getData(urlPrev);
		setPaginationVal(prevPage)
	}
	
	useEffect(() => {
		let url = '';
		if (currentPage === null) {
			url = `/admin/requests/list`;
		} else {
			url = `/admin/requests/list?page=${currentPage}`;
		}
		let data = {
			"name_of_user": searchName,
			"role": searchRole === 'all' ? '' : searchRole, //all, landlord, tenant
			"request_type": searchRequestType.includes('all') ? [] : searchRequestType, //1: Realtor Partner, 2: Property Management, 3: Advertising, 4: Panda Partner, 5: Professional Photoshoot, 6: Paralegal Consulting, 7: Full Service Inquiry, 8: Listing Promotion
			"city": searchCity,
			"date_time": searchdateTime.includes('all') ? [] : searchdateTime, //daytime, afternoon, evening 
			"actions": searchRequestStatus.includes('all') ? [] : searchRequestStatus, // 0: Needs Approval, 1: Completed, 2: Assigned to me
			"search_date": (searchDate&&moment(searchDate).format('YYYY-MM-DD') )|| '' 
		
		}
		postAPI(url, data).then(response => {
			log.info('response', response)
			if (response.status === 200) {
				setIsLoading(false)
				setTabledata(response.data.data)
				dispatch(setTableData(response.data.data))
			}
		})
		getAPI(`/edit-profile`).then(response => {
			setLoginId(response.data.data.id)
		})
		if (searchRequestType.length === 0) {
			setRequestType(['all'])
		}
		if (searchdateTime.length === 0) {
			setSearchDateTime(['all'])
		}
		if (searchRequestStatus.length === 0) {
			setRequeststatus(['all'])
		}
	}, [searchName, searchRole, searchRequestType, searchCity, searchdateTime, searchRequestStatus, currentPage, dispatch, pagination,searchDate])
	return (
		<React.Fragment>
			<AppLayout>
				<div className="userManagement">
					<div className="table-container">
						<h2>REQUESTS MANAGEMENT</h2>
						{isLoading === true ? <TableContentLoader columns={userColumns} component={'requestManagement'} /> : <>

							{tableData && tableData.data ?
								<TableTenant
									columns={userColumns}
									goToProfileDetail={(data) => goToProfileDetail(data)}
									component={'requestManagement'}
									searchCity={searchCity}
									searchName={searchName}
									setSearchCity={(data) => setSearchCity(data)}
									setSearchName={(data) => setSearchName(data)}
									setSeachRole={(data) => setSeachRole(data)}
									setSearchDate={(data) => setSearchDate(data)}
									sort_by_type={searchRequestType}
									sortType={(data) => sortType(data)}
									dateTime={(data) => dateTime(data)}
									searchdateTime={searchdateTime}
									searchDate={searchDate}
									searchRequestStatus={searchRequestStatus}
									requestStatus={(data) => requestStatus(data)}
									clickNext={() => onClickNext(tableData.current_page)}
									clickPrev={() => onClickPrev(tableData.current_page)}
									loginId={loginId}
								/> : ''}
						</>}
					</div>
				</div>
			</AppLayout>
			{openMarkCompleted === true &&
				<MarkAsCompleted
					isShow={openMarkCompleted}
					handleClose={() => setMarkCompleted(false)}
					setOpenModal={(data) => setModalShow(data)}
					openAssignedRequest={(data) => openAssignedRequest(data)}
					clickedData={clickedData}
					setTableData={setTableData}
					markeData={(data) => markeData(data)}
					path='request'
					filterData={filterData}
					setIsLoading={setIsLoading}
					onSaveInternalNotes={onSaveInternalNotes}
				/>
			}
			{requestAssignedPanel === true &&
				<AssignedRequestPanel
					isShow={requestAssignedPanel}
					handleClose={(data) => toggelRequestPanel(data)}
					setMarkCompleted={(data) => setMarkCompleted(data)}
					clickedData={clickedData}
				/>
			}
			<ModalBox
				show={modalShow}
				onHide={() => closeModal(false)}
				size="sm"
				type="condition"
				actionbuttontitle={'Yes'}
				buttonaction={() => onConfirm()}
				backbuttontitle={'Cancel'}
				backaction={() => closeModal(false)}
			>
				<p className="switching-details">Are you sure you want to mark request as completed?</p>
			</ModalBox>
		</React.Fragment>
	)
};

export default withRouter(RequestManagementTable);