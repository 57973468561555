import React, { useState } from 'react';
import { Col, Row, Form, Image, Button } from 'react-bootstrap';
import { logger, toaster } from '../../../../../Utils';
import { LargeButton, CancelButton, SidePanel } from '../../../../components';
import { AddingTenantSchema } from './Schema/AddingTenantSchema';
import { validate } from '../../../../../Utils/Validation/validation';
import { postAPI } from '../../../../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantsList } from '../../../../../Store/actions/AddedTenantAction';

const log = logger('AddingTenants');
const AddingTenants = (props) => {
    const { isShow, handleClose, sendBackRes, from, setSkipOpen, apiCallback, singleReasonData } = props;
    const listTenants = useSelector(state => state.addedTenantReducer.addedTenants);
    const dispatch = useDispatch();
    const [tenantAdded, setTenantAdded] = useState([
        {
            tenantFullName: '', tenantEmailId: ''
        }
    ]);

    const OnChangeInput = (event, index) => {
        log.info(event.target.value);
        let items = [...tenantAdded];
        let item = { ...items[index] };
        const name = event.target.name ? event.target.name : event.target.getAttribute('name')
        item[name] = event.target.value;
        items[index] = item;
        setTenantAdded(items);
    }
    const addMore = () => {
        const data = { tenantFullName: '', tenantEmailId: '' }
        setTenantAdded(tenantAdded => [...tenantAdded, data])
    }
    const OnRemove = (index) => {
        let items = [...tenantAdded];
        items.splice(index, 1);
        setTenantAdded(items);
    }
    const addAndInvite = () => {
        if (validate(AddingTenantSchema)) {
            const url = '/admin/listing/lease/add-tenant';
            const data = [];
            tenantAdded.map((tenant) => {
                const newData = {
                    name: tenant.tenantFullName,
                    email: tenant.tenantEmailId
                }
                data.push(newData)
                return tenant;
            })
            const tenant = {
                'tenants': data
            }
            postAPI(url, tenant).then(res => {
                if (res.status === 200) {
                    let resTenant = [];
                    if (listTenants !== null && listTenants !== undefined) {
                        let concated = res.data.data.concat(listTenants[0]);
                        resTenant.push(concated);
                    } else {
                        resTenant.push(res.data.data);
                    }
                    dispatch(getTenantsList(resTenant))
                    handleClose();
                    sendBackRes(true);
                } else if (res.response.status === 422) {
                    toaster('error', 'Tenant email ID has already been taken')
                }
            })
        }
    }
    const handleCloseConfirm = () => {
        if (localStorage.getItem(localStorage.getItem('tenantDetailFrom')) === 'upload-lease') {
            handleClose();
            apiCallback();
        } else {
            handleClose();
            sendBackRes(true);
        }

    }
    const openSkipModal = () => {
        setSkipOpen(true)
        handleClose();
    }


    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title={from === 'listing-details' ? 'Add And Send Invite' : 'ADD AND SEND INVITATION'}
            extraClasses={from === 'listing-details' ? '' : 'lease-side'}
            subTitle={from === 'listing-details' ? 'Invite a tenant outside of rent panda' : 'invite a tenant outside of rent panda'} >
            < div className='deactive-reasons sidepanel-body'>
                <Form id='Form'>
                    {tenantAdded ?
                        tenantAdded.map((data, i) => {
                            return (
                                <div key={'tenantAdded' + i} className={'tenantAdded' + i}>
                                    <Row>
                                        <Form.Group as={Col} md='12'>
                                            <div className='display-flex-inline-elm'>
                                                <Form.Label>Tenant’s Full Name</Form.Label>
                                                {i !== 0 &&
                                                    <div className='sub-title d-flex justify-content-between align-items-center pt-0'>
                                                        <Image
                                                            className='icon cursorPointer'
                                                            width='20px'
                                                            height='20px'
                                                            title='Remove'
                                                            onClick={() => OnRemove(i)}
                                                            src={require('../../../../../_assets/images/delete-icon.svg')}
                                                        />
                                                    </div>}
                                            </div>
                                            <Form.Control
                                                type='text'
                                                placeholder='John Doe'
                                                name='tenantFullName'
                                                id={'tenantFullName' + i}
                                                value={data.tenantFullName}
                                                onChange={(e) => OnChangeInput(e, i)} />
                                        </Form.Group>
                                        <Form.Group as={Col} md='12'>
                                            <Form.Label>Tenant’s Email</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='name@mail.com'
                                                name='tenantEmailId'
                                                id={'tenantEmailId' + i}
                                                value={data.tenantEmailId}
                                                onChange={(e) => OnChangeInput(e, i)} />
                                        </Form.Group>
                                    </Row>
                                    <hr />
                                </div>)
                        }) : ''}
                </Form>

                <Row>
                    <Col md='12'>
                        <Button onClick={() => addMore()} type='button' className='pull-right add-more'>+ Add More</Button>
                    </Col>
                </Row>
                {props.titleUpload === false && <>
                    {(singleReasonData && singleReasonData.order === 2) && <Row className="fixed-bottom skip-link">
                        <Col md='12'>
                            <Button onClick={() => openSkipModal()} type="button" className="skip-btn mb-4" style={{ textDecoration: 'none' }}>Skip</Button>
                        </Col>
                    </Row>}
                </>
                }
            </div>

            <div className='fixed-bottom d-flex justify-content-between'>
                <CancelButton onClick={() => handleCloseConfirm()} title={from === 'listing-details' ? 'Back' : 'back'} style={{ fontWeight: 'bold' }} />
                <LargeButton title='add and invite' onClick={() => addAndInvite()} style={{ width: '160px' }} />
            </div>
        </SidePanel>
    </>);
}
export default AddingTenants;