import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import { logger } from '../../Utils';
import { AppLayout } from "../../App/components";
import { Container } from "react-bootstrap"; //, Row, Col
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import "react-quill/dist/quill.snow.css";
import "./style.scss";

// const log = logger('Preview Blog');
const LandingPage = (props) => {
  const [previewData, setPreviewData] = useState("");
 
  /* Binds the meta description after receiving previewData ONLY if excerpt is available and not null/'' */
  const [metaData, setMetaData] = useState("");
  const setMeta = (previewData) => {
    if(!!previewData.excerpt) {
      setMetaData([{
        name: 'description',
        content: previewData.excerpt,
      }]);
    }
  }
  
  useEffect(() => {
    if (props.previewData) {
      setPreviewData(props.previewData);
    }
  });

  return (
    <AppLayout noSidebar={true} newWebHeader={true}>
      {previewData && (
        <div className="previewPage">
          
            <Helmet meta={metaData}>
                <title>{previewData.headline}</title>
            </Helmet>
          <section className="sec-landing-standalone">
            <Container>
              <main>
                <article>
                  <header>
                    <h1 className="mainHead text-left">
                      {previewData.headline}
                    </h1>
                  </header>
                  {/* <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            Category: <strong>{previewData.category && previewData.category.name}</strong>
                                        </Col>
                                        <Col className="mb-3">
                                            Tags:
                                            {
                                                previewData.cms_tags.map((tag, i) => {
                                                    return <strong key={`tag_${i}`}>{tag.name}</strong>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="lastCol">
                                    <Row>
                                        <Col>Created By: <span>{previewData.author}</span></Col>
                                        <Col>
                                            Last Edited On: 
                                            <Moment format="MMM D, YYYY">{previewData.updated_at}</Moment>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="pageExcerpt">Excerpt: {previewData.excerpt}</div> */}
                  {/* <div className="pageContent">{previewData.content}</div> */}
                  <div className="pageContent">
                    {props.children}
                  </div>
                </article>
              </main>
            </Container>
          </section>
        </div>
      )}
    </AppLayout>
  );
};

export default LandingPage;