import React from "react";
import { Container, Row, Col, Nav, Image } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Loader } from "../../App/components";
import "./style.scss";
const placeHolderImg = require("../../_assets/images/placeholder.jpg");

const ArticlesCardList = (props) => {
    const {
        articlesList,
        colCount,
        showLoader,
        numberOfCards,
    } = props;

    const articlesCard = () => {
        return articlesList.length > 0 ? (
            articlesList.map((article, i) => {
                return (
                    <Col key={"article" + article.id} md={colCount}
                        className="grid mb-4 col-12 col-md-4">
                        <div className="resources-box">
                            <div className="resources-box-image">
                                <LazyLoadImage
                                    effect="blur"
                                    className="grid-img"
                                    src={article.image_url ? article.image_url : placeHolderImg}
                                    alt="grid-img"
                                />
                            </div>
                            <h4 className="grid-name">{article.headline}</h4>
                            <p className="grid-content">{article.excerpt}</p>
                            <div className="mt-auto">
                                <Nav.Link
                                    className="date-text"
                                    href={`/resources/blog/preview/${article.id}`}
                                >
                                    Read more
                                    <Image
                                        className="pl-2 next"
                                        src={require("../../_assets/images/arrow-forward.svg")}
                                        alt="View More"
                                    />
                                </Nav.Link>
                            </div>
                        </div>
                    </Col>
                );
            })
        ) : showLoader ? (
            <Loader />
        ) : (
            <h2>No Data Found</h2>
        );
    };

    return (
        <section>
            <Container>
                <Row>
                    {articlesList && articlesList.length >= numberOfCards && (
                        <Col className="text-right viewMore col-auto">
                            <Nav.Link className="date-text" href={`/resources/blog`}>
                                View More
                                <Image
                                    className="pl-2 next"
                                    src={require("../../_assets/images/arrow-forward.svg")}
                                    alt="View More"
                                />
                            </Nav.Link>
                        </Col>
                    )}
                    {articlesList && articlesCard()}
                </Row>
            </Container>
        </section>
    );
};

export default ArticlesCardList;
