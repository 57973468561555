export const CreateLeaseDetailsSchema = {
    rent: [
        { rule: "required", message: "Rent required" },
    ],
    other_lease_type: [
        { rule: "required", message: "Clarification required" },
    ],
    deposit_amount: [
        { rule: "required", message: "Amount required" },
    ],
    rent_paid_on: [
        { rule: "required", message: "Rent paid on required" },
    ],
    parking_spot: [
        { rule: "required", message: "Spot required" },
    ],
    parking_price: [
        // { rule: "required", message: "Price required" },
        // { rule: "maxLength", maxLength: 12, message: "Max parking price can be 99999999999" },
        // { rule: "decValue", maxLength: 9, message: "Parking price cannot have more than 8 numbers before decimal" }
    ],
    rent_payable_to: [
        { rule: "required", message: "Rent payable to is required" },
    ],
    full_name_contact: [
        { rule: "required", message: "Name is required" },
    ],
    administrative_charge: [
        { rule: "required", message: "Administrative charge required" },
    ],
    is_rent_deposit_amount: [
        { rule: "required", message: "Rent deposite required" },
    ],
    key_deposite_amount: [
        { rule: "required", message: "Key deposite required" },
    ],
    rent_discount_amount: [
        { rule: "required", message: "Amount required" },
    ],
    rent_discount_description: [
        { rule: "required", message: "Description required" },
    ],
    start_date: [
        { rule: "required", message: "Start date required" },
    ],
    end_date: [
        { rule: "required", message: "End date required" },
    ],
    partial_cover_start_date: [
        { rule: "required", message: "Start date required" },
    ],
    partial_cover_end_date: [
        { rule: "required", message: "End date required" },
    ],
    partial_cover_amount: [
        { rule: "required", message: "Amount required" },
    ],
    partial_payment_date: [
        { rule: "required", message: "Date required" },
    ],
    day_to_day_communication_email: [
        { rule: "email", message: "Email is required" },
    ],
    agree_to_received_notices_email: [
        { rule: "email", message: "Email is required" },
    ],

}