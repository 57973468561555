import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger } from '../../../../Utils';
import { AppLayout, ModalBox, PageTitleHeader, PDFViewerComponent } from '../../../components';
import CreateLeaseContactInfo from './CreateLeaseContactInfo';
import CreateLeaseDetails from './CreateLeaseDetails';
import CreateLeaseTenantDetails from './CreateLeaseTenantDetails';
import AddingTenants from '../AdminListingDetails/EditAdminListing/AddingTenants';
import CompleteLeaseDetails from './CompleteLeaseDetails';
import ConfirmListTenants from './ConfirmListTenants';
import SignLease from './SignLease';
import FinalCreateLease from './FinalCreateLease';
import { useDispatch, useSelector } from 'react-redux';
import { notificationPagination } from '../../../../Store/actions/Action';

const log = logger('RootCreateLease');
const RootCreateLease = (props) => {
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState('');
	const [user_id, setUserId] = useState('');
	const [openAddtenant, setAddTenant] = useState(false);
	const [componentName, setComponent] = useState('');
	const [addtenantWindow, setAddTenantWindow] = useState(false);
	const [apiCall, apiCallback] = useState(false);
	const [signLease, setOpenSignLease] = useState(false);
	const [finalCreateLease, setOpenFinalCreateLease] = useState(false);
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [data, setData] = useState(null);
	const [backClicked, setBackClicked] = useState(false);
	const dispatch = useDispatch();

	const heading = useSelector(state => state.propertyDetailsReducer.leaseHeading);

	const [skip, setSkip] = useState(false);
	const [appendix, setAppendix] = useState(false);
	const [pdfData, setPdfData] = useState(null);

	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setComponent(props.match.params.component);
		setLeaseId(props.match.params.lease_id);
		setUserId(props.match.params.user_id);
		setSkip(false)
	}, [
		props.match.params.property_id,
		props.match.params.property_details_id,
		props.match.params.component,
		props.match.params.user_id,
		props.match.params.lease_id]);

	const goBack = () => {
		setOpenSwitchModal(false);
		props.history.push({ pathname: `/profile-detail/listings/landlord/${user_id}/${property_id}/${property_details_id}` })
	}

	const back = () => {
		dispatch(notificationPagination(null));
		props.history.push({ pathname: `/profile-detail/listings/landlord/${user_id}/${property_id}/${property_details_id}` })
	}
	const callAddTenant = (data) => {
		setAddTenant(data)
	}
	const toggleAddTenant = () => {
		setAddTenant(!openAddtenant)
	}
	const openAddTenantWindow = (data) => {
		setAddTenantWindow(data);
		toggleAddTenant();
	}
	const toggleAddTenantWindow = () => {
		setAddTenantWindow(!addtenantWindow);
	}
	const sendBackRes = (data) => {
		setAddTenant(true)
	}
	const openSignLease = (data, reason) => {
		log.info('sdlkasfm ', data)
		setOpenSignLease(data)
	}
	const toggleOpenSignLease = () => {
		setOpenSignLease(!signLease)
	}
	const openFinalCreateLease = (data) => {
		setOpenFinalCreateLease(data)
		setBackClicked(false)
	}
	const toggleOpenFinalCreateLease = () => {
		setOpenFinalCreateLease(!finalCreateLease)
		setBackClicked(true)
	}
	const openConfirmation = (data) => {
		setOpenSwitchModal(data)
	}
	const openPDFView = (data) => {
		setPdfData(data);
		setAppendix(true)
	}
	const closeAppendix = () => {
		setAppendix(false)
		setOpenFinalCreateLease(true)
	}

	let subHeading = '';
	if (componentName === 'contact-details') {
		subHeading = 'Fill out the info below to begin'
	} else if (componentName === 'lease-details') {
		subHeading = 'Let’s talk about the lease details'
	} else if (componentName === 'tenant-details') {
		subHeading = 'One page to go and you’re done'
	} else if (componentName === 'complete-lease-details') {
		subHeading = 'Review the lease details'
	}

	return (<>

		<AppLayout>
			<div >
				<PageTitleHeader
					isArrow={true}
					onClick={() => back()}
					title={heading !== 'edit' ? 'CREATE A LEASE' : 'EDITING LEASE DETAILS'}
					progress={
						(componentName === 'contact-details') ? 20 :
							(componentName === 'lease-details') ? 50 :
								(componentName === 'tenant-details') ? 70 :
									(componentName === 'complete-lease-details') && 90
					}
					subTitle={subHeading}
				/>
			</div>
			{(componentName === 'complete-lease-details') &&
				<>
					{data &&
						<>
							{data.is_signed === true ?
								<div className="top-status delivered">
									<p>Lease Has Been Signed</p>
								</div>
								: (data.is_sent_to_tenant === true ?
									<div className="top-status delivered">
										<p>Lease Has Not Been Signed Yet, Please Upload Your Lease</p>
									</div>
									: <div className="top-status in-progress">
										<p>Please Review The Information Below Before Signing The Lease</p>
									</div>)}
						</>
					}
				</>}

			<div className='profile-container'>
				<div className='profile-container-box'>
					{log.info(componentName)}
					{(componentName === 'contact-details') &&
						<CreateLeaseContactInfo/>
					}
					{(componentName === 'lease-details') &&
						<CreateLeaseDetails/>
					}
					{(componentName === 'tenant-details') &&
						<CreateLeaseTenantDetails
							callAddTenant={(data) => callAddTenant(data)}
							apiCall={apiCall}
						/>
					}
					{(componentName === 'complete-lease-details') &&
						<CompleteLeaseDetails
							component='lease-detail'
							openFinalCreateLease={(data) => openFinalCreateLease(data)}
							openSignLease={(data, reason) => openSignLease(data, reason)}
							setData={(data) => setData(data)}
							backClicked={backClicked}
						/>
					}
				</div>
			</div>

		</AppLayout>
		{openAddtenant === true &&
			<ConfirmListTenants
				isShow={openAddtenant}
				handleClose={() => toggleAddTenant()}
				property_details_id={property_details_id}
				property_id={property_id}
				openAddTenantWindow={(data) => openAddTenantWindow(data)}
				apiCallback={(data) => apiCallback(data)}
				lease_id={lease_id}
				from="create-lease"
			/>
		}
		{addtenantWindow === true &&
			<AddingTenants
				isShow={addtenantWindow}
				handleClose={() => toggleAddTenantWindow()}
				property_details_id={property_details_id}
				sendBackRes={(data) => sendBackRes(data)}
				from="create-lease"
			/>
		}
		{signLease === true &&
			<SignLease
				isShow={signLease}
				handleClose={() => toggleOpenSignLease()}
				openFinalCreateLease={(data) => openFinalCreateLease(data)}
				setSkip={(data) => setSkip(data)} />
		}
		{finalCreateLease === true &&
			<FinalCreateLease
				isShow={finalCreateLease}
				handleClose={() => toggleOpenFinalCreateLease()}
				openSignLease={(data) => openSignLease(data)}
				openConfirmation={(data) => openConfirmation(data)}
				openPDFView={(data) => openPDFView(data)}
			/>
		}
		<ModalBox
			show={openSwitchModal}
			onHide={goBack}
			size="md"
			actionbuttontitle={"got it"}
			buttonaction={goBack}
			extraClassUpper={'create-lease-option'}
		>
			{skip !== true ?
				<div classNmae="switching-details">
					<h4>Congrats on renting your place! </h4>
					<p style={{ padding: '0 22px' }}>You have uploaded a lease and sent it to the tenant for their records. make sure that you and the tenant have copies of the signed lease for your records.</p>
				</div> :
				<div classNmae="switching-details">
					<h4>you have sent the lease to the tenant(s) for offline signing</h4>
					<p style={{ padding: '0 22px', textTransform: 'lowercase' }}>once signed, the tenant can send it to<br />your on Rent panda and you will be<br />notified to sign it</p>
				</div>
			}
		</ModalBox>
		<ModalBox
			show={appendix}
			onHide={() => closeAppendix()}
			size="xl"
			className="cu-full-modal"
		>
			<PDFViewerComponent PDFUrl={pdfData && pdfData.lease_pdf_url} />
		</ModalBox>
	</>);
}

export default withRouter(RootCreateLease);
