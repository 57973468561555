import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formsPdfUrlAction } from '../../../../../Store/actions/Action';
import { getAPI} from '../../../../../Api';
import { Col, Row, Image, Button } from 'react-bootstrap';
import { SidePanel, Loader } from '../../../../components';
import './style.scss'
import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';

const PreviewForms = ({ previewForm, setPreviewForm }) => {
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    const [viewData, setViewData] = useState('');
    const [showLoader, setShowLoader]=useState(false)

    const dispatch = useDispatch();

    const handleClose = () => { setPreviewForm(false) }
    
    useEffect(() => {
        if (clickedData !== null) {
            setShowLoader(true);
            getAPI(`/admin/cms/forms/view/${clickedData.id}`).then(resData => {
                if (resData.status === 200) {
                    const resDataView = resData.data.data;
                    setViewData(resDataView);
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                }
            })
        }
    }, [clickedData])

    return (
        viewData && 
        <>
            <SidePanel
                extraClasses="previewForms"
                isShow={previewForm}
                isHide={handleClose}
                title='Form content Detail'>
                <div className='sidepanel-body'>
                    <Row className='row-spacing'>
                        {showLoader ? <Loader /> : <Col md='12'>
                            <Image className="previewThumb" src={viewData.thumbnail_url ? viewData.thumbnail_url : require("../../../../../_assets/images/placeholder.jpg")} alt="" />
                        </Col>}
                    </Row>
                    
                    <h4 className='sub-heading'>Tenant Important from</h4>
                    <Row>
                        {showLoader ? <Loader /> : <Col md='12'>
                            <div className="boldText categoriesBox">Category: <span>{viewData.category && viewData.category.name}</span></div>
                            {viewData.cms_tags &&
                                <div className="boldText tagsBox">
                                    Tags:
                                    {viewData.cms_tags.map(tag => <span key={tag.id} className="tag">{tag.name}</span>)}
                                </div>
                            }
                            <div className='ql-editor' dangerouslySetInnerHTML={{__html: viewData.description}}></div>
                            <Button variant="link" onClick={() => dispatch(formsPdfUrlAction(viewData.form_url))}>Read more &rsaquo;</Button>
                        </Col>}
                    </Row>
                </div>
            </SidePanel>
        </>
    );
}
export default withRouter(PreviewForms);