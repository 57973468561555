

import React from "react";

const MultipleCheckOptions = (props) => {
    const { mapData, OnMultipleOptionCheck, name, setParam, extra } = props
    return (
        <>
            <ul className="select-options">
                {mapData.map((data) =>
                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={name + data.value} value={data.value} name={name}
                        className={(setParam.includes(data.value)) ? "active" : ""}>{data.title}</li>)}
                {extra && extra}
            </ul>
        </>
    );
}
export default MultipleCheckOptions;