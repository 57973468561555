export const ParalegalFormSchema = {
    fullName: [
        { rule: "required", message: "Name is required" },
    ],
    appointment_time: [
        { rule: "required", message: "Appointment time is required" },
    ],
    dateContact: [
        { rule: "required", message: "Appointment date is required" },
    ],
    question: [
        { rule: "required", message: "Question is required" },
    ],
}