export const SET_COUPON_COMPONENT              = 'SET_COMPONENT';
export const SET_COUPON_FILTERS         = 'SET_COUPON_FILTERS';
export const SET_ACTIVE_COUPON_TABLEDATA       = 'SET_ACTIVE_TABLEDATA';
export const SET_INACTIVE_COUPON_TABLEDATA     = 'SET_INACTIVE_TABLEDATA';
export const SET_ACTIVE_COUPON_CURRENT_PAGE    = 'SET_ACTIVE_CURRENT_PAGE';
export const SET_INACTIVE_COUPON_CURRENT_PAGE  = 'SET_INACTIVE_CURRENT_PAGE';

export function setCouponComponent(data) {
    return function (dispatch) {
        dispatch({ type: SET_COUPON_COMPONENT, currentComponent: data });
    };
}

export function setCouponFilters(data) {
    return function (dispatch, getState) {
        dispatch({ type: SET_COUPON_FILTERS, filters: data });
        const state = getState();
        if (state.CouponReducer.currentComponent === 'active') {
            dispatch(setActiveCouponCurrentPage(1));
        } else if (state.CouponReducer.currentComponent === 'inactive') {
            dispatch(setInActiveCouponCurrentPage(1));
        } 
    };
}

export function setActiveCouponTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_ACTIVE_COUPON_TABLEDATA, activeCouponTableData: data });
    };
}

export function setInActiveCouponTableData(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_COUPON_TABLEDATA, inActiveCouponTableData: data });
    };
}

export function setActiveCouponCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_ACTIVE_COUPON_CURRENT_PAGE, activeCouponCurrentPage: data });
    };
}

export function setInActiveCouponCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: SET_INACTIVE_COUPON_CURRENT_PAGE, inActiveCouponCurrentPage: data });
    };
}

