import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PhoneNumberFormat, ProfileCard } from "../../../components";
import Cookies from 'js-cookie';

const PersonalReferencesInfoDisplay = (props) => {
  const { profileDetails, editaction } = props;
  const role = Number(Cookies.get('role'));

  return (
    <ProfileCard
      title="References"
      editaction={editaction}>

      <Container fluid>
        <Row>
          <Col md='12'>
            <label>Name</label>
          </Col>
        </Row>
        {profileDetails.personal_reference ?
          profileDetails.personal_reference.map((data, i) => {
            return (
              <Row key={'reference_' + i} className="mb-2">
                <Col md='2'>
                  <h3>{data.name}</h3>
                </Col>
                <Col md='2'>
                  <label>{data.relationship}</label>
                </Col>
                <Col md='2'>
                  <h3 className="highlight"><PhoneNumberFormat phoneNumber={data.phone_number} /></h3>
                </Col>
              </Row>
            )
          }) :
          <Row>
            <Col md='12' className="d-flex justify-content-center">
              <label>No Information Provided</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default PersonalReferencesInfoDisplay;