export const generateReport = (reportData) => {
  const headline = reportData.headline
  const chapters = reportData.chapters.split(", ")
  const sections = Object.keys(reportData)
  const sectionPat = /section/i
  const sectionKeys =  sections.filter((section) => sectionPat.test(section))
  let report = {
    headline: headline,
    sectionLength: sectionKeys.length,
    reportSections: []
  }

  for (let i = 0; i <= sectionKeys.length ; i++) {
    let sectionData
    const section = reportData[`section ${i}`]
    if (section) {
      sectionData = JSON.parse(section)
      report.reportSections.push({
        section: i,
        title: chapters[i],
        data: sectionData
      })
    }
  }

  return report
}