import React from 'react';
import "./style.scss"
import Map from "../../../_assets/images/new_homepage/Map.png"
import useWindowDimensions from "../../../App/hooks/WindowSize";


const YouAreCoveredSection = () => {
    const { height, width } = useWindowDimensions();
  
    return (
          <div className="youAreCoveredSection">
            <div className="youAreCoveredHeader">wherever you are in ontario, we've got you covered!</div>
            <div className="youAreCoveredBanner">
              <img src={Map} />
            </div>
          </div>
    );
};

export default YouAreCoveredSection;