import { changePasswordAPI } from "../api/changePassword-api";

export const POST_CHANGE_PASSWORD_INIT = "POST_CHANGE_PASSWORD_INIT";
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS";
export const POST_CHANGE_PASSWORD_FAILED = "POST_CHANGE_PASSWORD_FAILED";

export function setChangePassword(body, props) {
  return function (dispatch) {
    dispatch({ type: POST_CHANGE_PASSWORD_INIT });
    changePasswordAPI(body, props)
      .then((response) => {
        return dispatch({ type: POST_CHANGE_PASSWORD_SUCCESS, response });
      })

      .catch((error) => {
        dispatch({ type: POST_CHANGE_PASSWORD_FAILED, error: error.message });
      });
  };
}
