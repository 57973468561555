import React from "react";
import "./style.scss";
import Highlighter from 'react-highlight-words';
import { logger } from "../../../Utils";

const log = logger('HighlightContent')
const HighlightContent = (props) => {
    const { searchWord, highlightWord } = props
    log.info(searchWord, highlightWord)
    return (
        <Highlighter
            highlightClassName="highlightClass"
            searchWords={[searchWord]}
            autoEscape={true}
            textToHighlight={highlightWord}
        />
    );
}

export default HighlightContent;