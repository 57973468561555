import { validPhone } from '../helper';
export const ReferenceValidationSchema = {
  name: [{ rule: 'required', message: 'Name required' }],
  relationship: [{ rule: 'required', message: 'Relationship required' }],
  phone_number: [
    { rule: 'required', message: 'Phone number required' },
    {
      rule: 'phone',
      length: 10,
      message: 'Phone number must be of 10 digits',
      isEmpty: true,
    },
  ],
};

export const isValidFields = (data = []) => {
  return data.every((item) => {
    return (
      (item.name && item.relationship && validPhone(item.phone_number)) ||
      (!item.name.length &&
        !item.relationship.length &&
        !item.phone_number?.toString().length)
    );
  });
};

export const isAllEmpty = (data = []) => {
  return data.every((item) => {
    return (
      !item.name.length &&
      !item.relationship.length &&
      !item.phone_number?.toString().length
    );
  });
};
