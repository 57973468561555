import React from 'react';
import { withRouter } from "react-router-dom";
import Layout from '../components/Layout';
import { Referral, FormForRealtors, ForLeasingAgent } from './components';
import { logger } from '../../Utils';
import { Helmet } from 'react-helmet';

import './style.scss';

const log = logger("ForRealtors");
const ForRealtors = (props) => {
	log.info("props", props);

	return (
		<div className="forRealtorsPage">
			<Helmet>
				<title>For Realtors | Rent Panda</title>
				<meta name="description" content="Pandas don't do it alone, they spend time with 
				other pandas in the wild. We love to spend time with realtors and see how we can help your business. " />
			</Helmet>
			<Layout newHeader={true}>
				<section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda loves taking care of you and your clients.</h1>
                    </div>
                </section>

				<Referral />
				<FormForRealtors />
				<ForLeasingAgent />
			</Layout>
		</div >
	)
}

export default withRouter(ForRealtors);
