import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { toaster, validate } from "../../../../Utils";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import LargeButton from "../../Buttons/LargeButton";
import DatePickerComponent from "../../DatePickerComponent";
import TimePickerComponent from "../../TimePickerComponent";
import SignatureForm from "../Signature/SignatureForm";
import "../style.scss";
import { N6Schema } from "./ValidationSchema/N6Schema";
import moment from 'moment';
import {transFormSignData} from './helper'
import { useGlobalState } from "../../../../StateContext";

const N6Form = (props) => {
  const {
    property_id,
    property_detail_id,
    tenant_id,
    noticeData,
    getParalegal,
    triggerReqTenant,
    onBack,onNoticeDataUpdate
  } = props;
  const [globalState,setGlobalState]=useGlobalState()
  const [terminationDate, setTerminationDateDate] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const [noticeReasons, setNoticeReasons] = useState([
    {
      date: null,
      time: null,
      details: "",
    },
    {
      date: null,
      time: null,
      details: "",
    },
    {
      date: null,
      time: null,
      details: "",
    },
  ]);
  const [reason1, setReason1] = useState(false);
  const [reason2, setReason2] = useState(false);
  const [reason3, setReason3] = useState(false);


  useEffect(()=>{
    setGlobalState({ onSubmit: onSubmit })
    return ()=>{
      setGlobalState({ onSubmit: null })
    }
  },[])


  useEffect(()=>{
    onNoticeDataUpdate({    
      tenant_move_out: terminationDate,
      notice_reasons: noticeReasons,
      end_tenancy_reason_1: reason1 ? "1" : "0",
      end_tenancy_reason_2: reason2 ? "1" : "0",
      end_tenancy_reason_3: reason3 ? "1" : "0",
      signData,
      notice_type:6
    })
    setGlobalState({ onSubmit: onSubmit })

  },[tenant_id.length,property_id , terminationDate,phoneError,signData,noticeReasons,reason1,reason2,reason3])



  const onSubmit = () => {
    setGlobalState({isSubmitClicked:true})

    if (tenant_id.length === 0) {
      triggerReqTenant(true);
    }
    if (signData?.phoneNumber === "" || signData?.phoneNumber?.replace("-",'').replace("_",'').length !== 10
) {
    setPhoneError(true);
    return 
  }
    if (
      validate(N6Schema) &&
      tenant_id.length !== 0 &&
      signData?.phoneNumber !== ""
    ) {
      const url = "/landlord/notice/store-notice";
      const data = {
        property_id: property_id,
        property_detail_id: property_detail_id,
        notice_type: 6,
        tenant_move_out: terminationDate,
        notice_reasons: noticeReasons,
        end_tenancy_reason_1: reason1 ? "1" : "0",
        end_tenancy_reason_2: reason2 ? "1" : "0",
        end_tenancy_reason_3: reason3 ? "1" : "0",
        agree_terms_and_conditions: "1",
        first_name: signData && signData?.firstName,
        last_name: signData && signData?.lastName,
        phone_number: signData && signData?.phoneNumber,
        date: signData && signData?.signDate,
        landlord_signature: signData && signData?.full_name_sign,
        tenants: tenant_id,
        i_want_to: 14,
      };
      postAPI(url, data).then((response) => {
        if (response === undefined) {
          toaster("error", "Something went wrong!");
        } else if (response.status === 200) {
          const notice_id = response.data.data.notice_id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
        }
      });
    }
  };
  const disableError = (data) => {
    setPhoneError(data);
  };
  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const handleDateChange = (date, type) => {
    const dateValid = dateSanitizer(date);
    if (type === "termination") {
      setTerminationDateDate(dateValid);
    } else if (type === "event1") {
      let tempArray = [...noticeReasons];
      tempArray[0].date = dateValid;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      let tempArray = [...noticeReasons];
      tempArray[1].date = dateValid;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      let tempArray = [...noticeReasons];
      tempArray[2].date = dateValid;
      setNoticeReasons(tempArray);
    }
  };

  const handleChangeTime = (time, type) => {
    const theDate = new Date(Date.parse(time));
    const timeFormated = theDate.toLocaleTimeString();
    if (type === "event1") {
      let tempArray = [...noticeReasons];
      tempArray[0].time = timeFormated;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      let tempArray = [...noticeReasons];
      tempArray[1].time = timeFormated;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      let tempArray = [...noticeReasons];
      tempArray[2].time = timeFormated;
      setNoticeReasons(tempArray);
    }
  };

  const onChangeDescription = (value, type) => {
    if (type === "event1") {
      let tempArray = [...noticeReasons];
      tempArray[0].details = value;
      setNoticeReasons(tempArray);
    } else if (type === "event2") {
      let tempArray = [...noticeReasons];
      tempArray[1].details = value;
      setNoticeReasons(tempArray);
    } else if (type === "event3") {
      let tempArray = [...noticeReasons];
      tempArray[2].details = value;
      setNoticeReasons(tempArray);
    }
  };

  useEffect(() => {
    if (noticeData ) {
      if(noticeData.tenant_move_out && noticeData.tenant_move_out!=='Invalid date')
      setTerminationDateDate(moment(noticeData.tenant_move_out).toDate());
      setReason1(noticeData.end_tenancy_reason_1 === "0" ? false : noticeData.end_tenancy_reason_1 === "1" && true)
      setReason2(noticeData.end_tenancy_reason_2 === "0" ? false : noticeData.end_tenancy_reason_2 === "1" && true)
      setReason3(noticeData.end_tenancy_reason_3 === "0" ? false : noticeData.end_tenancy_reason_3 === "1" && true)     
      const tempArray =[...(noticeData.notice_reasons||[])]
      tempArray.forEach((section) => {
        if(section.date && section.date!=='Invalid date')
         section.date = moment(section.date).toDate()        
      });
      setNoticeReasons(tempArray.length ? tempArray:[{
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      },
      {
        date: null,
        time: null,
        details: "",
      },])
    }
  }, [noticeData.notice_type]);
 

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice to End your Tenancy</span>
          <LargeButton
            title={"get help from our paralegal"}
            style={{ width: "310px", margin: "0" }}
            onClick={getParalegal}
            extraClass={'get-paralegal-help-btn'}
          />
        </div>
        <span className="form-title">
          For Illegal Acts or Misrepresenting Income in a Rent-Geared-to-Income
          Rental Unit{" "}
        </span>
        <Form id="Form" className="mt-2">
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label>
                When do you want your tenant to move out? (Date of Termination)
              </Form.Label>
              <DatePickerComponent
                id="terminationDate"
                name="terminationDate"
                format={"dd/MM/yyyy"}
                value={terminationDate && terminationDate}
                handleDateChange={(date) =>
                  handleDateChange(date, "termination")
                }
                minDate={new Date()}
                displayText={"Select or Type"}
                popperPlacement="bottom-start"
              />
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">Reason(s) for Ending Tenancy</span>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Label>
                Please indicate your reasons by checking the boxes on the
                options below
              </Form.Label>
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason1"}
                  name={"reason1"}
                  checked={reason1}
                  onChange={() => {
                    setReason1(!reason1);
                  }}
                  hidden
                />
                <label htmlFor={"reason1"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 1: I believe that you or someone living with you has
                  committed an illegal act or is carrying on an illegal business
                  at the residential complex involving:
                  <ul>
                    <li>the production of an illegal drug,</li>
                    <li>trafficking in an illegal drug,</li>
                    <li>
                      possession of an illegal drug for the purposes of
                      trafficking.
                    </li>
                  </ul>
                  or
                  <br />I believe that you or someone living with you has
                  permitted someone else to carry out one or more of the above
                  illegal activities involving drugs in the residential complex.
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason2"}
                  name={"reason2"}
                  checked={reason2}
                  onChange={() => {
                    setReason2(!reason2);
                  }}
                  hidden
                />
                <label htmlFor={"reason2"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 2: I believe that you or someone living with you has
                  committed an illegal act or is carrying on an illegal business
                  at the residential complex (other than an illegal act or
                  business described in Reason 1),
                  <br />
                  or
                  <br />I believe that you or someone living with you has
                  permitted someone else to commit an illegal act or carry on an
                  illegal business at the residential complex (other than an
                  illegal act or business described in Reason 1).
                </span>
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-checkbox-wrapper d-flex justify-content-start">
                <input
                  type="checkbox"
                  className="checkit"
                  id={"reason3"}
                  name={"reason3"}
                  checked={reason3}
                  onChange={() => {
                    setReason3(!reason3);
                  }}
                  hidden
                />
                <label htmlFor={"reason3"} className="checkmark"></label>
                <span className="checkbox-title">
                  Reason 3: You live in a rent-geared-to-income rental unit and
                  I believe that you have misrepresented your income or the
                  income of family members who live in the rental unit.
                </span>
              </div>
            </Form.Group>
          </Row>
          <div className="border-separator"></div>
          <div className="mt-4">
            <span className="form-title">
              Details about the reasons for notice
            </span>
          </div>
          <Form.Label>
            List up to 3 events below that have led you to give this notice,
            including the dates, times and specific detail
          </Form.Label>
          <div className="event-details">
            <span className="event-title">Event 1 Details</span>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <DatePickerComponent
                  id="date1"
                  name="date1"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons && noticeReasons[0].date && noticeReasons[0].date}
                  handleDateChange={(date) => handleDateChange(date, "event1")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <TimePickerComponent
                  name={"event1"}
                  id={"event1"}
                  viewDateParam={noticeReasons && noticeReasons[0].time}
                  handleChangeTime={(e) => handleChangeTime(e, "event1")}
                  placeholder={"Type or select"}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details1"
                  id="details1"
                  value={noticeReasons && noticeReasons[0].details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event1")
                  }
                />
              </Form.Group>
            </Row>
          </div>
          <div className="event-details">
            <span className="event-title">Event 2 Details</span>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <DatePickerComponent
                  id="date2"
                  name="date2"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons && noticeReasons[1].date && noticeReasons[1].date}
                  handleDateChange={(date) => handleDateChange(date, "event2")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <TimePickerComponent
                  name={"event2"}
                  id={"event2"}
                  viewDateParam={noticeReasons[1].time}
                  handleChangeTime={(e) => handleChangeTime(e, "event2")}
                  placeholder={"Type or select"}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details2"
                  id="details2"
                  value={noticeReasons[1].details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event2")
                  }
                />
              </Form.Group>
            </Row>
          </div>
          <div className="event-details">
            <span className="event-title">Event 3 Details</span>

            <Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Time</Form.Label>
                <DatePickerComponent
                  id="date3"
                  name="date3"
                  format={"dd/MM/yyyy"}
                  value={noticeReasons[2].date && noticeReasons[2].date}
                  handleDateChange={(date) => handleDateChange(date, "event3")}
                  displayText={"Type or select"}
                  popperPlacement="bottom-start"
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Date</Form.Label>
                <TimePickerComponent
                  name={"event3"}
                  id={"event3"}
                  viewDateParam={noticeReasons[2].time}
                  handleChangeTime={(e) => handleChangeTime(e, "event3")}
                  placeholder={"Type or select"}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="10">
                <Form.Label>Details of events</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="8"
                  placeholder="Write here"
                  name="details3"
                  id="details3"
                  value={noticeReasons[2].details}
                  onChange={(e) =>
                    onChangeDescription(e.target.value, "event3")
                  }
                />
              </Form.Group>
            </Row>
            <div className="border-separator"></div>
            <div className="mt-4 mb-4">
              <span className="form-title">Signature</span>
            </div>
          </div>
          <SignatureForm
            type={"lastName"}
            phoneError={phoneError}
            disableError={(data) => disableError(data)}
            setSignData={(data) => setSignData(data)}
            noticeData={{...noticeData,...transFormSignData( noticeData?.signData || {} ) }}
            notice_type={6}
          />
        </Form>
      </div>
      <div className="container-footer button-extra-margin">
        <Button variant="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Button variant="success" onClick={() => onSubmit()}>
          Review
        </Button>
      </div>
    </>
  );
};

export default withRouter(N6Form);
