import React, { useEffect, useState } from "react";
import { Col, Row, Form, Image } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import DatePickerComponent from "../../DatePickerComponent";
import moment from "moment";
import { logger } from "../../../../Utils";
import { useSelector } from "react-redux";
import { updateRawData } from "../../../../Store/actions/RawDataAction";
import { TERMS_TEXT } from "../../../Landlord/Notices/helper";

const log = logger("SignatureForm");
const SignatureForm = (props) => {
 
  const {
    setSignData,
    notice_type,
    phoneError,
    noticeData,
    type,
    signUserType,
    disableError,
    hideAgreement,
    errors
  } = props;
 
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signingName, setSigningName] = useState("");
  const [signDate, setSignDate] = useState(moment().toDate());
  const [phoneNumber, setPhoneNumber] = useState("");
  const [full_name_sign, setFull_name_sign] = useState(null);
  const [agree, setAgree] = useState(false);
  const [tenant_first_name, setTenantFirstName] = useState("");
  const [tenant_last_name, setTenantLastName] = useState("");
  const [tenant_phone_number, setTenantPhoneNumber] = useState("");
  const [tenant_sign_date, setTenantSignDate] = useState(moment().toDate());
  const [tenant_signature, setTenantSignature] = useState(null);
  const rawData = useSelector((state) => state.rawData)
  const auth = useSelector((state) => state.auth)
   const  basic_info = auth
   useEffect(() => {
    if (!tenant_signature) {
      setTimeout(() => {
        // createSignImage(tenant_first_name + ' ' + tenant_last_name, 'tenant')
        onChangeHandler({ target: { name: 'tenant_first_name', value: tenant_first_name } }, setTenantFirstName);
      }, 1000);
    }
    let timer = setTimeout(() => {
      createSignImage(tenant_first_name + ' ' + tenant_last_name, 'tenant')
    }, 300);
    return () => clearTimeout(timer);
  }, [tenant_first_name, tenant_last_name])

  useEffect(() => {
    let timer = setTimeout(() => {
      createSignImage(firstName + ' ' + lastName)
    }, 300);
    return () => clearTimeout(timer);
  }, [firstName, lastName])


  const redirectToInfo = () => {
    updateRawData({ allCleared: true })
    setTimeout(() =>
      props.history.push(`/notice-info/${notice_type}`), 300);

  };


  const onChangeHandler = (event, state) => {
    const re = /^[A-Za-z]+$/
    const value = event.target.value
    const name = event.target.name
    if (value === '' || re.test(value)) {
      state(value)
      // if (name === 'tenant_first_name' || name === 'tenant_last_name') {
      //   if (name === 'tenant_first_name') {
      //     createSignImage(value + ' ' + tenant_last_name, 'tenant')
      //   } else {
      //     createSignImage(tenant_first_name + ' ' + value, 'tenant')
      //   }
      // } else {
      //   if (name === 'firstName') {
      //     createSignImage(value + ' ' + lastName)
      //   } else {
      //     createSignImage(firstName + ' ' + value)
      //   }
      // }
    }
  }

  const onFullNameChange = (event, state) => {
    const re = /^[A-Za-z ]*$/
    const value = event.target.value
    const name = event.target.name
    if (value === '' || re.test(value)) {
      state(value)
      if (name === 'signingName') {
        createSignImage(value)
      }
    }
  }

  const createSignImage = (value, type) => {
    const textToImage = require("text-to-image");
    textToImage
      .generate("\n\t" + value, {
        fontFamily: "Broetown-Signature",
        customHeight: 70,
        fontSize: 50,
        fontWeight: 600,
        lineHeight: 15,
        margin: 20,
        textColor: "black",
      })
      .then(function (dataUri) {
        if (type === "tenant") {
          setTenantSignature(dataUri);
        } else {
          setFull_name_sign(dataUri);
        }
      });
  };


  useEffect(() => {
    if (noticeData && noticeData.landlord_signature) {
      setFirstName(noticeData.first_name);
      setLastName(noticeData.last_name);
      setSigningName(noticeData.first_name + ' ' + noticeData.last_name);
      setSignDate(moment(noticeData.date).toDate());
      setPhoneNumber(noticeData.phone_number);
      setFull_name_sign(noticeData.landlord_signature);
    }
    if (basic_info) {
      setFirstName(basic_info.first_name);
      setLastName(basic_info.last_name);
      setSigningName(basic_info.first_name + ' ' + basic_info.last_name);
      setPhoneNumber(basic_info.phone);
    }

    if (noticeData && noticeData.tenant_signature) {
      setTenantFirstName(noticeData.tenant_first_name);
      setTenantLastName(noticeData.tenant_last_name);
      setSigningName(noticeData.first_name + ' ' + noticeData.last_name);
      setTenantPhoneNumber(noticeData.tenant_phone_number);
      setTenantSignDate(moment(noticeData.tenant_sign_date).toDate());
      setTenantSignature(noticeData.tenant_signature);
    } else {
      if (auth) {
        setTenantFirstName(auth.first_name)
        setTenantLastName(auth.last_name);
        setTenantPhoneNumber(auth.phone)
      }
    }

  }, 
  [noticeData.notice_type,basic_info?.first_name,]
  );

  useEffect(() => {
    console.log({ tenant_phone_number, phoneNumber })
    setSignData({
      firstName: firstName,
      lastName: lastName,
      signingName: signingName,
      signDate: signDate || '',
      phoneNumber: phoneNumber?.toString().replace(/-/g, ''),
      full_name_sign: full_name_sign,
      tenant_first_name: tenant_first_name,
      tenant_last_name: tenant_last_name,
      tenant_phone_number: tenant_phone_number?.toString().replace(/-/g, ''),
      tenant_sign_date: tenant_sign_date,
      tenant_signature: tenant_signature,
      agree: agree
    });
  }, [
    firstName,
    lastName,
    signingName,
    signDate,
    phoneNumber,
    full_name_sign,
    tenant_first_name,
    tenant_last_name,
    tenant_phone_number,
    tenant_sign_date,
    tenant_signature,
    agree
  ]);

  log.info("noticeData", noticeData,basic_info);

  return signUserType === "tenant" ? (
    <>
      {type === "lastName" && (
        <Row>
          <Form.Group as={Col} md="5">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              name="tenant_first_name"
              id="tenant_first_name"
              value={tenant_first_name}
              maxLength="30"
              onChange={(e) => {
                onChangeHandler(e, setTenantFirstName);
                // createSignImage(
                //   tenant_first_name + " " + tenant_last_name,
                //   "tenant"
                // );
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              name="tenant_last_name"
              id="tenant_last_name"
              value={tenant_last_name}
              maxLength="30"
              onChange={(e) => {
                onChangeHandler(e, setTenantLastName);
                // createSignImage(
                //   tenant_first_name + " " + tenant_last_name,
                //   "tenant"
                // );
              }}
            />
          </Form.Group>
        </Row>
      )}
      <Row>
        <Form.Group as={Col} md="5">
          <Form.Label>Date</Form.Label>
          <DatePickerComponent
            id="tenant_sign_date"
            name="tenant_sign_date"
            format={"dd/MM/yyyy"}
            value={tenant_sign_date ? tenant_sign_date : ""}
            handleDateChange={(date) => setTenantSignDate(date)}
            minDate={new Date()}
            displayText={"Select or Type"}
            popperPlacement="bottom-end"
            disableValue={true}
          />
        </Form.Group>

        <Form.Group as={Col} md="5">
          <Form.Label>Phone Number</Form.Label>
          <NumberFormat
            format="###-###-####"
            mask="_"
            placeholder="123-123-1234"
            name="tenant_phone_number"
            id="tenant_phone_number"
            className={
              phoneError === true ? "form-control is-invalid" : "form-control"
            }
            value={tenant_phone_number ? tenant_phone_number : ""}
            onChange={(e) => {
              setTenantPhoneNumber(e.target.value);
              disableError(false);
            }}
          />
          {phoneError && (
            <div className="invalid-select">phone number required</div>
          )}
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md="5" className="signature">
          <Form.Label>Esignature</Form.Label>
          <br />
          {tenant_signature && (
            <Image src={tenant_signature} alt="Sign Image" />
          )}
        </Form.Group>
      </Row>
      <div className="agree-info d-flex justify-content-start mt-4 mb-4">
        <input
          type="checkbox"
          className="checkit"
          id={"agree"}
          name={"agree"}
          checked={agree || rawData?.agree}
          onChange={() => {
            setAgree(!agree)
            updateRawData({ agree: !agree })
          }}
          hidden
        />
        <label htmlFor={"agree"} className="checkmark"></label>
        <span className="checkbox-title">I have read and agree with the</span>
        <span className="checkbox-subtitle" onClick={redirectToInfo}>
          {TERMS_TEXT[notice_type] || 'Important information from the Landlord and Tenant Board'
          }
        </span>
      </div>
    </>
  ) : (
    <>
      {type === "lastName" ? (
        <Row>
          <Form.Group as={Col} md="5">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              name="firstName"
              id="firstName"
              value={firstName}
              maxLength="30"
              onChange={(e) => {
                onChangeHandler(e, setFirstName);
                // createSignImage(firstName + " " + lastName);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              name="lastName"
              id="lastName"
              value={lastName}
              maxLength="30"
              onChange={(e) => {
                onChangeHandler(e, setLastName);
                // createSignImage(firstName + " " + lastName);
              }}
            />
          </Form.Group>

        </Row>
      ) : (
        <Row>
          <Form.Group as={Col} md="5">
            <Form.Label>Name Of Person Signing</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              name="signingName"
              id="signingName"
              value={signingName}
              maxLength="30"
              onChange={(e) => {
                onFullNameChange(e, setSigningName);
                // createSignImage(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              placeholder="123-123-1234"
              name="phoneNumber"
              id="phoneNumber"
              className={
                phoneError === true ? "form-control is-invalid" : "form-control"
              }
              value={phoneNumber ? phoneNumber : ""}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                disableError(false);
              }}
            />
            {phoneError && (
              <div className="invalid-select">phone number required</div>
            )}
          </Form.Group>
        </Row>
      )}
      <Row>
        <Form.Group as={Col} md="5">
          <Form.Label>Date</Form.Label>
          <DatePickerComponent
            id="signingDate"
            name="signingDate"
            format={"dd/MM/yyyy"}
            value={signDate ? signDate : ""}
            handleDateChange={(date) => setSignDate(date)}
            minDate={new Date()}
            displayText={"Select or Type"}
            popperPlacement="bottom-end"
            disableValue={true}
          />
        </Form.Group>

        {type === "lastName" && <Form.Group as={Col} md="5">
          <Form.Label>Phone Number</Form.Label>
          <NumberFormat
            format="###-###-####"
            mask="_"
            placeholder="123-123-1234"
            name="phoneNumber"
            id="phoneNumber"
            className={
              phoneError === true ? "form-control is-invalid" : "form-control"
            }
            value={phoneNumber ? phoneNumber : ""}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              disableError(false);
            }}
          />
          {phoneError && (
            <div className="invalid-select">phone number required</div>
          )}
        </Form.Group>}
      </Row>

      <Row>
        <Form.Group as={Col} md="5" className="signature">
          <Form.Label>Esignature</Form.Label>
          <br />
          {full_name_sign && <Image src={full_name_sign} alt="Sign Image" />}
        </Form.Group>
      </Row>
      {hideAgreement ? null : (
        <div className="agree-info d-flex justify-content-start mt-4 mb-4">
          <input
            type="checkbox"
            className="checkit"
            id={"agree"}
            name={"agree"}
            checked={agree || rawData?.agree}
            onChange={() => {
              setAgree(!agree)
              updateRawData({ agree: !agree })
            }}
            hidden
          />
          <label htmlFor={"agree"} className="checkmark"></label>
          <span className="checkbox-title">I have read and agree with the</span>
          <span className="checkbox-subtitle" onClick={redirectToInfo}>
            {TERMS_TEXT[notice_type] || 'Important information from the Landlord and Tenant Board'}
          </span>
        </div>
      )}
    </>
  );
};
export default withRouter(SignatureForm);
