import React from 'react';
import { withRouter } from "react-router-dom";
import { Image, Col } from 'react-bootstrap';

const LeaveItToThePros = ({ icon, title, description }) => {
    return (
        <Col md={4} className="leaveItToThePros mb-4 text-center">
            <Image className="leaveItToThePros-icon" src={icon} />
            <div className="leaveItToThePros-title">{title}</div>
            <div className="leaveItToThePros-description">{description}</div>
        </Col>
    )
}

export default withRouter(LeaveItToThePros);
