import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import CheckBox from "../../Checkbox/Checkbox";
import Cookies from 'js-cookie';

const N12DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, cancelNotice } = props;
  const role = Number(Cookies.get('role'));

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Notice to End Tenancy<br />
           Landlord, a purchaser or a family member requires the rental unit (N12)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            Date of termination
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Reason(s) for Ending Tenancy</span>
        </div>

        {noticeData.end_tenancy_reason_1 === 1 &&
          <Row>
            <Form.Group as={Col} md="12">
              <div className="select-radio-wrapper">
                <Form.Check
                  key={"reason1"}
                  type="radio"
                  id={`reason1`}
                  name="reasons"
                  value={"reason1"}
                  checked={noticeData.end_tenancy_reason_1 === 1 && true}
                  disabled
                  label={
                    "Reason 1: The following person intends to move into the rental unit and occupy it for at least one year:"
                  }
                />
                {noticeData.end_tenancy_reason_1 === 1 && (
                  <div className="internal-checkboxes">
                    <div className="check-title">
                      <span>Select all that apply</span>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"Me"}
                          name={"1"}
                          disabled
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("1")}
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Spouse"}
                          name={"2"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("2")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Child"}
                          name={"3"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("3")}
                          disabled
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Parent(s)"}
                          name={"4"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("4")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's child"}
                          name={"5"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("5")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's parent"}
                          name={"6"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("6")}
                          disabled
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          label={
                            "A person who provides or will provide care services to:"
                          }
                          name={"7"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("7")}
                          disabled
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"Me"}
                          name={"8"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("8")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Spouse"}
                          name={"9"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("9")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Child"}
                          name={"10"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("10")}
                          disabled
                        />
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        <CheckBox
                          extraClass="extra-width"
                          label={"My Parent(s)"}
                          name={"11"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("11")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's child"}
                          name={"12"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("12")}
                          disabled
                        />
                        <CheckBox
                          extraClass="extra-width"
                          label={"My spouse's parent"}
                          name={"13"}
                          checked={noticeData.end_tenancy_reason_1_move_into_rental_unit.includes("13")}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form.Group>
          </Row>}
        {noticeData.end_tenancy_reason_2 === 1 && <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>
              Please select one to indicate your reason for ending the tenancy
              </Form.Label>
            <div className="select-radio-wrapper">
              <Form.Check
                key={"reason2"}
                type="radio"
                id={`reason2`}
                name="reasons"
                value={"reason2"}
                checked={noticeData.end_tenancy_reason_2 === 1 && true}
                disabled
                label={
                  "Reason 2: I have signed an Agreement of Purchase and Sale of the rental unit and the following person intends to move into the rental unit:"
                }
              />
              {noticeData.end_tenancy_reason_2 === 1 && (
                <div className="internal-checkboxes">
                  <div className="check-title">
                    <span>Select all that apply</span>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-start mb-3">
                      <CheckBox
                        extraClass="extra-width"
                        label={"Me"}
                        name={"1"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("1")}
                        disabled
                      />
                      <CheckBox
                        extraClass="extra-width"
                        label={"My Spouse"}
                        name={"2"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("2")}
                        disabled
                      />
                      <CheckBox
                        extraClass="extra-width"
                        label={"My Child"}
                        name={"3"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("3")}
                        disabled
                      />
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                      <CheckBox
                        extraClass="extra-width"
                        label={"My Parent(s)"}
                        name={"4"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("4")}
                        disabled
                      />
                      <CheckBox
                        extraClass="extra-width"
                        label={"My spouse's child"}
                        name={"5"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("5")}
                        disabled
                      />
                      <CheckBox
                        extraClass="extra-width"
                        label={"My spouse's parent"}
                        name={"6"}
                        checked={noticeData.end_tenancy_reason_2_move_into_rental_unit?.includes("6")}
                        disabled
                      />
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                      <CheckBox
                        label={
                          "A person who provides or will provide care services to:"
                        }
                        name={"7"}
                        checked={noticeData.end_tenancy_reason_2_purchase_and_sale_of_rental_unit?.includes("7")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form.Group>
        </Row>}
        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />
        <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />

        </div>
      </div>

      <div className="container-footer button-extra-margin">
        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N12DetailsView);
