import React from 'react'
import { Row, Form, Col, Image } from 'react-bootstrap'

const SignLeaseBody = props => {
    const {full_name_sign_Lease, onChangeInput, full_name_sign_LeaseUrl, skipAction} = props;

    return (
        <div className="sidepanel-body">
            <Row>
                <Form.Group as={Col} md="12">
                    <Form.Label style={{ color: "black" }} className="text-capitalize">Type your full name for digital signature</Form.Label>
                    <Form.Control
                        type="text"
                        name="full_name_sign_Lease"
                        id="full_name_sign_Lease"
                        value={full_name_sign_Lease}
                        placeholder="e.g. John Doe"
                        maxLength="30"
                        onChange={(e) => onChangeInput(e)}
                    />
                    <p className="mt-3 disclaimer" style={{ color: "#646464" }}>Disclaimer: this is a simple electronic signature and may not constitute a legally binding agreement between tenant and landlord.
                        Rent Panda recommends that all leases be signed in person, with each party to the agreement obtaining a copy of the fully executed and legally binding lease agreement as per section 17 of the Residential Tenancy Agreement.</p>
                    <p className='skip-link' id='BTN-create-lease-skip-sign' data-description='Landlord skipped digitally signing lease' onClick={() => skipAction()}>Skip (Sign Offline)</p>

                    {full_name_sign_LeaseUrl &&
                        <Form.Group className="mt-3 signature">
                            <Form.Label style={{ color: "black" }}>Esignature</Form.Label>
                            <Image src={full_name_sign_LeaseUrl} alt="Sign Image" />
                        </Form.Group>}
                </Form.Group>
            </Row>
        </div>
    )
}

export default SignLeaseBody
