import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {SingleOptionCheck, CostNumberFormat} from '../../../'
import { rentDeposite } from '../../../../../Constants/LeaseConstants';
import { numberFormatSanitizerInt } from '../../../../../Utils';

const DepositsAndDiscounts = props => {
    const {leaseInfo, OnSingleOptionCheck, onChangeHandler } = props;
    return (
        <>
            <h3 className='text-left lease-heading'>deposits and discounts</h3>
            <Row>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Is there a rent deposit?</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_deposit'} setParam={leaseInfo.is_rent_deposit}
                    />
                </Form.Group>

                {leaseInfo.is_rent_deposit === 1 &&
                    <Form.Group as={Col} md='5'>
                        <Form.Label>Rent Deposit Amount</Form.Label>
                        <CostNumberFormat
                            name={'is_rent_deposit_amount'}
                            id={'is_rent_deposit_amount'}
                            classname='form-control'
                            value={leaseInfo.is_rent_deposit_amount ? leaseInfo.is_rent_deposit_amount : ''}
                            decimalSeparator={'.'}
                            decimalScale={2}
                            updateField={(e) => onChangeHandler(numberFormatSanitizerInt(e.target.value), 'is_rent_deposit_amount')}
                            pre='$'
                            placeholder={'$200'}
                        />
                    </Form.Group>}
            </Row>

            <Row>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Is there a key deposit?</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_key_deposite'} setParam={leaseInfo.is_key_deposite}
                    />
                </Form.Group>

                {leaseInfo.is_key_deposite === 1 &&
                    <Form.Group as={Col} md='5'>
                        <Form.Label>Key Deposit Amount <em>(can only be amount to replace key)</em></Form.Label>
                        <CostNumberFormat
                            name={'key_deposite_amount'}
                            id={'key_deposite_amount'}
                            classname='form-control'
                            value={leaseInfo.key_deposite_amount ? leaseInfo.key_deposite_amount : ''}
                            updateField={(e) => onChangeHandler(numberFormatSanitizerInt(e.target.value), 'key_deposite_amount')}
                            pre='$'
                            placeholder={'$200'}
                            decimalSeparator={'.'}
                            decimalScale={2}
                        />
                    </Form.Group>}
            </Row>

            <Row>
                <Form.Group as={Col} md='3'>
                    <Form.Label>Is there a rent discount</Form.Label>
                    <SingleOptionCheck
                        mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_discounted'} setParam={leaseInfo.is_rent_discounted}
                    />
                </Form.Group>
                {leaseInfo.is_rent_discounted === 1 &&
                    <>
                        <Form.Group as={Col} md='5'>
                            <Form.Label>Amount</Form.Label>
                            <CostNumberFormat
                                name={'rent_discount_amount'}
                                id={'rent_discount_amount'}
                                classname='form-control'
                                value={leaseInfo.rent_discount_amount ? leaseInfo.rent_discount_amount : ''}
                                updateField={(e) => onChangeHandler(numberFormatSanitizerInt(e.target.value), 'rent_discount_amount')}
                                pre='$'
                                placeholder={'$200'}
                                decimalSeparator={'.'}
                                decimalScale={2}
                            />

                            <Form.Label className="mt-3">Rent Discount Description</Form.Label>
                            <Form.Control
                                type='text'
                                as='textarea'
                                rows={5}
                                name='rent_discount_description'
                                id='rent_discount_description'
                                value={leaseInfo.rent_discount_description}
                                placeholder='Write description here...'
                                onChange={(e) => onChangeHandler(e.target.value, 'rent_discount_description')}
                            />
                        </Form.Group>
                    </>
                }
            </Row>
        </>
    )
}

export default DepositsAndDiscounts
