import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from "react-switch";
import { getAPI, postAPI } from '../../../../Api';
import { logger } from '../../../../Utils';
import { ModalBox } from '../../../components';
import DetailStatusLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/DetailStatusLoader.js/DetailStatusLoader';

const log = logger("ApprovalStatus");
const ApprovalStatus = (props) => {
    const { isLoading, apiCallback, openDeactivateList } = props;
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);

    const [openSwitchModal, setOpenSwitchModal] = useState(false);
    const [openSwitchVerified, setOpenSwitchVerified] = useState(false);
    const [openSwitchActivate, setOpenSwitchActivate] = useState(false);
    const [checkedApproved, setCheckedApproved] = useState(false);
    const [property_details_id, setProperty_details_id] = useState("");

    const handleChangeApproved = () => {
        setCheckedApproved(!checkedApproved);
        toggleModal();
    }
    const [checkedNotVerified, setCheckedNotVerified] = useState(false);
    const handleChangeNotVerified = () => {
        setCheckedNotVerified(!checkedNotVerified)
        toggleModalVerified();
    }
    const [checkedListedStatus, setCheckedListedStatus] = useState(null);
    const handleChangeListedStatus = () => {
        setCheckedListedStatus(!checkedListedStatus)
        if (checkedListedStatus === true) {
            openDeactivateList(false)
        } else {
            setOpenSwitchActivate(true)
        }
    }

    const toggleModal = () => {
        setCheckedApproved(!checkedApproved);
        setOpenSwitchModal(!openSwitchModal);
    }
    const goBack = () => {
        let status_id = "";
        if (checkedApproved === true) {
            status_id = 1;
        } else {
            status_id = 3;
        }
        let url = '/admin/listing/approval/' + property_details_id + '/' + status_id;
        getAPI(url).then(res => {
            if (res.status === 200) {
                toggleModal()
                apiCallback();
            }
        })
    }
    const toggleModalVerified = () => {
        setOpenSwitchVerified(!openSwitchVerified);
        setCheckedNotVerified(!checkedNotVerified)
    }
    const goBackVerified = () => {
        let status_id = "";
        if (checkedNotVerified === true) {
            status_id = 1;
        } else {
            status_id = 0;
        }
        let url = '/admin/listing/verification/' + property_details_id + '/' + status_id;
        getAPI(url).then(res => {
            if (res.status === 200) {
                toggleModalVerified()
                apiCallback();
            }
        })
    }
    const handleCloseActive = () => {
        setOpenSwitchActivate(false);
        setCheckedListedStatus(!checkedListedStatus)
    }
    const goBackActivate = () => {
        let url = '/admin/listing/active/' + property_details_id;
        postAPI(url).then(res => {
            if (res.status === 200) {
                setOpenSwitchActivate(false);
                apiCallback();
            }
        })
    }
    useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_details_id]);
    useEffect(() => {
        if ((unitDetails && unitDetails.is_verified) === "verified") {
            log.info(unitDetails.is_verified)
            setCheckedNotVerified(true)
        } else {
            setCheckedNotVerified(false)
        }
        if ( unitDetails && (unitDetails.formatted_status === "approved" || unitDetails.formatted_status === "rented") ) {
            setCheckedApproved(true)
        } else {
            setCheckedApproved(false)
        }
        if ((unitDetails && unitDetails.is_active) === 1) {
            setCheckedListedStatus(true)
        } else {
            setCheckedListedStatus(false)
        }
    }, [unitDetails]);

    return (
        isLoading === true ? <DetailStatusLoader /> :
            <>
                <div className="inner-wrapper">
                    <Row>
                        <Col md={8}>
                            <h6>Approval Status</h6>
                            {unitDetails && <h4 className="switch-heading">listing {unitDetails.formatted_status === 'approved' || unitDetails.formatted_status === 'rented' || unitDetails.formatted_status === 'for rent' ? 'approved' : 'not approved'} </h4>}
                        </Col>
                        <Col md={4} className="text-right">
                            <Switch
                                className="react-switch"
                                onChange={() => handleChangeApproved()}
                                checked={checkedApproved}
                                height={48}
                                width={87}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                disabled={(unitDetails && (unitDetails.formatted_status === "pending approval" || unitDetails.formatted_status === "approved" || unitDetails.formatted_status === "rented")) ? false : true}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="inner-wrapper">
                    <Row>
                        <Col md={8}>
                            <h6>Verification Status</h6>
                            <h4 className="switch-heading">{
                                unitDetails && unitDetails.is_verified !== "verified" ? "listing not verified" : "listing verified"
                            }</h4>
                        </Col>
                        <Col md={4} className="text-right">
                            <Switch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className="react-switch"
                                onChange={() => handleChangeNotVerified()}
                                checked={checkedNotVerified} height={48} width={87} />
                        </Col>
                    </Row>
                </div>
                <div className="inner-wrapper">
                    <Row>
                        <Col md={8}>
                            <h6>Listed Status</h6>
                            <h4 className="switch-heading">listing {unitDetails && unitDetails.is_active === 1 ? "active" : "inactive"}</h4>
                        </Col>
                        <Col md={4} className="text-right">
                            <Switch
                                uncheckedIcon={false}
                                disabled={(unitDetails && (unitDetails.formatted_status === "approved" || unitDetails.formatted_status === "rented")) ? false : true}
                                checkedIcon={false}
                                className="react-switch"
                                onChange={() => handleChangeListedStatus()}
                                checked={checkedListedStatus === null ? false : checkedListedStatus} height={48} width={87} />
                        </Col>
                    </Row>
                </div>
                <ModalBox
                    show={openSwitchModal}
                    onHide={toggleModal}
                    size="sm"
                    actionbuttontitle={checkedApproved === true ? "confirm approval" : "confirm unapproval"}
                    buttonaction={goBack}
                    extraClassUpper={'approval-docs'}
                >
                    {checkedApproved === true ?
                        <p className="switching-details pl-5 pr-5">By approving this property, you are allowing the landlord to list the property publically</p> :
                        <p className="switching-details pl-5 pr-5">By unapproving this listing it will also be hidden from the public.</p>}
                </ModalBox>
                <ModalBox
                    show={openSwitchVerified}
                    onHide={toggleModalVerified}
                    size="sm"
                    actionbuttontitle={"yes, change status"}
                    buttonaction={goBackVerified}
                    extraClassUpper={'verified'}
                >
                    {checkedNotVerified === true ?
                        <p className="switching-details">You are changing the status of the listing <span>{propertyDetails && propertyDetails.address}</span> to verified. Continue?</p> :
                        <p className="switching-details">You are changing the status of the listing <span>{propertyDetails && propertyDetails.address}</span> to unverified. Continue?</p>}
                </ModalBox>
                <ModalBox
                    show={openSwitchActivate}
                    closeButtonHeader={false}
                    size="sm"
                    type="condition"
                    actionbuttontitle={"Yes"}
                    buttonaction={() => goBackActivate()}
                    backbuttontitle={'Cancel'}
                    backaction={() => handleCloseActive()}
                    extraClassUpper={'activate-deactivate'}
                >
                    <p className="switching-details">Are you sure you want to activate this listing for the landlord? </p>
                </ModalBox>
            </>
    )
}

export default withRouter(ApprovalStatus);