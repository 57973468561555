import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { logger, toaster } from "../../../../Utils";
import { SidePanel, ModalBox, CancelButton, LargeButton } from "../../";
import { getAPI, postAPI } from "../../../../Api";
import { useDispatch } from "react-redux";
import { setIsLeaseFromTenantApplication } from "../../../../Store/actions/MessageDetailAction";


import '../style.scss';

const log = logger("DeactivationOptions");
const DeactivationOptions = (props) => {
    const { isShow, handleClose, setLeaseOptionsScreen, getPropertyDetails } = props;

    const dispatch = useDispatch();
    const [property_id, setProperty_id] = useState("");
    const [property_details_id, setProperty_details_id] = useState("");
    const [deactivateReason, setDeactivateReason] = useState(null);
    const [is_deactivateConfirmationModel, setDeactivateConfirmationModel] = useState(false);
    const [is_leaseConfirmationModel, setLeaseConfirmationModel] = useState(false);
    const [reason_id, setReasonId] = useState(null);
    const [deactiveSuccessModalShow, setDeactiveSuccessModalShow] = useState(false);
    const [description, setDescription] = useState('');

    const OnChangeInput = (event) => {
        setDescription(event.target.value);
        props.setDeactivateDescription(event.target.value);
    }

    // log.info("reason_id", reason_id);
    
    const OnDeactivateReason = (reasonId) => {
        setReasonId(reasonId);
        onActionDeactivate(reasonId);
        props.setDeactivateReason(reasonId);
    }
    
    const onActionDeactivate = (reasonId) => {        
        if (reasonId === 1 || reasonId === 3) {
            handleClose();
            setDeactivateConfirmationModel(true);
        }else if (reasonId === 6) {
            log.info("other option clicked");
        } else if (reasonId === 2) {
            handleClose();
            dispatch(setIsLeaseFromTenantApplication(false));
            props.setIsDeactivation(true);
            props.setSearchTenant(true);
            props.setLeaseBackStep(1);
        } else if (reasonId === 4 || reasonId === 5) {
            handleClose();
            dispatch(setIsLeaseFromTenantApplication(false));
            props.setIsDeactivation(true);
            props.setAddTenant(true);
        } else {
            handleClose();
            setLeaseConfirmationModel(true);
        }
    }

    const onDeactiveSubmit = () => {
        const url = `/landlord/listing/deactive/${property_details_id}`;
        const data = {
            "deactivate_reason": reason_id,
            "deactivate_description": description
        };
        // if(reason_id === 6 && !description){
        //     setDescriptionError(true)
        // }
        // if(!descriptionError){
        postAPI(url, data).then((response) => {
            if(response.status !== 200){
                toaster('error', response.response.data.message)
            }else if (response.status === 200) {
                // const res = response.data.data;
                handleClose();
                setDeactivateConfirmationModel(false);
                setLeaseConfirmationModel(false);
                setDeactiveSuccessModalShow(true);
                getPropertyDetails();
            }
        });
        // }
    }

    // Get Deactivate lisitng Options
    useEffect(() => {
        const url = '/landlord/listing/get-deactive-reasons';
        getAPI(url).then(response => {
            const res = response.data.data;
            setDeactivateReason(res)
        })
            .catch(error => {
            });
    }, [])

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id]);

    log.info("deactivateReason", deactivateReason);

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose()}
            title="Deactivate Listing"
            subTitle="Why are you deactivating this listing?">
            <div className="deactive-reasons sidepanel-body">
                {deactivateReason && deactivateReason.map((reason, i) => {
                    return (<Row className="p-4 border-reason cursor-pointer" key={'reason_'+i} onClick={() => OnDeactivateReason(reason.id)}>
                        <Col className='pl-0 pr-0 change-heading d-flex justify-content-between' md={12}>
                            {reason.deactive_reason}
                            <img className="next-arrow" src={require('../../../../_assets/images/next.svg')} alt="next" />
                        </Col>

                        {(reason.id === 6 && reason_id === 6) &&
                            <>
                                <Form.Control
                                    as="textarea"
                                    rows="4"
                                    className="deactive-reason mt-2"
                                    placeholder="We would love to know why you want to deactivate your listing"
                                    name="description"
                                    id="description"
                                    value={description}
                                    onChange={(e) => OnChangeInput(e)} />
                            </>
                        }
                    </Row>)
                })}
            </div>

            
            {(reason_id === 6) &&
            <div className={'fixed-bottom d-flex justify-content-between'}>
                <CancelButton onClick={() => handleClose()} title='Back' />
                <LargeButton onClick={() => {
                    handleClose();
                    setDeactivateConfirmationModel(true);
                }} title={'next'} style={{ width: '200px' }} />
            </div>}
        </SidePanel>

        <ModalBox
            show={is_deactivateConfirmationModel}
            onHide={() => setDeactivateConfirmationModel(false)}
            size="sm"
            type="condition"
            actionbuttontitle={'No'}
            buttonaction={() => {
                setDeactivateConfirmationModel(false)
            }}
            backbuttontitle={'Yes'}
            backaction={() => {
                onDeactiveSubmit()
            }}
        >
            <h4>are you sure you want to deactivate this listing?</h4>
            <p className="popText">it will taken off of rent panda, and go to your “deactivated" page</p>
        </ModalBox>

        <ModalBox
            show={deactiveSuccessModalShow}
            onHide={() => {
                setDeactiveSuccessModalShow(false);
            }}
            size="md"
            actionbuttontitle={"got it"}
            buttonaction={() => {
                setDeactiveSuccessModalShow(false);
            }}
        >
            <h4>you have deactivated this listing</h4>
        </ModalBox>

        <ModalBox
            show={is_leaseConfirmationModel}
            onHide={() => setLeaseConfirmationModel(false)}
            size="sm"
            type="condition"
            actionbuttontitle={'Yes'}
            buttonaction={() => {
                onDeactiveSubmit()
            }}
            backbuttontitle={'No'}
            backaction={() => {
                setLeaseConfirmationModel(false);
                setLeaseOptionsScreen(true);
            }}
        >
            <h4>are you sure you want to skip and deactivate listing?</h4>
            <p>you won’t get access to the management tool where you can collect rent, serve notices, record repairs and more!</p>
        </ModalBox>
    </>);
}
export default withRouter(DeactivationOptions);