import React from 'react'

import { useHistory } from 'react-router-dom'

import ModalBox from '../ModalBox'


function CompleteProfileModal({setShow, show,redirect}) {

    const history=useHistory()

    return (

        <div>

            <ModalBox

			show={show}

			onHide={() => setShow(false)}

			size={"sm"}

			type="condition"

			actionbuttontitle={'Yes'}

			buttonaction={async () => {

				history.push({

					pathname: `/profile`,

					state: {

						redirect: redirect,

					}

				})

			}}

			backbuttontitle={'No'}

			backaction={() => {

				setShow(false)

			}}

			customActionButtonClass='height-large-only'

		>

			<h4> your profile is incomplete </h4>

			<p>  In order to request a showing <br />

				your profile must be

				<br />

				complete. Would you like to

				complete your profile now?</p>


		</ModalBox>


        </div>

    )

}


export default CompleteProfileModal