import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentLoader from 'react-content-loader';

const UserDetailContentLoader = (props) => {
	return (
		<Row className={props.section ? 'container-detail admin-section' : "container-detail admin"}>
			<Col md="12" className="card-detail">
				<div className="detail-left">
					<span className="user">
						<ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
							<rect x="0" y="0" rx="0" ry="0" width="20%" height="15" />
						</ContentLoader>
					</span>
					<span className="user-name">
						<ContentLoader height={30} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
							<rect x="0" y="0" rx="0" ry="0" width="40%" height="30" />
						</ContentLoader>
					</span>
					<span className="user-contact-title">
						<span className="title-text">
							<ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
								<rect x="0" y="0" rx="0" ry="0" width="60%" height="15" />
							</ContentLoader>
						</span>
					</span>
				</div>
				<div className="detail-right">
					<ContentLoader height={50} style={{ width: '100%' }} speed={2} foregroundColor={"rgb(0 0 0 / 36%)"}>
						<rect x="170" y="0" rx="20" ry="20" width="50%" height="50" />
					</ContentLoader>
				</div>
			</Col>
		</Row>
	)
}
export default UserDetailContentLoader;