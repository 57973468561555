import React, { useState, useEffect } from "react";
import { Col, Image } from "react-bootstrap";
import { LargeButton, CancelButton, SidePanel, Loader } from "../../../components";
import { getAPI, postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { logger, toaster } from "../../../../Utils";

const log = logger("FinalCreateLease");
const FinalCreateLease = (props) => {
	const { isShow, handleClose, openConfirmation } = props;
	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');
	const [lease_id, setLeaseId] = useState('');
	const [pdfData, setPdfData] = useState(null);
	const [disableBtn, setBtnDisable] = useState(false);

	// const reasonDeactivate = useSelector(state => state.deactiveReasonReducer.deactiveReason);
	const handleCloseConfirm = () => {
		handleClose();
		// openSignLease(true);
	}
	const onDownload = () => {
		// handleClose();
		// props.openPDFView(pdfData);
		window.open(pdfData && pdfData.lease_pdf_url);
	}
	const finalCreate = () => {
		setBtnDisable(true)
		const url = "/admin/listing/lease/create-lease";
		const data = {
			"lease_id": lease_id,
			"property_id": property_id,
			"property_detail_id": property_details_id,
		}
		postAPI(url, data).then(res => {
			setBtnDisable(false)
			if (res.status === 200) {
				// const url = "/admin/listing/deactive/" + property_details_id;
				// const data = {
				//     "deactivate_reason": reasonDeactivate && reasonDeactivate.order,
				//     "deactivate_description": reasonDeactivate && reasonDeactivate.deactive_reason,
				//     'is_active': 1
				// }
				// postAPI(url, data).then(res => {
				//     if (res.status === 200) {
				//         handleClose();
				//         openConfirmation(true)
				//     }
				// })
				handleClose();
				openConfirmation(true)
			} else if (res.response.status === 400) {
				log.info(res.response)
				toaster('error', res.response.data.message);
			} else if (res.response.status === 422) {
				toaster('error', res.response.data.message);
			}
		})
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		setLeaseId(props.match.params.lease_id);
		if (props.match.params.property_details_id && props.match.params.lease_id) {
			let url = '';
			url = `/admin/listing/lease/view-appendix/${props.match.params.property_details_id}/${props.match.params.lease_id}`;

			getAPI(url).then(res => {
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setPdfData(fetchedData);
					log.info('resp', fetchedData);
				}
			})
		}
	}, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);
	return (<div className='sign-lease-comp'>
		<SidePanel
			isShow={isShow}
			isHide={handleClose}
			title="CREATE A LEASE"
			subTitle={'Download The Lease'}
		>
			<div className="sidepanel-body lease-pdf-view">
				{pdfData ?
					<Col className='confirm-section mt-3 d-flex justify-content-start'>
						<Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
						<div className='file-details'>
							{pdfData.lease_pdf_url.split('/').pop()}<br />
							<Image className="icon" src={require("../../../../_assets/images/download.svg")} alt="Download" />
							<span className='file-details-link' onClick={() => onDownload()}>Download</span>
						</div>
					</Col> : <Loader />}
				<p className="mt-3 disclaimer">Disclaimer: Please ensure that you and your tenant have signed the lease (digitally or in person) and both of you keep a digital and hard copy of the lease for your records.
                        If you have signed the lease offline, ensure you provide your tenant with a copy of the signed lease within 21 days of signing.</p>
			</div>
			<div className="fixed-bottom d-flex justify-content-between">
				<CancelButton onClick={() => handleCloseConfirm()} title='back' style={{ fontWeight: 'bold' }} />
				<LargeButton saveLoading={disableBtn} title='finish' onClick={() => finalCreate()} style={{ width: '200px' }} />
			</div>
		</SidePanel>
	</div>);
}
export default withRouter(FinalCreateLease);