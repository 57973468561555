import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { logger } from "../../../Utils";
import { postAPI } from "../../../Api";
import { Loader, ModalBox, PDFViewerComponent } from '../../../App/components';
import Layout from '../../components/Layout';

import '../style.scss';

const log = logger("FormsResources");
const FormsResources = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const handleFormClick = (form_url) => {
        setShowLoader(true);
        setPdfUrl(form_url);
        setPdfModal(true);
        setShowLoader(false);
    }
    
    const [formsList, setFormsList] = useState([]);
    useEffect(() => {
        setShowLoader(true);
        postAPI(`/cms/forms/list`).then(response => {
            if (response.status === 200) {
                setFormsList(response.data.data)
                setShowLoader(false);
            }
            log.info("/cms/forms/list > response ", response.data.message);
        })
    }, []);

    return (
        <div className="FormsResourcesPage">
            <Layout newHeader={true}>
                <div className="FormsResourcesPage-header">
                    <Container>
                        <div className="main-heading text-center">Landlord Forms and Information</div>
                        <div className="sub-heading text-center">All the forms you’ll ever need as a landlord.</div>
                    </Container>
                </div>

                <div className="FormsResourcesPage-contentBox">
                    <Container>
                        <Row>

                            {(formsList && formsList.length > 0) ? formsList.map((form, i) => {
                                return <Col key={'article' + i} md={4} className="grid mb-4">
                                    <div className="resources-box">
                                        <div className="resources-box-image">
                                            {/* <Image className="grid-img" src={form.thumbnail_url ? form.thumbnail_url : require("../../../_assets/images/placeholder.jpg")} alt="grid-img" /> */}
                                            <LazyLoadImage
                                            effect="blur"
                                            className="grid-img"
                                            src={form.thumbnail_url ? form.thumbnail_url : require("../../../_assets/images/placeholder.jpg")}
                                            alt="grid-img"
                                            />
                                        </div>                                        
                                        <h4 className="grid-name">{form.title}</h4>
                                        <p class="grid-content">{form.description.replace(/<[^>]*>?/gm, '')}</p>
                                        
                                        <div className="mt-auto">
                                            {form.form_url && <Button variant="link" className="date-text" onClick={() => handleFormClick(form.form_url)}>Read more 
                                                <Image
                                                    className="pl-2 next"
                                                    src={require("../../../_assets/images/arrow-forward.svg")}
                                                    alt='View More'
                                                />
                                                {showLoader && <i className='fa fa-spinner fa-spin ml-2'></i>}
                                            </Button>}
                                        </div>
                                        
                                    </div>

                                </Col>
                            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>}

                        </Row>
                    </Container>
                </div>
            </Layout>

            <ModalBox
                show={pdfModal}
                onHide={() => setPdfModal(false)}
                size="xl"
                className="cu-full-modal"
            >
                <PDFViewerComponent PDFUrl={pdfUrl && pdfUrl} />
            </ModalBox>
        </div>
    )
}

export default withRouter(FormsResources)
