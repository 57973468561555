import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAPI, postAPI } from '../../../../Api';
import { getNoticeType } from '../../../../Store/actions/Action';
import { logger } from '../../../../Utils';
import { multiCheckbox } from '../../../../Utils/MultiCheckbox/MultiCheckbox';
import { useSelector } from 'react-redux'
import { TableLandlord } from '../../../components';
import UserManagementTable from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/UserManagementTable';
import '../style.scss';
import { updateRawData } from '../../../../Store/actions/RawDataAction'
// import store from '../../../../configureStore';

const log = logger('Notices');
const Notices = (props) => {
    const { user_id, role } = props;
    const {createdAt='', selectedNoticeType=[], searchLocation=''} = useSelector((state) => state.rawData)
    const [tableData, setTableList] = useState(null);
    const [path] = useState('');
    const [isLoading, setIsLoding] = useState(true);
    const [status, sortNoticeStatus] = useState(['all']);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user_id !== '' || user_id !== undefined) {
            let urlInside = `/admin/notice/list`;
            const data = {
                "types": selectedNoticeType,
                "status": [`${status}`], //2 for pending, 3 for Completed,
                "created": createdAt, //asc, desc,
                "search_term": searchLocation,
                "user_id": user_id
            }
            postAPI(urlInside, data).then(response => {
                if (response.status === 200) {
                    setIsLoding(false);
                    const res = response.data.data;
                    setTableList(res)
                }
            })
        }

        getAPI(`/admin/notice/notice-types`).then(response => {
            if (response.status === 200) {
                log.info('notice-types', response.data.data);
                let newArr = response.data.data;
                const data = {
                    id: 'all',
                    title: "View All"
                }
                newArr.push(data)
                dispatch(getNoticeType(newArr))
                log.info('notice-types newArr', newArr);
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id, searchLocation, createdAt, selectedNoticeType?.length, status])

    let userColumns = [];


    userColumns = [
        {
            header: 'ADDRESS',
            width: '35%'
        },
        {
            header: 'TYPE',
            width: '15%'
        },
        {
            header: 'CREATED',
            width: '15%'
        },
        {
            header: 'DOCS',
            width: '20%'
        },
        {
            header: 'STATUS',
            width: '20%'
        }
    ];
    const filterData = {
        "types": selectedNoticeType,
        "status": [`${status}`],
        "created": createdAt, //asc, desc,
        "search_term": searchLocation,
        "user_id": user_id
    }
    let url = '/admin/notice/list';

    const insertInStore = (key, value) => updateRawData({ [key]: value })
    const sortCreatedNotice = (value) => insertInStore('createdAt', value)
    const setSearch = (value) => insertInStore('searchLocation', value)
    const setType = (value) => insertInStore('selectedNoticeType', value)
    // const sortStatus = (value) => insertInStore('status', value)



    const getData = (fetchedUrl) => {
         if (user_id !== '') {
            postAPI(fetchedUrl ? fetchedUrl : url, filterData)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoding(false);
                        const res = response.data.data;
                        setTableList(res)
                    }
                })
        }
    }

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        let urlNext = `/admin/notice/list?page=${nextPage}`;
        getData(urlNext);
    }
    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        let urlPrev = `/admin/notice/list?page=${prevPage}`;
        getData(urlPrev);
    }
    const goToProfileDetail = (userData) => {
        props.history.push({ pathname: `/notice-details/${role}/${userData.id}/${user_id}`, state: { path: path } })
    }
    const onValueChange = (event, data) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let fetchedFilter = multiCheckbox(status, item, isChecked);
        setType(fetchedFilter)
        if (user_id !== '') {
            const filterDataInside = {
                "types": fetchedFilter,
                "status": status,
                "created": createdAt, //asc, desc,
                "search_term": searchLocation,
                "user_id": user_id
            }
            postAPI(url, filterDataInside)
                .then(response => {
                    log.info('get user', response);
                    if (response.status === 200) {
                        const res = response.data.data;
                        setTableList(res)
                    }
                })
        }
    }
    // const sortNoticeStatus = (event, data) => {
    //     const item = event.target.name;
    //     const isChecked = event.target.checked;
    //     let fetchedFilter = multiCheckbox(status, item, isChecked);
    //     sortStatus(fetchedFilter)
    //     if (user_id !== '') {
    //         const filterDataInside = {
    //             "types": selectedNoticeType,
    //             "status": fetchedFilter,
    //             "created": createdAt, //asc, desc,
    //             "search_term": searchLocation,
    //             "user_id": user_id
    //         }
    //         postAPI(url, filterDataInside)
    //             .then(response => {
    //                 log.info('get user', response);
    //                 if (response.status === 200) {
    //                     const res = response.data.data;
    //                     setTableList(res)
    //                 }
    //             })
    //     }
    // }
 
    return (
        isLoading === true ? <UserManagementTable columns={userColumns} component={'lease'} /> :
            <>
                {tableData ?
                    <TableLandlord
                        columns={userColumns}
                        tableData={tableData.data}
                        to={tableData.to}
                        from={tableData.from}
                        current_page={tableData.current_page}
                        last_page={tableData.last_page}
                        per_page={tableData.per_page}
                        total={tableData.total}
                        goToProfileDetail={(data) => goToProfileDetail(data)}
                        clickNext={() => onClickNext(tableData.current_page)}
                        clickPrev={() => onClickPrev(tableData.current_page)}
                        setSearch={(data) => setSearch(data)}
                        searchLocation={searchLocation}
                        selectedNoticeType={selectedNoticeType}
                        sortByNoticeType={(data, elm) => onValueChange(data, elm)}
                        sortCreatedNotice={(data) => sortCreatedNotice(data)}
                        sortNoticeStatus={(data, elm) => sortNoticeStatus(data, elm)}
                        status={status}
                        component='notices'
                    />
                    
                    : ''}
            </>
    );
}

export default withRouter(Notices);
