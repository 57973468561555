import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LargeButton, SidePanel, CancelButton } from '../../components';
import { withRouter } from 'react-router-dom';
import './style.scss';
import SearchInput from '../ProfileDetail/ListUsableComponent/SearchInput';
import { logger, toaster } from '../../../Utils';
import { getAPI, postAPI } from '../../../Api';

const log = logger('AssignedRequestPanel');
const AssignedRequestPanel = (props) => {
    const { isShow, handleClose, setMarkCompleted, clickedData } = props;
    const [searchLocation, setSearch] = useState(null);
    const [serachData, setSearchData] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedData, setSelectedData] = useState([]);
    const [adminList, setAdminList] = useState([]);

    const onValueChange = (event, data) => {
        setSelectedOption(event.target.value);
        setSelectedData(data);
    }

    const handleCloseConfirm = (e) => {
        handleClose();
        setMarkCompleted(true);
    }
    const confirm = () => {
        if (selectedData !== null) {
            let url = `/admin/requests/assign`;
            const data = {
                "request_type_id": clickedData.request_type_id,
                "id": clickedData.id,
                "assigned_to": selectedData.id
            }
            postAPI(url, data).then(response => {
                if (response.status === 200) {
                    handleClose();
                    setMarkCompleted(true);
                    toaster('success', 'Admin has been asiggned')
                }
            })
        } else {
            toaster('error', 'Please select one admin')
        }
    }
    const customSearch = (e) => {
        setSearch(e);
        const excludeColumns = [
            "id",
            "email"
        ];
        var updatedList = serachData;
        const lowercasedFilter = e.toLowerCase().trim();
        const filteredData = updatedList.filter((item) => {
            return Object.keys(item).some((key) =>
                excludeColumns.includes(key)
                    ? false
                    : item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        log.info("filteredData", filteredData);
        setAdminList(filteredData)
    }
    useEffect(() => {
        if (isShow === true) {
            let url = `/admin/requests/admin-list`;
            getAPI(url).then(response => {
                log.info(response.data.data)
                setAdminList(response.data.data);
                setSearchData(response.data.data);
            })
        }
    }, [isShow])
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title='Select Admin'
            subTitle={'Select a admin to assign professional photo shoot'}
        >
            <div className="deactive-reasons mt-3 sidepanel-body mark_completed">
                <SearchInput extraCalss={'mb-2'} searchLocation={searchLocation} placeHolder={"Search Name"} setSearch={(data) => customSearch(data)} />
                {adminList && adminList.map((data, i) => {
                    return <Row className="p-3 border-reason custom-checkbox-styling" key={"tenant" + i}>
                        <Col md={12} className="pl-0 pr-0">
                            <div className="form-check">
                                <input className="form-check-input" type="radio"
                                    name="exampleRadios"
                                    id={"tenantName" + i}
                                    value={data.name}
                                    checked={selectedOption === data.name}
                                    onChange={(e) => onValueChange(e, data)}
                                />
                                <label className="form-check-label" htmlFor={"tenantName" + i}>
                                    {data.name}
                                </label>
                            </div>
                        </Col>
                    </Row>
                })}
                {(adminList && adminList.length === 0) && <h4 className="edit-sub text-center mt-5">There is no admin found</h4>}
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                <LargeButton title='Confirm' onClick={() => confirm()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(AssignedRequestPanel);