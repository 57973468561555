import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Image, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Cookies from "js-cookie";
import RenderCardImg from "./RenderCardImg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ModalBox } from "../../components";
import "./style.scss";
import { setMyFavourite } from "./helper";
import { toaster } from "../../../Utils";
import ButtonWithNo from "../Buttons/ButtonWithNo/ButtonWithNo";
import { Link } from 'react-router-dom';

const PropertyCard = (props) => {
  const {
    onFavouriteChange = () => {},
    selectedId,
    id,
    originalId,
    type,
    grid,
    inquiries,
    onClickInquiries,
    viewCount,
    cardType,
    approval,
    isDeactivated,
    hideVerifedStatus,
    is_verified,
    image_url,
    title,
    // hide_exact_address,
    details,
    price,
    isLoveIcon,
    onClick,
    onClickRepairs,
    onClickNotice,
    isDeleteIcon,
    onDeleteAction,
    notification,
    new_message,
    new_tenancy_application,
    new_viewing_request,
    onVerifyAction,
    landlord_name,
    tenant_name,
    isFavListPage,
    slug,
    isSearching,
    utilities,
  } = props;

  const role =
    useSelector((state) => state.auth.role) || parseInt(Cookies.get("role"));
  const [isFavorite, setIsFavorite] = useState(isLoveIcon);
  const tokenCookie = Cookies.get("access_token");
  const isLoggedIn = tokenCookie;
  const history = useHistory();
  const [confirmAction, setConfirmAction] = useState(false);
  // let titiletoArr = title.split(',');
  // titiletoArr.splice(-2, 2)
  // let formatedTitled = hide_exact_address === 0 && role !== 1 ? titiletoArr.join(',').substr(0, 125) : title;

  const setIsFavouriteHandler = async (id) => {
    // debugger
    if (role === 2 || !isLoggedIn) {
      setConfirmAction(true);
      return;
    }
    setIsFavorite(!isFavorite);
    const { status, message } = await setMyFavourite(id);
    if (status === 200) {
      if (isFavListPage) onFavouriteChange(!isFavorite, id);
      toaster("success", message);
    } else {
      if (isFavListPage) onFavouriteChange(!isFavorite, id);
      setIsFavorite(false);
      toaster("error", message);
    }
  };

  const getSignedUp = () => {
    setConfirmAction(false);
    history.push("/signup/tenant");
  };
  const utilitiesType = {
    1: 'Heat',
    2: 'Hydro',
    3: 'Water',
    4 : 'None'
  }
  let finalUtilities = ''
  if(utilities?.length){
    utilities.forEach((util)=>{
      finalUtilities += utilitiesType[util] + ', '
    })
  }else{
    finalUtilities = 'None'
  }
  if(finalUtilities[finalUtilities.length - 2] === ','){
    finalUtilities = finalUtilities.slice(0, finalUtilities.length - 2)
  }
  const { price_per_month } = props;
  const priceText = price_per_month && `$${price_per_month}`;
  return (
    <>
      <Col
        className="mb-4"
        id={"property_card_" + id}
        xl={grid ? grid : 4}
        lg={6}
        sm={12}
        xs={12}
        // style={{
        //   padding: type !== "list" ? "5px" : "5px 0"
        // }}
      >
        <Card
          className={
            type === "list"
              ? "display-card list"
              : selectedId === id
              ? "display-card selected-card"
              : "display-card"
          }
        >
          <div className="card-header-wrapper">
            {isSearching != null && isSearching == true && slug != null && slug != '' && id != null && originalId != null ?
              <Link to={'/for-rent' + slug + '/' + originalId + '/' + id + '/details'} onClick={ (event) => event.preventDefault() }>
                <RenderCardImg src={image_url} onClick={onClick} />
              </Link>
            : <RenderCardImg src={image_url} onClick={onClick} /> }

            <div className="top-details-wrapper">              

              {role === 2 && viewCount && viewCount > 0 ? (
                <div className="views-wrapper" style={{marginLeft: (inquiries && inquiries > 0) ? '': 'auto' }}>
                  <Image
                    className="icon"
                    src={require("../../../_assets/images/white-eye-in-black-circle-icon.svg")}
                    alt="view count"
                  />
                  <NumberFormat
                    className="count"
                    value={viewCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              ) : (
                ""
              )}

              {role === 2 && inquiries && inquiries > 0 ? (
                <div className="inquiries-wrapper" onClick={onClickInquiries}>
                  <span className="count">{inquiries}</span>new inquiries
                </div>
              ) : (
                ""
              )}

              {price && type !== "list" && (
                <div >
                  {/* <span>
                    <NumberFormat
                      value={price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />{" "}
                    / month
                  </span> */}
                </div>
              )}

              {isLoveIcon !== undefined && type !== "list" && (
                <Image
                  className="icon"
                  width="22px"
                  height="20px"
                  src={
                    isFavListPage
                      ? require("../../../_assets/images/love-icon.svg")
                      : isFavorite
                      ? require("../../../_assets/images/love-icon.svg")
                      : require("../../../_assets/images/love-icon-white-hollow.svg")
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsFavouriteHandler(id)}
                  id="BTN-favourite-listing"
                  data-description="Tenant chose to favourite a listing"
                />
              )}
            </div>
            {approval ? (
              approval !== "approved" &&
              approval !== "rented" && (
                <div
                  className={
                    approval === "in progress"
                      ? "approval-wrapper inprogress"
                      : `approval-wrapper ${approval}`
                  }
                >
                  <span>{approval}</span>
                </div>
              )
            ) : isDeactivated ? (
              <div className={"approval-wrapper deactivated"}>
                <span>deactivated</span>
              </div>
            ) : (
              ""
            )}
          </div>

          <Card.Body
            className={
              cardType === "rentedList" &&
              "pb-0 d-flex flex-column align-content-between"
            }
          >
            <div className="top-section">
              {
                <div style={{ display: "flex", alignItems:'baseline' }}>
                  <h2
                    style={{
                      flex: 1,
                      fontSize: 20,
                      fontWeight: "bolder",
                      color: "black",
                      lineHeight: '22px',
                      textAlign:'left'
                    }}
                  >
                    {priceText ? 
                    <>
                      {priceText}
                      <span style={{fontSize:'14px'}}>
                        {' '}/ month
                      </span>
                    </>
                       : (
                        <span>
                          <NumberFormat
                            value={price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                          <span style={{fontSize:'14px'}}>
                            / month
                          </span>
                        </span>
                    )}
                  </h2>

                  {!hideVerifedStatus && type !== "list" && props.engagedListing !== true ? (
                    <div className="status-wrapper">
                      {is_verified === "unverified" ? (
                        <span
                          className="cursor-pointer"
                          onClick={onVerifyAction}
                        >
                          {is_verified}
                          &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                        </span>
                      ) : is_verified === "verification pending" ? (
                        <span style={{ cursor: "default" }}>
                          {is_verified}
                          &nbsp; &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: "default" }} className="success">
                          {is_verified}
                          &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/verified.png")}
                          />
                        </span>
                      )}

                      {/* {notification && (
                        <div className="list-inline d-flex justify-content-end">
                          <div className="list-li">
                            <div className="icon-wrap">
                              <Image
                                width="20px"
                                height="14px"
                                alt="list-grid-icon"
                                src={require(`../../../_assets/images/messages/messages.svg`)}
                              />
                              {new_message && (
                                <div className="message-active"></div>
                              )}
                            </div>
                          </div>
                          <div className="list-li">
                            <div className="icon-wrap">
                              <Image
                                width="20px"
                                height="14px"
                                alt="list-grid-icon"
                                src={require(`../../../_assets/images/messages/calender.svg`)}
                              />
                              {new_tenancy_application && (
                                <div className="message-active"></div>
                              )}
                            </div>
                          </div>
                          <div className="list-li">
                            <Image
                              width="20px"
                              height="14px"
                              alt="list-grid-icon"
                              src={require(`../../../_assets/images/messages/application.svg`)}
                            />
                            {new_viewing_request && (
                              <div className="message-active"></div>
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>
                  ) : (
                    <div className="status-wrapper">
                      {props.isVerifed === "unverified" ? (
                        <span
                          className="cursor-pointer"
                          onClick={onVerifyAction}
                        >
                          {props.isVerifed}
                          &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                        </span>
                      ) : props.isVerifed === "verification pending" ? (
                        <span style={{ cursor: "default" }}>
                          {props.isVerifed}
                          &nbsp; &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: "default" }} className="success">
                          {props.isVerifed}
                          &nbsp;
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/verified.png")}
                          />
                        </span>
                      )}
                    </div>
                  )}
                </div>
              }

              {cardType === "rentedList" && (
                <div className="rented-title">
                  {role === 2 ? "Tenant/s" : "Landlord"}:{" "}
                  {role === 2 ? tenant_name : landlord_name}
                </div> //TBD
              )}
              {price && type === "list" && <label>{price}</label>}

              <div className="details-wrapper">
                {
                  <div style={{ width: "100%" }}>
                    <span className="specification" title={details}>
                      {details}
                    </span>
                    {is_verified && type === "list" && props.engagedListing !== true ? (
                      <span className="verified">{is_verified}</span>
                    ) : ("")}
                  </div>
                }
                <h2
                  className="property-title"
                  title={title}
                  onClick={onClick}
                  style={{ cursor: onClick && "pointer" }}
                >
                  {title}
                </h2>
                {cardType !== "rentedList" && (
                  <div className="d-flex utilities">
                    <span>Utilites Included:</span>
                    &nbsp;
                    <b>{finalUtilities}</b>
                  </div>
                )}
                {/* <div className="d-flex">
                  <span>Utilites Included:</span>
                  &nbsp;
                  <b>Water, Hydro</b>
                </div> */}

                {isLoveIcon && type === "list" && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/love-icon.svg")}
                    />
                  </div>
                )}

                {isDeleteIcon && type === "list" && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/delete-icon.svg")}
                    />
                  </div>
                )}

                {onDeleteAction && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/delete-icon.svg")}
                      onClick={onDeleteAction}
                    />
                  </div>
                )}
              </div>
            </div>

            {cardType === "rentedList" && (
              <div
                className="d-flex"
                style={{ paddingBlock: 20, position:'relative', justifyContent: 'space-evenly' }}
              >
              <div style={{position:'relative'}}>
                <ButtonWithNo
                    onClick={onClickRepairs}
                    count={props.request_count}
                    img={require("../../../_assets/images/request-icon.svg")}
                    title={"Repairs"}
                />
                {props.request_count !== 0 && (
                  <div className='red-chips-request'><span style={{fontSize:'10px',lineHeight:'2'}}>{props.request_count}</span></div>
                )}
              </div>
                

                <div style={{ marginLeft: 20, position:'relative' }}>
                  <ButtonWithNo
                    onClick={onClickNotice}
                    count={props.notice_count}
                    img={require("../../../_assets/images/notice-icon.svg")}
                    title={"Notice"}
                  />
                  {props.notice_count !== 0 && (
                    <div className='red-chips-notice'><span style={{fontSize:'10px',lineHeight:'2'}}>{props.notice_count}</span></div>
                  )}
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size="sm"
        actionbuttontitle={"get started"}
        buttonaction={() => getSignedUp()}
      >
        {role === 2 ? (
          <>
            <h4>Looks like you're a landlord</h4>
            <p>
              Please sign up as a tenant with another
              <br />
              email address to engage with a listing
            </p>
          </>
        ) : (
          <>
            <h4>Sign up for free</h4>
            <p>
              create an account and get all the tools
              <br />
              you need to find the perfect home
            </p>
          </>
        )}
      </ModalBox>
    </>
  );
};
export default PropertyCard;
