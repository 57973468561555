import { getAPI } from "../../../Api";

export const getLeaseDetails = async (property_details_id, lease_id) => {
    const url = `/landlord/listing/lease/get-lease-details/${property_details_id}/${lease_id}`
    try {
        const res = await getAPI(url)
        
        if (res.status === 200) {
            return {status: res.status, data : res.data.data, error : null};
        } else if (res.response?.status === 404) {
            return {status : res.response?.status, data : null, error : res.response.data.message}
        }
    }catch(error){
        return {status : null, data: null, error : error.message}
    }
}